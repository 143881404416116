import { api } from '../../app/api/baseApi';

export const groupApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getGroups: builder.query({
            query: () => ({
                url: '/iam/v1/groups',
                method: 'GET',
            }),
        }),
        getGroupHierarchy: builder.query({
            query: (parentId: string) => ({
                url: `/iam/v1/groups/hierarchy?parentId=${parentId}`,
                method: 'GET',
            }),
        }),
        getAllGroupHierarchy: builder.query<object, void>({
            query: () => ({
                url: '/iam/v1/groups/hierarchy',
                method: 'GET',
            }),
        }),
        getGroupsHierarchy: builder.query<object, any>({
            query: (parentId: string) => ({
                url: `/iam/v1/groups/hierarchy${parentId ? `?parentId=${parentId}` : ''}`,
            }),
            providesTags: ['GroupsHierarchy'],
            extraOptions: {
                manualCaching: true,
            },
        }),
        getGroupsList: builder.query<object, any>({
            query: (parentId: string) => ({
                url: `/iam/v1/groups/hierarchy${parentId ? `?parentId=${parentId}` : ''}`,
            }),
            providesTags: ['GroupsHierarchyList'],
        }),
        createGroup: builder.mutation<object, object>({
            query: (body) => ({
                url: `/iam/v1/groups`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error): any =>
                !error ? ['Orgs', 'GroupById', 'GroupsHierarchy', 'GroupsHierarchyList'] : [],
        }),
        moveExistingGroup: builder.mutation<object, object>({
            query: (body) => ({
                url: `/iam/v1/groups`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (result, error): any =>
                !error ? ['GroupById', 'GroupsHierarchy', 'GroupsHierarchyList'] : [],
        }),
        deleteGroup: builder.mutation<object, object>({
            query: (payload: { groupId: string }) => ({
                url: `/iam/v1/groups/${payload.groupId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error): any => (!error ? ['Orgs', 'DeviceList', 'GroupsHierarchy'] : []),
        }),
        updateGroup: builder.mutation<object, object>({
            query: (payload: { groupId: string; name: string; shortName: string }) => ({
                url: `/iam/v1/groups/${payload.groupId}`,
                method: 'PATCH',
                body: { name: payload.name, shortName: payload.shortName },
            }),
            invalidatesTags: (result, error): any =>
                !error ? ['GroupById', 'GroupsHierarchy', 'GroupsHierarchyList'] : [],
        }),
        updateGroupBackground: builder.mutation<object, object>({
            query: (payload: { groupId: string; image: any }) => ({
                url: `/iam/v1/groups/${payload.groupId}/image`,
                method: 'PATCH',
                body: payload.image,
            }),
            invalidatesTags: (result, error): any => (!error ? ['GroupById'] : []),
        }),
        getGroupById: builder.query<object, object>({
            query: (payload: { groupId: string }) => ({
                url: `/iam/v1/groups/${payload.groupId}`,
            }),
            providesTags: ['GroupById'],
        }),
        assignUsersToGroups: builder.mutation<object, object>({
            query: (payload: { groupId: string; userIds: string[] }) => ({
                url: `/iam/v1/groups/${payload.groupId}/assign/users`,
                method: 'PATCH',
                body: { userIds: payload.userIds },
            }),
        }),
        removeGroupImage: builder.mutation<object, object>({
            query: (payload: { groupId: string }) => ({
                url: `/iam/v1/groups/${payload.groupId}/image`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error): any => (!error ? ['GroupById'] : []),
        }),
    }),
});

export const {
    useGetGroupsQuery,
    useGetGroupHierarchyQuery,
    useAssignUsersToGroupsMutation,
    useCreateGroupMutation,
    useDeleteGroupMutation,
    useGetGroupByIdQuery,
    useGetGroupsHierarchyQuery,
    useMoveExistingGroupMutation,
    useUpdateGroupBackgroundMutation,
    useUpdateGroupMutation,
    useGetAllGroupHierarchyQuery,
    useRemoveGroupImageMutation,
    useGetGroupsListQuery,
    useLazyGetGroupsListQuery,
} = groupApi;
