import { api } from '../../app/api/baseApi';

const deviceSettingsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getDeviceSettings: builder.query<object, any>({
            query: (deviceId) => ({
                url: `/dm/v1/devices/${deviceId}/settings`,
                method: 'GET',
            }),
            providesTags: ['Settings'],
        }),
        updateDeviceSettings: builder.mutation<object, any>({
            query: ({ deviceId, payload }) => ({
                url: `/dm/v1/devices/${deviceId}/settings`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Settings'] : []),
        }),
        muteAlarm: builder.mutation<object, any>({
            query: ({ deviceId, isAudibleAlarm }) => ({
                url: `/dm/v1/devices/${deviceId}/settings`,
                method: 'PATCH',
                params: { isAudibleAlarm },
            }),
            invalidatesTags: (result, error): any => (!error ? ['DeviceDetails'] : []),
        }),
    }),
});

export const { useGetDeviceSettingsQuery, useUpdateDeviceSettingsMutation, useMuteAlarmMutation } = deviceSettingsApi;
