import React, { useState, useEffect } from 'react';
import { DateFormats, Languages, PreferencePayload, Timezones, UserProfile } from '@fiji/common/src/types';
import { Card, Box, Typography, Select, MenuItem, FormControl, SelectChangeEvent } from '@mui/material';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { useTheme } from '@mui/material/styles';
import {
    useGetDateFormatsQuery,
    useGetLanguagesQuery,
    useGetTimeZonesQuery,
    useGetUserProfileQuery,
} from '@fiji/common/src/features/profile/profileApi';
import { RefetchConfigOptions } from '@reduxjs/toolkit/dist/query/core/apiState';
import { useUpdateUserProfileMutation } from '@fiji/common/src/features/userManagement/userManagement';

import { useAppDispatch } from '@fiji/common/src/app/store';

import { useStyles } from './styles';
import { setToastifyContent } from '@fiji/common/src/features/common/commonSlice';

export const Preferences = (): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();

    const [userData, setUserData] = useState<Partial<PreferencePayload>>({});
    const [initialRender, setInitialRender] = useState<boolean>(true);

    const { data: profileDetails } = useGetUserProfileQuery<{
        data: UserProfile;
        refetch: RefetchConfigOptions;
    }>(undefined, { skip: !initialRender });

    const { data: languagesData } = useGetLanguagesQuery<{
        data: Languages;
        isLoading: boolean;
    }>();
    const { data: allTimeZones } = useGetTimeZonesQuery<{
        data: Timezones;
        isLoading: boolean;
    }>();
    const { data: allDateFormats } = useGetDateFormatsQuery<{
        data: DateFormats;
        isLoading: boolean;
    }>();

    const [updateUserProfile] = useUpdateUserProfileMutation({ fixedCacheKey: 'updateUserProfile' });

    /* The `useEffect` hook is used to perform side effects in a functional component. In this case,
    the effect is triggered when the `profileDetails` variable changes. */
    useEffect(() => {
        if (profileDetails) {
            setUserData((prev: any) => ({
                ...prev,
                language: profileDetails?.data?.language,
                timezone: profileDetails?.data?.timezone,
                dateFormat: profileDetails?.data?.dateFormat,
            }));
        }
    }, [profileDetails]);

    /* The `useEffect` hook is used to perform side effects in a functional component. In this case,
    the effect is triggered when the `initialRender` variable changes. If `initialRender` is
    `false`, it means that the user has made changes to their preferences and the
    `preferencesApiCallHandler` function is called to update the user's profile preferences. */
    useEffect(() => {
        if (!initialRender) {
            void preferencesApiCallHandler();
        }
    }, [initialRender]);

    /**
     * The function `preferencesApiCallHandler` updates the user's preferences and displays a success
     * message if the update is successful.
     */
    const preferencesApiCallHandler = async (): Promise<void> => {
        setInitialRender(true);
        const { error }: any = await updateUserProfile({
            ...(userData.language && { language: userData.language }),
            ...(userData.dateFormat && { dateFormat: userData.dateFormat }),
            ...(userData.timezone && { timezone: userData.timezone }),
        });

        if (!error) {
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: `Your selected preference has been updated`,
                    duration: 3000,
                })
            );
        }
    };

    /**
     * The function updates the user's preferences and triggers a re-render of the component.
     * @param e - The parameter `e` is of type `SelectChangeEvent<unknown>`. It represents the event
     * object that is triggered when the value of a select input element is changed.
     */
    const preferencesOnChangeHandler = (e: SelectChangeEvent<unknown>): void => {
        setUserData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
        setInitialRender(false);
    };

    return (
        <>
            <Card sx={{ p: 0, mb: 2 }}>
                <Box>
                    <Box className="panel-header">
                        <InfoListItem
                            title={
                                <Typography color={'primary'} variant={'subtitle2'}>
                                    Preferences
                                </Typography>
                            }
                            divider={'full'}
                            dense
                            hidePadding
                        />
                    </Box>
                    <InfoListItem
                        className={classes.infoListItem}
                        data-testid="infoListItem"
                        data-cy={'list-content'}
                        title={'Language'}
                        subtitle={
                            languagesData?.data?.find((lang: any) => lang?.value === userData?.language)?.label ||
                            'English'
                        }
                        avatar={false}
                        hidePadding
                        divider={'full'}
                        rightComponent={
                            <FormControl id="language" variant="filled" className={classes.formControl}>
                                <Select
                                    className={classes.multiSelect}
                                    value={userData?.language ?? 'en'}
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    name="language"
                                    onChange={preferencesOnChangeHandler}
                                >
                                    {languagesData?.data?.map((lang: any) => (
                                        <MenuItem key={lang['label']} value={lang['value']}>
                                            {lang['label']}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        }
                    />
                    <InfoListItem
                        className={classes.infoListItem}
                        data-testid="infoListItem"
                        data-cy={'list-content'}
                        subtitle={
                            allTimeZones?.data?.find((timeZone) => timeZone?.id === userData?.timezone)?.displayName ??
                            allTimeZones?.data[0]?.displayName ??
                            ''
                        }
                        title={'Time Zone'}
                        avatar={false}
                        hidePadding
                        divider={'full'}
                        rightComponent={
                            <FormControl
                                id="time-zone"
                                variant="filled"
                                sx={{ minWidth: 160, mb: 0 }}
                                className={classes.formControl}
                            >
                                <Select
                                    value={
                                        allTimeZones?.data?.find((timeZone) => timeZone?.id === userData?.timezone)
                                            ?.id ??
                                        allTimeZones?.data[0]?.id ??
                                        ''
                                    }
                                    className={classes.multiSelect}
                                    onChange={preferencesOnChangeHandler}
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    name="timezone"
                                >
                                    {allTimeZones?.data?.map((timeZone: any) => (
                                        <MenuItem key={`${timeZone?.id}key`} value={timeZone?.id}>
                                            {timeZone?.displayName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        }
                    />
                    <InfoListItem
                        className={classes.infoListItem}
                        data-testid="infoListItem"
                        data-cy={'list-content'}
                        title={'Date Format'}
                        subtitle={userData?.dateFormat ?? allDateFormats?.data[0] ?? ''}
                        avatar={false}
                        hidePadding
                        divider={'full'}
                        rightComponent={
                            <FormControl
                                id="date-format"
                                variant="filled"
                                sx={{ minWidth: 160 }}
                                className={classes.formControl}
                            >
                                <Select
                                    value={userData?.dateFormat ?? allDateFormats?.data[0] ?? ''}
                                    onChange={preferencesOnChangeHandler}
                                    className={classes.multiSelect}
                                    name="dateFormat"
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                >
                                    {allDateFormats?.data?.map((date: string) => (
                                        <MenuItem key={`key${date}`} value={date}>
                                            {date}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        }
                    />
                </Box>
            </Card>
        </>
    );
};
