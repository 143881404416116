import { InfoListItem } from '@brightlayer-ui/react-components';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Switch } from '@mui/material';

const useStyles = makeStyles(() => ({
    cardWrapper: {
        padding: '48px',
        marginBottom: '16px',
    },
    sectionHeader: {
        width: '100%',
        marginBottom: 16,
    },
    infoListItem: {
        height: '100%',
    },
}));

export const RolesPermissions = (): JSX.Element => {
    const classes = useStyles();
    const ALL_PERMISSIONS = [
        'Acknowledge Alarms',
        'Manage Devices',
        'Manage Device Groups',
        'Manage Users',
        'Scheduling',
    ];
    return (
        <>
            {ALL_PERMISSIONS.map((permission) => (
                <InfoListItem
                    key={permission}
                    className={classes.infoListItem}
                    data-testid="infoListItem"
                    data-cy={'list-content'}
                    title={permission}
                    avatar={false}
                    iconAlign="center"
                    hidePadding
                    rightComponent={<Switch />}
                />
            ))}
        </>
    );
};
