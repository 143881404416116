import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeviceType } from '../../types/Device';
import { RootState } from '../../app/store';

const initialState: DeviceType = {
    sortParameter: 'status',
    sortType: 'ASC',
    filterApplied: false,
    sortApplied: false,
    filterStatus: [],
    searchDeviceName: '',
    searchAll: '',
    deviceType: [],
    selectedDevice: {},
    filterSelected: {
        filterParameters: [],
        searchByDeviceName: '',
        filterTags: [],
        statusActive: {
            Alarm: false,
            Offline: false,
            Warning: false,
            Informational: false,
        },
        selectedDeviceType: [],
    },
    pageNum: 1,
    pageSize: 10,
    deviceListTotal: 0,
    mqttCredentials: {
        currentId: '',
        clientId: '',
    },
};

const deviceSlice = createSlice({
    name: 'device',
    initialState: {
        ...initialState,
        manageDevices: {},
    },
    reducers: {
        setSortType: (state, action: PayloadAction<string>) => {
            state.sortType = action.payload;
            if (state.sortType !== '') {
                state.sortApplied = true;
            } else {
                state.sortApplied = false;
            }
        },
        setSortParameter: (state, action: PayloadAction<string>) => {
            state.sortParameter = action.payload;
        },
        setDeviceSorting: (state, action: PayloadAction<{ [key: string]: string }>) => {
            state.sortParameter = action.payload.sortParameter;
            state.sortType = action.payload.sortType;
            if (state.sortType !== '') {
                state.sortApplied = true;
            } else {
                state.sortApplied = false;
            }
            state.pageNum = 1;
        },
        setFilterParam: (state, action: PayloadAction<string[]>) => {
            state.filterStatus = action.payload;
            if (state.filterApplied === false && action.payload.length) {
                state.filterApplied = true;
            } else if (action.payload.length === 0) {
                state.filterApplied = false;
            }
        },
        resetDeviceSort: (state) => {
            state.sortApplied = initialState.sortApplied;
            state.sortParameter = initialState.sortParameter;
            state.sortType = initialState.sortType;
            state.pageNum = initialState.pageNum;
        },
        setSelectedDevice: (state, { payload }: PayloadAction<string>) => {
            state.selectedDevice = payload;
        },
        setManageDevices: (state, { payload }: PayloadAction<object>) => {
            state.manageDevices = payload;
        },
        setSearchDeviceName: (state, { payload }: PayloadAction<string>) => {
            state.searchDeviceName = payload;
            if (state.searchDeviceName !== '') {
                state.filterApplied = true;
            }
        },
        setDeviceType: (state, action: PayloadAction<string[]>) => {
            state.deviceType = action.payload;
            if (state.filterApplied === false && action.payload.length) {
                state.filterApplied = true;
            }
        },
        setMqttCredential: (state, { payload }) => {
            state.mqttCredentials = payload;
        },

        setFilterSelected: (state, action: PayloadAction<any>) => {
            state.filterSelected = action.payload;
        },
        setTotalDeviceRecords: (state, action: PayloadAction<number>) => {
            state.deviceListTotal = action.payload;
        },
        setSearchAll: (state, action: PayloadAction<string>) => {
            state.searchAll = action.payload;
        },
        setPageNumber: (state, action: PayloadAction<number>) => {
            state.pageNum = action.payload;
        },
        resetDeviceFilterPayload: (state) => Object.assign(state, initialState),
    },
    extraReducers: (builder) => {
        builder.addCase('auth/appLogout', (state) => {
            Object.assign(state, initialState);
        });
    },
});

export const {
    setSortType,
    setSortParameter,
    setFilterParam,
    setManageDevices,
    setMqttCredential,
    resetDeviceSort,
    setSearchDeviceName,
    setDeviceType,
    setSelectedDevice,
    setFilterSelected,
    setTotalDeviceRecords,
    setDeviceSorting,
    setPageNumber,
    resetDeviceFilterPayload,
    setSearchAll,
} = deviceSlice.actions;
export const selectManageDevices = (state: RootState): any => state.device.manageDevices;
export const selectManageMqttCredentials = (state: RootState): any => state.device.mqttCredentials;
export default deviceSlice;
