import React, { useState, useEffect } from 'react';
import {
    Box,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    AppBar,
    Stack,
    Button,
    Typography,
    IconButton,
    Grid,
    Skeleton,
    CircularProgress,
    Tooltip,
} from '@mui/material';
import * as Colors from '@brightlayer-ui/colors';
import { useTheme } from '@mui/material/styles';
import {
    ContentPaste,
    Replay,
    NavigateNext,
    PowerSettingsNew,
    BatteryFull,
    Battery5Bar,
    Create,
    Done,
    FormatListBulleted,
    ContentPasteOff,
    AssignmentLate,
    Battery20,
    Battery30,
    Battery50,
    Battery60,
    Battery80,
    Battery90,
    BatteryAlert,
    TimerOutlined,
    BatteryUnknown,
} from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { EmptyState, Hero, HeroBanner, InfoListItem, ListItemTag } from '@brightlayer-ui/react-components';
import { Battery, Current, CurrentAc, VoltageAc } from '@brightlayer-ui/icons-mui';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import { RefetchConfigOptions } from '@reduxjs/toolkit/dist/query/core/apiState';

import { useStyles } from './styles';

import { COMMAND_CONSTANTS, DeviceLoads, DeviceProperties, TurnOffModal, TurnOnModal } from './';
import { closeMqttConnection, connectMQTT, subscribeTopic } from '../../mqttConnection';
import {
    useExecuteDeviceCommandMutation,
    useExecuteDeviceLoadCommandMutation,
    useExecuteRunTestMutation,
    useGetAllDeviceLoadsQuery,
    useLazyGetDeviceChannelsQuery,
} from '@fiji/common/src/features/deviceDetails/deviceDetailApi';
import { useGetDeviceByIdQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useGetTimeLineSummaryQuery } from '@fiji/common/src/features/timeline/timelineApi';
import { TimelineStatusIcon } from '../../components/TimelineStatusIcon';
import { UserProfile } from '@fiji/common/src/types';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { DeviceTrends } from '../DeviceTrends';
import TimelineCardSkeleton from '../Common/Skeletons/TimelineCardSkeleton';
import PropertiesCardSkeleton from '../Common/Skeletons/PropertiesCardSkeleton';
import HeroSkeleton from '../Common/Skeletons/HeroSkeleton';
import { setToastifyContent } from '@fiji/common/src/features/common/commonSlice';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { useTransformDatTime } from '../../hooks/useTransformDatTime';
import { useRBAC } from '../../hooks';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useGetMQTTCredentialsMutation } from '@fiji/common/src/features/mqtt/mqttApi';

/**
 * The SummaryTabTypes type is a React component prop type that includes a function called setActiveTab
 * which takes an argument of any type and does not return anything.
 * @property setActiveTab - A function that takes an argument of any type and does not return anything.
 * It is used to set the active tab in the summary tab component.
 */
export type SummaryTabTypes = {
    setActiveTab: (arg: any) => void;
    setDeviceStatus: any;
    deviceStatus: any;
};

const PRIORITY_TAG_NAMES = ['inputVoltage', 'percentLoad', 'remainingCapacity', 'runTimeToEmpty'];
const PRIORITY_STATUS_NAME: Record<string, number> = {
    'Alarm Active': 1,
    'Warning Active': 2,
    'Alarm Cleared': 3,
    'Warning Cleared': 3,
    Informational: 3,
    Offline: 3,
    'Offline Cleared': 3,
};

type TimelineData = {
    productId: string;
    serialNumber: string;
    status: string;
    [key: string]: unknown;
};

export const SummaryTab = (props: SummaryTabTypes): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const navigate = useNavigate();
    const { deviceId } = useParams();
    const dispatch = useAppDispatch();
    const rolePermissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(rolePermissions);
    const canViewLoads = hasPermission('view-device-loads');
    const canUpdateLoads = hasPermission('update-device-loads');
    const canViewCommonds = hasPermission('device-command');
    const canUpdateDevice = hasPermission('update-device');

    const [currentSnackbarId, setCurrentSnackbarId] = useState<any>({});
    const [timelineRecords, setTimelineRecords] = useState([]);

    const [modalState, setModalState] = useState({ isOpen: false, command: '', type: '', loadId: '' });

    const [deviceChannelDetails, setDeviceChannelDetails] = useState<any>([]);
    const [channelTimestamp, setChannelTimestamp] = useState();
    const [isLoadingCommand, setIsLoadingCommand] = useState<any>([]);
    const [, setTriggeredCommand] = useState('');

    const [executeDeviceCommand, { data: deviceCommandData, error: deviceCommandError }]: any =
        useExecuteDeviceCommandMutation();

    const [executeRunTest, { isSuccess: runTestData, error: runTestError }]: any = useExecuteRunTestMutation();

    const [executeDeviceLoadCommand, { data: deviceLoadCommandsData, error: deviceLoadCommandsError }]: any =
        useExecuteDeviceLoadCommandMutation();

    const {
        data: deviceLoads,
        isLoading: isLoadingDeviceLoads,
        isFetching: isFetchingDeviceLoads,
    } = useGetAllDeviceLoadsQuery({ deviceId }, { skip: !canViewLoads });

    const {
        data: deviceDetails,
        isLoading,
        isFetching,
        refetch: refetchDeviceDetails,
    } = useGetDeviceByIdQuery<{ data: any; refetch: RefetchConfigOptions; isLoading: boolean; isFetching: boolean }>(
        deviceId,
        {
            skip: !deviceId || deviceId === ':deviceId',
        }
    );

    const {
        data: timelineData,
        isLoading: isTimeLineLoading,
        isFetching: isTimeLineFetching,
    }: any = useGetTimeLineSummaryQuery(
        { deviceId: [deviceId] },
        {
            refetchOnMountOrArgChange: true,
            skip: !deviceId || deviceId === ':deviceId',
        }
    );

    const { data: profileDetails } = useGetUserProfileQuery<{
        data: UserProfile;
        refetch: RefetchConfigOptions;
    }>();

    const { convertDateTime } = useTransformDatTime(profileDetails);

    const [getDeviceChannels, deviceChannelsResponse]: any = useLazyGetDeviceChannelsQuery();

    const {
        isLoading: channelsLoading,
        isFetching: channelsFetching,
        data: deviceChannels,
    }: any = deviceChannelsResponse;

    useEffect(() => {
        if (deviceId && deviceId !== ':deviceId') {
            getDeviceChannels({ deviceId });
        }
    }, [deviceId]);

    /* The below code is using the `useEffect` hook in a React component. It is setting up a side
    effect that will be triggered whenever the `deviceId` variable changes. */
    useEffect(() => {
        if (deviceId && deviceId !== ':deviceId') {
            refetchDeviceDetails()
                .then(() => {})
                .catch(() => {});
        }
    }, [deviceId]);

    const timeoutClearId: any = React.useRef(null);

    useEffect(() => {
        if (runTestData) {
            timeoutClearId.current = setTimeout(() => {
                handleCloseSnackbar('RUN_TEST');
                setIsLoadingCommand((prevState: any) => prevState.filter((item: any) => item.command !== 'RUN_TEST'));
                handleShowMessage(
                    'RUN_TEST',
                    'TimeOut',
                    getRenTestErrorIcons('TimeOut'),
                    undefined,
                    true,
                    snackbarActionTryAgain
                );
            }, 65000);
        }
    }, [runTestData]);

    const handleShowMessage = (
        command: string,
        messageType?: string,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Icon?: JSX.Element,
        subtitle?: string,
        showNotificationAction?: any,
        snackbarActionBtn?: any
    ): void => {
        const snackbarProps: any = {
            variant: 'notificationWithIcon',
            subtitle: subtitle ?? COMMAND_CONSTANTS?.[command]?.[messageType ?? 'successMessage']?.subtitle,
            autoHideDuration: messageType === 'DoneAndPassed' || messageType === 'TimeOut' ? 3000 : null,
            Icon: Icon ?? (
                <IconButton
                    sx={{
                        width: '42px',
                        height: '42px',
                        borderRadius: '50%',
                        color: '#fff',
                        backgroundColor: '#39B620',
                    }}
                >
                    <Done sx={{ width: '24px', height: '24px' }} />
                </IconButton>
            ),
            ...(showNotificationAction && {
                notificationWithAction: true,
                notificationAction: snackbarActionBtn ?? snackbarAction,
            }),
        };
        setCurrentSnackbarId((prev: any) => ({
            ...prev,
            [command]: enqueueSnackbar(
                COMMAND_CONSTANTS?.[command]?.[messageType ?? 'successMessage']?.title,
                snackbarProps
            ),
        }));
    };

    /* The below code is a useEffect hook in a TypeScript React component. It is triggered whenever the
    value of `deviceCommandData` changes. */
    useEffect(() => {
        if (deviceCommandData) {
            const command: string = deviceCommandData?.requestBody?.command ?? '';
            setIsLoadingCommand((prevState: any) => prevState.filter((item: any) => item.command !== command));
            handleCloseSnackbar(command);
            if (command !== 'TURN_ON_DEVICE' && command !== 'TURN_OFF_DEVICE')
                dispatch(
                    setToastifyContent({
                        isOpen: true,
                        message: COMMAND_CONSTANTS?.[command]?.['successMessage']?.title,
                        duration: 3000,
                    })
                );
        }
    }, [deviceCommandData]);

    /* The below code is a useEffect hook in a TypeScript React component. It is triggered whenever the
    value of `deviceLoadCommandsData` changes. */
    useEffect(() => {
        if (deviceLoadCommandsData) {
            const command: string = deviceLoadCommandsData?.requestBody?.command ?? '';
            const loadId: string = deviceLoadCommandsData?.loadId ?? '';
            setIsLoadingCommand((prevState: any) =>
                prevState.filter((item: any) => item.command !== command && item.loadId !== loadId)
            );
            handleCloseSnackbar(command);
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: COMMAND_CONSTANTS?.[command]?.['successMessage']?.title,
                    duration: 3000,
                })
            );
        }
    }, [deviceLoadCommandsData]);

    const triggerSuccessMsg = (): void => {
        setTriggeredCommand((prev: string): any => {
            if (prev === 'TURN_ON' || prev === 'TURN_OFF') {
                const command: string = prev === 'TURN_OFF' ? 'TURN_OFF_DEVICE' : 'TURN_ON_DEVICE';
                dispatch(
                    setToastifyContent({
                        isOpen: true,
                        message: COMMAND_CONSTANTS?.[command]?.['successMessage']?.title,
                        duration: 3000,
                    })
                );
            }
            return prev;
        });
    };

    useEffect(() => {
        if (timelineData) setTimelineRecords(timelineData?.data);
    }, [timelineData]);

    /* The below code is using the useEffect hook in a React component. It is checking if the value of
    the deviceCommandError variable has changed. If it has, it sets the isLoadingCommand state to
    false and calls the handleCloseSnackbar function. */
    useEffect(() => {
        if (deviceCommandError) {
            const command: string = deviceCommandError?.requestBody?.command ?? '';
            setIsLoadingCommand((prevState: any) => prevState.filter((item: any) => item.command !== command));
            handleCloseSnackbar(command);
        }
    }, [deviceCommandError]);

    useEffect(() => {
        if (runTestError) {
            setIsLoadingCommand((prevState: any) => prevState.filter((item: any) => item.command !== 'RUN_TEST'));
            handleCloseSnackbar('RUN_TEST');
        }
    }, [runTestError]);

    /* The below code is using the useEffect hook in a React component. It is checking if the value of
    the deviceCommandError variable has changed. If it has, it sets the isLoadingCommand state to
    false and calls the handleCloseSnackbar function. */
    useEffect(() => {
        if (deviceLoadCommandsError) {
            const command: string = deviceLoadCommandsError?.requestBody?.command ?? '';
            const loadId: string = deviceLoadCommandsError?.loadId ?? '';
            setIsLoadingCommand((prevState: any) =>
                prevState.filter((item: any) => item.command !== command && item.loadId !== loadId)
            );
            handleCloseSnackbar(command);
        }
    }, [deviceLoadCommandsError]);

    const [getMqttCredentials, { data: mqttCredentials }]: any = useGetMQTTCredentialsMutation();
    useEffect(() => {
        if (deviceId) {
            getMqttCredentials({ clientId: null, deviceId });
        }
        return () => {
            closeMqttConnection();
        };
    }, [deviceId]);

    useEffect(() => {
        const pollingInterval = setInterval(() => {
            if (deviceId) getMqttCredentials({ clientI: null, deviceId });
        }, 540000);
        return () => {
            clearInterval(pollingInterval);
            closeMqttConnection();
        };
    }, [getMqttCredentials]);

    useEffect(() => {
        if (mqttCredentials?.data?.token) {
            const encodedCredentials = JSON.parse(
                Buffer.from(mqttCredentials?.data?.token, 'base64').toString('utf-8')
            );
            const decodedCredentials: any = {};

            for (const key in encodedCredentials) {
                decodedCredentials[key] =
                    key === 'clientId'
                        ? encodedCredentials[key]
                        : Buffer.from(encodedCredentials[key], 'base64').toString('utf-8');
            }
            connectMQTT(() => {
                subscribeTopic(
                    [
                        `BSSRM/TREND/${deviceId || ''}`,
                        `BSSRM/TIMELINE/${deviceId || ''}`,
                        `BSSRM/DEVICE_STATUS/${deviceId || ''}`,
                        `BSSRM/OUTPUT_OFF/${deviceId || ''}`,
                        `BSSRM/RUN_TEST/${deviceId || ''}`,
                    ],
                    mqttMessageHandler
                );
            }, decodedCredentials);
        }
    }, [mqttCredentials]);

    /* The below code is using the useEffect hook in a React component. It is setting the state
    variables `deviceChannelDetails` and `channelTimestamp` based on the values from the
    `deviceChannels` object. If the `deviceChannels` object has a `data` property with a
    `deviceDetail` property, then `deviceChannelDetails` will be set to that value. Similarly, if
    the `deviceChannels` object has a `data` property with a `timestamp` property, then
    `channelTimestamp` will be set to that value. The useEffect hook will run whenever the
    `deviceChannels` */
    useEffect(() => {
        if (deviceChannels?.data?.deviceDetail) setDeviceChannelDetails(deviceChannels?.data?.deviceDetail);
        if (deviceChannels?.data?.timestamp) setChannelTimestamp(deviceChannels?.data?.timestamp);
    }, [deviceChannels]);

    /**
     * The function handleCloseSnackbar is used to close a snackbar in a TypeScript React application.
     */
    const handleCloseSnackbar = (command: any): void => {
        closeSnackbar(currentSnackbarId[command]);
        setCurrentSnackbarId((prevState: any) => {
            delete prevState[command];
            return prevState;
        });
    };

    const handleCloseSnackbarBySnackId = (e: any): void => {
        closeSnackbar(+e.target.name);
    };

    const snackbarAction = (snackbarKey: any): JSX.Element => (
        <Button variant="text" sx={{ color: '#80BDE0' }} name={snackbarKey} onClick={handleCloseSnackbarBySnackId}>
            Ok
        </Button>
    );

    const snackbarActionTryAgain = (snackbarKey: any): JSX.Element => (
        <Button
            variant="text"
            sx={{ color: '#80BDE0' }}
            name={snackbarKey}
            onClick={(e: any): void => {
                handleCloseSnackbarBySnackId(e);
                handleExecuteDeviceCommand('RUN_TEST', '');
            }}
        >
            Try Again
        </Button>
    );

    /* The below code is defining a function called `getCardHeaderTitle` that returns a JSX element.
    The JSX element represents a card header with a title and additional details. */
    const getCardHeaderTitle = (): JSX.Element => (
        <Box className={classes.cardHeaderTitle}>
            {isLoading || isFetching || channelsFetching || channelsLoading ? (
                <Skeleton animation="wave">
                    <Typography classes={{ root: classes.categoryName }} variant="subtitle2">
                        Details
                    </Typography>
                </Skeleton>
            ) : (
                <Typography classes={{ root: classes.categoryName }} variant="subtitle2">
                    Details
                </Typography>
            )}
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                {isLoading || isFetching || channelsFetching || channelsLoading ? (
                    <Skeleton animation="wave">
                        <Typography variant="subtitle2" fontWeight={300}>
                            Last Updated:{' '}
                            <b className="fw-600">
                                {convertDateTime({
                                    timestamp: channelTimestamp,
                                    customFormat: profileDetails?.data?.dateFormat
                                        ? `${profileDetails?.data?.dateFormat} HH:mm`
                                        : 'DD/MM/YYYY HH:mm',
                                })}
                            </b>
                        </Typography>
                    </Skeleton>
                ) : (
                    <Typography variant="subtitle2" fontWeight={300}>
                        Last Updated:{' '}
                        <b className="fw-600">
                            {convertDateTime({
                                timestamp: channelTimestamp,
                                customFormat: profileDetails?.data?.dateFormat
                                    ? `${profileDetails?.data?.dateFormat} HH:mm`
                                    : 'DD/MM/YYYY HH:mm',
                            })}
                        </b>
                    </Typography>
                )}

                {isLoading || isFetching || channelsFetching || channelsLoading ? (
                    <Skeleton animation="wave">
                        <IconButton aria-label="settings" size="small">
                            <Replay fontSize="small" />
                        </IconButton>
                    </Skeleton>
                ) : (
                    <IconButton
                        aria-label="settings"
                        size="small"
                        onClick={(): void => getDeviceChannels({ deviceId, params: { live: true } })}
                        disabled={props?.deviceStatus?.connectionStatus !== 'Online'}
                    >
                        <Replay fontSize="small" />
                    </IconButton>
                )}
            </Stack>
        </Box>
    );

    /**
     * The function `mqttMessageHandler` updates the device channel details and channel timestamp based
     * on the received MQTT message.
     * @param {any} message - The `message` parameter is the payload of the MQTT message received. It
     * can be of any type, as indicated by the `any` type annotation.
     * @param {string} messageTopic - The `messageTopic` parameter is a string that represents the
     * topic of the MQTT message. It is used to determine if the message is relevant to the current
     * device or not.
     */

    const getRenTestErrorIcons = (type: string): JSX.Element => {
        switch (type) {
            case 'Aborted':
            case 'NoTestInitiatedMapped':
            case 'NoTestInitiated': {
                return (
                    <IconButton
                        sx={{
                            width: '42px',
                            height: '42px',
                            borderRadius: '50%',
                            color: '#fff',
                            backgroundColor: '#CA3C3D',
                        }}
                    >
                        <ContentPasteOff sx={{ width: '24px', height: '24px' }} />
                    </IconButton>
                );
            }
            case 'DoneAndError': {
                return (
                    <IconButton
                        sx={{
                            width: '42px',
                            height: '42px',
                            borderRadius: '50%',
                            color: '#fff',
                            backgroundColor: '#CA3C3D',
                        }}
                    >
                        <AssignmentLate sx={{ width: '24px', height: '24px' }} />
                    </IconButton>
                );
            }
            case 'TimeOut': {
                return (
                    <IconButton
                        sx={{
                            width: '42px',
                            height: '42px',
                            borderRadius: '50%',
                            color: '#fff',
                            backgroundColor: '#CA3C3D',
                        }}
                    >
                        <ErrorOutlineIcon sx={{ width: '24px', height: '24px' }} />
                    </IconButton>
                );
            }
            default: {
                return <></>;
            }
        }
    };

    const handelAddTimeline = (message: any): void => {
        setTimelineRecords((prevState: any) => {
            const prevStateClone = JSON.parse(JSON.stringify(prevState));
            const existTimelineIndex = prevStateClone.findIndex(
                (timeline: any) => timeline.timelineId === message.timelineId
            );
            if (existTimelineIndex !== -1) {
                prevStateClone[existTimelineIndex] = message;
            } else {
                prevStateClone.unshift(message);
                const lastIndex = prevStateClone?.findLastIndex((timeline: any) => timeline?.status !== 'Alarm Active');
                if (prevState?.length === 5) {
                    if (lastIndex === -1) {
                        prevStateClone.pop();
                    } else {
                        prevStateClone.splice(lastIndex, 1);
                    }
                }
            }
            return prevStateClone.sort(
                (timelineA: TimelineData, timelineB: TimelineData) =>
                    PRIORITY_STATUS_NAME[timelineA.status] - PRIORITY_STATUS_NAME[timelineB.status]
            );
        });
    };

    const handelAddTrend = (message: any): void => {
        setDeviceChannelDetails((prev: any) =>
            prev.map((channel: any) => {
                if (channel.id === message.id) {
                    return { ...channel, value: message.value, unit: message.unit };
                }
                return channel;
            })
        );
    };

    const mqttMessageHandler = (message: any, messageTopic: string): void => {
        const cloneDeviceId = deviceId || '';
        if (messageTopic === `BSSRM/TREND/${cloneDeviceId}` && message) {
            handelAddTrend(message);
            setChannelTimestamp(message?.timestamp);
        } else if (messageTopic === `BSSRM/TIMELINE/${cloneDeviceId}` && message) {
            handelAddTimeline(message);
        } else if (messageTopic === `BSSRM/DEVICE_STATUS/${cloneDeviceId}` && message) {
            props.setDeviceStatus((prev: any) => ({ ...prev, ...message }));
            triggerSuccessMsg();
        } else if (messageTopic === `BSSRM/OUTPUT_OFF/${cloneDeviceId}` && message) {
            props.setDeviceStatus((prev: any) => ({ ...prev, ...message }));
        } else if (messageTopic === `BSSRM/RUN_TEST/${cloneDeviceId}` && message) {
            clearTimeout(timeoutClearId.current);
            setIsLoadingCommand((prevState: any) => prevState.filter((item: any) => item.command !== 'RUN_TEST'));
            handleCloseSnackbar('RUN_TEST');
            handleShowMessage(
                'RUN_TEST',
                message?.runTestStatus,
                message?.runTestStatus !== 'DoneAndPassed' ? getRenTestErrorIcons(message?.runTestStatus) : undefined,
                message?.runTestStatus === 'DoneAndError' ? '' : undefined,
                true
            );
        }
    };

    /* The below code is defining a function called `loadCardHeaderTitle` that returns a JSX element.
    The JSX element is a `Box` component with a class name of `cardHeaderTitle`. Inside the `Box`
    component, there is a `Typography` component with a class name of `categoryName` and a variant
    of `subtitle2`. The text inside the `Typography` component is "Loads". */
    const loadCardHeaderTitle = (): JSX.Element => (
        <Box className={classes.cardHeaderTitle}>
            {isLoading || isFetching || isLoadingDeviceLoads || isFetchingDeviceLoads ? (
                <Skeleton animation="wave">
                    <Typography classes={{ root: classes.categoryName }} variant="subtitle2">
                        Loads
                    </Typography>
                </Skeleton>
            ) : (
                <Typography classes={{ root: classes.categoryName }} variant="subtitle2">
                    Loads
                </Typography>
            )}
        </Box>
    );

    /* The below code is defining a function called `getTimelineCardHeaderTitle` that returns a JSX
    element. The JSX element is a `Box` component with a class name of `cardHeaderTitle`. Inside the
    `Box` component, there is a `Typography` component with a class name of `categoryName` and a
    variant of `subtitle2`. The text inside the `Typography` component is "Timeline". */
    const getTimelineCardHeaderTitle = (): JSX.Element => (
        <Box className={classes.cardHeaderTitle}>
            {isLoading || isFetching || isTimeLineLoading || isTimeLineFetching ? (
                <Skeleton animation="wave">
                    <Typography classes={{ root: classes.categoryName }} variant="subtitle2">
                        Timeline
                    </Typography>
                </Skeleton>
            ) : (
                <Typography classes={{ root: classes.categoryName }} variant="subtitle2">
                    Timeline
                </Typography>
            )}
        </Box>
    );

    /* The below code is defining a function called `getPropertiesCardHeaderTitle` that returns a JSX
    element. This JSX element represents a card header title for a properties section. */
    const getPropertiesCardHeaderTitle = (): JSX.Element => (
        <Box className={classes.cardHeaderTitle}>
            {isLoading || isFetching ? (
                <Skeleton animation="wave">
                    <Typography classes={{ root: classes.categoryName }} variant="subtitle2">
                        Properties
                    </Typography>
                </Skeleton>
            ) : (
                <Typography classes={{ root: classes.categoryName }} variant="subtitle2">
                    Properties
                </Typography>
            )}

            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                {isLoading || isFetching ? (
                    <Skeleton animation="wave">
                        <Create />
                    </Skeleton>
                ) : (
                    <IconButton
                        aria-label="settings"
                        size="small"
                        disabled={!canUpdateDevice}
                        onClick={(): void =>
                            navigate(`/device/edit/${deviceId || ''}/properties`, {
                                state: { deviceDetailsTab: 'Summary' },
                            })
                        }
                    >
                        <Create
                            fontSize="small"
                            sx={{
                                color: canUpdateDevice ? Colors.gray[500] : Colors.gray[200],
                            }}
                        />
                    </IconButton>
                )}
            </Stack>
        </Box>
    );

    const getBatteryIcon = (batteryLevel: number): JSX.Element => {
        switch (true) {
            case batteryLevel !== null && batteryLevel < 10:
                return <BatteryAlert fontSize="inherit" sx={{ color: Colors.gray[500] }} />;
            case batteryLevel >= 10 && batteryLevel < 30:
                return <Battery20 fontSize="inherit" sx={{ color: Colors.blue[500] }} />;
            case batteryLevel >= 30 && batteryLevel < 50:
                return <Battery30 fontSize="inherit" sx={{ color: Colors.blue[500] }} />;
            case batteryLevel === 50:
                return <Battery50 fontSize="inherit" sx={{ color: Colors.blue[500] }} />;
            case batteryLevel > 50 && batteryLevel < 60:
                return <Battery60 fontSize="inherit" sx={{ color: Colors.blue[500] }} />;
            case batteryLevel >= 60 && batteryLevel < 80:
                return <Battery80 fontSize="inherit" sx={{ color: Colors.blue[500] }} />;
            case batteryLevel >= 80 && batteryLevel < 90:
                return <Battery90 fontSize="inherit" sx={{ color: Colors.blue[500] }} />;
            case batteryLevel >= 90:
                return <Battery fontSize="inherit" sx={{ color: Colors.blue[500] }} />;
            default:
                return <BatteryUnknown fontSize="inherit" sx={{ color: Colors.gray[500] }} />;
        }
    };

    /* The below code is a TypeScript function called `getChannelIcon` that takes in two parameters:
    `channelKey` (a string) and `channelValue` (any type). */
    const getChannelIcon = (channelKey: string, channelValue: any, isHero?: boolean): JSX.Element => {
        switch (channelKey) {
            case 'percentLoad': {
                return (
                    <Typography style={{ width: '36px', height: '36px' }}>
                        <CircularProgressbar
                            value={channelValue ?? 0}
                            strokeWidth={50}
                            counterClockwise
                            styles={buildStyles({
                                strokeLinecap: 'butt',
                            })}
                        />
                    </Typography>
                );
            }
            case 'inputVoltage': {
                return <VoltageAc fontSize="inherit" />;
            }
            case 'runTimeToEmpty': {
                return <TimerOutlined fontSize="inherit" />;
            }
            case 'remainingCapacity': {
                return isHero ? getBatteryIcon(channelValue) : <Battery5Bar />;
            }
            case 'outputPower': {
                return <VoltageAc fontSize="inherit" />;
            }
            case 'inputFrequency': {
                return <CurrentAc />;
            }
            case 'outputVoltage': {
                return <BatteryFull fontSize="inherit" />;
            }
            case 'outputCurrent': {
                return <Current fontSize="inherit" />;
            }
            default: {
                return <></>;
            }
        }
    };

    /**
     * The function `getStatusBackgroundColor` takes a key as input and returns a corresponding
     * background color based on the key value.
     * @param {string} key - The `key` parameter is a string that represents the status of something.
     * It can have one of the following values: 'Alarm', 'Offline', 'Warning', or 'Normal'.
     * @returns The function `getStatusBackgroundColor` returns a string representing the background
     * color based on the input key.
     */
    const getStatusBackgroundColor = (timelineStatus: string): string => {
        if (timelineStatus === 'Alarm' || timelineStatus?.includes('Active')) {
            switch (timelineStatus) {
                case 'Alarm Active': {
                    return '#CA3C3D';
                }
                case 'Alarm': {
                    return '#69B1C3';
                }
                case 'Warning Active': {
                    return '#F0AA1F';
                }

                default: {
                    return '#39B620';
                }
            }
        }
        return '';
    };

    const getListItemTag = (data: any): any => {
        if (data?.status === 'Alarm') {
            return <ListItemTag label={'OFFLINE'} fontColor="#DBEEF2" backgroundColor="#69B1C3" />;
        } else if (data?.status?.includes('Cleared')) {
            return (
                <ListItemTag
                    label={data?.status?.includes('Warning') ? 'CLEARED' : 'CLOSED'}
                    fontColor="#424E54"
                    backgroundColor="#D5D8DA"
                />
            );
        }
    };

    /**
     * The function `handleExecuteDeviceCommand` handles the execution of a device command and displays
     * a loading message.
     * @param {string} command - The `command` parameter is a string that represents the device command
     * to be executed.
     */
    const handleExecuteDeviceCommand = (command: string, type: string): void => {
        setTriggeredCommand(command);
        const finalLoadId = modalState?.loadId;
        const finalCommand = !type ? command : `${command}_${type}`;
        setIsLoadingCommand((prevState: any) => [...prevState, { command: finalCommand, loadId: finalLoadId }]);
        setModalState({ isOpen: false, command: '', type: '', loadId: '' });
        if (command === 'RUN_TEST') executeRunTest({ id: deviceId });
        else if (type !== 'LOAD') executeDeviceCommand({ id: deviceId, body: { command: finalCommand } });
        else executeDeviceLoadCommand({ deviceId, loadId: finalLoadId, body: { command: finalCommand } });
        handleShowMessage(
            finalCommand,
            'loadingMessage',
            <CircularProgress color="inherit" size={24} />,
            undefined,
            command === 'RUN_TEST' || type === 'LOAD'
        );
    };

    /**
     *        executeDeviceCommand({ id: deviceId, body: { command } });
 (`key`) from an
     * object in an array (`deviceChannelDetails`) based on a matching `channelName`.
     * @param {string} channelName - A string representing the name of a device channel.
     * @param {string} key - The `key` parameter is a string that represents the property name of the
     * `deviceChannelDetails` object that you want to retrieve.
     */
    const getDeviceChannelDetails = (channelName: any, key: string, compareKey?: string): any =>
        deviceChannelDetails?.find((channel: any) => channel?.[compareKey ?? 'name'] === channelName)?.[key];

    const INFO_LIST_PROPS: any = {
        dataTestid: 'infoListItem',
        hidePadding: true,
        ripple: true,
        dense: true,
        divider: 'full',
    };

    const scrollToTop = (): void => {
        window.scrollTo(0, 0);
    };

    const isCommandExistsInLoading = (command: string, loadId: string): boolean =>
        Boolean(isLoadingCommand.find((item: any) => item.command === command && item.loadId === loadId));

    const getLoadDetails = (loadId: string): any => deviceLoads?.data?.find((load: any): any => load.id === loadId);

    const getDeviceStatus = (): string => {
        if (!props.deviceStatus?.status) {
            return 'N/A';
        } else if (props.deviceStatus?.status && props.deviceStatus?.connectionStatus === 'Online') {
            return `Online • ${
                props.deviceStatus?.status === 'Informational' ? 'Normal' : props.deviceStatus?.status ?? ''
            }`;
        }
        return props.deviceStatus?.connectionStatus;
    };

    const getCardActions = (label: string, onClick?: any): JSX.Element => (
        <CardActions
            className={classes.cardFooter}
            onClick={(): void => {
                scrollToTop();
                onClick();
            }}
        >
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                width={'100%'}
                onClick={onClick}
            >
                <Typography variant={'subtitle2'} color={'primary'} sx={{ cursor: 'pointer' }}>
                    {label}
                </Typography>
                <IconButton aria-label="settings">
                    <NavigateNext fontSize="medium" />
                </IconButton>
            </Stack>
        </CardActions>
    );

    const getTimelineList = (): JSX.Element => {
        if (!timelineRecords?.length) {
            return (
                <EmptyState
                    icon={<FormatListBulleted className="my-svg-icon" />}
                    title="No Events Found"
                    description="No Alarm Or Events Found"
                />
            );
        }
        return (
            <>
                {timelineRecords?.map((item: any) => (
                    <InfoListItem
                        key={item?.timelineId}
                        data-testid="infoListItem"
                        hidePadding
                        ripple
                        chevron
                        onClick={(): void => {
                            navigate(`/timeline/${item?.timelineId}`, {
                                state: {
                                    redirectPath: location.pathname,
                                    deviceDetailsTab: 'Summary',
                                },
                            });
                        }}
                        statusColor={getStatusBackgroundColor(item?.status)}
                        iconAlign="center"
                        subtitle={item?.groupPath?.replaceAll('/', ' < ') || item?.groupName}
                        title={item?.events?.name}
                        divider={'full'}
                        icon={<TimelineStatusIcon key={item?.timelineId} status={item?.status} />}
                        rightComponent={getListItemTag(item)}
                        leftComponent={
                            <Stack direction={'column'}>
                                <Typography variant={'subtitle2'} className="f-14">
                                    {convertDateTime({
                                        timestamp: parseInt(item?.createdAt),
                                        customFormat: 'h:mm',
                                    }) || 'N/A'}
                                    <span style={{ marginLeft: '5px', fontWeight: 400 }}>
                                        {convertDateTime({
                                            timestamp: parseInt(item?.createdAt),
                                            customFormat: 'A',
                                        }) || ''}
                                    </span>
                                </Typography>
                                <Typography variant={'caption'} className="f-12">
                                    {convertDateTime({ timestamp: parseInt(item?.createdAt) }) || 'N/A'}
                                </Typography>
                            </Stack>
                        }
                    />
                ))}
                {getCardActions('View All Events', (): void => props.setActiveTab('Timeline'))}
            </>
        );
    };

    const getOutputOnBtnName = (): string => {
        if (isCommandExistsInLoading('TURN_OFF_DEVICE', '')) {
            return 'Turning Off...';
        } else if (isCommandExistsInLoading('CYCLE_DEVICE', '')) {
            return 'Cycling...';
        }
        return 'Turn Off...';
    };

    const getOutputOnCommandButtons = (): JSX.Element => (
        <>
            <Button
                onClick={(): void => {
                    if (
                        !isCommandExistsInLoading('CYCLE_DEVICE', '') &&
                        !isCommandExistsInLoading('TURN_OFF_DEVICE', '')
                    )
                        setModalState({
                            isOpen: true,
                            command: 'TURN_OFF',
                            type: 'DEVICE',
                            loadId: '',
                        });
                }}
                variant={'contained'}
                className={
                    isCommandExistsInLoading('CYCLE_DEVICE', '')
                        ? 'contained-primary-rounded'
                        : 'contained-danger-rounded'
                }
                {...(isCommandExistsInLoading('TURN_OFF_DEVICE', '') || isCommandExistsInLoading('CYCLE_DEVICE', '')
                    ? { startIcon: <CircularProgress color="inherit" size={20} /> }
                    : { startIcon: <PowerSettingsNew /> })}
            >
                {getOutputOnBtnName()}
            </Button>
            <Button
                variant={'outlined'}
                className="outlined-light-rounded"
                onClick={(): void => {
                    if (!isCommandExistsInLoading('RUN_TEST', '')) handleExecuteDeviceCommand('RUN_TEST', '');
                }}
                {...(isCommandExistsInLoading('RUN_TEST', '')
                    ? { startIcon: <CircularProgress color="inherit" size={20} /> }
                    : { startIcon: <ContentPaste /> })}
            >
                {isCommandExistsInLoading('RUN_TEST', '') ? 'Running Test' : 'Run Test'}
            </Button>
        </>
    );

    const getOutputOffCommandButtons = (): JSX.Element => (
        <>
            <Button
                variant={'contained'}
                className="contained-success-rounded"
                onClick={(): void => {
                    if (!isCommandExistsInLoading('TURN_ON_DEVICE', ''))
                        setModalState({
                            isOpen: true,
                            command: 'TURN_ON',
                            type: 'DEVICE',
                            loadId: '',
                        });
                }}
                {...(isCommandExistsInLoading('TURN_ON_DEVICE', '')
                    ? { startIcon: <CircularProgress color="inherit" size={20} /> }
                    : { startIcon: <PowerSettingsNew /> })}
            >
                {isCommandExistsInLoading('TURN_ON_DEVICE', '') ? 'Turning On' : 'Turn On'}
            </Button>
        </>
    );

    return (
        <>
            {props.deviceStatus?.connectionStatus === 'Online' && canViewCommonds && (
                <AppBar className={classes.contentHeader} position="static" color="primary">
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        {props.deviceStatus?.connectionStatus === 'Online' && (
                            <>
                                {!props.deviceStatus?.outputOff
                                    ? getOutputOnCommandButtons()
                                    : getOutputOffCommandButtons()}
                            </>
                        )}
                    </Stack>
                </AppBar>
            )}
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardHeader} title={getCardHeaderTitle()} />
                        <CardContent className={classes.cardContent}>
                            {isLoading || isFetching || channelsLoading || channelsFetching ? (
                                <>
                                    <HeroBanner py={2} sx={{ justifyContent: 'space-around' }}>
                                        <HeroSkeleton />
                                    </HeroBanner>
                                    <Divider />
                                    <PropertiesCardSkeleton />
                                </>
                            ) : (
                                <>
                                    <HeroBanner py={2}>
                                        {deviceChannelDetails
                                            ?.filter(
                                                (channel: any) =>
                                                    PRIORITY_TAG_NAMES.includes(channel?.name) &&
                                                    props.deviceStatus?.connectionStatus === 'Online'
                                            )
                                            ?.map((channel: any) => (
                                                <Tooltip
                                                    key={channel?.id}
                                                    title={channel?.displayName}
                                                    disableHoverListener={channel?.displayName?.length < 14}
                                                >
                                                    <Hero
                                                        icon={getChannelIcon(channel?.name, channel?.value, true)}
                                                        label={channel?.displayName}
                                                        ChannelValueProps={{
                                                            value: channel?.value ?? '-',
                                                            units:
                                                                channel?.unit || channel?.unit === null
                                                                    ? channel?.unit
                                                                    : '%',
                                                        }}
                                                    />
                                                </Tooltip>
                                            ))}
                                    </HeroBanner>
                                    <Divider />
                                    <InfoListItem
                                        {...INFO_LIST_PROPS}
                                        title={'Status'}
                                        icon={<PowerSettingsNew />}
                                        rightComponent={
                                            <Typography variant={'subtitle1'}>{getDeviceStatus()}</Typography>
                                        }
                                    />
                                    {deviceChannelDetails
                                        ?.filter(
                                            (channel: any) =>
                                                !PRIORITY_TAG_NAMES.includes(channel?.name) &&
                                                props.deviceStatus?.connectionStatus === 'Online'
                                        )
                                        ?.map(
                                            (channel: any) =>
                                                channel.name !== 'presentStatus' && (
                                                    <InfoListItem
                                                        key={channel?.id}
                                                        {...INFO_LIST_PROPS}
                                                        title={channel.displayName}
                                                        icon={getChannelIcon(channel?.name, channel?.value)}
                                                        rightComponent={
                                                            <Typography variant={'subtitle1'}>
                                                                {`${
                                                                    getDeviceChannelDetails(channel.name, 'value') ??
                                                                    '-'
                                                                } `}
                                                                {getDeviceChannelDetails(channel.name, 'unit') && (
                                                                    <Typography component={'span'} fontWeight={300}>
                                                                        {getDeviceChannelDetails(channel.name, 'unit')}
                                                                    </Typography>
                                                                )}
                                                            </Typography>
                                                        }
                                                    />
                                                )
                                        )}
                                </>
                            )}
                        </CardContent>
                        <CardActions
                            className={classes.cardFooter}
                            sx={{
                                borderTop: '0 !important',
                                cursor: 'pointer',
                                '&:hover': {
                                    background: 'rgba(0, 0, 0, 0.09)',
                                },
                            }}
                            onClick={(): void =>
                                navigate(`/device/edit/${deviceId || ''}/details`, {
                                    state: { deviceDetailsTab: 'Summary' },
                                })
                            }
                        >
                            <Stack
                                direction={'row'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                width={'100%'}
                            >
                                {isLoading || isFetching || channelsFetching || channelsLoading ? (
                                    <Skeleton animation="wave">
                                        <Typography variant={'subtitle2'} color={'primary'}>
                                            {'View All Details'}
                                        </Typography>
                                    </Skeleton>
                                ) : (
                                    <>
                                        <Typography
                                            variant={'subtitle2'}
                                            color={'primary'}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {'View All Details'}
                                        </Typography>

                                        <IconButton aria-label="settings">
                                            <NavigateNext
                                                className={classes.rightComponentChevron}
                                                onClick={(): void =>
                                                    navigate(`/device/edit/${deviceId || ''}/details`, {
                                                        state: { deviceDetailsTab: 'Summary' },
                                                    })
                                                }
                                            />
                                        </IconButton>
                                    </>
                                )}
                            </Stack>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardHeader} title={loadCardHeaderTitle()} />
                        <CardContent className={classes.cardContent}>
                            {isFetching || isLoading || isLoadingDeviceLoads || isFetchingDeviceLoads ? (
                                <PropertiesCardSkeleton type="loads" />
                            ) : (
                                <DeviceLoads
                                    deviceLoads={deviceLoads}
                                    infoListProps={INFO_LIST_PROPS}
                                    deviceId={deviceId || ''}
                                    setModalState={setModalState}
                                    isDisabled={!canUpdateLoads}
                                    isCommandExistsInLoading={isCommandExistsInLoading}
                                />
                            )}
                        </CardContent>
                    </Card>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardHeader} title={getTimelineCardHeaderTitle()} />
                        <CardContent className={classes.cardContent}>
                            {isTimeLineLoading || isTimeLineFetching || isLoading || isFetching ? (
                                <TimelineCardSkeleton type="tab" />
                            ) : (
                                getTimelineList()
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card className={classes.card}>
                        <CardHeader className={classes.cardHeader} title={getPropertiesCardHeaderTitle()} />
                        <DeviceProperties
                            infoListProps={INFO_LIST_PROPS}
                            deviceDetails={deviceDetails}
                            isLoading={isFetching || isLoading}
                            convertDateTime={convertDateTime}
                        />
                        {getCardActions('View All Properties', (): void =>
                            navigate(`/device/edit/${deviceId || ''}/properties`, {
                                state: { deviceDetailsTab: 'Summary' },
                            })
                        )}
                    </Card>
                    <Card className="card-wrapper card-head card-spacing" sx={{ marginTop: '16px' }}>
                        <DeviceTrends deviceId={deviceId} />
                        <Divider />
                        {getCardActions('View Full Trendviewer', (): void =>
                            navigate(`/device/edit/${deviceId || ''}/trends`, {
                                state: { deviceDetailsTab: 'Summary' },
                            })
                        )}
                    </Card>
                </Grid>
            </Grid>
            {modalState.isOpen && (
                <>
                    <TurnOnModal
                        isOpen={modalState.command === 'TURN_ON' && modalState.type === 'DEVICE'}
                        title="Turn Device On?"
                        subtitle="Would you like to turn the device on?"
                        handleCloseModal={(): void =>
                            setModalState({ isOpen: false, command: '', type: '', loadId: '' })
                        }
                        handleExecuteDeviceCommand={(command): void => handleExecuteDeviceCommand(command, 'DEVICE')}
                    />
                    <TurnOffModal
                        isOpen={modalState.command === 'TURN_OFF' && modalState.type === 'DEVICE'}
                        title="Turn Device Off?"
                        subtitle="Would you like to turn the device off and on (cycle) or turn the device off?"
                        handleCloseModal={(): void =>
                            setModalState({ isOpen: false, command: '', type: '', loadId: '' })
                        }
                        handleExecuteDeviceCommand={(command): void => handleExecuteDeviceCommand(command, 'DEVICE')}
                    />
                    <TurnOnModal
                        isOpen={modalState.command === 'TURN_ON' && modalState.type === 'LOAD'}
                        title="Turn Load On?"
                        subtitle={`Would you like to turn on load ${getLoadDetails(modalState.loadId)?.number}, ${
                            getLoadDetails(modalState.loadId)?.name
                        }?`}
                        handleCloseModal={(): void =>
                            setModalState({ isOpen: false, command: '', type: '', loadId: '' })
                        }
                        buttonName={'Turn On Load'}
                        handleExecuteDeviceCommand={(command): void => handleExecuteDeviceCommand(command, 'LOAD')}
                    />
                    <TurnOffModal
                        isOpen={modalState.command === 'TURN_OFF' && modalState.type === 'LOAD'}
                        title={'Turn Load Off?'}
                        subtitle={`Would you like to turn off or cycle (turn off and on) load
                        ${getLoadDetails(modalState.loadId)?.number}, ${getLoadDetails(modalState.loadId)?.name}?`}
                        handleCloseModal={(): void =>
                            setModalState({ isOpen: false, command: '', type: '', loadId: '' })
                        }
                        handleExecuteDeviceCommand={(command): void => handleExecuteDeviceCommand(command, 'LOAD')}
                    />
                </>
            )}
        </>
    );
};
