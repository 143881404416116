import React from 'react';

/**
 * The below type represents an object that contains functions and properties related to a confirmation
 * dialog.
 * @property onCancel - A function that will be called when the user cancels the confirmation action.
 * @property onConfirm - The `onConfirm` property is a function that will be called when the user
 * confirms the action in the confirm object.
 * @property onClick - The `onClick` property is a function that takes a parameter `clickedData` of
 * type `any`. It is used to handle the click event when the user interacts with the object.
 * @property {boolean} isVisible - A boolean value indicating whether the confirm object is currently
 * visible or not.
 * @property {any} data - The `data` property is a variable that can hold any type of data. It is used
 * to pass additional information to the `onClick` function when it is called.
 */
type ConfirmObject = {
    onCancel: () => void;
    onConfirm: () => any;
    onClick: (clickedData?: any) => void;
    isVisible: boolean;
    data: any;
};

export const useConfirm = (callback: (arg0: object) => void): ConfirmObject => {
    const [isVisible, toggleIsVisible] = React.useState(false);
    const [data, updateData] = React.useState<object>({});

    /**
     * The function `onConfirm` calls a callback function with some data and then toggles a boolean
     * value to false.
     */
    const onConfirm = (): void => {
        callback(data);
        toggleIsVisible(false);
    };

    /**
     * The `onCancel` function toggles the visibility of something to false.
     */
    const onCancel = (): void => {
        toggleIsVisible(false);
    };

    /**
     * The onClick function updates data and toggles visibility.
     * @param {any} clickedData - The `clickedData` parameter is of type `any`, which means it can
     * accept any data type. It represents the data that is passed when the click event occurs.
     */
    const onClick = (clickedData?: any): void => {
        updateData(clickedData);
        toggleIsVisible(true);
    };

    return {
        onCancel,
        onConfirm,
        onClick,
        isVisible,
        data,
    };
};
