export const ALL_PROTECTED_ROUTES = [
    {
        path: '',
        component: 'ManageDevices',
        title: 'All Groups',
        children: [
            {
                path: 'profile',
                component: 'MyProfile',
                title: 'My Profile',
            },
            {
                path: 'notifications',
                component: 'NotificationPreferences',
                title: 'Notifications',
            },
            {
                path: 'automations',
                component: 'Automations',
                title: 'Automation',
                permissionKey: 'get-all-automation-block',
            },
            {
                path: 'timeline/:timeLineId',
                component: 'ManageDevices',
                subComponent: 'TimeLine',
                title: 'Timeline',
            },
            {
                path: 'users',
                component: 'User',
                title: 'Users',
                permissionKey: 'view-filtered-users',
                children: [
                    {
                        path: 'user/add',
                        component: 'AddUserOrEdit',
                        title: 'Invite User',
                        permissionKey: 'invite-user-to-organization',
                    },

                    {
                        path: 'profile/:id',
                        component: 'MyProfile',
                        title: 'User Profile',
                        permissionKey: 'view-users',
                    },
                    {
                        path: 'user/edit/:userId',
                        component: 'AddUserOrEdit',
                        title: 'Edit User Details',
                        permissionKey: 'update-user',
                    },
                    {
                        path: 'user/approve/:userId',
                        component: 'AddUserOrEdit',
                        title: 'Approve User',
                    },
                ],
            },
            {
                path: 'groups',
                component: 'GroupManagement',
                title: 'Group Management',
                permissionKey: 'view-groups',
                children: [
                    {
                        path: 'group/edit/:groupId',
                        component: 'GroupManagementEditDetails',
                        title: 'Edit Group',
                        permissionKey: 'view-group',
                    },
                ],
            },
            {
                path: 'organization',
                component: 'ManageOrganization',
                title: 'Organization Settings',
                isNoShadowHeader: true,
            },
            {
                path: 'groups/summary/:groupId?',
                component: 'GroupManagement',
                subComponent: 'AllGroupScreen',
            },
            {
                path: 'device/details/:deviceId',
                component: 'DeviceSummary',
                title: 'Device Details',
                isCollapsibleHeader: true,
                permissionKey: 'view-device',
                children: [
                    {
                        path: 'device/edit/:deviceId/:tabId',
                        component: 'DeviceEditProperties',
                        title: '',
                    },
                    {
                        path: 'load-details/:deviceId/:loadId',
                        component: 'DeviceSummary',
                        subComponent: 'LoadDetails',
                        title: '',
                    },
                ],
            },
            {
                path: 'roles',
                component: 'Roles',
                children: [
                    {
                        path: 'organizationAdmin',
                        component: 'OrganizationAdmin',
                    },
                    {
                        path: 'rolesViewer',
                        component: 'RolesViewer',
                    },
                    {
                        path: 'roleCustom',
                        component: 'RoleCustom',
                    },
                ],
            },
        ],
    },
    {
        path: 'auditLogs',
        component: 'AuditLogs',
        permissionKey: 'retrieve all audit logs',
    },
    {
        path: 'devices',
        component: 'ManageDevices',
        title: 'All Groups',
        permissionKey: 'view-devices',
        children: [
            {
                path: 'add-device',
                component: 'AddDevice',
                title: 'Add Device',
                permissionKey: 'create-device',
            },
        ],
    },
    {
        path: 'accept/invite',
        component: 'AcceptEULA',
        hideDrawer: true,
    },
];
