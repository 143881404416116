import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

/**
 * The below type defines the props for a table pagination component in a TypeScript React application.
 * @property {number} page - The current page number.
 * @property goToNextPage - A function that is called when the user wants to navigate to the next page
 * of the table.
 * @property goToPrevPage - A function that is called when the user wants to navigate to the previous
 * page of the table.
 * @property goToFirstPage - A function that will be called when the user wants to navigate to the
 * first page of the table.
 * @property goToLastPage - A function that will be called when the user wants to navigate to the last
 * page of the table.
 * @property {number} totalPages - The `totalPages` property represents the total number of pages in
 * the table.
 */
type TablePaginationActionsProps = {
    page: number;
    goToNextPage: () => void;
    goToPrevPage: () => void;
    goToFirstPage: () => void;
    goToLastPage: () => void;
    totalPages: number;
};

/* The code is defining a functional component called `TablePaginationActions` that takes in a set of
props of type `TablePaginationActionsProps`. */
export const TablePaginationActions = (props: TablePaginationActionsProps): JSX.Element => {
    const theme = useTheme();
    const { page, totalPages, ...remainingProps } = props;

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton onClick={remainingProps?.goToFirstPage} disabled={page === 0} aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={remainingProps?.goToPrevPage} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={remainingProps?.goToNextPage} disabled={page + 1 >= totalPages} aria-label="next page">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={remainingProps?.goToLastPage} disabled={page + 1 >= totalPages} aria-label="last page">
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
};
