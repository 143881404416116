import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * The `PageNotFound` component redirects to the home page if the current path is not the root path.
 */
export const PageNotFound = (): JSX.Element => {
    const navigate = useNavigate();

    /* The `useEffect` hook is used to perform side effects in a functional component. In this case, it
    is used to check if the current path is not the root path ("/") and then redirect the user to
    the home page ("/") using the `navigate` function from the `react-router-dom` library. */
    useEffect(() => {
        if (location.pathname !== '/') {
            navigate('/');
        }
    }, [location]);

    return <></>;
};
