import React, { useState, useEffect } from 'react';
import {
    Button,
    Card,
    Container,
    Grid,
    TextField,
    Typography,
    Stack,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    useMediaQuery,
    CardContent,
    CardActions,
    Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useStyles } from './styles';
import Hierarchy from '../../components/Hierarchy';
import { RefetchConfigOptions } from '@reduxjs/toolkit/dist/query/core/apiState';
import { useLocation, useNavigate, useParams } from 'react-router';
import { handleWhiteSpaces } from '../../CommonUtils';
import { AutoCompleteTags, DiscardModal, ErrorModal } from './index';
import { BackdropLoader } from '../../components/BackdropLoader';
import { useCleanPayload, useGetHierarchyHandlers } from '../../hooks';
import { DEVICE_FIELDS_LIMIT, TAGS_LIMIT, TAG_LIMIT, guidRegex } from '@fiji/common/src/constants';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useCreateTagMutation, useGetTagsQuery } from '@fiji/common/src/features/tagManagement/tagApi';
import {
    useCreateDeviceMutation,
    useEditDeviceMutation,
    useGetDeviceTypesQuery,
    useGetDevicesQuery as useValidGuidQuery,
    useGetDeviceNameMutation,
} from '@fiji/common/src/features/deviceManagement/deviceApi';
import { setToastifyContent } from '@fiji/common/src/features/common/commonSlice';
import { Device } from '@fiji/common/src/types/Device';
import { selectHierarchyData } from '@fiji/common/src/features/group/groupSlice';

const initialDevicePayload = {
    description: '',
    deviceName: '',
    deviceTypeId: '',
    groupId: '',
    tagIds: [],
    guid: '',
};

const DeviceActions = ({
    devicePayload,
    selectedGroups,
    errorMessage,
    isGUIDError,
    isGUIDLoading,
    submitHandler,
    handleDiscardChanges,
}: {
    devicePayload: any;
    selectedGroups: any;
    deviceTagsList: any;
    errorMessage: any;
    isGUIDError: boolean;
    isGUIDLoading: boolean;
    submitHandler: (props: any) => any;
    handleDiscardChanges: () => void;
}): JSX.Element => {
    const theme = useTheme();
    const { deviceId } = useParams();
    const classes = useStyles(theme);
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const disableHandler = (): boolean => {
        if (
            devicePayload.deviceName === '' ||
            devicePayload.deviceTypeId === '' ||
            devicePayload.guid === '' ||
            selectedGroups === '' ||
            isGUIDError ||
            isGUIDLoading ||
            errorMessage.guid !== '' ||
            !handleWhiteSpaces([devicePayload.deviceName, devicePayload?.description, devicePayload?.guid])
        ) {
            return true;
        }
        return false;
    };

    return (
        <CardActions className={classes.cardFooter}>
            {sm ? null : (
                <Stack spacing={2} direction={'column'} sx={{ width: '100%' }}>
                    <Button variant={'contained'} onClick={submitHandler}>
                        Save Device
                    </Button>
                    <Button id="save" variant={'outlined'}>
                        Save & Add New Device
                    </Button>
                    <Button variant={'outlined'} onClick={handleDiscardChanges}>
                        Cancel
                    </Button>
                </Stack>
            )}
            {smDown ? null : (
                <Grid container>
                    <Grid item xs={3}>
                        <Button variant={'outlined'} onClick={handleDiscardChanges}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={9}>
                        <Stack spacing={2} direction={'row'} justifyContent={'flex-end'}>
                            {!deviceId && (
                                <Button
                                    variant={'outlined'}
                                    disabled={disableHandler()}
                                    id="save"
                                    onClick={submitHandler}
                                >
                                    Save & Add New Device
                                </Button>
                            )}

                            <Button variant={'contained'} disabled={disableHandler()} onClick={submitHandler}>
                                {deviceId ? 'Update Device' : 'Save Device'}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            )}
        </CardActions>
    );
};

const handleSetPayload = (
    e: any,
    errorMessage: any,
    setValidType: (props: any) => any,
    setErrorMessage: (props: any) => any,
    setDevicePayload: (props: any) => any
): void => {
    let key: string = e.target.id;

    if (key === 'guid' && guidRegex.test(e.target.value)) {
        setValidType('guid');
        setErrorMessage({ ...errorMessage, [key]: '' });
    }

    if (e.target.name) {
        key = e.target.name;
    }
    if (e.target.value.length > DEVICE_FIELDS_LIMIT[key]) {
        return;
    }
    setDevicePayload((prev: any) => ({ ...prev, [key]: e.target.value }));
};

const blurChangeHandler = (
    devicePayload: any,
    id: any,
    errorMessage: any,
    deviceList: any,
    setErrorMessage: (props: any) => any
): void => {
    for (const key in devicePayload) {
        if (key === id) {
            if (
                (devicePayload[key] && devicePayload[key]?.length < DEVICE_FIELDS_LIMIT[key]) ||
                (id === 'guid' && !guidRegex.test(devicePayload?.[key]))
            ) {
                setErrorMessage({ ...errorMessage, [key]: `Please enter a valid ${id.toUpperCase()}.` });
            } else if (!deviceList?.data || !errorMessage[key]) setErrorMessage({ ...errorMessage, [key]: '' });
        }
    }
};

const handleDeviceSuccessError = (props: any): void => {
    if (props?.deviceError) {
        props?.setErrorSavingModal(true);
        props?.setResponseError(props?.deviceError?.data?.errorMessage);
    }
    if (props?.deviceSuccess) {
        props?.dispatch(
            setToastifyContent({
                isOpen: true,
                message: `Device added successfully`,
                duration: 3000,
            })
        );
        if (props?.saveType === 'save') {
            props?.setDevicePayload({
                ...initialDevicePayload,
                groupId: props?.devicePayload.groupId,
            });
        } else {
            props?.navigate('/devices', { state: 'Devices' });
        }
    }
};

const getLimitText = (value: any, limit: any): string => `${value || 0}/${limit}`;
const discardChangeHandler = (
    devicePayload: any,
    state: string,
    navigate: (rout: string, state: any) => any,
    setDiscardChangesModal: (props: any) => any
): void => {
    if (JSON.stringify(initialDevicePayload) === JSON.stringify(devicePayload)) {
        navigate('/', { state });
    } else {
        setDiscardChangesModal(true);
    }
};

let isEnteredTagValue = false;

const keyDownHandler = async (
    e: any,
    devicePayload: any,
    deviceTagsList: any,
    createDeviceTag: (props: any) => any,
    refetchDeviceTags: () => any,
    setDevicePayload: (props: any) => any
): Promise<void> => {
    if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
        isEnteredTagValue = true;
    } else if (e.code !== 'Enter' && e.code !== 'ArrowDown' && e.code !== 'ArrowUp') {
        isEnteredTagValue = false;
    }
    if (e.code !== 'Enter' || !devicePayload.tagIds || devicePayload.tagIds.length >= TAGS_LIMIT) {
        return;
    }
    const filterdTagIds =
        deviceTagsList
            ?.filter((device: any) => !devicePayload?.tagIds?.includes(device.name))
            .map((device: any) => device.name) ?? [];
    const value: string = e?.target?.value;
    if (filterdTagIds?.includes(value)) {
        const updatedTagIds = [...devicePayload.tagIds, e.target.value];
        const updatedDevicePayload = { ...devicePayload, tagIds: updatedTagIds };
        setDevicePayload(updatedDevicePayload);
    } else if (e?.target?.value?.length && !isEnteredTagValue) {
        const { error }: any = await createDeviceTag({ name: e.target.value });
        if (!error && e.target.value) {
            const updatedTagIds = [...devicePayload.tagIds, value];
            const updatedDevicePayload = { ...devicePayload, tagIds: updatedTagIds };
            setDevicePayload(updatedDevicePayload);
            await refetchDeviceTags();
        }
    }
};

const handleTagListLimitExceeded = (values: any, setDevicePayload: (props: any) => any): void => {
    if (values?.length > TAGS_LIMIT) {
        return;
    }
    setDevicePayload((prev: any) => ({ ...prev, tagIds: values }));
};

const handleTagLimitExceeded = (e: any, setInputTagValue: (props: any) => any): void => {
    if (e?.target?.value?.length > TAG_LIMIT) {
        return;
    }
    setInputTagValue(e?.target?.value ?? '');
};

export const AddDevice = React.forwardRef((props: any, ref): JSX.Element => {
    const { state } = useLocation();
    const { deviceId } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const classes = useStyles(theme);
    const currentOrg = useTypedSelector(selectedOrg);

    const [devicePayload, setDevicePayload] = useState<Partial<Device>>({
        ...initialDevicePayload,
        groupId: state?.groupId || '',
    });
    const [responseError, setResponseError] = useState<any[]>([]);
    const [errorSavingModal, setErrorSavingModal] = useState(false);
    const [inputTagValue, setInputTagValue] = useState('');
    const [discardChangesModal, setDiscardChangesModal] = useState(false);
    const [saveType, setSaveType] = useState('');
    const [errorMessage, setErrorMessage] = useState<{
        [key: string]: string;
        guid: string;
    }>({
        guid: '',
    });
    const [openSelectBox, setOpenSelectBox] = useState(false);
    const [validType, setValidType] = useState<string>('guid');

    const hierarchyData = useTypedSelector(selectHierarchyData);

    const {
        data: deviceTypesList,
        isLoading: isDeviceTypesLoading,
        isFetching: isDeviceTypesFetching,
    } = useGetDeviceTypesQuery<{
        data: any;
        isLoading: boolean;
        isFetching: boolean;
    }>();

    const {
        data: deviceTagsList,
        isLoading: isDeviceTagLoading,
        isFetching: isDeviceTagFetching,
        refetch: refetchDeviceTags,
    } = useGetTagsQuery<{
        data: any;
        isLoading: boolean;
        isFetching: boolean;
        refetch: RefetchConfigOptions;
    }>({ maxVar: '100', offset: '0' });

    const {
        data: deviceList,
        isFetching: isGUIDFetching,
        isError: isGUIDError,
    } = useValidGuidQuery<{
        data: any;
        isLoading: boolean;
        isFetching: boolean;
        isError: boolean;
        refetch: RefetchConfigOptions;
    }>(
        { [validType]: devicePayload[validType] },
        {
            skip: devicePayload?.[validType]?.length !== DEVICE_FIELDS_LIMIT[validType],
            refetchOnMountOrArgChange: true,
        }
    );

    const [createDeviceTag] = useCreateTagMutation();
    const [editDevice] = useEditDeviceMutation();
    const [createDevice, { isLoading: isDeviceCreated, error: deviceError, data: deviceSuccess }]: any =
        useCreateDeviceMutation();

    const { selectedGroups, groupSelectionHandler, getSelectedGroupLabel } = useGetHierarchyHandlers({
        valueType: 'string',
        initialValue: state?.groupId || hierarchyData?.data?.data?.data[0]?.id,
    });

    const [cleanPayload] = useCleanPayload();
    const [getName, { data: deviceNameData, isLoading: isDeviceNameLoading }] = useGetDeviceNameMutation();

    useEffect(() => {
        if (deviceNameData)
            setDevicePayload((prev: Partial<Device>) => ({
                ...prev,
                deviceName: deviceNameData?.data?.deviceName ?? '',
            }));
    }, [deviceNameData]);

    useEffect(() => {
        if (devicePayload?.deviceTypeId && selectedGroups) {
            getDeviceName() as any;
        }
    }, [devicePayload?.deviceTypeId, selectedGroups]);

    const getDeviceName = async (): Promise<void> => {
        await getName({
            groupId: selectedGroups,
            deviceTypeId: devicePayload?.deviceTypeId,
        });
    };

    /* The below code is a TypeScript React component that uses the `useDebounce` hook to debounce a
    handler function. */

    /* The below code is using the `useEffect` hook in a TypeScript React component. It is setting up a
    side effect that will be triggered whenever the `currentOrg` variable changes. */
    useEffect(() => {
        if (currentOrg?.id) {
            refetchDeviceTags()
                .then(() => {})
                .catch(() => {});
        }
    }, [currentOrg]);

    /* The below code is a useEffect hook in a TypeScript React component. It is triggered whenever the
    `deviceList` dependency changes. */
    useEffect(() => {
        const isExistingDevice = deviceList?.data && deviceList?.data?.isExistingDevice;
        const isNotValidGuid = deviceList?.data && !deviceList?.data?.isValidGuid;
        if (isExistingDevice && !isNotValidGuid) {
            setErrorMessage((prev: any) => ({
                ...prev,
                [validType]: `A device with this ${validType} has already been added to the system.`,
            }));
        } else if (isNotValidGuid) {
            setErrorMessage((prev: any) => ({
                ...prev,
                [validType]: `Please enter a valid ${validType?.toUpperCase?.()}.`,
            }));
        } else {
            setErrorMessage((prev: any) => ({
                ...prev,
                [validType]: '',
            }));
        }
    }, [deviceList]);

    /* The below code is using the useEffect hook in a React component. It is listening for changes in
    the variables `deviceError`, `deviceSuccess`, and `saveType`. */
    useEffect(() => {
        handleDeviceSuccessError({
            deviceError: deviceError,
            devicePayload: devicePayload,
            deviceSuccess: deviceSuccess,
            saveType: saveType,
            setDevicePayload: setDevicePayload,
            setErrorSavingModal: setErrorSavingModal,
            setResponseError: setResponseError,
            navigate: navigate,
            dispatch: dispatch,
        });
    }, [deviceError, deviceSuccess, saveType]);

    /**
     * The function `handleGroupSelect` takes an `id` parameter, calls the `groupSelectionHandler`
     * function with the `id`, and sets the `openSelectBox` state to `false`.
     * @param {string} id - The `id` parameter is a string that represents the identifier of the
     * selected group.
     */
    const handleGroupSelect = (id: string): void => {
        groupSelectionHandler(id);
        setOpenSelectBox(false);
    };

    /**
     * The `payloadHandler` function updates the `devicePayload` state based on the value of the target
     * element, and also triggers a debounce handler for specific target IDs.
     * @param {any} e - The parameter `e` is an event object that is passed to the `payloadHandler`
     * function. It contains information about the event that triggered the function, such as the
     * target element, the event type, and any additional data associated with the event.
     * @returns The function `payloadHandler` returns `void`, which means it does not return any value.
     */
    const payloadHandler = (e: any): void => {
        handleSetPayload(e, errorMessage, setValidType, setErrorMessage, setDevicePayload);
    };

    /**
     * The function `selectedTagsHandler` updates the `tagIds` property in the `devicePayload` state
     * based on the selected tags.
     * @param {any} e - The parameter "e" is an event object that represents the event that triggered
     * the selectedTagsHandler function. It is typically used to access information about the event,
     * such as the target element or the event type.
     * @param {any} values - The `values` parameter is an array that contains the selected tags.
     * @returns If the length of `values` is greater than `TAGS_LIMIT`, nothing is being returned.
     * Otherwise, the `setDevicePayload` function is being called with an updated `tagIds` property in
     * the `prev` object.
     */
    const selectedTagsHandler = (e: any, values: any): void => {
        handleTagListLimitExceeded(values, setDevicePayload);
    };

    /**
     * The function `inputTagHandler` limits the length of the input value and updates the state with
     * the new value.
     * @param {any} e - The parameter `e` is an event object that is passed to the `inputTagHandler`
     * function. It is typically an event object that is triggered when an input tag's value changes.
     * @returns If the length of the value in the input tag is greater than the TAG_LIMIT, then nothing
     * is returned. Otherwise, the value of the input tag is set as the input tag value.
     */
    const inputTagHandler = (e: any): void => {
        handleTagLimitExceeded(e, setInputTagValue);
    };

    /**
     * The function `handleKeyDown` is an asynchronous function that is triggered when a key is
     * pressed, and if the pressed key is the Enter key and certain conditions are met, it creates a
     * new device tag and updates the device payload.
     * @param {any} e - The parameter `e` is an event object that represents the keydown event. It
     * contains information about the event, such as the key code and target element.
     */
    const handleKeyDown = async (e: any): Promise<void> => {
        await keyDownHandler(
            e,
            devicePayload,
            deviceTagsList?.data?.records,
            createDeviceTag,
            refetchDeviceTags,
            setDevicePayload
        );
    };
    /**
     * The submitHandler function is used to handle form submission in a TypeScript React component,
     * updating or creating a device based on the form data.
     * @param {any} e - The parameter `e` is an event object that is passed to the `submitHandler`
     * function. It is typically an object that contains information about the event that triggered the
     * function, such as the target element that was clicked or the form that was submitted. In this
     * case, it is being used to
     */
    const submitHandler = async (e: any): Promise<void> => {
        setSaveType(e.target.id);
        const payload = {
            ...devicePayload,
            groupId: selectedGroups,
            tagIds: deviceTagsList?.data?.records
                ?.filter((tag: any) => devicePayload.tagIds?.includes(tag.name))
                .map((tag: any) => tag.id),
        };

        const cleanedPayload = cleanPayload(payload);
        if (deviceId) await editDevice({ payload: cleanedPayload, id: deviceId });
        else await createDevice(payload);
    };

    /**
     * The function `handleTagDelete` is used to remove a tag from an array of tag IDs in a device
     * payload.
     * @param {any} data - The `data` parameter is of type `any`, which means it can be any data type.
     * It is not specified what kind of data it represents in this context.
     * @param {number} index - The `index` parameter is the index of the element in the `tagIds` array
     * that needs to be deleted.
     */
    const handleTagDelete = (data: any, index: number): void => {
        const devicePayloadClone = JSON.parse(JSON.stringify(devicePayload));
        devicePayloadClone.tagIds.splice(index, 1);
        setDevicePayload(devicePayloadClone);
    };

    /**
     * The function `disableHandler` returns `true` if any of the specified conditions are met,
     * otherwise it returns `false`.
     * @returns The function `disableHandler` returns a boolean value.
     */

    /**
     * The function `handleToggleSelectBox` toggles the state of `openSelectBox` between `true` and
     * `false`.
     */
    const handleToggleSelectBox = (): void => {
        setOpenSelectBox((prev) => !prev);
    };

    /**
     * The function checks if the initial device payload is equal to the current device payload and
     * navigates to a different page if they are, otherwise it sets a discard changes modal to true.
     */
    const handleDiscardChanges = (): void => {
        discardChangeHandler(devicePayload, state, navigate, setDiscardChangesModal);
    };

    React.useImperativeHandle(ref, () => ({
        handleDiscardChanges: handleDiscardChanges,
    }));

    /**
     * The function `handleBlurChange` is used to handle blur events on input fields and update error
     * messages based on the input values.
     * @param e - The parameter `e` is of type `React.FocusEvent<HTMLInputElement>`, which represents
     * the event object for the blur event on an input element. It contains information about the
     * event, such as the target element and any associated data.
     * @param {string} id - The `id` parameter is a string that represents the id of the input field
     * that triggered the blur event.
     */
    const handleBlurChange = (e: React.FocusEvent<HTMLInputElement>, id: string): void => {
        blurChangeHandler(devicePayload, id, errorMessage, deviceList, setErrorMessage);
    };

    return (
        <React.Fragment>
            <header>
                <title>AddDevice</title>
                <meta name="description" content="Description of AddDevice" />
            </header>

            <Container fixed className={classes.ContainerWrapper}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardBody}>
                        <Typography variant={'h6'} className={classes.sectionHeader}>
                            {`Add Device to ${currentOrg?.name}`}
                        </Typography>
                        <Typography variant={'body2'} className={classes.sectionHeader}>
                            {'Fields marked with an asterisk(*) are required when adding a Device.'}
                        </Typography>
                        <FormControl variant={'filled'} className={classes.formControl} required fullWidth>
                            <InputLabel id={'select-Assign-to-Group'} required={false}>
                                Assign to Group(s)
                            </InputLabel>
                            <Select
                                displayEmpty
                                id="groupId"
                                labelId="demo-customized-select-label"
                                disabled={hierarchyData?.isLoading}
                                renderValue={(): any => getSelectedGroupLabel(hierarchyData?.data?.data?.data)}
                                value={getSelectedGroupLabel(hierarchyData?.data?.data?.data) ? ' ' : ''}
                                open={openSelectBox}
                                onClose={handleToggleSelectBox}
                                onOpen={handleToggleSelectBox}
                            >
                                <Hierarchy
                                    key="fe2f21fFbta"
                                    selectedNodes={selectedGroups}
                                    onChangeTreeItem={handleGroupSelect}
                                    hierarchyData={hierarchyData?.data?.data?.data}
                                />
                            </Select>
                        </FormControl>
                        <FormControl variant={'filled'} className={classes.formControl} required fullWidth>
                            <InputLabel id={'select-device-type'} required={true}>
                                Device Type
                            </InputLabel>
                            <Select
                                disabled={isDeviceTypesFetching || isDeviceTypesLoading}
                                labelId={'select-device-type'}
                                value={devicePayload?.deviceTypeId ?? ''}
                                name="deviceTypeId"
                                onChange={payloadHandler}
                            >
                                {deviceTypesList?.data?.records.map((device: any) => (
                                    <MenuItem key={`key${device.id}`} value={device?.id}>
                                        {device?.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            className={classes.formControl}
                            value={devicePayload?.deviceName}
                            id="deviceName"
                            required
                            label={'Device Name'}
                            fullWidth
                            variant={'filled'}
                            onChange={payloadHandler}
                            disabled={isDeviceNameLoading}
                            sx={{ marginBottom: '0 !important' }}
                        />
                        <Typography variant="body2" className="custom-add-device-field-lengths-style">
                            {getLimitText(devicePayload?.deviceName?.length, DEVICE_FIELDS_LIMIT['deviceName'])}
                        </Typography>

                        <TextField
                            id="guid"
                            label={'GUID'}
                            required
                            value={devicePayload?.guid}
                            onChange={payloadHandler}
                            fullWidth
                            onBlur={(e: React.FocusEvent<HTMLInputElement>): void => handleBlurChange(e, 'guid')}
                            sx={{ marginBottom: '0 !important' }}
                            variant={'filled'}
                            {...(errorMessage?.guid && { error: true, helperText: errorMessage?.guid })}
                        />
                        <Typography variant="body2" className="custom-add-device-field-lengths-style">
                            {getLimitText(devicePayload?.guid?.length, DEVICE_FIELDS_LIMIT['guid'])}
                        </Typography>

                        <AutoCompleteTags
                            value={devicePayload?.tagIds}
                            inputValue={inputTagValue}
                            onInputChange={inputTagHandler}
                            disabled={isDeviceTagFetching || isDeviceTagLoading}
                            onChange={selectedTagsHandler}
                            options={
                                deviceTagsList?.data.records
                                    .filter((device: any) => !devicePayload?.tagIds?.includes(device.name))
                                    .map((device: any) => device.name) ?? []
                            }
                            handleTagDelete={handleTagDelete}
                            onKeyDown={handleKeyDown}
                        />
                        <Box className="modal-space-between">
                            <Typography
                                variant="body2"
                                sx={{ paddingLeft: '16px' }}
                                className="custom-add-device-field-lengths-style"
                            >
                                {`${TAGS_LIMIT} tags maximum`}
                            </Typography>
                            <Typography variant="body2" className="custom-add-device-field-lengths-style">
                                {getLimitText(inputTagValue?.length, TAG_LIMIT)}
                            </Typography>
                        </Box>
                        <TextField
                            value={devicePayload?.description}
                            id="description"
                            label={'Description'}
                            fullWidth
                            variant={'filled'}
                            onChange={payloadHandler}
                            sx={{ marginBottom: '0 !important' }}
                        />
                        <Typography
                            variant="body2"
                            sx={{ marginBottom: '0 !important' }}
                            className="custom-add-device-field-lengths-style"
                        >
                            {getLimitText(devicePayload?.description?.length, DEVICE_FIELDS_LIMIT['description'])}
                        </Typography>
                    </CardContent>
                    <DeviceActions
                        devicePayload={devicePayload}
                        selectedGroups={selectedGroups}
                        deviceTagsList={deviceTagsList}
                        errorMessage={errorMessage}
                        isGUIDError={isGUIDError}
                        isGUIDLoading={isGUIDFetching}
                        submitHandler={submitHandler}
                        handleDiscardChanges={handleDiscardChanges}
                    />
                </Card>
            </Container>
            <ErrorModal
                key="ade31c1f"
                retryHandler={submitHandler}
                isVisible={errorSavingModal}
                messages={responseError}
                modalHandler={(action: boolean): void => setErrorSavingModal(action)}
                clearPayload={(): void => setDevicePayload(initialDevicePayload)}
                deviceId={deviceId}
            />
            <DiscardModal
                key="dr4e14da"
                isVisible={discardChangesModal}
                state={state}
                modalHandler={(action: boolean): void => setDiscardChangesModal(action)}
            />
            {isDeviceCreated && <BackdropLoader isOpen={true} />}
        </React.Fragment>
    );
});
