import { api } from '../../app/api/baseApi';
export const mqttApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getMQTTCredentials: builder.mutation<object, any>({
            query: (body) => ({
                url: `/dm/v1/broker/acl/client`,
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const { useGetMQTTCredentialsMutation } = mqttApi;
