import moment from 'moment-timezone';

const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const useTransformDatTime = (profile: any): any => {
    const convertDateTime = ({ timestamp, customFormat }: { timestamp: number; customFormat?: string }): any =>
        moment(timestamp)
            .tz(profile?.data?.timezone ?? localTimeZone)
            .format(customFormat ?? profile?.data?.dateFormat ?? 'DD/MM/YYYY');

    return {
        convertDateTime,
    };
};
