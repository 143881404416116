import React from 'react';

/**
 * The `useSelectedIds` function is a custom hook in TypeScript that manages a list of selected IDs and
 * provides functions to update the list based on user selection.
 * @returns The function `useSelectedIds` returns an array containing three elements: `selectedIds`,
 * `setSelectedIds`, and `selectionHandler`.
 */
export const useSelectedIds = (): any[] => {
    const [selectedIds, setSelectedIds] = React.useState<string[]>([]);

    /* The `selectionHandler` function is a callback function that is created using the
    `React.useCallback` hook. It takes an `id` parameter and returns another function that takes an
    `e` parameter of type `React.ChangeEvent<HTMLInputElement>`. */
    const selectionHandler = React.useCallback(
        (id: string) =>
            (e: React.ChangeEvent<HTMLInputElement>): void => {
                const selectedIdsClone = JSON.parse(JSON.stringify({ ids: selectedIds }));
                const uncheckedIndex = selectedIdsClone.ids.indexOf(id);
                if (e.target.checked) {
                    selectedIdsClone.ids.push(id);
                }
                if (!e.target.checked) {
                    selectedIdsClone.ids.splice(uncheckedIndex, 1);
                }
                setSelectedIds(selectedIdsClone.ids);
            },
        [selectedIds]
    );

    return [selectedIds, setSelectedIds, selectionHandler];
};
