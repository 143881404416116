import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Box,
    Avatar,
    Typography,
    useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as Colors from '@brightlayer-ui/colors';
import { Theme, useTheme } from '@mui/material/styles';
import { ChatBubbleOutline } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) => ({
    muiDialogpaper: {
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(100vh - 56px)',
        },
        '& .MuiPaper-root': {
            maxWidth: '450px',
            width: '450px',
            height: '100%',
            maxHeight: '621px',
            overflowY: 'hidden',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                margin: '0px',
                maxHeight: 'none',
                width: '100%',
                borderRadius: 0,
                height: '100%',
            },
        },
    },
    MuiDialogTitleRoot: {
        padding: '62px 24px 0px 24px',
    },
    dialogcontent: {
        minHeight: '334px',
    },
    MuiDialogActionsRoot: {
        padding: '24px',
        borderTop: '1px solid rgba(66, 78, 84, 0.12)',
    },
    avatarIcon: {
        height: '96px',
        width: '96px',
        '& .MuiSvgIcon-root': {
            height: '47px',
            width: '47px',
        },
    },
    avatarBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(5),
    },
    typographyTitle: {
        marginBottom: theme.spacing(2),
        fontSize: '20px',
        color: Colors.black[800],
    },
}));

/* The `NeedHelpScreen` component is a functional component in TypeScript React. It takes in a prop
object with two properties: `isOpen` (a boolean indicating whether the dialog is open) and
`handleCloseModal` (a function to handle closing the dialog). */
const NeedHelpScreen = (props: { isOpen: boolean; handleCloseModal: () => void }): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Dialog
            open={props.isOpen}
            onClose={props.handleCloseModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.muiDialogpaper}
        >
            <DialogTitle id="alert-dialog-title" className={classes.MuiDialogTitleRoot}>
                <Box className={classes.avatarBox}>
                    <Avatar className={classes.avatarIcon}>
                        <ChatBubbleOutline color="primary" />
                    </Avatar>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1" className={classes.typographyTitle}>
                    General Questions
                </Typography>
                <Typography variant="body1" mb={5}>
                    For feedback, questions, or support please email us at{' '}
                    <Box
                        sx={{ cursor: 'pointer' }}
                        component={'span'}
                        color={'#268FCA'}
                        onClick={(): void => {
                            window.location.href = 'mailto:BLSupport@eaton.com';
                        }}
                    >
                        BLSupport@eaton.com
                    </Box>
                    .
                </Typography>
                <Typography variant="subtitle1" className={classes.typographyTitle}>
                    Emergency Support
                </Typography>
                <Typography variant="body1" mb={5}>
                    For immediate support during business hours,
                    <br />
                    <Box
                        component={'span'}
                        sx={{ cursor: 'pointer' }}
                        color={'#268FCA'}
                        onClick={(): void => {
                            window.location.href = 'tel:1-877-ETN-CARE';
                        }}
                    >
                        1-877-ETN-CARE
                    </Box>
                    , Option 2, Option 9.
                </Typography>
            </DialogContent>
            <DialogActions className={classes.MuiDialogActionsRoot}>
                {smDown ? null : (
                    <Button variant={'contained'} sx={{ width: '100%' }} onClick={props.handleCloseModal}>
                        Close
                    </Button>
                )}
                {sm ? null : (
                    <Button variant={'outlined'} onClick={props.handleCloseModal}>
                        Close
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default NeedHelpScreen;
