/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export type NotificationModal = {
    isOpen: boolean;
    notificationId?: string;
};

const initialNotificationModalState: NotificationModal = {
    isOpen: false,
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        addNotificationModal: initialNotificationModalState,
    },
    reducers: {
        addNotificationModal: (state, { payload }: PayloadAction<NotificationModal>) => {
            state.addNotificationModal = payload;
        },
    },
});

export default notificationSlice;
export const { addNotificationModal } = notificationSlice.actions;

export const selectAddNotificationModal = (state: RootState): NotificationModal =>
    state.notification.addNotificationModal;
