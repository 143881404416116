import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import NeedHelpScreen from '../../components/NeedHelpScreen';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import { stylesClasses } from './styles';

/* eslint-disable @typescript-eslint/consistent-type-definitions */
declare global {
    interface Window {
        opera: any;
        MSStream: any;
    }
}
/* eslint-enable @typescript-eslint/consistent-type-definitions */

const ANDROID_APP_URL = (window as any).APP_CONFIG.ANDROID_APP_URL;
const IOS_APP_URL = (window as any).APP_CONFIG.IOS_APP_URL;
const DEFAULT_HELP_URL =
    'https://www.eaton.com/us/en-us/catalog/backup-power-ups-surge-it-power-distribution/tripp-lite-by-eaton-ups/tripp-lite-series-cloud-ups.html';

export const QrDetails = (): JSX.Element => {
    const [isContactScreenOpen, setIsContactScreenOpen] = useState(false);
    const policyRef = React.useRef<any>(null);
    const [isAndroid, setIsAndroid] = useState(false);
    const [isIOS, setIsIOS] = useState(false);
    const [isWideScreen, setIsWideScreen] = useState(false);

    const clickMobileAppHandler = (e: any): void => {
        if (isAndroid) {
            e.preventDefault();
            window.location.href = ANDROID_APP_URL;
        } else if (isIOS) {
            e.preventDefault();
            window.location.href = IOS_APP_URL;
        } else {
            // default case
            e.preventDefault();
            window.location.href = ANDROID_APP_URL;
        }
    };

    useEffect(() => {
        const checkScreenSize = (): any => {
            setIsWideScreen(window.innerWidth >= 960);
        };
        checkScreenSize();
        const resizeListener = (): any => {
            checkScreenSize();
        };

        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent)) {
            setIsAndroid(true);
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setIsIOS(true);
        } else {
            setIsAndroid(true);
        }
    }, []);

    return (
        <>
            <Container
                sx={{
                    ...stylesClasses.responsiveStylesContainer,
                    padding: 0,
                    justifyContent: 'center',
                    // alignItems: 'center',
                    display: 'flex',
                    minHeight: '100vh',
                    minWidth: '100%',
                    // backgroundColor: '#EEF0F0',
                }}
            >
                <Card sx={{ ...stylesClasses.responsiveStyles, borderRadius: 0 }}>
                    <CardMedia
                        component="img"
                        alt="qrDetailHeader image"
                        image={
                            isWideScreen
                                ? require('../../assets/images/qrDetailHeader.svg').default
                                : require('../../assets/images/qrDetailHeaderMobile.svg').default
                        }
                    />
                    <CardContent sx={{ ...stylesClasses.responsiveStylesCardContent }}>
                        <Typography fontSize={14} fontWeight={400} marginBottom={2}>
                            To provision your device, you can either download the app or launch it in your web browser.
                        </Typography>
                        <Stack spacing={3} marginBottom={5}>
                            <Button
                                disableElevation
                                variant="contained"
                                sx={{ textTransform: 'none' }}
                                onClick={clickMobileAppHandler}
                                startIcon={<AppShortcutIcon />}
                            >
                                Download the Mobile Application
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{ textTransform: 'none', borderColor: 'rgba(66, 78, 84, 0.12)' }}
                                onClick={(e): any => {
                                    e.preventDefault();
                                    window.open(window.location.origin, '_blank');
                                }}
                                startIcon={<OpenInBrowserIcon />}
                            >
                                Launch the Application in a browser
                            </Button>
                        </Stack>

                        <Stack spacing={1}>
                            <Button
                                variant="text"
                                sx={{ textTransform: 'none' }}
                                onClick={(e): any => {
                                    e.preventDefault();
                                    window.open(DEFAULT_HELP_URL, '_blank');
                                }}
                            >
                                Explore Eaton-Brightlayer Enabled UPS Line
                            </Button>
                            <Button
                                variant="text"
                                sx={{ textTransform: 'none' }}
                                onClick={(): void => {
                                    setIsContactScreenOpen(true);
                                }}
                            >
                                Need Help?
                            </Button>
                            <Button
                                variant="text"
                                sx={{ textTransform: 'none' }}
                                onClick={(): void => {
                                    policyRef?.current?.modalHandler(true);
                                }}
                            >
                                Privacy Policy
                            </Button>
                        </Stack>
                        <Box display="flex" justifyContent="center" alignItems="center" marginTop={3}>
                            <Box
                                component="img"
                                alt="The house from the offer."
                                src={require('../../assets/images/cybersecurity-certified.svg').default}
                            />
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            <NeedHelpScreen isOpen={isContactScreenOpen} handleCloseModal={(): void => setIsContactScreenOpen(false)} />
            <PrivacyPolicy ref={policyRef} />
        </>
    );
};
