/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { useStyles } from './styles';
import DeleteModal from '../../components/DeleteModal';
import { useDeleteUserMutation } from '@fiji/common/src/features/userManagement/userManagement';
import { useNavigate } from 'react-router-dom';
import { useConfirm } from '../../hooks';
import { useAppDispatch } from '@fiji/common/src/app/store';
import { setToastifyContent } from '@fiji/common/src/features/common/commonSlice';

/**
 * The `DeleteModalProps` type is used in a TypeScript React component and represents the props for a
 * delete modal component.
 * @property {boolean} isDeleteModalVisible - A boolean value indicating whether the delete modal is
 * visible or not.
 * @property modalHandler - The `modalHandler` property is a function that takes a boolean parameter
 * `action` and does something with it. It is typically used to handle the user's action in the delete
 * modal, such as confirming or canceling the deletion.
 * @property {any} data - The `data` property is of type `any` and can hold any type of data. It is
 * used to pass additional data to the delete modal component.
 * @property {string | undefined} userId - The `userId` property is a string or undefined value that
 * represents the unique identifier of a user.
 */
type DeleteModalProps = {
    isDeleteModalVisible: boolean;
    modalHandler: (action: boolean) => void;
    data: any;
    userId: string | undefined;
};

export const DeleteUserModal = (props: DeleteModalProps): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const deleteModalId = React.useId();

    const [deleteUser] = useDeleteUserMutation();

    /**
     * The deleteUserHandler function deletes a user and displays a success message, then navigates to
     * the user page.
     */
    const deleteUserHandler = async (): Promise<void> => {
        const { error }: any = await deleteUser({ userIds: [props.userId] });
        if (!error) {
            closeDeleteModal();
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: 'User has been successfully deleted',
                })
            );
            navigate('/users');
        }
    };

    const {
        isVisible: isDeleteModalOpen,
        onClick: openDeleteModal,
        onCancel: closeDeleteModal,
        onConfirm: confirmDeletion,
    } = useConfirm(deleteUserHandler as any);

    return (
        <>
            <Dialog
                open={props.isDeleteModalVisible}
                onClose={(): void => props.modalHandler(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.muiDialogpaper}
            >
                <DialogTitle id="alert-dialog-title" className={classes.MuiDialogTitleRoot}>
                    <Typography variant={'h6'}>
                        {' '}
                        {`Delete  ${props?.data?.firstName || ''} ${props?.data?.lastName || ''} ?`}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant={'body2'} mb={1}>
                        {`Select 'Continue' to permanently delete ${props?.data?.firstName || ''} ${
                            props?.data?.lastName || ''
                        } and all of their profile information. This will not remove log entries or reverse changes they previously made to the system.`}
                    </Typography>
                </DialogContent>
                <DialogActions className={classes.MuiDialogActionsRoot}>
                    <Stack direction={'row'} spacing={2} justifyContent={'end'}>
                        <Button variant={'outlined'} onClick={(): void => props.modalHandler(false)}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            onClick={(): void => {
                                props.modalHandler(false);
                                openDeleteModal(undefined);
                            }}
                        >
                            Continue
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>

            <DeleteModal
                key={deleteModalId}
                deleteButtonText="Delete User"
                closeHandler={closeDeleteModal}
                confirmClick={confirmDeletion}
                isVisible={isDeleteModalOpen}
                wrapperClass={classes.muiDialogpaper}
                actionClass={classes.MuiDialogActionsRoot}
                titleClass={classes.MuiDialogTitleRoot}
                description={
                    <>
                        {`${props?.data?.firstName || ''} ${props?.data?.lastName || ''} `} and all of their profile
                        information will be permanently deleted.
                    </>
                }
                deleteButtonClass={classes.deleteButton}
                headingText={`Delete ${props?.data?.firstName || ''} ${props?.data?.lastName || ''} ?`}
            />
        </>
    );
};
