import { api } from '../../app/api/baseApi';

const documentsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getDocuments: builder.mutation<object, any>({
            query: (body) => ({
                url: `/dm/v1/documents/filter`,
                body: body,
                method: 'POST',
            }),
        }),
        getDocumentTypes: builder.query<any, any>({
            query: (body) => ({
                url: '/dm/v1/documents/types',
                body: body,
                method: 'POST',
            }),
            providesTags: ['Documents'],
        }),
    }),
});

export const { useGetDocumentsMutation, useGetDocumentTypesQuery } = documentsApi;
