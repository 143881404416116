/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { api } from '../../app/api/baseApi';

export const deviceDetailsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getDeviceChannels: builder.query<object, any>({
            query: ({ deviceId, params }) => ({
                url: `/dm/v1/devices/${deviceId}/details`,
                method: 'GET',
                params,
            }),
        }),
        executeDeviceCommand: builder.mutation<object, any>({
            query: (payload) => ({
                url: `/dm/v1/devices/${payload.id}/command`,
                method: 'POST',
                body: payload.body,
            }),
            transformResponse: (response: any, meta: any, args: any): any => ({
                data: response?.data,
                requestBody: args?.body,
            }),
            transformErrorResponse: (response: any, meta: any, payload): any => ({
                data: response?.data,
                requestBody: payload?.body,
            }),
        }),
        executeRunTest: builder.mutation<object, any>({
            query: (payload) => ({
                url: `/dm/v1/devices/${payload.id}/runTest`,
                method: 'POST',
                body: payload.body,
            }),
        }),
        getAllDeviceLoads: builder.query({
            query: ({ deviceId }) => ({
                url: `/dm/v1/devices/${deviceId}/loads`,
                method: 'GET',
            }),
            providesTags: ['Loads'],
        }),
        getDeviceLoadById: builder.query({
            query: ({ deviceId, loadId }) => ({
                url: `/dm/v1/devices/${deviceId}/loads/${loadId}`,
                method: 'GET',
            }),
            providesTags: ['LoadById'],
        }),
        updateDeviceLoadById: builder.mutation<object, any>({
            query: ({ deviceId, loadId, body }) => ({
                url: `/dm/v1/devices/${deviceId}/loads/${loadId}`,
                method: 'PATCH',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['LoadById', 'Loads'] : []),
        }),
        executeDeviceLoadCommand: builder.mutation<object, any>({
            query: ({ deviceId, loadId, body }) => ({
                url: `/dm/v1/devices/${deviceId}/loads/${loadId}/command`,
                method: 'PATCH',
                body: body,
            }),
            transformResponse: (response: any, meta: any, args: any): any => ({
                data: response?.data,
                requestBody: args?.body,
                loadId: args.loadId,
            }),
            transformErrorResponse: (response: any, meta: any, payload): any => ({
                data: response?.data,
                requestBody: payload?.body,
                loadId: payload.loadId,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Loads'] : []),
        }),

        getTrendsByDeviceId: builder.query<object, any>({
            query: (payload) => ({
                url: `/dm/v1/devices/${payload?.id}/trends`,
                method: 'POST',
                body: payload?.body,
            }),
        }),
        downloadDeviceTrends: builder.mutation<any, any>({
            query: ({ payload, id }: any) => ({
                url: `/dm/v1/devices/${id}/trends/download`,
                method: 'POST',
                body: payload,
            }),
        }),
        getAllChannels: builder.query<object, void>({
            query: () => ({
                url: `/dm/v1/devices/channels`,
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useLazyGetDeviceChannelsQuery,
    useExecuteDeviceCommandMutation,
    useExecuteRunTestMutation,
    useGetAllDeviceLoadsQuery,
    useGetDeviceLoadByIdQuery,
    useUpdateDeviceLoadByIdMutation,
    useExecuteDeviceLoadCommandMutation,
    useGetAllChannelsQuery,
    useGetTrendsByDeviceIdQuery,
    useDownloadDeviceTrendsMutation,
    useGetDeviceChannelsQuery,
} = deviceDetailsApi;
