import { api } from '../../app/api/baseApi';

export const automationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAutomations: builder.query<object, void>({
            query: () => ({
                url: `/iam/v1/automations`,
            }),
            providesTags: ['Automations'],
        }),

        getAutomationsWithFilter: builder.query<object, void>({
            query: (body) => ({
                url: `/iam/v1/automations/filter`,
                body: body,
                method: 'POST',
            }),
            providesTags: ['Automations'],
        }),

        createAutomation: builder.mutation<object, object>({
            query: (body) => ({
                url: `/iam/v1/automations`,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Automations'] : []),
        }),

        updateAutomation: builder.mutation<any, any>({
            query: ({ body, automationId }) => ({
                url: `/iam/v1/automations/${automationId}`,
                method: 'PUT',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Automations'] : []),
        }),
        deleteAutomation: builder.mutation<any, any>({
            query: (body) => ({
                url: `/iam/v1/automations`,
                body: body,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error): any => (!error ? ['Automations'] : []),
        }),
        getAutomationById: builder.query<any, any>({
            query: (automationId) => ({
                url: `/iam/v1/automations/${automationId}`,
                method: 'GET',
            }),
            providesTags: ['Automations'],
            keepUnusedDataFor: 0.00001,
        }),
        editAutomationStatus: builder.mutation<any, any>({
            query: ({ body, automationId }) => ({
                url: `/iam/v1/automations/${automationId}/access`,
                method: 'PATCH',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Automations'] : []),
        }),
    }),
});

export const {
    useGetAutomationsQuery,
    useCreateAutomationMutation,
    useUpdateAutomationMutation,
    useDeleteAutomationMutation,
    useGetAutomationsWithFilterQuery,
    useGetAutomationByIdQuery,
    useEditAutomationStatusMutation,
} = automationApi;
