import './mqtt-patch';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './scss/main.scss';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import * as BLUIThemes from '@brightlayer-ui/react-themes';
import '@brightlayer-ui/react-themes/open-sans';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { BackdropLoader } from './components/BackdropLoader';
import { store } from '@fiji/common/src/app/store';
import { createPortal } from 'react-dom';

const container = document.getElementById('root');
const root = createRoot((container as HTMLElement) || document.createDocumentFragment());

root.render(
    <Provider store={store}>
        <Suspense fallback={<BackdropLoader isOpen={true} />}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={createTheme(BLUIThemes.blue)}>
                    <CssBaseline />
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>

                    {createPortal(<BackdropLoader />, document.body)}
                </ThemeProvider>
            </StyledEngineProvider>
        </Suspense>
    </Provider>
);
