import React from 'react';
import { RegistrationData, useRegistrationUIState } from '@brightlayer-ui/react-auth-workflow';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { AccountCircle } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles(() => ({
    cardHeader: {
        padding: '56px 24px 0 24px',
        '& .MuiCardHeader-avatar': {
            justifyContent: 'center',
            margin: 'auto',
            marginBottom: '32px',
        },
        '& .MuiCardHeader-content': {
            display: 'none',
        },
    },
    cardBody: {
        padding: '16px 24px',
        flex: '1 1 0px',
        '& p': {
            '& b': {
                fontWeight: '600',
            },
        },
    },
}));

/**
 * The below type defines the props for a custom registration component in a TypeScript React
 * application.
 * @property {RegistrationData} registrationData - This property is of type `RegistrationData` and
 * represents the data for a user registration. It is optional, meaning it can be undefined or null.
 * @property {boolean} accountExists - A boolean value indicating whether an account already exists for
 * the user.
 */
type CustomRegistrationProps = {
    registrationData?: RegistrationData;
    accountExists?: boolean;
};

/* The code defines a React functional component called `CustomRegistrationSuccessScreen`. It is
exported as a named export. The component takes in props of type `CustomRegistrationProps`. */
export const CustomRegistrationSuccessScreen: React.FC<React.PropsWithChildren<CustomRegistrationProps>> = (
    props
): JSX.Element => {
    const registrationState = useRegistrationUIState();
    const classes = useStyles();
    return (
        <>
            <CardHeader
                className={classes.cardHeader}
                avatar={<AccountCircle color="primary" sx={{ height: '65px', width: '65px' }} />}
            ></CardHeader>
            <CardContent className={classes.cardBody}>
                <Typography variant={'h6'} mb={2} color={'#2B353A'}>
                    Welcome {(!props.accountExists && props.registrationData?.accountDetails?.firstName) || ''}!
                </Typography>
                {!props.accountExists ? (
                    <Typography variant="body1" mb={3}>
                        Your user account has been successfully created with the email{' '}
                        <b>{registrationState?.inviteRegistration?.email}.</b>
                    </Typography>
                ) : (
                    <Typography variant="body1" mb={3}>
                        Your account has been successfully created. Please log in with your Okta account email and
                        password
                    </Typography>
                )}
            </CardContent>
            <Divider />
            <CardActions sx={{ p: 3, justifyContent: 'flex-end' }}>
                <Button
                    variant={'contained'}
                    disableElevation
                    color={'primary'}
                    sx={{ width: '100%' }}
                    component={Link}
                    to={'/login'}
                >
                    Log In
                </Button>
            </CardActions>
        </>
    );
};

export default CustomRegistrationSuccessScreen;
