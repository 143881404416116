import React from 'react';
import EmailIcon from '@mui/icons-material/Email';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { NotificationType } from '@fiji/common/src/types';
import { Avatar, Box, Divider, Paper, Switch, Typography } from '@mui/material';
import { MailOutline, ChatBubbleOutline, NotificationsActive, WarningSharp, Person, Info } from '@mui/icons-material';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { setToastifyContent } from '@fiji/common/src/features/common/commonSlice';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';

type CardProps = {
    preference: NotificationType;
    data: NotificationType[];
    saveHandler: any;
};

export const NotificationCard = ({ data, preference, saveHandler }: CardProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const currentOrg = useTypedSelector(selectedOrg);

    /* The `renderIcons` function is a helper function that takes a `type` parameter and returns a JSX
 element representing an avatar icon based on the value of `type`. */

    const renderIcons = (type: string): JSX.Element => {
        switch (type) {
            case 'NotificationsActiveIcon':
                return (
                    <Avatar sx={{ backgroundColor: '#CA3C3D' }}>
                        <NotificationsActive sx={{ color: '#fff' }} />
                    </Avatar>
                );
            case 'InfoIcon':
                return (
                    <Avatar sx={{ backgroundColor: '#007bc1', color: '#fff' }}>
                        <Info />
                    </Avatar>
                );

            case 'WarningSharpIcon':
                return (
                    <Avatar sx={{ backgroundColor: '#F0CB2F' }}>
                        <WarningSharp sx={{ color: '#806A00' }} />
                    </Avatar>
                );
            default:
                return (
                    <Avatar>
                        <Person />
                    </Avatar>
                );
        }
    };

    /**
     * The function `payloadHandler` is an asynchronous function that handles the payload for changing
     * notification preferences and saves it.
     * @param {string} type - The `type` parameter is a string that represents the type of notification
     * preference to be updated. It can have values like "sms", "push", or "email".
     * @param {string} preferenceId - The `preferenceId` parameter is a string that represents the ID of
     * a specific notification preference.
     */
    const payloadHandler = async (type: string, preferenceId: string): Promise<void> => {
        const payload: any = { orgIds: [currentOrg?.id], preferences: [] };
        const selectedPreference: any = data
            ?.map?.((item: NotificationType) => ({
                id: item?.id,
                sms: item?.sms,
                push: item?.push,
                email: item?.email,
            }))
            ?.find((notification: any) => notification?.id === preferenceId);
        if (selectedPreference) {
            selectedPreference[type] = !selectedPreference[type];
        }
        payload.preferences[0] = selectedPreference;

        const { error }: any = await saveHandler(payload);
        if (!error) {
            dispatch(setToastifyContent({ isOpen: true, message: 'Notification preferences changed successfully.' }));
        }
    };

    return (
        <>
            <Paper className="paper-margin-16">
                <Box
                    sx={{
                        gap: '16px',
                        padding: '16px',
                    }}
                    className="modal-align-center"
                >
                    {renderIcons(preference?.icon)}
                    <Box>
                        <Typography variant="body1" sx={{ fontSize: '16px', fontWeight: '600', color: '#424E54' }}>
                            {preference?.name}
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: '400', color: '#424E54' }}>
                            {preference?.description}
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <Box
                    className="modal-space-between"
                    sx={{
                        gap: '16px',
                        padding: '16px',
                    }}
                >
                    <Box className="modal-align-center">
                        {preference?.email ? (
                            <EmailIcon sx={{ marginRight: '22px', marginLeft: '10px', color: '#727E84' }} />
                        ) : (
                            <MailOutline sx={{ marginRight: '22px', marginLeft: '10px', color: '#727E84' }} />
                        )}
                        <Box>
                            <Typography variant="body1" className="modal-greyhead-text">
                                Email Notifications
                            </Typography>
                            <Typography variant="body2" className="modal-para">
                                {preference?.email ? 'Enabled' : 'Disabled'}
                            </Typography>
                        </Box>
                    </Box>
                    <Switch
                        id="email"
                        checked={preference?.email}
                        onChange={(): Promise<void> => payloadHandler('email', preference?.id)}
                    />
                </Box>
                <Divider />
                <Box
                    className="modal-space-between"
                    sx={{
                        gap: '16px',
                        padding: '16px',
                    }}
                >
                    <Box className="modal-align-center">
                        {preference?.sms ? (
                            <ChatBubbleIcon sx={{ marginRight: '22px', marginLeft: '10px', color: '#727E84' }} />
                        ) : (
                            <ChatBubbleOutline sx={{ marginRight: '22px', marginLeft: '10px', color: '#727E84' }} />
                        )}
                        <Box>
                            <Typography variant="body1" className="modal-greyhead-text">
                                Text Notifications (SMS)
                            </Typography>
                            <Typography variant="body2" className="modal-para">
                                {preference?.sms ? 'Enabled' : 'Disabled'}
                            </Typography>
                        </Box>
                    </Box>
                    <Switch
                        id="sms"
                        checked={preference?.sms}
                        onChange={(): Promise<void> => payloadHandler('sms', preference?.id)}
                    />
                </Box>
                <Divider />
                {/* <Box
                    className="modal-space-between"
                    sx={{
                        gap: '16px',
                        padding: '16px',
                    }}
                >
                    <Box className="modal-align-center">
                        <PushNotification sx={{ marginRight: '22px', marginLeft: '10px', color: '#727E84' }} />
                        <Box>
                            <Typography variant="body1" className="modal-greyhead-text">
                                Push Notifications
                            </Typography>
                            <Typography variant="body2" className="modal-para">
                                Applies to your current device or browser
                            </Typography>
                        </Box>
                    </Box>
                    <Switch
                        id="push"
                        checked={preference?.push}
                        onChange={(): Promise<void> => payloadHandler('push', preference?.id)}
                    />
                </Box> */}
            </Paper>
        </>
    );
};
