import { EmptyState } from '@brightlayer-ui/react-components';
import { Add, InsertPhoto } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import * as Colors from '@brightlayer-ui/colors';
import React from 'react';

/**
 * The UploadImageProps type is a TypeScript interface for a React component that takes an onChange
 * function and a wrapperClass string as props.
 * @property onChange - The `onChange` property is a function that will be called when the user selects
 * an image to upload. It takes one argument, which is the selected image file.
 * @property {string} wrapperClass - The `wrapperClass` property is a string that represents the CSS
 * class name for the wrapper element of the image upload component.
 */
type UploadImageProps = {
    onChange: (arg0: any) => void;
    wrapperClass: string;
};

/* The code is defining a React functional component called `UploadImage`. It takes in two props:
`onChange` and `wrapperClass`. */
export const UploadImage = (props: UploadImageProps): JSX.Element => (
    <EmptyState
        className={props?.wrapperClass}
        sx={{ position: 'relative' }}
        icon={<InsertPhoto fontSize={'inherit'} sx={{ color: Colors.gray[500] }} />}
        title={'Upload a Photo'}
        description={
            <Stack direction={'column'}>
                <Typography mb={1} variant={'subtitle2'}>
                    {'Use Upload Button or Drag and Drop'}
                </Typography>
                <Typography variant="caption">{'Maximum file size of 2MB'}</Typography>
            </Stack>
        }
        actions={
            <Button
                variant={'contained'}
                color={'primary'}
                startIcon={<Add />}
                component="label"
                onChange={props?.onChange}
            >
                Upload a Photo
                <input accept="image/*" multiple type="file" className="upload-image-file" />
            </Button>
        }
    />
);
