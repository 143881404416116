import React from 'react';
import { useStyles } from './styles';
import { useTheme } from '@mui/material/styles';
import {
    List,
    Stack,
    TextField,
    ListItem,
    Avatar,
    ListItemAvatar,
    Typography,
    IconButton,
    Divider,
    ListItemText,
} from '@mui/material';
import { AlarmOn, Contrast, Done, FilterAlt, KeyboardArrowRight, Search } from '@mui/icons-material';
import * as Colors from '@brightlayer-ui/colors';
import { Battery } from '@brightlayer-ui/icons-mui';

export const MobileView = ({ data }: any): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    return (
        <List>
            <Stack direction={'row'} spacing={2} mx={2} mb={2}>
                <TextField
                    className={classes.searchTextField}
                    hiddenLabel
                    id="filled-hidden-label-small"
                    placeholder="Search..."
                    size="small"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <Search
                                style={{
                                    color: theme.palette.text.secondary,
                                    marginRight: theme.spacing(1),
                                }}
                            />
                        ),
                    }}
                />
                <IconButton id="edit-phone" aria-label="settings">
                    <FilterAlt />
                </IconButton>
            </Stack>
            <Divider />
            {data?.length &&
                data?.map((device: any) => (
                    <>
                        <ListItem
                            key={`list_${device.deviceId}`}
                            alignItems="flex-start"
                            secondaryAction={<KeyboardArrowRight className={classes.hevronRight} />}
                        >
                            <ListItemAvatar>
                                <Avatar sx={{ background: Colors.green[500] }}>
                                    <Done sx={{ color: Colors.white[50] }} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                className={classes.infoListItemPrimary}
                                primary={device.deviceName}
                                secondary={
                                    <React.Fragment>
                                        <Stack direction={'row'} spacing={1}>
                                            <Stack direction="row" spacing={1}>
                                                <Contrast />
                                                <Typography variant="body2">50% •</Typography>
                                            </Stack>
                                            <Stack direction="row" alignItems={'center'} spacing={1}>
                                                <Battery />
                                                <Typography variant="body2">50% •</Typography>
                                            </Stack>
                                            <Stack direction="row" alignItems={'center'} spacing={1}>
                                                <AlarmOn />
                                                <Typography variant="body2">1h19m</Typography>
                                            </Stack>
                                        </Stack>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider />
                    </>
                ))}
        </List>
    );
};
