import React from 'react';
import { Box, Divider, Grid, Paper, Typography, Skeleton } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useGetTimeLineByIdQuery } from '@fiji/common/src/features/timeline/timelineApi';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '@fiji/common/src/app/store';
import { setPageHeaderData } from '@fiji/common/src/features/common/commonSlice';
import SimpleCardSkeleton from './SimpleCardSkeleton';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { UserProfile } from '@fiji/common/src/types';
import { ListItemTag } from '@brightlayer-ui/react-components';
import { useTransformDatTime } from '../../hooks/useTransformDatTime';

const DeviceDetails = ({ isLoading, data }: { isLoading: boolean; data: any }): JSX.Element => (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {isLoading ? (
            <SimpleCardSkeleton count={5} />
        ) : (
            <>
                <ListItem sx={{ padding: '16px' }}>
                    <ListItemText sx={{ fontSize: '14px', fontWeight: '600', color: '#007bc1' }}>
                        Device Details
                    </ListItemText>
                </ListItem>
                <Divider />
                <ListItem sx={{ padding: '16px' }}>
                    <ListItemText sx={{ fontSize: '16px', fontWeight: '400', color: '#556167' }}>Name</ListItemText>
                    <ListItemText
                        sx={{
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#556167',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        {data?.data?.deviceTimeline?.deviceName}
                    </ListItemText>
                </ListItem>
                <Divider />
                <ListItem sx={{ padding: '16px' }}>
                    <ListItemText sx={{ fontSize: '16px', fontWeight: '400', color: '#556167' }}>Group</ListItemText>
                    <ListItemText
                        sx={{
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#556167',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        {data?.data?.deviceTimeline?.groupPath?.replaceAll('/', ' > ') ||
                            data?.data?.deviceTimeline?.groupName}
                    </ListItemText>
                </ListItem>
                <Divider />
                <ListItem sx={{ padding: '16px' }}>
                    <ListItemText sx={{ fontSize: '16px', fontWeight: '400', color: '#556167' }}>Model</ListItemText>
                    <ListItemText
                        sx={{
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#556167',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        {data?.data?.modelNumber ?? '-'}
                    </ListItemText>
                </ListItem>
                <Divider />
                <ListItem sx={{ padding: '16px' }}>
                    <ListItemText sx={{ fontSize: '16px', fontWeight: '400', color: '#556167' }}>Serial #</ListItemText>
                    <ListItemText
                        sx={{
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#556167',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        {data?.data?.deviceTimeline?.serialNumber ?? '-'}
                    </ListItemText>
                </ListItem>
            </>
        )}
    </List>
);

const EventDetails = ({ isLoading, data }: { isLoading: boolean; data: any }): JSX.Element => {
    const getListItemTextColor = (): string => {
        if (data?.data?.deviceTimeline?.status?.includes('Cleared')) {
            return '#424E54';
        }
        return '#FFFFFF';
    };

    const getListItemText = (): string => {
        if (data?.data?.deviceTimeline?.status === 'Alarm') {
            return 'Offline';
        } else if (data?.data?.deviceTimeline?.status === 'Alarm Cleared') {
            return 'Closed';
        } else if (
            data?.data?.deviceTimeline?.status === 'Warning Cleared' ||
            data?.data?.deviceTimeline?.status === 'Offline Cleared'
        ) {
            return 'Cleared';
        }
        return data?.data?.deviceTimeline?.status?.split(' ')[1];
    };

    const getListItemBackgroundColor = (): string => {
        if (data?.data?.deviceTimeline?.status?.includes('Active')) {
            return '#CA3C3D';
        } else if (data?.data?.deviceTimeline?.status === 'Alarm') {
            return '#69B1C3';
        }
        return 'transparent';
    };

    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {isLoading ? (
                <SimpleCardSkeleton count={6} />
            ) : (
                <>
                    <ListItem sx={{ padding: '16px' }}>
                        <ListItemText sx={{ fontSize: '14px', fontWeight: '600', color: '#007bc1' }}>
                            Event Details
                        </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem sx={{ padding: '16px' }}>
                        <ListItemText sx={{ fontSize: '16px', fontWeight: '400', color: '#556167' }}>
                            Event Name
                        </ListItemText>
                        <ListItemText
                            sx={{
                                fontSize: '14px',
                                fontWeight: '400',
                                color: '#556167',
                                display: 'flex',
                                justifyContent: 'end',
                            }}
                        >
                            {data?.data?.deviceTimeline?.events?.name ?? '-'}
                        </ListItemText>
                    </ListItem>
                    {data?.data?.deviceTimeline?.status !== 'Informational' && (
                        <>
                            <Divider />
                            <ListItem sx={{ padding: '16px' }}>
                                <ListItemText sx={{ fontSize: '16px', fontWeight: '400', color: '#556167' }}>
                                    Status
                                </ListItemText>
                                <ListItemText
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        color: '#556167',
                                        display: 'flex',
                                        justifyContent: 'end',
                                    }}
                                >
                                    <ListItemTag
                                        sx={{
                                            border: data?.data?.deviceTimeline?.status?.includes('Cleared')
                                                ? '1px solid #424E54'
                                                : 'none',
                                        }}
                                        label={getListItemText()?.toLocaleUpperCase()}
                                        fontColor={getListItemTextColor()}
                                        backgroundColor={getListItemBackgroundColor()}
                                    />
                                </ListItemText>
                            </ListItem>
                            <Divider />
                        </>
                    )}

                    <ListItem sx={{ padding: '16px' }}>
                        <ListItemText sx={{ fontSize: '16px', fontWeight: '400', color: '#556167' }}>
                            Severity
                        </ListItemText>
                        <ListItemText
                            sx={{
                                fontSize: '14px',
                                fontWeight: '400',
                                color: '#556167',
                                display: 'flex',
                                justifyContent: 'end',
                            }}
                        >
                            {data?.data?.deviceTimeline?.events?.severity ?? '-'}
                        </ListItemText>
                    </ListItem>

                    {data?.data?.deviceTimeline && Object.keys(data?.data?.deviceTimeline?.values)?.length ? (
                        Object.keys(data?.data?.deviceTimeline?.values)?.map((item: any) => (
                            <>
                                <Divider />
                                <ListItem sx={{ padding: '16px' }}>
                                    <ListItemText sx={{ fontSize: '16px', fontWeight: '400', color: '#556167' }}>
                                        {item}
                                    </ListItemText>
                                    <ListItemText
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: '#556167',
                                            display: 'flex',
                                            justifyContent: 'end',
                                        }}
                                    >
                                        {data?.data?.deviceTimeline?.values[item] ?? 0}
                                    </ListItemText>
                                </ListItem>
                                <Divider />
                            </>
                        ))
                    ) : (
                        <></>
                    )}
                </>
            )}
        </List>
    );
};

export const TimeLine = (): JSX.Element => {
    const { timeLineId } = useParams();

    const { data: profileDetails } = useGetUserProfileQuery<{ data: UserProfile }>();
    const {
        currentData: data,
        isLoading,
        isFetching,
    }: // isSuccess,
    any = useGetTimeLineByIdQuery(
        {
            timeLineId: timeLineId,
        },
        { refetchOnMountOrArgChange: true }
    );

    const dispatch = useAppDispatch();
    const { convertDateTime } = useTransformDatTime(profileDetails);

    React.useEffect(() => {
        if (data) {
            dispatch(setPageHeaderData(data?.data?.deviceTimeline));
        }
    }, [data]);

    const getEventClosedText = (status: string): string => {
        if (status === 'Alarm Cleared') {
            return 'Time Event Closed';
        } else if (status === 'Warning Cleared' || status === 'Offline Cleared') {
            return 'Time Event Cleared';
        }
        return 'Time Event Cleared';
    };

    return (
        <>
            <Grid container spacing={2} padding={'24px 112px 0 112px'}>
                <Grid item xs={12}>
                    <Paper>
                        {isLoading || isFetching ? (
                            <div style={{ padding: '6px' }}>
                                <Skeleton animation="wave">
                                    <Typography variant="body1">Description</Typography>
                                </Skeleton>
                            </div>
                        ) : (
                            <Typography
                                variant="body1"
                                sx={{ padding: '16px', color: '#007bc1', fontSize: '14px', fontWeight: '600' }}
                            >
                                Description
                            </Typography>
                        )}
                        <Divider />
                        {isLoading || isFetching ? (
                            <div style={{ padding: '6px' }}>
                                <Skeleton animation="wave">
                                    <Typography variant="body1">Description of device can be anything</Typography>
                                </Skeleton>
                            </div>
                        ) : (
                            <Typography
                                variant="body2"
                                sx={{ padding: '16px', color: '#424e54', fontSize: '14px', fontWeight: '400' }}
                            >
                                {data?.data?.deviceTimeline?.description ?? '-'}
                            </Typography>
                        )}
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper>
                        <EventDetails isLoading={isFetching || isLoading} data={data} />
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper sx={{ marginBottom: '16px' }}>
                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            {isLoading || isFetching ? (
                                <SimpleCardSkeleton count={3} />
                            ) : (
                                <>
                                    <ListItem sx={{ padding: '16px' }}>
                                        <ListItemText sx={{ fontSize: '14px', fontWeight: '600', color: '#007bc1' }}>
                                            History
                                        </ListItemText>
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            padding: '16px',
                                        }}
                                    >
                                        <Box>
                                            <ListItemText
                                                sx={{ fontSize: '16px', fontWeight: '400', color: '#556167' }}
                                            >
                                                Last Update Received
                                            </ListItemText>
                                        </Box>
                                        <Box display={'flex'} alignItems={'center'}>
                                            <ListItemText
                                                sx={{
                                                    fontSize: '14px',
                                                    fontWeight: '400',
                                                    color: '#556167',
                                                    marginRight: '16px',
                                                }}
                                            >
                                                {/* 15 minutes ago */}
                                            </ListItemText>
                                            <ListItemText
                                                sx={{
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    color: '#556167',
                                                }}
                                            >
                                                {`${
                                                    convertDateTime({
                                                        timestamp: parseInt(data?.data?.deviceTimeline?.updatedAt),
                                                    }) || '-'
                                                }, ${
                                                    convertDateTime({
                                                        timestamp: parseInt(data?.data?.deviceTimeline?.updatedAt),
                                                        customFormat: 'HH:mm:ss',
                                                    }) || '-'
                                                }`}
                                            </ListItemText>
                                        </Box>
                                    </ListItem>
                                    <Divider />
                                    {data?.data?.deviceTimeline?.status?.includes('Cleared') && (
                                        <>
                                            <ListItem
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    padding: '16px',
                                                }}
                                            >
                                                <Box>
                                                    <ListItemText
                                                        sx={{ fontSize: '16px', fontWeight: '400', color: '#556167' }}
                                                    >
                                                        {getEventClosedText(data?.data?.deviceTimeline?.status)}
                                                    </ListItemText>
                                                </Box>
                                                <Box display={'flex'} alignItems={'center'}>
                                                    <ListItemText
                                                        sx={{
                                                            fontSize: '14px',
                                                            fontWeight: '400',
                                                            color: '#556167',
                                                            marginRight: '16px',
                                                        }}
                                                    ></ListItemText>
                                                    <ListItemText
                                                        sx={{
                                                            fontSize: '14px',
                                                            fontWeight: '600',
                                                            color: '#556167',
                                                        }}
                                                    >
                                                        {`${
                                                            convertDateTime({
                                                                timestamp: parseInt(
                                                                    data?.data?.deviceTimeline?.updatedAt
                                                                ),
                                                            }) || '-'
                                                        }, ${
                                                            convertDateTime({
                                                                timestamp: parseInt(
                                                                    data?.data?.deviceTimeline?.updatedAt
                                                                ),
                                                                customFormat: 'HH:mm:ss',
                                                            }) || '-'
                                                        }`}
                                                    </ListItemText>
                                                </Box>
                                            </ListItem>
                                            <Divider />
                                        </>
                                    )}
                                    <ListItem
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            padding: '16px',
                                        }}
                                    >
                                        <Box>
                                            <ListItemText
                                                sx={{ fontSize: '16px', fontWeight: '400', color: '#556167' }}
                                            >
                                                Time of Event
                                            </ListItemText>
                                        </Box>
                                        <Box display={'flex'} alignItems={'center'}>
                                            <ListItemText
                                                sx={{
                                                    fontSize: '14px',
                                                    fontWeight: '400',
                                                    color: '#556167',
                                                    marginRight: '16px',
                                                }}
                                            >
                                                {/* 45 minutes ago */}
                                            </ListItemText>
                                            <ListItemText
                                                sx={{
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    color: '#556167',
                                                }}
                                            >
                                                {`${
                                                    convertDateTime({
                                                        timestamp: parseInt(data?.data?.deviceTimeline?.createdAt),
                                                    }) || '-'
                                                }, ${
                                                    convertDateTime({
                                                        timestamp: parseInt(data?.data?.deviceTimeline?.createdAt),
                                                        customFormat: 'HH:mm:ss',
                                                    }) || ' - '
                                                }`}
                                            </ListItemText>
                                        </Box>
                                    </ListItem>
                                </>
                            )}
                        </List>
                    </Paper>

                    <Paper>
                        <DeviceDetails isLoading={isFetching || isLoading} data={data} />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};
