import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { RootState, useTypedSelector } from '@fiji/common/src/app/store';

/* The code defines a React functional component called `BackdropLoader`. It takes an optional prop
`isOpen` of type boolean. */
export const BackdropLoader = ({ isOpen }: { isOpen?: boolean }): JSX.Element => {
    const action = useTypedSelector((state: RootState) => state.common);

    const [isLoading, setIsLoading] = React.useState<boolean>(action?.isLoader);

    /* The `React.useEffect` hook is used to perform side effects in a functional component. In this
    case, it is used to update the `isLoading` state variable based on the values of
    `action?.isLoader` and `isOpen`. */
    React.useEffect(() => {
        if (isOpen) {
            setIsLoading(true);
            return;
        }
        setIsLoading(action?.isLoader);
    }, [action?.isLoader, isOpen]);

    return (
        <div>
            <Backdrop sx={{ color: '#fff', zIndex: (theme): any => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress thickness={5} size={50} color="inherit" />
            </Backdrop>
        </div>
    );
};
