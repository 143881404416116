import { api } from '../../app/api/baseApi';

const actionsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllActions: builder.query<object, void>({
            query: () => ({
                url: `/iam/v1/actions`,
            }),
        }),
    }),
});

export const { useGetAllActionsQuery } = actionsApi;
