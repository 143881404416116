/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { Dialog, DialogTitle, Typography, DialogContent, TextField, DialogActions, Stack, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useStyles } from './styles';
import { useEditDeviceMutation } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { setToastifyContent } from '@fiji/common/src/features/common/commonSlice';
import { useAppDispatch } from '@fiji/common/src/app/store';
import Loader from '../../components/Loader';
import { handleWhiteSpaces } from '../../CommonUtils';
import { DEVICE_FIELDS_LIMIT } from '@fiji/common/src/constants';

/**
 * The `EditDeviceNameModalProps` type represents the props for an edit device name modal component in
 * a TypeScript React application.
 * @property {string | undefined} deviceName - The `deviceName` property is a string that represents
 * the name of the device. It can be either a valid string value or `undefined` if the device name is
 * not available.
 * @property {boolean} isOpen - A boolean value indicating whether the modal is open or not.
 * @property {any} deviceId - The `deviceId` property is used to identify the specific device for which
 * the name is being edited. It can be of any type, as indicated by the `any` type annotation.
 * @property closeHandler - A function that will be called when the modal is closed.
 * @property refetchDetailsCall - The `refetchDetailsCall` property is a function that is used to
 * trigger a call to fetch updated details for the device. It is typically used after the device name
 * has been edited and saved, to ensure that the latest device information is displayed.
 */
type EditDeviceNameModalProps = {
    deviceName: string | undefined;
    isOpen: boolean;
    deviceId: any;
    closeHandler: () => void;
    refetchDetailsCall: () => void;
};

export const EditDeviceNameModal = (props: EditDeviceNameModalProps): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();

    const [name, setName] = React.useState<string | undefined>(props.deviceName);

    const [editDeviceDetail, { isSuccess, isLoading }] = useEditDeviceMutation();

    /* The `React.useEffect` hook is used to perform side effects in a functional component. In this
   case, the effect is triggered when the `props.deviceName` value changes. */
    React.useEffect(() => {
        if (props?.deviceName) {
            setName(props.deviceName);
        }
    }, [props?.deviceName]);

    /* The `React.useEffect` hook in the code snippet is used to perform a side effect when the
    `isSuccess` variable changes. */
    React.useEffect(() => {
        if (isSuccess) {
            props.refetchDetailsCall();
            props.closeHandler();
        }
    }, [isSuccess]);

    /**
     * The submitHandler function is an asynchronous function that calls the editDeviceDetail function
     * with the device name and device ID as parameters.
     */
    const submitHandler = async (): Promise<void> => {
        const { error }: any = await editDeviceDetail({ payload: { deviceName: name }, id: props.deviceId });

        if (!error) {
            dispatch(setToastifyContent({ isOpen: true, message: 'Changes Saved Successfully' }));
        }
    };

    /**
     * The function `handleModalClose` closes a modal and sets the name of a device.
     */
    const handleModalClose = (): void => {
        props.closeHandler();
        setName(props?.deviceName);
    };

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.closeHandler}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.rootMuiDialogpaper}
        >
            {isLoading ? (
                <>
                    <DialogContent sx={{ height: '225px' }}>
                        <Loader size={60} />
                    </DialogContent>
                </>
            ) : (
                <>
                    <DialogTitle id="alert-dialog-title" className={classes.dialogTitleRoot}>
                        <Typography variant={'h6'}>Edit Name</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            value={name}
                            id="deviceName"
                            label={'Device Name'}
                            fullWidth
                            variant={'filled'}
                            onChange={(e: any): void => {
                                if (e.target.value.length <= DEVICE_FIELDS_LIMIT['deviceName']) {
                                    setName(e.target.value);
                                }
                            }}
                        />
                        <Typography variant="body2" className="custom-add-device-field-lengths-style">
                            {`${name?.length || 0}/${DEVICE_FIELDS_LIMIT['deviceName']}`}
                        </Typography>
                    </DialogContent>
                    <DialogActions className={classes.MuiDialogActionsRoot}>
                        <Stack direction={'row'} spacing={2} justifyContent={'end'}>
                            <Button variant={'outlined'} onClick={handleModalClose}>
                                Cancel
                            </Button>
                            <Button
                                disabled={name === props.deviceName || name === '' || !handleWhiteSpaces(name)}
                                onClick={submitHandler}
                                variant={'contained'}
                            >
                                Save
                            </Button>
                        </Stack>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};
