import { RegistrationUIActions, AccountDetailInformation } from '@brightlayer-ui/react-auth-workflow';
import {
    useGenerateCodeMutation,
    useUpdateProfileMutation,
    useVerifySignUpCodeMutation,
} from '@fiji/common/src/features/registration/registration';
// Constants
import { SAMPLE_EULA } from '@fiji/common/src/constants/sampleEula';
import { useCleanPayload } from '../../hooks';
import { useAppDispatch } from '@fiji/common/src/app/store';
import { setToastifyContent } from '@fiji/common/src/features/common/commonSlice';

const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Example implementation of [[RegistrationUIActions]] to start with during development.
 *
 * Registration Actions to be performed based on the user's actions. The application will create appropriate actions
 * (often API calls, local network storage, credential updates, etc.) based on the actionable needs of the user.
 */
let validateCodeRetries = 0;
export const ProjectRegistrationUIActions: () => RegistrationUIActions = () => {
    const [generateCode] = useGenerateCodeMutation();
    const [verifySignUpCode] = useVerifySignUpCodeMutation();
    const [updateProfile] = useUpdateProfileMutation();
    const [cleanPayload] = useCleanPayload();
    const dispatch = useAppDispatch();
    return {
        /**
         * The user wants to complete an action but must first accept the EULA.
         * The application should retrieve an application-specific EULA for the user.
         *
         * @param language  The i18n language the user is requesting for the EULA text.
         *
         * @returns Resolve with EULA, otherwise reject with an error message.
         */
        loadEULA: async (language?: string): Promise<string> => {
            await sleep(1000);

            if (!language?.includes('en')) {
                return 'Other language EULA';
            }

            return SAMPLE_EULA;
        },

        /**
         * The user entered their email address and accepted the EULA.
         * The API should now send them an email with the validation code.
         *
         * @param email  Email for the registering user.
         *
         * @returns Resolve when the server accepted the request.
         */
        requestRegistrationCode: async (email: string): Promise<void> => {
            try {
                validateCodeRetries = 0;
                await generateCode({ email }).unwrap();
                dispatch(
                    setToastifyContent({
                        isOpen: true,
                        message: `${email} verification code sent.`,
                        duration: 3000,
                    })
                );
            } catch (err: any) {
                throw new Error(err.data.errorMessage);
            }
        },

        /**
         * The user has tapped on an email link inviting them to register with the application.
         * The application should validate the code provided by the link.
         *
         * @param validationCode  Registration code provided from the link.
         * @param validationEmail  Email provided from the invitation email link (optional) `?email=addr%40domain.com`.
         *
         * @returns Resolves when the code is valid. True if registration is complete, False if account information is needed.
         *          If the code is not valid a rejection will occur with an error message.
         */
        validateUserRegistrationRequest: async (validationCode: string, validationEmail?: string): Promise<boolean> => {
            if (validateCodeRetries >= 10) {
                throw new Error('You have made too many failed attempts. Please request for a new code.');
            } else {
                try {
                    const { data }: { data: { isUserAlreadyPresentInIDP: boolean } } = await verifySignUpCode({
                        email: validationEmail,
                        verificationCode: validationCode,
                    }).unwrap();
                    return data.isUserAlreadyPresentInIDP;
                } catch (err: any) {
                    if (err.status === 400) {
                        validateCodeRetries++;
                    }
                    throw new Error(err.data.errorMessage);
                }
            }
        },
        /**
         * The user has been invited to register and has entered the necessary account and
         * password information.
         * The application should now complete the registration process given the user's data.
         *
         * Note: Upon resolution, the user will be brought back to the Login screen.
         *
         * @param userData  Account details and password entered by the user.
         * @param validationCode  Registration code provided from the invitation email link.
         * @param validationEmail  Email provided from the invitation email link (optional) `?email=addr%40domain.com`.
         *
         * @returns Resolve when account creation succeeds, otherwise reject with an error message.
         */
        completeRegistration: async (
            userData: {
                password: string;
                accountDetails: AccountDetailInformation;
            },
            validationCode: string,
            validationEmail: string
        ): Promise<{ email: string; organizationName: string }> => {
            try {
                await updateProfile(
                    cleanPayload({
                        ...userData.accountDetails,
                        email: validationEmail,
                        licenseAccepted: true,
                    })
                ).unwrap();
            } catch (err: any) {
                throw new Error(err.data.errorMessage);
            }
            return { email: validationEmail, organizationName: 'Monaco' };
        },
    };
};
