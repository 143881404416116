import React from 'react';
import { Box, DialogContent, DialogContentText, Divider, Stack, Switch, TextField, Typography } from '@mui/material';
import { ChatBubble, ChatBubbleOutline, Email, EmailOutlined } from '@mui/icons-material';

export const SelectNotificationTypes = React.memo((props: any): JSX.Element => {
    /**
     * The function `payloadHandler` is a TypeScript function in a React component that handles changes
     * in input fields and updates the payload state accordingly.
     * @param e - The parameter `e` is of type `React.ChangeEvent<HTMLInputElement>`. This means it is
     * an event object that is triggered when the value of an input element changes.
     * @returns nothing (undefined).
     */
    const payloadHandler = (e: React.ChangeEvent<HTMLInputElement>): any => {
        if (e?.target?.id !== 'name') {
            props?.setPayload((prev: any) => ({
                ...prev,
                [e.target.id]: !prev[e.target.id],
            }));
            return;
        }

        props?.setPayload((prev: any) => ({
            ...prev,
            [e.target.id]: e.target.value,
        }));
    };

    return (
        <DialogContent sx={{ padding: '0', marginTop: '0' }}>
            <DialogContentText id="alert-dialog-slide-description">
                <Divider />
                <Box sx={{ padding: '24px' }}>
                    <TextField
                        id="name"
                        label="Custom Notification Name"
                        variant="filled"
                        className="w-100"
                        value={props?.payload?.name}
                        onChange={payloadHandler}
                        helperText={'e.g. Overload Notification, Security Desk Notifications'}
                    />
                </Box>
                <Divider />
                <Box
                    className="modal-space-between"
                    sx={{ borderBottom: '1px solid rgba(66, 78, 84, 0.12)', padding: '16px' }}
                >
                    <Box className="modal-align-center">
                        {props?.payload?.email ? (
                            <Email className="modal-custom-icon" />
                        ) : (
                            <EmailOutlined className="modal-custom-icon" />
                        )}
                        <Stack sx={{ paddingLeft: '8px' }}>
                            <Typography variant="body1" className="modal-greyhead-text">
                                Email Notifications
                            </Typography>
                            <Typography variant="body2" className="modal-para">
                                {props?.payload?.email ? 'Enabled' : 'Disabled'}
                            </Typography>
                        </Stack>
                    </Box>
                    <Box>
                        <Switch id="email" checked={props?.payload?.email} onChange={payloadHandler} />
                    </Box>
                </Box>
                <Box
                    className="modal-space-between"
                    sx={{ borderBottom: '1px solid rgba(66, 78, 84, 0.12)', padding: '16px' }}
                >
                    <Box className="modal-align-center">
                        {props?.payload?.sms ? (
                            <ChatBubble className="modal-custom-icon" />
                        ) : (
                            <ChatBubbleOutline className="modal-custom-icon" />
                        )}
                        <Stack sx={{ paddingLeft: '8px' }}>
                            <Typography variant="body1" className="modal-greyhead-text">
                                Text Notifications (SMS)
                            </Typography>
                            <Typography variant="body2" className="modal-para">
                                {props?.payload?.sms ? 'Enabled' : 'Disabled'}
                            </Typography>
                        </Stack>
                    </Box>
                    <Box>
                        <Switch id="sms" checked={props?.payload?.sms} onChange={payloadHandler} />
                    </Box>
                </Box>
                {/* <Box
                    className="modal-space-between"
                    sx={{ borderBottom: '1px solid rgba(66, 78, 84, 0.12)', padding: '16px' }}
                >
                    <Box className="modal-align-center">
                        <PushNotification className="modal-custom-icon" />
                        <Stack sx={{ paddingLeft: '8px' }}>
                            <Typography variant="body1" className="modal-grey-header">
                                Push Notifications (SMS)
                            </Typography>
                            <Typography variant="body1" className="modal-para">
                                The setting may not apply to users that have Push Notifications disabled, or non-user
                                Contacts
                            </Typography>
                        </Stack>
                    </Box>
                    <Box>
                        <Switch id="push" checked={props?.payload?.push} onChange={payloadHandler} />
                    </Box>
                </Box> */}
            </DialogContentText>
        </DialogContent>
    );
});
