/* eslint-disable @typescript-eslint/no-floating-promises */
import { Avatar, Box, Button, Container, Divider, Paper, Typography } from '@mui/material';
import React from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { NotificationData, NotificationType, UserProfile } from '@fiji/common/src/types';
import {
    useGetNotificationPreferencesQuery,
    useSaveNotificationPreferencesMutation,
    useSendTestNotifcationMutation,
} from '@fiji/common/src/features/notification/notificationApi';

import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { setToastifyContent } from '@fiji/common/src/features/common/commonSlice';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { switchOrgModal, selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { RefetchConfigOptions } from '@reduxjs/toolkit/dist/query/core/apiState';
import { AllOrgApplyConfirmModal, NotificationCard, NotificationSkeleton, ResetNotificationsModal } from './';

export const PreferencesTab = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const currentOrg = useTypedSelector(selectedOrg);

    const { data: userProfileData } = useGetUserProfileQuery<{
        data: UserProfile;
        isLoading: boolean;
    }>();

    const {
        data: notificationsData,
        isLoading: areNotificationsLoading,
        isSuccess,
    } = useGetNotificationPreferencesQuery<{
        data: NotificationData;
        isLoading: boolean;
        isFetching: boolean;
        refetch: RefetchConfigOptions;
        isSuccess: boolean;
    }>(undefined, { refetchOnMountOrArgChange: true });

    const [sendNotificationApi] = useSendTestNotifcationMutation();
    const [saveNotificationPrefernces, { isLoading: areNotificationsSaving }] =
        useSaveNotificationPreferencesMutation();

    const [isConfirmationModalVisible, setIsConfirmModalVisible] = React.useState<boolean>(false);
    const [resetConfirmModal, setResetConfirmModal] = React.useState<boolean>(false);

    const getPhoneNumber = (): string => {
        if (userProfileData?.data?.areaCode && userProfileData?.data?.phoneNumber) {
            return `${userProfileData?.data?.areaCode} ${userProfileData?.data?.phoneNumber}`;
        } else if (!userProfileData?.data?.areaCode && userProfileData?.data?.phoneNumber) {
            return userProfileData?.data?.phoneNumber;
        }
        return '-';
    };

    /**
     * The function `sendNotification` sends a notification to a user's email or phone number, and
     * displays a success message if the notification is sent successfully.
     * @param {string} type - The `type` parameter is a string that represents the type of notification
     * to be sent. It can have values such as 'email' or 'phoneNumber'.
     */
    const sendNotification = async (type: string): Promise<void> => {
        const payload: any = {
            [type]: userProfileData?.data?.[type],
            notificationType: type === 'email' ? type.toUpperCase() : 'SMS',
        };
        if (type === 'phoneNumber') {
            payload['areaCode'] = userProfileData?.data?.['areaCode'];
        }
        const { error }: any = await sendNotificationApi(payload);
        if (!error) {
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    message: `Notification sent to ${userProfileData?.data?.[type]} successfully`,
                    duration: 3000,
                })
            );
        }
    };

    /**
     * The function `handleOpenSwitchOrganizationModal` dispatches an action to open a modal for
     * switching organizations.
     */
    const handleOpenSwitchOrganizationModal = (): void => {
        dispatch(switchOrgModal(true));
    };

    /**
     * The function `resetPreferenceHandler` resets the notification preferences for a given
     * organization by setting the SMS, push, and email preferences to false for each notification
     * type.
     */
    const resetPreferenceHandler = async (): Promise<void> => {
        const payload: any = {
            orgIds: [currentOrg?.id],
            preferences: notificationsData?.data?.map?.((item: NotificationType) => ({
                id: item?.id,
                sms: true,
                push: true,
                email: true,
            })),
        };

        const { error }: any = await saveNotificationPrefernces(payload);
        if (!error) {
            setResetConfirmModal(false);
            dispatch(setToastifyContent({ isOpen: true, message: 'Notification preferences reset successfully.' }));
        }
    };

    /**
     * The submitHandler function is an asynchronous function that handles the submission of
     * notification preferences, updates the preferences in the backend, and displays a success message
     * if the update is successful.
     */
    const submitHandler = async (): Promise<void> => {
        const payload = {
            orgIds: ['all'],
            preferences: notificationsData?.data?.map?.((item: NotificationType) => ({
                id: item?.id,
                sms: item?.sms,
                push: item?.push,
                email: item?.email,
            })),
        };
        const { error }: any = await saveNotificationPrefernces(payload);
        if (!error) {
            setIsConfirmModalVisible(false);
            dispatch(setToastifyContent({ isOpen: true, message: 'Notifications preferences changed successfully.' }));
        }
    };

    return (
        <Container>
            <Paper
                sx={{
                    padding: '16px',
                }}
                className="modal-space-between"
            >
                <Box sx={{ gap: '16px' }} className="modal-align-center">
                    <Avatar>
                        <InfoOutlinedIcon />
                    </Avatar>
                    <Typography variant="body2">
                        {`You're currently setting your notification preferences for ${currentOrg?.name} and the groups you have access to.`}
                        <br />
                        {'Your Preferences do not affect other users.'}
                    </Typography>
                </Box>
                <Button
                    variant="outlined"
                    sx={{
                        fontSize: '14px',
                        fontWeight: '600',
                        borderColor: 'rgba(66, 78, 84, 0.12)',
                    }}
                    onClick={handleOpenSwitchOrganizationModal}
                >
                    Switch Organization
                </Button>
            </Paper>

            {areNotificationsLoading || areNotificationsSaving ? (
                <>
                    <NotificationSkeleton key="skeleton123141" />
                </>
            ) : (
                notificationsData?.data?.map(
                    (preference: NotificationType): JSX.Element => (
                        <NotificationCard
                            key={preference.id}
                            data={notificationsData?.data}
                            preference={preference}
                            saveHandler={saveNotificationPrefernces}
                        />
                    )
                )
            )}

            <Paper className="paper-margin-16" sx={{ padding: '16px' }}>
                <Button
                    variant="outlined"
                    className="w-100"
                    sx={{
                        fontSize: '14px',
                        fontWeight: '600',
                        borderColor: 'rgba(66, 78, 84, 0.12)',
                    }}
                    disabled={!isSuccess}
                    onClick={(): void => setIsConfirmModalVisible(true)}
                >
                    Apply to All Organizations
                </Button>
                <AllOrgApplyConfirmModal
                    key="allORg3e1d11"
                    isOpen={isConfirmationModalVisible}
                    closeModal={(): void => setIsConfirmModalVisible(false)}
                    submitHandler={submitHandler}
                />
            </Paper>
            <Paper sx={{ marginBottom: '50px' }}>
                <Typography variant="body1" sx={{ padding: '16px' }} className="modal-blue-header">
                    Troubleshooting
                </Typography>
                <Divider />
                <Box sx={{ padding: '16px', display: 'flex', flexDirection: 'column' }}>
                    <Box>
                        <Button
                            variant={'outlined'}
                            color="error"
                            sx={{
                                color: '#BD2E2F',
                                fontSize: '14px',
                                fontWeight: '600',
                                marginBottom: '16px',
                                borderColor: 'rgba(66, 78, 84, 0.12)',
                                width: '100%',
                            }}
                            onClick={(): void => setResetConfirmModal(true)}
                        >
                            Reset Notification Settings
                        </Button>
                    </Box>
                    <Button
                        startIcon={<MailOutlineIcon />}
                        variant="outlined"
                        className="modal-blue-header"
                        sx={{
                            marginBottom: '16px',
                            borderColor: 'rgba(66, 78, 84, 0.12)',
                        }}
                        onClick={(): void => {
                            sendNotification('email');
                        }}
                    >
                        Send a Test Email{' '}
                        <span className="lower-case-title" style={{ padding: '5px' }}>
                            ({userProfileData?.data?.email})
                        </span>
                    </Button>
                    <Button
                        startIcon={<ChatBubbleOutlineIcon />}
                        variant="outlined"
                        className="modal-blue-header"
                        disabled={getPhoneNumber() === 'N/A' || !userProfileData?.data?.areaCode}
                        onClick={(): void => {
                            sendNotification('phoneNumber');
                        }}
                        sx={{
                            marginBottom: '16px',
                            borderColor: 'rgba(66, 78, 84, 0.12)',
                        }}
                    >
                        Send a Test Text Message ({getPhoneNumber()})
                    </Button>
                    {/* <Box>
                        <Button
                            startIcon={<PushNotification />}
                            variant="outlined"
                            className="modal-blue-header"
                            sx={{
                                borderColor: 'rgba(66, 78, 84, 0.12)',
                                width: '100%',
                            }}
                        >
                            Send a Test Push Notification
                        </Button>
                    </Box> */}
                </Box>
            </Paper>

            <ResetNotificationsModal
                key="edafa"
                clickHandler={resetPreferenceHandler}
                closeModal={(): void => setResetConfirmModal(false)}
                isOpen={resetConfirmModal}
            />
        </Container>
    );
};
