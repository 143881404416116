import React, { useEffect, useState } from 'react';
import { StepperModal } from '../../../components/StepperModal';
import { AssignUsersContent, DisplayPreferencesContent, GroupDetailsContent, getGroupLabel } from './';
import { addGroupModal, selectAddGroupModal, selectHierarchyData } from '@fiji/common/src/features/group/groupSlice';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { GroupModal } from '@fiji/common/src/types';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useCleanPayload, useGetHierarchyHandlers, useRBAC } from '../../../hooks';
import {
    useAssignUsersToGroupsMutation,
    useCreateGroupMutation,
    useMoveExistingGroupMutation,
    useUpdateGroupBackgroundMutation,
} from '@fiji/common/src/features/group/groupApi';
import { useTheme, Button } from '@mui/material';
import { useStyles } from './styles';
import { MovingSourceContent } from '../MoveGroupsDevicesModal';
import { setToastifyContent } from '@fiji/common/src/features/common/commonSlice';
import { BackdropLoader } from '../../../components/BackdropLoader';
import { handleWhiteSpaces } from '../../../CommonUtils';

export const AddGroupModal = (): JSX.Element => {
    const rolePermissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(rolePermissions);
    const canUploadGroupBackground = hasPermission('upload-group-image');

    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();

    const showAddGroupModal = useTypedSelector<GroupModal>(selectAddGroupModal);

    const [groupDetails, setGroupDetails] = useState({
        groupName: '',
        groupShortName: '',
        destinationGroup: showAddGroupModal?.groupId,
    });

    const [currentStep, setCurrentStep] = React.useState<string>('groupDetails');
    const [moveExistingPayload, setMoveExistingPayload] = useState({ devices: [], groups: [] });
    const [allSelectedNodes, setAllSelectedNodes] = useState({ devices: [], groups: [] });
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [preferenceType, setPreferenceType] = useState('default');
    const [groupImage, setGroupImage] = useState<any>();
    const [isCreatingGroup, setIsCreatingGroup] = useState(false);

    const [cleanPayload] = useCleanPayload();

    const [createGroup] = useCreateGroupMutation();
    const [moveExistingGroup] = useMoveExistingGroupMutation();
    const [assignUsersToGroups] = useAssignUsersToGroupsMutation();
    const [updateGroupBackground] = useUpdateGroupBackgroundMutation();

    const { removeUnwantedIds } = useGetHierarchyHandlers({
        isChildrenPreselected: true,
    });

    const hierarchyData = useTypedSelector(selectHierarchyData);

    useEffect(() => {
        if (showAddGroupModal?.isOpen) {
            if (showAddGroupModal?.groupId)
                setGroupDetails((prev) => ({ ...prev, destinationGroup: showAddGroupModal?.groupId }));
            else setGroupDetails((prev) => ({ ...prev, destinationGroup: hierarchyData?.data?.data?.data[0]?.id }));
        }
    }, [showAddGroupModal, hierarchyData?.data]);

    useEffect(() => {
        const filteredNodeIds = removeUnwantedIds(
            hierarchyData?.data?.data?.data,
            allSelectedNodes?.groups,
            allSelectedNodes?.devices
        );
        setMoveExistingPayload(filteredNodeIds);
    }, [allSelectedNodes, hierarchyData?.data]);

    const modalContent = React.useMemo(() => {
        const allSteps = [
            {
                key: 'groupDetails',
                title: 'New Group',
                header: 'Group Details',
                component: <GroupDetailsContent groupDetails={groupDetails} setGroupDetails={setGroupDetails} />,
            },
            {
                key: 'moveExistingGroupsAndDevices',
                title: 'New Group',
                header: `Move Existing Groups & Devices to ${groupDetails?.groupName}`,
                description: 'Existing groups can be moved to this group. Moving groups will also move their children.',
                component: (
                    <MovingSourceContent
                        selectedSource={allSelectedNodes}
                        onNodeSelection={setAllSelectedNodes}
                        parentId={groupDetails?.destinationGroup}
                        isOpen={showAddGroupModal?.isOpen}
                    />
                ),
            },
            {
                key: 'assignUsers',
                title: 'New Group',
                header: `Select Users to Access ${groupDetails?.groupName}`,
                description: 'Users that belong to the parent of this group are preselected.',
                component: (
                    <AssignUsersContent
                        destinationGroup={groupDetails?.destinationGroup}
                        selectedUsers={selectedUsers}
                        setSelectedUsers={setSelectedUsers}
                    />
                ),
                ...(!canUploadGroupBackground && { nextButtonLabel: 'Add Group' }),
            },
        ];
        if (canUploadGroupBackground) {
            allSteps.push({
                key: 'displayPreferences',
                title: 'New Group',
                header: `Display Preferences for ${groupDetails?.groupName}`,
                component: (
                    <DisplayPreferencesContent
                        preferenceType={preferenceType}
                        setPreferenceType={setPreferenceType}
                        groupImage={groupImage}
                        setGroupImage={setGroupImage}
                    />
                ),
                nextButtonLabel: 'Add Group',
            });
        }
        return allSteps;
    }, [groupDetails, allSelectedNodes, canUploadGroupBackground, selectedUsers, preferenceType, groupImage]);

    const handleCloseGroupModal = (): void => {
        setCurrentStep('groupDetails');
        setGroupDetails({
            groupName: '',
            groupShortName: '',
            destinationGroup: hierarchyData?.data?.data?.data[0]?.id,
        });
        setSelectedUsers([]);
        setGroupImage(undefined);
        setMoveExistingPayload({ devices: [], groups: [] });
        setAllSelectedNodes({ devices: [], groups: [] });
        dispatch(addGroupModal({ isOpen: false, groupId: '' }));
    };

    const handleStepDisable = (): boolean => {
        switch (currentStep) {
            case 'groupDetails':
                return (
                    !groupDetails?.groupName ||
                    !groupDetails?.destinationGroup ||
                    !handleWhiteSpaces([groupDetails?.groupName, groupDetails?.groupShortName])
                );
            case 'displayPreferences':
                return preferenceType === 'photo' && !groupImage;
            default:
                return false;
        }
    };

    const isAddGroupFinishButtonDisabled = (): boolean => {
        const currentStepIndex = getCurrentStepIndex();
        if (currentStepIndex === modalContent.length - 1) {
            return true;
        }
        return false;
    };

    const getCurrentStep = (step: string): void => {
        setCurrentStep(step);
    };

    const getCurrentStepIndex = (): number => {
        const allSteps = modalContent;
        return allSteps.findIndex((step) => step.key === currentStep);
    };

    const handleCreateGroup = async (): Promise<string> => {
        const { data: createGroupData, error }: any = await createGroup(
            cleanPayload({
                name: groupDetails?.groupName,
                shortName: groupDetails?.groupShortName,
                parentId: groupDetails?.destinationGroup,
            })
        );
        if (!error) {
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: `${groupDetails?.groupName} has been added to ${
                        getGroupLabel(hierarchyData?.data?.data?.data, groupDetails?.destinationGroup) ?? ''
                    }`,
                    duration: 3000,
                })
            );
            return createGroupData?.data?.id;
        }
        return '';
    };

    const handleMoveExistingGroupsDevices = async (currentGroupId: string): Promise<void> => {
        await moveExistingGroup({
            destinationGroupId: currentGroupId,
            ...(moveExistingPayload?.devices?.length && { deviceIds: moveExistingPayload?.devices }),
            ...(moveExistingPayload?.groups?.length && { groupIds: moveExistingPayload?.groups }),
        });
    };

    const handleAssignUsersToGroup = async (currentGroupId: string): Promise<void> => {
        await assignUsersToGroups({
            groupId: currentGroupId,
            userIds: selectedUsers,
        });
    };

    const handleUpdateGroupBackground = async (currentGroupId: string): Promise<void> => {
        setIsCreatingGroup(true);
        const formData = new FormData();
        formData.append('image', groupImage);
        const { error }: any = await updateGroupBackground({
            groupId: currentGroupId,
            image: formData,
        });
        if (!error) {
            setIsCreatingGroup(false);
        }
    };

    const saveGroupDetails = async (currentStepIndex: number): Promise<void> => {
        let currentGroupId;
        if (currentStepIndex >= 1) {
            currentGroupId = await handleCreateGroup();
        }
        if (
            currentStepIndex >= 2 &&
            (moveExistingPayload?.devices?.length || moveExistingPayload?.groups?.length) &&
            currentGroupId
        ) {
            await handleMoveExistingGroupsDevices(currentGroupId);
        }
        if (currentStepIndex >= 3 && selectedUsers.length && currentGroupId) {
            await handleAssignUsersToGroup(currentGroupId);
        }
        if (currentStepIndex >= 4 && groupImage && canUploadGroupBackground && currentGroupId) {
            await handleUpdateGroupBackground(currentGroupId);
        }
        setIsCreatingGroup(false);
    };

    const handleSaveGroup = async (): Promise<void> => {
        const currentStepIndex = getCurrentStepIndex();
        setIsCreatingGroup(true);
        await saveGroupDetails(currentStepIndex + 1);
        handleCloseGroupModal();
    };

    if (isCreatingGroup) {
        return <BackdropLoader isOpen={true} />;
    }

    return (
        <StepperModal
            modalContent={modalContent}
            closeModal={handleCloseGroupModal}
            isOpen={showAddGroupModal?.isOpen}
            submitHandler={handleSaveGroup}
            getCurrentStep={getCurrentStep}
            isDisabled={handleStepDisable()}
            classes={{ paper: classes.dialogPaper }}
            extraButton={
                <Button
                    className={classes.button}
                    variant="outlined"
                    onClick={handleSaveGroup}
                    disabled={
                        handleStepDisable() ||
                        isAddGroupFinishButtonDisabled() ||
                        !handleWhiteSpaces([groupDetails?.groupName, groupDetails?.groupShortName])
                    }
                >
                    Add Group & Finish
                </Button>
            }
        />
    );
};
