import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { Configuration } from '../../types/configuration';

const initialSelectedConfigurationState: Configuration = {
    configProfileName: '',
    configId: '',
    configFields: {
        ipv4Address: '',
        ipv4Method: '',
        ipv4Subnet: '',
        ipv4Gateway: '',
        proxySetting: false,
        proxyIP: '',
        proxyPort: 0,
        proxyUser: '',
        proxyPassword: '',
    },
};

const configurationSlice = createSlice({
    name: 'sampleConfig',
    initialState: {
        selectedConfig: initialSelectedConfigurationState,
    },
    reducers: {
        setSelectedConfig: (state, action: PayloadAction<Configuration>) => {
            const config: Configuration = action.payload;
            state.selectedConfig = config;
        },
    },
});

export const { setSelectedConfig } = configurationSlice.actions;
export default configurationSlice;

export const selectedConfig = (state: RootState): Configuration => state.sampleConfig.selectedConfig;
