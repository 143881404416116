/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { Stack, Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PowerSettingsNew } from '@mui/icons-material';
import { PowerRedundancy } from '@brightlayer-ui/icons-mui';
import { useStyles } from './styles';

/**
 * The `TurnOffProps` type is used in a TypeScript React component and defines the props required
 * for turning off a device, including whether a modal is open, functions for handling modal close and
 * executing device commands.
 * @property {boolean} isOpen - A boolean value indicating whether the device turn-off modal is open or
 * not.
 * @property handleCloseModal - A function that is called when the modal is closed. It does not take
 * any arguments and does not return anything.
 * @property handleExecuteDeviceCommand - A function that takes a string argument and does something
 * with it, likely executing a command on the device.
 */
export type TurnOffProps = {
    isOpen: boolean;
    title: string;
    subtitle: string;
    handleCloseModal: () => void;
    handleExecuteDeviceCommand: (args: string) => void;
};

/* The code block is defining a React functional component called `TurnOffModal`. It takes in
three props: `isOpen`, `handleCloseModal`, and `handleExecuteDeviceCommand`. */
export const TurnOffModal = ({
    isOpen,
    title,
    subtitle,
    handleCloseModal,
    handleExecuteDeviceCommand,
}: TurnOffProps): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <Dialog
            open={isOpen}
            onClose={handleCloseModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.rootMuiDialogpaper}
        >
            <DialogTitle id="alert-dialog-title" className={classes.dialogTitleRoot}>
                <Typography variant={'h6'}>{title}</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant={'body1'}>{subtitle}</Typography>
            </DialogContent>
            <DialogActions className={classes.MuiDialogActionsRoot}>
                <Stack direction={'row'} spacing={2} justifyContent={'space-between'} width={'100%'}>
                    <Button variant={'outlined'} onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Stack direction={'row'} spacing={2}>
                        <Button
                            variant={'contained'}
                            className="contained-primary-rounded"
                            startIcon={<PowerRedundancy />}
                            onClick={(): void => handleExecuteDeviceCommand('CYCLE')}
                        >
                            Cycle{' '}
                        </Button>
                        <Button
                            variant={'contained'}
                            className="contained-danger-rounded"
                            startIcon={<PowerSettingsNew />}
                            onClick={(): void => handleExecuteDeviceCommand('TURN_OFF')}
                        >
                            Turn Off
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
