/* eslint-disable @typescript-eslint/no-misused-promises */
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    FormControl,
    Select,
    Stack,
    Typography,
    Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useStyles } from './styles';
import Hierarchy from '../../components/Hierarchy';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { useEditDeviceMutation } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { setToastifyContent } from '@fiji/common/src/features/common/commonSlice';
import Loader from '../../components/Loader';
import { selectHierarchyData } from '@fiji/common/src/features/group/groupSlice';

/**
 * The ModalProps type is used to define the props for a modal component in a TypeScript React
 * application.
 * @property {boolean} isOpen - A boolean value indicating whether the modal is open or not.
 * @property closeHandler - The `closeHandler` property is a function that will be called when the
 * modal is closed. It does not take any arguments and does not return anything.
 * @property {any} groupId - The `groupId` property is of type `any` and represents the identifier of a
 * group. It can be any value, such as a number or a string, that uniquely identifies a group.
 * @property {string | undefined} deviceId - The `deviceId` property is a string or undefined value
 * that represents the ID of a device.
 * @property refetchDetailsCall - The `refetchDetailsCall` property is a function that can be called to
 * trigger a refetch of some data. It is typically used when you want to update the details of a
 * specific group or device after performing some action.
 */
type ModalProps = {
    isOpen: boolean;
    closeHandler: () => void;
    groupId: any;
    deviceId: string | undefined;
    refetchDetailsCall: () => void;
};

export const EditDeviceGroupModal = (props: ModalProps): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();

    const [selectedGroupId, setSelectedGroupId] = React.useState(props?.groupId);
    const [openSelectBox, setOpenSelectBox] = React.useState<boolean>(false);

    const hierarchyData = useTypedSelector(selectHierarchyData);

    const [editDeviceDetail, { isSuccess, isLoading }] = useEditDeviceMutation();

    /* The `React.useEffect` hook is used to perform side effects in a functional component. In this
    case, the effect is triggered when the `isSuccess` variable changes. */
    React.useEffect(() => {
        if (isSuccess) {
            props.refetchDetailsCall();
            props.closeHandler();
        }
    }, [isSuccess]);

    /* The `React.useEffect` hook is used to perform side effects in a functional component. In this
    case, the effect is triggered when the `props?.groupId` value changes. */
    React.useEffect(() => {
        if (props?.groupId) {
            setSelectedGroupId(props.groupId);
        }
    }, [props?.groupId]);

    /**
     * The function `handleToggleSelectBox` toggles the state of `openSelectBox` between `true` and
     * `false`.
     */
    const handleToggleSelectBox = (): void => {
        setOpenSelectBox((prev) => !prev);
    };

    /**
     * The function `handleGroupSelect` updates the selected group ID and closes the select box.
     * @param {string} id - The `id` parameter is a string that represents the selected group's
     * identifier.
     */
    const handleGroupSelect = (id: string): void => {
        setSelectedGroupId(id);
        setOpenSelectBox(false);
    };

    /**
     * The function `getGroupLabel` recursively searches for a group with a specific ID in a nested
     * data structure and returns its name.
     * @param {any} source - The `source` parameter is an array of objects. Each object represents a
     * group and has properties like `id` and `name`.
     * @param {any} groupId - The `groupId` parameter is the identifier of the group for which we want
     * to retrieve the label.
     * @returns The function `getGroupLabel` returns a string value if a matching group ID is found in
     * the source array, otherwise it returns `null`.
     */
    const getGroupLabel = (source: any, groupId: any): string | null => {
        let result = null;
        source?.forEach((item: any) => {
            if (item.id === groupId) {
                result = item.name;
            } else if (item.children) {
                const label = getGroupLabel(item.children, groupId);
                if (label) {
                    result = label;
                }
            }
        });
        return result;
    };

    /**
     * The function `getSelectedGroupLabel` returns the label of the selected group from a given
     * hierarchy.
     */
    const getSelectedGroupLabel = (): string => getGroupLabel(hierarchyData?.data?.data?.data, selectedGroupId) ?? '';

    /**
     * The submitHandler function is an asynchronous function that calls the editDeviceDetail function
     * with the selectedGroupId and props.deviceId as parameters.
     */
    const submitHandler = async (): Promise<void> => {
        const { error }: any = await editDeviceDetail({ payload: { groupId: selectedGroupId }, id: props.deviceId });

        if (!error) {
            dispatch(setToastifyContent({ isOpen: true, message: 'Changes Saved Successfully' }));
        }
    };

    /**
     * The function `handleModalClose` closes a modal and sets the selected group ID.
     */
    const handleModalClose = (): void => {
        props.closeHandler();
        setSelectedGroupId(props?.groupId);
    };

    return (
        <Dialog
            open={props?.isOpen}
            onClose={props?.closeHandler}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.rootMuiDialogpaper}
        >
            <DialogTitle id="alert-dialog-title" className={classes.dialogTitleRoot}>
                <Typography variant={'h6'}>Edit Group </Typography>
            </DialogTitle>
            <DialogContent>
                {isLoading ? (
                    <Box sx={{ height: '75px', overflow: 'hidden' }}>
                        <Loader size={40} />
                    </Box>
                ) : (
                    <FormControl variant={'filled'} required fullWidth>
                        <Select
                            displayEmpty
                            id="groupId"
                            labelId="demo-customized-select-label"
                            disabled={hierarchyData?.isLoading}
                            renderValue={getSelectedGroupLabel}
                            value={getSelectedGroupLabel() ? ' ' : ''}
                            open={openSelectBox}
                            onClose={handleToggleSelectBox}
                            onOpen={handleToggleSelectBox}
                        >
                            <Hierarchy
                                key="fe2facr1fFbta"
                                selectedNodes={selectedGroupId}
                                onChangeTreeItem={handleGroupSelect}
                                hierarchyData={hierarchyData?.data?.data?.data}
                            />
                        </Select>
                    </FormControl>
                )}
            </DialogContent>
            <DialogActions className={classes.MuiDialogActionsRoot}>
                <Stack direction={'row'} spacing={2} justifyContent={'end'}>
                    <Button variant={'outlined'} onClick={handleModalClose}>
                        Cancel
                    </Button>
                    <Button
                        disabled={!selectedGroupId || selectedGroupId === props.groupId || isLoading}
                        variant={'contained'}
                        onClick={submitHandler}
                    >
                        Save
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
