import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { Organization } from '../../types';

const initialSelectedOrgState: Organization = {
    adminCount: 0,
    deviceCount: 0,
    groupCount: 0,
    id: '',
    logo: '',
    mfa: true,
    name: '',
    orgCode: '',
    selected: false,
    status: '',
    orgAdmin: false,
    roleId: '',
};

export type OrgFlow = {
    flow: string;
    isOpen: boolean;
};

const orgSlice = createSlice({
    name: 'organizationMob',
    initialState: {
        selectedOrg: initialSelectedOrgState,
        switchOrgModal: false,
        orgFlowModal: { isOpen: false, flow: '' },
    },
    reducers: {
        setSelectedOrg: (state, action: PayloadAction<Organization>) => {
            const org: Organization = action.payload;
            state.selectedOrg = org;
        },
        resetSelectedOrg: (state) => {
            state.selectedOrg = initialSelectedOrgState;
        },

        switchOrgModal: (state, { payload }: PayloadAction<boolean>) => {
            state.switchOrgModal = payload;
        },
        orgFlowModal: (state, { payload }: PayloadAction<any>) => {
            state.orgFlowModal = payload;
        },
    },
});

export const { setSelectedOrg, switchOrgModal, resetSelectedOrg } = orgSlice.actions;
export default orgSlice;

export const selectedOrg = (state: RootState): Organization => state.organizationMob.selectedOrg;
export const selectSwitchOrgModal = (state: RootState): boolean => state.organizationMob.switchOrgModal;
export const selectOrgFlowModal = (state: RootState): OrgFlow => state.organizationMob.orgFlowModal;
