import React from 'react';
import { TableRow, TableCell, Skeleton, TableBody, Checkbox } from '@mui/material';
import { createNumArray } from '../CommonUtils';

/**
 * The `TableSkeleton` function returns a JSX element representing a skeleton loading animation for a
 * table with a specified number of rows and headers.
 * @param {any} header - The `header` parameter is an object that contains information about the
 * header. It may have the following properties:
 * @returns The `TableSkeleton` component is returning a JSX element representing a table body with
 * multiple rows and cells. Each cell contains a skeleton loading animation based on the provided
 * header object.
 */
export const TableSkeleton = ({ row = 10, headers }: { row?: number; headers: any }): JSX.Element => {
    /**
     * The function `getSkeletonHtml` returns a JSX element representing a skeleton loading animation
     * based on the provided header object.
     * @param {any} header - The `header` parameter is an object that contains information about the
     * header. It may have the following properties:
     * @returns The function `getSkeletonHtml` returns a JSX element.
     */
    const getSkeletonHtml = (header: any): JSX.Element => {
        if (header?.skeleton) {
            return header?.skeleton;
        } else if (header?.isSelectable) {
            return (
                <Skeleton animation="wave" variant="rounded" width={20} height={20} sx={{ margin: '0 auto' }}>
                    <Checkbox color="primary" />
                </Skeleton>
            );
        }
        return <Skeleton animation="wave" variant="text" />;
    };
    return (
        <TableBody>
            {createNumArray(row).map((rowItem) => (
                <TableRow key={`unique${rowItem}`}>
                    {headers?.map((header: any) => (
                        <TableCell key={`unique${header?.header || ''} `}>{getSkeletonHtml(header)}</TableCell>
                    ))}
                </TableRow>
            ))}
        </TableBody>
    );
};
