/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { useStyles } from './styles';
import { useTheme } from '@mui/material/styles';
import {
    useMediaQuery,
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    Stack,
} from '@mui/material';

/**
 * The above type represents the props for an error modal component in a TypeScript React application.
 * @property {any[]} messages - The `messages` property is an array that contains any error messages
 * that need to be displayed in the error modal.
 * @property {boolean} isVisible - The `isVisible` property is a boolean that determines whether the
 * error modal should be visible or not.
 * @property modalHandler - The `modalHandler` property is a function that takes a boolean parameter
 * and does some action based on that parameter. It is used to handle the visibility of the error
 * modal.
 * @property retryHandler - The `retryHandler` property is a function that takes an argument `e` of
 * type `any` and returns a `Promise` that resolves to `void`. It is used to handle retrying an action
 * or operation when it fails.
 * @property clearPayload - The `clearPayload` property is a function that is used to clear any data or
 * payload related to the error. It does not take any arguments and does not return anything.
 * @property {string | undefined} deviceId - The `deviceId` property is a string that represents the
 * unique identifier of a device. It is optional and can be either a string value or `undefined`.
 */
type ErrorModalProps = {
    messages: any[];
    isVisible: boolean;
    modalHandler: (action: boolean) => void;
    retryHandler: (e: any) => Promise<void>;
    clearPayload: () => void;
    deviceId: string | undefined;
};

/* The code is defining a functional component called `ErrorModal` that renders a dialog box to display
error messages. */
export const ErrorModal = ({ isVisible, modalHandler, ...props }: ErrorModalProps): JSX.Element => {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles(theme);

    /**
     * The function `newDeviceHandler` closes a modal and clears the payload.
     */
    const newDeviceHandler = (): void => {
        modalHandler(false);
        props.clearPayload();
    };

    return (
        <Dialog
            open={isVisible}
            onClose={(): void => modalHandler(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.muiDialogpaper}
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant={'h6'}> {'Error Saving'}</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant={'body1'} mb={1}>
                    {'An error occurred while saving.'}
                </Typography>
                {props?.messages?.map((message: string) => (
                    <Typography key={message} variant={'body1'} mb={1}>
                        &quot;{message}&quot;
                    </Typography>
                ))}
            </DialogContent>
            <DialogActions
                className={classes.MuiDialogActionsRoot}
                sx={{ border: 'none !important', pt: '0 !important' }}
            >
                {sm ? null : (
                    <Stack direction={'column'} justifyContent={'flex-end'}>
                        <Button
                            variant={'text'}
                            sx={{ justifyContent: 'end' }}
                            onClick={(): void => modalHandler(false)}
                        >
                            Cancel
                        </Button>
                        <Button variant={'text'} sx={{ justifyContent: 'end' }} onClick={newDeviceHandler}>
                            Add New Device
                        </Button>
                        <Button variant={'text'} sx={{ justifyContent: 'end' }} onClick={props.retryHandler}>
                            Try again
                        </Button>
                    </Stack>
                )}
                {smDown ? null : (
                    <Grid container>
                        <Grid item xs={4}>
                            <Button variant={'outlined'} onClick={(): void => modalHandler(false)}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={8} textAlign={'end'}>
                            {!props.deviceId && (
                                <Button variant={'outlined'} sx={{ mr: 2 }} onClick={newDeviceHandler}>
                                    Add New Device
                                </Button>
                            )}

                            <Button variant={'contained'} onClick={props.retryHandler}>
                                Try again
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </DialogActions>
        </Dialog>
    );
};
