import { api } from '../../app/api/baseApi';

export const userManagementApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllUsers: builder.query({
            query: (body) => ({
                url: `/iam/v1/users/filter`,
                method: 'POST',
                body: body,
            }),
            providesTags: ['AllUsers'],
        }),
        getAllRoles: builder.query<object, void>({
            query: () => ({ url: `/iam/v1/roles` }),
        }),
        getGroupAssignedUsers: builder.query({
            query: (payload) => ({
                url: `/iam/v1/groups/${payload.groupId}/users`,
            }),
            providesTags: ['AssignedUsers'],
        }),
        createUser: builder.mutation({
            query: (body) => ({
                url: `/iam/v1/users`,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['AssignedUsers', 'Orgs', 'AllUsers'] : []),
        }),
        updateUserProfile: builder.mutation({
            query: (body) => ({
                url: `/iam/v1/users/me/profile`,
                method: 'PATCH',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Profile'] : []),
        }),
        deleteUser: builder.mutation({
            query: (body) => ({
                url: `/iam/v1/users`,
                method: 'DELETE',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['AssignedUsers', 'Orgs', 'AllUsers'] : []),
        }),

        disableUser: builder.mutation({
            query: (body) => ({
                url: `/iam/v1/users/access`,
                method: 'PATCH',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Orgs', 'UserById', 'AllUsers'] : []),
        }),
        getUserById: builder.query({
            query: (id) => ({
                url: `/iam/v1/users/${id}`,
            }),
            providesTags: ['UserById'],
        }),

        editUser: builder.mutation({
            query: (payload: { id: any; body: any }) => ({
                url: `/iam/v1/users/${payload.id}`,
                method: 'PATCH',
                body: payload.body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Orgs', 'AllUsers'] : []),
        }),
        resetPasswordForInvitedUser: builder.mutation({
            query: (body) => ({
                url: `/iam/v1/users/orgs/reset`,
                method: 'POST',
                body: body,
            }),
        }),
        approveUsers: builder.mutation({
            query: (body) => ({
                url: `/iam/v1/users/request/approve`,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['UserById', 'AllUsers', 'Orgs'] : []),
        }),
        resendInvitation: builder.mutation({
            query: (body) => ({
                url: `/iam/v1/users/invite/resend`,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['UserById', 'AllUsers'] : []),
        }),
        updateNotifications: builder.mutation({
            query: (payload: { id: any; body: any }) => ({
                url: `/iam/v1/users/orgs${payload.id}/notification`,
                method: 'PUT',
                body: payload.body,
            }),
        }),
        assignRoleToUser: builder.mutation({
            query: (payload: { userId: string; roleId: string }) => ({
                url: `/iam/v1/users/${payload.userId}/assign/role`,
                method: 'PATCH',
                body: { roleId: payload.roleId },
            }),
        }),
    }),
});

export const {
    useGetGroupAssignedUsersQuery,
    useApproveUsersMutation,
    useEditUserMutation,
    useGetAllUsersQuery,
    useResetPasswordForInvitedUserMutation,
    useCreateUserMutation,
    useDeleteUserMutation,
    useUpdateUserProfileMutation,
    useDisableUserMutation,
    useGetUserByIdQuery,
    useAssignRoleToUserMutation,
    useResendInvitationMutation,
    useGetAllRolesQuery,
} = userManagementApi;

export const {
    endpoints: { getAllUsers, createUser, updateUserProfile, assignRoleToUser },
} = userManagementApi;
