import React from 'react';
import { ProjectAuthUIActions } from '../store/actions/AuthUIActions';
import { ProjectRegistrationUIActions } from '../store/actions/RegistrationUIActions';
import { RoutingContext } from '@brightlayer-ui/react-auth-workflow/dist/contexts/RoutingContext';
import { AuthUIContextProvider, AuthUIInternalStore, RegistrationData } from '@brightlayer-ui/react-auth-workflow';
import { CustomDetailsScreen } from '../components/CustomDetailsScreen';
import { CustomRegistrationSuccessScreen } from '../components/CustomRegistrationSuccessScreen';

/**
 * The below function is a TypeScript React component that provides configuration for an authentication
 * user interface.
 * @param props - The `props` parameter is an object that contains a single property `children`, which
 * is of type `JSX.Element`.
 * @returns a JSX element.
 */
const AuthUIConfiguration = (props: { children: JSX.Element }): JSX.Element => {
    /* The `securityActions` object is defining four functions: `onUserAuthenticated`,
    `onUserNotAuthenticated`, `showChangePassword`, and `hideChangePassword`. These functions are
    placeholders and currently do not have any implementation logic. They are likely intended to be
    used as callbacks for handling user authentication and password change events in the
    authentication user interface. */
    const securityActions = {
        onUserAuthenticated: (): void => {},
        onUserNotAuthenticated: (): void => {},
        showChangePassword: (): void => {},
        hideChangePassword: (): void => {},
    };

    const routingContextValue = React.useMemo(
        () => ({
            routes: {
                REGISTER_SELF: '',
                LOGIN: '/login',
                FORGOT_PASSWORD: '',
                RESET_PASSWORD: '',
                SUPPORT: '',
                REGISTER_INVITE: '',
            },
        }),
        []
    );

    const registrationSuccessScreen = (registrationData: RegistrationData): JSX.Element => (
        <CustomRegistrationSuccessScreen registrationData={registrationData} />
    );

    const accountAlreadyExistsScreen = (): JSX.Element => <CustomRegistrationSuccessScreen accountExists />;
    return (
        <AuthUIContextProvider
            authActions={ProjectAuthUIActions(securityActions)}
            registrationActions={ProjectRegistrationUIActions}
            background={{
                backgroundImage: ` url(${require('../assets/images/loginBg.png')})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundColor: `rgba(3, 98, 167, 1)`,
            }}
            htmlEula={true}
            customAccountDetails={[{ component: CustomDetailsScreen }]}
            enableCreatePassword={false}
            registrationSuccessScreen={registrationSuccessScreen}
            accountAlreadyExistsScreen={accountAlreadyExistsScreen}
        >
            <AuthUIInternalStore>
                <RoutingContext.Provider value={routingContextValue}>{props.children}</RoutingContext.Provider>
            </AuthUIInternalStore>
        </AuthUIContextProvider>
    );
};

export default AuthUIConfiguration;
