/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Dialog, DialogTitle, Typography, DialogContent, Checkbox, DialogActions, Stack, Button } from '@mui/material';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { useConfirm, useSelectedIds } from '../hooks';
import { useStyles } from '../pages/ManageDevices/styles';
import Loader from './Loader';
import DeleteModal from './DeleteModal';

/**
 * The `MultiItemsModalProps` type is used to define the props for a multi-items modal component in a
 * TypeScript React application.
 * @property {boolean} isLoading - A boolean value indicating whether the data is currently being
 * loaded or not.
 * @property {string} modalTitle - The title of the modal component.
 * @property {string} keyToTraverse - The `keyToTraverse` property is a string that represents the key
 * to traverse in the `filteredData` array. This key is used to access the value of each item in the
 * array.
 * @property {string} nameKey - The `nameKey` property is an optional string that represents the key in
 * the `filteredData` array that contains the name or label of each item.
 * @property {any[]} filteredData - The `filteredData` property is an array of objects that represents
 * the data to be displayed in the modal. Each object in the array should have a key-value pair where
 * the key is the `keyToTraverse` property and the value is the name of the item.
 * @property {string[]} selectedIds - An array of string values representing the selected item IDs.
 * @property {string} modalBodyText - A string that represents the text to be displayed in the body of
 * the modal.
 * @property selectionHandler - A function that takes an id (string) as a parameter and returns any
 * value. This function is used to handle the selection of an item in the modal.
 * @property deleteHandler - A function that will be called when the delete action is triggered.
 * @property deleteModalDescription - The `deleteModalDescription` property is a JSX element that
 * represents the description or content of the delete modal. It can contain any valid JSX content such
 * as text, HTML tags, or other React components.
 * @property {string} deleteModalHeading - The `deleteModalHeading` property is a string that
 * represents the heading or title of the delete modal.
 */
type MultiItemsModalProps = {
    isLoading: boolean;
    modalTitle: string;
    keyToTraverse: string;
    nameKey?: string;
    filteredData: any[];
    selectedIds: string[];
    modalBodyText: string;
    deleteHandler: () => void;
    deleteModalDescription: JSX.Element;
    deleteModalHeading: string;
    deleteButtonText: string;
    handleIdsToBeDeleted: any;
};

/* The below code is defining a React functional component called `MultiItemsDeleteModal`. It is a
modal component that displays a list of items with checkboxes and allows the user to delete selected
items. */
const MultiItemsDeleteModal = React.forwardRef((props: MultiItemsModalProps, ref): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const deleteModalId: any = React.useId();
    const [selectedIds, setSelectedIds, selectionHandler] = useSelectedIds();

    const [isModalVisible, setIsModalVisible] = React.useState(false);

    React.useEffect(() => {
        if (props?.selectedIds) {
            setSelectedIds(props.selectedIds);
        }
    }, [props?.selectedIds]);
    React.useEffect(() => {
        if (selectedIds) {
            props.handleIdsToBeDeleted(selectedIds);
        }
    }, [selectedIds]);

    /**
     * The function `modalHandler` takes a boolean parameter `action` and sets the state variable
     * `isModalVisible` to that value.
     * @param {boolean} action - The `action` parameter is a boolean value that determines whether the
     * modal should be visible or not. If `action` is `true`, the modal will be visible. If `action` is
     * `false`, the modal will be hidden.
     */
    const modalHandler = (action: boolean): void => {
        setIsModalVisible(action);
    };

    /**
     * The function `getInfoTitle` returns a string representing the title of some data, based on
     * certain conditions.
     * @param {any} data - The `data` parameter is an object that contains information about a person,
     * such as their first name, last name, and other properties.
     * @returns The function `getInfoTitle` returns a string value.
     */
    const getInfoTitle = (data: any): string => {
        let result = '';
        if (props.nameKey) {
            result = data[props.nameKey];
        } else if (data.firstName || data.lastName) {
            result = `${data?.firstName || ''} ${data?.lastName || ''}`;
        } else {
            result = 'N/A';
        }

        return result;
    };

    /* The `React.useImperativeHandle` hook is used to expose certain functions or methods of a child
    component to its parent component. In this case, it is used to expose the `modalHandler`
    function of the `MultiItemsDeleteModal` component to its parent component. */
    React.useImperativeHandle(ref, () => ({
        modalHandler: (action: boolean): void => modalHandler(action),
    }));

    const {
        isVisible: isDeleteModalOpen,
        onClick: openDeleteModal,
        onCancel: closeDeleteModal,
        onConfirm: confirmDeletion,
    } = useConfirm(props.deleteHandler);

    return (
        <>
            <Dialog
                open={isModalVisible}
                onClose={(): void => modalHandler(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.muiDialogpaper}
            >
                {props.isLoading ? (
                    <div style={{ position: 'relative' }}>
                        <Loader />
                    </div>
                ) : (
                    <>
                        <DialogTitle id="alert-dialog-title">
                            <Typography variant={'h6'} mb={2}>
                                {' '}
                                {props.modalTitle}
                            </Typography>
                            <Typography variant={'body1'}>{props.modalBodyText}</Typography>
                        </DialogTitle>
                        <DialogContent>
                            {props?.filteredData?.map((item: any) => (
                                <InfoListItem
                                    key={item[props.keyToTraverse]}
                                    className={classes.infoListItem}
                                    data-testid="infoListItem"
                                    data-cy={'list-content'}
                                    title={getInfoTitle(item)}
                                    avatar={false}
                                    hidePadding
                                    icon={
                                        <Checkbox
                                            checked={
                                                Boolean(selectedIds.length) &&
                                                selectedIds.includes(item[props.keyToTraverse])
                                            }
                                            onChange={selectionHandler(item[props.keyToTraverse])}
                                        />
                                    }
                                />
                            ))}
                        </DialogContent>
                        <DialogActions id="delete-user-dialog" className={classes.MuiDialogActionsRoot}>
                            <Stack direction={'row'} spacing={2} justifyContent={'end'}>
                                <Button
                                    variant={'outlined'}
                                    onClick={(): void => {
                                        modalHandler(false);
                                        setSelectedIds(props.selectedIds);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant={'contained'}
                                    disabled={!selectedIds?.length}
                                    onClick={(): void => {
                                        modalHandler(false);
                                        openDeleteModal(undefined);
                                    }}
                                >
                                    Continue
                                </Button>
                            </Stack>
                        </DialogActions>
                    </>
                )}
            </Dialog>

            <DeleteModal
                key={deleteModalId}
                closeHandler={closeDeleteModal}
                confirmClick={confirmDeletion}
                deleteButtonText={props?.deleteButtonText ?? 'Delete'}
                isVisible={isDeleteModalOpen}
                wrapperClass={classes.muiDialogpaper}
                actionClass={classes.MuiDialogActionsRoot}
                titleClass={classes.MuiDialogTitleRoot}
                description={props.deleteModalDescription}
                deleteButtonClass={classes.deleteButton}
                headingText={props.deleteModalHeading}
            />
        </>
    );
});

export default MultiItemsDeleteModal;
