import React, { useCallback, useState } from 'react';
import {
    Box,
    Card,
    Typography,
    Button,
    Container,
    Grid,
    Avatar,
    Stack,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
// import * as Colors from '@brightlayer-ui/colors';
// import Loader from '../../components/Loader';
import { useConfirm, useImageValidated, useRBAC } from '../../hooks';
import { BackdropLoader } from '../../components/BackdropLoader';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';
import { InfoListItem } from '@brightlayer-ui/react-components/core/InfoListItem';
import IconButton from '@mui/material/IconButton';
import DeleteModal from '../../components/DeleteModal';
import { useOktaAuth } from '@okta/okta-react';
import {
    Email,
    Call,
    Create,
    Shield,
    MoreVert,
    PersonOff,
    PersonRemove,
    FileUpload,
    Person,
    DeleteForever,
} from '@mui/icons-material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Switch from '@mui/material/Switch';

import {
    useGetUserProfileQuery,
    useRemoveProfileImageMutation,
    useUploadProfileLogoMutation,
    useDeleteProfileMutation,
} from '@fiji/common/src/features/profile/profileApi';
import { RefetchConfigOptions } from '@reduxjs/toolkit/dist/query/core/apiState';
import {
    useDisableUserMutation,
    useGetUserByIdQuery,
    useUpdateUserProfileMutation,
} from '@fiji/common/src/features/userManagement/userManagement';
import { useNavigate, useParams, useLocation } from 'react-router';
import { EmptyState, ListItemTag, UserMenu, UserMenuItem } from '@brightlayer-ui/react-components';
import { getUserInitials } from '../../CommonUtils';

import { UserProfile } from '@fiji/common/src/types';
import * as Colors from '@brightlayer-ui/colors';
import { useGetOrgQuery } from '@fiji/common/src/features/orgManagement/orgApi';
import {
    CountryCodeModal,
    PhoneNumberModal,
    Preferences,
    Roles,
    UserNameModal,
    DeleteUserModal,
    ProfileSkeleton,
} from './index';
import { useStyles } from './styles';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { setPageHeaderData, setToastifyContent, setIsSignOut } from '@fiji/common/src/features/common/commonSlice';

export const MyProfile = (): JSX.Element => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const classes = useStyles(theme);
    const { id } = useParams<{ id: string }>();
    const [getValidator] = useImageValidated();
    const location = useLocation();
    const rolePermissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(rolePermissions);
    const canUpdateUser = hasPermission('update-user');
    const canEnableDisableUser = hasPermission('enable-disable-user');
    const canDeleteUser = hasPermission('delete-user');

    const [activeModal, setActiveModal] = useState<string>('');
    const [permanentlyDeletedModal, setPermanentlyDeletedModal] = useState<boolean>(false);
    const [isImageUploaded, setImageIsUploaded] = useState<boolean>(false);
    const { oktaAuth } = useOktaAuth();
    const [orgIds, setOrgIds] = useState<any>([]);
    const [disableUser, { isLoading: disableUserLoading }] = useDisableUserMutation();
    const [removeProfileImage] = useRemoveProfileImageMutation();
    const [deleteProfile, { isLoading: isDeleteProfile }] = useDeleteProfileMutation();
    const [uploadProfileLogo, { isLoading }] = useUploadProfileLogoMutation();
    const [enableDeleteButton, setEnableDeleteButton] = useState<boolean>(true);
    const [updateUserProfile, { isLoading: editUserLoader }] = useUpdateUserProfileMutation({
        fixedCacheKey: 'updateUserProfile',
    });
    const {
        data: activeOrgs,
        // refetch: refreshActiveOrgs,
        isLoading: isLoadingActiveOrg,
    } = useGetOrgQuery<{
        data: any;
        isLoading: boolean;
        isFetching: boolean;
        // refetch: RefetchConfigOptions;
    }>({ page: 0, size: 25, status: 'ACTIVE' });
    const {
        data: pendingOrgs,
        // refetch: refreshPendingOrgs,
        isLoading: isLoadingPendingOrgs,
    } = useGetOrgQuery<{
        data: any;
        isLoading: boolean;
        isFetching: boolean;
        // refetch: RefetchConfigOptions;
    }>({ page: 0, size: 25, status: 'PENDING' });
    const {
        data: userProfileData,
        isLoading: profileLoader,
        isFetching: fetchingProfile,
        refetch: refetchUserProfile,
    } = useGetUserProfileQuery<{
        data: UserProfile;
        isLoading: boolean;
        isFetching: boolean;
        refetch: RefetchConfigOptions;
    }>(undefined, { refetchOnMountOrArgChange: true });
    // const {
    //     data: usersList,
    //     // refetch: refetchAllUsers,
    //     isLoading: usersLoader,
    //     isFetching,
    // } = useGetAllUsersQuery<{
    //     data: AllUserObj;
    //     isLoading: boolean;
    //     isFetching: boolean;
    //     refetch: RefetchConfigOptions;
    // }>(
    //     { page: 0, size: 100, sort: { key: 'role', sortType: 'ASC' }, filters: { status: ['ACTIVE'] } },
    //     { skip: false }
    // );
    const {
        currentData: invitedUserData,
        refetch: refetchUser,
        isLoading: isLoadingUserById,
    } = useGetUserByIdQuery(id, { skip: !id });

    React.useEffect(() => {
        if (userProfileData?.data?.image) setImageIsUploaded(true);
        else setImageIsUploaded(false);
    }, [userProfileData]);

    React.useEffect(() => {
        if (invitedUserData) dispatch(setPageHeaderData({ userName: getUsername() }));
    }, [invitedUserData]);
    React.useEffect(() => {
        if (location.pathname !== '/profile') {
            if (userProfileData?.data?.id !== invitedUserData?.data?.id) {
                setEnableDeleteButton(false);
            } else {
                setEnableDeleteButton(true);
            }
        }
        // if (userProfileData?.data?.id) {
        //     setEnableDeleteButton(true);
        // }
    }, [userProfileData, invitedUserData]);

    const disableHandler = async (): Promise<void> => {
        const { error }: any = await disableUser({ access: !invitedUserData?.data?.access, userIds: [id] });
        if (!error) {
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: invitedUserData?.data?.access
                        ? `User Accounts have been disabled`
                        : `User Accounts have been enabled`,
                })
            );
            await refetchUser();
        }
    };

    const mfaUpdateCallHandler = async (): Promise<void> => {
        const { error }: any = await updateUserProfile({ mfa: !userProfileData?.data?.mfa });
        if (!error) {
            await refetchUserProfile();
        }
    };

    const handleUploadLogo = async (e: any): Promise<void> => {
        const target = e.target as HTMLInputElement;

        if (!target.files) return;
        if (getValidator(target.files[0])) {
            const formData = new FormData();
            formData.append('image', target.files[0]);
            const { error }: any = await uploadProfileLogo(formData);
            if (!error) {
                await refetchUserProfile();
            }
        }
        e.target.value = null;
    };

    const removeUploadedLogo = async (): Promise<void> => {
        const { error }: any = await removeProfileImage();
        if (!error) {
            await refetchUserProfile();
        }
    };

    const activeModalHandler = (action: string): void => {
        setActiveModal(action);
    };

    const handleStatusBackgroundColor = (type: string): any => {
        switch (type) {
            case 'INVITED':
                return '#E0F1FD';

            case 'ACTIVE':
                return '#39B620';

            case 'INACTIVE':
                return '#727e84';
            default:
                return '#727E84';
        }
    };

    const handleStatusColor = (type: string): any => {
        switch (type) {
            case 'INVITED':
                return '#0088F2 !important';
            case 'ACTIVE':
                return '#FFFFFF!important';
            case 'INACTIVE':
                return '#EEF0F0 !important';
            default:
                return '#EEF0F0 !important';
        }
    };

    const getMenuItems = (): UserMenuItem[] => {
        const menuItems = [];
        if (canUpdateUser) {
            menuItems.push({
                title: 'Edit User Details',
                icon: <Create />,
                onClick: (): void => navigate(`/user/edit/${id || ''}`),
            });
        }
        if (
            canEnableDisableUser &&
            id !== userProfileData?.data?.id &&
            (invitedUserData?.data?.status === 'INACTIVE' || invitedUserData?.data?.status === 'ACTIVE')
        ) {
            menuItems.push({
                title: invitedUserData?.data?.access === true ? 'Disable User' : 'Enable User',
                icon: invitedUserData?.data?.access === true ? <PersonOff /> : <Person />,
                onClick: disableHandler,
            });
        }
        if (canDeleteUser && id !== userProfileData?.data?.id) {
            menuItems.push({
                title: 'Delete User',
                icon: <PersonRemove />,
                onClick: (): void => setPermanentlyDeletedModal(true),
            });
        }
        return menuItems;
    };

    const getRightMenuComponent = (): JSX.Element | undefined => {
        if (id && Boolean(getMenuItems()?.length)) {
            return (
                <UserMenu
                    avatar={
                        <IconButton aria-label="settings">
                            <MoreVert />
                        </IconButton>
                    }
                    menuGroups={[
                        {
                            items: getMenuItems(),
                        },
                    ]}
                />
            );
        } else if (!id) {
            return (
                <IconButton
                    id="edit-username"
                    aria-label="settings"
                    onClick={(): void => setActiveModal('usernameModal')}
                >
                    <Create sx={{ color: Colors.gray[500] }} />
                </IconButton>
            );
        }
    };

    const getPhoneNumber = (): string => {
        if (id) {
            return `${invitedUserData?.data?.areaCode || ''}${invitedUserData?.data?.phoneNumber || '-'}`;
        }
        return `${userProfileData?.data?.areaCode || ''}${userProfileData?.data?.phoneNumber || '-'}`;
    };

    const getCountryLabel = (): string => {
        if (id) {
            return invitedUserData?.data?.country || '-';
        }
        return userProfileData?.data?.country || '-';
    };

    const getUsername = (): string => {
        if (id) {
            return `${invitedUserData?.data?.firstName || '-'} ${invitedUserData?.data?.lastName || ''}`;
        }
        return `${userProfileData?.data?.firstName || '-'} ${userProfileData?.data?.lastName || ''}`;
    };

    const getBadgeLabel = (): string => {
        if (invitedUserData?.data?.status === 'INACTIVE') {
            return 'INACTIVE';
        }
        return invitedUserData?.data?.status ?? 'N/A';
    };
    const handleUserLogout = useCallback(async (): Promise<void> => {
        dispatch(setIsSignOut(true));
        oktaAuth.clearStorage();
        await oktaAuth.signOut();
    }, []);
    const handleDeleteUserApiCall = async (): Promise<void> => {
        const { error }: any = await deleteProfile({ organizationIds: orgIds });
        if (!error) {
            void handleUserLogout();
            navigate('/login');

            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: 'Your Account has been successfully deleted.',
                    duration: 3000,
                })
            );
        }
    };

    /* The below code is defining a function called `handle` using the `useCallback`
    hook. */
    const handleDeleteUser = useCallback((): void => {
        if (activeOrgs?.data?.records || pendingOrgs?.data?.records) {
            const isOrgAdmin = activeOrgs?.data?.records.some(
                (element: any) => element.roleName === 'Organization Admin'
            );

            if (isOrgAdmin === true) {
                setActiveModal('assignUsersPrompt');
            } else {
                // const filteredOrgs = activeOrgs?.data?.records.filter((element: any) => element.rolename === 'Organization Admin');
                const filterOrgIds = activeOrgs?.data?.records.map((element: any) => element.id);
                const filteredPendingOrgs =
                    pendingOrgs?.data?.records.length > 0
                        ? pendingOrgs?.data?.records.map((element: any) => element.id)
                        : [];

                setOrgIds([...filterOrgIds, ...filteredPendingOrgs]);

                setActiveModal('assignUserDeletePrompt');
            }
        }

        // if (isAssignAdmin === false) {
        //     setActiveModal('assignUsersPrompt');
        // }
    }, [activeModal, activeOrgs, pendingOrgs]);
    const {
        isVisible: isDeleteModalOpen,
        onClick: openDeleteModal,
        onCancel: closeDeleteModal,
        onConfirm: confirmDeletion,
    } = useConfirm(handleDeleteUserApiCall as any);
    const getFileUploadHandlerActions = (): JSX.Element => {
        if (isImageUploaded) {
            return (
                <>
                    <Button
                        color={'primary'}
                        variant={'outlined'}
                        startIcon={<FileUpload />}
                        component="label"
                        onChange={handleUploadLogo}
                        className="grey-btn"
                    >
                        <input hidden accept="image/*" type="file" />
                        Replace Image
                    </Button>
                    <Button variant="text" onClick={removeUploadedLogo} startIcon={<DeleteIcon />}>
                        Remove Image
                    </Button>
                </>
            );
        }
        return (
            <Button
                variant={'outlined'}
                color={'primary'}
                startIcon={<FileUpload />}
                component="label"
                onChange={handleUploadLogo}
                className="grey-btn"
            >
                <input hidden accept="image/*" type="file" />
                Upload an Image
            </Button>
        );
    };

    return (
        <React.Fragment>
            <header>
                <title>MyProfile</title>
                <meta name="description" content="Description of MyProfile" />
            </header>

            {isLoading ||
            editUserLoader ||
            profileLoader ||
            fetchingProfile ||
            isLoadingActiveOrg ||
            isLoadingPendingOrgs ||
            isLoadingUserById ||
            disableUserLoading ? (
                <ProfileSkeleton
                    count={id ? 2 : 3}
                    wrapperClass={classes.ContainerWrapper}
                    id={id}
                    bodyClass={classes.contentBody}
                />
            ) : (
                <>
                    <Container fixed className={classes.ContainerWrapper}>
                        {enableDeleteButton && (
                            <Stack direction={'row'} spacing={2} className={classes.deleteContainer}>
                                <Button
                                    id="delete-account"
                                    variant={'outlined'}
                                    className={classes.deleteButtonContainer}
                                    startIcon={<DeleteForever />}
                                    onClick={handleDeleteUser}
                                >
                                    Delete My Account
                                </Button>
                            </Stack>
                        )}
                        <Box className={classes.contentBody}>
                            <Card sx={{ p: 0, mb: 2 }}>
                                <Grid container>
                                    <Grid item xs={!id ? 9.2 : 12}>
                                        <InfoListItem
                                            title={
                                                <Typography sx={{ pt: 3, px: 1, pb: 1 }} variant={'h5'}>
                                                    {getUsername()}
                                                    {id && (
                                                        <ListItemTag
                                                            style={{ marginLeft: '20px' }}
                                                            label={getBadgeLabel()}
                                                            backgroundColor={handleStatusBackgroundColor(
                                                                invitedUserData?.data?.status || ''
                                                            )}
                                                            color={handleStatusColor(
                                                                invitedUserData?.data?.status || ''
                                                            )}
                                                        />
                                                    )}
                                                </Typography>
                                            }
                                            dense
                                            hidePadding
                                            rightComponent={getRightMenuComponent()}
                                        />
                                        <InfoListItem
                                            data-testid="infoListItem"
                                            data-cy={'list-content'}
                                            icon={<Email />}
                                            subtitle={'Email'}
                                            title={id ? invitedUserData?.data?.email : userProfileData?.data?.email}
                                            avatar={false}
                                            iconAlign="center"
                                            hidePadding
                                        />
                                        <InfoListItem
                                            data-testid="infoListItem"
                                            data-cy={'list-content'}
                                            icon={<Call />}
                                            subtitle={'Phone Number'}
                                            title={getPhoneNumber()}
                                            avatar={false}
                                            iconAlign="center"
                                            hidePadding
                                            rightComponent={
                                                !id && (
                                                    <IconButton
                                                        id="edit-phone"
                                                        aria-label="settings"
                                                        onClick={(): void => setActiveModal('phoneNumberModal')}
                                                    >
                                                        <Create sx={{ color: Colors.gray[500] }} />
                                                    </IconButton>
                                                )
                                            }
                                        />
                                        <InfoListItem
                                            data-testid="infoListItem"
                                            data-cy={'list-content'}
                                            icon={<LocationOnIcon />}
                                            subtitle={'Country'}
                                            title={getCountryLabel()}
                                            avatar={false}
                                            iconAlign="center"
                                            hidePadding
                                            rightComponent={
                                                !id && (
                                                    <IconButton
                                                        id="edit-country"
                                                        aria-label="settings"
                                                        onClick={(): void => setActiveModal('countryModal')}
                                                    >
                                                        <Create sx={{ color: Colors.gray[500] }} />
                                                    </IconButton>
                                                )
                                            }
                                        />
                                        {!id && (
                                            <InfoListItem
                                                data-testid="infoListItem"
                                                data-cy={'list-content'}
                                                icon={<Shield />}
                                                subtitle={'Required by the organization'}
                                                title={'Two-factor Authentication'}
                                                avatar={false}
                                                iconAlign="center"
                                                hidePadding
                                                rightComponent={
                                                    <Switch
                                                        id="mfa"
                                                        disabled={
                                                            !userProfileData?.data?.accessMfa ||
                                                            userProfileData?.data?.isSuperAdmin
                                                        }
                                                        checked={userProfileData?.data?.mfa}
                                                        onChange={mfaUpdateCallHandler}
                                                    />
                                                }
                                            />
                                        )}
                                    </Grid>
                                    {!id && (
                                        <Grid item xs={2.8}>
                                            <EmptyState
                                                className={classes.emptyState}
                                                icon={
                                                    <Avatar
                                                        sx={{ fontSize: '36px' }}
                                                        src={userProfileData?.data?.image || ''}
                                                    >
                                                        {userProfileData?.data?.firstName ||
                                                        userProfileData?.data?.lastName ? (
                                                            getUserInitials(
                                                                `${userProfileData?.data?.firstName} ${userProfileData?.data?.lastName}`
                                                            )
                                                        ) : (
                                                            <Person fontSize="inherit" />
                                                        )}{' '}
                                                    </Avatar>
                                                }
                                                title={' '}
                                                actions={getFileUploadHandlerActions()}
                                                description={
                                                    <Typography variant="caption" sx={{ marginTop: '10px' }}>
                                                        {'PNG, JPG (300x300px), 2 MB Max.'}
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Card>
                            <Roles key="aef24a5w" id={id ?? ''} invitedUserData={invitedUserData?.data} />
                            {!id && <Preferences key="fwff2dq3cadq" />}
                        </Box>
                    </Container>
                </>
            )}
            {/* Update Username modal start */}
            <UserNameModal
                key="nt21fcg1"
                activeModal={activeModal}
                activeModalHandler={activeModalHandler}
                wrapperClass={classes.muiDialogpaper}
                titleClass={classes.MuiDialogTitleRoot}
                sectionClass={classes.sectionHeader}
                formOverFlow={classes.formOverflow}
                actionsClass={classes.MuiDialogActionsRoot}
            />

            {/* Update Username modal end */}
            {/* Update Phone No modal start */}

            <PhoneNumberModal
                key="acrafybny3"
                activeModal={activeModal}
                activeModalHandler={activeModalHandler}
                wrapperClass={classes.muiDialogpaper}
                titleClass={classes.MuiDialogTitleRoot}
                sectionClass={classes.sectionHeader}
                formOverFlow={classes.phoneNumberUpdate}
                actionsClass={classes.MuiDialogActionsRoot}
                dividerClass={classes.divider}
                topDividerClass={classes.topDivider}
            />
            {/* Update Phone No modal end */}

            {/*countryCode modal */}

            <CountryCodeModal
                key="acfae31cae"
                activeModal={activeModal}
                activeModalHandler={activeModalHandler}
                wrapperClass={classes.muiDialogpaper}
                titleClass={classes.MuiDialogTitleRoot}
                sectionClass={classes.sectionHeader}
                formOverFlow={classes.formOverflow}
                actionsClass={classes.MuiDialogActionsRoot}
                dividerClass={classes.divider}
                topDividerClass={classes.topDivider}
            />

            <DeleteUserModal
                key="3dg521gsadr"
                data={invitedUserData?.data}
                isDeleteModalVisible={permanentlyDeletedModal}
                userId={id}
                modalHandler={(action: boolean): void => setPermanentlyDeletedModal(action)}
            />
            <Dialog
                open={activeModal === 'assignUserDeletePrompt'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.muiDialogpaper}
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant={'h6'} sx={{ wordBreak: 'break-word' }}>
                        {' '}
                        {`Delete Your Account?`}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant={'body2'} sx={{ wordBreak: 'break-word' }} mb={1}>
                        {`Select 'Continue' to permanently delete your account and all of it's associated information and data.`}
                    </Typography>
                </DialogContent>
                <DialogActions className={classes.MuiDialogActionsRoot}>
                    <Stack spacing={2} direction={'row'}>
                        <Button variant={'outlined'} onClick={(): void => setActiveModal('')}>
                            Cancel
                        </Button>
                        <Button
                            id="continue-delete"
                            variant={'contained'}
                            className={classes.continueButton}
                            onClick={(): void => {
                                openDeleteModal();
                                setActiveModal('');
                            }}
                        >
                            Continue
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
            <DeleteModal
                key={'delete-modal'}
                closeHandler={closeDeleteModal}
                confirmClick={confirmDeletion}
                deleteButtonText="Delete My Account"
                isVisible={isDeleteModalOpen}
                wrapperClass={classes.muiDialogpaper}
                actionClass={classes.MuiDialogActionsRoot}
                titleClass={classes.MuiDialogTitleRoot}
                description={
                    <>
                        {`Your account and all of it's associated information and data will be permanently deleted. This cannot be undone. `}
                    </>
                }
                headingText={`Delete Your Account?`}
                deleteButtonClass={classes.deleteButton}
            />
            <Dialog
                open={activeModal === 'assignUsersPrompt'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.muiDialogpaper}
            >
                {/* {usersLoader || isFetching ? (
                    <Loader size={60} />
                ) : ( */}
                {
                    <>
                        <DialogTitle id="alert-dialog-title">
                            <Typography variant={'h6'}> {'Delete Your Account.'}</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Typography variant={'body1'} mb={1}>
                                You are an organization administrator for one or more organizations. You must switch to
                                each organization for which you are an administrator, and then either delete the
                                organization or grant the administrator role to another user and leave the organization.
                                Once you have deleted or left all organizations, or are no longer an administrator for
                                any organization, you can
                                <strong>{' delete your account.'}</strong>{' '}
                            </Typography>
                        </DialogContent>
                        <DialogActions className={classes.MuiDialogActionsRoot}>
                            <Stack spacing={2} direction={'row'}>
                                <Button
                                    variant={'contained'}
                                    className={classes.continueButton}
                                    onClick={(): void => {
                                        // navigate('/profile');
                                        setActiveModal('');
                                    }}
                                >
                                    Back
                                </Button>
                            </Stack>
                        </DialogActions>
                    </>
                }
            </Dialog>

            {isDeleteProfile && <BackdropLoader isOpen={true} />}
        </React.Fragment>
    );
};
