import React, { useState, useCallback, useEffect, useRef, createRef } from 'react';
import { UserProfile } from '@fiji/common/src/types';
import {
    Dialog,
    Divider,
    DialogTitle,
    Typography,
    DialogContent,
    Box,
    TextField,
    DialogActions,
    Grid,
    FormControl,
    MenuItem,
    InputLabel,
    Button,
    Select,
    MobileStepper,
    Stack,
    Modal,
} from '@mui/material';
import {
    useGenerateMobileOtpMutation,
    useGetAreaCodesQuery,
    useGetUserProfileQuery,
    useVerifyMobileOtpMutation,
} from '@fiji/common/src/features/profile/profileApi';
import clsx from 'clsx';
import { createNumArray, handleWhiteSpaces } from '../../CommonUtils';
import { NUMBER_REGEX } from '@fiji/common/src/constants';
import Loader from '../../components/Loader';

/**
 * The PhoneNumberModalProps type defines the props for a phone number modal component in a TypeScript
 * React application.
 * @property {string} activeModal - This property represents the currently active modal. It is of type
 * string and is used to determine which modal should be displayed on the screen.
 * @property {string} wrapperClass - The `wrapperClass` property is a string that represents the CSS
 * class name for the wrapper element of the phone number modal component.
 * @property {string} titleClass - A CSS class for the title element of the phone number modal.
 * @property {string} sectionClass - The `sectionClass` property is a string that represents the CSS
 * class name for the section element in the phone number modal.
 * @property {string} formOverFlow - The `formOverFlow` property is a string that represents the CSS
 * class for the form overflow. It is used to control the overflow behavior of the form element.
 * @property {string} actionsClass - This property is used to define the CSS class for the actions
 * section of the phone number modal. It is typically used to style the buttons or other interactive
 * elements in the modal.
 * @property activeModalHandler - A function that takes a string argument and does something with it.
 * It is used to handle the active modal state in the component.
 * @property {string} topDividerClass - A CSS class name for the top divider element in the phone
 * number modal.
 * @property {string} dividerClass - A CSS class for styling the divider element.
 */
type PhoneNumberModalProps = {
    activeModal: string;
    wrapperClass: string;
    titleClass: string;
    sectionClass: string;
    formOverFlow: string;
    actionsClass: string;
    activeModalHandler: (arg0: string) => void;
    topDividerClass: string;
    dividerClass: string;
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export const PhoneNumberModal = ({
    activeModal,
    wrapperClass,
    titleClass,
    sectionClass,
    formOverFlow,
    actionsClass,
    activeModalHandler,
    dividerClass,
    topDividerClass,
}: PhoneNumberModalProps): JSX.Element => {
    const ALL_STEPS = ['asking', 'verifying', 'updated'];
    const VERIFICATION_CODE_FIELDS = [{ id: '1' }, { id: '2' }, { id: '3' }, { id: '4' }, { id: '5' }, { id: '6' }];

    const [generateMobileOtp, { isLoading: isGeneratingMobileOtp, isError, isSuccess }] =
        useGenerateMobileOtpMutation();
    const [verifyMobileOtp, { isLoading: isVerifyingMobileOtp }] = useVerifyMobileOtpMutation();

    const [userData, setUserData] = useState<Partial<UserProfile['data']>>({
        phoneNumber: '',
        areaCode: '',
    });
    const [currentStep, setCurrentStep] = useState(ALL_STEPS[0]);
    const [MFACode, setMFACode] = useState(['', '', '', '', '', '']);
    const [MFAError, setMFAError] = useState('');
    const [MFARequestCount, setMFARequestCount] = useState(0);
    const [attemptExpire, setAttemptExpire] = useState(false);

    const verificationCodeFieldsRef = useRef<any[]>(VERIFICATION_CODE_FIELDS.map(() => createRef()));

    const { data: profileDetails } = useGetUserProfileQuery<{ data: UserProfile }>(undefined);

    const { data: areaCodes } = useGetAreaCodesQuery<{ data: any }>();

    /* The below code is using the `useEffect` hook in a React component. It is triggered whenever the
    `profileDetails` variable changes. */
    useEffect(() => {
        if (profileDetails && (isSuccess || !isError)) {
            setUserData((prev) => ({
                ...prev,
                areaCode: profileDetails?.data?.areaCode ?? prev.areaCode,
                phoneNumber: profileDetails?.data?.phoneNumber ?? prev.phoneNumber,
            }));
        } else if (isError) {
            setUserData((prev) => ({
                ...prev,
                areaCode: profileDetails?.data?.areaCode ?? '',
                phoneNumber: profileDetails?.data?.phoneNumber ?? '',
            }));
        }
    }, [profileDetails, activeModal]);

    /* The below code is using the `useEffect` hook in a React component. It is checking the value of
    `MFARequestCount` and if it is equal to 10, it sets the state variable `attemptExpire` to
    `true`. The `useEffect` hook is triggered whenever the value of `MFARequestCount` changes. */
    useEffect(() => {
        if (MFARequestCount === 10) {
            setAttemptExpire(true);
        }
    }, [MFARequestCount]);

    /* The below code is a TypeScript React function component. It defines a function called
    `handleChangeVerificationCode` using the `useCallback` hook. This function is used to handle
    changes in the verification code input fields. */
    const handleChangeVerificationCode = useCallback(
        (e: any): void => {
            if (e.target.value !== '' && !Number.isNaN(Number(e.target.value))) {
                setMFAError('');
                let MFACodeClone = JSON.parse(JSON.stringify(MFACode));
                if (e.target.value.length >= 1) {
                    for (let i = 0; i < e.target.value.length; i++) {
                        MFACodeClone[parseInt(e.target.id) + i - 1] = e.target.value[i];
                    }
                }
                MFACodeClone = MFACodeClone.slice(0, 6);
                setMFACode(MFACodeClone);
                if (parseInt(e.target.id) < 6) {
                    verificationCodeFieldsRef.current[
                        e.target.value.length > 1 ? e.target.value.length - 1 : parseInt(e.target.id)
                    ].current.focus();
                }
            }
        },
        [MFACode]
    );

    /**
     * The function handles the removal of a verification code by updating the MFACode state and
     * focusing on the previous verification code field if applicable.
     * @param {any} e - The parameter "e" is an event object that represents the keyboard event. It
     * contains information about the event, such as the type of event (e.g., keydown, keyup), the key
     * that was pressed, and the target element that triggered the event.
     */
    const handleRemoveVerificationCode = (e: any): void => {
        if (e.keyCode === 8) {
            const MFACodeClone = JSON.parse(JSON.stringify(MFACode));
            MFACodeClone[parseInt(e.target.id) - 1] = '';
            setMFACode(MFACodeClone);
            if (parseInt(e.target.id) > 1) {
                verificationCodeFieldsRef.current[parseInt(e.target.id) - 2].current.focus();
            }
        }
    };

    /**
     * The function handleChangeUserData updates the user data based on the input value, with a
     * condition to limit the length of the phone number.
     * @param {any} e - The parameter `e` is an event object that is passed to the
     * `handleChangeUserData` function. It represents the event that triggered the function, such as a
     * change event on an input field.
     * @returns If the condition `e.target.name === 'phoneNumber' && e.target.value.length > 10` is
     * true, then nothing is being returned. Otherwise, the updated `userData` object is being
     * returned.
     */
    const handleChangeUserData = (e: any): void => {
        if (
            e.target.name === 'phoneNumber' &&
            e.target.value.length &&
            (e.target.value.length > 10 || !NUMBER_REGEX.test(e.target.value))
        ) {
            return;
        }
        setUserData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    /**
     * The function `handleGenerateMFACode` generates a mobile OTP code and updates the current step to
     * 'verifying', and if the attempt has expired, it resets the MFA request count, clears the MFA
     * error, and resets the MFA code.
     */
    const handleGenerateMFACode = async (): Promise<void> => {
        try {
            await generateMobileOtp(userData).unwrap();
            setMFAError('');
            setCurrentStep('verifying');
        } catch (err: any) {
            console.error(err);
        }
        if (attemptExpire) {
            setMFARequestCount(0);
            setAttemptExpire(false);
            setMFAError('');
            setMFACode(['', '', '', '', '', '']);
        }
    };

    /**
     * The function `handleVerifyMFACode` is used to handle the verification of a multi-factor
     * authentication (MFA) code in a TypeScript React application.
     */
    const handleVerifyMFACode = async (): Promise<void> => {
        setMFARequestCount((prev) => prev + 1);
        try {
            let otpCode = '';
            MFACode.forEach((code: string): void => {
                otpCode += code;
            });
            await verifyMobileOtp({ verificationCode: otpCode, ...userData }).unwrap();
            setCurrentStep('updated');
            setMFARequestCount(0);
        } catch (err: any) {
            setMFAError(err?.data?.errorMessage);
            setMFACode(['', '', '', '', '', '']);
        }
    };

    /**
     * The function getCurrentStepIndex returns the index of the current step in an array of all steps.
     */
    const getCurrentStepIndex = (): number => ALL_STEPS.findIndex((step) => step === currentStep);

    /**
     * The function `getModalHeader` returns a string based on the value of the `currentStep` variable.
     * @returns The function `getModalHeader` returns a string.
     */
    const getModalHeader = (): string => {
        switch (currentStep) {
            case 'asking': {
                return 'Update Phone Number';
            }
            case 'verifying': {
                return 'Verify Phone Number';
            }
            case 'updated': {
                return 'Phone Number Updated';
            }
            default: {
                return '';
            }
        }
    };

    /**
     * The function `getModalDescription` returns a JSX element based on the value of the `currentStep`
     * variable.
     * @returns The function `getModalDescription` returns a JSX element.
     */
    const getModalDescription = (): JSX.Element => {
        switch (currentStep) {
            case 'asking': {
                return (
                    <>
                        To update your phone number, enter a new phone number in the fields below. You will need to
                        verify your new phone number before the changes can be saved to your account.
                    </>
                );
            }
            case 'verifying': {
                return (
                    <>
                        A verification code has been sent to the provided phone number. Click the link or enter the code
                        below to continue. This code is valid for 15 minutes.
                    </>
                );
            }
            case 'updated': {
                return (
                    <>
                        Your phone number has been successfully updated with the phone number{' '}
                        <b>
                            {userData?.areaCode} {userData?.phoneNumber}
                        </b>
                        . A confirmation email has been sent to your email address {profileDetails?.data?.email}.
                    </>
                );
            }
            default: {
                return <></>;
            }
        }
    };

    /* The below code is defining a function called `getModalContent` that returns a JSX element based
    on the value of the `currentStep` variable. */
    const getModalContent = (): JSX.Element => {
        switch (currentStep) {
            case 'asking': {
                return isGeneratingMobileOtp || isVerifyingMobileOtp ? (
                    <>
                        <Box sx={{ height: '75px', overflow: 'hidden' }}>
                            <Loader size={40} />
                        </Box>
                    </>
                ) : (
                    <Grid container spacing={2}>
                        <>
                            <Grid item xs={4}>
                                <FormControl variant={'filled'} fullWidth>
                                    <InputLabel htmlFor={'Area Code'}>Country Code</InputLabel>
                                    <Select
                                        name="areaCode"
                                        data-cy={'country-selector'}
                                        fullWidth
                                        labelId={'Area Code'}
                                        inputProps={{
                                            name: 'areaCode',
                                        }}
                                        value={userData?.areaCode}
                                        onChange={handleChangeUserData}
                                    >
                                        {areaCodes?.data?.map((code: any) => (
                                            <MenuItem key={code} value={code}>
                                                {code}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    name="phoneNumber"
                                    id={'phoneNumber'}
                                    label={'Phone Number (Optional)'}
                                    value={userData.phoneNumber}
                                    onChange={handleChangeUserData}
                                    fullWidth
                                    variant={'filled'}
                                    type="text"
                                />
                            </Grid>
                        </>
                    </Grid>
                );
            }
            case 'verifying': {
                return isGeneratingMobileOtp || isVerifyingMobileOtp ? (
                    <>
                        <Box sx={{ height: '150px', overflow: 'hidden' }}>
                            <Loader size={40} />
                        </Box>
                    </>
                ) : (
                    <>
                        <Box sx={{ textAlign: 'center' }}>
                            <Stack spacing={1} direction="row" sx={{ pb: MFAError && '5px !important' }}>
                                {VERIFICATION_CODE_FIELDS.map((field, index) => (
                                    <TextField
                                        hiddenLabel
                                        placeholder="0"
                                        variant="filled"
                                        sx={{ input: { textAlign: 'center' } }}
                                        key={field.id}
                                        inputRef={verificationCodeFieldsRef.current[index]}
                                        {...field}
                                        onChange={handleChangeVerificationCode}
                                        value={MFACode[index]}
                                        onKeyDown={handleRemoveVerificationCode}
                                        {...(MFAError && { error: true })}
                                    />
                                ))}
                            </Stack>
                            {MFAError && (
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        textAlign: 'left',
                                        color: '#CA3C3D',
                                    }}
                                >
                                    Verification code incorrect.{' '}
                                    {MFARequestCount >= 8
                                        ? `You have ${10 - MFARequestCount} more attempts.`
                                        : 'Please try again.'}
                                </Typography>
                            )}
                        </Box>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'end',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box sx={{ marginTop: '80px' }}>
                                    <Typography variant={'body2'}>{"Didn't receive a code?"}</Typography>
                                    <Button
                                        className="btn"
                                        variant="text"
                                        sx={{ width: '100%' }}
                                        onClick={handleGenerateMFACode}
                                    >
                                        Send Again
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </>
                );
            }
            default: {
                return <></>;
            }
        }
    };

    /**
     * The function returns the label for a back action button based on the current step index.
     * @returns The function `getBackActionButtonLabel` returns a string. If the `currentStepIndex` is
     * 0, it returns the string 'Cancel'. Otherwise, it returns the string 'Back'.
     */
    const getBackActionButtonLabel = (): string => {
        const currentStepIndex = getCurrentStepIndex();
        if (currentStepIndex === 0) {
            return 'Cancel';
        }
        return 'Back';
    };

    /**
     * The function handleCloseGroupModal resets various state variables.
     */
    const handleCloseGroupModal = (): void => {
        activeModalHandler('');
        setCurrentStep('asking');
        setMFACode(['', '', '', '', '', '']);
        setAttemptExpire(false);
        setMFARequestCount(0);
        setMFAError('');
        setUserData({ phoneNumber: '', areaCode: '' });
    };

    /**
     * The function `goToPreviousStep` is used to navigate to the previous step in a multi-step
     * process, and if the current step is the first step, it will close a modal.
     */
    const goToPreviousStep = (): void => {
        const currentStepIndex = getCurrentStepIndex();
        if (currentStepIndex === 0) {
            handleCloseGroupModal();
        } else if (ALL_STEPS[currentStepIndex - 1]) {
            setCurrentStep(ALL_STEPS[currentStepIndex - 1]);
            setMFACode(['', '', '', '', '', '']);
            setAttemptExpire(false);
            setMFARequestCount(0);
            setMFAError('');
        }
    };

    /**
     * The function `getNextActionButtonLabel` returns the label for the next action button based on
     * the current step index.
     * @returns The function `getNextActionButtonLabel` returns a string. The string returned depends
     * on the value of `currentStepIndex`. If `currentStepIndex` is equal to `ALL_STEPS.length - 2`,
     * then the function returns the string 'Submit'. Otherwise, it returns the string 'Next'.
     */
    const getNextActionButtonLabel = (): string => {
        const currentStepIndex = getCurrentStepIndex();
        if (ALL_STEPS.length - 2 === currentStepIndex) {
            return 'Submit';
        }
        return 'Next';
    };

    /**
     * The function `goToNextStep` is an asynchronous function that handles different steps based on
     * the value of `currentStep`.
     */
    const goToNextStep = async (): Promise<void> => {
        switch (currentStep) {
            case 'asking': {
                await handleGenerateMFACode();
                break;
            }
            case 'verifying': {
                await handleVerifyMFACode();
                break;
            }
            default: {
                break;
            }
        }
    };

    /**
     * The function `isNextDisabled` checks if the "Next" button should be disabled based on the
     * current step and user data.
     * @returns a boolean value.
     */
    const isNextDisabled = (): boolean => {
        switch (currentStep) {
            case 'asking': {
                return (
                    !userData?.areaCode?.length ||
                    !handleWhiteSpaces(userData?.phoneNumber) ||
                    userData?.phoneNumber?.length !== 10 ||
                    !userData?.phoneNumber?.match(/^\d+$/) ||
                    (userData?.phoneNumber === profileDetails?.data?.phoneNumber &&
                        userData?.areaCode === profileDetails?.data?.areaCode)
                );
            }
            case 'verifying': {
                let otpCode = '';
                MFACode.forEach((code: string): void => {
                    otpCode += code;
                });
                return otpCode.length !== 6;
            }
            default: {
                return false;
            }
        }
    };

    return (
        <>
            <Dialog
                open={activeModal === 'phoneNumberModal'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={wrapperClass}
            >
                <DialogTitle id="alert-dialog-title" className={titleClass}>
                    <Typography variant={'h6'} className={sectionClass}>
                        {getModalHeader()}
                    </Typography>
                    <Typography variant={'body1'}>{getModalDescription()}</Typography>
                    <Divider className={clsx(dividerClass, topDividerClass)} />
                </DialogTitle>
                <DialogContent sx={{ position: 'relative' }}>
                    <Box className={formOverFlow}>{getModalContent()}</Box>
                </DialogContent>
                <DialogActions className={actionsClass}>
                    {currentStep === 'updated' ? (
                        <Button variant={'contained'} fullWidth onClick={handleCloseGroupModal}>
                            Close
                        </Button>
                    ) : (
                        <MobileStepper
                            steps={2}
                            activeStep={getCurrentStepIndex()}
                            variant="dots"
                            position="static"
                            sx={{ background: 'none !important' }}
                            backButton={
                                <Button variant="outlined" onClick={goToPreviousStep}>
                                    {getBackActionButtonLabel()}
                                </Button>
                            }
                            nextButton={
                                <Button variant="contained" onClick={goToNextStep} disabled={isNextDisabled()}>
                                    {getNextActionButtonLabel()}
                                </Button>
                            }
                        />
                    )}
                </DialogActions>
            </Dialog>
            <Modal open={attemptExpire} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        You have made too many failed attempts.
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Please request for a new code to be sent to {userData?.areaCode}{' '}
                        {createNumArray(
                            (userData?.phoneNumber?.length || 1) -
                                (userData?.phoneNumber?.substring(6, 10)?.length || 0)
                        )?.map(() => '*')}
                        {userData?.phoneNumber?.substring(6, 10)}.
                    </Typography>
                    <Stack spacing={1} direction="column" sx={{ paddingTop: '20px' }}>
                        <Button
                            className="btn"
                            variant="text"
                            sx={{ width: '100%', justifyContent: 'flex-end' }}
                            onClick={handleGenerateMFACode}
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                        >
                            Request for a New Code
                        </Button>
                        <Button
                            className="btn"
                            variant="text"
                            sx={{ width: '100%', justifyContent: 'flex-end' }}
                            onClick={handleCloseGroupModal}
                        >
                            Back
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
};
