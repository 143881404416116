import { NUMBER_REGEX } from '@fiji/common/src/constants';
import { DialogContent, Divider, DialogContentText, TextField, Box, InputAdornment } from '@mui/material';
import React from 'react';

export const Details = React.memo((props: any): JSX.Element => {
    /**
     * The changeHandler function updates the payload state with the new value from the input field.
     * @param {any} e - The parameter `e` is an event object that is passed to the `changeHandler`
     * function. It is typically an event object that is triggered when a change event occurs, such as
     * when a user types into an input field or selects an option from a dropdown menu.
     */
    const changeHandler = (e: any): void => {
        if (e.target.name === 'delay' && e.target.value.length && !NUMBER_REGEX.test(e.target.value)) {
            return;
        }
        if ((+e?.target.value < 86400 && e.target.name === 'delay') || e.target.name === 'name') {
            props?.setPayload((prev: any) => ({
                ...prev,
                [e?.target?.name]: e?.target?.value,
            }));
        }
    };

    return (
        <>
            <DialogContent sx={{ padding: '0', marginTop: '0' }}>
                <Divider />
                <DialogContentText id="alert-dialog-description" sx={{ padding: '25px' }}>
                    <TextField
                        name="name"
                        id="filled-basic"
                        sx={{ width: '100%' }}
                        label="Automation Name"
                        value={props?.payload?.name}
                        variant="filled"
                        onChange={changeHandler}
                        helperText={'e.g. Shutdown on Over Temperature, Load 2 On When High'}
                    />
                    <Box sx={{ marginTop: '20px' }}>
                        <TextField
                            name="delay"
                            sx={{ width: '100%' }}
                            value={props?.payload?.delay}
                            label="Delay"
                            variant="filled"
                            onChange={changeHandler}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">s</InputAdornment>,
                            }}
                            inputProps={{
                                style: { textAlign: 'end', marginRight: '2px' },
                            }}
                        />
                    </Box>
                </DialogContentText>
            </DialogContent>
        </>
    );
});
