import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { TabType, TabsProps } from '@fiji/common/src/types';
import clsx from 'clsx';

/* The code is defining a custom React component called `CustomTabs`. This component is a wrapper
around the `Tabs` component from the `@mui/material` library. It accepts a set of props
(`TabsProps`) and a `ref` as parameters. */
export const CustomTabs = React.forwardRef((props: TabsProps, ref): JSX.Element => {
    const [activeTab, setActiveTab] = React.useState<string>(props?.preSelectedTab);

    /**
     * The function `activeTabHandler` sets the active tab in a React component.
     * @param {string} tab - The `tab` parameter is a string that represents the name or identifier of
     * the tab that needs to be activated.
     */
    const activeTabHandler = (tab: string): void => {
        setActiveTab(tab);
    };

    /* The `React.useImperativeHandle` hook is used to expose certain functions or methods of a child
    component to its parent component. In this case, it allows the parent component to access the
    `activeTabHandler` function of the `CustomTabs` component. */
    React.useImperativeHandle(ref, () => ({
        activeTabHandler: (arg0: string): void => activeTabHandler(arg0),
    }));

    /* The `React.useEffect` hook is used to perform side effects in a React component. In this case,
    it is used to execute the code inside the callback function whenever the `activeTab` state
    variable changes. */
    React.useEffect(() => {
        if (activeTab) {
            props?.onTabChange(activeTab);
        }
    }, [activeTab]);

    return (
        <Tabs
            value={activeTab}
            TabIndicatorProps={{
                style: {
                    backgroundColor: '#007bc1',
                    color: '#007bc1',
                },
            }}
            className={props?.className ?? 'tab-wrapper'}
            {...(props?.customStyleObject && {
                sx: props?.customStyleObject,
            })}
        >
            {props?.data?.map((tab: TabType) => (
                <Tab
                    value={tab?.id}
                    key={tab?.id}
                    icon={tab?.icon}
                    label={tab?.label}
                    className={clsx({
                        tab: true,
                        'tab-active': activeTab === tab?.id,
                    })}
                    {...(tab?.sx && {
                        sx: { ...tab?.sx, color: activeTab === tab?.id ? '#007bc1 !important' : '#727E84' },
                    })}
                    iconPosition={tab?.iconPosition ?? 'start'}
                    onClick={(): void => activeTabHandler(tab?.id)}
                />
            ))}
        </Tabs>
    );
});
