import makeStyles from '@mui/styles/makeStyles';
import * as Colors from '@brightlayer-ui/colors';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
    drawerHeader: {
        minHeight: '56px',
    },
    drawerSubheader: {
        minHeight: '48px',
        '& .MuiStack-root': {
            height: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.04)',
        },
    },
    active: {
        backgroundColor: 'rgba(0, 123, 193, 0.15) !important',
    },
    DrawerFooter: {
        padding: theme.spacing(2),
    },
    eatonLogoFooter: {
        height: '28px',
        width: '103px',
        '& img': {
            objectFit: 'contain',
            height: '100%',
            objectPosition: 'center',
        },
    },
    muiDialogpaper: {
        '& .MuiPaper-root': {
            width: '450px',
        },
    },
    MuiDialogTitleRoot: {},
    MuiDialogActionsRoot: {
        padding: '24px',
        borderTop: '1px solid rgba(66, 78, 84, 0.12)',
    },
    deleteButton: {
        background: Colors.red[600],
        '&:hover': {
            backgroundColor: Colors.red[400],
        },
        '&.Mui-disabled': {
            backgroundColor: '#F9E8E8',
            color: '#E59E9E',
        },
    },
    emptyState: {
        height: 'calc(100vh - 198px)',
    },
    // tree start
    treeItem: {
        '& .MuiTreeItem-content': {
            height: 56,
            borderBottom: '1px solid rgba(66, 78, 84, 0.12)',
            '& .MuiTreeItem-iconContainer': {
                marginRight: 0,
                width: 'auto',
            },
        },
    },
    treeItemContentBox: {
        position: 'relative',
    },
    treeIteminfo: {
        position: 'absolute',
        right: '8px',
        top: '50%',
        transform: 'translateY(-50%)',
        color: Colors.gray[500],
    },
    expandIcon: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: Colors.gray[500],
        fontSize: '24px !important',
    },
    orgMenuSelect: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '16px',
    },
    selectedOrgName: {
        fontSize: '20px',
        fontWeight: '400',
        paddingLeft: '16px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    verticalBreadcrumbs: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        transform: 'rotate(90deg)',
        marginTop: '25px',
        position: 'absolute',
        '& .MuiBreadcrumbs-ol': {
            flexWrap: 'nowrap',
            paddingLeft: '24px',
            width: 'calc(100vh - 160px)',
        },
    },
    disabled: {
        opacity: '0.5',
        pointerEvents: 'none',
        cursor: 'not-allowed',
    },
    dropdownMenu: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: '600',
    },
    dropdownMenuIcon: {
        paddingLeft: '4px',
        cursor: 'pointer',
    },
}));
