import { InfoListItem } from '@brightlayer-ui/react-components';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { selectAddGroupModal } from '@fiji/common/src/features/group/groupSlice';
import { useGetGroupAssignedUsersQuery } from '@fiji/common/src/features/userManagement/userManagement';
import { GroupModal } from '@fiji/common/src/types';
import { Avatar, Checkbox, useTheme } from '@mui/material';
import React from 'react';
import { useStyles } from './styles';
import clsx from 'clsx';
import { getUserInitials } from '../../../CommonUtils';
import Loader from '../../../components/Loader';
import { Person } from '@mui/icons-material';

export const AssignUsersContent = ({
    destinationGroup,
    selectedUsers,
    setSelectedUsers,
}: {
    destinationGroup: string;
    selectedUsers: string[];
    setSelectedUsers: (selectedUsers: any) => void;
}): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const showAddGroupModal = useTypedSelector<GroupModal>(selectAddGroupModal);

    const {
        data: allUsers,
        isLoading,
        isFetching,
    } = useGetGroupAssignedUsersQuery(
        { groupId: destinationGroup },
        { skip: !destinationGroup || !showAddGroupModal?.isOpen }
    );

    const handleSelectUser = (e: any): void => {
        const targetId = e.target.name;
        if (selectedUsers.includes(targetId)) {
            const modifiedSelected = selectedUsers.filter((item) => item !== targetId);
            setSelectedUsers(modifiedSelected);
        } else {
            setSelectedUsers((prev: any) => [...prev, targetId]);
        }
    };

    return (
        <>
            {isLoading || isFetching ? (
                <Loader />
            ) : (
                allUsers?.data?.length > 0 &&
                allUsers?.data?.map((user: any) => (
                    <InfoListItem
                        key={user.id}
                        className={clsx(classes.infoListItem, {
                            [classes.disabled]: user?.orgAdmin,
                        })}
                        data-testid="infoListItem"
                        data-cy={'list-content'}
                        title={user?.name || '-'}
                        subtitle={user.role}
                        divider="full"
                        leftComponent={
                            <Checkbox
                                checked={user?.orgAdmin || selectedUsers?.includes(user.id) || user.access}
                                onChange={handleSelectUser}
                                name={user.id}
                            />
                        }
                        avatar
                        hidePadding
                        icon={
                            <Avatar sx={{ marginLeft: '0 !important' }} src={user?.image}>
                                {user?.name ? getUserInitials(user?.name) : <Person fontSize="inherit" />}
                            </Avatar>
                        }
                    />
                ))
            )}
        </>
    );
};
