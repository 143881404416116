import {
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    CircularProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useStyles } from './styles';
import { useAppDispatch } from '@fiji/common/src/app/store';
import { UserOrgs } from '../ManageOrganization/ManageOrganization';
import { useGetOrgQuery, useWithdrawOrgMutation } from '@fiji/common/src/features/orgManagement/orgApi';
import { EmptyState } from '@brightlayer-ui/react-components';
import { CorporateFare } from '@mui/icons-material';
import orgSlice from '@fiji/common/src/features/orgManagement/orgSlice';
import clsx from 'clsx';
import commonSlice from '@fiji/common/src/features/common/commonSlice';
import Loader from '../../components/Loader';

export const WithdrawOrg = (): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();

    const [isWithdrawingOrg, setIsWithdrawingOrg] = useState(false);
    const [selectedWithdrawOrgs, setSelectedWithdrawOrgs] = useState<string[]>([]);

    const {
        data: orgLists,
        isLoading,
        isFetching,
    } = useGetOrgQuery<{
        data: { data: any };
        isLoading: boolean;
        isFetching: boolean;
    }>({ page: 0, size: 200, status: 'PENDING' });

    const [withdrawOrganization] = useWithdrawOrgMutation();

    /**
     * The closeOrgFlowModal function dispatches an action to close the organization flow modal.
     */
    const closeOrgFlowModal = (): any => dispatch(orgSlice.actions.orgFlowModal({ isOpen: false, flow: '' }));

    /**
     * The function calculates the number of pending organizations in an array of user organizations.
     * @param allOrgs - The `allOrgs` parameter is an array of objects representing user organizations.
     * Each object in the array is of type `Partial<UserOrgs>`, which means it may have some or all of
     * the properties defined in the `UserOrgs` interface.
     * @returns The function `pendingOrgsLength` returns the number of organizations with a status of
     * 'PENDING' in the `allOrgs` array.
     */
    const pendingOrgsLength = (allOrgs: Partial<UserOrgs[]>): number => allOrgs?.length;

    /**
     * The function `handleWithdrawOrganization` is an asynchronous function that handles the
     * withdrawal of organizations and displays a toast message.
     * @param {any} e - The parameter `e` is an event object that represents the event that triggered
     * the function. It is of type `any`, which means it can be any type of event object.
     */
    const handleWithdrawOrganization = async (e: any): Promise<void> => {
        let orgIds: any[] = [];
        if (e.target.id === 'single') {
            if (orgLists?.data?.records?.length === 1) {
                orgIds = [orgLists?.data?.records[0]?.id];
            }
            // const pendingOrgs = orgLists?.data?.find((org: any) => org?.status === 'PENDING');
            // if (pendingOrgs) orgIds = [pendingOrgs.id];
        } else {
            orgIds = selectedWithdrawOrgs;
        }
        setIsWithdrawingOrg(true);
        const { data }: any = await withdrawOrganization({ orgIds: orgIds });
        setIsWithdrawingOrg(false);
        if (data) {
            let message = '';
            if (data?.data?.length === 1) {
                message = `Your request to join ${data?.data?.[0]?.name} has been withdrawn.`;
            } else if (data?.data?.length > 1) {
                message = `Your request to ${data?.data?.length || 0} organizations have been withdrawn.`;
            }
            dispatch(commonSlice.actions.setToastifyContent({ message, isOpen: true }));
            closeOrgFlowModal();
        }
    };

    /**
     * The function `getPendingOrgName` takes an array of user organizations and returns the name of
     * the first organization with a status of 'PENDING', or an empty string if no such organization
     * exists.
     * @param allOrgs - An array of objects representing user organizations. Each object has properties
     * like "name" and "status".
     * @returns The function `getPendingOrgName` returns a string.
     */
    const getPendingOrgName = (allOrgs: Partial<UserOrgs[]>): string => allOrgs?.[0]?.name ?? '';

    const getEmptyStateStyles = (): any => {
        if (pendingOrgsLength(orgLists?.data?.records) > 1) {
            return { paddingTop: '96px', height: 'auto', minHeight: 'auto' };
        }
        return {};
    };

    /* The below code is defining a function called `getHeader` that returns a JSX element. */
    const getHeader = (): JSX.Element => (
        <EmptyState
            sx={getEmptyStateStyles()}
            icon={<CorporateFare fontSize={'inherit'} />}
            title={
                pendingOrgsLength(orgLists?.data?.records) > 1
                    ? `Withdraw your request to join from...`
                    : `Withdraw your request to join ${getPendingOrgName(orgLists?.data?.records)}?`
            }
            actions={
                pendingOrgsLength(orgLists?.data?.records) === 1 && (
                    <>
                        <Button
                            className={classes.deleteButton}
                            sx={{ width: '100%' }}
                            variant="contained"
                            id="single"
                            onClick={handleWithdrawOrganization}
                            {...(isWithdrawingOrg && {
                                startIcon: <CircularProgress color="inherit" size={20} />,
                            })}
                        >
                            {!isWithdrawingOrg && 'Withdraw Request To Join'}
                        </Button>
                        <Button
                            className="outlined-light"
                            sx={{ width: '100%', mt: '16px' }}
                            variant="outlined"
                            id="pending"
                            onClick={handleCloseWithdrawModal}
                        >
                            Cancel
                        </Button>
                    </>
                )
            }
        />
    );

    const handleCloseWithdrawModal = (): void => {
        dispatch(orgSlice.actions.orgFlowModal({ isOpen: false, flow: '' }));
    };

    return (
        <Card className={classes.card}>
            {isLoading || isFetching ? (
                <Loader />
            ) : (
                <>
                    {getHeader()}
                    {pendingOrgsLength(orgLists?.data?.records) > 1 && (
                        <>
                            <CardContent className={clsx(classes.cardBody, classes.customCardPadding)}>
                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    {orgLists?.data?.records?.map((org: any) => {
                                        const handleSelectWithdrawOrgList = (): void => {
                                            setSelectedWithdrawOrgs((prev: string[]) => {
                                                const currentIndex = org && prev.indexOf(org.id);
                                                const newChecked = [...prev];
                                                if (currentIndex === -1 && org) {
                                                    newChecked.push(org.id);
                                                } else if (currentIndex !== undefined) {
                                                    newChecked.splice(currentIndex, 1);
                                                }
                                                return newChecked;
                                            });
                                        };
                                        return (
                                            <ListItem key={org?.id} disablePadding>
                                                <ListItemButton
                                                    role={undefined}
                                                    onClick={handleSelectWithdrawOrgList}
                                                    dense
                                                >
                                                    <ListItemIcon sx={{ minWidth: '40px' }}>
                                                        <Checkbox
                                                            edge="start"
                                                            tabIndex={-1}
                                                            disableRipple
                                                            checked={org && selectedWithdrawOrgs.indexOf(org.id) !== -1}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={org?.id} primary={org?.name} />
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </CardContent>
                            <CardActions className={classes.cardFooter}>
                                <Stack direction="row" spacing={2} justifyContent="end" width={'100%'}>
                                    <Button
                                        className="outlined-light"
                                        variant="outlined"
                                        id="pending"
                                        onClick={handleCloseWithdrawModal}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className={classes.deleteButton}
                                        variant="contained"
                                        disabled={!selectedWithdrawOrgs.length}
                                        id="multiple"
                                        onClick={handleWithdrawOrganization}
                                        {...(isWithdrawingOrg && {
                                            startIcon: <CircularProgress color="inherit" size={20} />,
                                        })}
                                    >
                                        {!isWithdrawingOrg && `Withdraw ${selectedWithdrawOrgs.length}`}
                                    </Button>
                                </Stack>
                            </CardActions>
                        </>
                    )}
                </>
            )}
        </Card>
    );
};
