import React, { useState, useEffect } from 'react';
import { Organization, UserProfile } from '@fiji/common/src/types';
import { Card, Box, Typography, List } from '@mui/material';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { AdminPanelSettings } from '@mui/icons-material';
import { useGetPaginatedOrgQuery } from '@fiji/common/src/features/orgManagement/orgApi';
import Loader from '../../components/Loader';

/**
 * The `RolesProps` type is used in a TypeScript React component and includes properties for profile
 * details, an ID, and invited user data.
 * @property {UserProfile['data'] | undefined} profileDetails - The `profileDetails` property is of
 * type `UserProfile['data'] | undefined`. It represents the details of a user's profile. The type
 * `UserProfile['data']` refers to a specific property `data` within the `UserProfile` type. The `|`
 * symbol indicates that the property can
 * @property {string} id - A string representing the ID of the user or profile.
 * @property {InvitedUserProfile | any} invitedUserData - The `invitedUserData` property is of type
 * `InvitedUserProfile | any`. This means that it can either be of type `InvitedUserProfile` or any
 * other type.
 */
type RolesProps = {
    id: string;
    invitedUserData: any;
};

export const Roles = ({ id, invitedUserData }: RolesProps): JSX.Element => {
    const [userData, setUserData] = useState<Partial<UserProfile['data']>>({});
    const [orgFilter, setOrgFilter] = useState<{ page: number; size: number; status?: string }>({
        page: 0,
        size: 25,
        status: 'ACTIVE',
    });

    const { data: orgDetails, isFetching }: any = useGetPaginatedOrgQuery(orgFilter, {
        refetchOnMountOrArgChange: true,
    });

    /* The `useEffect` hook is used to perform side effects in a React functional component. In this
    case, the `useEffect` hook is used to update the `userData` state variable based on changes to
    the `id`, `profileDetails`, or `invitedUserData` variables. */
    useEffect(() => {
        if (id && invitedUserData) {
            setUserData(invitedUserData);
        }
    }, [id, invitedUserData]);

    return userData?.roles?.length || orgDetails?.data?.records?.length ? (
        <Card sx={{ p: 0, mb: 2 }}>
            <Box>
                <Box className="panel-header">
                    <InfoListItem
                        title={
                            <Typography color={'primary'} variant={'subtitle2'}>
                                Roles
                            </Typography>
                        }
                        divider={'full'}
                        dense
                        hidePadding
                    />
                </Box>

                <List>
                    {!userData?.roles?.length &&
                        orgDetails?.data?.records?.map((org: Organization) => (
                            <InfoListItem
                                key={org?.id}
                                data-testid="infoListItem"
                                data-cy={'list-content'}
                                subtitle={org?.name}
                                title={org?.roleName}
                                avatar={false}
                                icon={<AdminPanelSettings />}
                                iconAlign="center"
                                hidePadding
                                divider={'full'}
                            />
                        ))}
                    {orgFilter?.page + 1 < Math.ceil(orgDetails?.data?.total / orgFilter?.size) && (
                        <List>
                            <InfoListItem
                                data-testid="infoListItem"
                                data-cy={'list-content'}
                                title={
                                    !isFetching && (
                                        <Typography sx={{ color: '#007bc1', fontWeight: 'bold' }}>
                                            Show More...
                                        </Typography>
                                    )
                                }
                                avatar
                                iconAlign="center"
                                hidePadding
                                sx={{ textAlign: 'center' }}
                                onClick={(): void => setOrgFilter((prev) => ({ ...prev, page: prev.page + 1 }))}
                            />
                            {isFetching && <Loader size={30} />}
                        </List>
                    )}
                </List>

                {userData?.roles?.length &&
                    userData.roles?.map((role: any) => (
                        <InfoListItem
                            key={role.organizationName}
                            data-testid="infoListItem"
                            data-cy={'list-content'}
                            subtitle={role.orgName}
                            title={role?.roleName}
                            avatar={false}
                            icon={<AdminPanelSettings />}
                            iconAlign="center"
                            hidePadding
                            divider={'full'}
                        />
                    ))}
            </Box>
        </Card>
    ) : (
        <></>
    );
};
