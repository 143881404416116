import React, { memo } from 'react';
import { AppBar, Toolbar, Typography, Box, Stack } from '@mui/material';
import { Spacer } from '@brightlayer-ui/react-components';
import makeStyles from '@mui/styles/makeStyles';
import { Theme, useTheme } from '@mui/material/styles';
import HeaderActions from './HeaderActions';
import { RootState, useTypedSelector } from '@fiji/common/src/app/store';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';

const useStyles = makeStyles((theme: Theme) => ({
    toolbarGutters: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(2),
        minHeight: '56px',
        [theme.breakpoints.down('sm')]: {
            minHeight: '56px',
        },
    },
    userMenu: {
        marginRight: theme.spacing(3),
    },
}));

/**
 * The `AppHeader` component is a TypeScript React component that renders a header with a title,
 * organization name, and additional actions.
 * @param props - The `props` parameter is an object that contains two properties:
 * @returns The `AppHeader` component is returning a JSX element.
 */
const AppHeader = (props: { title: string; page: JSX.Element }): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const selectedOrganization = useTypedSelector(selectedOrg);
    const headerTitle = useTypedSelector((state: RootState) => state?.common?.appHeaderTitle);

    return (
        <>
            <Box
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    minHeight: '100vh',
                    position: 'relative',
                }}
            >
                <AppBar position={'sticky'}>
                    <Toolbar sx={{ px: 2 }} className={`${classes.toolbarGutters} white-space-pre`}>
                        <Stack>
                            <Typography variant={'h6'} color={'inherit'} lineHeight={'23px'}>
                                {headerTitle ?? props.title}
                            </Typography>
                            <Typography variant={'body1'} color={'inherit'}>
                                {!headerTitle && selectedOrganization?.name}
                            </Typography>
                        </Stack>
                        <Spacer />
                        <HeaderActions />
                    </Toolbar>
                </AppBar>
                {props.page}
            </Box>
        </>
    );
};

export default memo(AppHeader);
