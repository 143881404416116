import React from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Stack, Breadcrumbs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Theme, useTheme } from '@mui/material/styles';
import * as Colors from '@brightlayer-ui/colors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams, useLocation } from 'react-router-dom';
import { Route } from '../router/routes';
import { useNavigate } from 'react-router';
import { Spacer } from '@brightlayer-ui/react-components';
import HeaderActions from './HeaderActions';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { RootState, useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { setPageHeaderData } from '@fiji/common/src/features/common/commonSlice';

const useStyles = makeStyles((theme: Theme) => ({
    toolbarGutters: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        minHeight: '56px',
        [theme.breakpoints.down('sm')]: {
            minHeight: '56px',
        },
    },
    innerHeaderTitle: {
        fontSize: '20px',
        lineHeight: '23px',
    },
}));

/* The `usersPageList` constant is an array that contains the titles of different user pages. It is
used in the `InternalPageHeader` component to determine if the current page is a user page and
display the appropriate title in the header. */
const usersPageList = ['User Profile', 'Edit User Details', 'Approve User', 'Invite User'];

/* The `InternalPageHeader` component is a React functional component that renders a header for an
internal page. It takes in three props: `nestedRoutesList`, `title`, and `page`. */
const InternalPageHeader = (props: {
    nestedRoutesList: Route[];
    title: string;
    page: JSX.Element;
    addPageRef: any;
    isNoShadowHeader?: boolean;
}): JSX.Element => {
    const { nestedRoutesList, title, page, addPageRef } = props;
    const params: any = useParams();
    const theme = useTheme();
    const classes = useStyles(theme);
    const navigate = useNavigate();
    const location = useLocation();
    const selectedOrganization = useTypedSelector(selectedOrg);
    const dispatch = useAppDispatch();
    const NO_ORGANIZATION = !selectedOrganization?.id && location.pathname === '/organization';
    const { collapsibleHeaderDetails: deviceData } = useTypedSelector((state: any) => state?.common);

    /**
     * The function `handleNavigateBack` is used to navigate back to the previous page in a TypeScript
     * React application, taking into account nested routes and URL parameters.
     */
    const handleNavigateBack = (): void => {
        if (addPageRef?.current) {
            addPageRef?.current?.handleDiscardChanges();
        } else {
            let newUrl = '';
            if (location?.state?.redirectPath) {
                newUrl = location?.state?.redirectPath;
            } else {
                newUrl = `/${nestedRoutesList[nestedRoutesList.length - 2].path}`;
                const result = newUrl.split(':');
                for (let i = 1; i < result.length; i++) {
                    if (params?.[result[i]]) {
                        newUrl = newUrl.replace(`:${result[i]}`, params?.[result[i]]);
                    } else {
                        newUrl = '/';
                        break;
                    }
                }
            }
            navigate(!selectedOrganization?.id ? '/organization' : `${newUrl}`, {
                state: location.state,
            });
        }
    };

    const pageHeader: any = useTypedSelector((state: RootState) => state?.common?.pageHeader);

    const getStatusColor = (): string => {
        switch (pageHeader?.status) {
            case 'Warning Active':
                return '#F47721';
            case 'Alarm':
                return '#69B1C3';
            case 'Alarm Active':
                return '#CA3C3D';
            default:
                return '#007BC1';
        }
    };

    const getBackgroundColor = (): string => {
        if (pageHeader?.events?.severity) {
            return getStatusColor();
        } else if (NO_ORGANIZATION) {
            return '#007BC1';
        }
        return '#fff';
    };

    React.useEffect(() => {
        if (
            !window?.location?.pathname.includes('timeline') &&
            !window?.location?.pathname.includes('profile/') &&
            !window?.location?.pathname?.includes('load-details/')
        ) {
            dispatch(setPageHeaderData(undefined));
        }
    }, [window?.location.pathname]);

    const getHeaderTitle = (route: Route): string => {
        switch (true) {
            case Boolean(pageHeader?.deviceName):
                return pageHeader.deviceName;
            case Boolean(pageHeader?.loadName):
                return pageHeader.loadName;
            case route.title === 'All Groups':
                return 'Manage Devices';
            case Boolean(NO_ORGANIZATION):
                return 'No Organization';
            default:
                return route.title ?? '';
        }
    };

    const getHeaderSubTitle = (): string => {
        switch (true) {
            case nestedRoutesList[nestedRoutesList.length - 1].title === 'User Profile':
                return pageHeader?.userName;
            case location?.pathname?.includes('device/edit'):
                return deviceData?.deviceName;
            case Boolean(pageHeader?.subTitle):
                return pageHeader?.subTitle;
            default:
                return pageHeader?.events?.name ?? title;
        }
    };

    return (
        <>
            <Box
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    minHeight: '100vh',
                    position: 'relative',
                }}
            >
                <AppBar position={props?.isNoShadowHeader ? 'static' : 'sticky'}>
                    <Toolbar
                        className={classes.toolbarGutters}
                        sx={{
                            px: 2,
                            backgroundColor: getBackgroundColor(),
                            color: NO_ORGANIZATION || pageHeader?.events?.severity ? '#fff' : '#424e54',
                        }}
                    >
                        {!NO_ORGANIZATION && (
                            <IconButton onClick={handleNavigateBack}>
                                <ArrowBackIcon
                                    sx={{ color: pageHeader?.events?.severity ? '#fff' : Colors.black[500] }}
                                />
                            </IconButton>
                        )}
                        <Stack pl={3} direction={'column'}>
                            <Breadcrumbs separator={'/'}>
                                {usersPageList.includes(
                                    `${nestedRoutesList[nestedRoutesList?.length - 1]?.title ?? ''}`
                                ) ? (
                                    <Typography
                                        key={nestedRoutesList[nestedRoutesList.length - 1].path}
                                        variant={'h6'}
                                        color={'inherit'}
                                        onClick={(): void =>
                                            navigate(`/${nestedRoutesList[nestedRoutesList.length - 1].path}`)
                                        }
                                        className={`${classes.innerHeaderTitle} white-space-pre`}
                                        sx={{
                                            color: pageHeader?.events?.severity || NO_ORGANIZATION ? '#fff' : '#424e54',
                                        }}
                                    >
                                        {nestedRoutesList[nestedRoutesList.length - 1].title}
                                    </Typography>
                                ) : (
                                    nestedRoutesList?.map(
                                        (route: Route): any =>
                                            route.title && (
                                                <Typography
                                                    key={route.path}
                                                    variant={'h6'}
                                                    color={'inherit'}
                                                    onClick={(): void => navigate(`/${route.path}`)}
                                                    className={classes.innerHeaderTitle}
                                                    sx={{
                                                        whiteSpace: 'pre',
                                                        color:
                                                            pageHeader?.events?.severity || NO_ORGANIZATION
                                                                ? '#fff'
                                                                : '#424e54',
                                                    }}
                                                >
                                                    {getHeaderTitle(route)}
                                                </Typography>
                                            )
                                    )
                                )}
                            </Breadcrumbs>
                            <Typography
                                sx={{
                                    whiteSpace: 'pre',
                                }}
                                variant={'body1'}
                                color={pageHeader?.events?.severity ? '#fff' : Colors.black[500]}
                            >
                                {getHeaderSubTitle()}
                            </Typography>
                        </Stack>
                        <Spacer />
                        <HeaderActions disabled={!selectedOrganization?.id} />
                    </Toolbar>
                </AppBar>
                {page}
            </Box>
        </>
    );
};

export default InternalPageHeader;
