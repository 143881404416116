import React from 'react';
import {
    CloudDone,
    CloudOff,
    InfoOutlined,
    NotificationsActive,
    NotificationsActiveOutlined,
    Warning,
    WarningAmberOutlined,
} from '@mui/icons-material';
import { Avatar } from '@mui/material';

type Props = { status: string };

export const TimelineStatusIcon = (props: Props): JSX.Element => {
    switch (props.status) {
        case 'Alarm Active':
            return (
                <Avatar sx={{ backgroundColor: '#CA3C3D' }}>
                    <NotificationsActive sx={{ color: '#fff', height: '40px', width: '40px', p: '6px' }} />
                </Avatar>
            );

        case 'Alarm Cleared':
            return <NotificationsActiveOutlined sx={{ color: '#CA3C3D', height: '40px', width: '40px', p: '6px' }} />;

        case 'Warning Active':
            return (
                <Avatar sx={{ backgroundColor: '#F0AA1F' }}>
                    <Warning sx={{ color: '#fff', height: '40px', width: '40px', p: '6px' }} />
                </Avatar>
            );

        case 'Warning Cleared':
            return <WarningAmberOutlined sx={{ color: '#F0AA1F', height: '40px', width: '40px', p: '6px' }} />;

        case 'Informational':
            return <InfoOutlined sx={{ color: '#007BC1', height: '40px', width: '40px', p: '6px' }} />;

        case 'Alarm':
            return (
                <Avatar sx={{ backgroundColor: '#69B1C3' }}>
                    <CloudOff sx={{ color: '#fff', height: '40px', width: '40px', p: '6px' }} />
                </Avatar>
            );
        case 'Offline Cleared': {
            return <CloudOff sx={{ color: '#69B1C3', height: '40px', width: '40px', p: '6px' }} />;
        }
        default:
            return <CloudDone sx={{ color: '#fff', height: '40px', width: '40px', p: '6px' }} />;
    }
};
