import React from 'react';

/**
 * The `useIsMount` function returns a boolean value indicating whether the component is being mounted
 * for the first time.
 * @returns The function `useIsMount` returns a boolean value indicating whether the component is being
 * mounted for the first time or not.
 */
export const useIsMount = (): boolean => {
    const isMountRef = React.useRef(true);

    /* The `React.useEffect` hook is used to perform side effects in functional components. In this
    case, it is used to update the value of `isMountRef.current` to `false` after the component has
    been mounted for the first time. */
    React.useEffect(() => {
        isMountRef.current = false;
    }, []);

    return isMountRef.current;
};
