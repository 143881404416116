import React, { useState } from 'react';
import { Box, AppBar } from '@mui/material';
import { Assessment, List } from '@mui/icons-material';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Device } from '@brightlayer-ui/icons-mui';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { useStyles } from './styles';
import { DevicesList, SummaryScreen } from './';
import { DocumentTab, TimelineTab } from '../DeviceSummary';
import { useLocation } from 'react-router-dom';
import { useCommonActions, useIsMount } from '../../hooks';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { api } from '@fiji/common/src/app/api/baseApi';

export const ManageDevices = (): JSX.Element => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const classes = useStyles(theme);
    const isMount = useIsMount();
    const { state }: any = useLocation();
    const currentOrg = useTypedSelector(selectedOrg);

    const [activeTab, setActiveTab] = useState<string>(state?.manageDevicesTab ?? 'Summary');

    /* The line `const { togglePageLoader, refreshRouterState } = useCommonActions();` is using the
   `useCommonActions` hook to destructure and assign the `togglePageLoader` and `refreshRouterState`
   functions to variables. These functions are likely provided by a custom hook and can be used to
   perform common actions in the component. */
    const { togglePageLoader, refreshRouterState } = useCommonActions();

    React.useEffect(() => {
        togglePageLoader(false);
    }, []);

    React.useEffect(() => {
        if (activeTab && currentOrg.id && !isMount) {
            dispatch((api as any).endpoints.getGroupsHierarchy.initiate(null));
        }
    }, [activeTab]);

    /**
     * The function `getTabsData` returns a JSX element based on the value of the `activeTab` variable.
     * @returns The function `getTabsData` returns a JSX element based on the value of the `activeTab`
     * variable. The returned JSX element depends on the value of `activeTab` and can be one of the
     * following components: `DevicesList`, `SummaryScreen`, `TimelineTab`, `DocumentScreen`, or an
     * empty fragment (`<>...</>`).
     */
    const getTabsData = (): JSX.Element => {
        switch (activeTab) {
            case 'Devices': {
                return <DevicesList />;
            }
            case 'Summary': {
                return <SummaryScreen setActiveTab={setActiveTab} />;
            }
            case 'Timeline': {
                return <TimelineTab />;
            }
            case 'Documents': {
                return <DocumentTab />;
            }
            default: {
                return <></>;
            }
        }
    };

    return (
        <React.Fragment>
            <header>
                <title>User</title>
                <meta name="description" content="Description of User" />
            </header>
            <AppBar className={classes.subHeader} position="static" color="primary">
                <Tabs id={'tabs'} className={classes.tabsContent}>
                    <Tab
                        onClick={(): void => {
                            refreshRouterState('/devices');
                            setActiveTab('Summary');
                        }}
                        label="Summary"
                        className={activeTab === 'Summary' ? classes.tabActive : classes.tab}
                        icon={<Assessment />}
                        iconPosition="start"
                    />
                    <Tab
                        onClick={(): void => {
                            refreshRouterState('/devices');
                            setActiveTab('Devices');
                        }}
                        label="Devices"
                        className={activeTab === 'Devices' ? classes.tabActive : classes.tab}
                        icon={<Device />}
                        iconPosition="start"
                    />
                    <Tab
                        onClick={(): void => {
                            refreshRouterState('/devices');
                            setActiveTab('Timeline');
                        }}
                        label="Timeline"
                        className={activeTab === 'Timeline' ? classes.tabActive : classes.tab}
                        icon={<List />}
                        iconPosition="start"
                    />
                    <Tab
                        onClick={(): void => {
                            refreshRouterState('/devices');
                            setActiveTab('Documents');
                        }}
                        label="Documents"
                        className={activeTab === 'Documents' ? classes.tabActive : classes.tab}
                        icon={<TextSnippetIcon />}
                        iconPosition="start"
                    />
                </Tabs>
            </AppBar>

            {
                <React.Fragment>
                    <Box className={classes.containerWrapper}>{getTabsData()}</Box>
                </React.Fragment>
            }
        </React.Fragment>
    );
};
