import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlarmType } from '../../types/alarm';

const initialState: AlarmType = {
    sortParameter: 'status',
    sortType: 'DESC',
    alarmFilterApplied: false,
    alarmSortApplied: false,
    isInvalidDate: false,
    searchEventName: '',
    filterStatus: [],
    selectedAlarmDetails: {
        alarmDate: '',
        alarmTime: '',
        alarmType: '',
        status: '',
    },
    filterSelected: {
        filterParameters: [],
        searchByEventName: '',
        filterTags: [],
        severityActive: {
            ActiveAlarm: false,
            Offline: false,
            Warning: false,
            Info: false,
            Action: false,
        },
    },
    filterDateParam: '',
    pageNum: 1,
    pageSize: 10,
    alarmListTotal: 0,
};
const alarmSlice = createSlice({
    name: 'alarm',
    initialState,
    reducers: {
        setSelectedAlarmDetails: (state, { payload }: PayloadAction<any>) => {
            state.selectedAlarmDetails = payload;
        },
        setAlarmSorting: (state, action: PayloadAction<{ [key: string]: string }>) => {
            state.sortParameter = action.payload.sortParameter;
            state.sortType = action.payload.sortType;
            if (state.sortType !== '') {
                state.alarmSortApplied = true;
            } else {
                state.alarmSortApplied = false;
            }
        },
        resetAlarmSort: (state) => {
            state.alarmSortApplied = initialState.alarmSortApplied;
            state.sortParameter = initialState.sortParameter;
            state.sortType = initialState.sortType;
        },
        setAlarmFilterSelected: (state, action: PayloadAction<any>) => {
            state.filterSelected = action.payload;
        },
        setValidDateRage: (state, action: PayloadAction<any>) => {
            state.isInvalidDate = action.payload;
        },
        setSearchEventName: (state, { payload }: PayloadAction<string>) => {
            state.searchEventName = payload;
            state.pageNum = initialState.pageNum;
        },
        resetAlarmFilter: (state) => {
            state.alarmFilterApplied = initialState.alarmFilterApplied;
            state.filterSelected = initialState.filterSelected;
            state.searchEventName = initialState.searchEventName;
            state.pageNum = initialState.pageNum;
            state.pageSize = initialState.pageSize;
            state.filterStatus = initialState.filterStatus;
            state.filterDateParam = initialState.filterDateParam;
        },
        setFilterParam: (state, action: PayloadAction<string[]>) => {
            const filterStatusArray: string[] = [];
            if (action.payload.length > 0) {
                action.payload.map((item) => {
                    switch (item) {
                        case 'ActiveAlarm': {
                            filterStatusArray.push('Alarm Active');
                            break;
                        }
                        case 'Info': {
                            filterStatusArray.push('Informational');
                            break;
                        }
                        case 'Warning': {
                            filterStatusArray.push('Warning Active');
                            filterStatusArray.push('Warning Cleared');
                            break;
                        }
                        default: {
                            filterStatusArray.push(item);
                        }
                    }
                });
            }
            state.filterStatus = filterStatusArray;
        },
        setFilterDateParam: (state, action: PayloadAction<any>) => {
            state.filterDateParam = action.payload;
        },
        toggleAlarmFilter: (state, action: PayloadAction<boolean>) => {
            state.alarmFilterApplied = action.payload;
        },
    },
});

export const {
    setSelectedAlarmDetails,
    setAlarmSorting,
    resetAlarmSort,
    setAlarmFilterSelected,
    setSearchEventName,
    resetAlarmFilter,
    setValidDateRage,
    setFilterParam,
    setFilterDateParam,
    toggleAlarmFilter,
} = alarmSlice.actions;

export default alarmSlice;
