import { DELETE_KEY } from '@fiji/common/src/constants';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

/**
 * The `DeleteModalProps` type defines the props for a delete modal component in a TypeScript React
 * application.
 * @property {boolean} isVisible - A boolean value indicating whether the delete modal is visible or
 * not.
 * @property closeHandler - A function that will be called when the modal is closed.
 * @property {string} wrapperClass - A string representing the CSS class for the wrapper element of the
 * delete modal.
 * @property {string} titleClass - The `titleClass` property is a string that represents the CSS class
 * to be applied to the title of the delete modal.
 * @property {string} headingText - The heading text is a string that represents the main title or
 * heading of the delete modal.
 * @property {string} deleteButtonClass - This property is used to specify the CSS class for the delete
 * button in the delete modal.
 * @property confirmClick - A function that will be called when the delete button is clicked and the
 * user confirms the deletion.
 * @property {string} actionClass - The `actionClass` property is a string that represents the CSS
 * class to be applied to the action buttons in the delete modal.
 * @property description - The `description` property is a JSX element that represents the additional
 * description or information to be displayed in the delete modal. It can contain any valid JSX content
 * such as text, HTML tags, or other React components.
 * @property {string} deleteButtonText - The `deleteButtonText` property is a string that represents
 * the text to be displayed on the delete button in the delete modal.
 * @property {boolean} noHelperText - A boolean flag indicating whether to display any helper text in
 * the delete modal. If set to true, no helper text will be displayed.
 * @property {boolean} noExtraDescription - A boolean flag indicating whether to hide the extra
 * description in the delete modal.
 */
type DeleteModalProps = {
    isVisible: boolean;
    closeHandler: () => void;
    wrapperClass: string;
    titleClass: string;
    headingText: string;
    deleteButtonClass: string;
    confirmClick: () => void;
    actionClass: string;
    description: JSX.Element;
    deleteButtonText: string;
    noHelperText?: boolean;
    noExtraDescription?: boolean;
};

/* The `DeleteModal` component is a reusable modal component in a TypeScript React application. It
displays a dialog box with a title, description, and input field for the user to confirm a deletion
action. */
const DeleteModal = ({
    isVisible,
    closeHandler,
    wrapperClass,
    titleClass,
    headingText,
    deleteButtonClass,
    confirmClick,
    actionClass,
    description,
    deleteButtonText,
    noExtraDescription,
}: DeleteModalProps): JSX.Element => {
    const [deleteKey, setDeleteKey] = useState<string>('');

    /**
     * The function `deleteKeyHandler` updates the state variable `deleteKey` with the value of an
     * input element, but only if the length of the value is less than or equal to a constant
     * `DELETE_KEY`.
     * @param e - The parameter `e` is of type `React.ChangeEvent<HTMLInputElement>`. This means it is
     * an event object that is triggered when the value of an input element of type `HTMLInputElement`
     * changes.
     * @returns The function `deleteKeyHandler` returns `void`, which means it does not return any
     * value.
     */
    const deleteKeyHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (e.target.value?.length > DELETE_KEY) {
            return;
        }
        setDeleteKey(e.target.value);
    };

    /**
     * The `confirmHandler` function clears the delete key and triggers the `confirmClick` function.
     */
    const confirmHandler = (): void => {
        setDeleteKey('');
        confirmClick();
    };

    return (
        <Dialog
            id={'delete-modal'}
            open={isVisible}
            onClose={(): void => {
                closeHandler();
                setDeleteKey('');
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={wrapperClass}
        >
            <DialogTitle id="alert-dialog-title" className={titleClass} sx={{ wordWrap: 'break-word' }}>
                <Typography variant={'h6'}> {headingText ?? 'Delete Users?'}</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant={'body2'} mb={2} sx={{ wordWrap: 'break-word' }}>
                    {description}
                </Typography>
                {!noExtraDescription && (
                    <Typography variant={'body2'} mb={2}>
                        To proceed, type &apos;<strong>{'delete'}</strong>&apos; in the text box below and then select
                        the
                        {` '${deleteButtonText}'`} button.
                    </Typography>
                )}
                <TextField
                    id={'typeDelete'}
                    label={'Type “delete” to confirm'}
                    fullWidth
                    onChange={deleteKeyHandler}
                    variant={'filled'}
                    value={deleteKey}
                />
                <Typography variant="body2" className="custom-add-device-field-lengths-style">
                    {`${deleteKey?.length || 0}/${DELETE_KEY}`}
                </Typography>
            </DialogContent>
            <DialogActions className={actionClass}>
                <Stack direction={'row'} spacing={2} justifyContent={'end'}>
                    <Button
                        variant={'outlined'}
                        onClick={(): void => {
                            setDeleteKey('');
                            closeHandler();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={'contained'}
                        disabled={deleteKey !== 'delete'}
                        onClick={confirmHandler}
                        className={deleteButtonClass}
                    >
                        {deleteButtonText}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteModal;
