/* eslint-disable @typescript-eslint/no-misused-promises */
import { Avatar, Box, Chip, IconButton, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import CustomTable from '../../components/CustomTable';
import * as Colors from '@brightlayer-ui/colors';
import {
    AddBox,
    ChevronRight,
    MoreVert,
    Email,
    ChatBubbleOutline,
    Delete,
    EmailOutlined,
    ChatBubble,
} from '@mui/icons-material';
import { UserMenu, UserMenuItem } from '@brightlayer-ui/react-components';
import { useTheme } from '@mui/material/styles';
import { addNotificationModal } from '@fiji/common/src/features/notification/notificationSlice';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import {
    useDeleteCustomNotificationMutation,
    useGetCustomNotificationsQuery,
} from '@fiji/common/src/features/customNotification/customNotificationApi';
import TuneIcon from '@mui/icons-material/Tune';
import {
    useConfirm,
    useFilteredValues,
    useGetHierarchyHandlers,
    useIsMount,
    useRBAC,
    useSelectedIds,
} from '../../hooks';

import { setToastifyContent } from '@fiji/common/src/features/common/commonSlice';
import HelpIcon from '@mui/icons-material/Help';
import { useGetAllEventsQuery } from '@fiji/common/src/features/events/eventsApi';
import Hierarchy from '../../components/Hierarchy';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { CustomNotificationList, CustomNotificationType, EventList } from '@fiji/common/src/types';

import { useStyles } from '../ManageDevices/styles';
import ConfirmModal from '../../components/ConfirmModal';
import { selectHierarchyData } from '@fiji/common/src/features/group/groupSlice';

type PayloadType = {
    size: number;
    page: number;
    filters: {
        [key: string]: string[] | string;
    };
    sortData?: { key: string; sortType: string };
    searchKey?: string;
};

type TableRefWithFunctions = {
    resetFilters: (arg0: string, arg1?: boolean) => void;
    resetCheckboxes: () => void;
    dropDownHandler: (arg0: string) => void;
};

type MenuItem = {
    title: string;
    icon: JSX.Element;
    className?: string;
    onClick: () => void;
};

export const NotificationTable = ({ loading }: { loading: boolean }): JSX.Element => {
    const dispatch = useAppDispatch();

    const theme = useTheme();
    const classes = useStyles(theme);
    const initialRender = useIsMount();
    const tableRef = React.useRef<TableRefWithFunctions>();

    const deleteModalId = React.useId();

    const rolePermissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(rolePermissions);
    const canAddNotification = hasPermission('create-custom-notification');
    const canDeleteNotification = hasPermission('delete-custom-notification');
    const canEditNotification = hasPermission('update-custom-notification');

    const [payload, setPayload] = React.useState<PayloadType>({ size: 10, page: 0, filters: {} });

    const hierarchyData = useTypedSelector(selectHierarchyData);

    const { selectedGroups, handleSelectGroup, groupSelectionHandler } = useGetHierarchyHandlers({});
    const { data: allEvents } = useGetAllEventsQuery<{ data: EventList }>(undefined, {
        refetchOnMountOrArgChange: true,
    });
    const [selectedIds, setSelectedIds] = useSelectedIds();

    const [deleteNotifcation] = useDeleteCustomNotificationMutation();

    /* The below code is using the `useEffect` hook in React to update the `payload` state whenever the
    `selectedGroups` state changes. It checks if it is not the initial render and then updates the
    `filters` property of the `payload` state by merging the previous `filters` object with a new
    `groups` property that is set to the value of `selectedGroups`. */
    React.useEffect(() => {
        if (!initialRender) {
            const filterClone = JSON.parse(JSON.stringify(payload?.filters));
            if (selectedGroups?.length) {
                filterClone['groups'] = selectedGroups;
            } else {
                delete filterClone['groups'];
            }
            setPayload((prev: PayloadType) => ({
                ...prev,
                page: 0,
                filters: { ...filterClone },
            }));
        }
    }, [selectedGroups]);

    /**
     * The function `getMenuItems` returns an array of objects representing menu items, each with a
     * title, icon, and onClick function.
     * @returns The function `getMenuItems` returns an array of `UserMenuItem` objects.
     */
    const getMenuItems = (): UserMenuItem[] => {
        const items: MenuItem[] = [];
        if (canAddNotification) {
            items.push({
                title: 'Add',
                icon: <AddBox />,
                onClick: (): void => {
                    dispatch(addNotificationModal({ isOpen: true }));
                },
            });
        }
        if (canDeleteNotification) {
            items.push({
                title: 'Delete',
                className: !selectedIds?.length ? 'disabled' : '',
                icon: <Delete />,
                onClick: (): void => {
                    openDeleteModal();
                },
            });
        }

        return items;
    };

    /**
     * The function `getCellElements` takes in a `data` object and a `key` string, and returns a JSX
     * element that displays the name of the first item in the `data[key]` array, along with the number
     * of other items in the array. If the array is empty, it displays "N/A".
     * @param {CustomNotificationType} data - The `data` parameter is of type `CustomNotificationType`.
     * It is an object that contains some data.
     * @param {string} key - The `key` parameter is a string that represents the key of the property in
     * the `data` object that you want to access.
     */
    const getCellElements = (data: CustomNotificationType, key: string): JSX.Element => (
        <Typography variant="body1">
            {data?.[key]?.length > 1
                ? `${data?.[key]?.[0]?.name} and ${data?.[key]?.length - 1} Others `
                : data?.[key]?.[0]?.name ?? '-'}
        </Typography>
    );

    /**
     * The function `getNotificationType` returns a JSX element that displays different icons based on
     * the presence of certain properties in the `data` object.
     * @param {CustomNotificationType} data - The `data` parameter is an object that contains information about the
     * notification types. It is expected to have the following properties:
     */
    const getNotificationType = (data: CustomNotificationType): JSX.Element => (
        <Stack direction={'row'} spacing={2}>
            {data?.email ? <Email sx={{ color: Colors.blue['500'] }} /> : <EmailOutlined />}
            {data?.sms ? <ChatBubble sx={{ color: Colors.blue['500'] }} /> : <ChatBubbleOutline />}
        </Stack>
    );

    /**
     * The function "handleTableCheckboxes" sets the selected IDs based on the provided data.
     * @param {string[]} data - An array of strings representing the selected checkboxes in a table.
     */
    const handleTableCheckboxes = (data: string[]): void => {
        setSelectedIds(data);
    };

    /* The below code is defining a function called `getGroupHeaderOptions` that returns a JSX element.
    The JSX element is a component called `Hierarchy` with various props being passed to it. These
    props include `multiSelectionKey`, `selectedNodes`, `onChangeTreeItem`, `isMultiSelect`,
    `hierarchyData`, and `filter`. The `Hierarchy` component is likely used to display a
    hierarchical tree structure with the ability to select multiple nodes and handle changes in
    selection. */
    const getGroupHeaderOptions = (): JSX.Element => (
        <Hierarchy
            multiSelectionKey="children"
            selectedNodes={selectedGroups}
            onChangeTreeItem={(value: any, key?: string, type?: string, isChecked?: boolean): void =>
                handleSelectGroup(value, hierarchyData?.data?.data?.data, type, isChecked, key)
            }
            isMultiSelect
            hierarchyData={hierarchyData?.data?.data?.data}
        />
    );

    /**
     * The function `getActionCellExtraOptions` returns a JSX element representing a user menu with an
     * avatar and menu items.
     */
    const getActionCellExtraOptions = (): JSX.Element => (
        <UserMenu
            id="users"
            avatar={
                <IconButton id="device-action-btn" size="large">
                    {' '}
                    <MoreVert />
                </IconButton>
            }
            menuGroups={[
                {
                    items: getMenuItems(),
                },
            ]}
        />
    );

    /**
     * The function `handleFilterChange` updates the `payload` state based on the provided filters,
     * page, size, and sort data.
     * @param filters - An object representing the filters to be applied to the data. It could contain
     * various properties based on the specific requirements of the application.
     * @param page - The page parameter represents the current page number in the pagination. It is used
     * to determine which page of data to fetch or display.
     * @param size - The `size` parameter represents the number of items to be displayed per page in a
     * paginated list.
     * @param [sortData] - The `sortData` parameter is an optional parameter of type
     * `PayloadType['sortData']`. It is used to specify the sorting criteria for the data.
     */
    const handleFilterChange = (
        filters: PayloadType['filters'],

        sortData?: PayloadType['sortData']
    ): void => {
        if (JSON.stringify(filters) !== JSON.stringify(payload.filters)) {
            setPayload((prev: PayloadType) => ({
                ...prev,
                page: 0,
                filters: prev?.filters?.groups?.length ? { groups: prev?.filters?.groups, ...filters } : filters,
            }));
        } else if (sortData && Object.keys(sortData)?.length) {
            setPayload((prev: PayloadType) => ({
                ...prev,
                page: 0,
                sort: sortData,
            }));
        }
    };

    const handlePaginationChange = (page: PayloadType['page'], size: PayloadType['size']): void => {
        if (page || size) setPayload((prev: PayloadType) => ({ ...prev, page: page, size: size }));
    };

    /**
     * The function `deleteNotificationHandler` deletes custom notifications and displays a toast
     * message indicating the number of notifications deleted.
     */
    const deleteNotificationHandler = async (): Promise<void> => {
        const { error }: any = await deleteNotifcation({ notificationIds: selectedIds });
        if (!error) {
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: ` ${selectedIds.length} custom notifications deleted successfully`,
                    duration: 3000,
                })
            );

            tableRef?.current?.resetCheckboxes();
        }
    };

    const handleChipDelete = (key: string): void => {
        tableRef?.current?.resetFilters(key, true);
        const payloadClone = JSON.parse(JSON.stringify(payload));
        if (key === 'groups') {
            groupSelectionHandler([]);
            delete payloadClone['filters'][key];
            setPayload(payloadClone);
        }
    };

    const {
        isVisible: isDeleteModalOpen,
        onClick: openDeleteModal,
        onCancel: closeDeleteModal,
        onConfirm: confirmDeletion,
    } = useConfirm(deleteNotificationHandler as any);

    const getActionButtons = (data: CustomNotificationType): JSX.Element =>
        canEditNotification && (
            <ChevronRight
                onClick={(): void => {
                    dispatch(addNotificationModal({ isOpen: true, notificationId: data?.id }));
                }}
                sx={{ cursor: 'pointer' }}
                className={classes.hevronRight}
            />
        );

    const columns: any[] = [
        {
            header: '',
            isSelectable: true,
        },
        {
            header: 'Name',
            accessor: 'name',
            width: '10%',
            isSortable: true,
            isDebounce: true,
            isFilterable: true,
        },
        {
            header: 'Device',
            accessor: 'devices',
            isFilterable: true,
            isSortable: true,
            isDebounce: true,
            width: '10%',
            cell: (data: CustomNotificationType): JSX.Element => getCellElements(data, 'devices'),
        },
        {
            header: 'Events',
            width: '20%',
            accessor: 'events',
            isFilterable: true,
            isSortable: true,
            cell: (data: CustomNotificationType): JSX.Element => getCellElements(data, 'events'),
            filterOptions: [{ id: 'all', label: 'All' }].concat(
                allEvents?.data?.records
                    ?.map((event: any) =>
                        event?.child?.map((childEvent: any) => ({ id: childEvent?.id, label: childEvent?.name }))
                    )
                    .flat(Infinity)
            ),
        },
        {
            header: 'Group',
            width: '20%',
            accessor: 'groups',
            isSortable: true,
            isFilterable: true,
            headerOptions: getGroupHeaderOptions,
            cell: (data: CustomNotificationType): JSX.Element => getCellElements(data, 'groups'),
        },
        {
            header: 'Users',
            accessor: 'users',
            width: '20%',
            isSortable: true,
            isFilterable: true,
            isDebounce: true,
            cell: (data: CustomNotificationType): JSX.Element => getCellElements(data, 'users'),
        },
        {
            header: 'Notification Types',
            width: '25%',
            cell: getNotificationType,
            accessor: 'notificationTypes',
            isFilterable: true,
            filterOptions: [
                { id: 'all', label: 'All' },
                { label: 'SMS', id: 'sms' },
                { label: 'Email', id: 'email' },
            ],
        },
        {
            extraOptions: getActionCellExtraOptions,
            width: '5%',
            cell: getActionButtons,
        },
    ];

    const [tableFilters] = useFilteredValues({ allFilters: columns, triggeredFilters: payload?.filters });

    const {
        data: customNotifications,
        isLoading,
        isFetching,
    } = useGetCustomNotificationsQuery<{
        data: CustomNotificationList;
        isLoading: boolean;
        isFetching: boolean;
    }>({ ...payload, filters: tableFilters }, { refetchOnMountOrArgChange: true });

    return (
        <>
            <Box sx={{ margin: '0 16px' }}>
                <Paper
                    sx={{
                        padding: '16px',
                        margin: '16px 0',
                    }}
                    className="modal-space-between"
                >
                    <Box sx={{ gap: '16px' }} className="modal-align-center">
                        <Avatar>
                            <HelpIcon />
                        </Avatar>
                        <Typography variant="body2">
                            {`Custom Notifications can be created by Group, Device Type, Devices, and/or Events. Custom notifications can be assigned to existing User Accounts or non-user Contacts.`}
                        </Typography>
                    </Box>
                </Paper>
                <Stack direction="row" marginBottom={'16px'} justifyContent={'end'} spacing={1}>
                    {Object.keys(payload?.filters).map((key: string) => (
                        <Chip
                            key={`unique${key}`}
                            label={key}
                            onDelete={(): void => {
                                handleChipDelete(key);
                            }}
                        />
                    ))}
                </Stack>
                <CustomTable
                    isLoading={isLoading || isFetching || loading}
                    total={customNotifications?.data?.total}
                    isPagination
                    ref={tableRef}
                    controlledPageSize={payload?.page}
                    handleCheckboxSelect={handleTableCheckboxes}
                    data={customNotifications?.data?.records}
                    headers={columns}
                    noDataFoundDescription={
                        <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: '600', color: '#424E54' }}>
                            Custom Notifications can be created by group,
                            <br />
                            Device Type, Devices, and/or Events. Custom
                            <br />
                            notifications can be assigned to existing User
                            <br />
                            Accounts or non-user Contacts.
                        </Typography>
                    }
                    {...(canAddNotification && {
                        noDataFoundButtonText: 'Add a Custom Notification',
                        noDataFoundButtonAction: (): void => {
                            dispatch(addNotificationModal({ isOpen: true }));
                        },
                    })}
                    noDataFoundIcon={<TuneIcon sx={{ height: '75px', width: '75px', color: '#727E84' }} />}
                    noDataFoundTitle="No Custom Notifications"
                    keyToTraverse="id"
                    handleFilterChange={handleFilterChange}
                    handlePageChange={handlePaginationChange}
                />
            </Box>

            <ConfirmModal
                key={deleteModalId}
                confirmButtonText={selectedIds?.length > 1 ? 'Delete Notifications' : 'Delete Notification'}
                closeHandler={closeDeleteModal}
                confirmClick={confirmDeletion}
                isVisible={isDeleteModalOpen}
                confirmButtonClass={classes.deleteButton}
                wrapperClass={classes.muiDialogpaper}
                actionClass={classes.MuiDialogActionsRoot}
                titleClass={classes.MuiDialogTitleRoot}
                description={
                    <>
                        {selectedIds?.length > 1
                            ? `${selectedIds?.length} notifications will be permanently deleted.This cannot be undone.`
                            : 'This notification will be permanently deleted.This cannot be undone.'}
                    </>
                }
                headingText={selectedIds?.length > 1 ? 'Delete Notifications?' : 'Delete Notification?'}
            />
        </>
    );
};
