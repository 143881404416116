const resources = {
    translation: {
        BLUI: {
            FORGOT_PASSWORD: {
                INSTRUCTIONS:
                    'If the entered email address has an account with Eaton, you will receive a response within <b>ten minutes</b>.\n\n' +
                    'For immediate support during business hours dial {{phone}}.',
                LINK_SENT: 'A link to reset your password has been sent to <b>{{email}}</b>',
            },
            SELF_REGISTRATION: {
                INSTRUCTIONS:
                    'To register for an Eaton account. You will need to verify your email address to continue.',
            },
            FORMS: {
                RESET_PASSWORD: 'Forgot Password',
                PASS_MATCH_ERROR: 'Passwords do not match',
            },
            ACTIONS: {
                DONE: 'Close',
                CREATE_ACCOUNT: 'Sign Up',
            },
            MESSAGES: {
                CONTACT: 'Need Help?',
                PRIVACY: 'Privacy Policy',
                ERROR: 'Error',
            },
            CHANGE_PASSWORD: {
                PASSWORD_INFO:
                    'Password must be 8-16 characters in length, contain at least one uppercase character, one lowercase character, one number, and one special character.',
            },
            PASSWORD_REQUIREMENTS: {
                LENGTH: '8-16 characters in length',
                NUMBERS: 'At least one number',
                UPPER: 'At least one uppercase letter',
                LOWER: 'At least one lowercase letter',
                SPECIAL: 'At least one special character (! @ # $ ^ &)',
            },
        },
        MFA: {
            REQUEST_CODE_MSG: 'Request a Security Code',
            SENT_CODE_MSG: 'We Sent A Code!',
            SELECT_CODE_DEST_MSG: 'Select where you would like the security code sent.',
            CHECK_DEST_MSG: 'Check {{field}} for a code and enter it below',
            GET_NEW_CODE_LABEL: 'Send Again',
            VERIFY_CODE_FAIL_MSG: 'Verification code incorrect.  Please try again.',
            VERIFICATION_CODE_SENT: '{{field}} verification code sent.',
            VERIFY_TRIES_LEFT_MSG: 'You have {{field}} more attempt(s).',
            NO_VERIFY_TRIES_LEFT_MSG: "Error, you've exceeded your ten attempts. Send new code.",
        },

        LABELS: {
            SMS: 'SMS',
            PHONE: 'phone',
            YOUR_SMS: 'your SMS',
            EMAIL: 'email',
            YOUR_EMAIL: 'your email',
            ADD_DEVICE: 'Add Device',
            VERIFY: 'Verify',
            SEARCH: 'Search',
        },
        REGISTRATION: {
            COUNTRY_CODE: 'Country Code',
            PHONE_NUMBER: 'Phone Number (Optional)',
            COUNTRY: 'Country',
            WELCOME: 'Welcome',
            SUCCESS_MESSAGE_TEXT: 'Your account has been successfully created with the email <b>{{email}}</b>.',
            SUCCESS_MESSAGE_TEXT_1:
                '\n\nYour account has already been added to the <b>{{organization}}</b> organization.\n\nSelect Log In below to Log In.',
            FAILURE_MESSAGE:
                'We were unable to complete your registration. Press continue below to continue to Eaton Brightlayer Industrial suite.',
            MULTIFACTOR_AUTH_TEXT: 'Enable Two-factor Authentication',
            STEPS: {
                MULTIFACTOR_TITLE: 'Secure Your Account',
            },
            MULTIFACTOR_TITLE: 'Secure Your Account With Two-Factor Authentication',
            MULTIFACTOR_TEXT:
                'Prevent unauthorized access for your account by adding an additional layer of security. When you sign in, two-factor authentication helps make sure your information remians safe, private and secure.',
            MULTIFACTOR_EXTRA_PROTECTION_TITLE: 'Extra Protection',
            MULTIFACTOR_EXTRA_PROTECTION_TEXT:
                'We’ll ask for your password and then a login code when we notice an unusual login request for your account.',
            MULTIFACTOR_SMS_TTITLE: 'VIA SMS Message or Email',
            MULTIFACTOR_SMS_TEXT: 'A code will be sent to you via SMS Message or Email.',
            AREA_CODE: 'Area Code',
        },
        ADD_DEVICE_FORM: {
            INFORM_MANDATORY_FIELDS: 'Fields marked with an asterisk (*) are required to add a Device',
            // PRODUCT_ID_TAKEN_ERROR_MSG: 'A device with this Product ID has already been added to the system',
            // SERIAL_NUM_TAKEN_ERROR_MSG: 'A device with this serial number has already been added to the system.',
            GUID_TAKEN_ERROR_MSG: 'A device with this GUID number has already been added to the system.',
            SAVE_DEVICE: 'Save Device',
            SAVE_AND_ADD_NEW_DEVICE: 'Save & Add New Device',
            CANCEL: 'Cancel',
            INVALID_DEVICE_NAME:
                'Please enter a valid device name. Device names support alphanumeric characters, -, and _.',
            INVALID_GUID: 'Please enter a valid GUID.',
            INVALID_PRODUCT_ID: 'Please enter a valid Product ID.',
            INVALID_SERIAL_NUM: 'Please enter a valid Serial Number.',
            DEVICE_SAVED_SUCCESSFULLY: 'Device Saved Successfully',
            CONFIGURATION_APPLY_SUCCESSFULLY: 'Device successfully updated with new configuration.',
            NETWORK_SETTINGS: 'Network Settings',
        },
        ADD_DEVICE_OPTION: {
            ERROR_TITLE: 'Error Retrieving Details',
            ERROR_QRCODE_MSG: 'Unable to read QR code data',
            ERROR_REQUEST_MSG: 'Unable to process request',
            ENTER_MANUALLY_BTN_LBL: 'Enter Manually',
            SCAN_AGAIN_BTN_LBL: 'Scan Again',
            CAMERA_USER_MSG: 'Align reticles with QR Code on back of device',
        },
        DEVICE: {
            NFC_PAIRING_LABEL: 'Configure Device(s) with NFC',
            ADD_MANUALLY_LABEL: 'Add Device Manually',
            ASSIGN_TO_GROUP_LABEL: 'Assign to Group',
            SORT_CRITERIA: {
                LABEL_DEVICE: 'Device Name',
                LABEL_GROUP: 'Group',
            },
            FILTER: {
                STATUS: 'Status',
                ACTIVE_ALARM: 'Active Alarm',
                OFFLINE: 'Offline',
                WARNING: 'Warning',
                NO_ACTIVE_ALERT: 'No Active Alerts',
                TYPE: 'Type',
                APPLY_FILTERS: 'Apply Filters',
                CLEAR_FILTERS: 'Clear All Filters',
            },
            ADD_NFC_CONFIG: {
                BTN_EXPAND: 'Expand All',
                IPV4_SECTION: 'IPv4 Settings',
                IPV6_SECTION: 'IPv6 Settings',
                PROXY_SETTINGS: 'Proxy Settings',
                DNS_SETTINGS: 'DNS Settings',
                IPV4_TYPE_DHCP: 'DHCP (Automatic)',
                IPV6_TYPE_SLACC: 'SLACC (Automatic)',
                IP_STATIC: 'Static',
                IPV4_METHOD: 'IPv4 Method*',
                IPV4_ADDRESS: 'IPv4 Address*',
                IPV4_SUBNET: 'IPv4 Subnet*',
                IPV4_GATEWAY: 'IPv4 Gateway*',
                IPV6_METHOD: 'IPv6 Method*',
                IPV6_ADDRESS: 'IPv6 Address*',
                IPV6_PREFIX: 'IPv6 Prefix*',
                IPV6_GATEWAY: 'IPv6 Gateway*',
                PROXY_IP: 'Proxy IP*',
                PROXY_PORT: 'Proxy Port*',
                PROXY_AUTH: 'Proxy Settings',
                PROXY_USER: 'Proxy User',
                PROXY_PASS: 'Proxy Password',
                PROXY_CPASS: 'Confirm Proxy Password*',
                PRIMARY_DNS: 'Primary DNS*',
                SEC_DNS: 'Secondary DNS*',
                HOST_NAME: 'Hostname*',
                DOMAIN_NAME: 'Domain Name*',
                CONFIGURATION_SAVED_SUCCESSFULLY: 'Configuration Profile Saved Successfully',
            },
            NFC: {
                TITLE: 'Configure and Add a Device',
                SUB_TITLE: 'Select a Configuration Profile',
                NO_CONFIG_FOUND: 'No Configuration Profiles found',
                NO_CONFIG_MSG:
                    'Configuration Profiles let you configure and claim devices into this organisation. Create a new profile to add devices.',
                BTN_ADD_CURRENT_PROFILE: 'Add Configuration Profile',
                BTN_ADD_NEW_PROFILE: 'Add Device Using Current Configuration',
                BTN_ADD_EXISTING_PRFILE: 'Use Current Device Configuration',
                BTN_ADD_NEW_PRFILE_LIST: 'Add New Configuration Profile',
                BTN_CANCEL: 'Cancel',
                BTN_SAVE: 'Save',
                BTN_ENTER_GUID_MANUALLY: 'Enter GUID Manually',
                ERROR_SAVE_TITLE: 'Error Saving Configuration',
                ERROR_SAVE_SUBTITLE: 'Unable to save configuration details to device.',
                ERROR_RETRIVE_TITLE: 'Error Retrieving Details',
                ERROR_RETRIVE_SUBTITLE: 'Error connecting to NFC',
                BTN_SCAN_AGAIN: 'Scan Again',
                BTN_ENTER_MANUALLY: 'Enter Manually',
                SCAN_TITLE: 'Ready to Scan',
                SCAN_LABEL: 'Hold your phone near the item to learn more about it.',
                SCAN_SUCCESS: 'Device successfully updated with new configuration',
                BANNER_MSG: 'Device Configured Successfully. Select a profile to configure another device.',
                NFC_DISABLE_MSG: 'Please enable NFC from settings.',
                ERROR_TITLE: 'Error',
                ERROR_MSG: 'Something went wrong. Please try again.',
                INFORM_MANDATORY_FIELDS: 'Fields marked with an asterisk(*) are required fields to add a Device.',
            },
            DELETE_NFC_MODAL: {
                MODAL_TITLE: 'Delete {{name}}?',
                MODAL_BODY: 'Select ‘Continue’ to permanently delete {{name}}.This cannot be undone.',
                MODAL_BODY_PART_ONE: 'To proceed, type ',
                MODAL_BODY_PART_DELETE: '‘delete’',
                MODAL_BODY_PART_TWO: ' in the text box below and then select the ‘Delete Organization’ button.',
                MODAL_FOOTER_DELETE_BTN: 'Delete Organization',
                MODAL_CONTINOUS: 'Continue',
                MODAL_CONFIRM_MSG: '{{name}} settings will be permanently deleted.This cannot be undone.',
                MODAL_TEXT_LABEL: 'Type “delete” to confirm',
                DEL_CONFIGURATION: 'Delete Configuration Profile',
                CONFIGURATION_DELETE_SUCCESSFULLY: 'Configuration Profile Deleted Successfully',
            },
            NFC_GUID_MANUAL: {
                TITLE: 'Enter GUID',
                GUID: 'GUID',
                PLACEHOLDER_TEXT: '********-****-****-****-************',
            },
            NFC_SCAN: {
                TITLE: 'Scan QR code',
                SUBTITLE: 'Retrieve GUID',
            },
        },
        LOGIN: {
            CREATE_ACCOUNT: 'Sign Up',
        },
        ORG_REGISTRATION: {
            CREATE_ORG: {
                TITLE: 'Create an Organization',
                UPLOAD_TITLE: 'Upload Organization Logo',
                ORG_NAME: 'Organization Name',
                CREATE_ORG: 'Create Organization',
            },
            JOIN_ORG: {
                TITLE: 'Join an Organization',
                NOTE_MSG:
                    'Enter the Organization Code below. Contact your administrator if you do not know your Organization Code.',
                ORG_CODE: 'Organization Code',
                JOIN_ORG: 'Join Organization',
                CANCEL_BTN: 'Cancel',
            },
            NO_ORG: {
                TITLE: 'No Organization',
                NOTE_MSG:
                    'You do not currently belong to an Organization. You’ll need to Join or Create an Organization to use the application.',
                CREATE_ORG_BTN: 'Create a New Organization',
                JOIN_ORG_BTN: 'Join an Existing Organization',
                PENDING_MSG: 'You’re currently waiting for approval to join {{orgs}}.',
                JOIN_ANOTHER_BTN: 'Join Another Organization',
                WITHDRAW_REQ_BTN: 'Withdraw Request',
            },
            ORG_CREATED: {
                TITLE: 'Organization Created!',
                FINISH_BTN: 'Finish',
                SUCCESS_MSG:
                    'You have successfully created the {{name}} organization! Tap Finish to continue to the application.',
                WELCOME: 'Welcome {{firstName}} {{lastName}}!',
            },
            ORG_JOINED: {
                TITLE: 'Organization Joined',
                FINISH_BTN: 'Finish',
                JOIN_ANOTHER_BTN: 'Join Another Organization',
                SUCCESS_MSG:
                    'You have successfully joined the {{name}} organization! Tap Finish to continue to the application.',
            },
            WAITING_APPROVAL: {
                TITLE: 'Awaiting Approval',
                NOTE_ONE:
                    'You’ll need to be approved by an administrator before you can join this Organization. You’ll receive an email notification when you’ve been approved.',
                NOTE_TWO:
                    'In the meantime you can continue setting up your account, get to know the application, or join another Organization.',
                CONTINUE_BTN: 'Continue',
                JOIN_BTN: 'Join Another Organization',
            },
            PENDING_APPROVAL: {
                TITLE: 'Awaiting Approval',
                NOTE_ONE:
                    'You’ve already requested to join this organization and are awaiting administrator  approval. You’ll receive an email notification when you’ve been approved.',
                NOTE_TWO:
                    'In the meantime, you can continue setting up your account, get to know the application, or join another Organization.',
                CLOSE_BTN: 'Close',
                NOTE_APPROVED:
                    'You are already a member of the {{name}} organization. Tap Finish to continue to the application.',
                BTN_FINISH: 'Finish',
                BTN_JOIN_ANOTHER: 'Join Another Organization',
            },
            WITHDRAW_ORG: {
                TITLE: 'Withdraw Your Request',
                NOTE: 'Withdraw your request to join {{name}}?',
                NOTE_2: 'Withdraw your request to join from...',
                BTN_WITHDRAW: 'Withdraw Request To Join',
                BTN_CANCEL: 'Cancel',
                BTN_WITHDRAW_ONLY: 'Withdraw',
                SUCCESS_MSG: 'Your request to join {{name}} has been withdrawn.',
                SUCESS_MSG_ORGS: 'Your request to {{number}} {{org}} have been withdrawn.',
                ORG: 'organization',
                ORGS: 'organizations',
            },
            ORG_DETAILS: {
                TITLE: 'Organization',
                SUB_TITLE: 'Settings',
                IMG_MSG: 'PNG, JPG (300x300px), 2 MB Max.',
                UPLOAD_BTN: 'Upload Organization Logo',
                REMOVE_LOGO_BTN: 'Remove Logo',
                REPLACE_LOGO_BTN: 'Replace Organization Logo',
                ORG_NAME: 'Organization Name',
                ORG_CODE: 'Organization Code',
                ORG_CODE_MSG: 'This code cannot be changed',
                AUTH_MSG: 'Require Two-factor Authentication (2FA)',
                BTN_SWITCH: 'Switch Organization',
                BTN_JOIN: 'Join an Organization',
                BTN_CREATE: 'Create an Organization',
                BTN_LEAVE: 'Leave this Organization',
                BTN_DELETE: 'Permanently Delete this Organization',
                BTN_SAVE: 'Save Changes',
                BTN_DISCARD: 'Discard Changes',
                BTN_CANCLE: 'Cancel',
                BTN_CAMERA: 'Take Photo',
                BTN_GALLARY: 'Choose Existing Photo',
                BTN_FILES: 'Browse Files...',
                ERROR_CAMERA: 'Error while uploading logo.',
                LEAVE_MSG: 'Once you leave this Organization you will lose all access.',
                BTN_LEAVE_ACTION: 'Leave Organization',
                PERMISSION_ERROR: 'Please enable permissions for image access in phone settings.',
            },
            ADMIN_LEAVE_MODAL: {
                TITLE: 'Assign Another User the Organization Admin Role Before Leaving',
                SUB_TITLE:
                    'As the only organization admin, you’ll need to grant that role to another user before you can leave',
                BTN_OK: 'Assign Role',
                BTN_CANCEL: 'Cancel',
            },
            ASSIGN_ROLE: {
                BTN_ASSIGN: 'Assign Role',
                BTN_CANCEL: 'Cancel',
                MSG_TITLE: 'Assign Another User the Organization Admin Role Before Leaving',
                MSG_BODY_ONE:
                    'As the only organization admin, you’ll need to grant that role to another user before you can leave ',
                MSG_BODY_TWO:
                    '. If no other users exist, you’ll need to add users to the organization first through the ',
                WEB_URL_TEXT: 'desktop application (URL).',
                MSG_BOTTOM: 'The Organization can also be deleted if you cannot add other users.',
            },
            CONFIRM_LEAVE: {
                BTN_LEAVE: 'Leave Organization',
                BTN_UNDO: 'Undo Assign Role',
                BTN_CANCEL: 'Back to Organization Settings',
            },
            SWITCH_ORG: {
                BTN_CANCEL_REQ: 'Cancel Request',
                BTN_CANCEL: 'Cancel',
                MODAL_TITLE: 'Cancel Request?',
                MODAL_DESC: 'Your request will be withdrawn from joining organization',
            },
        },
        MANAGEMENT: {
            DELETE_ORG_MODAL: {
                MODAL_TITLE: 'Delete {{name}}?',
                MODAL_BODY:
                    'Select ‘Continue’ to permanently delete {{name}} and all of it’s associated location, device, alarm, event, report, and user data. This cannot be undone.',
                MODAL_BODY_PART_ONE: 'To proceed, type ',
                MODAL_BODY_PART_DELETE: '‘delete’',
                MODAL_BODY_PART_TWO: ' in the text box below and then select the ‘Delete Organization’ button.',
                MODAL_FOOTER_DELETE_BTN: 'Delete Organization',
                MODAL_CONTINOUS: 'Continue',
                MODAL_CONFIRM_MSG:
                    '{{name}} and all of it’s associated location, device, alarm, event, report, and user data will be permanently deleted. This cannot be undone.',
                MODAL_TEXT_LABEL: 'Type “delete” to confirm',
            },
            USER_PROFILE: {
                HEADER_TITLE: 'My Profile',
                UPLOAD_BTN: 'Upload Image',
                REPLACE_IMAGE_BTN: 'Replace Image',
                REMOVE_IMAGE_BTN: 'Remove Image',
                IMG_FILE_UPLOAD_NOTE: 'PNG, JPG (300x300px), 2 MB Max.',
                PHONE_NUMBER: 'Phone Number',
                EMAIL: 'Email',
                COUNTRY: 'Country',
                PASSWORD: 'Password',
                MFA_TITLE: 'Two-factor Authentication',
                MFA_SUBTITLE: 'Required by the organization',
            },
            EDIT_USERNAME: {
                NOTE: 'Fields marked with an asterisk(*) are required fields when editing a User.',
                UPDATE_BTN: 'Update Username',
                UPDATE_NAME_TITLE: 'Update Name',
                FIRST_NAME: 'First Name*',
                LAST_NAME: 'Last Name*',
                UPDATE_SUCCESS_MSG: 'Username has been updated.',
            },
            EDIT_PHONE_NUMBER: {
                HEADER: 'Update Phone Number',
                HEADER_OTP: 'Verify Phone Number',
                TITLE: 'Phone No',
                BTN_BACK: 'Back',
                BTN_SUBMIT: 'Submit',
                BTN_NEXT: 'Next',
                ERROR_TITLE: 'Error',
                SUCCESS_MSG: 'Your phone number has been updated.',
                NOTE: 'To update your phone number, enter a new phone number in the fields below. You will need to verify your new phone number before the changes can be saved to your account.',
                HELP_TEXT: 'A mobile number is required in order to receive sms notifications.',
                OTP_NOTE:
                    'A verification code has been sent to the phone number you provided. Enter the code below to continue. This code is valid for 30 minutes.',
                OTP_HELP_TEXT: 'Didn’t receive a code?',
                OTP_BTN: 'Send Again',
            },
            EDIT_COUNTRY: {
                HEADER: 'Update My Country',
                TITLE: 'Country',
                BTN_BACK: 'Back',
                BTN_SUBMIT: 'Submit',
                ERROR_TITLE: 'Error',
                SUCCESS_MSG: 'Your country preference has been updated.',
                NOTE: 'Please select your country preferences',
            },
        },
        ACTIONS: {
            CANCEL_BTN: 'Cancel',
            CLOSE_BTN: 'Close',
        },
        GENERAL: {
            ERROR_TITLE: 'Error',
            NO_DATA: 'No Data',
            NO_DEVICES_FOUND: 'No Device Found',
            NO_EVENT_FOUND: 'No Event Found',
            ERROR_MESSAGE: 'Something went wrong',
            NETWORK_ERROR: "Can't connect to network from app.",
        },
        HELP: {
            GENERAL_QUESTION: 'General questions',
            EMERGENCY_SUPPORT: 'Emergency Support',
            MSG_BODY_ONE: 'For feedback questions, or support please email us at ',
            MSG_BODY_TWO: 'For immediate support during business hours ',
            MSG_BODY_THREE: ',Option 2, Option 9.',
        },
        NOTIFICATION_PREFERNCES: {
            LABEL: {
                EMAIL: 'Email Notifications',
                SMS: 'Text Notifications (SMS)',
                PUSH: 'Push Notifications',
                DAILY_DIGEST: 'Include in Daily Digest',
                WEEKLY_DIGEST: 'Include in Weekly Digest',
                TROUBLESHOOTING: 'Troubleshooting',
            },
            BTN: {
                APPLY_ALL_ORG: 'Apply to All Organizations',
                RESET_NOTIFICATION: 'Reset Notification Settings',
                SEND_TEST_EMAIL: 'Send a Test Email ',
                SEND_TEST_SMS: 'Send a Test Text Message ',
                SEND_PUSH_NOTIFICATION: 'Send a Test Push Notification',
            },
            MESSAGES: {
                EMAIL_SUCCESS_MSG: 'Test Email Sent',
                SMS_SUCCESS_MSG: 'Test Text (SMS) Sent',
                FAIL_MSG: 'Failed to sent test email or sms',
                RESET_SUCCESS_MSG: 'Notification preferences reset successfully..',
                SUCCESS_MSG: 'Notification preferences changed successfully.',
                ERROR_MSG: 'Failed to save {{type}} setting',
                SWITCH_ORG_MESSAGE:
                    'You are currently setting your notification preferences for Acme Holdings and the groups you have access to. Your Preferences do not affect other users.',
            },
            APPLY_ALL_ORG_DIALOG: {
                DIALOG_TITLE: 'Apply Your Preferences to All Organizations?',
                INFO_TEXT:
                    'Your Preferences for this Organization will be applied to your account on the following Organizations.',
                APPLY_BTN: 'Apply',
            },
        },
        CUSTOM_NOTIFICATION: {
            NO_DATA_TITLE: 'No Custom Notifications',
            NOTE: 'Custom Notifications can be created by Group, Device Type, Devices, and/or Events. Custom notifications can be assigned to existing User Accounts or non-user Contacts.',
            NO_DATA_BTN: 'Add a Custom Notification',
            CUSTOM_BTN: 'Add Notification',
        },
        NFC: {
            ADD_CONFIGURATION: 'Add Configuration Profile',
            EDIT_CONFIGURATION: 'Edit Configuration Profile',
            SAVE_CONFIGURATION: 'Save Configuration Profile',
            SAVE_AND_ADD_NEW_CONFIGURATION: 'Save as New Configuration Profile',
        },
        RESET_NOTIFICATION: {
            MODAL_TITLE: 'Reset Notification Preferences?',
            MODAL_BODY:
                'Are you sure you want to reset your notification prefrences? This does not apply to Custom Notifications.',
            MODAL_RESET: 'Reset Notifications',
        },
        ALARM: {
            DEVICE_TYPE_MODEL: 'Model #',
            DEVICE_SERIAL: 'Serial# ',
            SORT_CRITERIA: {
                LABEL_EVENT: 'Event',
                EVENT: 'event',
                LABEL_SEVERITY: 'Severity',
                Severity: 'severity',
                LABEL_DATE_TIME: 'Date & Time',
                DATE_TIME: 'dateandtime',
            },
            FILTER: {
                INFORMATIONAL: 'Informational',
                ACTION: 'Action',
            },
        },
        SORTING: {
            TITLE: 'Sort',
            ASCENDING: 'Ascending',
            DESCENDING: 'Descending',
            APPLY: 'Apply',
            RESET: 'Reset Sort Order To Default',
        },
        USER_LIST: {
            SELF_ACCOUNT_INFO: 'YOU',
            PENDING_STATUS: 'PENDING',
            APPROVE: 'Approve',
        },
        USER_LIST_MODAL: {
            ADD_USER: 'Add User',
            APPROVE_PENDING: 'Approve Pending',
            DISABLE_USER: 'Disable Users',
            DELETE_FOREVER: ' Delete Forever',
            RESEND_INVITATION: 'Resend Invitation',
        },
        INVITE_USER: {
            HEADER: 'Invite user to',
            INFO_LABEL: 'Field marked with * are important',
            FIRST_NAME: 'First Name',
            LAST_NAME: 'Last Name',
            EMAIL_LABEL: 'Email Address*',
            SELECT_ROLE: 'Select a Role*',
            ASSIGN_GROUP: 'Assign Group(s)*',
            ADD_ROLE_LABEL: 'Add Additional Roles',
            PHONE_NO_LABEL: 'Phone No',
            INVITE_BUTTON: 'Invite And Add another user',
        },
        ERROR: {
            TIMEOUT_TITLE: 'Session Timed Out',
            TIMEOUT_DESCRIPTION:
                'Your session timed out. To return to the application please reauthenticate your user session.',
            RE_LOGIN_BTN: 'Log In with Okta',
            NOT_FOUND_TITLE: 'Not Found',
            NOT_FOUND_DESCRIPTION: 'Resource not found',
            LOGOUT_BTN: 'Logout',
        },
    },
};
export default resources;
