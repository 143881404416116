import { InfoListItem } from '@brightlayer-ui/react-components';
import { AccountCircle } from '@mui/icons-material';
import { Button, List } from '@mui/material';
import React from 'react';

export const MobileView = (props: any): JSX.Element => (
    <>
        <List>
            <InfoListItem
                className={props.classes.userListInfoListItem}
                data-testid="infoListItem"
                data-cy={'list-content'}
                icon={<AccountCircle />}
                iconColor={props.colors.blue[500]}
                statusColor={props.colors.blue[50]}
                info={'Manage'}
                subtitle={'Tatianaaminoff@eaton.com'}
                title={'Aminoff Tatiana'}
                divider={'full'}
                avatar
                iconAlign="center"
                sx={{ wordWrap: 'break-word' }}
                hidePadding
                rightComponent={<Button variant={'outlined'}>Approve</Button>}
            />
            <InfoListItem
                className={props.classes.userListInfoListItem}
                data-testid="infoListItem"
                data-cy={'list-content'}
                icon={<AccountCircle />}
                iconColor={props.colors.blue[500]}
                statusColor={props.colors.blue[50]}
                info={'Manage'}
                subtitle={'Tatianaaminoff@eaton.com'}
                title={'Aminoff Tatiana'}
                divider={'full'}
                avatar
                iconAlign="center"
                sx={{ wordWrap: 'break-word' }}
                hidePadding
                rightComponent={<Button variant={'outlined'}>Approve</Button>}
            />
        </List>
    </>
);
