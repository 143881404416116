/**
 * The `useCleanPayload` function returns two helper functions, `cleanPayload` and
 * `removeDuplicateValues`, which can be used to clean and remove duplicate values from a payload
 * object.
 * @returns An array containing two functions: `cleanPayload` and `removeDuplicateValues`.
 */
export const useCleanPayload = (): any => {
    const cleanPayload = (payload: any): any => {
        for (const key in payload) {
            if (
                payload[key] === '' ||
                payload[key] === null ||
                (Array.isArray(payload[key]) && !payload[key]?.length)
            ) {
                delete payload[key];
            }
        }
        return payload;
    };

    const removeDuplicateValues = (currentPayload: any, previousPayload: any): any => {
        for (const key in currentPayload) {
            if (JSON.stringify(currentPayload[key]) === JSON.stringify(previousPayload[key])) {
                delete currentPayload[key];
            }
        }
        return currentPayload;
    };

    return [cleanPayload, removeDuplicateValues];
};
