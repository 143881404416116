import React from 'react';
import { createNumArray } from '../../CommonUtils';
import { Box, Skeleton, Typography } from '@mui/material';
import { ListItemTag } from '@brightlayer-ui/react-components';

const DeviceCardSkeleton = (): JSX.Element => (
    <>
        {createNumArray(3).map((item) => (
            <Box
                className="align-center"
                sx={{
                    padding: '10px 16px',
                }}
                key={`card${item}`}
            >
                <Box className="align-center" sx={{ flexBasis: '70%' }}>
                    <Box>
                        <Skeleton animation="wave" variant="circular" width={45} height={45} />
                    </Box>
                    <Box sx={{ paddingLeft: '10px' }}>
                        <Skeleton animation="wave">
                            <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>devicename</Typography>
                        </Skeleton>
                        <Skeleton animation="wave">
                            <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>devicename</Typography>
                        </Skeleton>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Skeleton animation="wave" variant="circular" width={10} height={10} />
                            <Skeleton animation="wave" sx={{ marginLeft: 1 }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>10%</Typography>
                            </Skeleton>
                        </Box>
                    </Box>
                </Box>
                <Box
                    className="d-flex"
                    sx={{
                        flexBasis: '30%',
                    }}
                >
                    <Skeleton animation="wave">
                        <ListItemTag label="Offline" />
                    </Skeleton>

                    <Skeleton animation="wave" variant="circular" height={30} width={30} />
                </Box>
            </Box>
        ))}
    </>
);

export default DeviceCardSkeleton;
