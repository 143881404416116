import React, { useState } from 'react';
import { Avatar, Skeleton, Stack, TextField, Typography, Chip, IconButton } from '@mui/material';
import { ChevronRight, Search, FormatListBulleted, FileDownload, CancelOutlined } from '@mui/icons-material';
import { ListItemTag } from '@brightlayer-ui/react-components';
import CustomTable from '../../components/CustomTable';
import { useNavigate, useParams } from 'react-router-dom';
import {
    useDownloadTimelineMutation,
    useGetTimeLineQuery,
    useGetTimelineStatusQuery,
} from '@fiji/common/src/features/timeline/timelineApi';
import { useCleanPayload, useDebounce, useFilteredValues, useGetHierarchyHandlers, useIsMount } from '../../hooks';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { TimelineStatusIcon } from '../../components/TimelineStatusIcon';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import Hierarchy from '../../components/Hierarchy';
import { setAppHeaderTitle, setSelectedGroup } from '@fiji/common/src/features/common/commonSlice';
import { downloadFileFromLink, toFirstLetterUpperCase } from '../../CommonUtils';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { UserProfile } from '@fiji/common/src/types';
import { useTransformDatTime } from '../../hooks/useTransformDatTime';
import { useTheme } from '@mui/material/styles';
import { useStyles } from '../ManageDevices/styles';
import { selectHierarchyData } from '@fiji/common/src/features/group/groupSlice';

export const TimelineTab = (): JSX.Element => {
    const { deviceId } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const selectedGroup = useTypedSelector((state) => state.common.groupId);
    const currentOrg = useTypedSelector(selectedOrg);
    const theme = useTheme();
    const classes = useStyles(theme);

    const tableRef = React.useRef<any>();

    const [payload, setPayload] = useState<any>({ page: 0, size: 10, filters: {} });
    const [isValid, setIsValid] = useState<boolean>(false);
    const [date, setDate] = useState<any>(null);
    const [searchKey, setSearchKey] = useState<string | undefined>(undefined);

    const hierarchyData = useTypedSelector(selectHierarchyData);

    const { data: timlineStatusList }: any = useGetTimelineStatusQuery();
    const { data: profileDetails } = useGetUserProfileQuery<{ data: UserProfile }>();

    const [downloadTimeline, { isSuccess: downloadTimelineSuccess, data: downloadedFile }] =
        useDownloadTimelineMutation();

    const [, debouncedValue] = useDebounce(undefined, undefined, searchKey);

    const { selectedGroups, handleSelectGroup, removeUnwantedIds, groupSelectionHandler } = useGetHierarchyHandlers({});
    const [cleanPayload] = useCleanPayload();
    const isMount = useIsMount();
    const { convertDateTime } = useTransformDatTime(profileDetails);
    React.useEffect(() => {
        if (!isMount) {
            if (!location.pathname.includes('device/details')) {
                dispatch(setSelectedGroup(''));
                dispatch(setAppHeaderTitle(''));
            }
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                searchKey: debouncedValue,
            }));
        }
    }, [debouncedValue]);

    React.useEffect(() => {
        if (downloadTimelineSuccess) {
            downloadFileFromLink(downloadedFile?.data, 'Timeline');
        }
    }, [downloadTimelineSuccess]);

    /* The `React.useEffect` hook is used to perform side effects in a functional component. In this
    case, the effect is triggered when the `isSuccess` or `selectedGroup` variables change. */

    React.useEffect(() => {
        const filteredGroupIds = removeUnwantedIds(hierarchyData?.data?.data?.data, selectedGroups, []);
        const filterClone = payload.filters ? JSON.parse(JSON.stringify(payload.filters)) : {};
        if (!filteredGroupIds?.groups?.length) {
            delete filterClone['groupId'];
        } else {
            filterClone['groupId'] = filteredGroupIds?.groups;
        }
        setPayload((prev: any) => ({
            ...prev,
            page: 0,
            filters: filterClone,
        }));
    }, [selectedGroups]);

    React.useEffect(() => {
        if (selectedGroup && payload.filters?.groupId) {
            setIsValid(true);
        }
    }, [selectedGroup, payload]);

    /* The below code is a useEffect hook in a TypeScript React component. It is triggered whenever the
    value of the `selectedGroup` variable changes. */
    React.useEffect(() => {
        if (hierarchyData?.data && !deviceId) {
            if (selectedGroup) {
                handleSelectGroup([selectedGroup], hierarchyData?.data?.data?.data);
                return;
            }
            setIsValid(true);
        }
    }, [selectedGroup, hierarchyData?.data]);
    /* The `React.useEffect` hook in the provided code is used to update the `payload` state whenever
    the `deviceId` variable changes. */
    React.useEffect(() => {
        if (deviceId) {
            setPayload((prev: any) => ({ ...prev, page: 0, filters: { ...prev.filters, deviceId: [deviceId] } }));
            setIsValid(true);
        }
    }, [deviceId]);

    React.useEffect(() => {
        if (date) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                filters: { ...prev.filters, createdAt: new Date(date)?.getTime() + 86399000 },
            }));
        }
    }, [date]);

    const handleGroupSelect = (nodeIds: any, key?: string, type?: string, isChecked?: boolean): void => {
        if (nodeIds !== selectedGroup || !nodeIds?.length) {
            dispatch(setSelectedGroup(''));
        }
        handleSelectGroup(nodeIds, hierarchyData?.data?.data?.data, type, isChecked, key);
    };

    /**
     * The function `handleFilterChange` updates the payload state with new filter, page, and size
     * values.
     * @param {any} filters - An object containing the filter criteria for the data. It can include
     * properties like "name", "category", "price", etc. The specific properties and their values
     * depend on the requirements of your application.
     * @param {any} page - The `page` parameter represents the current page number for pagination. It
     * is of type `any`, but it is expected to be a number.
     * @param {any} size - The "size" parameter represents the number of items to be displayed per page
     * in a paginated list or table.
     */
    const handleFilterChange = (filters: any, sortData: any): void => {
        if (JSON.stringify(filters) !== JSON.stringify(payload?.filters)) {
            const customKeys: any = {
                createdAt: payload?.filters?.createdAt || null,
                groupId: payload?.filters?.groupId || null,
            };

            const cleanedKeys = cleanPayload(customKeys);
            setPayload((prev: any) => ({ ...prev, page: 0, filters: { ...cleanedKeys, ...filters } }));
        }
        if (Object.keys(sortData)?.length) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                sort: sortData,
            }));
        }
    };

    const handlePaginationChange = (page: number, size: number): void => {
        if (page || size) setPayload((prev: any) => ({ ...prev, page: page, size: size }));
    };

    /**
     * The function `getStatusBackgroundColor` takes a key as input and returns a corresponding
     * background color based on the key value.
     * @param {string} key - The `key` parameter is a string that represents the status of something.
     * It can have one of the following values: 'Alarm', 'Offline', 'Warning', or 'Normal'.
     * @returns The function `getStatusBackgroundColor` returns a string representing the background
     * color based on the input key.
     */
    const getStatusBackgroundColor = (key: string): string => {
        switch (key) {
            case 'Alarm Active': {
                return '#CA3C3D';
            }
            case 'Alarm': {
                return '#69B1C3';
            }
            case 'Warning Active': {
                return '#F0AA1F';
            }

            default: {
                return '#39B620';
            }
        }
    };

    /* The `getSeverityCell` function is a helper function that returns a JSX element representing the
    severity of a status. It takes a `statusData` parameter, which is an object containing the
    status data. */
    const getSeverityCell = (data: any): JSX.Element => (
        <TimelineStatusIcon key={data?.timelineId} status={data?.status} />
    );

    /* The `getDateTimeCell` function is a helper function that returns a JSX element representing the
    date and time of a timeline event. */
    const getDateTimeCell = (data: any): JSX.Element => (
        <Stack direction={'column'}>
            <Typography variant="subtitle2" className="f-14">
                {convertDateTime({ timestamp: parseInt(data?.createdAt), customFormat: 'h:mm' }) || 'N/A'}
                <span style={{ marginLeft: '5px', fontWeight: 400 }}>
                    {convertDateTime({ timestamp: parseInt(data?.createdAt), customFormat: 'A' }) || ''}
                </span>
            </Typography>
            <Typography variant={'caption'} className="f-12">
                {convertDateTime({ timestamp: parseInt(data?.createdAt) }) || 'N/A'}
            </Typography>
        </Stack>
    );

    /**
     * The function `getDeviceCell` returns a JSX element that displays the device name and serial
     * number.
     * @param {any} data - The `data` parameter is of type `any`, which means it can accept any type of
     * data. It is used as the input to the `getDeviceCell` function.
     */
    const getDeviceCell = (data: any): JSX.Element => (
        <Stack direction={'column'}>
            <Typography variant="body2">{data?.deviceName || '-'}</Typography>
            <Typography variant={'caption'}>{data?.serialNumber ? `#${data?.serialNumber}` : '-'}</Typography>
        </Stack>
    );

    const handleDateChange = (data: any): void => {
        setDate(data);
    };

    const getDateHeaderOptions = (): JSX.Element => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar disableFuture value={date ?? null} onChange={handleDateChange} />
        </LocalizationProvider>
    );

    const getEventCell = (data: any): JSX.Element => (
        <Typography variant="subtitle2">{data?.events?.name || '-'}</Typography>
    );
    /* The below code is defining a function called `getActionCell` that takes in a parameter `data` of
    type `any`. */
    const getActionCell = (data: any): JSX.Element => {
        const getLabel = (): any => {
            if (data?.status === 'Alarm') {
                return <ListItemTag label={'OFFLINE'} fontColor="#DBEEF2" backgroundColor="#69B1C3" />;
            } else if (data?.status === 'Alarm Cleared') {
                return <ListItemTag label={'CLOSED'} fontColor="#424E54" backgroundColor="#D5D8DA" />;
            } else if (data?.status === 'Warning Cleared' || data?.status === 'Offline Cleared') {
                return <ListItemTag label={'CLEARED'} fontColor="#424E54" backgroundColor="#D5D8DA" />;
            }
        };

        return (
            <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'end'}>
                {getLabel()}
                <ChevronRight
                    onClick={(): void => {
                        navigate(`/timeline/${data?.timelineId}`, {
                            state: {
                                redirectPath: location.pathname,
                                ...(location.pathname.includes('device/details')
                                    ? {
                                          deviceDetailsTab: 'Timeline',
                                      }
                                    : { manageDevicesTab: 'Timeline' }),
                            },
                        });
                    }}
                    sx={{ cursor: 'pointer' }}
                    className={classes.hevronRight}
                />
            </Stack>
        );
    };

    /**
     * The function `getStatusStyles` returns an object with CSS styles for a status indicator based on
     * the provided data.
     * @param {any} data - The `data` parameter is an object that contains information about the
     * status.
     */
    const getStatusStyles = (data: any): any => {
        if (data?.status === 'Alarm' || data?.status?.includes('Active')) {
            return {
                content: '" "',
                position: 'absolute',
                top: '0px',
                bottom: '-1px',
                left: '0px',
                width: '6px',
                zIndex: '100',
                backgroundColor: getStatusBackgroundColor(data?.status),
            };
        }
    };

    const getDateSx = (): any => ({
        width: 'auto !important',
        maxHeight: '500px !important',
    });

    const getGroupHeaderOptions = (): JSX.Element => (
        <Hierarchy
            isMultiSelect
            expandedNodes={[currentOrg?.id]}
            selectedNodes={selectedGroups}
            onChangeTreeItem={(value: any, key?: string, type?: string, isChecked?: boolean): void =>
                handleGroupSelect(value, key, type, isChecked)
            }
            hierarchyData={hierarchyData?.data?.data?.data}
            multiSelectionKey="children"
        />
    );

    const getExtraHeaderOptions = (): JSX.Element => (
        <IconButton
            disabled={!timelineData?.data?.records?.length}
            onClick={async (): Promise<void> => {
                const newPayload = JSON.parse(JSON.stringify(payload));
                delete newPayload.page;
                newPayload.size = timelineData?.data?.total;
                if (deviceId) {
                    newPayload.filters['deviceId'] = [deviceId];
                }
                await downloadTimeline({ payload: newPayload, id: currentOrg?.id });
            }}
        >
            <FileDownload sx={{ color: '#727E84' }} />
        </IconButton>
    );

    const columns: any[] = [
        {
            header: 'Status',
            width: '20%',
            sx: getStatusStyles,
            accessor: 'status',
            isSortable: true,
            isFilterable: true,
            filterOptions: [{ id: 'all', label: 'All' }].concat(
                timlineStatusList?.data?.map((item: any) => ({ id: item, label: item }))
            ),
            cell: getSeverityCell,
            skeleton: (
                <Skeleton animation="wave" variant="circular">
                    <Avatar />
                </Skeleton>
            ),
        },
        {
            header: 'Date',
            width: '20%',
            accessor: 'createdAt',
            isFilterable: true,
            headerOptions: getDateHeaderOptions,
            isSortable: true,
            cell: getDateTimeCell,
            headerSx: getDateSx,
        },
        {
            header: 'Type',
            width: '15%',
            accessor: 'event',
            isFilterable: true,
            isDebounce: true,
            isSortable: true,
            typeVariant: 'subtitle2',
            cell: getEventCell,
        },
        {
            header: 'Device',
            isFilterable: true,
            isSortable: true,
            isDebounce: true,
            width: '15%',
            accessor: 'deviceName',
            cell: getDeviceCell,
        },
        {
            header: 'Group',
            width: '30%',
            accessor: 'groupPath',
            filterKey: 'groupId',
            sortKey: 'groupName',
            isFilterable: !deviceId,
            isSortable: true,
            headerOptions: getGroupHeaderOptions,
            cell: (data: any): void => data?.groupPath?.replaceAll('/', ' < ') || data?.groupName,
        },
        {
            cell: getActionCell,
            extraOptions: getExtraHeaderOptions,
        },
    ];

    const [tableFilters] = useFilteredValues({ allFilters: columns, triggeredFilters: payload?.filters });

    const {
        data: timelineData,
        isLoading,
        isFetching,
        isSuccess: timelineSuccess,
        isError: timelineError,
    }: any = useGetTimeLineQuery(
        { ...payload, filters: deviceId ? { ...tableFilters, deviceId: [deviceId] } : tableFilters },
        {
            refetchOnMountOrArgChange: true,
            skip: !isValid,
        }
    );

    const getFilterChipLabel = (key: string): string => {
        if (key === 'groupId') {
            return 'Group';
        }
        if (key === 'createdAt') {
            return 'Date';
        }
        return toFirstLetterUpperCase(key);
    };

    const handleChipDelete = (key: any): void => {
        if (key === 'createdAt') {
            setDate(null);
        } else if (key === 'groupId') {
            groupSelectionHandler([]);
        }
        tableRef?.current?.resetFilters(key === 'groupId' ? 'groupPath' : key, 'close');
        if (key === 'groupId') tableRef?.current?.dropDownHandler('groupName', 'close');
        const paginationPayloadClone = JSON.parse(JSON.stringify(payload));
        delete paginationPayloadClone?.filters[key];
        setPayload(paginationPayloadClone);
        dispatch(setSelectedGroup(''));
    };

    React.useEffect(() => {
        if (currentOrg?.id && !isMount) {
            tableRef?.current?.resetAllFiltersAndSort();
            setSearchKey(undefined);
            setPayload({ page: 0, size: 10, filters: {} });
        }
    }, [currentOrg]);

    return (
        <>
            <Stack direction={'row'} spacing={2} my={2} justifyContent={'space-between'} alignItems={'center'}>
                <TextField
                    hiddenLabel
                    id="searchKey"
                    onChange={(e): void => {
                        setSearchKey(e.target.value);
                    }}
                    value={searchKey || ''}
                    placeholder="Search..."
                    size="small"
                    InputProps={{
                        startAdornment: <Search id="search" sx={{ mr: '5px', color: '#727e84' }} />,
                        endAdornment: searchKey && (
                            <CancelOutlined
                                sx={{ color: '#727e84', cursor: 'pointer' }}
                                onClick={(): void => setSearchKey('')}
                            />
                        ),
                    }}
                />
                {payload?.filters && (
                    <Stack direction="row" spacing={1}>
                        {Object.keys(payload?.filters).map(
                            (key: string) =>
                                key !== 'deviceId' && (
                                    <Chip
                                        key={`unique${key}`}
                                        label={getFilterChipLabel(key)}
                                        onDelete={(): void => {
                                            handleChipDelete(key);
                                        }}
                                    />
                                )
                        )}
                    </Stack>
                )}
            </Stack>
            <CustomTable
                total={timelineData?.data?.total}
                isPagination
                controlledPageSize={payload?.page || 0}
                handleFilterChange={handleFilterChange}
                handlePageChange={handlePaginationChange}
                ref={tableRef}
                data={timelineData?.data?.records}
                isLoading={isLoading || isFetching || hierarchyData?.isLoading || (!timelineSuccess && !timelineError)}
                headers={columns}
                keyToTraverse="timelineId"
                noDataFoundIcon={<FormatListBulleted className="my-svg-icon" />}
                containerClass="table"
                wrapperClass="list-view"
                noDataFoundTitle="No Event Found"
                noDataFoundDescription="No Alarm Or Event Found"
            />
        </>
    );
};
