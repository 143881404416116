import { createSlice } from '@reduxjs/toolkit';
import { PageType } from '../../types/userManagement';

const initialState: PageType = {
    pageNum: 1,
    pageSize: 10,
    userListTotal: 0,
};
const userManagementSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
});
export default userManagementSlice;
