/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import {
    Box,
    Button,
    Card,
    Container,
    Grid,
    TextField,
    Typography,
    Stack,
    Divider,
    Select,
    MenuItem,
    FormControl,
    CardContent,
    InputLabel,
    Chip,
    CardActions,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import {
    useApproveUsersMutation,
    useCreateUserMutation,
    useEditUserMutation,
    useGetAllRolesQuery,
    useGetAllUsersQuery,
    useGetUserByIdQuery,
} from '@fiji/common/src/features/userManagement/userManagement';
import { useLocation } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import { AllRolesObj, AllUserObj, Group, RoleObj, UserProfile, UserType } from '@fiji/common/src/types';
import { useDispatch } from 'react-redux';
import { BackdropLoader } from '../../components/BackdropLoader';
import { RefetchConfigOptions } from '@reduxjs/toolkit/dist/query/core/apiState';
import Hierarchy from '../../components/Hierarchy';
import { handleWhiteSpaces } from '../../CommonUtils';
import { useCleanPayload, useGetHierarchyHandlers, useConfirm } from '../../hooks';
import { useGetAreaCodesQuery, useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { setToastifyContent } from '@fiji/common/src/features/common/commonSlice';
import ConfirmModal from '../../components/ConfirmModal';
import { useStyles } from './styles';
import { NUMBER_REGEX } from '@fiji/common/src/constants';
import { selectHierarchyData } from '@fiji/common/src/features/group/groupSlice';

const initialPayload = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    role: '',
    email: '',
    groupIds: [],
    country: '',
};

export const AddUserOrEdit = (): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const navigate = useNavigate();
    const { userId } = useParams();
    const location = useLocation();
    const [cleanPayload, removeDuplicateValues] = useCleanPayload();
    const currentOrg = useTypedSelector(selectedOrg);
    const dispatch = useDispatch();

    const [userPayload, setUserPayload] = useState<UserType>(initialPayload);
    const [areaCode, setAreaCode] = useState('');

    const { data: allUsers, refetch: getAllUsers } = useGetAllUsersQuery<{
        data: AllUserObj;
        refetch: RefetchConfigOptions;
    }>(
        {
            page: 0,
            size: 50,
            filters: {
                status: ['PENDING'],
            },
        },
        { skip: !location.pathname.includes('/user/approve') || !userId }
    );

    const { data: allRoles, refetch: refetchAllRoles } = useGetAllRolesQuery<{
        data: AllRolesObj;
        refetch: RefetchConfigOptions;
    }>();

    const {
        data: userQueryData,
        isLoading: userDetailsLoader,
        refetch: refetchUserById,
    } = useGetUserByIdQuery(userId, {
        skip: !userId,
    });

    const { data: userProfileData } = useGetUserProfileQuery<{ data: UserProfile }>();
    const { data: areaCodes } = useGetAreaCodesQuery<{ data: any }>();

    const hierarchyData = useTypedSelector(selectHierarchyData);

    const { selectedGroups, handleSelectGroup, getGroupLabel, groupSelectionHandler, removeUnwantedIds } =
        useGetHierarchyHandlers({
            isChildrenPreselected: true,
        });

    const [createUser, { isLoading: createUserLoader }] = useCreateUserMutation();
    const [editUser, { isLoading: editUserLoader }] = useEditUserMutation();
    const [approveUser, { isLoading: approveUserLoader }] = useApproveUsersMutation();

    /* The below code is a useEffect hook in a TypeScript React component. It is triggered whenever the
    dependencies `userQueryData` or `groupsHierarchy` change. */
    useEffect(() => {
        if (userQueryData?.data && hierarchyData?.data?.data?.data) {
            const dataClone = JSON.parse(JSON.stringify(userQueryData?.data));
            dataClone.role = userQueryData?.data?.roles.find((role: any) => role?.orgName === currentOrg?.name)?.roleId;
            setUserPayload(dataClone);
            handleSelectGroup(
                dataClone?.groups?.map((group: Group) => group?.id),
                hierarchyData?.data?.data?.data
            );
            setAreaCode(userQueryData?.data?.areaCode);
        }
    }, [userQueryData, hierarchyData?.data]);

    /* The below code is using the `useEffect` hook in a React component. It is setting up a side
    effect that will be triggered whenever the `userId` variable changes. */
    useEffect(() => {
        if (userId) {
            refetchAllRoles()
                .then(() => {})
                .catch(() => {});
        }
    }, [userId]);

    /* The below code is using the useEffect hook in a React component. It is setting up a side effect
    that will be triggered whenever the location or userId variables change. */
    useEffect(() => {
        if (location.pathname.includes('/user/approve')) {
            getAllUsers() as any;
        }
    }, [location, userId]);

    /**
     * The payloadHandler function handles changes in input fields, updating the user payload state
     * with the new values.
     * @param {any} e - The parameter `e` is an event object that is passed to the `payloadHandler`
     * function. It is typically an event object that is triggered by some user interaction, such as a
     * button click or input change.
     * @returns The function `payloadHandler` returns `void`, which means it does not return any value.
     */
    const payloadHandler = (e: any): void => {
        if (e.target.name === 'phoneNumber') {
            if (e.target.value.length && (e.target.value.length > 10 || !NUMBER_REGEX.test(e.target.value))) return;
        }
        if (
            e.target.name === 'role' &&
            allRoles?.data?.records?.find((role) => role.id === e.target.value)?.type === 'ADMIN' &&
            !selectedGroups.includes(hierarchyData?.data?.data?.data?.[0]?.id)
        ) {
            handleSelectGroup(
                hierarchyData?.data?.data?.data?.[0]?.id,
                hierarchyData?.data?.data?.data,
                'multiSelect',
                true,
                'children'
            );
        }
        setUserPayload((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    /**
     * The handleChangeAreaCode function updates the area code state based on the value of the input
     * field.
     * @param {any} evt - The parameter `evt` is an event object that is passed to the function when an
     * event occurs. It contains information about the event, such as the target element that triggered
     * the event and the value of the target element. In this case, `evt.target.value` refers to the
     * value of the target
     */
    const handleChangeAreaCode = (evt: any): void => {
        setAreaCode(evt.target.value);
    };

    /**
     * The function returns a success message for editing a user's profile or role.
     * @param {any} payload - The `payload` parameter is an object that contains information about the
     * user. It may have properties such as `role` and `groupIds`.
     * @returns a string message. The message depends on the properties of the payload object. If the
     * payload has a "role" property but does not have a "groupIds" property, the message will indicate
     * that the user's role has been changed. Otherwise, the message will indicate that the user's
     * profile has been updated.
     */
    const getEditUserSuccessMessage = (payload: any): string => {
        if (payload?.roleId && !payload?.groupIds) {
            const updatedRoleName = allRoles?.data?.records?.find((role) => role.id === userPayload?.role)?.name;
            return `${userPayload.firstName ?? 'User'}'s role has been changed to ${updatedRoleName || ''}.`;
        }
        return `${userPayload.firstName ?? 'User'}'s profile has been updated.`;
    };

    /**
     * The function `handleEditUser` is an asynchronous function that takes a payload as input, cleans
     * and updates the payload, and then calls the `editUser` function to update the user's
     * information. If the update is successful, it dispatches a success message and navigates to the
     * user's profile page.
     * @param {any} payload - The `payload` parameter is an object that contains the data to be edited
     * for a user. It may include properties such as `name`, `email`, `password`, etc.
     */
    const handleEditUser = async (payload: any): Promise<void> => {
        const filteredGroupIds = removeUnwantedIds(hierarchyData?.data?.data?.data, selectedGroups, []);
        const currentPayload = cleanPayload({
            ...payload,
            groupIds: filteredGroupIds?.groups,
        });
        const previousPayload = cleanPayload({
            ...userPayload,
            roleId: userQueryData?.data?.roles.find((role: any) => role?.orgName === currentOrg?.name)?.roleId,
            groupIds: userQueryData?.data?.groups?.map((group: Group) => group?.id),
        });
        const updatedPayload = removeDuplicateValues(currentPayload, previousPayload);
        const { error }: any = await editUser({
            id: userId,
            body: updatedPayload,
        });
        if (!error) {
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: getEditUserSuccessMessage(updatedPayload),
                    duration: 3000,
                })
            );
            if (userProfileData?.data?.id === userId && getRoleNameById(userPayload.role) === 'Viewer') {
                navigate(`/`);
            } else {
                await refetchUserById();
                navigate(`/profile/${userId || ''}`);
            }
        }
    };

    /**
     * The function `handleCreateUser` creates a new user with the provided payload and handles the
     * success or failure of the operation.
     * @param {any} payload - The `payload` parameter is an object that contains data for creating a
     * user. It likely includes properties such as `firstName`, `lastName`, `areaCode`, `phoneNumber`,
     * and `groupIds`.
     * @param {any} id - The `id` parameter is used to determine the source of the function call. It
     * can be either `'nextButton'` or any other value.
     */
    const handleCreateUser = async (payload: any, id: any): Promise<void> => {
        const filteredGroupIds = removeUnwantedIds(hierarchyData?.data?.data?.data, selectedGroups, []);
        const { error, data }: any = await createUser(
            cleanPayload({
                ...payload,
                groupIds: filteredGroupIds?.groups,
            })
        );

        if (!error) {
            if (id === 'nextButton') {
                navigate('/users');
            } else {
                setUserPayload(initialPayload);
                groupSelectionHandler([]);
            }
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: `${data?.data?.firstName ?? 'User'} added successfully`,
                    duration: 3000,
                })
            );
        }
    };

    /**
     * The function `handleApproveUser` is an asynchronous function that approves a user and displays a
     * toast message, and then navigates to a different page based on the value of the `id` parameter.
     * @param {any} payload - The payload parameter is an object that contains the roleId and groupIds
     * properties. These properties are used as arguments for the approveUser function.
     * @param {any} id - The `id` parameter is a variable that represents the ID of a user. It is used
     * to determine the behavior of the function based on the value of `id`.
     */
    const handleApproveUser = async (payload: any, id: any): Promise<void> => {
        const filteredGroupIds = removeUnwantedIds(hierarchyData?.data?.data?.data, selectedGroups, []);
        const { error }: any = await approveUser({
            roleId: payload.roleId,
            groupIds: filteredGroupIds?.groups,
            userId,
        });

        if (!error) {
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: `User Accounts have been approved.`,
                    duration: 3000,
                })
            );
            if (id === 'anotherNextButton') {
                const filteredUsersList = allUsers?.data?.records?.filter((user: any) => user.id !== userId);
                if (filteredUsersList?.length) navigate(`/user/approve/${filteredUsersList[0]?.id}`);
            } else {
                navigate('/users');
            }
        }
    };

    /**
     * The function `handleSaveUser` is an asynchronous function that handles saving user data by
     * checking if all required fields are filled and then either approving, editing, or creating a
     * user based on the current location and user ID.
     * @param {any} id - The `id` parameter is used to identify the user. It is likely a unique
     * identifier such as a user ID or a database record ID.
     */
    const handleSaveUser = async (id: any): Promise<void> => {
        const payload = {
            ...userPayload,
            roleId: userPayload.role,
            groupIds: selectedGroups,
            areaCode: areaCode,
            country: '',
        };
        delete payload?.role;

        if (payload.email === '' || !selectedGroups.length || payload.roleId === '') {
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: `Please fill all the * required fields in form`,
                    duration: 3000,
                })
            );
        } else if (location.pathname.includes('/user/approve')) {
            await handleApproveUser(payload, id);
        } else if (userId) {
            await handleEditUser(payload);
        } else {
            await handleCreateUser(payload, id);
        }
    };

    const {
        isVisible: isConfirmModalOpen,
        onClick: openConfirmModal,
        onCancel: closeConfirmModal,
        onConfirm: confirmSuccess,
    } = useConfirm(handleSaveUser as any);

    /**
     * The function `handleSubmitPayload` checks if the user's role matches the current organization's
     * role and opens a confirmation modal if not, otherwise it saves the user.
     * @param {any} e - The parameter `e` is an event object that is passed to the
     * `handleSubmitPayload` function. It is typically an event object that is triggered by a user
     * action, such as clicking a button or submitting a form.
     */
    const handleSubmitPayload = async (e: any): Promise<void> => {
        const id = e.target.id;
        if (
            userPayload.role !==
                userQueryData?.data?.roles.find((role: any) => role?.orgName === currentOrg?.name)?.roleId &&
            location.pathname === `/user/edit/${userId ?? ''}`
        ) {
            openConfirmModal(id);
        } else {
            await handleSaveUser(id);
        }
    };

    /**
     * The function `disableHandler` checks if certain conditions are met and returns `true` if they
     * are, otherwise it returns `false`.
     * @param {string} [type] - The `type` parameter is an optional string that determines the specific
     * condition to check in the `disableHandler` function. It is used to determine if the function
     * should return `true` or `false` based on certain conditions.
     * @returns a boolean value. It returns true if any of the following conditions are met:
     * - userPayload.email is an empty string
     * - selectedGroups is an empty array
     * - userPayload.role is an empty string
     * - any of the values in the handleWhiteSpaces array (userPayload.firstName, userPayload.lastName,
     * userPayload.email, userPayload.phoneNumber) have leading or trailing white spaces
     */
    const disableHandler = (type?: string): any => {
        if (userId) {
            const payload = {
                ...userPayload,
                roleId: userPayload.role,
                groupIds: selectedGroups,
                country: '',
                areaCode: areaCode,
            };
            delete payload?.role;
            const filteredCurrentGroupIds = removeUnwantedIds(hierarchyData?.data?.data?.data, selectedGroups, []);
            const currentPayload = cleanPayload({
                ...payload,
                groupIds: filteredCurrentGroupIds?.groups,
            });
            const filteredPreviousGroupIds = removeUnwantedIds(
                hierarchyData?.data?.data?.data,
                userQueryData?.data?.groups?.map((group: Group) => group?.id),
                []
            );
            const previousPayload = cleanPayload({
                ...userPayload,
                roleId: userQueryData?.data?.roles.find((role: any) => role?.orgName === currentOrg?.name)?.roleId,
                groupIds: filteredPreviousGroupIds?.groups,
            });
            const updatedPayload = removeDuplicateValues(currentPayload, previousPayload);
            if (Object.keys(updatedPayload)?.length === 0) {
                return true;
            }
        } else if (
            userPayload.email === '' ||
            !selectedGroups.length ||
            userPayload.role === '' ||
            !handleWhiteSpaces([
                userPayload.firstName,
                userPayload.lastName,
                userPayload.email,
                userPayload.phoneNumber,
            ])
        ) {
            return true;
        }
        if (type === 'anotherNext' && allUsers?.data?.total === 1) {
            return true;
        }
        return false;
    };

    /* The below code is defining a function called `getSelectedGroupLabel` that returns a JSX element. */
    const getSelectedGroupLabel = (): JSX.Element => {
        const allSelectedGroups = getGroupLabel(hierarchyData?.data?.data?.data, selectedGroups).flat(Infinity);
        const uniqueSelectedGroups = allSelectedGroups.filter(
            (group: any, index: any) => allSelectedGroups.findIndex((item: any) => item.value === group.value) === index
        );
        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, marginTop: '20px' }}>
                {uniqueSelectedGroups.map((group: any) => (
                    <Chip
                        key={`selected${group?.value}`}
                        disabled={
                            hierarchyData?.isLoading ||
                            userId === userProfileData?.data?.id ||
                            allRoles?.data?.records?.find((role) => role.id === userPayload?.role)?.type === 'ADMIN'
                        }
                        label={group?.label}
                        onDelete={(): void =>
                            handleSelectGroup(
                                group?.value,
                                hierarchyData?.data?.data?.data,
                                'multiSelect',
                                false,
                                'children'
                            )
                        }
                    />
                ))}
            </Box>
        );
    };

    /**
     * The function `getRoleNameById` takes a roleId as input and returns the name of the role
     * associated with that id, if it exists.
     * @param {string | undefined} roleId - The `roleId` parameter is a string or undefined. It
     * represents the ID of a role that we want to find the name for.
     * @returns The function `getRoleNameById` returns a string value if `requiredRole` is truthy,
     * otherwise it returns `undefined`.
     */
    const getRoleNameById = (roleId: string | undefined): string | undefined => {
        const requiredRole = allRoles?.data?.records?.find((role: any) => role.id === roleId);
        if (requiredRole) {
            return requiredRole.name;
        }
    };

    /**
     * The function `getCardTitle` returns a string based on the current location and user ID.
     * @returns The function `getCardTitle` returns a string. The specific string that is returned
     * depends on the conditions in the if-else statements. If the current location pathname includes
     * '/approveUser', it will return a string that includes the name of the current organization. If
     * there is a userId, it will return the string 'Edit User Information'. Otherwise, it will return
     * a string that includes the name of
     */
    const getCardTitle = (): string => {
        if (location.pathname.includes('/user/approve')) {
            return `Approve User to ${currentOrg?.name}`;
        } else if (userId) {
            return 'Edit User Information';
        }
        return `Invite User to ${currentOrg?.name}`;
    };

    /**
     * The function returns a specific label based on the current location pathname.
     * @returns The function `getAnotherNextButtonLabel` returns a string value. The specific string
     * that is returned depends on the value of `location.pathname`. If `location.pathname` includes
     * the string '/approveUser', then the function returns the string 'Approve and Move to Next
     * Request'. Otherwise, it returns the string 'Invite And Add Another User'.
     */
    const getAnotherNextButtonLabel = (): string => {
        if (location.pathname.includes('/user/approve')) {
            return 'Approve and Move to Next Request';
        }
        return 'Invite And Add Another User';
    };

    /**
     * The function `getNextButtonLabel` returns a string based on the current location pathname and
     * the existence of a userId.
     * @returns The function `getNextButtonLabel` returns a string value. The specific string that is
     * returned depends on the conditions inside the function. If the current URL path includes
     * '/approveUser', the function returns the string 'Approve User'. If the `userId` variable is
     * truthy, the function returns the string 'Submit Changes'. Otherwise, the function returns the
     * string 'Invite User'.
     */
    const getNextButtonLabel = (): string => {
        if (location.pathname.includes('/user/approve')) {
            return `Approve User`;
        } else if (userId) {
            return 'Submit Changes';
        }
        return `Invite User`;
    };

    return (
        <React.Fragment>
            <header>
                <title>AddUserOrEdit</title>
                <meta name="description" content="Description of AddUserOrEdit" />
            </header>
            <Container fixed className={classes.ContainerWrapper}>
                <Card>
                    <CardContent className={classes.cardBody}>
                        <Typography variant={'h6'} className={classes.sectionHeader}>
                            {getCardTitle()}
                        </Typography>
                        <Typography variant={'body2'} mb={3}>
                            {'Fields marked with an asterisk(*) are required.'}
                        </Typography>
                        <Grid container spacing={2} className={classes.gridContainer}>
                            <Grid item xs={6} className={classes.gridItem}>
                                <TextField
                                    value={userPayload?.firstName ?? ''}
                                    onChange={payloadHandler}
                                    name={'firstName'}
                                    label={'First Name'}
                                    disabled={Boolean(userId)}
                                    fullWidth
                                    variant={'filled'}
                                    inputProps={{ maxLength: 50 }}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.gridItem}>
                                <TextField
                                    value={userPayload?.lastName ?? ''}
                                    onChange={payloadHandler}
                                    name={'lastName'}
                                    disabled={Boolean(userId)}
                                    label={'Last Name'}
                                    fullWidth
                                    variant={'filled'}
                                    inputProps={{ maxLength: 50 }}
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            value={userPayload?.email}
                            className={classes.textField}
                            onChange={payloadHandler}
                            name={'email'}
                            disabled={Boolean(userId)}
                            label={'Email Address'}
                            required
                            fullWidth
                            variant={'filled'}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <FormControl variant={'filled'} fullWidth>
                                    <InputLabel htmlFor={'Area Code'}>Country Code</InputLabel>
                                    <Select
                                        data-cy={'country-selector'}
                                        fullWidth
                                        labelId={'Area Code'}
                                        inputProps={{
                                            name: 'Country Code',
                                        }}
                                        value={areaCode}
                                        onChange={handleChangeAreaCode}
                                        disabled={Boolean(userId)}
                                    >
                                        {areaCodes?.data?.map((code: any) => (
                                            <MenuItem key={code} value={code}>
                                                {code}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    value={userPayload?.phoneNumber}
                                    onChange={payloadHandler}
                                    name={'phoneNumber'}
                                    label={'Phone Number '}
                                    fullWidth
                                    variant={'filled'}
                                    disabled={Boolean(userId)}
                                    helperText={'A mobile number is required in order to receive SMS notifications.'}
                                    type="text"
                                />
                            </Grid>
                        </Grid>
                        <Divider className={clsx(classes.divider, classes.topDivider)} />
                        <FormControl variant={'filled'} className={classes.textField} required fullWidth>
                            <InputLabel id="demo-simple-select-autowidth-label">Select a Role</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                name="role"
                                onChange={payloadHandler}
                                value={userPayload?.role}
                            >
                                {allRoles?.data?.records.map((role: RoleObj) => (
                                    <MenuItem value={role.id} key={role?.id}>
                                        {role?.name}{' '}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant={'filled'} className={classes.selectFormControl} required fullWidth>
                            <InputLabel id="demo-customized-select-label">Assign to Group(s)</InputLabel>
                            <Select
                                displayEmpty
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    },
                                }}
                                disabled={
                                    hierarchyData?.isLoading ||
                                    userId === userProfileData?.data?.id ||
                                    allRoles?.data?.records?.find((role) => role.id === userPayload?.role)?.type ===
                                        'ADMIN'
                                }
                                value={
                                    getGroupLabel(hierarchyData?.data?.data?.data, selectedGroups).flat().length
                                        ? ' '
                                        : ''
                                }
                            >
                                <Hierarchy
                                    isMultiSelect
                                    selectedNodes={selectedGroups}
                                    customClass="parent-group-height"
                                    isOverflow={true}
                                    onChangeTreeItem={(
                                        value: any,
                                        key?: string,
                                        type?: string,
                                        isChecked?: boolean
                                    ): void =>
                                        handleSelectGroup(value, hierarchyData?.data?.data?.data, type, isChecked, key)
                                    }
                                    hierarchyData={hierarchyData?.data?.data?.data}
                                    multiSelectionKey="children"
                                />
                            </Select>
                        </FormControl>
                        {getSelectedGroupLabel()}
                    </CardContent>
                    <CardActions className={classes.cardfooter}>
                        <Grid container>
                            <Grid item xs={3}>
                                <Button
                                    variant={'outlined'}
                                    onClick={(): void => {
                                        if (location.pathname.includes('/user/approve')) {
                                            navigate('/users');
                                        } else {
                                            navigate(-1);
                                        }
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={9}>
                                <Stack
                                    spacing={2}
                                    direction={'row'}
                                    justifyContent={'flex-end'}
                                    className={classes.buttomGroup}
                                >
                                    {(!userId || location.pathname.includes('/user/approve')) && (
                                        <Button
                                            disabled={disableHandler('anotherNext')}
                                            id="anotherNextButton"
                                            variant={'outlined'}
                                            onClick={handleSubmitPayload}
                                        >
                                            {getAnotherNextButtonLabel()}
                                        </Button>
                                    )}

                                    <Button
                                        disabled={disableHandler('next')}
                                        id="nextButton"
                                        variant={'contained'}
                                        onClick={handleSubmitPayload}
                                    >
                                        {getNextButtonLabel()}
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </Container>
            {(userDetailsLoader || createUserLoader || editUserLoader || approveUserLoader) && (
                <BackdropLoader key="f3qf24vnpv" isOpen={true} />
            )}
            <ConfirmModal
                key={'siugu8383'}
                confirmButtonText={'Continue'}
                closeHandler={closeConfirmModal}
                confirmClick={confirmSuccess}
                isVisible={isConfirmModalOpen}
                wrapperClass={classes.muiDialogpaper}
                actionClass={classes.MuiDialogActionsRoot}
                titleClass={classes.MuiDialogTitleRoot}
                description={
                    <>
                        You have changed {userPayload?.firstName ?? 'User'}
                        &#39;s role to {getRoleNameById(userPayload.role)}. If you did not mean to do this - select
                        cancel. <br />
                        <br /> If this was intended, select Continue to save your changes.
                    </>
                }
                headingText={`Change ${
                    userPayload?.firstName || userPayload?.lastName
                        ? `${userPayload?.firstName} ${userPayload?.lastName}`
                        : 'User'
                }’s role to ${getRoleNameById(userPayload.role) ?? ''}?`}
            />
        </React.Fragment>
    );
};
