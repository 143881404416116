import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tags } from '../../types/deviceManagement';

const initialTags = <any>[];

const tagSlice = createSlice({
    name: 'tags',
    initialState: {
        tags: initialTags,
        manageTags: {},
    },
    reducers: {
        setTags(state, action: PayloadAction<Tags>) {
            const tags = action.payload;
            state.tags = tags;
        },
        setManageDevices: (state, { payload }: PayloadAction<object>) => {
            state.manageTags = payload;
        },
    },
});

export const { setTags } = tagSlice.actions;
export const selectCurrentTags = (state: any): any => state.tags;

export default tagSlice;
