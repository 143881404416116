import { useTypedSelector } from '@fiji/common/src/app/store';
import React from 'react';
import { Box } from '@mui/material';
import Hierarchy from '../../../components/Hierarchy';
import * as Colors from '@brightlayer-ui/colors';
import { Ups } from '@brightlayer-ui/icons-mui';
import { useGetHierarchyHandlers, useIsMount } from '../../../hooks';
import { SourceNodes } from './';
import { selectHierarchyData, selectMoveGroupsDevicesModal } from '@fiji/common/src/features/group/groupSlice';

export const MovingSourceContent = ({
    selectedSource,
    onNodeSelection,
    disabledGroupId,
    showDefaultGroup = false,
    parentId,
    isOpen,
}: {
    selectedSource: SourceNodes;
    onNodeSelection: (args: any) => void;
    disabledGroupId?: string;
    showDefaultGroup?: boolean;
    parentId?: string;
    isOpen: boolean;
}): JSX.Element => {
    const { selectedGroups, handleSelectGroup, groupSelectionHandler } = useGetHierarchyHandlers({
        isChildrenPreselected: true,
    });

    const hierarchyData = useTypedSelector(selectHierarchyData);

    const [expandedNodes, setExpandedNodes] = React.useState<string[]>([]);
    const [selectedNodes, setSelectedNodes] = React.useState<any>(selectedSource);
    const isMount = useIsMount();
    const moveModal = useTypedSelector(selectMoveGroupsDevicesModal);

    const handleSelectNodes = (nodeId: any, key?: string, type?: string, isChecked?: boolean): void => {
        if (key === 'devices') {
            const selectedNodesClone = JSON.parse(JSON.stringify(selectedNodes));
            const nodeIndex = selectedNodesClone.devices.indexOf(nodeId);
            if (nodeIndex === -1) {
                selectedNodesClone.devices.push(nodeId);
            } else {
                selectedNodesClone.devices.splice(nodeIndex, 1);
            }
            setSelectedNodes(selectedNodesClone);
        }
        handleSelectGroup(nodeId, hierarchyData?.data?.data?.data, type, isChecked, key);
    };

    const onExpandNodes = (nodeIds: string[]): void => {
        setExpandedNodes(nodeIds);
    };

    React.useEffect(() => {
        if ([...selectedNodes.devices, ...selectedNodes.groups].length !== selectedGroups.length && !isMount) {
            const filteredGroups = selectedGroups?.filter((id: string) => selectedNodes?.devices?.indexOf(id) === -1);
            setSelectedNodes((prev: any) => ({ ...prev, groups: filteredGroups }));
        }
    }, [selectedGroups]);

    React.useEffect(() => {
        if (JSON.stringify(selectedNodes) !== JSON.stringify(selectedSource)) {
            onNodeSelection(selectedNodes);
        }
    }, [selectedNodes]);

    React.useEffect(() => {
        if ([...selectedSource.devices, ...selectedSource.groups].length !== selectedGroups.length && isOpen) {
            groupSelectionHandler([...selectedSource.devices, ...selectedSource.groups]);
        }
    }, [selectedSource]);

    React.useEffect(() => {
        if (moveModal?.groupId) {
            handleSelectNodes(moveModal?.groupId, 'children', 'multiSelect', true);
        }
    }, [moveModal?.groupId]);

    React.useEffect(() => {
        if (moveModal?.deviceId?.length && Array.isArray(moveModal?.deviceId)) {
            const selectedNodesClone = JSON.parse(JSON.stringify(selectedNodes));
            moveModal?.deviceId?.map((deviceId) => {
                selectedNodesClone?.devices?.push(deviceId);
                handleSelectGroup(deviceId, hierarchyData?.data?.data?.data, 'multiSelect', true, 'devices');
            });
            setSelectedNodes(selectedNodesClone);
        } else if (typeof moveModal?.deviceId === 'string' && moveModal?.deviceId) {
            handleSelectNodes(moveModal?.deviceId, 'devices', 'multiSelect', true);
        }
    }, [moveModal?.deviceId]);

    return (
        <>
            <Hierarchy
                hierarchyData={hierarchyData?.data?.data?.data}
                onChangeTreeItem={handleSelectNodes}
                selectedNodes={selectedGroups}
                expandedNodes={expandedNodes}
                filter={[
                    !showDefaultGroup && { key: 'defaultGroup', value: false, operator: '===', iterator: 'children' },
                    disabledGroupId && {
                        key: 'id',
                        value: disabledGroupId,
                        operator: '!==',
                        iterator: 'children',
                        action: 'disable',
                    },
                    parentId && {
                        key: 'id',
                        value: parentId,
                        operator: '!==',
                        iterator: 'children',
                        action: 'disableCheck',
                    },
                ]}
                isLoading={hierarchyData?.isLoading}
                customIterator={['devices', 'children']}
                iconKey={['devices']}
                isMultiSelect
                multiSelectionKey={['devices', 'children']}
                renderIcon={(): JSX.Element => <Box component={Ups} sx={{ color: Colors.gray[500], ml: 1, mr: 1 }} />}
                onExpandNodes={onExpandNodes}
            />
        </>
    );
};
