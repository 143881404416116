import React from 'react';
import { Group } from '@fiji/common/src/types';
import { TreeItems } from '../router/drawer';

/**
 * The `HierarchyProps` type is used to define the properties of a hierarchy component, including the
 * value type, initial value, and whether children are preselected.
 * @property {string} valueType - The `valueType` property is an optional string that specifies the
 * type of value that the component will handle.
 * @property {string} initialValue - The `initialValue` property is an optional string that represents
 * the initial value for the component.
 * @property {boolean} isChildrenPreselected - This property is a boolean that indicates whether the
 * children of the component should be preselected or not.
 */
type HierarchyProps = {
    valueType?: string;
    initialValue?: string;
    isChildrenPreselected?: boolean;
};

export const useGetHierarchyHandlers = ({ valueType, initialValue }: HierarchyProps): any => {
    const [selectedGroups, setSelectedGroups] = React.useState<string | any[]>([]);

    /* The `React.useEffect` hook is used to perform side effects in a functional component. In this
    case, it is used to set the initial value of the `selectedGroups` state variable when the
    `initialValue` prop changes. */
    React.useEffect(() => {
        if (initialValue) {
            setSelectedGroups(initialValue);
        }
    }, [initialValue]);

    /**
     * The function `getAllChildrenIds` recursively retrieves the ids of all children in a given group
     * object.
     * @param {Group} source - The `source` parameter is of type `Group`.
     * @returns The function `getAllChildrenIds` returns an array that contains the `id` of the
     * `source` group and the `id` of all its children.
     */
    const getAllChildrenIds = (source: Group): any => {
        let result = [];
        if (source?.children) {
            result = source?.children?.map((item: any) => getAllChildrenIds(item));
        }

        return [source?.id, ...result];
    };

    /**
     * The function `findGroupInSource` recursively searches for a group with a specific ID in a nested
     * array of groups and returns an array of all the IDs of its children.
     * @param {string} selectedParentId - The `selectedParentId` parameter is a string that represents
     * the ID of the parent group that we want to find in the source array.
     * @param {Group[]} source - The `source` parameter is an array of `Group` objects.
     * @returns The function `findGroupInSource` returns an array of any type.
     */
    const findGroupInSource = (selectedParentId: string, source: Group[]): any[] => {
        const resultIndex = source?.findIndex((item: Group) => item.id === selectedParentId);

        if (resultIndex !== -1) {
            return getAllChildrenIds(source?.[resultIndex])?.flat(Infinity);
        }

        return source.map((item2) => findGroupInSource(selectedParentId, item2?.children)).flat(Infinity);
    };

    /**
     * The function `getParentIdsBottomToUp` recursively searches a hierarchy of items to find the
     * parent IDs of a given child ID, returning them in bottom-to-up order.
     * @param {any} childId - The `childId` parameter is the ID of the child element for which you want
     * to find the parent IDs.
     * @param {any} hierarchy - The `hierarchy` parameter is an array that represents a hierarchical
     * structure. Each item in the array represents a node in the hierarchy and has the following
     * properties:
     * @param {any} parentIds - parentIds is an optional parameter that represents an array of parent
     * IDs. It is used to keep track of the parent IDs as we traverse the hierarchy from bottom to top.
     * @returns The function `getParentIdsBottomToUp` returns an array of parent IDs in bottom-to-up
     * order if the child ID is found in the hierarchy. If the child ID is not found, it returns
     * `null`.
     */
    const getParentIdsBottomToUp = (childId: any, hierarchy: any, parentIds: any = []): any => {
        for (const item of hierarchy) {
            if (item.id === childId) {
                return [...parentIds, item.id];
            } else if (item.children && item.children.length > 0) {
                const foundParentIds = getParentIdsBottomToUp(childId, item.children, [...parentIds, item.id]);
                if (foundParentIds) {
                    return foundParentIds;
                }
            }
        }
        return null; // Child ID not found
    };

    const handleAllGroupsToBeRemoved = (nodeIds: any, groupHierarchy: any, iterator?: string): void => {
        const allGroupsToBeRemoved =
            iterator === 'children' ? findGroupInSource(nodeIds, groupHierarchy).flat(Infinity) : [nodeIds];
        const allParentIds = getParentIdsBottomToUp(nodeIds, groupHierarchy);
        const uniqueGroupsToBeRemoved = allGroupsToBeRemoved.filter((item, i, ar) => ar.indexOf(item) === i);
        setSelectedGroups((prev) =>
            Array.isArray(prev)
                ? prev?.filter((group) => !uniqueGroupsToBeRemoved?.includes(group) && !allParentIds?.includes(group))
                : []
        );
    };

    /**
     * The function `handleSelectGroup` is used to handle the selection of groups based on different
     * parameters and update the selected groups accordingly.
     * @param {any} nodeIds - An array of node IDs. These are the IDs of the nodes that are being
     * selected or deselected.
     * @param {any} groupHierarchy - The `groupHierarchy` parameter is an object that represents the
     * hierarchy of groups. It contains information about the parent-child relationships between
     * groups.
     * @param {string} [type] - The `type` parameter is an optional string that specifies the type of
     * selection. It can have two possible values: "multiSelect" or undefined.
     * @param {boolean} [isChecked] - The `isChecked` parameter is a boolean value that indicates
     * whether a checkbox or multi-select option is checked or not. It is used to determine the action
     * to be taken based on the user's selection.
     */
    const handleSelectGroup = (
        nodeIds: any,
        groupHierarchy: any,
        type?: string,
        isChecked?: boolean,
        iterator?: string
    ): void => {
        if (type === 'multiSelect') {
            if (isChecked === true) {
                const allAccessibleGroups =
                    iterator === 'children' ? findGroupInSource(nodeIds, groupHierarchy).flat(Infinity) : [nodeIds];
                const uniqueAccessibleGroups = allAccessibleGroups.filter((item, i, ar) => ar.indexOf(item) === i);
                setSelectedGroups((prev) =>
                    Array.isArray(prev) ? [...prev, ...uniqueAccessibleGroups] : [prev, ...uniqueAccessibleGroups]
                );
            } else {
                handleAllGroupsToBeRemoved(nodeIds, groupHierarchy, iterator);
            }
        } else {
            const allAccessibleGroups = nodeIds
                ?.map((nodeId: string) => findGroupInSource(nodeId, groupHierarchy))
                .flat(Infinity);
            const uniqueAccessibleGroups = allAccessibleGroups.filter(
                (item: any, i: any, ar: any) => ar.indexOf(item) === i
            );
            setSelectedGroups(uniqueAccessibleGroups);
        }
    };

    /**
     * The function `getGroupLabel` retrieves the label of a group based on its ID from a nested source
     * object.
     * @param {any} source - The `source` parameter is an array of objects. Each object represents a
     * group and has properties like `id` and `name`. It can be nested, meaning each group object can
     * have a `children` property which is also an array of group objects.
     * @param {any} groupIds - The `groupIds` parameter is an array of group IDs.
     * @returns The function `getGroupLabel` returns the value of the `result` variable.
     */
    const getGroupLabel = (source: any, groupIds: any): any => {
        let result: any;
        if (valueType !== 'string') {
            result = [];
        }
        source?.forEach((item: any) => {
            if (Array.isArray(groupIds) && groupIds?.length) {
                groupIds?.forEach((groupId: string) => {
                    groupLabelHandler(groupId, item, result, source);
                });
            } else if (item.id === groupIds) {
                result = item.name;
            } else if (item.children.length) {
                const label = getGroupLabel(item.children, groupIds);
                if (label?.length) {
                    result = label;
                }
            }
        });
        return result;
    };

    /**
     * The function `groupLabelHandler` is used to generate a label for a given group ID based on a
     * source array of groups.
     * @param {any} groupId - The `groupId` parameter represents the ID of a group.
     * @param {any} item - The `item` parameter represents an object that contains information about a
     * group. It may have properties such as `id`, `name`, and `children`.
     * @param {any} result - The `result` parameter is an array that stores the labels and values of
     * the groups.
     * @param {any[]} source - The `source` parameter is an array that represents the source of the
     * groups. It contains objects with properties such as `id`, `name`, and `defaultGroup`.
     */
    const groupLabelHandler = (groupId: any, item: any, result: any, source: any[]): void => {
        if (groupId === source?.[0]?.id && source?.[0]?.defaultGroup === true) {
            result.push({
                label: `${source?.[0]?.name} (Organization Root)`,
                value: groupId,
            });
        } else if (item?.id === groupId) {
            result.push({ label: item?.name, value: groupId });
        } else if (item?.children?.length) {
            const label = getGroupLabel(item.children, groupId);
            if (label?.length) {
                result.push({ label, value: groupId });
            }
        }
    };

    /**
     * The function `getSelectedGroupLabel` returns the label of the selected group in a group
     * hierarchy, or an empty string if no group is selected.
     * @param {any} groupHierarchy - The `groupHierarchy` parameter is an array that represents the
     * hierarchy of groups. Each element in the array is an object that contains information about a
     * group, such as its `id`, `name`, and `defaultGroup` status.
     * @returns The function `getSelectedGroupLabel` returns either a string or an empty string.
     */
    const getSelectedGroupLabel = (groupHierarchy: any): any => {
        if (selectedGroups === groupHierarchy?.[0]?.id && groupHierarchy?.[0]?.defaultGroup === true) {
            return `${groupHierarchy?.[0]?.name} (Organization Root)`;
        }
        return getGroupLabel(groupHierarchy, selectedGroups) ?? '';
    };

    /**
     * The function `groupSelectionHandler` sets the selected groups based on the provided values.
     * @param {any} selectedValues - The parameter `selectedValues` is of type `any`, which means it
     * can accept any data type. It is used to store the selected values from a group selection.
     */
    const groupSelectionHandler = (selectedValues: any): void => {
        setSelectedGroups(selectedValues);
    };

    /**
     * The function `getTreeNodes` takes a group hierarchy and returns an array of all the node IDs in
     * the hierarchy.
     * @param {any} groupHierarchy - The `groupHierarchy` parameter is an array of objects representing
     * a hierarchical tree structure. Each object in the array represents a node in the tree and has
     * the following properties:
     * @returns The function `getTreeNodes` returns an array of strings.
     */
    const getTreeNodes = (groupHierarchy: any): string[] => {
        const treeNodes: string[] = [];

        const createTreeNodes = (treeItems: TreeItems[]): void => {
            treeItems?.map((item: TreeItems) => {
                treeNodes?.push(item.id);
                if (item.children) createTreeNodes(item.children);
            });
        };
        createTreeNodes(groupHierarchy);

        return treeNodes;
    };

    const handleFindDevice = (group: any, id: string, returnGroupOnDeviceFound?: boolean): any => {
        if (group.devices && group.devices.length > 0) {
            const foundDevice = group.devices.find((device: any) => device.id === id);
            if (foundDevice) {
                if (returnGroupOnDeviceFound) {
                    return group;
                }
                return foundDevice;
            }
        }
        return null;
    };

    const findGroupOrDeviceById = (groupHierarchy: any, id: string, returnGroupOnDeviceFound?: boolean): any => {
        for (let i = 0; i < groupHierarchy?.length; i++) {
            const group = groupHierarchy[i];

            if (group.id === id) {
                return group;
            }
            const foundDevice = handleFindDevice(group, id, returnGroupOnDeviceFound);

            if (foundDevice) {
                return foundDevice;
            }
            if (group.children && group.children.length > 0) {
                const foundGroup = group.children.find((child: any) => child.id === id);
                if (foundGroup) {
                    return foundGroup;
                }
                const result = findGroupOrDeviceById(group.children, id, returnGroupOnDeviceFound);
                if (result) {
                    return result;
                }
            }
        }

        return null; // Item not found
    };

    const iterateGroups = (treeNodes: any, selectedGroupIds: any, filteredNodeIds: any): any[] => {
        treeNodes?.forEach((treeItem: any) => {
            if (treeItem.children?.length > 0) {
                iterateGroups(treeItem.children, selectedGroupIds, filteredNodeIds);
            }
            const isAllChildrenPresent =
                treeItem?.children?.length &&
                treeItem?.children?.every((item: any) => selectedGroupIds?.includes(item.id));
            if (
                isAllChildrenPresent &&
                selectedGroupIds.includes(treeItem.id) &&
                !selectedGroupIds.includes(treeItem.parentId)
            ) {
                filteredNodeIds.add(treeItem.id);
            } else if (!selectedGroupIds?.includes(treeItem.parentId)) {
                const allPresentedChildIds = treeItem?.children?.filter((item: any) =>
                    selectedGroupIds?.includes(item.id)
                );
                if (allPresentedChildIds?.length) {
                    allPresentedChildIds.forEach((child: any) => filteredNodeIds.add(child.id));
                }
            }
        });
        return [...filteredNodeIds];
    };

    const removeUnwantedIds = (hierarchyData: any, selectedGroupIds: any, selectedDeviceIds: any): any => {
        let allFilteredGroupIds = [];
        if (selectedGroupIds?.length === 1) {
            allFilteredGroupIds = selectedGroupIds;
        } else {
            allFilteredGroupIds = iterateGroups(hierarchyData, selectedGroupIds, new Set())
                .flat(Infinity)
                .filter((item: any, i: any, ar: any) => ar.indexOf(item) === i);
        }

        const allFilteredDeviceIds = selectedDeviceIds?.filter(
            (deviceId: string) => !selectedGroupIds?.includes(findGroupOrDeviceById(hierarchyData, deviceId, true)?.id)
        );
        return { groups: allFilteredGroupIds, devices: allFilteredDeviceIds };
    };

    return {
        selectedGroups,
        getTreeNodes,
        handleSelectGroup,
        getGroupLabel,
        groupSelectionHandler,
        getSelectedGroupLabel,
        getAllChildrenIds,
        findGroupOrDeviceById,
        removeUnwantedIds,
        findGroupInSource,
    };
};
