import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type ToastState = {
    isOpen: boolean;
    duration?: number;
    message?: any;
    isUnauthorized?: boolean;
    extraArgs?: any;
};

const initialCollapsibleHeaderDetails: any = {};

const commonSlice = createSlice({
    name: 'common',
    initialState: {
        toast: {
            isOpen: false,
            duration: 0,
            message: '',
        },
        isLoader: false,
        collapsibleHeaderDetails: initialCollapsibleHeaderDetails,
        authModal: false,
        groupId: '',
        pageHeader: {},
        deviceId: '',
        appHeaderTitle: undefined,
        lastInteractionTime: 0,
        isSignOut: false,
        notFound: false,
    },
    reducers: {
        setToastifyContent: (state: { toast: ToastState }, action: PayloadAction<ToastState>) => {
            state.toast = action.payload;
        },
        toggleLoader: (state, action: PayloadAction<boolean>) => {
            state.isLoader = action.payload;
        },
        setCollapsibleHeaderDetails: (state, action: PayloadAction<any>) => {
            state.collapsibleHeaderDetails = action.payload;
        },
        setSelectedGroup: (state, action: PayloadAction<any>) => {
            state.groupId = action.payload;
        },
        setSelectedDevice: (state, action: PayloadAction<any>) => {
            state.deviceId = action.payload;
        },
        setPageHeaderData: (state, action: PayloadAction<any>) => {
            state.pageHeader = action.payload;
        },
        setAppHeaderTitle: (state, action: PayloadAction<any>) => {
            state.appHeaderTitle = action.payload;
        },
        setAuthModal: (state, action: PayloadAction<any>) => {
            state.authModal = action.payload;
        },
        setLastInteractionTime: (state, action: PayloadAction<any>) => {
            state.lastInteractionTime = action.payload;
        },
        setIsSignOut: (state, action: PayloadAction<any>) => {
            state.isSignOut = action.payload;
        },
        setNotFound: (state, action: PayloadAction<any>) => {
            state.notFound = action.payload;
        },
    },
    extraReducers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'organizationMob/setSelectedOrg': (state) => {
            state.appHeaderTitle = undefined;
            state.groupId = '';
        },
    },
});

export const {
    setCollapsibleHeaderDetails,
    setSelectedGroup,
    setSelectedDevice,
    setToastifyContent,
    toggleLoader,
    setPageHeaderData,
    setAppHeaderTitle,
    setAuthModal,
    setLastInteractionTime,
    setIsSignOut,
    setNotFound,
} = commonSlice.actions;

export default commonSlice;
