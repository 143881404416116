/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import {
    AppBar,
    Button,
    Typography,
    Switch,
    Container,
    ListItem,
    ListItemText,
    FormControl,
    Accordion,
    AccordionSummary,
    TextField,
    AccordionDetails,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useStyles } from './styles';
import { EmptyState, Spacer } from '@brightlayer-ui/react-components';
import {
    useGetDeviceSettingsQuery,
    useUpdateDeviceSettingsMutation,
} from '@fiji/common/src/features/deviceSettings/deviceSettingApi';
import { useNavigate, useParams } from 'react-router-dom';
import { NotificationSkeleton } from '../NotificationPreferences';
import {
    setAppHeaderTitle,
    setSelectedDevice,
    setSelectedGroup,
    setToastifyContent,
} from '@fiji/common/src/features/common/commonSlice';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { Settings } from '@mui/icons-material';
import { NUMBER_REGEX } from '@fiji/common/src/constants';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useRBAC } from '../../hooks';
import Loader from '../../components/Loader';

const SettingsModal = (props: any): JSX.Element => (
    <Dialog
        open={props?.isSaveModal || props?.isDiscardModal}
        onClose={props?.closeModalHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={props?.classes.muiDialogpaper}
    >
        <DialogTitle id="alert-dialog-title">
            <Typography variant={'h6'}> {props?.isDiscardModal ? 'Discard Changes?' : 'Save Changes?'}</Typography>
        </DialogTitle>
        <DialogContent>
            {props.isLoading && (
                <Typography variant={'body1'} mb={7}>
                    <Loader size={50} />
                </Typography>
            )}
            {!props.isLoading && props?.isDiscardModal && (
                <Typography variant={'body1'} mb={1}>
                    {'You will lose all changes made.'}
                </Typography>
            )}
        </DialogContent>
        <DialogActions className={props?.classes.MuiDialogActionsRoot}>
            <Grid container>
                <Grid item xs={4}>
                    {props?.isSaveModal && (
                        <Button variant={'outlined'} onClick={props?.closeModalHandler}>
                            Cancel
                        </Button>
                    )}
                </Grid>
                <Grid item xs={8} textAlign={'end'}>
                    {!props?.isSaveModal && (
                        <Button variant={'outlined'} onClick={props?.closeModalHandler} sx={{ mr: 2 }}>
                            Cancel
                        </Button>
                    )}
                    <Button
                        sx={{ border: '1px solid #ca3c3d' }}
                        variant={!props?.isSaveModal ? 'contained' : 'outlined'}
                        color="error"
                        className={props?.isDiscardModal ? props?.classes?.discardButton : ''}
                        onClick={props?.discardModalHandler}
                    >
                        Discard
                    </Button>
                    {props?.isSaveModal && (
                        <Button variant="contained" sx={{ ml: 2 }} onClick={props?.submitHandler}>
                            Save
                        </Button>
                    )}
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog>
);

export const SettingsTab = React.forwardRef((props: any, ref): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { deviceId } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const rolePermissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(rolePermissions);
    const canViewSettings = hasPermission('view-device-settings');
    const canUpdateSettings = hasPermission('update-device-settings');

    const [isDiscardModal, setIsDiscardModal] = React.useState(false);
    const [isSaveModal, setIsSaveModal] = React.useState(false);
    const [currentTab, setCurrentTab] = React.useState('Settings');
    const {
        currentData: deviceSettings,
        isLoading,
        isFetching,
        isSuccess,
    } = useGetDeviceSettingsQuery<{ currentData: any; isLoading: boolean; isFetching: boolean; isSuccess: boolean }>(
        deviceId ?? '',
        { skip: !canViewSettings }
    );

    const [updateSettings, { isLoading: isSettingUpdating }] = useUpdateDeviceSettingsMutation();

    const [payload, setPayload] = React.useState<{
        isAcFailAndRestore: boolean;
        isAudibleAlarm: boolean;
        powerDelay: number;
    }>();

    React.useEffect(() => {
        if (isSuccess && Object.keys(deviceSettings?.data ?? {}).length) {
            setPayload((prev: any) => ({
                ...prev,
                isAcFailAndRestore: deviceSettings?.data?.isAcFailAndRestore,
                isAudibleAlarm: deviceSettings?.data?.isAudibleAlarm,
                powerDelay: deviceSettings?.data?.powerDelay,
            }));
        }
    }, [deviceSettings]);

    const payloadHandler = (e: any): void => {
        if (e?.target?.id === 'powerDelay') {
            if (e.target.value.length && (e.target.value > 255 || !NUMBER_REGEX.test(e.target.value))) {
                return;
            }
            setPayload((prev: any) => ({ ...prev, powerDelay: +e.target.value }));
            return;
        }
        setPayload((prev: any) => ({ ...prev, [e.target.id]: !prev[e.target.id] }));
    };

    const openSaveModalDeviceChange = (): void => {
        if (props?.groupDeviceDetails?.key === 'children') {
            dispatch(setAppHeaderTitle(props?.groupDeviceDetails?.title));
            dispatch(setSelectedGroup(props?.groupDeviceDetails?.nodeIds));
            if (window.location.pathname.includes('details')) {
                navigate('/');
            }
            dispatch(setSelectedDevice(''));
        } else if (props?.groupDeviceDetails?.key === 'devices') {
            dispatch(setSelectedDevice(props?.groupDeviceDetails?.nodeIds));
        }
    };

    React.useImperativeHandle(ref, () => ({
        handleOpenSaveModal: (tab: string): void => {
            setCurrentTab(tab);
            setIsSaveModal(true);
        },
    }));

    const submitHandler = async (): Promise<void> => {
        const { error }: any = await updateSettings({ deviceId: deviceId, payload: payload });
        if (!error) {
            if (props?.isDeviceGroupChanged) openSaveModalDeviceChange();
            dispatch(setToastifyContent({ isOpen: true, message: 'Changes Saved Successfully ' }));
            props?.setActiveTab(currentTab);
            setIsSaveModal(false);
            setIsDiscardModal(false);
        }
    };

    React.useEffect(() => () => props?.setIsSaveDiscardDisabled(true), []);

    const isSaveDiscardDisabled = (): boolean => {
        if (
            (deviceSettings?.data?.powerDelay === payload?.powerDelay &&
                deviceSettings?.data?.isAcFailAndRestore === payload?.isAcFailAndRestore &&
                deviceSettings?.data?.isAudibleAlarm === payload?.isAudibleAlarm) ||
            isSettingUpdating ||
            isFetching
        ) {
            props?.setIsSaveDiscardDisabled(true);
            return true;
        }
        props?.setIsSaveDiscardDisabled(false);
        return false;
    };

    const closeModalHandler = (): void => {
        setIsSaveModal(false);
        setIsDiscardModal(false);
        setCurrentTab('Settings');
    };

    const discardModalHandler = (): void => {
        setPayload({
            powerDelay: deviceSettings?.data?.powerDelay,
            isAcFailAndRestore: deviceSettings?.data?.isAcFailAndRestore,
            isAudibleAlarm: deviceSettings?.data?.isAudibleAlarm,
        });
        if (props?.isDeviceGroupChanged) openSaveModalDeviceChange();
        closeModalHandler();
        props?.setActiveTab(currentTab);
    };
    return (
        <>
            {deviceSettings && Boolean(Object.keys(deviceSettings?.data ?? {})?.length) && (
                <AppBar className={classes.contentHeader} position="static" color="primary" sx={{ width: '100%' }}>
                    <Spacer />
                    <Button
                        variant="outlined"
                        sx={{ mr: 2 }}
                        disabled={isSaveDiscardDisabled() || !canUpdateSettings}
                        onClick={(): void => setIsDiscardModal(true)}
                    >
                        Discard Changes
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ mr: 2 }}
                        disabled={isSaveDiscardDisabled() || !canUpdateSettings}
                        onClick={submitHandler}
                    >
                        Save Changes
                    </Button>
                </AppBar>
            )}
            <Container fixed>
                <Paper
                    className={classes.MyPaperStyle}
                    sx={{ background: 'transparent', backgroundColor: 'transparent', boxShadow: 'none' }}
                >
                    {(isLoading || isFetching || isSettingUpdating) && (
                        <NotificationSkeleton key="skeleton12de41" count={1} />
                    )}
                    {!(isLoading || isFetching || isSettingUpdating) && (
                        <Accordion defaultExpanded={true}>
                            {!Object.keys(deviceSettings?.data ?? {})?.length ? (
                                <EmptyState
                                    icon={<Settings fontSize={'inherit'} className="my-svg-icon" />}
                                    title={'No Settings'}
                                />
                            ) : (
                                <>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        sx={{ borderBottom: '1px solid #f2f2f2' }}
                                    >
                                        <Typography variant="h5" className="custom-fs-16">
                                            General Settings
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: '0' }}>
                                        <ListItem
                                            sx={{ borderBottom: '1px solid rgba(66, 78, 84, 0.12)', padding: '16px' }}
                                        >
                                            <ListItemText primary="Audible Alarm" />
                                            <Switch
                                                id="isAudibleAlarm"
                                                edge="end"
                                                disabled={!canUpdateSettings}
                                                onChange={payloadHandler}
                                                checked={!!payload?.isAudibleAlarm}
                                                inputProps={{
                                                    'aria-labelledby': 'switch-list-label-Audible Alarm',
                                                }}
                                            />
                                        </ListItem>

                                        <ListItem
                                            sx={{ borderBottom: '1px solid rgba(66, 78, 84, 0.12)', padding: '0 16px' }}
                                        >
                                            <ListItemText primary="Power on Delay" />
                                            <FormControl variant="filled" sx={{ m: 1, minWidth: 300 }}>
                                                <TextField
                                                    id="powerDelay"
                                                    variant="filled"
                                                    className="w-100"
                                                    disabled={!canUpdateSettings}
                                                    value={payload?.powerDelay ?? ''}
                                                    onChange={payloadHandler}
                                                />
                                            </FormControl>
                                        </ListItem>
                                        <ListItem
                                            sx={{ borderBottom: '1px solid rgba(66, 78, 84, 0.12)', padding: '16px' }}
                                        >
                                            <ListItemText primary="AC Fail & Restore Auto-Reboot" />
                                            <Switch
                                                id="isAcFailAndRestore"
                                                edge="end"
                                                disabled={!canUpdateSettings}
                                                onChange={payloadHandler}
                                                checked={!!payload?.isAcFailAndRestore}
                                                inputProps={{
                                                    'aria-labelledby': 'switch-list-label-Audible Alarm',
                                                }}
                                            />
                                        </ListItem>
                                    </AccordionDetails>
                                </>
                            )}
                        </Accordion>
                    )}
                </Paper>
            </Container>
            <SettingsModal
                closeModalHandler={closeModalHandler}
                isSaveModal={isSaveModal}
                isDiscardModal={isDiscardModal}
                discardModalHandler={discardModalHandler}
                submitHandler={submitHandler}
                classes={classes}
                isLoading={isLoading || isFetching || isSettingUpdating}
            />
        </>
    );
});
