import { useAppDispatch } from '@fiji/common/src/app/store';
import { setToastifyContent } from '@fiji/common/src/features/common/commonSlice';

/**
 * The `useImageValidated` function is a custom hook in TypeScript that returns a validator function
 * for checking if an image file is valid based on its format and size.
 * @returns The function `useImageValidated` returns an array containing a single element, which is the
 * `getValidator` function.
 */
export const useImageValidated = (): any[] => {
    const dispatch = useAppDispatch();

    /**
     * The function `getValidator` checks if a file is a valid image format and if its size is less
     * than 2 MB.
     * @param {any} file - The `file` parameter is an object that represents an image file. It should
     * have properties like `type` (string) which represents the file type (e.g., 'image/png',
     * 'image/jpeg', etc.), and `size` (number) which represents the file size in bytes.
     * @returns The function `getValidator` returns a boolean value.
     */
    const getValidator = (file: any): boolean => {
        if (
            file?.type !== 'image/png' &&
            file?.type !== 'image/jpeg' &&
            file?.type !== 'image/jpg' &&
            file?.type !== 'image/gif' &&
            file?.type !== 'image/svg+xml'
        ) {
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: 'Invalid image format',
                    duration: 3000,
                })
            );
            return false;
        } else if (file?.size > 2097152) {
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: 'Image size must be less than 2 MB',
                    duration: 3000,
                })
            );
            return false;
        }

        return true;
    };

    return [getValidator];
};
