import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import * as Colors from '@brightlayer-ui/colors';
export const useStyles = makeStyles((theme: Theme) => ({
    containerWrapper: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0),
        },
        backgroundColor: '#F3F5F5',
    },
    tableHeadCell: {
        border: '0px',
        backgroundColor: '#fff',

        '& .MuiSvgIcon-root': {
            cursor: 'pointer',
        },

        '& .MuiStack-root': {
            position: 'relative',

            '& .MuiFormControl-root': {
                position: 'absolute',
                top: '45px',
                left: '0',
                boxShadow:
                    '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);',
                width: '100%',
                padding: '17px 16px',
                borderRadius: '4px',
                background: '#ffffff',
                maxHeight: '300px',
                overflow: 'hidden',
                overflowY: 'auto',
            },
        },

        '& .MuiMenuItem-root': {
            padding: '0',
        },
    },
    tableBodyCell: {
        border: '0px',
    },
    emptyStateWrapper: {
        height: 'calc(100vh - 300px)',
    },
    muiDialogpaper: {
        '& .MuiPaper-root': {
            width: '450px',
        },
    },
    MuiDialogTitleRoot: {
        paddingTop: '32px',
    },
    MuiDialogActionsRoot: {
        padding: '24px',
        borderTop: '1px solid rgba(66, 78, 84, 0.12)',
    },
    deleteButton: {
        background: Colors.red[600],
        '&:hover': {
            backgroundColor: Colors.red[400],
        },
    },
    infoListItem: {
        height: '100%',
        paddingLeft: '0px',
        '& .BluiInfoListItem-divider': {
            marginLeft: '-24px',
        },
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
        },
    },
    userListInfoListItem: {
        height: '100%',
        '& .BluiInfoListItem-statusStripe': {
            background: 'transparent',
        },
        // [theme.breakpoints.down('sm')]: {
        //     flexWrap:"wrap",
        // },
        '& .BluiInfoListItem-rightComponent': {
            [theme.breakpoints.down('sm')]: {
                marginLeft: theme.spacing(7),
            },
        },
        '& .MuiListItemText-root': {
            '& .MuiTypography-subtitle2': {
                display: 'flex',
                marginRight: theme.spacing(2),
                flexWrap: 'wrap',
            },
        },
    },
    hevronRight: {
        color: Colors.black[300],
    },
    arrowDropDown: {
        color: Colors.gray[500],
    },
    MultiSelectDropDown: {
        color: Colors.gray[500],
        width: '1em !important',
        height: '1em !important',
    },
    subHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '48px',
        background: Colors.white[50],
        paddingRight: theme.spacing(1),
    },
    tabsContent: {
        paddingLeft: '72px',
        paddingRight: '40px',
    },
    tab: {
        color: Colors.black[500],
        textTransform: 'capitalize',
        minHeight: '48px',
        fontWeight: 600,
    },
    tabActive: {
        color: Colors.blue[500],
        textTransform: 'capitalize',
        minHeight: '48px',
        fontWeight: 600,
        '&::after': {
            content: '""',
            position: 'absolute',
            height: '2px',
            bottom: '0px',
            width: '100%',
            transition: ' all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            background: Colors.blue[500],
        },
    },
    menuList: {
        width: '260px !important',
    },
    disabled: {
        opacity: '0.5',
        pointerEvents: 'none',
        cursor: 'not-allowed',
    },
    menuItem: {
        marginRight: '24px',
        color: Colors.gray[500],
        height: '2rem',
    },
    menuItemTableHeader: {
        paddingLeft: '6px',
    },
    filterItem: {
        backgroundColor: '#fff !important',
        '&:hover': {
            backgroundColor: '#fff !important',
        },
        '&.active': {
            backgroundColor: '#fff !important',
        },
        '&$selected': {
            backgroundColor: '#fff !important',
        },
    },
    orgAdminLabel: {
        display: 'flex',
        alignItems: 'center',
    },
}));
