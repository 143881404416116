import React from 'react';
import {
    Box,
    AppBar,
    Typography,
    IconButton,
    Container,
    FormControl,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Badge as BadgeComponent,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Article, CancelOutlined, Search } from '@mui/icons-material';
import { useStyles } from './styles';
import { DocumentFilter } from './';
import { useGetDocumentsMutation } from '@fiji/common/src/features/documents/documentsApi';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { NotificationSkeleton } from '../NotificationPreferences';
import { EmptyState } from '@brightlayer-ui/react-components';
import { RootState, useTypedSelector } from '@fiji/common/src/app/store';
import { useCleanPayload, useDebounce, useGetHierarchyHandlers, useIsMount, useRBAC } from '../../hooks';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { selectHierarchyData } from '@fiji/common/src/features/group/groupSlice';

const getAccordionSummaryTitle = (title: string): JSX.Element => (
    <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ borderBottom: '1px solid #f2f2f2' }}
    >
        <Typography variant="h5" className="custom-fs-16">
            {title}
        </Typography>
    </AccordionSummary>
);

export const DocumentTab = (): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const selectedGroup = useTypedSelector((state: RootState) => state?.common?.groupId);
    const [payload, setPayload] = React.useState<any>({ filters: {} });
    const [date, setDate] = React.useState<any>([null, null]);
    const [searchKey, setSearchKey] = React.useState<string | undefined>(undefined);
    const { deviceId } = useParams();
    const currentOrg = useTypedSelector(selectedOrg);
    const rolePermissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(rolePermissions);
    const canViewDocuments = hasPermission('view-device-documents');
    const [cleanPayload] = useCleanPayload();

    const { removeUnwantedIds } = useGetHierarchyHandlers({
        isChildrenPreselected: true,
    });

    const [getDocuments, { isLoading, data: documentsData }]: any = useGetDocumentsMutation({
        fixedCacheKey: 'documents',
    });

    const hierarchyData = useTypedSelector(selectHierarchyData);

    const [filterOpen, setFilterOpen] = React.useState<boolean>(false);
    const isMount = useIsMount();

    const [handleGlobalSearch] = useDebounce((data: any) => getDocuments(data), 700);

    const initializeDocumentCall = (): void => {
        if (deviceId) {
            getDocuments({ filters: { deviceIds: [deviceId] } });
            setPayload((prev: any) => ({
                ...prev,
                filters: { ...prev.filters, deviceIds: [deviceId] },
            }));
        } else if (selectedGroup) {
            getDocuments({ filters: { groupIds: [selectedGroup] } });
            setPayload((prev: any) => ({
                ...prev,
                filters: { ...prev.filters, groupsAndDevices: { groups: [selectedGroup], devices: [] } },
            }));
        } else {
            getDocuments({ filters: { groupIds: [currentOrg?.id] } });
        }
    };

    React.useEffect(() => {
        if (canViewDocuments) initializeDocumentCall();
    }, [deviceId, selectedGroup, canViewDocuments, currentOrg?.id]);

    React.useEffect(() => {
        if (!Object.keys(payload)?.length && !isMount && canViewDocuments) initializeDocumentCall();
    }, [payload]);

    const applyFilters = (newPayload: any): any => {
        if (payload?.filters?.groupsAndDevices) {
            const filteredNodeIds = removeUnwantedIds(
                hierarchyData?.data?.data?.data,
                payload.filters.groupsAndDevices.groups || [],
                payload.filters.groupsAndDevices.devices || []
            );
            if (newPayload.filters) {
                newPayload.filters['deviceIds'] = filteredNodeIds.devices || [];
                newPayload.filters['groupIds'] = filteredNodeIds.groups || [];
                delete newPayload?.filters?.['date'];
                delete newPayload?.filters?.['groupsAndDevices'];
            }
        }
        return cleanPayload(newPayload.filters);
    };

    React.useEffect(() => {
        if (!isMount && canViewDocuments) {
            if (payload?.filters && Object.keys(payload?.filters)?.length) {
                const newPayload = JSON.parse(JSON.stringify(payload));
                const cleanedPayload = applyFilters(newPayload);
                handleGlobalSearch(undefined, { ...payload, filters: { ...cleanedPayload }, searchKey: searchKey });
                return;
            }
            handleGlobalSearch(undefined, { searchKey: searchKey });
        }
    }, [searchKey]);

    const getDocHtml = (source: any): JSX.Element => (
        <Box className="custom-accordion-box-d-flex">
            <Box sx={{ marginRight: '10px' }}>
                <Typography className="custom-dark-color" variant="h5">
                    {moment(parseInt(source?.timestamp)).format('MMM').toUpperCase() || 'N/A'}
                </Typography>
                <Typography className="custom-light-color" variant="h6">
                    {moment(parseInt(source?.timestamp)).format('YYYY') || 'N/A'}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Box>
                    <Typography className="custom-dark-color" variant="h5">
                        {source?.displayName}
                    </Typography>
                    <Typography className="custom-light-color" variant="h5">
                        {source?.name}
                    </Typography>
                </Box>
                <Box>
                    <IconButton sx={{ color: '#727e84' }}>
                        <ArrowForwardIosIcon
                            sx={{ fontSize: '15px' }}
                            onClick={(): void => {
                                if (source?.url) {
                                    window.open(source?.url);
                                }
                            }}
                        />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );

    const getDocumentList = (): JSX.Element =>
        documentsData?.data?.length ? (
            documentsData?.data?.map((document: any) => (
                <Accordion defaultExpanded={true} key={document?.categoryId}>
                    {getAccordionSummaryTitle(document?.categoryName)}
                    <AccordionDetails>
                        {document?.documents?.map((file: any): JSX.Element => getDocHtml(file))}
                    </AccordionDetails>
                </Accordion>
            ))
        ) : (
            <Paper className={classes.MyPaperStyle} sx={{ height: '60vh' }}>
                <EmptyState icon={<Article fontSize={'inherit'} className="my-svg-icon" />} title={'No Documents'} />
            </Paper>
        );

    return (
        <>
            <Container maxWidth={false}>
                <AppBar
                    className={classes.contentHeader}
                    position="static"
                    color="primary"
                    sx={{
                        padding: '0 !important',
                        flexDirection: 'column !important',
                        alignItems: 'unset !important',
                        justifyContent: 'center !important',
                        background: 'transparent !important',
                        boxShadow: 'none !important',
                        border: 'none !important',
                        marginTop: '20px !important',
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box>
                            <FormControl>
                                <Box>
                                    <TextField
                                        hiddenLabel
                                        disabled={!canViewDocuments}
                                        id="searchKey"
                                        value={searchKey ?? ''}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                            setSearchKey(e.target.value);
                                        }}
                                        placeholder="Search..."
                                        size="small"
                                        InputProps={{
                                            startAdornment: <Search id="search" sx={{ mr: '5px', color: '#727e84' }} />,
                                            endAdornment: searchKey ? (
                                                <CancelOutlined
                                                    sx={{ color: '#727e84', cursor: 'pointer' }}
                                                    onClick={(): void => setSearchKey('')}
                                                />
                                            ) : (
                                                ''
                                            ),
                                        }}
                                    />
                                </Box>
                            </FormControl>
                        </Box>
                        <Box sx={{ position: 'relative' }}>
                            <IconButton disabled={!canViewDocuments}>
                                {payload?.filters && Object.keys(payload?.filters)?.length ? (
                                    <BadgeComponent color="primary" variant="dot">
                                        <FilterListIcon onClick={(): void => setFilterOpen(true)} />
                                    </BadgeComponent>
                                ) : (
                                    <FilterListIcon onClick={(): void => setFilterOpen(true)} />
                                )}
                            </IconButton>
                            {filterOpen && (
                                <DocumentFilter
                                    key="dewdfr311e1filter"
                                    payload={payload}
                                    setPayload={setPayload}
                                    customDate={date}
                                    setCustomDate={setDate}
                                    handleIsFilterOpen={(arg: boolean): void => setFilterOpen(arg)}
                                />
                            )}
                        </Box>
                    </Box>
                </AppBar>
                <Paper
                    className={classes.MyPaperStyle}
                    sx={{ background: 'transparent', backgroundColor: 'transparent', boxShadow: 'none' }}
                >
                    {isLoading ? <NotificationSkeleton key="ssdaD3113" count={1} /> : getDocumentList()}
                </Paper>
            </Container>
        </>
    );
};
