import React from 'react';
import { Avatar, Box, Button, Checkbox, DialogContent, DialogContentText, Stack, Typography } from '@mui/material';
import { AllUserObj, UserObj, UserProfile } from '@fiji/common/src/types';
import Loader from '../../components/Loader';
import { EmptyState } from '@brightlayer-ui/react-components';
import { Add, Person, PersonOff } from '@mui/icons-material';
import * as Colors from '@brightlayer-ui/colors';
import { useNavigate } from 'react-router-dom';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { getFullName, getUserInitials } from '../../CommonUtils';
import { useGetAllUsersQuery } from '@fiji/common/src/features/userManagement/userManagement';

export const SelectUsers = React.memo((props: any): JSX.Element => {
    const navigate = useNavigate();

    const { data: userProfileData } = useGetUserProfileQuery<{ data: UserProfile }>();
    const {
        data: users,
        isLoading,
        isFetching,
    } = useGetAllUsersQuery<{ data: AllUserObj; isLoading: boolean; isFetching: boolean }>(
        {
            page: 0,
            size: 50,
            filters: {
                notificationGroup: props?.payload?.groupIds,
            },
        },
        { refetchOnMountOrArgChange: true }
    );

    /**
     * The function `payloadHandler` handles changes in an input element and updates the payload state
     * in a React component.
     * @param e - The parameter `e` is of type `React.ChangeEvent<HTMLInputElement>`, which represents
     * the event object generated when the input element's value changes. It is typically used in React
     * components to handle user input.
     * @param {string} userId - The `userId` parameter is a string that represents the unique
     * identifier of a user.
     */
    const payloadHandler = (e: React.ChangeEvent<HTMLInputElement>, userId: string): void => {
        const payloadClone = JSON.parse(JSON.stringify(props?.payload));
        const uncheckedIndex = payloadClone?.userIds?.indexOf(userId);

        if (e.target.checked) {
            payloadClone?.userIds?.push(userId);
        } else {
            payloadClone?.userIds?.splice(uncheckedIndex, 1);
        }
        props.setPayload(payloadClone);
    };

    /**
     * The function `handleSelectAll` toggles the selection of all user IDs based on the current state.
     */
    const handleSelectAll = (): void => {
        if (props?.payload?.userIds?.length === users?.data?.records?.length) {
            props?.setPayload((prev: any) => ({ ...prev, userIds: [] }));
        } else {
            props?.setPayload((prev: any) => ({
                ...prev,
                userIds: users?.data?.records?.map((item: any) => item?.id),
            }));
        }
    };

    const getAvatar = (user: UserObj): any => {
        if (user?.firstName || user?.lastName) {
            return getUserInitials(`${user.firstName || ''} ${user.lastName || ''}`);
        }
        return <Person fontSize="inherit" />;
    };

    const getUserList = (): JSX.Element => {
        if (users?.data?.records?.length) {
            return (
                <>
                    <Box
                        sx={{
                            borderTop: '1px solid rgba(66, 78, 84, 0.12)',
                            borderBottom: '1px solid rgba(66, 78, 84, 0.12)',
                        }}
                        className="modal-space-between"
                    >
                        <Typography variant="body2" className="modal-blue-header">
                            All Users with Access
                        </Typography>
                        <Checkbox
                            checked={
                                Boolean(props?.payload?.userIds?.length) &&
                                props?.payload?.userIds?.length === users?.data?.records?.length
                            }
                            onChange={handleSelectAll}
                            indeterminate={
                                Boolean(props?.payload?.userIds?.length) &&
                                props?.payload?.userIds?.length < users?.data?.records?.length
                            }
                            sx={{ marginRight: '16px' }}
                        />
                    </Box>
                    {users?.data?.records.map(
                        (user: UserObj): JSX.Element => (
                            <Box
                                key={user?.id}
                                className="modal-space-between"
                                sx={{ borderBottom: '1px solid rgba(66, 78, 84, 0.12)', padding: '8px 0 8px 40px' }}
                            >
                                <Box className="modal-align-center">
                                    <Avatar>{getAvatar(user)}</Avatar>
                                    <Stack sx={{ paddingLeft: '16px' }}>
                                        <Typography variant="body1" className="modal-greyhead-text">
                                            {getFullName(user?.firstName, user?.lastName)}
                                        </Typography>
                                        <Typography variant="body2" className="modal-para">
                                            {user?.role}
                                        </Typography>
                                    </Stack>
                                </Box>
                                <Box className="modal-align-center">
                                    {userProfileData?.data?.id === user?.id && (
                                        <Typography variant="body1" className="you-text-style">
                                            You
                                        </Typography>
                                    )}
                                    <Checkbox
                                        sx={{ padding: '9px 9px 9px 24px', marginRight: '16px' }}
                                        checked={props?.payload?.userIds?.includes(user?.id)}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                            payloadHandler(e, user?.id)
                                        }
                                    />
                                </Box>
                            </Box>
                        )
                    )}
                </>
            );
        }
        return (
            <EmptyState
                icon={<PersonOff fontSize={'inherit'} sx={{ color: Colors.gray[500] }} />}
                title={'No Users found'}
                actions={
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        startIcon={<Add />}
                        onClick={(): void => navigate('/user/add')}
                    >
                        {'Add User'}
                    </Button>
                }
            />
        );
    };
    return (
        <DialogContent sx={{ padding: '0', marginTop: '0' }}>
            <DialogContentText id="alert-dialog-slide-description">
                {isLoading || isFetching ? <Loader /> : getUserList()}
            </DialogContentText>
        </DialogContent>
    );
});
