import React from 'react';
import { createNumArray } from '../../CommonUtils';
import { Box, Skeleton, Typography } from '@mui/material';

const SimpleCardSkeleton = ({ count }: { count?: number }): JSX.Element => (
    <>
        {createNumArray(count ?? 4).map((item, index) => (
            <Box
                className="modal-space-between"
                sx={{
                    gap: '16px',
                    padding: '16px',
                }}
                key={`unique${item}`}
            >
                <Skeleton animation="wave">
                    <Typography variant="body1">Event Details</Typography>
                </Skeleton>
                {index !== 0 && (
                    <Skeleton animation="wave">
                        <Typography variant="body1">Alarm Active</Typography>
                    </Skeleton>
                )}
            </Box>
        ))}
    </>
);

export default SimpleCardSkeleton;
