import Home from '@mui/icons-material/Home';
import { HomePage } from '../features/Home/home';

export const PAGES = [
    {
        title: 'Home Page',
        route: '',
        component: HomePage,
        icon: Home,
    },
];
