import { configureStore, PreloadedState, combineReducers } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { api } from './api/baseApi';
import profileSlice from '../features/profile/profileSlice';
import authSlice, { appLogout } from '../features/auth/authSlice';
import groupSlice from '../features/group/groupSlice';
import deviceSlice from '../features/deviceManagement/deviceSlice';
import organizationMob from '../features/orgManagement/orgSlice';
//import configurationSlice from '../features/nfc/nfcSlice';
import sampleConfig from '../features/nfc/nfcSlice';
import tagSlice from '../features/tagManagement/tagSlice';
import commonSlice from '../features/common/commonSlice';
import notificationSlice from '../features/notification/notificationSlice';
import alarmSlice from '../features/alarmManagement/alarmSlice';
import automationSlice from '../features/automation/automationSlice';
import userManagementSlice from '../features/userManagement/userManagementSlice';

const appReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    [authSlice.name]: authSlice.reducer,
    [commonSlice.name]: commonSlice.reducer,
    [organizationMob.name]: organizationMob.reducer,
    [tagSlice.name]: tagSlice.reducer,
    [deviceSlice.name]: deviceSlice.reducer,
    [profileSlice.name]: profileSlice.reducer,
    [sampleConfig.name]: sampleConfig.reducer,
    [groupSlice.name]: groupSlice.reducer,
    [notificationSlice.name]: notificationSlice.reducer,
    [automationSlice.name]: automationSlice.reducer,
    [alarmSlice.name]: alarmSlice.reducer,
    [userManagementSlice.name]: userManagementSlice.reducer,
});

const rootReducer = (state: any, action: any): any => {
    if (action.type === appLogout) {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};
const middlewares = [api.middleware];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
    configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
    });

export const store = setupStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof appReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
