/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useEffect, useState, useRef, useId } from 'react';
import {
    useMediaQuery,
    useTheme,
    Stack,
    Grid,
    Typography,
    Box,
    Avatar,
    Breadcrumbs,
    IconButton,
    Button,
} from '@mui/material';
import { useDrawer } from '../contexts/drawerContextProvider';
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerSubheader,
    EmptyState,
    UserMenu,
    UserMenuItem,
} from '@brightlayer-ui/react-components';
import {
    AccountTree,
    Add,
    AddBox,
    ArrowDropDown,
    CreateNewFolder,
    DeleteForever,
    FolderDelete,
    MoreVert,
    NavigateNext,
    VisibilityOff,
    Workspaces,
    NotificationsActiveOutlined,
    MoveDown,
    NotificationsActive,
} from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import * as Colors from '@brightlayer-ui/colors';
import { getUserInitials } from '../CommonUtils';
import { useConfirm, useGetHierarchyHandlers, useRBAC } from '../hooks';
import { DEFAULT_DRAWER_WIDTH, MIN_DRAWER_WIDTH } from '@fiji/common/src/constants/';
import IsoIcon from '@mui/icons-material/Iso';
import { useNavigate } from 'react-router';
import Hierarchy from '../components/Hierarchy';
import { Ups } from '@brightlayer-ui/icons-mui';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { selectedOrg, switchOrgModal } from '@fiji/common/src/features/orgManagement/orgSlice';
import {
    addGroupModal,
    deleteGroupModal,
    moveGroupsDevicesModal,
    selectHierarchyData,
} from '@fiji/common/src/features/group/groupSlice';
import {
    setAppHeaderTitle,
    setSelectedDevice,
    setSelectedGroup,
    setToastifyContent,
} from '@fiji/common/src/features/common/commonSlice';
import { useStyles } from './styles';
import { useDeleteDeviceMutation } from '@fiji/common/src/features/deviceManagement/deviceApi';
import DeleteModal from '../components/DeleteModal';

/**
 * The above type represents a tree structure with items that have an id, name, optional alarm count,
 * optional icon, optional parent id, and optional children.
 * @property {string} id - A unique identifier for each tree item.
 * @property {string} name - The name property represents the name of the tree item. It is a string
 * value.
 * @property {number} alarmCount - The `alarmCount` property is an optional number that represents the
 * number of alarms associated with the tree item.
 * @property icon - The `icon` property is a JSX element that represents an icon for the tree item. It
 * can be used to visually distinguish different types of tree items.
 * @property {string | null} parentId - The `parentId` property is used to specify the ID of the parent
 * item in a tree structure. It is optional and can be either a string or null.
 * @property {TreeItems[]} children - The `children` property is an optional array of `TreeItems`
 * objects. It represents the child nodes of a tree item. Each child node can have its own `id`,
 * `name`, `alarmCount`, `icon`, and `parentId` properties.
 */
export type TreeItems = {
    id: string;
    name: string;
    alarmCount?: number;
    icon?: JSX.Element;
    parentId?: string | null;
    children?: TreeItems[];
};

/* The below code is defining a TypeScript React component called `NavigationDrawer`. This component
represents a navigation drawer that can be used in a web application. It takes several props
including `drawerWidth` and `setDrawerWidth`. */
export const NavigationDrawer = (props: {
    drawerWidth: number;
    setDrawerWidth: (arg0: number) => void;
    addPageRef: any;
}): JSX.Element => {
    const theme = useTheme();
    const deleteModalId = useId();
    const navigate = useNavigate();
    const drawerRef = useRef<any>();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();
    const currentOrg = useTypedSelector(selectedOrg);
    const { drawerOpen, setDrawerOpen } = useDrawer();
    const isMobile = useMediaQuery(theme.breakpoints.down('md')) || props.drawerWidth < MIN_DRAWER_WIDTH + 10;
    const isDrawerOpen = drawerOpen && !isMobile;

    const rolePermissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(rolePermissions);
    const canCreateGroup = hasPermission('create-group');
    const canDeleteGroup = hasPermission('delete-group');
    const canViewGroups = hasPermission('view-groups');
    const canMoveGroups = hasPermission('move-group');
    const canCreateDevice = hasPermission('create-device');
    const canDeleteDevice = hasPermission('delete-device');

    /**
     * The function `handleOpenAddGroupModal` dispatches an action to open a modal for adding a group,
     * with an optional group ID parameter.
     * @param {string} [groupId] - The `groupId` parameter is an optional string that represents the ID
     * of a group.
     */
    const handleOpenAddGroupModal = (groupId?: string): any =>
        dispatch(addGroupModal({ isOpen: true, groupId: groupId ?? '' }));

    /* The below code is a TypeScript React code snippet. It is using the `useTypedSelector` hook to
    select the `groupId` property from the `common` slice of the Redux state. The selected `groupId`
    value is then assigned to the `selectedGroup` constant. */
    const selectedGroup = useTypedSelector((state) => state.common.groupId);
    const selectedDevice = useTypedSelector((state) => state.common.deviceId);

    const hierarchyData = useTypedSelector(selectHierarchyData);

    const [deleteDevice] = useDeleteDeviceMutation();
    const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
    const [selectedNodes, setSelectedNodes] = useState<string>('');
    const [nodeToPathMap, setNodeToPathMap] = useState<any>({});
    const [isGroupEmpty, setIsGroupEmpty] = useState(false);
    const [isDeviceEmpty, setIsDeviceEmpty] = useState(false);

    /* The below code is using the useEffect hook in a React component. It is setting the selectedNodes
    state variable to the value of selectedGroup and setting the selectedDevice state variable to an
    empty string. This effect will be triggered whenever the selectedGroup variable changes. */
    useEffect(() => {
        setSelectedNodes(selectedGroup);
        dispatch(setSelectedDevice(''));
        if (selectedGroup) {
            const title = getGroupLabel(hierarchyData?.data?.data?.data, selectedGroup);
            dispatch(setAppHeaderTitle(title));
        }
    }, [selectedGroup]);

    /* The below code is using the useEffect hook in a React component. It is checking if the value of
    `props.drawerWidth` is greater than `MIN_DRAWER_WIDTH + 10`. If it is, it sets the state
    variable `drawerOpen` to `true`, indicating that the drawer should be open. If it is not, it
    sets `drawerOpen` to `false`, indicating that the drawer should be closed. The effect is
    triggered whenever the value of `props.drawerWidth` changes. */
    useEffect(() => {
        if (props.drawerWidth > MIN_DRAWER_WIDTH + 10) {
            setDrawerOpen(true);
        } else {
            setDrawerOpen(false);
        }
    }, [props.drawerWidth]);

    /* The below code is using the useEffect hook in a React component. It is checking if the value of
    the "drawerOpen" variable has changed. If it has, it will call the "setDrawerWidth" function
    from the props object, passing in the value of "DEFAULT_DRAWER_WIDTH". This code is likely used
    to control the width of a drawer component based on whether it is open or closed. */
    useEffect(() => {
        if (drawerOpen) {
            props.setDrawerWidth(DEFAULT_DRAWER_WIDTH);
        }
    }, [drawerOpen]);

    /* The below code is using the useEffect hook in a TypeScript React component. It has a dependency
    array that includes the variable `groupsHierarchy`. When `groupsHierarchy` changes, the
    `generateNodeToPathMap` function is called with the `data` property of `groupsHierarchy`. */
    useEffect(() => {
        if (hierarchyData?.data) generateNodeToPathMap(hierarchyData?.data?.data?.data);
    }, [hierarchyData?.data]);

    useEffect(() => {
        if (selectedDevice) navigate(`/device/details/${selectedDevice}`);
    }, [selectedDevice]);

    /**
     * The handleChangeDrawer function toggles the state of the drawerOpen variable.
     */
    const handleChangeDrawer = (): void => {
        setDrawerOpen(!drawerOpen);
    };

    /**
     * The function `getTreeNodes` recursively creates an array of tree node IDs from a given tree
     * structure.
     * @returns The function `getTreeNodes` is returning an array of strings.
     */
    const getTreeNodes = (): string[] => {
        const treeNodes: string[] = [];

        const createTreeNodes = (treeItems: TreeItems[]): void => {
            treeItems.forEach((item: TreeItems) => {
                treeNodes.push(item.id);
                if (item.children) createTreeNodes(item.children);
            });
        };
        createTreeNodes(hierarchyData?.data?.data?.data);

        return treeNodes;
    };

    /**
     * The function `handleExpandCollapse` toggles the expanded state of tree nodes in a React
     * component.
     */
    const handleExpandCollapse = (): void => {
        setExpandedNodes((oldExpanded) => (oldExpanded.length === 0 ? getTreeNodes() : []));
    };

    /**
     * The function `onExpandNodes` sets the expanded nodes in a React component.
     * @param {string[]} nodeIds - The `nodeIds` parameter is an array of strings. It represents the
     * IDs of the nodes that need to be expanded.
     */
    const onExpandNodes = (nodeIds: string[]): void => {
        setExpandedNodes(nodeIds);
    };

    /**
     * The function `deleteDeviceHandler` is an asynchronous function that deletes a device and
     * displays a success message if the deletion is successful.
     */
    const deleteDeviceHandler = async (): Promise<void> => {
        const { error }: any = await deleteDevice({ deviceIds: [selectedDevice] });
        if (!error) {
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: `Device deleted successfully`,
                    duration: 3000,
                })
            );
            dispatch(setSelectedDevice(''));
            navigate('/');
        }
    };

    const {
        isVisible: isDeleteModalOpen,
        onCancel: closeDeleteModal,
        onClick: openDeleteDeviceModal,
        onConfirm: confirmDeletion,
    } = useConfirm(deleteDeviceHandler as any);

    const { getGroupLabel } = useGetHierarchyHandlers({});

    /**
     * The function `handleSelectNodes` updates the selected group or device based on the provided
     * nodeIds and key.
     * @param {string} nodeIds - A string representing the selected node or nodes.
     * @param {string} [key] - The `key` parameter is an optional string that determines the type of
     * nodes being selected. It can have two possible values: 'children' or 'devices'.
     */

    const handleSelectNodes = (nodeIds: string, key?: string): void => {
        const title = getGroupLabel(hierarchyData?.data?.data?.data, nodeIds);
        if (props?.addPageRef?.current?.isSaveDiscardDisabled === false) {
            props?.addPageRef?.current?.handleDiscardChanges(nodeIds, key, title);
        } else if (key === 'children') {
            dispatch(setAppHeaderTitle(title));
            dispatch(setSelectedGroup(nodeIds));
            if (window.location.pathname.includes('details')) {
                navigate('/');
            }
            setSelectedNodes(nodeIds);
            dispatch(setSelectedDevice(''));
        } else if (key === 'devices') {
            dispatch(setSelectedDevice(nodeIds));

            setSelectedNodes('');
        }
    };

    /**
     * The function `handleOpenDeleteGroupModal` opens a delete group modal if there are selected
     * nodes.
     */
    const handleOpenDeleteGroupModal = (): void => {
        if (selectedNodes) dispatch(deleteGroupModal({ isOpen: true, groupId: selectedNodes }));
    };

    /**
     * The function `handleOpenSwitchOrganizationModal` dispatches an action to open a modal for
     * switching organizations.
     */
    const handleOpenSwitchOrganizationModal = (): void => {
        dispatch(switchOrgModal(true));
    };

    const handleOpenMoveGroupsDevicesModal = (): void => {
        dispatch(moveGroupsDevicesModal({ isOpen: true, groupId: selectedGroup, deviceId: selectedDevice }));
    };

    /**
     * The function `getDrawerMenuItems` returns an array of menu items based on certain conditions.
     * @returns The function `getDrawerMenuItems` returns an array of `UserMenuItem` objects.
     */
    const getDrawerMenuItems = (): UserMenuItem[] => {
        const menuItems = [];
        if (canCreateDevice) {
            menuItems.push({
                title: ' Add Device',
                icon: <AddBox />,
                onClick: (): void =>
                    navigate('/add-device', { state: { groupId: selectedNodes, manageDeviceTab: 'Devices' } }),
            });
        }
        if (canDeleteDevice) {
            menuItems.push({
                className: !selectedDevice?.length ? classes.disabled : '',
                title: ' Delete Device',
                icon: <DeleteForever />,
                onClick: openDeleteDeviceModal,
                divider: true,
            });
        }
        if (canCreateGroup) {
            menuItems.push({
                title: 'Add Group',
                icon: <CreateNewFolder />,
                onClick: (): void => handleOpenAddGroupModal(selectedNodes),
            });
        }
        if (canDeleteGroup) {
            menuItems.push({
                ...(!selectedNodes?.length
                    ? { className: classes.disabled, divider: true }
                    : { onClick: handleOpenDeleteGroupModal }),
                title: 'Delete Group',
                icon: <FolderDelete />,
            });
        }
        if (canMoveGroups) {
            menuItems.push({
                title: 'Move Groups & Devices',
                icon: <MoveDown />,
                onClick: handleOpenMoveGroupsDevicesModal,
            });
        }
        if (canViewGroups) {
            menuItems.push({
                title: 'Group Management',
                icon: <AccountTree />,
                onClick: () => navigate('/groups'),
            });
        }
        return menuItems;
    };

    /**
     * The function `generateNodeToPathMap` recursively generates a mapping of node IDs to their
     * respective paths in a tree-like structure.
     * @param {any} nodes - An array of objects representing nodes. Each node object has properties
     * like id, defaultGroup, devices, and children.
     * @param {any} path - The `path` parameter is an array that represents the current path or
     * hierarchy of nodes. It is used to keep track of the parent nodes as we traverse through the tree
     * structure of nodes.
     */
    const generateNodeToPathMap = (nodes: any, path: any = []): void => {
        for (const node of nodes) {
            let nodePath = path;
            if (node.defaultGroup === false) nodePath = [...path, node.id];
            const devicesPayload: any = {};
            if (node?.devices?.length) {
                node?.devices?.map((device: any) => {
                    devicesPayload[device?.id] = nodePath;
                });
            }
            setNodeToPathMap((prevMap: any) => ({ ...prevMap, [node.id]: nodePath, ...devicesPayload }));

            if (node.children) {
                generateNodeToPathMap(node.children, nodePath);
            }
        }
    };

    /**
     * The function `getNodeLabelFromId` takes a node ID and an array of nodes, and recursively
     * searches for a node with a matching ID, returning its name if found.
     * @param {string} nodeId - The `nodeId` parameter is a string that represents the ID of the node
     * you want to find the label for.
     * @param {any} nodes - The `nodes` parameter is an array of objects. Each object represents a node
     * and has properties such as `id` and `name`. The `id` property is a unique identifier for the
     * node, and the `name` property is the label or name of the node. The `nodes`
     * @returns The function `getNodeLabelFromId` returns the label (name) of the node with the given
     * ID if it is found in the `nodes` array. If the node is not found, it returns `null`.
     */
    const getNodeLabelFromId = (nodeId: string, nodes: any): any => {
        for (const node of nodes) {
            if (node.id === nodeId) {
                return node.name;
            }

            if (node.children) {
                const name = getNodeLabelFromId(nodeId, node.children);
                if (name) {
                    return name;
                }
            }
        }

        return null; // Return null if the node with the given ID is not found
    };

    /**
     * The function `renderBreadcrumbs` returns an array of breadcrumb objects based on the selected
     * nodes or device.
     * @returns The function `renderBreadcrumbs` returns an array of breadcrumb objects. Each
     * breadcrumb object has a `title` property representing the label of a node and a `path` property
     * representing the ID of the node.
     */
    const renderBreadcrumbs = (): any => {
        if (!selectedNodes && !selectedDevice) return [];

        const path = nodeToPathMap[selectedNodes || selectedDevice];

        if (!path) return [];

        const breadcrumbs = path.map((nodeId: string) => ({
            title: getNodeLabelFromId(nodeId, hierarchyData?.data?.data?.data),
            path: nodeId,
        }));
        return breadcrumbs;
    };

    /**
     * The function `getDrawerHierarchyActions` returns JSX elements representing alarms in a drawer
     * hierarchy.
     * @param {any} nodeId - The `nodeId` parameter is a variable that represents the ID of a node in a
     * tree hierarchy. It is of type `any`, which means it can hold any value.
     * @param {any} treeItem - The `treeItem` parameter is an object that represents a node in a tree
     * hierarchy. It contains various properties and values related to the node.
     * @returns a JSX element.
     */
    const getDrawerHierarchyActions = (nodeId: any, treeItem: any): JSX.Element => {
        if (treeItem?.stats?.alarms || treeItem?.stats?.warnings) {
            return (
                <Box className="hierarchy-alarm">
                    {selectedNodes === nodeId ? (
                        <>
                            <NotificationsActive
                                sx={{ color: '#CA3C3D !important' }}
                                className="hierarchy-alarm-icon"
                            />
                            <Typography sx={{ color: '#CA3C3D !important' }} className="hierarchy-alarm-count">
                                {treeItem?.stats?.alarms + treeItem?.stats?.warnings || 0}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <NotificationsActiveOutlined className="hierarchy-alarm-icon" />
                            <Typography className="hierarchy-alarm-count">
                                {treeItem?.stats?.alarms + treeItem?.stats?.warnings || 0}
                            </Typography>
                        </>
                    )}
                </Box>
            );
        }
        return <></>;
    };

    const getDrawerSubHeader = (): JSX.Element => (
        <DrawerSubheader
            className={`${!isDrawerOpen ? 'drawer-sub-header' : classes.drawerSubheader}  ${
                !isGroupEmpty && !selectedGroup && !selectedDevice && isDrawerOpen ? classes.active : ''
            }`}
        >
            {!isDrawerOpen && (
                <Avatar alt={currentOrg?.name} src={currentOrg?.logo ?? ''}>
                    {getUserInitials(currentOrg?.name ?? '')}
                </Avatar>
            )}
            {isDrawerOpen && (
                <Stack
                    direction={'row'}
                    sx={{ padding: '0 16px', cursor: 'pointer' }}
                    onClick={(): any => {
                        dispatch(setSelectedGroup(''));
                        dispatch(setAppHeaderTitle(undefined));
                        if (window.location.pathname.includes('details')) {
                            navigate('/');
                        }
                        dispatch(setSelectedDevice(''));
                    }}
                >
                    {!isGroupEmpty && (
                        <>
                            <Typography
                                sx={{
                                    cursor: 'pointer',
                                    color: !selectedGroup && !selectedDevice ? Colors.blue[500] : Colors.gray[500],
                                }}
                                variant={'subtitle2'}
                            >
                                {currentOrg?.name}
                            </Typography>
                            <IsoIcon
                                sx={{
                                    cursor: 'pointer',
                                    color: !selectedGroup && !selectedDevice ? Colors.blue[500] : Colors.gray[500],
                                }}
                                onClick={handleExpandCollapse}
                            />
                        </>
                    )}
                    {isGroupEmpty && !isDeviceEmpty && canCreateGroup && (
                        <>
                            <Button
                                variant="outlined"
                                className="outlined-light"
                                fullWidth
                                startIcon={<Add />}
                                onClick={(): void => handleOpenAddGroupModal()}
                            >
                                Add Group
                            </Button>
                        </>
                    )}
                </Stack>
            )}
        </DrawerSubheader>
    );

    return (
        <>
            <Drawer
                open={isMobile ? false : drawerOpen}
                variant={!isMobile && drawerOpen ? 'permanent' : 'rail'}
                width={props.drawerWidth}
                ref={drawerRef}
            >
                <DrawerHeader
                    className={classes.drawerHeader}
                    sx={isDrawerOpen ? { flexDirection: 'row-reverse' } : { height: '56px' }}
                    icon={isDrawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    onIconClick={handleChangeDrawer}
                    titleContent={
                        <Box className={classes.orgMenuSelect}>
                            <Avatar alt={currentOrg?.name} src={currentOrg?.logo ?? ''}>
                                {getUserInitials(currentOrg?.name ?? '')}
                            </Avatar>
                            <Typography variant="h6" className={classes.selectedOrgName}>
                                <Box className={classes.dropdownMenu} onClick={handleOpenSwitchOrganizationModal}>
                                    <Typography className="text-ellipsis" variant="h6">
                                        {currentOrg?.name}
                                    </Typography>
                                    <ArrowDropDown className={classes.dropdownMenuIcon} />
                                </Box>
                                <UserMenu
                                    id="device"
                                    avatar={
                                        <IconButton size="large" color={'inherit'}>
                                            {' '}
                                            <MoreVert />
                                        </IconButton>
                                    }
                                    menuGroups={[
                                        {
                                            items: getDrawerMenuItems(),
                                        },
                                    ]}
                                />
                            </Typography>
                        </Box>
                    }
                />
                {(!isDeviceEmpty || !isGroupEmpty) && getDrawerSubHeader()}
                <DrawerBody>
                    {isDrawerOpen ? (
                        <Hierarchy
                            hierarchyData={hierarchyData?.data?.data?.data}
                            onChangeTreeItem={handleSelectNodes}
                            selectedNodes={selectedNodes || selectedDevice}
                            expandedNodes={expandedNodes}
                            actions={getDrawerHierarchyActions}
                            filter={{ key: 'defaultGroup', value: false, operator: '===', iterator: 'children' }}
                            isLoading={hierarchyData?.isLoading}
                            isGroupEmpty={(isEmpty: boolean): void => setIsGroupEmpty(isEmpty)}
                            isDeviceEmpty={(isEmpty: boolean): void => setIsDeviceEmpty(isEmpty)}
                            noDataFoundComponent={
                                <>
                                    {drawerOpen && (
                                        <EmptyState
                                            className={classes.emptyState}
                                            icon={
                                                <Workspaces
                                                    fontSize={'inherit'}
                                                    sx={{ color: Colors.gray[500] }}
                                                    className="my-svg-icon"
                                                />
                                            }
                                            title={'No Groups'}
                                            actions={
                                                <Stack direction={'column'} spacing={2}>
                                                    {canCreateGroup && (
                                                        <Button
                                                            variant={'outlined'}
                                                            color={'primary'}
                                                            startIcon={<Add />}
                                                            onClick={(): void => handleOpenAddGroupModal()}
                                                        >
                                                            {' '}
                                                            Add a Group{' '}
                                                        </Button>
                                                    )}
                                                    <Button
                                                        variant={'outlined'}
                                                        color={'primary'}
                                                        startIcon={<VisibilityOff />}
                                                        onClick={(): void => setDrawerOpen(false)}
                                                    >
                                                        {' '}
                                                        Hide This Panel{' '}
                                                    </Button>
                                                </Stack>
                                            }
                                            description={'You can add groups to better organize Devices.'}
                                        />
                                    )}
                                </>
                            }
                            disableSelect
                            customIterator={['devices', 'children']}
                            iconKey={['devices']}
                            renderIcon={(iterator, nodeId): JSX.Element => (
                                <Box
                                    component={Ups}
                                    sx={{
                                        color:
                                            iterator === 'devices' && selectedDevice === nodeId
                                                ? Colors.blue[500]
                                                : Colors.gray[500],
                                        ml: 1,
                                        mr: 1,
                                    }}
                                />
                            )}
                            onExpandNodes={onExpandNodes}
                            // customRenderFunc={(type='fetchdevices', id='deviceId') => callApi()}
                        />
                    ) : (
                        <Box className={classes.verticalBreadcrumbs}>
                            <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
                                {[{ title: currentOrg?.name ?? '', path: '' }, ...renderBreadcrumbs()].map(
                                    (breadcrumb: { title: string; path: string }): JSX.Element => (
                                        <Typography key="3" color="text.primary">
                                            {breadcrumb.title}
                                        </Typography>
                                    )
                                )}
                            </Breadcrumbs>
                        </Box>
                    )}
                </DrawerBody>
                <DrawerFooter className={classes.DrawerFooter}>
                    {isDrawerOpen && (
                        <Grid container direction={'row'} alignItems={'center'}>
                            <Grid item xs={6} className={classes.eatonLogoFooter}>
                                <Box component="img" src={require('../assets/images/eaton-logo-footer.png')} />
                            </Grid>
                            <Grid item xs={6} textAlign={'right'}>
                                <Stack direction={'column'}>
                                    <Typography variant="caption">
                                        © Copyright {new Date().getFullYear()} Eaton
                                    </Typography>
                                    <Typography variant="caption">All Rights Reserved</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    )}
                    {!drawerOpen && (
                        <Box
                            component="img"
                            src={require('../assets/images/eaton-logo-footer.png')}
                            sx={{ width: '41px' }}
                        />
                    )}
                </DrawerFooter>
            </Drawer>
            <DeleteModal
                key={deleteModalId}
                closeHandler={closeDeleteModal}
                confirmClick={confirmDeletion}
                deleteButtonText="Delete Device"
                isVisible={isDeleteModalOpen}
                wrapperClass={classes.muiDialogpaper}
                actionClass={classes.MuiDialogActionsRoot}
                titleClass={classes.MuiDialogTitleRoot}
                description={<>Device will be deleted permanently.</>}
                headingText={'Delete Device?'}
                deleteButtonClass={classes.deleteButton}
            />
        </>
    );
};
