import React from 'react';

import { Box, Card, CardContent, CardActions, Button } from '@mui/material';
import { EmptyState } from '@brightlayer-ui/react-components';
import makeStyles from '@mui/styles/makeStyles';
import * as Colors from '@brightlayer-ui/colors';
import { RemoveModerator, Warning } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    ContainerWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    card: {
        position: 'relative',
        width: '450px',
        height: '100%',
        maxHeight: '621px',
        display: 'flex',
        flexDirection: 'column',
    },
    cardBody: {
        padding: '16px 24px',
        flex: '1 1 0px',
    },
    cardFooter: {
        borderTop: '1px solid rgba(66, 78, 84, 0.12)',
        padding: '24px',
    },
    emptyState: {
        marginTop: '96px',
        justifyContent: 'start',
        padding: '0px',
    },
}));
export const ErrorPage = (): JSX.Element => {
    const classes = useStyles();
    const navigateHandler = (): void => {
        window.location.replace(`${window.location.origin}/login`);
    };
    const [searchParams] = useSearchParams();
    const errorCode = searchParams.get('code');

    return (
        <React.Fragment>
            <header>
                <title>Error</title>
                <meta name="description" content="Description of Error" />
            </header>
            <Box className={classes.ContainerWrapper}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardBody}>
                        <EmptyState
                            className={classes.emptyState}
                            icon={
                                errorCode === 'action-exipred' ? (
                                    <Warning fontSize={'inherit'} sx={{ color: Colors.blue[500] }} />
                                ) : (
                                    <RemoveModerator fontSize={'inherit'} sx={{ color: Colors.blue[500] }} />
                                )
                            }
                            title={errorCode === 'action-expired' ? 'Action Expired!' : 'Inactive Account!'}
                            description={
                                errorCode === 'action-expired'
                                    ? 'Please login again'
                                    : 'Please activate your account before accepting invite.'
                            }
                        />
                    </CardContent>
                    <CardActions className={classes.cardFooter}>
                        <Button sx={{ width: '100%' }} variant={'outlined'} color={'primary'} onClick={navigateHandler}>
                            Login
                        </Button>
                    </CardActions>
                </Card>
            </Box>
        </React.Fragment>
    );
};
