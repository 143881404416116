import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    FormControl,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useStyles } from './styles';
import { useAppDispatch } from '@fiji/common/src/app/store';
// import { UserOrgs } from '../ManageOrganization/ManageOrganization';
import { useGetOrgQuery, useJoinOrgMutation } from '@fiji/common/src/features/orgManagement/orgApi';
import { CorporateFare } from '@mui/icons-material';
import { handleWhiteSpaces } from '../../CommonUtils';
import orgSlice from '@fiji/common/src/features/orgManagement/orgSlice';

/**
 * The JoinAnotherOrganizationButton component is a button that triggers a handleOpenJoinOrgFlowModal function when
 * clicked.
 * @param  - - `variant`: This parameter determines the visual style of the button. It can have one of
 * three values: `'text'`, `'outlined'`, or `'contained'`.
 */
const JoinAnotherOrganizationButton = ({
    variant,
    handleOpenJoinOrgFlowModal,
}: {
    variant: 'text' | 'outlined' | 'contained';
    handleOpenJoinOrgFlowModal: () => void;
}): JSX.Element => (
    <Button className="outlined-light" sx={{ width: '100%' }} variant={variant} onClick={handleOpenJoinOrgFlowModal}>
        Join Another Organization
    </Button>
);

export const JoinOrg = (): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();

    const [orgStatus, setOrgStatus] = useState<null | string>(null); // pending, approved, created, joined, withdrawing
    const [orgCode, setOrgCode] = useState<string>('');
    const [isJoiningOrg, setIsJoiningOrg] = useState<boolean>(false);

    const { data: orgLists } = useGetOrgQuery<{
        data: { data: any };
    }>({ page: 0, size: 25, orgCode: orgCode }, { skip: !orgCode?.length });

    const [requestJoinOrganization, { error: joinOrgError }] = useJoinOrgMutation<any>();

    /**
     * The closeOrgFlowModal function dispatches an action to close the organization flow modal.
     */
    const closeOrgFlowModal = (): any => dispatch(orgSlice.actions.orgFlowModal({ isOpen: false, flow: '' }));

    const handleOpenJoinOrgFlowModal = (): any => {
        setOrgCode('');
        setOrgStatus(null);
    };

    /**
     * The function handleChangeOrgStatus handles the change in organization status based on user
     * input.
     * @param {any} e - The parameter `e` is an event object that is passed to the
     * `handleChangeOrgStatus` function. It is typically an event object that is triggered by a user
     * interaction, such as a button click or input change.
     */
    const handleChangeOrgStatus = async (e: any): Promise<void> => {
        if (e.target.id === 'joined') {
            if (orgLists?.data?.records?.find((org: any) => org?.status === 'PENDING')) {
                setOrgStatus('alreadyRequested');
                setOrgCode('');
            } else if (orgLists?.data?.records?.find((org: any) => org?.status === 'INVITED')) {
                setOrgStatus('alreadyInvited');
                setOrgCode('');
            } else if (orgLists?.data?.records?.find((org: any) => org?.status === 'ACTIVE')) {
                setOrgStatus('alreadyJoined');
                setOrgCode('');
            } else {
                setIsJoiningOrg(true);
                const { data }: any = await requestJoinOrganization({ orgCode: orgCode });
                setIsJoiningOrg(false);
                if (data) {
                    setOrgStatus('joined');
                }
            }
        } else {
            setOrgStatus(e.target.id);
        }
    };

    /**
     * The handleChangeOrgCode function updates the state variable orgCode with the value of the input
     * field.
     * @param {any} e - The parameter `e` is an event object that is passed to the
     * `handleChangeOrgCode` function. It represents the event that triggered the function, such as a
     * change event on an input element.
     */
    const handleChangeOrgCode = (e: any): void => {
        setOrgCode(e.target.value);
    };

    /* The below code is defining a function called `getHeader` that returns a JSX element. */
    const getHeader = (): JSX.Element => (
        <CardHeader
            avatar={
                <Avatar className={classes.headerAvatarIcon}>
                    <CorporateFare />
                </Avatar>
            }
            className={classes.cardHeader}
        ></CardHeader>
    );

    /**
     * The function `getCardBody` returns JSX elements based on the value of the `orgStatus` variable.
     * @returns The function `getCardBody` returns a JSX element.
     */
    const getCardBody = (): JSX.Element => {
        switch (orgStatus) {
            case 'alreadyRequested': {
                return (
                    <>
                        <Typography variant="body2" sx={{ marginBottom: '32px' }}>
                            {
                                "You've already requested to join this organization and are awaiting administrator approval. You'll receive an email notification when you've been approved."
                            }
                        </Typography>
                        <Typography variant="body2">
                            {
                                'In the meantime, you can continue setting up your account, get to know the application, or join another Organization.'
                            }
                        </Typography>
                    </>
                );
            }
            case 'alreadyInvited': {
                return (
                    <>
                        <Typography variant="body2" sx={{ marginBottom: '32px' }}>
                            {
                                'You have already been invited to join this organization. To accept the invitation, follow the instructions in the invitation email. If you do not have an invitation email, contact the organization administrator.'
                            }
                        </Typography>
                        <Typography variant="body2">
                            {
                                'In the meantime, you can continue setting up your account, get to know the application, or join another Organization.'
                            }
                        </Typography>
                    </>
                );
            }
            case 'alreadyJoined': {
                return (
                    <>
                        <Typography variant="body2" sx={{ marginBottom: '32px' }}>
                            {"You've already a part of this organization."}
                        </Typography>
                        <Typography variant="body2">
                            {
                                'In the meantime, you can continue setting up your account, get to know the application, or join another Organization.'
                            }
                        </Typography>
                    </>
                );
            }
            case 'joined': {
                return (
                    <>
                        <Typography variant="body2" mb={2}>
                            {
                                "You'll need to be approved by an administrator before you can join this Organization. You'll receive an email notification when you've been approved."
                            }
                        </Typography>
                        <Typography variant="body2">
                            {
                                'In the meantime, you can continue setting up your account, get to know the application, or join another Organization.'
                            }
                        </Typography>
                    </>
                );
            }
            case 'approved': {
                return (
                    <Typography variant="body2" sx={{ marginBottom: '32px' }}>
                        You have successfully joined the{' '}
                        {orgLists?.data?.records?.[0] && orgLists?.data?.records?.[0].name} organization! Tap Finish to
                        continue to the application.
                    </Typography>
                );
            }
            default: {
                return (
                    <>
                        <Typography variant="body2" mb={3}>
                            Enter the Organization Code below. Contact your administrator if you do not know your
                            Organization Code.
                        </Typography>
                        <FormControl variant={'filled'} className="select-form-control ml-0">
                            <TextField
                                id="organization-code"
                                required
                                sx={{ width: '100%' }}
                                label={'Organization Code'}
                                variant={'filled'}
                                InputLabelProps={{ required: false }}
                                value={orgCode}
                                onChange={handleChangeOrgCode}
                                {...(joinOrgError?.status === 400 &&
                                    joinOrgError?.data?.errorMessage && {
                                        error: true,
                                        helperText: joinOrgError?.data?.errorMessage,
                                    })}
                            />
                        </FormControl>
                    </>
                );
            }
        }
    };

    /* The below code is defining a function called `getCardActions` that returns a JSX element. The
    function uses a switch statement to determine the content of the JSX element based on the value
    of the `orgStatus` variable. */
    const getCardActions = (): JSX.Element => {
        switch (orgStatus) {
            case 'alreadyRequested':
            case 'alreadyInvited':
            case 'alreadyJoined': {
                return (
                    <Button
                        className="outlined-light"
                        sx={{ width: '100%' }}
                        variant="outlined"
                        onClick={closeOrgFlowModal}
                    >
                        Close
                    </Button>
                );
            }
            case 'joined': {
                return (
                    <>
                        <Button className="btn" sx={{ width: '100%' }} variant="contained" onClick={closeOrgFlowModal}>
                            Continue
                        </Button>
                        <JoinAnotherOrganizationButton
                            variant="outlined"
                            handleOpenJoinOrgFlowModal={handleOpenJoinOrgFlowModal}
                        />
                    </>
                );
            }
            case 'approved': {
                return (
                    <>
                        <Button sx={{ width: '100%' }} variant="contained" onClick={closeOrgFlowModal}>
                            Finish
                        </Button>
                        <JoinAnotherOrganizationButton
                            variant="outlined"
                            handleOpenJoinOrgFlowModal={handleOpenJoinOrgFlowModal}
                        />
                    </>
                );
            }
            default: {
                return (
                    <>
                        <Button
                            className="btn"
                            sx={{ width: '100%' }}
                            variant="contained"
                            id="joined"
                            onClick={handleChangeOrgStatus}
                            disabled={orgCode === '' || !handleWhiteSpaces(orgCode)}
                            {...(isJoiningOrg && { startIcon: <CircularProgress color="inherit" size={20} /> })}
                        >
                            {!isJoiningOrg && 'Join Organization'}
                        </Button>
                        <Button
                            className="outlined-light"
                            sx={{ width: '100%' }}
                            variant="outlined"
                            onClick={closeOrgFlowModal}
                        >
                            Cancel
                        </Button>
                    </>
                );
            }
        }
    };

    return (
        <Card className={classes.card}>
            {getHeader()}
            <CardContent className={classes.cardBody}>{getCardBody()}</CardContent>
            <CardActions className={classes.cardFooter}>
                <Stack spacing={2} sx={{ width: '100%' }}>
                    {getCardActions()}
                </Stack>
            </CardActions>
            {(orgStatus === 'alreadyRequested' || orgStatus === 'alreadyJoined') &&
                joinOrgError?.data?.errorMessage && (
                    <CardContent className={classes.cardBody}>{getCardBody()}</CardContent>
                )}
        </Card>
    );
};
