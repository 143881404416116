export const config: Config = {
    apiUrl:
        window?.location?.origin === 'https://brightlayer-remote-monitoring.eaton.com'
            ? 'https://api-brightlayer-remote-monitoring.eaton.com'
            : window?.location?.origin === 'http://localhost:3000'
            ? 'https://brightlayer-bss-dev.eaton.com'
            : window?.location?.origin?.replace('rm', 'bss'),
    apiVersion: process.env.REACT_APP_API_VERSION || '2.0',
};

export type Config = {
    apiUrl: string;
    apiVersion: string;
};
