import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import orgSlice, { selectOrgFlowModal } from '@fiji/common/src/features/orgManagement/orgSlice';
import { Dialog } from '@mui/material';
import React from 'react';
import { CreateOrg, JoinOrg, WithdrawOrg } from './';

/**
 * The `OrgFlowModal` component is a TypeScript React component that renders a dialog with different
 * flows based on the value of `orgFlowModal.flow`.
 * @returns The OrgFlowModal component is being returned as a JSX element.
 */
export const OrgFlowModal = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const orgFlowModal = useTypedSelector(selectOrgFlowModal);
    /**
     * The closeOrgFlowModal function dispatches an action to close the organization flow modal.
     */
    const closeOrgFlowModal = (): any => dispatch(orgSlice.actions.orgFlowModal({ isOpen: false, flow: '' }));

    return (
        <Dialog open={orgFlowModal?.isOpen} onClose={closeOrgFlowModal}>
            {orgFlowModal?.flow === 'create' && <CreateOrg />}
            {orgFlowModal?.flow === 'join' && <JoinOrg />}
            {orgFlowModal?.flow === 'withdraw' && <WithdrawOrg />}
        </Dialog>
    );
};
