import React, { useState } from 'react';
import {
    Box,
    Button,
    Card,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Container,
    Grid,
    Switch,
    TextField,
    Typography,
    IconButton,
    Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Stack } from '@mui/system';
import { Delete } from '@mui/icons-material';
import { RolesPermissions } from '../Roles/RolesPermissions';

const useStyles = makeStyles(() => ({
    cardWrapper: {
        padding: '48px',
        marginBottom: '16px',
        position: 'relative',
    },
    headerActionButton: {
        position: 'absolute',
        right: '8px',
        top: '8px',
    },
    helperText: {
        float: 'right',
    },
    muiDialogpaper: {
        '& .MuiPaper-root': {
            width: '453px',
        },
    },
    MuiDialogTitleRoot: {
        paddingTop: '32px',
    },
    MuiDialogActionsRoot: {
        padding: '24px',
        borderTop: '1px solid rgba(66, 78, 84, 0.12)',
    },
    newPasswordInputField: {
        marginTop: '32px',
        marginBottom: '8px',
    },
}));

export const RoleCustom = (): JSX.Element => {
    const [saveRoleModal, setSaveRoleModal] = useState(false);
    const [deleteRoleModal, setDeleteRoleModal] = useState(false);
    const classes = useStyles();
    return (
        <React.Fragment>
            <header>
                <title>RoleCustom</title>
                <meta name="description" content="Description of RoleCustom" />
            </header>
            <Container fixed>
                <Box sx={{ p: 2 }}>
                    <Card className={classes.cardWrapper}>
                        <Tooltip title="Delete">
                            <IconButton aria-label="settings" className={classes.headerActionButton}>
                                <Delete
                                    id="delete-modal"
                                    fontSize="small"
                                    onClick={(): void => setDeleteRoleModal(true)}
                                />
                            </IconButton>
                        </Tooltip>

                        <Stack spacing={2} direction={'row'} mb={2} alignItems={'center'}>
                            <TextField id={'roleName'} label={'Role Name'} required fullWidth variant={'filled'} />
                            <Stack direction={'row'} alignItems={'center'}>
                                <Typography variant="subtitle2">Enabled</Typography>
                                <Switch />
                            </Stack>
                        </Stack>
                        <TextField
                            sx={{ mb: 4 }}
                            id={'roleDescription'}
                            label={'Role Description'}
                            required
                            fullWidth
                            variant={'filled'}
                            helperText={<Typography className={classes.helperText}>{'105/200'}</Typography>}
                        />
                        <RolesPermissions />
                        <Grid container mt={6}>
                            <Grid item xs={6}>
                                <Button variant={'outlined'}>Cancel</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack spacing={2} direction={'row'} justifyContent={'flex-end'}>
                                    <Button id="save-as-btn" variant={'outlined'} disabled>
                                        Save As
                                    </Button>
                                    <Button
                                        id="save-btn"
                                        variant={'contained'}
                                        onClick={(): void => setSaveRoleModal(true)}
                                    >
                                        Save
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Card>
                </Box>
            </Container>
            {/* save role modal start */}
            <Dialog
                open={saveRoleModal}
                onClose={(): void => setSaveRoleModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.muiDialogpaper}
            >
                <DialogTitle id="alert-dialog-title" className={classes.MuiDialogTitleRoot}>
                    <Typography variant={'h6'}>Save Role</Typography>
                </DialogTitle>
                <DialogContent>
                    <TextField id={'emailAddress'} label={'Role Name'} required fullWidth variant={'filled'} />
                </DialogContent>
                <DialogActions id="save-dialog" className={classes.MuiDialogActionsRoot}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sx={{ display: 'flex' }}>
                            <Button variant={'outlined'} onClick={(): void => setSaveRoleModal(false)}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6} textAlign="end">
                            <Button variant={'contained'}>Save</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            {/* save role modal end */}
            {/* delete role modal start */}
            <Dialog
                open={deleteRoleModal}
                onClose={(): void => setDeleteRoleModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.muiDialogpaper}
            >
                <DialogTitle id="alert-dialog-title" className={classes.MuiDialogTitleRoot}>
                    <Typography variant={'h6'}>Delete Role?</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant={'body1'} mb={1}>
                        {
                            ' The <”role name”> Role will be deleted forever.  Users associated with this role may lose access to view and interact with the application.'
                        }
                    </Typography>
                    <Typography variant={'body1'} mb={2}>
                        To proceed, type <strong>“delete”</strong> in the text box below and then select the ‘Delete’
                        button.{' '}
                    </Typography>
                    <TextField
                        id={'typeDelete'}
                        label={'Type “delete”'}
                        fullWidth
                        variant={'filled'}
                        helperText={
                            <Box>
                                <Typography component={'span'}>{'Type “delete” to delete this role'}</Typography>
                                <Typography component={'span'} className={classes.helperText}>
                                    {'0/6'}
                                </Typography>
                            </Box>
                        }
                    />
                </DialogContent>
                <DialogActions id="delete-dialog" className={classes.MuiDialogActionsRoot}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sx={{ display: 'flex' }}>
                            <Button variant={'outlined'} onClick={(): void => setDeleteRoleModal(false)}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6} textAlign="end">
                            <Button variant={'contained'}>Delete</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            {/* delete role modal end */}
        </React.Fragment>
    );
};
