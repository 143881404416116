import React from 'react';
import * as Colors from '@brightlayer-ui/colors';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { ListItemTag } from '@brightlayer-ui/react-components';
import {
    // Avatar,
    Box,
    Typography,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { ChevronRight, ArrowDropDown } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    tableHeadCell: {
        border: '0px',
        backgroundColor: '#fff',
    },
    tableBodyCell: {
        border: '0px',
    },
    hevronRight: {
        color: Colors.black[300],
    },
}));

export const Roles = (): JSX.Element => {
    const classes = useStyles();
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <header>
                <title>Roles</title>
                <meta name="description" content="Description of Roles" />
            </header>
            <Box sx={{ p: 2 }}>
                <Paper>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width={'20%'} className={classes.tableHeadCell}>
                                        <Stack direction="row">
                                            Role
                                            <TableSortLabel>
                                                <Box component="span"></Box>
                                            </TableSortLabel>
                                            <ArrowDropDown sx={{ color: Colors.gray[500] }} />
                                        </Stack>
                                    </TableCell>
                                    <TableCell width={'65%'} className={classes.tableHeadCell}>
                                        <Stack direction="row">
                                            Description
                                            <ArrowDropDown sx={{ color: Colors.gray[500] }} />
                                        </Stack>
                                    </TableCell>
                                    <TableCell width={'10%'} className={classes.tableHeadCell}>
                                        <Stack direction="row">
                                            Status
                                            <ArrowDropDown sx={{ color: Colors.gray[500] }} />
                                        </Stack>
                                    </TableCell>
                                    <TableCell width={'5%'} className={classes.tableHeadCell} align={'right'}>
                                        <ChevronRight className={classes.hevronRight} />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.tableBodyCell}>
                                        <Typography variant="subtitle1">Eaton Admin</Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableBodyCell}>
                                        <Typography variant="body2">Eaton Level Administrator Account.</Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableBodyCell}></TableCell>
                                    <TableCell className={classes.tableBodyCell} align={'right'}>
                                        <ChevronRight className={classes.hevronRight} />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableBodyCell}>
                                        <Typography variant="subtitle1">Organization Admin</Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableBodyCell}>
                                        <Typography variant="body2">
                                            Acknowledge alarms, manage site level users, permissions. thresholds,
                                            devices and device groups,
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableBodyCell}>
                                        <Stack direction="row" alignItems="center"></Stack>
                                    </TableCell>
                                    <TableCell className={classes.tableBodyCell} align={'right'}>
                                        <ChevronRight
                                            className={classes.hevronRight}
                                            id="organization-admin"
                                            onClick={(): void => navigate('/organizationAdmin')}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableBodyCell}>
                                        <Typography variant="subtitle1">Manager</Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableBodyCell}>
                                        <Typography variant="body2">
                                            Acknowledge alarms, thresholds, devices and device groups.
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableBodyCell}></TableCell>
                                    <TableCell className={classes.tableBodyCell} align={'right'}>
                                        <ChevronRight className={classes.hevronRight} />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableBodyCell}>
                                        <Typography variant="subtitle1">Viewer</Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableBodyCell}>
                                        <Typography variant="body2">
                                            View device group and individual device status.
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableBodyCell}></TableCell>
                                    <TableCell className={classes.tableBodyCell} align={'right'}>
                                        <ChevronRight
                                            className={classes.hevronRight}
                                            id="roles-viewer"
                                            onClick={(): void => navigate('/rolesViewer')}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableBodyCell}>
                                        <Typography variant="subtitle1">Custom Role</Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableBodyCell}>
                                        <Typography variant="body2">
                                            Custom role created by an administrator with unique permissions.
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableBodyCell}>
                                        <ListItemTag label={'Disabled'} backgroundColor={Colors.red[500]} />
                                    </TableCell>
                                    <TableCell className={classes.tableBodyCell} align={'right'}>
                                        <ChevronRight
                                            className={classes.hevronRight}
                                            id="custom-role"
                                            onClick={(): void => navigate('/roleCustom')}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
            {/* )} */}
        </React.Fragment>
    );
};
