import React, { useState, useCallback, useEffect } from 'react';
import { UserProfile } from '@fiji/common/src/types';
import {
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    Box,
    TextField,
    DialogActions,
    Stack,
    Button,
} from '@mui/material';
import Loader from '../../components/Loader';
import { useUpdateUserProfileMutation } from '@fiji/common/src/features/userManagement/userManagement';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { RefetchConfigOptions } from '@reduxjs/toolkit/dist/query/core/apiState';
import { handleWhiteSpaces } from '../../CommonUtils';
import { USER_DETAILS_LIMIT } from '@fiji/common/src/constants';
import { useAppDispatch } from '@fiji/common/src/app/store';
import { setToastifyContent } from '@fiji/common/src/features/common/commonSlice';

/**
 * The above type represents the props required for a UserNameModal component in a TypeScript React
 * application.
 * @property {string} activeModal - A string that represents the currently active modal.
 * @property {string} wrapperClass - The `wrapperClass` property is a string that represents the CSS
 * class name for the wrapper element of the modal component.
 * @property {string} titleClass - The `titleClass` property is a string that represents the CSS class
 * to be applied to the title element of the modal.
 * @property {string} sectionClass - The `sectionClass` property is a string that represents the CSS
 * class name for the section element in the user name modal.
 * @property {string} formOverFlow - The `formOverFlow` property is a string that represents the CSS
 * class to be applied to the form when it overflows its container.
 * @property {string} actionsClass - This property is used to define the CSS class for the actions
 * section of the modal. It is a string value that represents the CSS class name.
 * @property activeModalHandler - A function that takes a string argument and does something with it.
 * It is used to handle the active modal state.
 */
type UserNameModalProps = {
    activeModal: string;
    wrapperClass: string;
    titleClass: string;
    sectionClass: string;
    formOverFlow: string;
    actionsClass: string;
    activeModalHandler: (arg0: string) => void;
};

export const UserNameModal = ({
    activeModal,
    wrapperClass,
    titleClass,
    sectionClass,
    formOverFlow,
    actionsClass,
    activeModalHandler,
}: UserNameModalProps): JSX.Element => {
    const dispatch = useAppDispatch();

    const [updateUserProfile, { isLoading: editUserLoader }] = useUpdateUserProfileMutation();

    const [userData, setUserData] = useState<Partial<UserProfile['data']>>({ firstName: '', lastName: '' });

    const { data: profileDetails } = useGetUserProfileQuery<{
        data: UserProfile;
        refetch: RefetchConfigOptions;
    }>();

    /* The `useEffect` hook is used to perform side effects in a React component. In this case, the
    effect is triggered when the `profileDetails` variable changes. */
    useEffect(() => {
        if (profileDetails) {
            setUserData((prev) => ({
                ...prev,
                firstName: profileDetails?.data?.firstName,
                lastName: profileDetails?.data?.lastName,
            }));
        }
    }, [profileDetails]);

    /* The `handleUpdateProfile` function is an asynchronous function that is used to update the user's
    profile with the new first name and last name values. */
    const handleUpdateProfile = useCallback(async (): Promise<void> => {
        const { error }: any = await updateUserProfile({
            ...(userData.firstName && { firstName: userData.firstName }),
            ...(userData.lastName && { lastName: userData.lastName }),
        });
        if (!error) {
            activeModalHandler('');
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: 'Username has been updated.',
                    duration: 3000,
                })
            );
        } else {
            setUserData((prev) => ({
                ...prev,
                firstName: profileDetails?.data?.firstName || '',
                lastName: profileDetails?.data?.lastName || '',
            }));
        }
    }, [userData]);

    /* The `handleChangeUserData` function is a callback function that is used to handle changes in the
    input fields for the user's first name and last name. */
    const handleChangeUserData = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>): void => {
            if (e?.target?.value?.length > USER_DETAILS_LIMIT) {
                return;
            }
            setUserData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
        },
        [userData]
    );

    return (
        <Dialog
            open={activeModal === 'usernameModal'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={wrapperClass}
        >
            <DialogTitle id="alert-dialog-title" className={titleClass}>
                <Typography variant={'h6'} className={sectionClass}>
                    {'Update Name'}
                </Typography>
                <Typography variant={'body1'}>
                    Fields marked with an asterisk(*) are required fields when editing a User.
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ position: 'relative' }}>
                {editUserLoader ? (
                    <>
                        <Box sx={{ height: '100px', overflow: 'hidden' }}>
                            <Loader size={40} />
                        </Box>
                    </>
                ) : (
                    <Box className={formOverFlow}>
                        <form style={{ width: '100%' }}>
                            <TextField
                                required
                                id="filled-required"
                                label="First Name"
                                value={userData?.firstName}
                                variant="filled"
                                fullWidth
                                name="firstName"
                                onChange={handleChangeUserData}
                            />
                            <TextField
                                sx={{ mt: 4 }}
                                required
                                id="filled-required"
                                label="Last Name"
                                value={userData?.lastName}
                                variant="filled"
                                fullWidth
                                name="lastName"
                                onChange={handleChangeUserData}
                            />
                        </form>
                    </Box>
                )}
            </DialogContent>
            <DialogActions className={actionsClass}>
                <Stack direction="row" justifyContent="end" sx={{ width: '100%', gap: '20px' }}>
                    <Button
                        variant={'outlined'}
                        onClick={(): void => {
                            activeModalHandler('');
                            setUserData((prev) => ({
                                ...prev,
                                firstName: profileDetails?.data?.firstName || '',
                                lastName: profileDetails?.data?.lastName || '',
                            }));
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={'contained'}
                        disabled={
                            userData?.firstName === '' ||
                            userData?.lastName === '' ||
                            !userData?.firstName ||
                            !userData?.lastName ||
                            !handleWhiteSpaces([userData?.firstName, userData?.lastName]) ||
                            (profileDetails?.data?.firstName === userData?.firstName &&
                                profileDetails?.data?.lastName === userData?.lastName)
                        }
                        onClick={handleUpdateProfile}
                    >
                        Update Username
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
