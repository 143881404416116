import React, { useState } from 'react';
import { Box, AppBar, Button, Typography, Modal } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Assessment, List, Settings } from '@mui/icons-material';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useStyles } from './styles';
import { DocumentTab, SettingsTab, SummaryTab, TimelineTab } from './';
import { useLocation, useParams } from 'react-router-dom';
import { setCollapsibleHeaderDetails } from '@fiji/common/src/features/common/commonSlice';
import { useAppDispatch } from '@fiji/common/src/app/store';
import { useGetDeviceByIdQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { RefetchConfigOptions } from '@reduxjs/toolkit/dist/query/core/apiState';

const getActiveTab = (tab: string, activeTab: string): string => (activeTab === tab ? 'tab-active' : 'tab');

export const DeviceSummary = React.forwardRef((props: any, ref): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { deviceId } = useParams();
    const { state }: any = useLocation();
    const dispatch = useAppDispatch();
    const [activeTab, setActiveTab] = useState(state?.deviceDetailsTab ?? 'Summary');
    const [isSaveDiscardDisabled, setIsSaveDiscardDisabled] = useState(true);
    const [isDeviceGroupChanged, setIsDeviceGroupChanged] = useState(false);
    const [groupDeviceDetails, setGroupDeviceDetails] = useState({ nodeIds: '', key: '', title: '' });
    const settingsRef: any = React.useRef(null);
    const [deviceStatus, setDeviceStatus] = useState<{
        status: string;
        connectionStatus: string;
        outputOff: boolean;
    }>();
    const { data: deviceDetails } = useGetDeviceByIdQuery<{
        data: any;
        refetch: RefetchConfigOptions;
        isLoading: boolean;
        isFetching: boolean;
    }>(deviceId, {
        refetchOnMountOrArgChange: true,
        skip: !deviceId || deviceId === ':deviceId',
    });
    const [jumpToSummaryModal, setJumpToSummaryModal] = React.useState(false);

    React.useEffect(() => {
        if (deviceDetails) {
            setDeviceStatus((prev: any) => ({
                ...prev,
                status: deviceDetails?.data?.status,
                connectionStatus: deviceDetails?.data?.connectionStatus,
                outputOff: deviceDetails?.data?.outputOff,
            }));
        }
    }, [deviceDetails]);

    React.useEffect(() => {
        if (deviceId) setActiveTab(state?.deviceDetailsTab ?? 'Summary');
        setIsDeviceGroupChanged(false);
    }, [deviceId]);

    React.useEffect(() => {
        dispatch(
            setCollapsibleHeaderDetails({
                ...deviceDetails?.data,
                status: deviceStatus?.status,
                connectionStatus: deviceStatus?.connectionStatus,
                isLoading: !deviceStatus,
            })
        );
    }, [deviceStatus]);

    const discardStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        bgcolor: 'background.paper',
        border: 'none',
        borderRadius: '4px',
        boxShadow: 24,
    };

    const tabHandler = (tab: string): void => {
        if (isSaveDiscardDisabled) {
            setActiveTab(tab);
        } else if (!isSaveDiscardDisabled && tab !== activeTab) {
            settingsRef?.current?.handleOpenSaveModal(tab);
            setIsSaveDiscardDisabled(true);
        }
    };

    const discardHandler = (nodeIds: string, key?: string, title?: string): void => {
        setIsDeviceGroupChanged(true);
        setGroupDeviceDetails({
            nodeIds: nodeIds ?? '',
            key: key ?? '',
            title: title ?? '',
        });
        tabHandler('Summary');
    };

    React.useImperativeHandle(ref, () => ({
        handleDiscardChanges: discardHandler,
        isSaveDiscardDisabled: isSaveDiscardDisabled,
    }));

    return (
        <React.Fragment>
            <header>
                <title>Device Summary</title>
                <meta name="description" content="Description of Device Summary" />
            </header>
            <AppBar className="tab-header" position="static" color="primary">
                <Tabs id={'tabs'} className="custom-tab-wrapper">
                    <Tab
                        onClick={(): void => tabHandler('Summary')}
                        label="Summary"
                        className={getActiveTab(activeTab, 'Summary')}
                        icon={<Assessment />}
                        iconPosition="start"
                    />
                    <Tab
                        onClick={(): void => tabHandler('Timeline')}
                        label="Timeline"
                        className={getActiveTab(activeTab, 'Timeline')}
                        icon={<List />}
                        iconPosition="start"
                    />
                    <Tab
                        onClick={(): void => tabHandler('Documents')}
                        label="Documents"
                        className={getActiveTab(activeTab, 'Documents')}
                        icon={<TextSnippetIcon />}
                        iconPosition="start"
                    />
                    <Tab
                        onClick={(): void => tabHandler('Settings')}
                        label="Settings"
                        className={getActiveTab(activeTab, 'Settings')}
                        icon={<Settings />}
                        iconPosition="start"
                    />
                </Tabs>
            </AppBar>
            <Box className={classes.ContainerWrapper}>
                {activeTab === 'Summary' && (
                    <SummaryTab
                        setActiveTab={setActiveTab}
                        setDeviceStatus={setDeviceStatus}
                        deviceStatus={deviceStatus}
                    />
                )}
                {activeTab === 'Timeline' && <TimelineTab />}
                {activeTab === 'Documents' && <DocumentTab />}
                {activeTab === 'Settings' && (
                    <SettingsTab
                        ref={settingsRef}
                        setIsSaveDiscardDisabled={setIsSaveDiscardDisabled}
                        setActiveTab={setActiveTab}
                        groupDeviceDetails={groupDeviceDetails}
                        isDeviceGroupChanged={isDeviceGroupChanged}
                    />
                )}
            </Box>

            <Box>
                <Modal
                    open={jumpToSummaryModal}
                    onClose={(): void => setJumpToSummaryModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={discardStyle}>
                        <Box sx={{ padding: '30px' }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Save Changes?
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '30px',
                                borderTop: '1px solid #f2f2f2',
                            }}
                        >
                            <Button variant="outlined" onClick={(): void => setJumpToSummaryModal(false)}>
                                Cancel
                            </Button>
                            <Button variant="outlined">Discard</Button>
                            <Button variant="contained" color="primary">
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </Box>
        </React.Fragment>
    );
});
