import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Stack,
    Select,
    InputLabel,
    FormControl,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { Group } from '@fiji/common/src/types';
import Hierarchy from '../../components/Hierarchy';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { useStyles } from './styles';
import { useGetHierarchyHandlers } from '../../hooks';
import { useMoveExistingGroupMutation } from '@fiji/common/src/features/group/groupApi';
import { deleteGroupModal, selectHierarchyData } from '@fiji/common/src/features/group/groupSlice';

/**
 * The ModalProps type is used to define the props for a modal component in a TypeScript React
 * application.
 * @property {boolean} isOpen - A boolean value indicating whether the modal is open or closed.
 * @property closeHandler - The `closeHandler` property is a function that takes an optional boolean
 * argument and does not return anything (`void`). It is used to handle the closing of the modal.
 * @property {Group[]} groupChildrens - An array of Group objects.
 * @property {string} groupId - A string representing the ID of the group.
 */
type ModalProps = {
    isOpen: boolean;
    closeHandler: (args1?: boolean) => void;
    groupChildrens: Group[];
    groupId: string;
    devices: any[];
};

export const MoveChildrenDeleteModal = (props: ModalProps): JSX.Element => {
    const [openSelectBox, setOpenSelectBox] = useState(false);

    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();

    const [moveExistingGroup] = useMoveExistingGroupMutation();

    const hierarchyData = useTypedSelector(selectHierarchyData);

    const {
        groupSelectionHandler: setSelectedParentId,
        selectedGroups: selectedParentId,
        getSelectedGroupLabel,
    } = useGetHierarchyHandlers({
        valueType: 'string',
        initialValue: hierarchyData?.data?.data?.data[0],
    });

    /* The `useEffect` hook is used to perform side effects in a React component. In this case, the
    effect is triggered whenever the `props.groupId` or `groupsHierarchy` values change. */
    useEffect(() => {
        if (props.groupId) {
            const parentGroupId = getParentId(props.groupId, hierarchyData?.data?.data?.data ?? []);
            setSelectedParentId(parentGroupId);
        }
    }, [props.groupId, hierarchyData?.data]);

    /**
     * The function `handleToggleSelectBox` toggles the state of `openSelectBox` between `true` and
     * `false`.
     */
    const handleToggleSelectBox = (): void => {
        setOpenSelectBox((prev) => !prev);
    };

    /**
     * The function `handleGroupSelect` sets the selected parent ID to the provided node ID and closes
     * the select box.
     * @param {string} nodeId - The `nodeId` parameter is a string that represents the ID of the
     * selected node in a group.
     */
    const handleGroupSelect = (nodeId: string): void => {
        setSelectedParentId(nodeId);
        setOpenSelectBox(false);
    };

    /**
     * The function `handleMoveChildrenToGroup` moves the children of a group to a new parent group and
     * then deletes the original group.
     */
    const handleMoveChildrenToGroup = async (): Promise<void> => {
        const { error }: any = await moveExistingGroup({
            ...(props.groupChildrens?.length && { groupIds: props.groupChildrens.map((group) => group?.id) }),
            ...(props?.devices.length && { deviceIds: props?.devices?.map((device) => device?.id) }),
            destinationGroupId: selectedParentId,
        });
        if (!error) {
            dispatch(deleteGroupModal({ isOpen: true, groupId: props.groupId }));
            props.closeHandler(true);
        }
    };

    /**
     * The function `getParentId` takes a childId and an array of groups, and returns the id of the
     * parent group that contains the child with the given id.
     * @param {string} childId - The `childId` parameter is a string that represents the ID of a child
     * group.
     * @param {any} groupsArray - The `groupsArray` parameter is an array of objects representing
     * groups. Each group object has a property `id` which is a string, and a property `children` which
     * is an array of objects representing child groups. Each child group object also has an `id`
     * property which is a string.
     * @returns a string value.
     */
    const getParentId = (childId: string, groupsArray: any): string => {
        for (const groupItem of groupsArray) {
            if (groupItem?.children?.filter((childGroup: Group) => childGroup.id === childId)) {
                return groupItem.id;
            }
        }
        return '';
    };

    return (
        <>
            <Dialog
                open={props.isOpen}
                onClose={(): void => props.closeHandler()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.rootMuiDialogpaper}
            >
                <DialogTitle id="alert-dialog-title" className={classes.MuiDialogTitleRoot}>
                    <Typography variant={'h6'}> {'Move Children to Another Group'}</Typography>
                </DialogTitle>
                <DialogContent>
                    <FormControl variant={'filled'} className={classes.textField} required fullWidth>
                        <InputLabel id="demo-simple-select-autowidth-label">Parent Group</InputLabel>
                        <Select
                            displayEmpty
                            labelId="demo-customized-select-label"
                            id="demo-customized-select"
                            disabled={hierarchyData?.isLoading}
                            renderValue={(): any => getSelectedGroupLabel(hierarchyData?.data?.data?.data)}
                            value={getSelectedGroupLabel(hierarchyData?.data?.data?.data) ? ' ' : ''}
                            open={openSelectBox}
                            onClose={handleToggleSelectBox}
                            onOpen={handleToggleSelectBox}
                        >
                            <Hierarchy
                                selectedNodes={selectedParentId}
                                onChangeTreeItem={handleGroupSelect}
                                hierarchyData={hierarchyData?.data?.data?.data}
                                customClass="parent-move-group-height"
                                isOverflow={true}
                                filter={{
                                    key: 'id',
                                    value: props.groupId,
                                    operator: '!==',
                                    iterator: 'children',
                                    skipChildrens: true,
                                }}
                            />
                        </Select>
                    </FormControl>
                    {props.groupChildrens?.length > 0 &&
                        props.groupChildrens.map((children) => (
                            <InfoListItem
                                key={children?.id}
                                className={classes.infoListItem}
                                data-testid="infoListItem"
                                data-cy={'list-content'}
                                title={children?.name}
                                subtitle={`${children?.groupCount || 0} Groups, ${children?.deviceCount || 0} Devices`}
                                avatar={false}
                                hidePadding
                            />
                        ))}
                </DialogContent>
                <DialogActions className={classes.MuiDialogActionsRoot}>
                    <Stack direction={'row'} spacing={2} justifyContent={'end'}>
                        <Button variant={'outlined'} onClick={(): void => props.closeHandler()}>
                            Cancel
                        </Button>
                        <Button variant={'contained'} disabled={!selectedParentId} onClick={handleMoveChildrenToGroup}>
                            Move Children
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
};
