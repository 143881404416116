import React from 'react';
import { createNumArray } from '../../CommonUtils';
import { Paper, Box, Skeleton, Typography, Switch, Divider } from '@mui/material';

export const NotificationSkeleton = ({ count }: { count?: number }): JSX.Element => (
    <>
        {createNumArray(count ?? 4).map((item) => (
            <Paper className="paper-margin-16" key={`unique${item}`}>
                {createNumArray(4).map((rowItem, index) => (
                    <Box
                        className="modal-space-between"
                        sx={{
                            gap: '16px',
                            padding: '16px',
                        }}
                        key={`unique${rowItem}`}
                    >
                        <Box className="modal-align-center">
                            <Skeleton
                                animation="wave"
                                variant="circular"
                                width={40}
                                height={40}
                                sx={{ marginRight: '22px', marginLeft: '10px', color: '#727E84' }}
                            />
                            <Box>
                                <Skeleton animation="wave">
                                    <Typography variant="body1" className="modal-greyhead-text">
                                        Loading Title
                                    </Typography>
                                </Skeleton>
                                <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }}>
                                    <Typography variant="body2" className="modal-para">
                                        Loading Description
                                    </Typography>
                                </Skeleton>
                            </Box>
                        </Box>
                        {index !== 0 && (
                            <Skeleton animation="wave">
                                <Switch />
                            </Skeleton>
                        )}
                    </Box>
                ))}
                {Boolean(count && count > 1) && <Divider />}
            </Paper>
        ))}
    </>
);
