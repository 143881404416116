import { api } from '../../app/api/baseApi';

export const tagApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getTags: builder.query<any, { maxVar: string; offset: string }>({
            query: (arg) => {
                const { maxVar, offset } = arg;
                return {
                    url: `/dm/v1/tags?max=${maxVar}&offset=${offset}`,
                    method: 'GET',
                };
            },
        }),
        createTag: builder.mutation({
            query: (body) => ({
                url: `/dm/v1/tags`,
                method: 'POST',
                body,
            }),
        }),
        deleteTag: builder.mutation({
            query: ({ tagId }: { tagId: string }) => ({
                url: `/dm/v1/tags/${tagId}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const { useGetTagsQuery, useCreateTagMutation, useDeleteTagMutation, useLazyGetTagsQuery } = tagApi;
