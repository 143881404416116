import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Typography,
} from '@mui/material';
import React from 'react';

type ModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    clickHandler: () => Promise<void>;
};

export const ResetNotificationsModal = ({ isOpen, closeModal, clickHandler }: ModalProps): JSX.Element => (
    <Dialog
        open={isOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modal-reset-notification"
    >
        <DialogTitle id="alert-dialog-title">
            <Typography variant="body1" className="modal-title">
                Reset Notification Preferences?
            </Typography>
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <Typography variant="body2" className="modal-para2">
                    Are you sure you want to reset your
                    <br /> notification preferences? This does not apply
                    <br /> to Custom Notifications.
                </Typography>
            </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', p: 2 }}>
            <Button
                variant="outlined"
                onClick={closeModal}
                sx={{ borderColor: 'rgba(66, 78, 84, 0.12)', mr: 1 }}
                className="modal-blue-header"
            >
                Cancel
            </Button>
            <Button
                variant="contained"
                sx={{
                    backgroundColor: '#BD2E2F',
                    color: '#fff',
                    fontSize: '14px',
                    fontWeight: '600',
                }}
                autoFocus
                onClick={clickHandler}
            >
                Reset Notifications
            </Button>
        </DialogActions>
    </Dialog>
);
