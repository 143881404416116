import React, { useEffect, useState } from 'react';
import { ProjectRegistrationUIActions } from '../../store/actions/RegistrationUIActions';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    FormControlLabel,
    SxProps,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import DOMPurify from 'dompurify';
import { useUpdateUserProfileMutation } from '@fiji/common/src/features/userManagement/userManagement';
import { useNavigate } from 'react-router-dom';
import { toggleLoader } from '@fiji/common/src/features/common/commonSlice';
import { useAppDispatch } from '@fiji/common/src/app/store';

const DialogTitleStyles = (theme: Theme): SxProps<Theme> => ({
    p: `${theme.spacing(4)} ${theme.spacing(3)} 0 ${theme.spacing(3)}`,
    [theme.breakpoints.down('sm')]: {
        p: `${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
    },
});

const DialogContentStyles = (theme: Theme): SxProps<Theme> => ({
    flex: '1 1 0px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    p: `${theme.spacing(2)} ${theme.spacing(3)}`,
    [theme.breakpoints.down('sm')]: {
        p: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    },
});

const DialogActionsStyles = (theme: Theme): SxProps<Theme> => ({
    p: 3,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
        p: 2,
    },
});

export const AcceptEULA = (): JSX.Element => {
    const theme = useTheme();
    const { loadEULA } = ProjectRegistrationUIActions();
    const [eulaContent, setEulaContent] = useState('');
    const [eulaAccepted, setEulaAccepted] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [updateUserProfile, { isLoading: editUserLoader }] = useUpdateUserProfileMutation();

    useEffect(() => {
        loadEULA('en').then((eula) => setEulaContent(eula)) as any;
    }, []);

    const handleAcceptEula = async (): Promise<void> => {
        const { error }: any = await updateUserProfile({ licenseAccepted: true });
        if (!error) {
            navigate('/');
        }
    };

    useEffect(() => {
        dispatch(toggleLoader(editUserLoader));
    }, [editUserLoader]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
            <Card
                sx={{
                    width: '100%',
                    height: '100%',
                    maxWidth: '450px',
                    maxHeight: '730px',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    [theme.breakpoints.down('sm')]: {
                        maxWidth: 'none',
                        maxHeight: 'none',
                        borderRadius: 0,
                    },
                }}
            >
                <CardHeader
                    title={
                        <Typography variant={'h6'} sx={{ fontWeight: 600 }}>
                            License Agreement
                        </Typography>
                    }
                    sx={DialogTitleStyles(theme)}
                />
                <CardContent sx={DialogContentStyles(theme)}>
                    <Box
                        sx={{ flex: '1 1 0px', overflow: 'auto' }}
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(eulaContent) }}
                    ></Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color={'primary'}
                                checked={eulaAccepted}
                                disabled={!eulaContent}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                    setEulaAccepted(event.target.checked)
                                }
                            />
                        }
                        label={'I have read and agree to the Terms & Conditions'}
                        sx={{ flex: '0 0 auto', mr: 0, mt: 2 }}
                    />
                </CardContent>
                <Divider />
                <CardActions sx={DialogActionsStyles(theme)}>
                    <Button variant="contained" disabled={!eulaAccepted} onClick={handleAcceptEula}>
                        Accept
                    </Button>
                </CardActions>
            </Card>
        </Box>
    );
};
