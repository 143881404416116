import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import * as Colors from '@brightlayer-ui/colors';

export const useStyles = makeStyles((theme: Theme) => ({
    containerWrapper: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0),
            paddingTop: theme.spacing(2),
        },
    },
    tableHeadCell: {
        border: '0px',
        backgroundColor: '#fff',
    },
    tableBodyCell: {
        border: '0px',
    },
    tableBodyCellStatus: {
        display: 'flex',
        justifyContent: 'center',
    },
    muiDialogpaper: {
        '& .MuiPaper-root': {
            width: '450px',
        },
    },
    MuiDialogTitleRoot: {},
    MuiDialogActionsRoot: {
        padding: '24px',
        borderTop: '1px solid rgba(66, 78, 84, 0.12)',
    },
    infoListItem: {
        height: '100%',
        paddingLeft: '0px',
        '& .BluiInfoListItem-divider': {
            marginLeft: '-24px',
        },
    },
    disabled: {
        opacity: '0.5',
        pointerEvents: 'none',
        cursor: 'not-allowed',
    },
    infoListItemSeattleDatacenter: {
        background: Colors.white[200],
        '&:first-child': {
            borderRadius: '5px 5px 0px 0px',
        },
        '&:last-child': {
            borderRadius: '0px 0px 5px 5px',
        },
    },
    infoListItemPrimary: {
        '& .MuiListItemText-primary': {
            color: Colors.gray[800],
            fontWeight: 600,
        },
    },
    hevronRight: {
        color: Colors.black[300],
    },
    arrowDropDown: {
        color: Colors.gray[500],
    },
    MultiSelectDropDown: {
        color: Colors.gray[500],
        width: '1em !important',
        height: '1em !important',
    },
    detailsIconGroup: {
        justifyContent: 'center',
        color: Colors.gray[500],
    },
    emptyStateWrapper: {
        height: 'calc(100vh - 136px)',
        minHeight: 'auto',
    },
    subHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        background: Colors.white[50],
        minHeight: '48px',
    },
    tabsContent: {
        height: '48px',
        width: '100%',
    },
    tab: {
        color: Colors.black[500],
        textTransform: 'capitalize',
        minHeight: '48px',
        fontWeight: 400,
        flexBasis: '20%',
        maxWidth: '20%',
        opacity: 1,
        '& .MuiSvgIcon-root': {
            color: '#727e84',
        },
    },
    tabActive: {
        color: Colors.blue[500],
        minHeight: '48px',
        fontWeight: 600,
        textTransform: 'capitalize',
        flexBasis: '20%',
        maxWidth: '20%',
        opacity: 1,
        '&::after': {
            content: '""',
            position: 'absolute',
            opacity: 1,
            height: '2px',
            bottom: '0px',
            width: '100%',
            transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            background: Colors.blue[500],
        },
    },
    menuList: {
        minWidth: '260px',
        top: '100px',
    },
    menuItem: {
        marginRight: '24px',
        color: Colors.gray[500],
    },
    searchTextField: {
        '& .MuiInputBase-root': {
            borderRadius: '30px',
        },
    },
    headerSearchTextField: {
        '& .MuiInputBase-root': {
            borderRadius: '5px',
        },
    },
    textField: {
        marginBottom: '48px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(4),
        },
    },
    deleteButton: {
        background: Colors.red[600],
        '&:hover': {
            backgroundColor: Colors.red[400],
        },
        '&.Mui-disabled': {
            backgroundColor: '#F9E8E8',
            color: '#E59E9E',
        },
    },
    avatarIcon: {
        height: '80px',
        width: '80px',
        backgroundColor: Colors.blue[500],
        '& .MuiSvgIcon-root': {
            height: '60px',
            width: '60px',
            color: Colors.white[50],
        },
    },
    avatarBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(4),
    },
    filterItem: {
        backgroundColor: '#fff !important',
        '&:hover': {
            backgroundColor: '#fff !important',
        },
        '&.active': {
            backgroundColor: '#fff !important',
        },
        '&$selected': {
            backgroundColor: '#fff !important',
        },
    },
}));
