import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import TreeItem, { useTreeItem } from '@mui/lab/TreeItem';
import { Checkbox, Radio, Typography } from '@mui/material';
import { Check, ChevronRight, ExpandMore } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material/styles';
import * as Colors from '@brightlayer-ui/colors';
import TreeView from '@mui/lab/TreeView/TreeView';
import Loader from './Loader';
import { useGetHierarchyHandlers } from '../hooks';

const useStyles = makeStyles((theme: Theme) => ({
    textField: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(4),
        },
    },
    muiMenuItem: {
        width: '100%',
        background: Colors.white[50],
        '& .MuiTreeItem-content': {
            height: 48,
            borderBottom: '1px solid rgba(66, 78, 84, 0.12)',
            '& .MuiTreeItem-iconContainer:first-child': {
                width: '0px',
            },

            '& .MuiTreeItem-label ': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: Colors.black[500],
                fontWeight: 600,
                fontSize: '14px',
                padding: '10px 8px 10px 10px',
            },
        },
    },
    expandIcon: {
        marginLeft: theme.spacing(1),
        color: Colors.gray[500],
        fontSize: '24px !important',
    },
    disableTreeItem: {
        pointerEvents: 'none',
        opacity: 0.5,
    },
}));

/**
 * The above type represents a filter used for hierarchical data with various properties and options.
 * @property {string} key - The key property represents the attribute or property of an object that you
 * want to filter or apply a condition on.
 * @property {any} value - The `value` property in the `HierarchyFilter` type represents the value that
 * will be used for filtering. It can be of any type, depending on the specific use case.
 * @property {string} operator - The `operator` property in the `HierarchyFilter` type specifies the
 * comparison operator to be used when filtering the hierarchy. It can be any valid comparison operator
 * such as `==`, `!=`, `>`, `<`, `>=`, `<=`, `in`, `not in`, `contains`,
 * @property {string} iterator - The `iterator` property is an optional property in the
 * `HierarchyFilter` type. It is used to specify the type of iterator to be used when filtering through
 * a hierarchy. The iterator can be any string value that represents the type of iterator to be used,
 * such as "preorder", "post
 * @property {boolean} skipChildrens - The `skipChildrens` property is a boolean value that determines
 * whether to skip filtering on the children of the current hierarchy level. If set to `true`, the
 * filter will only be applied to the current level and not to any nested levels. If set to `false` or
 * not provided, the
 * @property {'disable' | 'hidden'} action - The "action" property specifies the action to be taken on
 * the filtered items. It can have two possible values: "disable" or "hidden".
 */
type HierarchyFilter = {
    key: string;
    value: any;
    operator: string;
    iterator?: string;
    skipChildrens?: boolean;
    action?: 'disable' | 'hidden' | 'disableCheck';
};

const getLabelColor = (selectedNodes: any, nodeId: string, treeItem: any, actions: any): string => {
    if (selectedNodes === nodeId) {
        if (treeItem?.stats?.alarms && actions) {
            return '#CA3C3D !important';
        }
        return `${Colors.blue[500]} !important`;
    }
    return '';
};

/**
 * The above type is a TypeScript interface for a React component that represents a hierarchical data
 * structure with various optional properties and callbacks.
 * @property {any} selectedNodes - An array of selected node IDs in the hierarchy.
 * @property {string} customClass - A string that allows you to add custom CSS classes to the hierarchy
 * component.
 * @property {boolean} isOverflow - A boolean value indicating whether the hierarchy tree should have
 * an overflow behavior when the content exceeds the available space.
 * @property {any} expandedNodes - An array that stores the IDs of the nodes that are currently
 * expanded in the hierarchy.
 * @property onChangeTreeItem - The `onChangeTreeItem` property is a function that is called when a
 * tree item is changed. It takes four parameters: `nodeIds` (the selected node ids), `key` (optional
 * key for the selected node), `type` (optional type for the selected node), and `isChecked
 * @property {any[]} hierarchyData - It is an array that contains the hierarchical data for the
 * hierarchy component. Each item in the array represents a node in the hierarchy.
 * @property {boolean} isMultiSelect - A boolean value indicating whether multiple nodes can be
 * selected at once in the hierarchy.
 * @property actions - A function that takes a nodeId and treeItem as arguments and returns a JSX
 * element. This element will be rendered as actions for each node in the hierarchy.
 * @property {boolean} disableSelect - A boolean value that determines whether the user can select
 * nodes in the hierarchy. If set to true, the nodes will be disabled for selection.
 * @property filter - The `filter` property is an object that allows you to specify a filter for the
 * hierarchy data. It has the following properties:
 * @property noDataFoundComponent - A JSX element that will be rendered when there is no data available
 * to display in the hierarchy.
 * @property isGroupEmpty - A function that takes a boolean parameter indicating whether the group is
 * empty or not.
 * @property isDeviceEmpty - The `isDeviceEmpty` property is a function that takes a boolean parameter
 * `isDeviceEmpty` and is used to determine if the device group is empty. It can be used to display a
 * message or perform any other action based on whether the device group is empty or not.
 * @property {string[]} customIterator - The `customIterator` property is an optional array of strings
 * that specifies the custom iterator keys for rendering the hierarchy data. Each string in the array
 * represents a key in the hierarchy data object that should be used as an iterator for rendering the
 * hierarchy.
 * @property {string[]} iconKey - The `iconKey` property is a string array that specifies the keys of
 * the icons to be rendered for each node in the hierarchy. Each key corresponds to a specific icon
 * that represents a particular type of node.
 * @property renderIcon - A function that takes an optional argument (args1) of type string and returns
 * a JSX.Element. This function is used to render custom icons for the hierarchy nodes.
 * @property {string} multiSelectionKey - A string that represents the key used for multi-selection in
 * the hierarchy.
 * @property onExpandNodes - A function that is called when the user expands or collapses a node in the
 * hierarchy. It takes an array of nodeIds as a parameter.
 * @property {boolean} isLoading - A boolean value indicating whether the hierarchy data is currently
 * being loaded or not.
 */
type HierarchyType = {
    selectedNodes?: any;
    customClass?: string;
    isOverflow?: boolean;
    expandedNodes?: any;
    onChangeTreeItem?: (nodeIds: any, key?: string, type?: string, isChecked?: boolean) => void;
    hierarchyData: any[];
    isMultiSelect?: boolean;
    actions?: (nodeId: any, treeItem?: any, expandedItems?: any) => JSX.Element;
    disableSelect?: boolean;
    filter?: any;
    noDataFoundComponent?: JSX.Element;
    isGroupEmpty?: (isGroupEmpty: boolean) => void;
    isDeviceEmpty?: (isDeviceEmpty: boolean) => void;
    customIterator?: string[];
    iconKey?: string[];
    renderIcon?: (args1?: string, args2?: string) => JSX.Element;
    multiSelectionKey?: string | string[];
    onExpandNodes?: (nodeIds: string[]) => void;
    isLoading?: boolean;
    radioSelection?: boolean;
};

/* The below code is a TypeScript React component called `CustomContent`. It is a custom implementation
of a tree item component. */
const CustomContent = React.forwardRef((props: any, ref) => {
    const {
        classes: customTreeItemClasses,
        className,
        label,
        nodeId,
        icon: iconProp,
        expansionIcon,
        displayIcon,
        iterator,
        multiSelectionKey,
        renderIcon,
        iconKey,
        actions,
        disableSelect,
        isMultiSelect,
        handleSelectNodes,
        selectedNodes,
        treeItem,
        radioSelection,
        isDeviceAutoSelected,
        filter,
        expandedItems,
    } = props;

    const { findGroupOrDeviceById } = useGetHierarchyHandlers({});

    /* The below code is using the `useTreeItem` hook to destructure and assign values to the variables
    `disabled`, `expanded`, `selected`, `focused`, `handleExpansion`, `handleSelection`, and
    `preventSelection`. These variables are likely used to manage the state and behavior of a tree
    item in a React component. */
    const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection } =
        useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    /**
     * The function "handleMouseDown" prevents the default selection behavior when a mouse button is
     * pressed.
     * @param {any} event - The event parameter is an object that represents the event that occurred,
     * such as a mouse click or touch event. It contains information about the event, such as the
     * target element and the coordinates of the event.
     */
    const handleMouseDown = (event: any): void => {
        preventSelection(event);
    };

    /**
     * The function "handleExpansionClick" calls another function "handleExpansion" with the event
     * parameter.
     * @param {any} event - The event parameter is an object that represents the event that triggered
     * the click. It contains information about the event, such as the target element, the type of
     * event, and any additional data associated with the event.
     */
    const handleExpansionClick = (event: any): void => {
        handleExpansion(event);
    };

    /**
     * The function "handleSelectionClick" calls another function "handleSelection" with the event
     * parameter.
     * @param {any} event - The event parameter is an object that represents the event that occurred,
     * such as a mouse click or a keyboard press. It contains information about the event, such as the
     * target element that triggered the event.
     */
    const handleSelectionClick = (event: any): void => {
        handleSelection(event);
    };

    const disableCheckExists = Array.isArray(filter)
        ? filter.find((item: any) => item?.action === 'disableCheck')
        : filter?.action === 'disableCheck' && filter;

    return (
        <div
            className={clsx(className, customTreeItemClasses.root, {
                [customTreeItemClasses.expanded]: expanded,
                [customTreeItemClasses.selected]: selected,
                [customTreeItemClasses.focused]: focused,
                [customTreeItemClasses.disabled]: disabled,
            })}
            onMouseDown={handleMouseDown}
            ref={ref as React.Ref<HTMLDivElement>}
        >
            <div
                onClick={handleExpansionClick}
                className={`${customTreeItemClasses.iconContainer} ${selectedNodes === nodeId ? 'blueIconColor' : ''}`}
            >
                {icon}
            </div>
            {iconKey?.includes(iterator) && renderIcon && renderIcon(iterator, nodeId)}
            <Typography
                variant={'body2'}
                onClick={handleSelectionClick}
                component="div"
                className={`${customTreeItemClasses.label} white-space-pre`}
                id={iterator}
                sx={{
                    marginLeft: '10px',
                    color: getLabelColor(selectedNodes, nodeId, treeItem, actions),
                }}
            >
                <Typography
                    variant={'body2'}
                    id={iterator}
                    sx={{
                        width: '75%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: 600,
                    }}
                >
                    {label}
                </Typography>
                {iterator !== 'devices' && actions && actions(nodeId, treeItem, expandedItems)}
                {!isMultiSelect &&
                    !disableSelect &&
                    (radioSelection ? (
                        <Radio
                            checked={selectedNodes === nodeId}
                            id={iterator}
                            value={nodeId}
                            {...(!disabled && { onChange: (e): void => handleSelectNodes(e, nodeId) })}
                        />
                    ) : (
                        selectedNodes === nodeId && <Check className="checked-icon" />
                    ))}
                {isMultiSelect &&
                    (iterator === multiSelectionKey || multiSelectionKey.includes(iterator)) &&
                    (!disableCheckExists ||
                        (disableCheckExists && !findGroupOrDeviceById([treeItem], disableCheckExists?.value))) && (
                        <Checkbox
                            checked={selectedNodes?.includes(nodeId) || isDeviceAutoSelected}
                            name={nodeId}
                            {...(!disabled && { onChange: (e): void => handleSelectNodes(e, nodeId, iterator) })}
                            onClick={(e: any): void => e.stopPropagation()}
                            disabled={isDeviceAutoSelected}
                        />
                    )}
            </Typography>
        </div>
    );
});

/* The below code is defining a functional component called `CustomTreeItem` in TypeScript React. This
component is rendering a `TreeItem` component with custom props. The `TreeItem` component is a part
of a tree structure and is used to display hierarchical data. The `CustomTreeItem` component is
passing the `ContentComponent` prop as `CustomContent` and the `ContentProps` prop as
`props.ContentProps` to the `TreeItem` component. */
const CustomTreeItem = (props: any): JSX.Element => (
    <TreeItem ContentComponent={CustomContent} ContentProps={props.ContentProps} {...props} />
);

/* The below code is a TypeScript React component called "Hierarchy". It renders a hierarchical tree
structure based on the provided data. The component accepts various props such as selectedNodes,
expandedNodes, onChangeTreeItem, hierarchyData, isMultiSelect, disableSelect, customClass,
isOverflow, actions, filter, noDataFoundComponent, isEmpty, customIterator, iconKey, renderIcon,
multiSelectionKey, onExpandNodes, and isLoading. */
const Hierarchy = ({
    selectedNodes,
    expandedNodes,
    onChangeTreeItem,
    hierarchyData,
    isMultiSelect,
    disableSelect,
    customClass,
    isOverflow,
    actions,
    filter,
    noDataFoundComponent,
    isGroupEmpty,
    isDeviceEmpty,
    customIterator = ['children'],
    iconKey,
    renderIcon,
    multiSelectionKey,
    onExpandNodes,
    isLoading,
    radioSelection,
}: HierarchyType): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const [expandedItems, setExpandedItems] = useState<string[]>([]);

    /**
     * The function `handleSelectNodes` handles the selection of nodes in a tree, either allowing
     * multiple selections or a single selection.
     * @param {any} event - The event parameter is an object that represents the event that triggered
     * the function. It contains information about the event, such as the target element and any
     * additional data associated with the event.
     * @param {any} nodeIds - An array of node IDs representing the selected nodes in the tree.
     */
    const handleSelectNodes = (event: any, nodeIds: any, iterator?: string): void => {
        if (onChangeTreeItem) {
            if (isMultiSelect) {
                const targetId = event.target.name;
                onChangeTreeItem(targetId, iterator, 'multiSelect', event.target.checked);
            } else {
                onChangeTreeItem(nodeIds, event.target.id);
            }
        }
    };

    /**
     * The function `handleExpandNodes` sets the expanded items in state and calls a callback function
     * if provided.
     * @param {any} event - The event parameter is an object that represents the event that triggered
     * the function. It can contain information about the event, such as the type of event and any data
     * associated with it.
     * @param {string[]} nodeIds - An array of string values representing the IDs of the nodes that
     * need to be expanded.
     */
    const handleExpandNodes = (event: any, nodeIds: string[]): void => {
        event.stopPropagation();
        setExpandedItems(nodeIds);
        window.dispatchEvent(new Event('resize'));
        if (onExpandNodes) onExpandNodes(nodeIds);
    };

    /**
     * The function `getFilterExpression` checks if a given tree item matches a filter expression based
     * on a key and operator.
     * @param {any} treeItem - The `treeItem` parameter is an object representing a node in a tree
     * structure.
     * @param {string} key - The `key` parameter is a string that represents the property key of the
     * `treeItem` object that needs to be filtered.
     * @returns The function `getFilterExpression` returns a boolean value.
     */
    const getFilterExpression = (treeItem: any, key: string, filterResults?: HierarchyFilter): boolean => {
        if (key !== filterResults?.iterator) {
            return true;
        }
        switch (filterResults?.operator) {
            case '==': {
                // eslint-disable-next-line eqeqeq
                return treeItem[filterResults?.key] == filterResults?.value;
            }
            case '===': {
                return treeItem[filterResults?.key] === filterResults?.value;
            }
            case '!=': {
                // eslint-disable-next-line eqeqeq
                return treeItem[filterResults?.key] != filterResults?.value;
            }
            case '!==': {
                return treeItem[filterResults?.key] !== filterResults?.value;
            }
            case '>': {
                return treeItem[filterResults?.key] > filterResults?.value;
            }
            case '<': {
                return treeItem[filterResults?.key] < filterResults?.value;
            }
            case '>=': {
                return treeItem[filterResults?.key] >= filterResults?.value;
            }
            case '<=': {
                return treeItem[filterResults?.key] <= filterResults?.value;
            }
            default: {
                return true;
            }
        }
    };

    /**
     * The function `createGroupNodes` recursively creates a tree of custom tree items based on the
     * provided tree nodes, with the option to skip creating child nodes.
     * @param {any} treeNodes - An array of tree nodes that need to be processed.
     * @param {string} key - The `key` parameter is a string that represents the property name of the
     * tree node that is used to group the nodes. It is used to determine which nodes should be grouped
     * together.
     * @param {boolean} [skipChildrens] - A boolean flag indicating whether to skip processing the
     * children nodes of each tree item. If set to true, the function will only create group nodes for
     * the current level and will not recursively process the children nodes. If not provided or set to
     * false, the function will create group nodes for the current level as
     */

    const applyFilters = (treeItem: any, key: string): any => {
        let filterResults: any;
        let filterIndex = -1;
        let skip = false;
        if (Array.isArray(filter) && typeof filter === 'object') {
            const appliedFilters = filter
                .map((item) => {
                    if (item?.action !== 'disableCheck') {
                        return getFilterExpression(treeItem, key, item);
                    }
                })
                .filter((item) => item !== undefined);
            filterResults = appliedFilters.every((item) => item === true);
            if (!filterResults) {
                filterIndex = appliedFilters.findIndex((item) => item === false);
                if (filterIndex !== -1) {
                    skip = Boolean(filter?.[filterIndex]?.skipChildrens);
                }
            }
        } else {
            filterResults = getFilterExpression(treeItem, key, filter);
            skip = Boolean(filter?.skipChildrens);
        }
        return { filterResults, filterIndex, skip };
    };

    const createGroupNodes = (treeNodes: any, key: string, skipChildrens?: boolean, source?: any): any[] =>
        treeNodes?.map((treeItem: any) => {
            const childNodes = customIterator?.map(
                (iterator) =>
                    treeItem[iterator]?.length > 0 &&
                    createGroupNodes(treeItem[iterator], iterator, skipChildrens, treeItem)
            );

            const { filterResults, filterIndex, skip } = applyFilters(treeItem, key);

            return filterResults ||
                (Array.isArray(filter) &&
                    filterIndex !== -1 &&
                    (filter?.[filterIndex]?.action === 'disable' ||
                        filter?.[filterIndex]?.action === 'disableCheck')) ? (
                <CustomTreeItem
                    key={treeItem?.id?.toString()}
                    nodeId={treeItem?.id?.toString()}
                    label={treeItem.name}
                    className={classes.muiMenuItem}
                    iterator={key}
                    ContentProps={{
                        iterator: key,
                        multiSelectionKey,
                        renderIcon,
                        iconKey,
                        actions,
                        disableSelect,
                        isMultiSelect,
                        handleSelectNodes,
                        selectedNodes,
                        treeItem,
                        radioSelection,
                        isDeviceAutoSelected: key === 'devices' && selectedNodes.includes(source?.id),
                        filter,
                        expandedItems,
                    }}
                    disabled={!filterResults}
                >
                    {childNodes?.length > 0 && !childNodes?.every((item) => item === false) && childNodes}
                </CustomTreeItem>
            ) : (
                !skip &&
                    customIterator?.map(
                        (iterator) =>
                            treeItem[iterator]?.length > 0 &&
                            createGroupNodes(treeItem[iterator], iterator, skip, treeItem)
                    )
            );
        });

    /* The below code is using the useEffect hook in a React component. It sets the state variable
    "expandedItems" to the value of "expandedNodes" (or an empty array if "expandedNodes" is falsy)
    whenever "expandedNodes" changes. */
    useEffect(() => {
        setExpandedItems(expandedNodes || []);
    }, [expandedNodes]);

    /* The below code is using the `useEffect` hook in a TypeScript React component. It is setting up a
    side effect that will be triggered whenever the `hierarchyData` or `isGroupEmpty` variables
    change. */
    useEffect(() => {
        if (isGroupEmpty && hierarchyData?.[0]?.defaultGroup === true) {
            isGroupEmpty(!hierarchyData?.[0]?.children?.length);
        } else if (isGroupEmpty) {
            isGroupEmpty(!hierarchyData?.length);
        }
    }, [hierarchyData, isGroupEmpty]);

    /* The below code is using the `useEffect` hook in a React component. It is checking if the
    `hierarchyData` object is empty or if the `devices` array within the first element of
    `hierarchyData` is empty. If either condition is true, it calls the `isDeviceEmpty` function and
    passes the result of the check as an argument. The `useEffect` hook will re-run whenever
    `hierarchyData` or `isDeviceEmpty` changes. */
    useEffect(() => {
        if (isDeviceEmpty) {
            isDeviceEmpty(!hierarchyData?.[0]?.devices?.length);
        }
    }, [hierarchyData, isDeviceEmpty]);

    /* The below code is checking if the variable `isLoading` is true. If it is true, it will return a
    `Loader` component with a size of 50. */
    if (isLoading) {
        return <Loader size={50} />;
    }

    const hierarchyNodes = createGroupNodes(hierarchyData, 'children');

    return (
        <TreeView
            defaultCollapseIcon={<ExpandMore className={classes.expandIcon} />}
            defaultExpandIcon={<ChevronRight className={classes.expandIcon} />}
            onNodeSelect={handleSelectNodes}
            selected={selectedNodes}
            expanded={expandedItems}
            onNodeToggle={handleExpandNodes}
            disableSelection={isMultiSelect}
            className={clsx(isOverflow && customClass)}
            disabledItemsFocusable
        >
            {hierarchyNodes?.flat(Infinity)?.length > 0 &&
            !hierarchyNodes?.flat(Infinity)?.every((item) => item === false)
                ? hierarchyNodes
                : noDataFoundComponent}
        </TreeView>
    );
};

export default Hierarchy;
