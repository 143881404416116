import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    ContainerWrapper: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    cardBody: {
        padding: theme.spacing(3),
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: '16px 16px 0px 16px',
            marginBottom: theme.spacing(6),
        },
    },
    cardfooter: {
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            borderTop: '1px solid rgba(66, 78, 84, 0.12)',
            padding: theme.spacing(3),
        },
    },
    sectionHeader: {
        width: '100%',
        marginBottom: 16,
    },
    gridContainer: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
            flexDirection: 'column',
        },
    },
    gridItem: {
        marginBottom: '36px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: 'none',
            marginBottom: theme.spacing(2),
        },
    },
    selectFormControl: {
        '& .MuiInputBase-root': {
            paddind: '20px',
            '& .MuiSelect-select-MuiInputBase-input-MuiFilledInput-input': {
                '&:focus': {
                    backgroundColor: '#f7f8f8 !important',
                },
            },
        },
    },
    textField: {
        marginBottom: '36px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(4),
        },
    },
    divider: {
        marginTop: '48px',
    },
    topDivider: {
        marginTop: '32px',
        marginBottom: '32px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
    },
    buttomGroup: {
        [theme.breakpoints.down('sm')]: {
            '& > *:first-child': {
                display: 'none',
            },
        },
    },
    muiDialogpaper: {
        '& .MuiPaper-root': {
            maxWidth: '450px',
            width: '450px',
        },
    },
    MuiDialogTitleRoot: {
        paddingTop: '32px',
    },
    MuiDialogActionsRoot: {
        padding: '24px',
        borderTop: '1px solid rgba(66, 78, 84, 0.12)',
    },
}));
