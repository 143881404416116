/* eslint-disable @typescript-eslint/no-misused-promises */
import { IconButton, Stack, Switch, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import CustomTable from '../../components/CustomTable';
import { AddBox, ChevronRight, DeleteForever, MoreVert } from '@mui/icons-material';
import { UserMenu, UserMenuItem } from '@brightlayer-ui/react-components';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import {
    useConfirm,
    useFilteredValues,
    useGetHierarchyHandlers,
    useIsMount,
    useRBAC,
    useSelectedIds,
} from '../../hooks';
import { useGetAllActionsQuery } from '@fiji/common/src/features/actions/actionsApi';
import { useGetAllEventsQuery } from '@fiji/common/src/features/events/eventsApi';
import {
    useDeleteAutomationMutation,
    useEditAutomationStatusMutation,
} from '@fiji/common/src/features/automation/automationApi';
import { useTheme } from '@mui/material/styles';

import { setToastifyContent } from '@fiji/common/src/features/common/commonSlice';
import Hierarchy from '../../components/Hierarchy';
import { addAutomationModal } from '@fiji/common/src/features/automation/automationSlice';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import ConfirmModal from '../../components/ConfirmModal';
import { useStyles } from '../ManageDevices/styles';
import { selectHierarchyData } from '@fiji/common/src/features/group/groupSlice';
import { api } from '@fiji/common/src/app/api/baseApi';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';

export const AutomationTable = (props: any): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const initialRender = useIsMount();
    const dispatch = useAppDispatch();

    const tableRef = React.useRef<any>();
    const deleteModalRef = React.useRef<any>(null);

    const [selectedIds, setSelectedIds] = useSelectedIds();
    const { selectedGroups, handleSelectGroup, removeUnwantedIds } = useGetHierarchyHandlers({});

    const [enableDisableHandler, { isLoading }] = useEditAutomationStatusMutation();
    const [deleteAutomation] = useDeleteAutomationMutation();

    const hierarchyData = useTypedSelector(selectHierarchyData);

    const { data: allActions }: any = useGetAllActionsQuery();

    const { data: allEvents }: any = useGetAllEventsQuery();

    const deleteModalId = React.useId();
    const currentOrg = useTypedSelector(selectedOrg);

    React.useEffect(() => {
        if (currentOrg?.id) {
            dispatch((api as any).endpoints.getGroupsHierarchy.initiate(null));
        }
    }, []);

    /* The below code is using the `useEffect` hook in a React component. It is triggered whenever the
    `selectedGroups` variable changes. Inside the `useEffect` callback, it checks if it is not the
    initial render (by checking the `initialRender` variable) and then calls the `filterHandler`
    function from the `props` object. It passes a callback function as an argument to
    `filterHandler`, which updates the `filters` property of the previous state by merging it with a
    new object that has the `groupId` property set to the value of `selectedGroups`. */
    useEffect(() => {
        if (!initialRender) {
            const filteredGroupIds = removeUnwantedIds(hierarchyData?.data?.data?.data, selectedGroups, []);
            const filterClone = JSON.parse(JSON.stringify(props?.filter?.filters));
            if (!filteredGroupIds?.groups?.length) {
                delete filterClone['groups'];
            } else {
                filterClone['groups'] = filteredGroupIds?.groups;
            }
            props?.filterHandler((prev: any) => ({
                ...prev,
                page: 0,
                filters: { ...filterClone },
            }));
        }
    }, [selectedGroups]);

    /**
     * The function `multiUserDeleteModalHandler` handles the deletion of multiple users by updating
     * the state with the selected user IDs and filtering the automation records based on the selected
     * IDs.
     * @param {boolean} action - The `action` parameter is a boolean value that determines the action
     * to be performed. It is used to control the behavior of the `modalHandler` function.
     */
    const rolePermissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(rolePermissions);
    const canCreateAutomation = hasPermission('create-automation');
    const canDeleteAutomation = hasPermission('delete-automation');
    const canUpdateAutomation = hasPermission('update-automation');

    /* The below code is defining a function called `getMenuItems` that returns an array of
    `UserMenuItem` objects. Each `UserMenuItem` object has a `title`, `icon`, and `onClick`
    property. The `title` property represents the title of the menu item, the `icon` property
    represents the icon to be displayed for the menu item, and the `onClick` property represents the
    function to be executed when the menu item is clicked. */
    const getMenuItems = (): UserMenuItem[] => {
        const items: any[] = [];
        if (canCreateAutomation) {
            items.push({
                title: 'Add',
                icon: <AddBox className={classes.menuItem} />,
                onClick: (): void => {
                    dispatch(addAutomationModal({ isOpen: true }));
                },
            });
        }
        if (canDeleteAutomation) {
            items.push({
                title: 'Delete',
                className: !selectedIds?.length ? classes.disabled : '',
                icon: <DeleteForever />,
                onClick: (): void => {
                    openDeleteModal();
                },
            });
        }

        return items;
    };

    /**
     * The function `switchHandler` is an asynchronous function that handles a switch event in a
     * TypeScript React component and updates the enabled status of an automation based on the event
     * data.
     * @param {any} e - The parameter `e` is an event object that represents the event that triggered
     * the switch handler. It is typically used to access information about the event, such as the
     * target element that was clicked or the value of an input field.
     * @param {any} data - The `data` parameter is an object that contains information related to the
     * automation. It likely includes properties such as `id`, which represents the unique identifier
     * of the automation.
     */
    const switchHandler = async (e: any, data: any): Promise<void> => {
        const body = { access: e.target.checked };
        const automationId = data?.id;
        await enableDisableHandler({ body, automationId });
    };

    /**
     * The function `getSwitch` returns a JSX element representing a switch component with a checked
     * state and an onChange event handler.
     * @param {any} data - The `data` parameter is of type `any`, which means it can accept any type of
     * value. It is used as a prop to determine the initial checked state of the `Switch` component and
     * to pass additional data to the `switchHandler` function.
     */
    const getSwitch = (data: any): JSX.Element => (
        <Stack direction={'column'}>
            <Switch checked={data?.access} onChange={(e): Promise<void> => switchHandler(e, data)} />
        </Stack>
    );

    /* The below code is defining a function called `getGroupHeaderOptions` that returns a JSX element.
    The JSX element is a component called `Hierarchy` with various props being passed to it. These
    props include `multiSelectionKey`, `selectedNodes`, `onChangeTreeItem`, `isMultiSelect`,
    `hierarchyData`, and `filter`. The `Hierarchy` component is likely used to display a
    hierarchical tree structure with the ability to select multiple nodes and handle changes in
    selection. */
    const getGroupHeaderOptions = (): JSX.Element => (
        <Hierarchy
            multiSelectionKey="children"
            selectedNodes={selectedGroups}
            onChangeTreeItem={(value: any, key?: string, type?: string, isChecked?: boolean): void =>
                handleSelectGroup(value, hierarchyData?.data?.data?.data, type, isChecked, key)
            }
            isMultiSelect
            hierarchyData={hierarchyData?.data?.data?.data}
        />
    );

    /* The below code is defining a function called `getEvent` that takes in a parameter `data` of type
    `any`. It returns a JSX element that renders the name of the event from the `data` object, if it
    exists. The `?.` operator is used to safely access nested properties, so if `data` or `data.event`
    is `null` or `undefined`, it will not throw an error. */
    const getEvents = (data: any): JSX.Element => (
        <Typography variant="body1">
            {data?.events?.length > 1
                ? `${data?.events[0]?.name} and ${data?.events?.length - 1} Others `
                : data?.events[0]?.name ?? 'N/A'}
        </Typography>
    );

    /* The below code is defining a function called `getAction` that takes in a parameter `data` of type
    `any`. It returns a JSX element that renders the name of the `action` property of the `data` object,
    if it exists. The `?.` operator is used to safely access nested properties, so if `data` or
    `data.action` is `null` or `undefined`, the function will return `null`. */
    const getAction = (data: any): JSX.Element => <p>{data?.action?.name ?? 'N/A'}</p>;

    /**
     * The function `getActionExtraOptions` returns a JSX element representing a user menu with an
     * avatar and menu items.
     */
    const getActionExtraOptions = (): JSX.Element => (
        <UserMenu
            id="automation"
            avatar={
                <IconButton id="device-action-btn" size="large">
                    {' '}
                    <MoreVert />
                </IconButton>
            }
            menuGroups={[
                {
                    items: getMenuItems(),
                },
            ]}
        />
    );

    /* The below code is a TypeScript function called `getGroups` that takes in a parameter `data` of
    type `any`. It returns a JSX element. */
    const getGroups = (data: any): JSX.Element => (
        <Typography variant="body1">
            {data?.groups?.length > 1
                ? `${data?.groups[0]?.name} and ${data?.groups?.length - 1} Others `
                : data?.groups[0]?.name ?? 'N/A'}
        </Typography>
    );

    /* The below code is a TypeScript function called `getDevices` that takes in a parameter `data` of
    type `any`. It returns a JSX element, specifically a list of `<p>` elements that display the
    names of devices. It uses optional chaining (`?.`) to safely access the `devices` property of
    the `data` object and map over each device to create a `<p>` element with the device's name as
    the content. Each `<p>` element is given a unique `key` prop using the device's name. */
    const getDevices = (data: any): JSX.Element => (
        <Typography variant="body1">
            {data?.devices?.length > 1
                ? `${data?.devices[0]?.name} and ${data?.devices?.length - 1} Others `
                : data?.devices[0]?.name ?? 'N/A'}
        </Typography>
    );

    /**
     * The function returns a JSX element with a ChevronRight icon that triggers an editHandler
     * function when clicked, only if canUpdateAutomation is true.
     * @param {any} data - The `data` parameter is of type `any`, which means it can be any data type.
     */
    const getActionCell = (data: any): JSX.Element =>
        canUpdateAutomation && (
            <ChevronRight
                onClick={(): void => props?.editHandler(data?.id)}
                sx={{ cursor: 'pointer' }}
                className={classes.hevronRight}
            />
        );

    /**
     * The function "handleTableCheckboxes" sets the selected IDs based on the provided ID.
     * @param {any} id - The `id` parameter is the identifier of the checkbox that was clicked or
     * selected.
     */
    const handleTableCheckboxes = (id: any): void => {
        setSelectedIds(id);
    };

    const columns = React.useMemo(
        () => [
            {
                header: '',
                isSelectable: true,
            },
            {
                header: 'Enabled',
                width: '10%',
                cell: getSwitch,
            },
            {
                header: 'Automation Name',
                accessor: 'name',
                width: '17%',
                isDebounce: true,
                isSortable: true,
                isFilterable: true,
            },
            {
                header: 'Device',
                accessor: 'devices',
                width: '17%',
                isSortable: true,
                isFilterable: true,
                isDebounce: true,
                cell: getDevices,
            },
            {
                header: 'Group',
                width: '17%',
                accessor: 'groups',
                isSortable: true,
                isFilterable: true,
                cell: getGroups,
                typeVariant: 'body2',
                headerOptions: getGroupHeaderOptions,
            },
            {
                header: 'Triggers',
                width: '17%',
                accessor: 'events',
                isSortable: true,
                isFilterable: true,
                cell: getEvents,
                typeVariant: 'body2',
                filterOptions: [{ id: 'all', label: 'All' }].concat(
                    allEvents?.data?.records
                        ?.map((event: any) =>
                            event?.child?.map((childEvent: any) => ({ id: childEvent?.id, label: childEvent?.name }))
                        )
                        .flat(Infinity)
                ),
            },
            {
                header: 'Action',
                width: '17%',
                accessor: 'action',
                isSortable: true,
                isFilterable: true,
                cell: getAction,
                typeVariant: 'body2',
                filterOptions: [{ id: 'all', label: 'All' }].concat(
                    allActions?.data?.records?.map((action: any) => ({ id: action.id, label: action?.name }))
                ),
            },
            {
                ...((canCreateAutomation || canDeleteAutomation) && { extraOptions: getActionExtraOptions }),
                width: '5%',
                cell: getActionCell,
            },
        ],
        [allEvents, allActions, selectedIds, selectedGroups]
    );

    const [tableFilters] = useFilteredValues({
        allFilters: columns,
        triggeredFilters: props?.filter?.filters,
    });

    useEffect(() => {
        props?.handleAutomationFilters(tableFilters);
    }, [tableFilters]);

    /**
     * The function `deleteAutomationHandler` deletes automations and displays a success message if the
     * deletion is successful.
     */
    const deleteAutomationHandler = async (): Promise<void> => {
        const { error }: any = await deleteAutomation({ automationIds: selectedIds });
        if (!error) {
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: ` ${selectedIds.length} automation(s) deleted successfully`,
                    duration: 3000,
                })
            );
            deleteModalRef?.current?.modalHandler(false);
            tableRef?.current?.resetCheckboxes();
        }
    };

    const {
        isVisible: isDeleteModalOpen,
        onClick: openDeleteModal,
        onCancel: closeDeleteModal,
        onConfirm: confirmDeletion,
    } = useConfirm(deleteAutomationHandler as any);

    /**
     * The function `handleFilterChange` updates the filter, page, size, and sort data in the props
     * based on the provided parameters.
     * @param {any} filters - An object representing the filters applied to the data. It could contain
     * various properties depending on the specific filtering requirements.
     * @param {any} sortData - The `sortData` parameter is an object that contains information about
     * how the data should be sorted. It typically includes properties like `field` (the field to sort
     * by) and `direction` (the sorting direction, such as ascending or descending).
     */
    const handleFilterChange = (filters: any, sortData: any): void => {
        if (JSON.stringify(filters) !== JSON.stringify(props?.filter.filters)) {
            props?.filterHandler((prev: any) => ({
                ...prev,
                page: 0,
                filters: prev?.filters?.groups?.length ? { groups: prev?.filters?.groups, ...filters } : filters,
            }));
        } else if (Object.keys(sortData)?.length) {
            props?.filterHandler((prev: any) => ({
                ...prev,
                page: 0,
                sort: sortData,
            }));
        }
    };

    /**
     * The function `handlePaginationChange` updates the page and size values in the filter object and
     * calls the filterHandler function with the updated filter object.
     * @param {number} page - The page parameter represents the current page number in the pagination.
     * @param {number} size - The size parameter represents the number of items to be displayed per page in
     * a pagination component.
     */
    const handlePaginationChange = (page: number, size: number): void => {
        if (page || size) props?.filterHandler((prev: any) => ({ ...prev, page: page, size: size }));
    };

    return (
        <>
            <CustomTable
                isLoading={props?.isLoading || isLoading}
                total={props?.data?.total}
                ref={tableRef}
                controlledPageSize={props.filter.page}
                data={props?.data?.records}
                headers={columns}
                handleCheckboxSelect={handleTableCheckboxes}
                handleFilterChange={handleFilterChange}
                handlePageChange={handlePaginationChange}
                noDataFoundDescription={
                    <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: '600', color: '#424E54' }}>
                        Automations let you trigger control actions
                        <br />
                        with events
                    </Typography>
                }
                noDataFoundIcon={<SmartToyIcon sx={{ height: '75px', width: '75px', color: '#727E84' }} />}
                {...(canCreateAutomation && {
                    noDataFoundButtonText: 'Add an Automation',
                    noDataFoundButtonAction: (): any => dispatch(addAutomationModal({ isOpen: true })),
                })}
                noDataFoundTitle="No Automations"
                keyToTraverse="id"
                isPagination
            />
            <ConfirmModal
                key={deleteModalId}
                confirmButtonText={selectedIds?.length > 1 ? 'Delete Automations' : 'Delete Automation'}
                closeHandler={closeDeleteModal}
                confirmClick={confirmDeletion}
                isVisible={isDeleteModalOpen}
                confirmButtonClass={classes.deleteButton}
                wrapperClass={classes.muiDialogpaper}
                actionClass={classes.MuiDialogActionsRoot}
                titleClass={classes.MuiDialogTitleRoot}
                description={
                    <>
                        {selectedIds?.length > 1
                            ? `${selectedIds?.length} automations will be permanently deleted.This action cannot be undone.`
                            : 'This automation will be permanently deleted.This cannot be undone.'}
                    </>
                }
                headingText={selectedIds?.length > 1 ? 'Delete Automations?' : 'Delete Automation?'}
            />
        </>
    );
};
