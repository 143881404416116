import { api } from '../../app/api/baseApi';

const eventsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllEvents: builder.query<object, void>({
            query: () => ({
                url: `/iam/v1/events`,
            }),
        }),
    }),
});

export const { useGetAllEventsQuery } = eventsApi;
