import React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import { createNumArray } from '../../../CommonUtils';

const HeroSkeleton = (): JSX.Element => (
    <>
        {createNumArray(4).map((item) => (
            <Box key={item} sx={{ textAlign: 'center' }}>
                <Skeleton
                    animation="wave"
                    height={50}
                    width={50}
                    sx={{
                        margin: 'auto',
                        color: '#727E84',
                    }}
                    variant="circular"
                />
                <Skeleton sx={{ margin: 'auto' }} animation="wave" variant="text">
                    <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>100</Typography>
                </Skeleton>
                <Skeleton sx={{ margin: 'auto' }} animation="wave" variant="text">
                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Warnings</Typography>
                </Skeleton>
                <Skeleton sx={{ margin: 'auto' }} animation="wave" variant="text">
                    <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>Active</Typography>
                </Skeleton>
            </Box>
        ))}
    </>
);

export default HeroSkeleton;
