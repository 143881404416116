import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import * as Colors from '@brightlayer-ui/colors';

export const useStyles = makeStyles((theme: Theme) => ({
    ContainerWrapper: {
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    contentBody: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    subHeader: {
        backgroundColor: Colors.white[50],
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: theme.spacing(2),
        minHeight: '56px',
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            bottom: '0px',
            left: '0px',
            right: '0px',
            zIndex: 10,
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            padding: '16px',
        },
    },
    accordionDetails: {
        paddingTop: '0px',
    },
    muiGridRoot: {
        flexWrap: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            flexDirection: 'column-reverse',
        },
    },
    muiGridItem: {
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
            maxWidth: '100%',
        },
    },
    accordionSummary: {
        minHeight: '64px',
    },
    textFieldGroup: {
        padding: '16px 16px 0px 0px',
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
            maxWidth: '100%',
            padding: '16px 0px 0px 0px',
        },
    },
    sectionHeader: {
        width: '100%',
    },
    textField: {
        marginBottom: '48px',
    },
    copyIcon: {
        height: 36,
        width: 36,
    },
    selectField: {
        marginBottom: '16px',
    },
    divider: {
        width: 'calc(100% + 16px)',
    },
    bottomDivider: {
        marginBottom: '16px',
    },
    infoListItem: {
        [theme.breakpoints.down('sm')]: {
            paddingRight: theme.spacing(0),
            paddingLeft: theme.spacing(0),
        },
    },
    emptyState: {
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(6),
        },
        '& .BluiEmptyState-icon': {
            '& .MuiAvatar-root': {
                width: '96px',
                height: '96px',
            },
        },
    },
    uploadIcon: {
        border: '2px dashed #D5D8DA',
        borderRadius: '50%',
        background: Colors.white[100],
        color: Colors.blue[500],
        padding: '16px',
    },
    muiDialogpaper: {
        '& .MuiPaper-root': {
            maxWidth: '450px',
            width: '450px',
            minHeight: '300px',
        },
    },
    MuiDialogTitleRoot: {
        paddingTop: '32px',
    },
    dialogcontent: {
        minHeight: '334px',
    },
    MuiDialogActionsRoot: {
        padding: '24px',
        borderTop: '1px solid rgba(66, 78, 84, 0.12)',
    },
    listWrapper: {
        padding: '0px',
    },
    listItem: {
        '& .BluiInfoListItem-statusStripe ': {
            background: 'transparent',
        },
    },
    listItemActive: {
        background: '#007BC10D',
        color: Colors.blue[500],
        '& .BluiInfoListItem-subtitle': {
            color: Colors.blue[500],
        },
    },
    emptyStateWrapper: {
        height: 'calc(100vh - 97px)',
    },
    deleteButton: {
        background: Colors.red[600],
        '&:hover': {
            backgroundColor: Colors.red[400],
        },
        '&.Mui-disabled': {
            backgroundColor: '#F9E8E8',
            color: '#E59E9E',
        },
    },
    continueButton: {
        background: Colors.blue[600],
        '&:hover': {
            backgroundColor: Colors.blue[400],
        },
    },
}));
