import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Chip, IconButton, Stack, TextField, Typography } from '@mui/material';
import * as Colors from '@brightlayer-ui/colors';
import { UserMenu, ListItemTag, UserMenuItem } from '@brightlayer-ui/react-components';
import { useTheme } from '@mui/material/styles';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import {
    MoreVert,
    PersonAdd,
    PersonOff,
    PersonRemove,
    Send,
    Person,
    ChevronRight,
    Search,
    CancelOutlined,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
    useDisableUserMutation,
    useResendInvitationMutation,
    useDeleteUserMutation,
    useGetAllRolesQuery,
    useGetAllUsersQuery,
} from '@fiji/common/src/features/userManagement/userManagement';

import { AllRolesObj, AllUserObj, UserObj, UserProfile } from '@fiji/common/src/types';
import { useStyles } from './styles';
import CustomTable from '../../components/CustomTable';
import { useDebounce, useFilteredValues, useRBAC, useSelectedIds } from '../../hooks';
import MultiItemsDeleteModal from '../../components/MultiItemsDeleteModal';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { setToastifyContent } from '@fiji/common/src/features/common/commonSlice';

export const User = (): JSX.Element => {
    const theme = useTheme();
    const navigate = useNavigate();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();
    const tableRef = React.useRef<any>(null);
    const deleteModalRef = React.useRef<any>(null);

    const rolePermissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(rolePermissions);
    const canInviteUser = hasPermission('invite-user-to-organization');
    const canEnableDisableUser = hasPermission('enable-disable-user');
    const canApproveUser = hasPermission('approve-request');
    const canDeleteUser = hasPermission('delete-user');
    const canResetInvite = hasPermission('resend-invite');
    const canViewUser = hasPermission('view-users');

    const [disableUser] = useDisableUserMutation();
    const [resendInvitation] = useResendInvitationMutation();
    const [deleteUser, { isLoading: isDeleteUserLoading }] = useDeleteUserMutation();

    const [filteredUsers, setFilteredUsers] = useState<any[]>();
    const [searchKey, setSearchKey] = useState<string | undefined>(undefined);
    const [payload, setPayload] = useState<any>({ size: 10, page: 0, filters: {} });

    const { data: allRoles } = useGetAllRolesQuery<{
        data: AllRolesObj;
    }>();

    const { data: userProfileData } = useGetUserProfileQuery<{
        data: UserProfile;
    }>();

    const [, debouncedValue] = useDebounce(undefined, undefined, searchKey);

    const [selectedIds, setSelectedIds] = useSelectedIds();
    const [deleteUserIds, setDeleteUserIds] = React.useState(selectedIds);

    /* The below code is using the `useEffect` hook in a TypeScript React component. It is setting the
    `searchKey` property of the `payload` state variable to the `debouncedValue` whenever the
    `debouncedValue` changes. The `debouncedValue` is likely a value that has been debounced using a
    debounce function, which means it will only update after a certain delay or when a certain
    condition is met. */
    useEffect(() => {
        setPayload((prev: any) => ({
            ...prev,
            searchKey: debouncedValue,
            page: 0,
        }));
    }, [debouncedValue]);

    /* The below code is defining a function called `getRoleCell` that takes a parameter `user` of type
    `any`. Inside the function, it returns a JSX element that displays the user's role. If the user
    is an organization admin and their ID matches the ID in `userProfileData.data`, a bookmark icon
    is also displayed before the role. The role and bookmark icon are styled using the `Typography`
    component and the `orgAdminLabel` class. */
    const getRoleCell = (user: any): JSX.Element => (
        <Typography variant="body1" className={classes.orgAdminLabel}>
            {user?.orgAdmin && user?.id === userProfileData?.data?.id && (
                <BookmarkIcon sx={{ color: '#F0A920', mr: 1 }} />
            )}{' '}
            {user?.role}
        </Typography>
    );

    /**
     * The function `getPhoneNumber` takes a user object as input and returns their phone number in the
     * format "areaCode phoneNumber" if both are present, or just the phoneNumber if only that is
     * present, or 'N/A' if neither are present.
     * @param {any} user - The `user` parameter is an object that represents a user. It is of type
     * `any`, which means it can be any type of object.
     * @returns The function `getPhoneNumber` returns a string representing the phone number. If the
     * `user` object has both `areaCode` and `phoneNumber` properties, the function returns a string in
     * the format `${user.areaCode} ${user.phoneNumber}`. If the `user` object has only the
     * `phoneNumber` property, the function returns the `phoneNumber` value as a string. If the `user
     */
    const getPhoneNumber = (user: any): string => {
        if (user?.areaCode && user?.phoneNumber) {
            return `${user.areaCode} ${user.phoneNumber}`;
        } else if (!user.areaCode && user?.phoneNumber) {
            return user.phoneNumber;
        }
        return 'N/A';
    };

    /**
     * The function returns a JSX element containing the phone number of a user.
     * @param {any} user - The `user` parameter is an object that represents a user.
     */
    const getPhoneNumberCell = (user: any): JSX.Element => (
        <Typography variant={'body2'}>{getPhoneNumber(user)}</Typography>
    );

    /**
     * The function `getActionExtraOptions` returns a UserMenu component with menu items if there are
     * any, otherwise it returns undefined.
     * @returns The function `getActionExtraOptions` returns a JSX element.
     */
    const getActionExtraOptions = (): any => {
        if (getMenuItems().length) {
            return (
                <UserMenu
                    id="users"
                    avatar={
                        <IconButton id="device-action-btn" size="large">
                            {' '}
                            <MoreVert />
                        </IconButton>
                    }
                    menuGroups={[
                        {
                            items: getMenuItems(),
                        },
                    ]}
                />
            );
        }
    };

    /**
     * The function returns a JSX element with a ChevronRight icon that, when clicked, navigates to a
     * specific profile page.
     * @param {any} data - The `data` parameter is of type `any`, which means it can accept any type of
     * value.
     */
    const getActionCell = (data: any): JSX.Element => (
        <IconButton disabled={data?.status === 'PENDING' || data?.status === 'INVITED'}>
            <ChevronRight onClick={(): void => navigate(`/profile/${data?.id}`)} className={classes.hevronRight} />
        </IconButton>
    );

    /* The below code is defining an array of table headers for a React component. Each header object
    in the array specifies the properties of a column in the table. The properties include the
    header label, accessor (data key), width, cell class, filterable and sortable options, and
    custom cell rendering functions. The code also conditionally adds an additional column for
    actions based on certain permissions. The useMemo hook is used to memoize the columns array and
    optimize performance by preventing unnecessary re-renders. */
    const columns = React.useMemo(() => {
        /* The below code is defining a function called `getUserStatus` that takes a `user` object as a
        parameter. */
        const getUserStatus = (user: any): any => {
            if (user?.id === userProfileData?.data?.id) {
                return <ListItemTag label={'You'} backgroundColor="#fff" color="#000" />;
            } else if (user?.status !== 'ACTIVE' && user?.status === 'PENDING' && canApproveUser) {
                return (
                    <Button
                        id="status"
                        variant={'outlined'}
                        onClick={(): void => navigate(`/user/approve/${user?.id}`)}
                    >
                        {'Approve'}
                    </Button>
                );
            } else if (user?.status !== 'ACTIVE') {
                return (
                    <ListItemTag
                        label={user?.status ?? 'N/A'}
                        backgroundColor={handleStatusBackgroundColor(user?.status)}
                        color={handleStatusColor(user?.status)}
                    />
                );
            }
        };
        const tableHeaders: any = [
            {
                header: '',
                isSelectable: true,
            },
            {
                header: 'Last Name',
                accessor: 'lastName',
                width: '16%',
                isDebounce: true,
                cellClass: 'table-text-field-bold',
                isFilterable: true,
                isSortable: true,
            },
            {
                header: 'First Name',
                accessor: 'firstName',
                isDebounce: true,
                cellClass: 'table-text-field-bold',
                width: '16%',
                isFilterable: true,
                isSortable: true,
            },
            {
                header: 'Email',
                accessor: 'email',
                isDebounce: true,
                typeVariant: 'body2',
                isFilterable: true,
                width: '21%',
                isSortable: true,
            },
            {
                header: 'Phone',
                width: '16%',
                isFilterable: true,
                isDebounce: true,
                accessor: 'phoneNumber',
                cell: getPhoneNumberCell,
            },
            {
                header: 'Role',
                accessor: 'role',
                isSortable: true,
                width: '18%',
                variant: 'body2',
                isFilterable: true,
                filterOptions: [{ id: 'all', label: 'All' }].concat(
                    allRoles?.data?.records.map((role: any) => ({ id: role?.name, label: role?.name }))
                ),
                cell: getRoleCell,
            },
            {
                header: 'Status',
                width: '16%',
                accessor: 'status',
                cell: (user: any) => getUserStatus(user),
                isFilterable: true,
                filterOptions: [
                    { id: 'all', label: 'All' },
                    { label: 'Active', id: 'ACTIVE' },
                    { label: 'Inactive', id: 'INACTIVE' },
                    { label: 'Invited', id: 'INVITED' },
                    { label: 'Approve', id: 'PENDING' },
                ],
            },
        ];
        if (canViewUser) {
            tableHeaders.push({
                extraOptions: getActionExtraOptions,
                width: '5%',
                cell: getActionCell,
            });
        }
        return tableHeaders;
    }, [allRoles, canViewUser, selectedIds, userProfileData, rolePermissions]);

    const [tableFilters] = useFilteredValues({ allFilters: columns, triggeredFilters: payload?.filters });

    const {
        data: allUsers,
        isLoading,
        isFetching,
    } = useGetAllUsersQuery<{ data: AllUserObj; isFetching: boolean; isLoading: boolean }>(
        { ...payload, filters: tableFilters },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    React.useEffect(() => {
        if (allUsers) {
            tableRef?.current?.resetCheckboxes();
            setDeleteUserIds([]);
        }
    }, [allUsers]);

    /* The below code is defining a function called `navigationHandler` using the `useCallback` hook in
    a TypeScript React component. This function takes a `path` parameter of type `string` and
    returns a function that calls the `navigate` function with the provided `path`. The
    `useCallback` hook is used with an empty dependency array `[]`, indicating that the function
    does not depend on any variables and will not be recreated on re-renders. */
    const navigationHandler = useCallback(
        (path: string) => (): void => {
            navigate(path);
        },
        []
    );

    /**
     * The function `handleFilterChange` updates the `payload` state based on the provided filters,
     * page, size, and sortedData.
     * @param {any} filters - An object containing the filter criteria for the data.
     * @param {any} sortedData - The `sortedData` parameter is an object that represents the sorting
     * criteria for the data. It typically contains one or more key-value pairs, where the key
     * represents the field to sort by and the value represents the sort order (e.g., ascending or
     * descending).
     */
    const handleFilterChange = (filters: any, sortedData: any): void => {
        if (JSON.stringify(filters) !== JSON.stringify(payload.filters)) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                filters: filters,
            }));
        } else if (Object.keys(sortedData)?.length) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                sort: sortedData,
            }));
        }
    };

    /**
     * The function `handlePaginationChange` updates the `payload` state with the new `page` and `size`
     * values if either of them is provided.
     * @param {number} page - The page parameter represents the current page number in the pagination. It
     * is used to determine which page of data to display.
     * @param {number} size - The `size` parameter represents the number of items to be displayed per page
     * in a paginated list.
     */
    const handlePaginationChange = (page: number, size: number): void => {
        if (page || size) setPayload((prev: any) => ({ ...prev, page: page, size: size }));
    };

    /**
     * The function "handleTableCheckboxes" sets the selected IDs based on the provided data.
     * @param {string[]} data - An array of strings representing the selected checkboxes in a table.
     */
    const handleTableCheckboxes = (data: string[]): void => {
        setSelectedIds(data);
    };

    /**
     * The `disableHandler` function disables or enables user accounts based on the `access` parameter
     * and displays a toast message accordingly.
     * @param {boolean} access - A boolean value indicating whether to enable or disable user accounts.
     */
    const disableHandler = async (access: boolean): Promise<void> => {
        const { error }: any = await disableUser({ access: access, userIds: selectedIds });
        if (!error) {
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: !access ? `User Accounts have been disabled` : `User Accounts have been enabled`,
                })
            );
            tableRef?.current?.resetCheckboxes();
        }
    };

    /**
     * The function `resendInvitationHandler` sends a request to resend invitations to selected user
     * IDs, and if successful, displays a toast message and resets checkboxes in a table.
     */
    const resendInvitationHandler = async (): Promise<void> => {
        const { error }: any = await resendInvitation({ userIds: selectedIds });
        if (!error) {
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: `${
                        selectedIds.length === 1 ? '1 invitation resent successfully' : 'Invitations have been resent'
                    }`,
                })
            );
            tableRef?.current?.resetCheckboxes();
        }
    };

    /**
     * The function `multiUserDeleteModalHandler` handles the deletion of multiple users by updating
     * the list of filtered users and triggering a modal handler.
     * @param {boolean} action - The `action` parameter is a boolean value that determines the action
     * to be performed. It is used to control the behavior of the `modalHandler` function.
     */
    const multiUserDeleteModalHandler = (action: boolean): void => {
        deleteModalRef?.current?.modalHandler(action);
        const allUsersClone = JSON.parse(JSON.stringify(allUsers?.data));
        allUsersClone.records = allUsersClone.records.filter((user: UserObj) => selectedIds.includes(user?.id));
        setFilteredUsers(allUsersClone?.records);
    };

    /**
     * The function `booleanChecker` filters a list of user objects based on selected IDs and checks if
     * all the filtered users have a specific status.
     * @param {string} type - The `type` parameter is a string that represents the status type we want
     * to check against.
     * @returns The function `booleanChecker` returns a boolean value.
     */
    const booleanChecker = (type: string): boolean => {
        const filteredData = allUsers?.data?.records?.filter((user: UserObj) => selectedIds.includes(user.id));

        return filteredData?.every((item: UserObj) => item.status === type.toUpperCase());
    };

    /**
     * The function `actionButtonDisableHandler` returns an empty string if certain conditions are met,
     * otherwise it returns the value of the `classes.disabled` variable.
     * @param {string} type - The `type` parameter is a string that represents the type of action being
     * performed.
     * @returns The function `actionButtonDisableHandler` returns an empty string (`''`) if the
     * conditions inside the `if` statement are met. Otherwise, it returns the value of the
     * `classes.disabled` variable.
     */
    const actionButtonDisableHandler = (type: string): string => {
        if (
            selectedIds.length &&
            booleanChecker(type) &&
            !selectedIds?.some((id: any) => id === userProfileData?.data?.id)
        ) {
            return '';
        }
        return classes.disabled;
    };

    /**
     * The function `handleStatusBackgroundColor` returns a background color based on the input type.
     * @param {string} type - The `type` parameter is a string that represents the status type.
     * @returns The function `handleStatusBackgroundColor` returns a string value representing a color
     * code.
     */
    const handleStatusBackgroundColor = (type: string): any => {
        if (type === 'INVITED') return '#E0F1FD !important';
        return '#727E84 !important';
    };

    /**
     * The function `handleStatusColor` takes a string parameter `type` and returns a color value based
     * on the value of `type`.
     * @param {string} type - The `type` parameter is a string that represents the status type.
     * @returns The function `handleStatusColor` returns a string value representing a color code. If
     * the `type` parameter is equal to `'INVITED'`, it returns `'#0088F2 !important'`. Otherwise, it
     * returns `'#EEF0F0 !important'`.
     */
    const handleStatusColor = (type: string): any => {
        if (type === 'INVITED') return '#0088F2 !important';
        return '#EEF0F0 !important';
    };

    /**
     * The function `handleGlobalSearch` updates the search key based on the value of an input field.
     * @param {any} e - The parameter `e` is of type `any`, which means it can be any type of value. In
     * this case, it is likely an event object that is passed to the function when it is called.
     */
    const handleGlobalSearch = (e: any): void => {
        setSearchKey(e.target.value);
    };

    /**
     * The deleteUserHandler function deletes selected users and displays a success message.
     */
    const deleteUserHandler = async (): Promise<void> => {
        const { error }: any = await deleteUser({ userIds: deleteUserIds });
        if (!error) {
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: ` ${deleteUserIds.length} user(s) Successfully deleted!`,
                    duration: 3000,
                })
            );
            deleteModalRef?.current?.modalHandler(false);
            tableRef?.current?.resetCheckboxes();
        }
    };

    /* The below code is defining a function called `getMenuItems` that returns an array of
    `UserMenuItem` objects. */
    const getMenuItems = (): UserMenuItem[] => {
        const menuItems = [];
        if (canInviteUser) {
            menuItems.push({
                title: 'Add User',
                icon: <PersonAdd className={classes.menuItem} />,
                onClick: navigationHandler('/user/add'),
                divider: true,
            });
        }
        if (canEnableDisableUser) {
            menuItems.push({
                className: actionButtonDisableHandler('ACTIVE'),
                title: 'Disable User(s)',
                icon: <PersonOff className={classes.menuItem} />,
                onClick: () => disableHandler(false),
            });
            menuItems.push({
                className: actionButtonDisableHandler('INACTIVE'),
                title: 'Enable User(s)',
                icon: <Person className={classes.menuItem} />,
                onClick: () => disableHandler(true),
            });
        }
        if (canDeleteUser) {
            menuItems.push({
                className:
                    !selectedIds.length || selectedIds?.some((id: any) => id === userProfileData?.data?.id)
                        ? classes.disabled
                        : '',
                title: ' Delete User(s)',
                icon: <PersonRemove className={classes.menuItem} />,
                onClick: () => multiUserDeleteModalHandler(true),
            });
        }
        if (canResetInvite) {
            menuItems.push({
                className: actionButtonDisableHandler('INVITED'),
                title: 'Resend Invitation',
                icon: <Send className={classes.menuItem} />,
                onClick: () => resendInvitationHandler(),
            });
        }
        return menuItems;
    };

    /**
     * The function `getUsersTableProps` returns an object with properties `total`, `data`, and
     * `isLoading` that are derived from the `allUsers` variable and other variables `isLoading` and
     * `isFetching`.
     */
    const getUsersTableProps = (): any => ({
        total: allUsers?.data?.total,
        data: allUsers?.data?.records,
        isLoading: isLoading || isFetching,
    });

    return (
        <React.Fragment>
            <header>
                <title>User</title>
                <meta name="description" content="Description of User" />
            </header>

            <Box className={classes.containerWrapper}>
                <>
                    <Stack direction={'row'} spacing={2} my={2} justifyContent={'space-between'} alignItems={'center'}>
                        <TextField
                            hiddenLabel
                            id="searchKey"
                            placeholder="Search..."
                            size="small"
                            onChange={handleGlobalSearch}
                            value={searchKey ?? ''}
                            InputProps={{
                                startAdornment: <Search id="search" sx={{ mr: '5px', color: '#727e84' }} />,
                                endAdornment: searchKey ? (
                                    <CancelOutlined
                                        sx={{ color: '#727e84', cursor: 'pointer' }}
                                        onClick={(): void => setSearchKey('')}
                                    />
                                ) : (
                                    ''
                                ),
                            }}
                        />

                        {
                            <Stack direction="row" spacing={1}>
                                {Object.keys(payload?.filters).map((key: string) => (
                                    <Chip
                                        key={`unique${key}`}
                                        label={key?.charAt(0).toUpperCase() + key.slice(1)}
                                        onDelete={(): void => tableRef?.current?.resetFilters(key, true)}
                                    />
                                ))}
                            </Stack>
                        }
                    </Stack>

                    <CustomTable
                        ref={tableRef}
                        handlePageChange={handlePaginationChange}
                        isPagination
                        controlledPageSize={payload?.page}
                        keyToTraverse="id"
                        handleCheckboxSelect={handleTableCheckboxes}
                        handleFilterChange={handleFilterChange}
                        headers={columns}
                        {...(canInviteUser && {
                            noDataFoundButtonAction: navigationHandler('/user/add'),
                            noDataFoundButtonText: 'Add User',
                        })}
                        noDataFoundIcon={<PersonOff fontSize={'inherit'} sx={{ color: Colors.gray[500] }} />}
                        noDataFoundTitle={'No Users found'}
                        {...getUsersTableProps()}
                    />
                </>
            </Box>
            <MultiItemsDeleteModal
                ref={deleteModalRef}
                deleteButtonText="Delete User(s)"
                deleteHandler={deleteUserHandler}
                handleIdsToBeDeleted={setDeleteUserIds}
                isLoading={isDeleteUserLoading}
                key="33e1dadaef42"
                deleteModalDescription={
                    <>
                        {selectedIds?.length} user(s) and all of their profile information will be permanently deleted.
                        This will not remove log entries or reverse changes they previously made to the system
                    </>
                }
                keyToTraverse="id"
                filteredData={filteredUsers ?? []}
                deleteModalHeading="Delete User(s)?"
                selectedIds={selectedIds}
                modalBodyText={
                    "Select 'Continue' to permanently delete the selected user(s) and all of their profile information. This will not remove log entries or reverse changes they previously made to the system."
                }
                modalTitle="Delete User(s)"
            />
        </React.Fragment>
    );
};
