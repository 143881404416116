export const DOCUMENTS_LIST = {
    manuals: [
        {
            label: '9395 UPS Quickstart Guide',
            date: 'APR 2023',
            fileName: 'UP9395040012EN.pdf',
            isNew: true,
        },
        {
            label: '9395 UPS Troubleshooting Guide',
            date: 'MAR 2023',
            fileName: 'UP9395040012ENTTS.pdf',
        },
        {
            label: '9395 UPS User Guide',
            date: 'JAN 2023',
            fileName: 'UPSG1200REV5-5.pdf',
        },
        {
            label: 'UPS Manual REV5.5',
            date: 'DEC 2022',
            fileName: 'UP9395040012EN-9.pdf',
        },
        {
            label: 'UPS Manual REV5.5',
            date: 'DEC 2022',
            fileName: 'UP9395040012EN-9.pdf',
        },
    ],
    userGuides: [
        {
            label: 'Data Center Layout - Seattle Datacenter',
            date: 'APR 2023',
            fileName: 'WA-SEA-DC-Layout-041223.pdf',
            isNew: true,
        },
        {
            label: 'Shutdown Procedure - Seattle Datacenter',
            date: 'NOV 2023',
            fileName: 'WA-SEA-DC-SP10250218-112322.pdf',
        },
        {
            label: 'Startup Procedure - Seattle Datacenter',
            date: 'AUG 2023',
            fileName: 'Or-PDX-DC-SP10250218-112322.pdf',
        },
        {
            label: 'Online Diagram - Seattle Datacenter',
            date: 'DEC 2022',
            fileName: 'WA-SEA-DC-OL102-112322.pdf',
        },
    ],
    warrantyDocuments: [
        {
            label: 'Product Warranty Document Example',
            date: 'APR 2023',
            fileName: 'prod-war-example-030123.pdf',
            isNew: true,
        },
        {
            label: '9395 UPS User Guide',
            date: 'OCT 2023',
            fileName: 'prod-war-example-010123.pdf',
        },
    ],
    webResources: [
        {
            label: '9395 UPS Type 1 <"Product ID"> Product Page',
            desc: 'for additional product resources visit the product page.',
        },
        {
            label: '9395 UPS Type 1 <"Product ID"> Product Page',
            desc: 'for additional product resources visit the product page.',
        },
    ],
};
