import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import React from 'react';

/**
 * The `DeleteModalProps` type defines the props for a delete modal component in a TypeScript React
 * application.
 * @property {boolean} isVisible - A boolean value indicating whether the delete modal is visible or
 * not.
 * @property closeHandler - A function that will be called when the modal is closed.
 * @property {string} wrapperClass - The `wrapperClass` property is a string that represents the CSS
 * class name for the wrapper element of the delete modal component.
 * @property {string} titleClass - The `titleClass` property is a string that represents the CSS class
 * to be applied to the title of the delete modal.
 * @property {string} headingText - The `headingText` property is a string that represents the text to
 * be displayed as the heading of the delete modal.
 * @property {string} confirmButtonClass - The `confirmButtonClass` property is an optional string that
 * represents the CSS class to be applied to the confirm button in the delete modal.
 * @property confirmClick - The `confirmClick` property is a function that will be called when the user
 * clicks on the confirm button in the delete modal.
 * @property {string} actionClass - The `actionClass` property is a string that represents the CSS
 * class to be applied to the action section of the delete modal.
 * @property description - The `description` property is a JSX element that represents the content or
 * message to be displayed in the delete modal. It can contain any valid JSX content such as text, HTML
 * tags, or other React components.
 * @property {string} confirmButtonText - The `confirmButtonText` property is a string that represents
 * the text to be displayed on the confirm button in the delete modal.
 */
type DeleteModalProps = {
    isVisible: boolean;
    closeHandler: () => void;
    wrapperClass: string;
    titleClass: string;
    headingText: string;
    confirmButtonClass?: string;
    confirmClick: () => void;
    actionClass: string;
    description: JSX.Element;
    confirmButtonText: string;
};

/* The code block defines a functional component called `ConfirmModal`. It takes in several props of
type `DeleteModalProps` and returns a JSX element. */
const ConfirmModal = ({
    isVisible,
    closeHandler,
    wrapperClass,
    titleClass,
    headingText,
    confirmButtonClass,
    confirmClick,
    actionClass,
    description,
    confirmButtonText,
}: DeleteModalProps): JSX.Element => (
    <Dialog
        open={isVisible}
        onClose={closeHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={wrapperClass}
    >
        <DialogTitle id="alert-dialog-title" className={titleClass}>
            <Typography variant={'h6'}> {headingText ?? 'Delete Users?'}</Typography>
        </DialogTitle>
        <DialogContent>
            <Typography variant={'body1'} mb={2}>
                {description}
            </Typography>
        </DialogContent>
        <DialogActions className={actionClass}>
            <Stack flexDirection={'row'} justifyContent={'end'} alignItems={'center'}>
                <Button variant={'outlined'} onClick={closeHandler} sx={{ mr: 2 }}>
                    Cancel
                </Button>
                <Button variant={'contained'} onClick={confirmClick} className={confirmButtonClass}>
                    {confirmButtonText}
                </Button>
            </Stack>
        </DialogActions>
    </Dialog>
);

export default ConfirmModal;
