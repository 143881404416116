import React from 'react';
import {
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    DialogContentText,
    Box,
    Avatar,
    Divider,
    DialogActions,
    Button,
    List,
} from '@mui/material';
import { Organization } from '@fiji/common/src/types';
import { getUserInitials } from '../../CommonUtils';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { useGetPaginatedOrgQuery } from '@fiji/common/src/features/orgManagement/orgApi';
import Loader from '../../components/Loader';

type OrgModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    submitHandler: () => Promise<void>;
};

export const AllOrgApplyConfirmModal = (props: OrgModalProps): JSX.Element => {
    const [orgFilter, setOrgFilter] = React.useState<{ page: number; size: number }>({ page: 0, size: 25 });
    const { data, isFetching } = useGetPaginatedOrgQuery(orgFilter, { refetchOnMountOrArgChange: true });
    React.useEffect(() => {
        if (!props.isOpen) {
            setOrgFilter({ page: 0, size: 25 });
        }
    }, [props.isOpen]);
    return (
        <>
            <Dialog
                open={props?.isOpen}
                onClose={props?.closeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="modal-apply-all-notification"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="body1" className="modal-title">
                        Apply Your Preferences to All Organizations?
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '16px', fontWeight: '400', color: '#424E54' }}>
                        Your Preferences for this Organization will be applied to your account on the following
                        Organizations.
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {data?.data?.records?.map((org: Organization) => (
                            <Box key={org?.id}>
                                <InfoListItem
                                    icon={
                                        <Avatar alt={org?.name} src={org?.logo ?? ''}>
                                            {org?.name && getUserInitials(org?.name)}
                                        </Avatar>
                                    }
                                    title={org?.name}
                                    divider="partial"
                                    subtitleSeparator={'/'}
                                    iconAlign={'center'}
                                    subtitle={`${org?.groupCount} Groups, ${org?.deviceCount} Devices`}
                                />
                            </Box>
                        ))}
                        {orgFilter?.page + 1 < Math.ceil(data?.data?.total / orgFilter?.size) && (
                            <List>
                                <InfoListItem
                                    data-testid="infoListItem"
                                    data-cy={'list-content'}
                                    title={
                                        !isFetching && (
                                            <Typography sx={{ color: '#007bc1', fontWeight: 'bold' }}>
                                                Show More...
                                            </Typography>
                                        )
                                    }
                                    avatar
                                    iconAlign="center"
                                    hidePadding
                                    sx={{ textAlign: 'center' }}
                                    onClick={(): void => setOrgFilter((prev) => ({ ...prev, page: prev.page + 1 }))}
                                />
                                {isFetching && <Loader size={30} />}
                            </List>
                        )}
                    </DialogContentText>
                </DialogContent>
                <Divider />
                <DialogActions sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', p: 2 }}>
                    <Button
                        variant="outlined"
                        onClick={props.closeModal}
                        sx={{ borderColor: 'rgba(66, 78, 84, 0.12)', mr: 1 }}
                    >
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={props.submitHandler} autoFocus>
                        Apply
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
