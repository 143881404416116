/**
 * The function `getUserInitials` takes a name as input and returns the initials of the first and last
 * name capitalized.
 * @param {string} name - A string representing the user's full name.
 * @returns The function `getUserInitials` returns a string representing the initials of a given name.
 */
const getUserInitials = (name: string): string => {
    if (name) {
        const names = name.split(' ');
        let initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    }
    return '';
};

const getFullName = (firstName: string, lastName: string): string => {
    if (!firstName && !lastName) {
        return `-`;
    }
    return `${firstName ?? ''} ${lastName ?? ''}`;
};

/**
 * The function takes a string as input and returns the same string with the first letter converted to
 * uppercase.
 * @param {string} str - The parameter `str` is a string that represents the input text.
 */
const toFirstLetterUpperCase = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

/**
 * The function checks if a given value or array of values contains only whitespace characters.
 * @param {any} data - The `data` parameter is of type `any`, which means it can accept any data type.
 * @returns The function `handleWhiteSpaces` returns a boolean value.
 */
const handleWhiteSpaces = (data: any): any => {
    if (data === '' || data === null) {
        return true;
    } else if (typeof data === 'string') {
        return Boolean(data?.trim());
    } else if (Array.isArray(data)) {
        return data.every((str: string) => (str === '' || str === null ? true : Boolean(str?.trim())));
    }
    return false;
};

/**
 * The function `createNumArray` creates an array of numbers from 1 to the specified length.
 * @param {number} length - The `length` parameter is a number that represents the desired length of
 * the array to be created.
 * @returns The function `createNumArray` returns an array of numbers.
 */
const createNumArray = (length: number): number[] => {
    const data: number[] = [];
    for (let i = 0; i < length; i++) {
        data.push(i + 1);
    }
    return data;
};

/**
 * The function `getEventColor` takes a key as input and returns a corresponding color code based on
 * the key.
 * @param {string} key - The `key` parameter is a string that represents the type of event.
 * @returns The function `getEventColor` returns a string value. The specific string value that is
 * returned depends on the value of the `key` parameter passed to the function. If the `key` parameter
 * is equal to 'Alarm', the function returns the string '#CA3C3D'. If the `key` parameter is equal to
 * 'Offline', the function returns the string '#69B1C
 */
const getEventColor = (key: string): string => {
    /* The `switch (key)` statement is used to evaluate the value of the `key` variable and execute
    different code blocks based on different cases. Each `case` represents a possible value of the
    `key` variable. If the value of `key` matches a `case`, the corresponding code block is
    executed. If none of the `case` values match the value of `key`, the code block under the
    `default` case is executed (if present). */
    switch (key) {
        case 'alarms': {
            return '#CA3C3D';
        }
        case 'offlineDevices': {
            return '#69B1C3';
        }
        case 'warnings': {
            return '#F0AA1F';
        }
        default: {
            return '#007bc1';
        }
    }
};

/**
 * The function `getEventsLabel` takes a key as input and returns a corresponding label for events.
 * @param {string} key - The `key` parameter is a string that represents the type of event. It can have
 * one of the following values: 'warnings', 'alarms', 'information', or 'offlineDevices'.
 * @returns The function `getEventsLabel` returns a string value. The specific string value that is
 * returned depends on the input `key` parameter. If the `key` parameter matches one of the cases in
 * the switch statement, the corresponding label string is returned. If the `key` parameter does not
 * match any of the cases, an empty string is returned.
 */
const getEventsLabel = (key: string): string => {
    switch (key) {
        case 'warnings': {
            return 'Warnings';
        }
        case 'alarms': {
            return 'Alarms';
        }
        case 'information': {
            return 'Info';
        }
        case 'offlineDevices': {
            return 'Offline';
        }
        default: {
            return '';
        }
    }
};

/**
 * The function `getEventIconStyle` returns an object with color and font size properties based on the
 * provided key and size parameters.
 * @param {string} key - The `key` parameter is a string that represents the type or category of an
 * event.
 * @param {number} size - The size parameter is a number that represents the font size in pixels.
 */
const getEventIconStyle = (key: string, size: number): object => ({ color: getEventColor(key), fontSize: `${size}px` });

/**
 * The function `getTimelineDetailsStyle` returns an object with CSS styles for a timeline event based
 * on a given key.
 * @param {string} key - The `key` parameter is a string that represents the key of an event.
 */
const getTimelineDetailsStyle = (key: string): object => ({
    color: '#fff',
    backgroundColor: getEventColor(key),
    padding: '6px',
    borderRadius: '50%',
    height: '40px',
    width: '40px',
});

/**
 * The function `getPriorityEvent` returns the highest priority event based on the properties of the
 * `source` object.
 * @param {any} source - The `source` parameter is an object that represents some source of data. It is
 * expected to have a `stats` property, which is also an object. The `stats` object may have properties
 * such as `alarms`, `offlineDevices`, or `warnings`. The function `getPriorityEvent
 * @returns The function `getPriorityEvent` returns a string value.
 */
const getPriorityEvent = (source: any): string => {
    if (source?.stats?.alarms) {
        return 'alarms';
    } else if (source?.stats?.offlineDevices || source?.status === 'Offline') {
        return 'offlineDevices';
    } else if (source?.stats?.warnings) {
        return 'warnings';
    }
    return '';
};

/**
 * The function `downloadFileFromLink` creates a hidden link element, sets the link and filename
 * attributes, appends it to the document body, triggers a click event to download the file, and then
 * removes the link element from the document body.
 * @param {string} link - The `link` parameter is a string that represents the URL of the file you want
 * to download. It should be a valid URL that points to the file you want to download.
 * @param {string} filename - The `filename` parameter is a string that represents the desired name of
 * the downloaded file.
 */
const downloadFileFromLink = (link: string, filename: string): void => {
    if (link) {
        const element = document.createElement('a');
        element.setAttribute('href', link);
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }
};

const encodeBase64 = (dataToEncode: string): string => Buffer.from(dataToEncode).toString('base64');
const decodeBase64 = (encodedData: string): string => Buffer.from(encodedData, 'base64').toString();

export {
    getUserInitials,
    handleWhiteSpaces,
    createNumArray,
    toFirstLetterUpperCase,
    downloadFileFromLink,
    getEventColor,
    getEventsLabel,
    getEventIconStyle,
    getTimelineDetailsStyle,
    getPriorityEvent,
    getFullName,
    encodeBase64,
    decodeBase64,
};
