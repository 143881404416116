import React, { memo } from 'react';
import Dialog from '@mui/material/Dialog';
import { Button, DialogActions, DialogTitle, DialogContent, MobileStepper, Stack, Typography } from '@mui/material';
import Loader from './Loader';

/* The code is defining a functional component called `StepperModal` using React and TypeScript. It is
a modal component that displays a series of steps using a stepper. The component receives props,
including `isOpen` (a boolean indicating whether the modal is open), `modalContent` (an array of
objects representing each step in the modal), `getCurrentStep` (a function to get the current step),
`submitHandler` (a function to handle form submission), `closeModal` (a function to close the
modal), and `isDisabled` (a boolean indicating whether the next button should be disabled). */

const StepperDialogActions = ({
    modalContent,
    currentStepIndex,
    isLoading,
    isDisabled,
    extraButton,
    gotoNextStep,
    gotoBackStep,
}: {
    modalContent: any[];
    currentStepIndex: number;
    isLoading: boolean;
    isDisabled: boolean;
    extraButton: any;
    gotoNextStep: () => void;
    gotoBackStep: () => void;
}): JSX.Element => {
    const getBackActionButtonLabel = (): string => {
        if (currentStepIndex === 0) {
            return modalContent[currentStepIndex].backButtonLabel ?? 'Cancel';
        }
        return modalContent[currentStepIndex].backButtonLabel ?? 'Back';
    };

    const getNextActionButtonLabel = (): string => {
        if (modalContent.length - 1 === currentStepIndex) {
            return modalContent[currentStepIndex].nextButtonLabel ?? 'Finish';
        }
        return modalContent[currentStepIndex].nextButtonLabel ?? 'Next';
    };

    return (
        <DialogActions className="modal-actions">
            <MobileStepper
                style={{ height: '100%', background: 'transparent', boxShadow: 'none' }}
                steps={modalContent?.length}
                activeStep={currentStepIndex}
                variant="dots"
                position="static"
                backButton={
                    <Button variant="outlined" onClick={gotoBackStep} disabled={isLoading}>
                        {getBackActionButtonLabel()}
                    </Button>
                }
                nextButton={
                    <Stack direction={'row'} spacing={2}>
                        {extraButton}
                        <Button variant="contained" onClick={gotoNextStep} disabled={isDisabled || isLoading}>
                            {getNextActionButtonLabel()}
                        </Button>
                    </Stack>
                }
            />
        </DialogActions>
    );
};

export const StepperModal = memo((props: any): JSX.Element => {
    const [currentStep, setCurrentStep] = React.useState(props?.modalContent[0].key);

    /**
     * The function `getCurrentStepIndex` returns the index of the current step in the `modalContent`
     * array.
     */
    const getCurrentStepIndex = (): number => props?.modalContent.findIndex((step: any) => step.key === currentStep);

    /* The `React.useEffect` hook is used to perform side effects in a functional component. In this
    case, it is used to call the `getCurrentStep` function from the props whenever the `currentStep`
    state variable changes. */
    React.useEffect(() => {
        props?.getCurrentStep(currentStep);
    }, [currentStep]);

    React.useEffect(() => {
        if (props?.isOpen) setCurrentStep(props?.modalContent[0].key);
    }, [props?.isOpen]);

    /**
     * The function `gotoNextStep` updates the current step in a modal and performs additional actions
     * if it is the last step.
     */
    const gotoNextStep = (): void => {
        const currentStepIndex = getCurrentStepIndex();
        if (props?.modalContent?.length - 1 > currentStepIndex) {
            setCurrentStep(props?.modalContent[currentStepIndex + 1].key);
        } else {
            props.submitHandler();
            if (props.closeModalOnFinish !== false) {
                props.closeModal();
                setCurrentStep(props?.modalContent[0].key);
            }
        }
    };

    /**
     * The function `gotoBackStep` is used to navigate to the previous step in a modal, and if there is
     * no previous step, it closes the modal.
     */
    const gotoBackStep = (): void => {
        const currentStepIndex = getCurrentStepIndex();
        if (currentStepIndex > 0) {
            setCurrentStep(props?.modalContent[currentStepIndex - 1].key);
        } else {
            props.closeModal();
            setCurrentStep(props?.modalContent[0].key);
        }
    };

    /**
     * The function `getContent` returns the component from `props.modalContent` based on the current step
     * index.
     */
    const getContent = (): JSX.Element => props?.modalContent[getCurrentStepIndex()]?.component;

    /**
     * The function returns the label for a back action button based on the current step index.
     * @returns The function `getBackActionButtonLabel` returns a string value. If the
     * `currentStepIndex` is 0, it returns the string 'Cancel'. Otherwise, it returns the string
     * 'Back'.
     */

    return (
        <>
            <Dialog
                open={props?.isOpen}
                aria-describedby="alert-dialog-slide-description"
                className="custom-modal-width"
                classes={props.classes}
            >
                <DialogTitle className="modal-header">
                    <Typography variant="body2" className="modal-greyhead-text modal-header-title">
                        {props?.modalContent[getCurrentStepIndex()]?.title}
                    </Typography>
                    <Typography variant="h6">{props?.modalContent[getCurrentStepIndex()]?.header}</Typography>
                    {props?.modalContent[getCurrentStepIndex()]?.description && (
                        <Typography variant="body1" className="modal-description">
                            {props?.modalContent[getCurrentStepIndex()]?.description}
                        </Typography>
                    )}
                </DialogTitle>
                <DialogContent className="modal-content">{props?.isLoading ? <Loader /> : getContent()}</DialogContent>
                <StepperDialogActions
                    currentStepIndex={getCurrentStepIndex()}
                    modalContent={props.modalContent}
                    gotoBackStep={gotoBackStep}
                    gotoNextStep={gotoNextStep}
                    isLoading={props?.isLoading}
                    isDisabled={props?.isDisabled}
                    extraButton={props?.extraButton}
                />
            </Dialog>
        </>
    );
});
