import React from 'react';
import { TextField, Typography, Grid, Divider, InputAdornment, IconButton, Avatar } from '@mui/material';
import { ContentCopy, CorporateFare } from '@mui/icons-material';
import { InfoListItem, EmptyState } from '@brightlayer-ui/react-components';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import Switch from '@mui/material/Switch';
import { useRBAC } from '../../hooks';
import { useStyles } from './style';
export const OrganizationDetails = ({
    currentOrg,
    orgData,
    isImageUploaded,
    logoPreview,
    getFileUploadHandlerActions,
    handleChangeOrgData,
    copyCodeHandler,
}: {
    currentOrg: any;
    orgData: any;
    isImageUploaded: boolean;
    logoPreview: string | null;
    getFileUploadHandlerActions: () => any;
    handleChangeOrgData: (e: any) => void;
    copyCodeHandler: () => void;
}): JSX.Element => {
    const classes = useStyles();
    const rolePermissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(rolePermissions);
    const canUploadLogo = hasPermission('upload-organization-image');
    const canUpdateOrganization = hasPermission('update-organization');
    return (
        <Grid container className={classes.muiGridRoot}>
            <Grid item xs={8} className={classes.textFieldGroup}>
                {canUpdateOrganization ? (
                    <>
                        <TextField
                            className={classes.textField}
                            id={'organization'}
                            label={'Organization Name'}
                            fullWidth
                            required
                            variant={'filled'}
                            value={orgData?.name || ''}
                            name="name"
                            onChange={handleChangeOrgData}
                        />
                        <TextField
                            id={'crganizationCode'}
                            label={'Organization Code'}
                            fullWidth
                            required
                            variant={'filled'}
                            helperText={'This code cannot be changed'}
                            value={currentOrg?.orgCode || ''}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position={'end'}>
                                        <IconButton className={classes.copyIcon} size="large" onClick={copyCodeHandler}>
                                            <ContentCopy />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </>
                ) : (
                    <>
                        <Typography variant={'subtitle1'}>{currentOrg?.name || ''}</Typography>
                        <Typography variant={'body2'} sx={{ pb: 2 }}>
                            {'Organization Name'}
                        </Typography>
                        <Divider />
                        <Typography sx={{ position: 'relative' }}>
                            <Typography variant={'subtitle1'} sx={{ pt: 2 }}>
                                {currentOrg?.orgCode || ''}
                            </Typography>
                            <Typography variant={'body2'} sx={{ pb: 2 }}>
                                {'Organization Code'}
                            </Typography>
                            <IconButton
                                className={classes.copyIcon}
                                size="large"
                                onClick={copyCodeHandler}
                                sx={{ position: 'absolute', top: '25px', right: '0px' }}
                            >
                                <ContentCopy />
                            </IconButton>
                        </Typography>
                        <Divider />
                    </>
                )}
                <InfoListItem
                    sx={{ pr: 0, ...(canUpdateOrganization ? {} : { pl: 0 }) }}
                    data-testid="infoListItem"
                    className={classes.infoListItem}
                    data-cy={'list-content'}
                    title={<Typography variant={'body1'}>{'Require Two-factor Authentication (2FA)'}</Typography>}
                    avatar={false}
                    iconAlign="center"
                    hidePadding
                    rightComponent={
                        <Switch
                            checked={orgData?.mfa}
                            name="mfa"
                            disabled={!canUpdateOrganization}
                            onChange={handleChangeOrgData}
                        />
                    }
                />
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={4} className={classes.muiGridItem}>
                <EmptyState
                    className={classes.emptyState}
                    icon={
                        <Avatar src={logoPreview ?? (isImageUploaded ? currentOrg?.logo : null) ?? ''}>
                            <CorporateFare sx={{ height: '55px', width: '55px' }} />
                        </Avatar>
                    }
                    title={''}
                    {...(canUploadLogo && {
                        actions: getFileUploadHandlerActions(),
                        description: <Typography variant="caption">{'PNG, JPG (300x300px), 2 MB Max.'}</Typography>,
                    })}
                />
            </Grid>
        </Grid>
    );
};
