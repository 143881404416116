import React, { useEffect } from 'react';
import { DialogContent, FormControl, Stack, Typography, Checkbox, Button } from '@mui/material';
import { Add, Workspaces } from '@mui/icons-material';
import { EmptyState } from '@brightlayer-ui/react-components';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { addGroupModal, selectHierarchyData } from '@fiji/common/src/features/group/groupSlice';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useGetHierarchyHandlers, useIsMount, useRBAC } from '../../hooks';
import Hierarchy from '../../components/Hierarchy';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import Loader from '../../components/Loader';
import { api } from '@fiji/common/src/app/api/baseApi';

export const SelectGroups = React.memo((props: any): JSX.Element => {
    const currentOrg = useTypedSelector(selectedOrg);
    const isMount = useIsMount();
    const dispatch = useAppDispatch();
    const rolePermissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(rolePermissions);
    const canCreateGroup = hasPermission('create-group');

    const hierarchyData = useTypedSelector(selectHierarchyData);

    const { selectedGroups, handleSelectGroup, getAllChildrenIds, groupSelectionHandler } = useGetHierarchyHandlers({});

    React.useEffect(() => {
        if (currentOrg?.id) {
            dispatch((api as any).endpoints.getGroupsHierarchy.initiate(null));
        }
    }, []);

    /* The `useEffect` hook is used to perform side effects in a functional component. In this case,
    the effect is triggered whenever the `selectedGroups` variable changes. */
    useEffect(() => {
        if (selectedGroups && !isMount) {
            props?.setPayload((prev: any) => ({
                ...prev,
                groupIds: selectedGroups,
            }));
        }
    }, [selectedGroups]);

    /* The `useEffect` hook in the code snippet is used to update the selected groups when the
    `props.payload.groupIds` value changes. */
    useEffect(() => {
        if (props?.payload?.groupIds?.toString() !== selectedGroups?.toString()) {
            groupSelectionHandler(props?.payload?.groupIds);
        }
    }, [props?.payload?.groupIds]);

    /**
     * The function `allCheckHandler` handles the logic for selecting or deselecting all children in a
     * hierarchical group structure.
     * @param {any} e - The parameter `e` is an event object that is passed to the `allCheckHandler`
     * function. It is typically an event object that is triggered when a checkbox is checked or
     * unchecked.
     */
    const allCheckHandler = (e: any): void => {
        if (e.target.checked) {
            const resultArr = getAllChildrenIds(hierarchyData?.data?.data?.data?.[0])?.flat(Infinity);
            handleSelectGroup(resultArr, hierarchyData?.data?.data?.data);
        } else {
            handleSelectGroup([], hierarchyData?.data?.data?.data);
        }
    };

    /**
     * The function `isAllChecked` checks if all children in a hierarchy are selected.
     */
    const isAllChecked = (): boolean =>
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        getAllChildrenIds(hierarchyData?.data?.data?.data?.[0])?.flat(Infinity)?.length === selectedGroups?.length;

    /**
     * The function `handleOpenAddGroupModal` dispatches an action to open a modal for adding a group,
     * with an optional group ID parameter.
     * @param {string} [groupId] - The `groupId` parameter is an optional string that represents the ID
     * of a group.
     */
    const handleOpenAddGroupModal = (groupId?: string): any =>
        dispatch(addGroupModal({ isOpen: true, groupId: groupId ?? '' }));

    const getGroupList = (): JSX.Element =>
        hierarchyData?.data?.data?.data?.length ? (
            <FormControl variant={'filled'} required fullWidth>
                <Stack
                    direction={'row'}
                    sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid rgba(66, 78, 84, 0.12)',
                        borderTop: '1px solid rgba(66, 78, 84, 0.12)',
                    }}
                >
                    <Typography variant="body2" className="modal-blue-header">
                        All Groups
                    </Typography>
                    <Checkbox
                        indeterminate={!isAllChecked() && selectedGroups?.length}
                        checked={isAllChecked()}
                        sx={{ marginRight: '16px' }}
                        onChange={allCheckHandler}
                    />
                </Stack>
                <Hierarchy
                    multiSelectionKey="children"
                    selectedNodes={selectedGroups}
                    onChangeTreeItem={(value: any, key?: string, type?: string, isChecked?: boolean): void =>
                        handleSelectGroup(value, hierarchyData?.data?.data?.data, type, isChecked, key)
                    }
                    isMultiSelect
                    hierarchyData={hierarchyData?.data?.data?.data}
                    customIterator={['children']}
                />
            </FormControl>
        ) : (
            <EmptyState
                icon={<Workspaces fontSize={'inherit'} className="my-svg-icon" />}
                title={'No Groups'}
                actions={
                    <Stack direction={'column'} spacing={2}>
                        {canCreateGroup && (
                            <Button
                                variant={'outlined'}
                                color={'primary'}
                                startIcon={<Add />}
                                onClick={(): void => handleOpenAddGroupModal()}
                            >
                                {' '}
                                Add a Group{' '}
                            </Button>
                        )}
                    </Stack>
                }
                description={'You can add groups to better organize Devices.'}
            />
        );

    return (
        <DialogContent sx={{ padding: '0', marginTop: '0', height: '100%' }}>
            {hierarchyData?.isLoading ? <Loader /> : getGroupList()}
        </DialogContent>
    );
});
