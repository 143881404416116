import React from 'react';
import {
    Box,
    Checkbox,
    DialogContent,
    DialogContentText,
    Divider,
    Stack,
    Switch,
    TextField,
    Typography,
    InputAdornment,
} from '@mui/material';
import { useGetAllEventsQuery } from '@fiji/common/src/features/events/eventsApi';
import Loader from '../../components/Loader';
import { EmptyState } from '@brightlayer-ui/react-components';
import { Ups } from '@brightlayer-ui/icons-mui';
import { NUMBER_REGEX } from '@fiji/common/src/constants';

const SelectEventContents = ({
    allEventsCounts,
    props,
    allEvents,
}: {
    allEventsCounts: any;
    props: any;
    allEvents: any;
}): JSX.Element => {
    /**
     * The `payloadHandler` function is a TypeScript React function that handles changes in an input
     * element and updates the payload state accordingly.
     * @param e - The parameter `e` is of type `React.ChangeEvent<HTMLInputElement>`, which represents
     * the event object triggered by a change in an input element. It contains information about the
     * event, such as the target element and its value.
     * @param {string} [eventId] - The `eventId` parameter is a string that represents the ID of an
     * event.
     * @returns The function does not have a return statement.
     */
    const payloadHandler = (e: React.ChangeEvent<HTMLInputElement>, eventId?: string): void => {
        const payloadClone = JSON.parse(JSON.stringify(props?.payload));
        const uncheckedIndex = payloadClone?.eventIds?.indexOf(eventId);
        if (e.target?.id === 'delay') {
            if (e.target.value.length && !NUMBER_REGEX.test(e.target.value)) {
                return;
            }
            if (+e?.target.value < 86400) {
                props?.setPayload((prev: any) => ({
                    ...prev,

                    [e.target.id]: e.target.value,
                }));
            }
            return;
        }
        if (e.target.checked) {
            payloadClone?.eventIds?.push(eventId);
        } else {
            payloadClone?.eventIds?.splice(uncheckedIndex, 1);
        }

        props.setPayload(payloadClone);
    };

    /**
     * The function `handleSelectAll` toggles the selection of all child event IDs based on the current
     * state of the payload.
     */
    const handleSelectAll = (): void => {
        if (props?.payload?.eventIds?.length === allEventsCounts) {
            props?.setPayload((prev: any) => ({ ...prev, eventIds: [] }));
        } else {
            const allChildEventIds: string[] = [];
            if (allEvents?.data?.records?.length) {
                allEvents?.data?.records?.forEach((event: any) => {
                    if (event?.child?.length) {
                        event?.child?.forEach((childEvent: any) => {
                            allChildEventIds.push(childEvent?.id);
                        });
                    }
                });
            }
            props?.setPayload((prev: any) => ({
                ...prev,
                eventIds: allChildEventIds,
            }));
        }
    };

    if (allEventsCounts) {
        return (
            <DialogContentText id="alert-dialog-slide-description">
                {props?.isMulti && (
                    <>
                        {props?.isMulti && (
                            <>
                                <Divider />
                                <Box className="modal-space-between" sx={{ padding: '16px' }}>
                                    <Box className="modal-align-center">
                                        <Switch
                                            checked={props?.payload?.isAdvanced}
                                            onChange={(): void => {
                                                props.setPayload({
                                                    ...props.payload,
                                                    isAdvanced: !props?.payload?.isAdvanced,
                                                    delay: !props?.payload?.isAdvanced ? props?.payload?.delay : 30,
                                                });
                                            }}
                                        />
                                        <Typography variant="body1" className="modal-greyhead-text">
                                            Advanced
                                        </Typography>
                                    </Box>
                                    <Box sx={{ maxWidth: '70px', marginRight: '10px' }}>
                                        <TextField
                                            id="delay"
                                            label="Delay"
                                            variant="filled"
                                            className="delayed-input-class"
                                            disabled={!props?.payload?.isAdvanced}
                                            value={props?.payload?.delay}
                                            onChange={payloadHandler}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">s</InputAdornment>,
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </>
                        )}

                        <Divider />
                    </>
                )}
                <Box
                    sx={{
                        borderTop: '1px solid rgba(66, 78, 84, 0.12)',
                        borderBottom: '1px solid rgba(66, 78, 84, 0.12)',
                        padding: '8px 0',
                    }}
                    className="modal-space-between"
                >
                    <Typography variant="body2" className="modal-blue-header">
                        All Events
                    </Typography>
                    <Checkbox
                        sx={{ paddingRight: '17px' }}
                        checked={
                            Boolean(props?.payload?.eventIds?.length) &&
                            props?.payload?.eventIds?.length === allEventsCounts
                        }
                        indeterminate={
                            Boolean(props?.payload?.eventIds?.length) &&
                            props?.payload?.eventIds?.length < allEventsCounts
                        }
                        onChange={handleSelectAll}
                    />
                </Box>
                <Stack>
                    {allEvents?.data?.records?.map(
                        (event: any): JSX.Element => (
                            <>
                                <Box className="modal-space-between" sx={{ padding: '8px' }}>
                                    <Box className={`modal-align-center`}>
                                        <Stack sx={{ paddingLeft: '8px' }}>
                                            <Typography variant="body1" className={`modal-greyhead-text`}>
                                                {event?.name}
                                            </Typography>
                                            <Typography variant="body2" className="modal-para">
                                                {event?.description}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                    {event?.child?.length && (
                                        <>
                                            <Stack
                                                sx={{
                                                    paddingLeft: '8px',
                                                    width: '65%',
                                                }}
                                                direction={'row'}
                                            >
                                                {event.child.map((childEvent: any) => (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            width: '50%',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}
                                                        key={childEvent?.id}
                                                    >
                                                        <Checkbox
                                                            checked={props?.payload?.eventIds?.includes(childEvent?.id)}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                                                payloadHandler(e, childEvent?.id)
                                                            }
                                                        />
                                                        <Typography variant="body1" className="modal-greyhead-text">
                                                            {childEvent?.name}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Stack>
                                        </>
                                    )}
                                </Box>
                                <Divider />
                            </>
                        )
                    )}
                </Stack>
            </DialogContentText>
        );
    }
    return <EmptyState icon={<Ups fontSize={'inherit'} className="my-svg-icon" />} title={'No Events'} />;
};

export const SelectEvents = React.memo((props: any): JSX.Element => {
    const { data: allEvents, isLoading, isFetching }: any = useGetAllEventsQuery();

    const [allEventsCounts, setAllEventsCounts] = React.useState(0);

    React.useEffect(() => {
        if (allEvents) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            let count = 0;
            if (allEvents?.data?.records?.length) {
                allEvents?.data?.records?.forEach((event: any) => {
                    if (event?.child?.length) {
                        event?.child?.forEach(() => {
                            count++;
                        });
                    }
                });
            }
            setAllEventsCounts(count);
        }
    }, [allEvents]);

    /**
     * The function `payloadHandler` is a TypeScript React function that handles changes in an input
     * element and updates a payload object accordingly.
     * @param e - The parameter `e` is of type `React.ChangeEvent<HTMLInputElement>`, which represents
     * the event object generated when the input element's value changes. It contains information about
     * the event, such as the target element and the new value.
     * @param {string} [eventId] - The `eventId` parameter is a string that represents the ID of an
     * event.
     * @returns `void`, which means it does not return any value.
     */

    /**
     * The function `handleSelectAll` toggles the selection of all eventIds in the payload object based
     * on whether all eventIds are currently selected or not.
     */

    return (
        <DialogContent sx={{ padding: '0 !important', marginTop: '0' }}>
            {isLoading || isFetching ? (
                <Loader />
            ) : (
                <SelectEventContents props={props} allEventsCounts={allEventsCounts} allEvents={allEvents} />
            )}
        </DialogContent>
    );
});
