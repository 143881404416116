import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GroupHierarchy } from '../../types/GroupHierarchy';
import { GroupModal } from '../../types';
import { RootState } from '../../app/store';

const initialGroupsState = <any>[];
const initialGroupModalState: GroupModal = {
    isOpen: false,
    groupId: '',
};

type GroupDeviceModalType = {
    isOpen: boolean;
    groupId: string;
    deviceId: string | string[];
    destinationGroupId?: string;
    destinationGroupName?: string;
};

const initialGroupsDevicesModalState: GroupDeviceModalType = {
    isOpen: false,
    groupId: '',
    deviceId: '',
    destinationGroupId: '',
    destinationGroupName: '',
};

const groupSlice = createSlice({
    name: 'group',
    initialState: {
        addGroupModal: initialGroupModalState,
        deleteGroupModal: initialGroupModalState,
        groupHierarchy: initialGroupsState,
        moveGroupsDevicesModal: initialGroupsDevicesModalState,
        hierarchyData: { data: undefined, isLoading: true },
    },
    reducers: {
        addGroupModal: (state, { payload }: PayloadAction<GroupModal>) => {
            state.addGroupModal = payload;
        },
        deleteGroupModal: (state, { payload }: PayloadAction<GroupModal>) => {
            state.deleteGroupModal = payload;
        },
        setGroupHierarchy(state, action: PayloadAction<GroupHierarchy>) {
            const groupHierarchy = action.payload;
            state.groupHierarchy = groupHierarchy;
        },
        moveGroupsDevicesModal: (state, { payload }: PayloadAction<GroupDeviceModalType>) => {
            state.moveGroupsDevicesModal = payload;
        },
        setHierarchyData: (state, { payload }: PayloadAction<any>) => {
            state.hierarchyData = payload;
        },
    },
});

export const { setGroupHierarchy, addGroupModal, deleteGroupModal, moveGroupsDevicesModal, setHierarchyData } =
    groupSlice.actions;
export const selectAddGroupModal = (state: RootState): GroupModal => state.group.addGroupModal;
export const selectDeleteGroupModal = (state: RootState): GroupModal => state.group.deleteGroupModal;
export const selectCurrentGroupHierarchy = (state: any): any => state.groupHierarchy;
export const selectHierarchyData = (state: any): any => state.group.hierarchyData;
export const selectMoveGroupsDevicesModal = (state: RootState): GroupDeviceModalType =>
    state.group.moveGroupsDevicesModal;
export default groupSlice;
