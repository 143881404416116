import React, { useEffect, useMemo, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useGetRolesPermissionQuery, useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { UserProfile } from '@fiji/common/src/types';
import { useGetOrgQuery } from '@fiji/common/src/features/orgManagement/orgApi';
import { selectedOrg, setSelectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { setCredentials } from '@fiji/common/src/features/auth/authSlice';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { setPermissions } from '@fiji/common/src/features/profile/profileSlice';
import { setAuthModal, toggleLoader } from '@fiji/common/src/features/common/commonSlice';
import { api } from '@fiji/common/src/app/api/baseApi';
import { CreateRoutes } from '../router/routes';
import { ALL_PROTECTED_ROUTES } from '../router/allProtectedRoutes';
import { PageNotFound } from './PageNotFound';

declare module 'crypto-js' {
    export const enc: {
        Utf8: {
            parse: (text: string) => any;
            stringify: (wordArray: any) => string;
        };
        Base64: {
            parse: (text: string) => any;
            stringify: (wordArray: any) => string;
        };
        WordArray: {
            create: (array: number[]) => any;
        };
    };

    export const mode: {
        ECB: any;
    };
}

/**
 * The `RequiredAuth` component is a TypeScript React component that handles authentication and
 * authorization logic for an application.
 * @param  - - `onAppLoaded`: A function that is called when the app is loaded. It takes a boolean
 * argument indicating whether the app is loaded or not.
 */
export const RequiredAuth = (): JSX.Element => {
    const { oktaAuth, authState } = useOktaAuth();
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [isAppLoaded, setIsAppLoaded] = useState(false);
    const isSignOut = useTypedSelector((state: any) => state.common.isSignOut);
    const drawerProviderValue = useMemo(() => ({ drawerOpen, setDrawerOpen }), [drawerOpen, setDrawerOpen]);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isAppAuthenticated, setIsAppAuthenticated] = useState(false);
    const [currentRole, setCurrentRole] = useState<any>(null);
    const currentOrg = useTypedSelector(selectedOrg);

    const { data: userProfileData, isLoading: isLoadingProfile } = useGetUserProfileQuery<{
        data: UserProfile;
        isLoading: boolean;
        isFetching: boolean;
    }>(undefined, {
        skip: !isAppAuthenticated,
    });

    // const { data: associatedOrganizations, isLoading: isLoadingOrgs } = useGetSelectedOrgQuery<{
    //     data: { data: Organization };
    //     isLoading: boolean;
    //     isFetching: boolean;
    // }>(undefined, {
    //     skip: !isAppAuthenticated,
    // });

    const { data: selectedOrganizations, isLoading: isLoadingOrgs }: any = useGetOrgQuery(
        {
            page: 0,
            size: 20,
            selected: true,
        },
        { skip: !isAppAuthenticated }
    );
    const location = useLocation();
    const isAuthModalOpen = useTypedSelector((state: any) => state.common.authModal);

    const { data: rolePermissions, isLoading: isLoadingPermissions }: any = useGetRolesPermissionQuery(
        { roleId: currentRole },
        { skip: !currentRole }
    );

    /* The `useEffect` hook is used to handle logic related to the user's associated organizations and
    user profile data. */
    useEffect(() => {
        if (selectedOrganizations) {
            if (!selectedOrganizations?.data?.records?.length) {
                dispatch(
                    setSelectedOrg({
                        adminCount: 0,
                        deviceCount: 0,
                        groupCount: 0,
                        id: '',
                        logo: '',
                        mfa: true,
                        name: '',
                        orgCode: '',
                        selected: true,
                        status: '',
                        orgAdmin: false,
                        roleId: '',
                    })
                );
                setIsAppLoaded(true);
            } else if (selectedOrganizations?.data?.records?.length && userProfileData) {
                setCurrentRole(selectedOrganizations?.data?.records[0]?.roleId);
                if (selectedOrganizations) {
                    dispatch(setSelectedOrg(selectedOrganizations?.data?.records[0]));
                    dispatch((api as any).endpoints.getGroupsHierarchy.initiate(null));
                }
            }
        }
    }, [selectedOrganizations, userProfileData]);

    useEffect(() => {
        if (currentOrg?.id) {
            dispatch(api.util.invalidateTags(['GroupsHierarchy']));
        }
    }, [currentOrg?.id]);

    /* The `useEffect` hook is used to update the credentials in the application state when the
   authentication state changes. */
    useEffect(() => {
        if (authState?.isAuthenticated) {
            dispatch(setCredentials({ token: authState?.accessToken?.accessToken ?? '' }));
        }
    }, [authState]);

    /* The `useEffect` hook is used to handle the logic for signing out the user if the `toastData`
    indicates that the user is unauthorized. */
    useEffect(() => {
        if (oktaAuth && isAuthModalOpen) {
            setTimeout(() => {
                oktaAuth
                    .signOut()
                    .then(() => setAuthModal(false))
                    .catch((err) => {
                        throw new Error(err);
                    });
            }, 2000);
        }
    }, [isAuthModalOpen]);

    /* The `useEffect` hook in the provided code is responsible for handling the authentication logic. */
    useEffect(() => {
        if (!authState) {
            return;
        }
        if (!authState?.isAuthenticated) {
            const originalUri = toRelativeUrl(window.location.href, window.location.origin);
            oktaAuth.setOriginalUri(originalUri);
            navigate('/login');
        } else {
            setIsAppAuthenticated(true);
        }
    }, [!!authState, authState?.isAuthenticated, location.pathname]);

    /* The `useEffect` hook in the provided code is responsible for handling the logic related to the
    user's associated organizations and authentication state. */
    useEffect(() => {
        if (selectedOrganizations?.data && authState?.isAuthenticated && userProfileData?.data) {
            dispatch(setCredentials({ token: authState?.accessToken?.accessToken ?? '' }));
            if (!userProfileData.data.licenseAccepted) {
                navigate('/accept/invite');
            } else if (
                !selectedOrganizations?.data?.records?.length &&
                window.location.pathname !== '/organization' &&
                window.location.pathname !== '/profile'
            ) {
                navigate('/organization');
            }
        }
    }, [selectedOrganizations, authState, userProfileData]);

    /* The `useEffect` hook is used to handle the logic related to the `rolePermissions` data. */
    useEffect(() => {
        if (rolePermissions) {
            dispatch(setPermissions(rolePermissions?.data));
            setIsAppLoaded(true);
        }
    }, [rolePermissions]);

    useEffect(() => {
        if ((isSignOut || isLoadingPermissions || isLoadingProfile || isLoadingOrgs) && location.pathname === '/') {
            dispatch(toggleLoader(true));
        } else {
            dispatch(toggleLoader(false));
        }
    }, [isSignOut, isLoadingPermissions, isLoadingProfile, isLoadingOrgs, location]);

    return (
        <Routes>
            {CreateRoutes(ALL_PROTECTED_ROUTES, drawerProviderValue, false, [], !isAppLoaded)}
            {isAppLoaded && <Route path="*" element={<PageNotFound />} />}{' '}
        </Routes>
    );
};
