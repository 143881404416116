import React, { useEffect } from 'react';
import { EmptyState, HeroBanner, InfoListItem, ListItemTag } from '@brightlayer-ui/react-components';
import {
    NavigateNext,
    NotificationsActive,
    Check,
    CloudOff,
    Warning,
    Workspaces,
    Add,
    NotificationsNoneOutlined,
    WarningAmberOutlined,
    FormatListBulleted,
    ChevronLeft,
} from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Grid,
    IconButton,
    Skeleton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { useCommonActions, useGetHierarchyHandlers, useRBAC } from '../../hooks';
import { Device } from '@brightlayer-ui/icons-mui';
import { setSelectedGroup } from '@fiji/common/src/features/common/commonSlice';
import { useNavigate } from 'react-router-dom';
import { addGroupModal } from '@fiji/common/src/features/group/groupSlice';
import {
    getEventColor,
    getEventIconStyle,
    getEventsLabel,
    getPriorityEvent,
    getTimelineDetailsStyle,
} from '../../CommonUtils';
import { useGetPaginatedDeviceListQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useGetTimeLineSummaryQuery } from '@fiji/common/src/features/timeline/timelineApi';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { TimelineStatusIcon } from '../../components/TimelineStatusIcon';
import HeroSkeleton from '../Common/Skeletons/HeroSkeleton';
import GroupCardSkeleton from './GroupCardSkeleton';
import DeviceCardSkeleton from './DeviceCardSkeleton';
import TimelineCardSkeleton from '../Common/Skeletons/TimelineCardSkeleton';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { UserProfile } from '@fiji/common/src/types';
import { useTransformDatTime } from '../../hooks/useTransformDatTime';
import BatteryPercentageIcon from '../../components/BatteryPercentageIcon';
import { useGetGroupsListQuery } from '@fiji/common/src/features/group/groupApi';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';

const MAX_DEVICES_COUNT = 5;
const MAX_GROUPS_COUNT = 5;

const areAllValuesZero = (obj: any): boolean => {
    for (const key in obj) {
        if (obj[key] !== 0) {
            return false;
        }
    }
    return true;
};

/* The below code is defining a function called `getTimelineHeroHtml` that takes in two parameters:
`key` of type string and `value` of type number. */
const getTimelineHeroHtml = (key: string, value: number, allValues: any): JSX.Element => (
    <>
        <Typography sx={{ fontSize: '18px', fontWeight: '600', color: '#424E54' }}>{value}</Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: '600', color: '#727E84' }}>{getEventsLabel(key)}</Typography>
        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#727E84' }}>
            {!areAllValuesZero(allValues) && (key === 'information' ? 'Past 7 Days' : 'Active')}
        </Typography>
    </>
);

/* The below code is a TypeScript function called `getTimelineEventIcon` that returns a JSX element
based on the provided `key`, `style`, and optional `value` parameters. */
const getTimelineEventIcon = (key: string, style: any, value?: any): JSX.Element => {
    switch (key) {
        case 'alarms': {
            return value === 0 ? (
                <NotificationsNoneOutlined sx={{ fontSize: '40px', color: '#727E84' }} />
            ) : (
                <NotificationsActive sx={style} />
            );
        }
        case 'offlineDevices': {
            return value === 0 ? <CloudOff sx={{ fontSize: '40px', color: '#727E84' }} /> : <CloudOff sx={style} />;
        }
        case 'warnings': {
            return value === 0 ? (
                <WarningAmberOutlined sx={{ fontSize: '40px', color: '#727E84' }} />
            ) : (
                <Warning sx={style} />
            );
        }
        case 'information': {
            return value === 0 ? (
                <InfoOutlinedIcon sx={{ fontSize: '40px', color: '#727E84' }} />
            ) : (
                <InfoIcon sx={style} />
            );
        }
        default: {
            return <Check sx={{ ...style, backgroundColor: '#39B620' }} />;
        }
    }
};

/* The below code is defining a function called `getCardActions` that takes two parameters: `label` (a
string) and `onClick` (an optional function). */
const getCardActions = (label: string, onClick?: any): JSX.Element => (
    <CardActions sx={{ padding: '8px 16px' }} onClick={onClick}>
        <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100%'}
            onClick={onClick}
        >
            <Typography variant={'subtitle2'} color={'primary'} sx={{ cursor: 'pointer' }}>
                {label}
            </Typography>
            <IconButton aria-label="settings">
                <NavigateNext fontSize="medium" />
            </IconButton>
        </Stack>
    </CardActions>
);

/**
 * The function `getStatusBackgroundColor` takes a key as input and returns a corresponding background
 * color based on the key value.
 * @param {string} key - The `key` parameter in the `getStatusBackgroundColor` function is a string
 * that represents the status key. It is used in a switch statement to determine the background color
 * based on the status key.
 * @returns The function `getStatusBackgroundColor` returns a string representing the background color
 * based on the input `key`.
 */
const getStatusBackgroundColor = (timelineStatus: string): string => {
    if (timelineStatus === 'Alarm' || timelineStatus?.includes('Active')) {
        switch (timelineStatus) {
            case 'Alarm Active': {
                return '#CA3C3D';
            }
            case 'Alarm': {
                return '#69B1C3';
            }
            case 'Warning Active': {
                return '#F0AA1F';
            }

            default: {
                return '#39B620';
            }
        }
    }
    return '';
};

const getDeviceStatusBackgroundColor = (key: string): string => {
    switch (key) {
        case 'Alarm': {
            return '#CA3C3D';
        }
        case 'Offline': {
            return '#69B1C3';
        }
        case 'Warning': {
            return '#F0AA1F';
        }
        default: {
            return '#39B620';
        }
    }
};

export const SummaryScreen = ({ setActiveTab }: { setActiveTab: any }): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isValid, setIsValid] = React.useState(false);
    const selectedGroup = useTypedSelector((state) => state.common.groupId);
    const currentOrg = useTypedSelector(selectedOrg);

    const { selectedGroups, handleSelectGroup, removeUnwantedIds } = useGetHierarchyHandlers({});
    const {
        data: hierarchyData,
        isLoading: isGroupLoading,
        isFetching: isGroupFetching,
    }: any = useGetGroupsListQuery(selectedGroup, {
        skip: !currentOrg?.id,
    });
    const { data: profileDetails } = useGetUserProfileQuery<{ data: UserProfile }>();

    const {
        currentData: devicesData,
        isSuccess: deviceDataSuccess,
        isError: deviceDataError,
        isLoading: areDevicesLoading,
        isFetching: areDevicesFetching,
    }: any = useGetPaginatedDeviceListQuery(
        {
            filters: !selectedGroup.length
                ? {}
                : { groupId: removeUnwantedIds(hierarchyData?.data, selectedGroups, [])?.groups },
            page: 0,
            size: MAX_DEVICES_COUNT,
        },
        {
            skip: !isValid,
            refetchOnMountOrArgChange: true,
        }
    );

    const {
        currentData: timelineData,
        isSuccess: timelineDataSuccess,
        isError: timelineDataError,
        isLoading: isTimelineLoading,
        isFetching: isTimelineFetching,
    }: any = useGetTimeLineSummaryQuery(
        { groupId: removeUnwantedIds(hierarchyData?.data, selectedGroups, [])?.groups },
        {
            skip: !isValid,
            refetchOnMountOrArgChange: true,
        }
    );

    const { togglePageLoader } = useCommonActions();
    const { convertDateTime } = useTransformDatTime(profileDetails);

    useEffect(() => {
        if (timelineDataSuccess && deviceDataSuccess && !hierarchyData?.isLoading) {
            togglePageLoader(false);
        }
    }, [timelineDataSuccess, deviceDataSuccess, hierarchyData?.isLoading]);

    useEffect(() => {
        if (timelineDataError || deviceDataError) {
            togglePageLoader(false);
        }
    }, [timelineDataError, deviceDataError]);

    useEffect(() => {
        if (hierarchyData?.data) {
            if (selectedGroup) {
                handleSelectGroup([selectedGroup], hierarchyData?.data);
            }
            setIsValid(true);
        }
    }, [hierarchyData?.data, selectedGroup]);

    const rolePermissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(rolePermissions);
    const canCreateGroup = hasPermission('create-group');
    const canCreateDevice = hasPermission('create-device');
    const canViewDevice = hasPermission('view-device');
    const canViewDevices = hasPermission('view-devices');

    /**
     * The function `handleDispatchGroup` dispatches an action to set the selected group using the
     * provided group ID.
     * @param {string} groupId - The `groupId` parameter is a string that represents the ID of a group.
     */
    const handleDispatchGroup = (groupId: string): void => {
        dispatch(setSelectedGroup(groupId));
    };

    const getIconColor = (key: string): string => {
        switch (key) {
            case 'Alarm': {
                return '#FFFFFF';
            }
            case 'Offline': {
                return '#EEF0F0';
            }
            case 'Warning': {
                return '#FFFFFF';
            }
            default: {
                return '#FFFFFF';
            }
        }
    };
    const getDeviceIconStyle = (key: string): object => ({ color: getIconColor(key) });

    const getDeviceStatusIcon = (key: string, device: any): JSX.Element => {
        switch (key) {
            case 'Alarm': {
                return (
                    <>
                        <NotificationsActive sx={{ color: '#CA3C3D', fontSize: '18px', margin: '2px' }} />
                        <Typography
                            sx={{
                                color: '#CA3C3D',
                                fontSize: '14px',
                                fontWeight: '600',
                            }}
                        >
                            {device?.alarmCount ?? ''}
                        </Typography>
                    </>
                );
            }
            case 'Offline': {
                return <ListItemTag label={'OFFLINE'} fontColor="#DBEEF2" backgroundColor="#69B1C3" />;
            }
            case 'Warning': {
                return (
                    <>
                        <Warning sx={{ color: '#F0AA1F', fontSize: '18px', margin: '2px' }} />
                        <Typography
                            sx={{
                                color: '#F0AA1F',
                                fontSize: '14px',
                                fontWeight: '600',
                            }}
                        >
                            {device?.warningCount ?? device?.alarmCount ?? ''}
                        </Typography>
                    </>
                );
            }
            default: {
                return <></>;
            }
        }
    };

    const getDeviceIcon = (key: string, style: any): JSX.Element => {
        switch (key) {
            case 'Alarm': {
                return <NotificationsActive sx={style} />;
            }
            case 'Offline': {
                return <CloudOff sx={style} />;
            }
            case 'Warning': {
                return <Warning sx={style} />;
            }
            default: {
                return <Check sx={{ ...style, backgroundColor: '#39B620' }} />;
            }
        }
    };

    const getDeviceIcons = (data: any): JSX.Element => (
        <Avatar sx={{ backgroundColor: getDeviceStatusBackgroundColor(data) }}>
            {getDeviceIcon(data, getDeviceIconStyle(data))}
        </Avatar>
    );

    const getListItemTag = (data: any): any => {
        if (data?.status === 'Alarm') {
            return <ListItemTag label={'OFFLINE'} fontColor="#DBEEF2" backgroundColor="#69B1C3" />;
        } else if (data?.status?.includes('Cleared')) {
            return (
                <ListItemTag
                    label={data?.status?.includes('Warning') ? 'CLEARED' : 'CLOSED'}
                    fontColor="#424E54"
                    backgroundColor="#D5D8DA"
                />
            );
        }
    };

    const getGroupPath = (pathName: string): JSX.Element => {
        const groupPath = pathName?.split('/');
        return (
            <Tooltip title={pathName?.replaceAll('/', ' < ')} arrow>
                <Typography
                    variant={'body2'}
                    sx={{
                        textOverflow: 'ellipsis',
                        width: '250px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {groupPath?.map(
                        (groupName: string, index: number): JSX.Element => (
                            <>
                                <span>{groupName}</span>
                                {index !== groupPath.length - 1 && (
                                    <ChevronLeft
                                        fontSize="small"
                                        sx={{ color: '#727e84', position: 'relative', top: '5px' }}
                                    />
                                )}
                            </>
                        )
                    )}
                </Typography>
            </Tooltip>
        );
    };

    /**
     * The function `handleOpenAddGroupModal` dispatches an action to open a modal for adding a group,
     * with the selected group ID as a parameter.
     */
    const handleOpenAddGroupModal = (): any => dispatch(addGroupModal({ isOpen: true, groupId: selectedGroup }));

    /**
     * The function handleNavigateAddDevice is used to navigate to the '/add-device' route with
     * the selectedGroup as a parameter.
     */
    const handleNavigateAddDevice = (): any =>
        navigate('/add-device', { state: { groupId: selectedGroup, manageDevicesTab: 'Summary' } });

    /* The below code is defining a function called `getGroupCardContent` that returns JSX elements. */
    const getGroupCardContent = (): JSX.Element => {
        let groupSumaryUrl = '/groups/summary';
        if (selectedGroup) {
            groupSumaryUrl = `/groups/summary/${selectedGroup}`;
        }

        if (isGroupFetching || isGroupLoading) {
            return <GroupCardSkeleton />;
        }
        if (!hierarchyData?.data[0]?.children?.length) {
            return (
                <>
                    <EmptyState
                        icon={<Workspaces className="my-svg-icon" />}
                        title={'No Child Groups'}
                        description={'This group has no groups'}
                        actions={
                            canCreateGroup && (
                                <Button
                                    variant="outlined"
                                    startIcon={<Add />}
                                    sx={{
                                        color: '#007BC1 !important',
                                        marginTop: '12px !important',
                                        borderRadius: '4px !important',
                                        backgroundColor: '#fff !important',
                                    }}
                                    onClick={handleOpenAddGroupModal}
                                >
                                    Add a Group
                                </Button>
                            )
                        }
                    />
                </>
            );
        }
        return (
            <>
                {hierarchyData?.data[0]?.children?.slice(0, MAX_GROUPS_COUNT)?.map((group: any) => {
                    const priorityEvent = getPriorityEvent(group);
                    return (
                        <>
                            <InfoListItem
                                key={group?.id}
                                title={group?.name}
                                divider={'full'}
                                statusColor={priorityEvent && getEventColor(priorityEvent)}
                                subtitle={group?.devices?.length || 0}
                                icon={
                                    priorityEvent ? (
                                        getTimelineEventIcon(priorityEvent, getTimelineDetailsStyle(priorityEvent))
                                    ) : (
                                        <Workspaces sx={{ color: '#727E84', height: '40px', width: '40px' }} />
                                    )
                                }
                                rightComponent={
                                    <>
                                        <Stack flexDirection={'row'} alignItems={'center'}>
                                            {group?.stats &&
                                                Object.keys(group?.stats).map(
                                                    (event: any): any =>
                                                        group?.stats[event] > 0 &&
                                                        event !== 'totalDevices' && (
                                                            <>
                                                                <Stack
                                                                    flexDirection={'row'}
                                                                    alignItems={'center'}
                                                                    sx={{ mr: 2 }}
                                                                >
                                                                    {getTimelineEventIcon(
                                                                        event,
                                                                        getEventIconStyle(event, 15)
                                                                    )}
                                                                    {event?.status === 'Offline' ? (
                                                                        <ListItemTag
                                                                            style={{ marginLeft: '20px' }}
                                                                            label={'OFFLINE'}
                                                                            backgroundColor={`${getEventColor(
                                                                                'offlineDevices'
                                                                            )} !important`}
                                                                            color={'#E0F1FD'}
                                                                        />
                                                                    ) : (
                                                                        <Typography
                                                                            sx={{
                                                                                ...getEventIconStyle(event, 14),
                                                                                fontWeight: '600',
                                                                                paddingLeft: '5px',
                                                                            }}
                                                                        >
                                                                            {group?.stats[event]}
                                                                        </Typography>
                                                                    )}
                                                                </Stack>
                                                            </>
                                                        )
                                                )}
                                        </Stack>
                                    </>
                                }
                                chevron
                                iconAlign="center"
                                ripple
                                onClick={(): void => {
                                    handleDispatchGroup(group?.id);
                                }}
                            />
                        </>
                    );
                })}
                {getCardActions('View All Groups', () =>
                    navigate(groupSumaryUrl, {
                        state: { manageDevicesTab: 'Summary' },
                    })
                )}
            </>
        );
    };

    const getTimelineList = (): JSX.Element => {
        if (!timelineData?.data?.length) {
            return (
                <EmptyState
                    icon={<FormatListBulleted className="my-svg-icon" />}
                    title="No Events"
                    description="No Alarms or Events"
                />
            );
        }
        return (
            <>
                {timelineData?.data?.map((event: any) => (
                    <>
                        <InfoListItem
                            key={event?.id}
                            title={event?.events?.name}
                            iconAlign="center"
                            divider={'full'}
                            icon={<TimelineStatusIcon key={event?.timelineId} status={event?.status} />}
                            statusColor={getStatusBackgroundColor(event?.status)}
                            subtitle={event?.groupPath && getGroupPath(event?.groupPath)}
                            leftComponent={
                                <Stack>
                                    <Typography variant={'subtitle2'} className="f-14 ">
                                        {convertDateTime({
                                            timestamp: parseInt(event?.createdAt),
                                            customFormat: 'h:mm',
                                        }) || '-'}
                                        <span style={{ marginLeft: '5px', fontWeight: 400 }}>
                                            {convertDateTime({
                                                timestamp: parseInt(event?.createdAt),
                                                customFormat: 'A',
                                            }) || ''}
                                        </span>
                                    </Typography>
                                    <Typography variant={'caption'} className="f-12">
                                        {convertDateTime({
                                            timestamp: parseInt(event?.createdAt),
                                        }) || 'N/A'}
                                    </Typography>
                                </Stack>
                            }
                            rightComponent={getListItemTag(event)}
                            onClick={(): void => {
                                navigate(`/timeline/${event?.timelineId}`, {
                                    state: { deviceDetailsTab: 'Summary' },
                                });
                            }}
                            chevron
                            ripple
                        />
                    </>
                ))}
                {getCardActions('View All Events', () => setActiveTab('Timeline'))}
            </>
        );
    };

    const getGroupDetails = (): JSX.Element | JSX.Element[] =>
        hierarchyData?.data?.[0]?.stats ? (
            Object.keys(hierarchyData?.data?.[0]?.stats).map((key) => (
                <Box sx={{ textAlign: 'center' }} key={key}>
                    {getTimelineEventIcon(key, getEventIconStyle(key, 40), hierarchyData?.data?.[0]?.stats?.[key])}
                    {getTimelineHeroHtml(key, hierarchyData?.data?.[0]?.stats?.[key], hierarchyData?.data?.[0]?.stats)}
                </Box>
            ))
        ) : (
            <></>
        );

    /* The below code is defining a function called `getDeviceCardContent` that returns a JSX element. */
    const getDeviceCardContent = (): JSX.Element => {
        if (
            hierarchyData?.isLoading ||
            areDevicesFetching ||
            areDevicesLoading ||
            (!deviceDataSuccess && !deviceDataError)
        ) {
            return <DeviceCardSkeleton />;
        }
        if (!devicesData?.total) {
            return (
                <>
                    <EmptyState
                        icon={<Device className="my-svg-icon" />}
                        title={'No Devices'}
                        description={'This group has no direct child Devices, but may have Devices in child Groups'}
                        actions={
                            canCreateDevice && (
                                <Button
                                    variant="outlined"
                                    startIcon={<Add />}
                                    sx={{
                                        color: '#007BC1!important',
                                        marginTop: '12px!important',
                                        borderRadius: '4px!important',
                                        backgroundColor: '#fff!important',
                                    }}
                                    onClick={handleNavigateAddDevice}
                                >
                                    Add a Device
                                </Button>
                            )
                        }
                    />
                </>
            );
        }
        return (
            <>
                {devicesData?.records?.map(
                    (device: any): JSX.Element => (
                        <>
                            <InfoListItem
                                title={''}
                                divider={'full'}
                                className={canViewDevice ? 'cursor-pointer' : 'default-cursor'}
                                avatar
                                iconAlign="center"
                                statusColor={getDeviceStatusBackgroundColor(device?.status)}
                                leftComponent={
                                    <>
                                        <Stack className="white-space-pre">
                                            <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#424E54' }}>
                                                {device?.deviceName}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    fontWeight: '400',
                                                    color: '#424E54',
                                                    p: '3px 0',
                                                }}
                                            >
                                                {device?.groupPath}
                                            </Typography>
                                            <Stack flexDirection={'row'} alignItems={'center'}>
                                                <Typography
                                                    style={{
                                                        width: '1rem',
                                                        height: '100%',
                                                        display: 'flex',
                                                        marginRight: '10px',
                                                    }}
                                                >
                                                    <CircularProgressbar
                                                        value={device?.percentLoad ?? 0}
                                                        strokeWidth={50}
                                                        counterClockwise
                                                        styles={buildStyles({
                                                            strokeLinecap: 'butt',
                                                        })}
                                                    />
                                                </Typography>
                                                <Typography
                                                    sx={{ fontSize: '14px', fontWeight: '400', color: '#727E84' }}
                                                >
                                                    {device?.percentLoad}%
                                                </Typography>
                                                <BatteryPercentageIcon batteryLevel={device?.batteryPercentage} />
                                                <Typography
                                                    sx={{ fontSize: '14px', fontWeight: '400', color: '#727E84' }}
                                                >
                                                    {device?.batteryPercentage}%
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </>
                                }
                                icon={getDeviceIcons(device?.status)}
                                rightComponent={
                                    <>
                                        {device?.alarmCount || device?.status === 'offline' ? (
                                            <Stack flexDirection={'row'} alignItems={'center'} sx={{ mr: 2 }}>
                                                {getDeviceStatusIcon(device?.status, device)}
                                            </Stack>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                }
                                chevron
                                ripple
                                onClick={(): void =>
                                    canViewDevice &&
                                    navigate(`/device/details/${device?.deviceId}`, {
                                        state: { manageDevicesTab: 'Summary' },
                                    })
                                }
                            />
                        </>
                    )
                )}
                {getCardActions('View All Devices', () => setActiveTab('Devices'))}
            </>
        );
    };

    const getTotalDeviceCount = (): string => {
        if (devicesData?.total) {
            return `${devicesData?.total}`;
        }
        return '';
    };

    const getGroupsCount = (): string => {
        if (hierarchyData?.data[0]?.children?.length) {
            return `${hierarchyData?.data[0]?.children?.length}`;
        }
        return '';
    };

    return (
        <>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Card>
                            {hierarchyData?.isLoading ? (
                                <div style={{ padding: '16px' }}>
                                    <Skeleton animation="wave">
                                        <Typography>Timeline</Typography>
                                    </Skeleton>
                                </div>
                            ) : (
                                <Typography
                                    sx={{
                                        color: '#007bc1',
                                        fontSize: '16px !important',
                                        fontWeight: '600 !important',
                                        padding: '16px',
                                    }}
                                >
                                    Timeline
                                </Typography>
                            )}
                            <Divider />
                            <CardContent sx={{ padding: '0 !important' }}>
                                <HeroBanner py={2} sx={{ justifyContent: 'space-around' }}>
                                    {hierarchyData?.isLaoding ? <HeroSkeleton /> : getGroupDetails()}
                                </HeroBanner>
                                <Divider />
                                <Card>
                                    <CardContent sx={{ p: 0, '&.MuiCardContent-root:last-child': { pb: 0 } }}>
                                        {hierarchyData?.isLaoding ||
                                        isTimelineLoading ||
                                        isTimelineFetching ||
                                        !timelineDataSuccess ? (
                                            <TimelineCardSkeleton />
                                        ) : (
                                            getTimelineList()
                                        )}
                                    </CardContent>
                                </Card>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={6}>
                        <Card>
                            {hierarchyData?.isLaoding ? (
                                <div style={{ padding: '16px' }}>
                                    <Skeleton animation="wave">
                                        <Typography>Groups</Typography>
                                    </Skeleton>
                                </div>
                            ) : (
                                <Typography
                                    sx={{
                                        color: '#007bc1',
                                        fontSize: '16px !important',
                                        fontWeight: '600 !important',
                                        padding: '16px',
                                        whiteSpace: 'pre',
                                    }}
                                >
                                    Groups {getGroupsCount()}
                                </Typography>
                            )}
                            <Divider />
                            {getGroupCardContent()}
                        </Card>

                        {canViewDevices && (
                            <Card sx={{ marginTop: '20px' }}>
                                {hierarchyData?.isLaoding || areDevicesFetching || areDevicesLoading ? (
                                    <div style={{ padding: '16px' }}>
                                        <Skeleton animation="wave">
                                            <Typography>Devices</Typography>
                                        </Skeleton>
                                    </div>
                                ) : (
                                    <Typography
                                        sx={{
                                            color: '#007bc1',
                                            fontSize: '16px !important',
                                            fontWeight: '600 !important',
                                            padding: '16px',
                                            whiteSpace: 'pre',
                                        }}
                                    >
                                        Devices {getTotalDeviceCount()}
                                    </Typography>
                                )}
                                <Divider />
                                {getDeviceCardContent()}
                            </Card>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};
