import React from 'react';
import { Autocomplete, Chip, TextField } from '@mui/material';

/* The code is defining a functional component called `AutoCompleteTags` that returns a JSX element. */
export const AutoCompleteTags = (props: any): JSX.Element => (
    <Autocomplete
        multiple
        id="tagIds"
        value={props?.value}
        inputValue={props?.inputValue}
        disabled={props?.disabled}
        options={props?.options}
        onChange={props?.onChange}
        onInputChange={props?.onInputChange}
        renderTags={(value: any[], getTagProps): any =>
            value.map((option: any, index: number) => (
                // eslint-disable-next-line react/jsx-key
                <Chip
                    {...getTagProps({ index })}
                    label={option}
                    onDelete={(): void => props?.handleTagDelete(option, index)}
                />
            ))
        }
        renderInput={(params): JSX.Element => (
            <TextField
                className={props?.className}
                {...params}
                variant="filled"
                hiddenLabel={true}
                id="name"
                {...(props?.value?.length !== 6 && { placeholder: 'Tags' })}
                onKeyDown={props?.onKeyDown}
            />
        )}
        {...(props?.value?.length === 6 && { readOnly: true })}
    />
);
