import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as Colors from '@brightlayer-ui/colors';
import { Theme, useTheme } from '@mui/material/styles';

const PrivacyPolicy = React.forwardRef((props, ref): JSX.Element => {
    React.useImperativeHandle(ref, () => ({
        modalHandler: (action: boolean): void => modalHandler(action),
    }));

    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const modalHandler = (action: boolean): void => {
        setIsOpen(action);
    };

    const useStyles = makeStyles((theme: Theme) => ({
        muiDialogpaper: {
            padding: '24px 16px',
            [theme.breakpoints.down('sm')]: {
                minHeight: 'calc(100vh - 56px)',
            },
            '& .MuiPaper-root': {
                maxWidth: '460px',
                width: '460px',
                height: '100%',
                maxHeight: '760px',
                overflow: 'hidden',
                overflowY: 'auto',
            },
        },
        dialogcontent: {
            minHeight: '334px',
        },
        MuiDialogActionsRoot: {
            padding: '24px',
            // paddingTop: '0',
            borderTop: '1px solid rgba(66, 78, 84, 0.12)',
        },
        typographyTitle: {
            marginBottom: theme.spacing(2),
            fontSize: '20px',
            color: Colors.black[800],
        },
        typographyHeader: {
            fontSize: '28px',
            fontWeight: '600',
            color: '#2B353A',
        },
        typograpghySubHeader: {
            textTransform: 'capitalize',
            fontSize: '16px',
            fontWeight: '700',
            color: '#424E54',
            paddingBottom: '24px',
        },
        typographyPara: {
            fontSize: '16px',
            fontWeight: '400',
            color: '#424E54',
            paddingBottom: '16px',
        },
        typographyParaHeader: {
            fontSize: '16px',
            fontWeight: '600',
            color: '#424E54',
            paddingBottom: '16px',
            textDecoration: 'underline',
        },
    }));

    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.muiDialogpaper}
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant="body1" className={classes.typographyHeader}>
                    Privacy Policy
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body2" className={classes.typograpghySubHeader}>
                    <Typography
                        variant="body2"
                        component={'span'}
                        sx={{ textTransform: 'uppercase' }}
                        className={classes.typograpghySubHeader}
                    >
                        PRIVACY NOTICE /YOUR PRIVACY RIGHTS
                    </Typography>
                    <br />
                    Brightlayer Software Suites Remote Monitoring (BSSRM SERVICES)
                </Typography>
                <Typography variant="body2" component={'p'} className={classes.typographyPara}>
                    Eaton Corporation (“Eaton” or “us” or “we” or “our”) owns and operates the BSSRM Services
                    application (“Application”) and BSSRM Services web browser application (‘Site). Eaton is committed
                    to protecting the privacy of those who use the Application and use the BSSRM Services web browser
                    application (collectively, “BSSRM Services” or “Services”). As such, Eaton’s privacy practices are
                    explained in this Privacy Notice (“Notice”).
                </Typography>
                <Typography variant="body2" component={'p'} className={classes.typographyPara}>
                    This Notice contains details about how Eaton collects and uses information from you and the entity
                    you represent (collectively, the “Authorized Party”) when the Authorized Party uses the BSSRM
                    Services.
                </Typography>
                <Typography variant="body2" component={'p'} className={classes.typographyPara}>
                    Whenever you download, install or operate the BSSRM Services Application or use the BSSRM Services
                    in any other way, you represent that you are an agent or representative of an Authorized Party, and
                    that you have the authority to use the Services on behalf of such Authorized Party.
                </Typography>
                <Typography variant="body2" component={'p'} className={classes.typographyPara}>
                    This Notice covers only the Services identified above, and does not apply to information which the
                    Authorized Party may send us by other means. Other Eaton sites, applications and other online
                    services or locations may have their own privacy policies, and the Authorized Party should consult
                    those accordingly.
                </Typography>
                <Typography variant="body2" component={'p'} className={classes.typographyPara}>
                    Please read this Notice carefully to understand our practices regarding the Authorized Party’s
                    information and how we will treat it. If the Authorized Party does not agree with our policies and
                    practices, do not download, install or operate the BSSRM Services and do not interact with any other
                    part of the Services. By using the Services, the Authorized Party agrees to the terms of this
                    Notice.
                </Typography>
                <Typography variant="body2" component={'p'} className={classes.typographyPara}>
                    This Notice is effective as of: 2023.07.01.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyParaHeader}>
                    Notice Changes
                </Typography>
                <Typography variant="body2" component={'p'} className={classes.typographyPara}>
                    Eaton may update this Notice from time to time. Any changes to this Notice will be posted for a
                    period of thirty (30) days and will be effective when posted via a link on the login page and within
                    the application. The changes will also be immediately incorporated into this Notice.
                </Typography>
                <Typography variant="body2" component={'p'} className={classes.typographyPara}>
                    Continued use of the Services by the Authorized Party after any changes are made to this Notice
                    constitutes the Authorized Party’s acceptance of the changes, so please check this Notice
                    periodically for updates. If any of the changes are unacceptable to the Authorized Party, the
                    Authorized Party should cease using the Services.
                </Typography>
                <Typography variant="body2" component={'p'} className={classes.typographyPara}>
                    If any changes to this Notice materially affect how Eaton treats or handles personal data that the
                    Authorized Party has already provided to Eaton or that has otherwise been collected by us, we will
                    notify the Authorized Party by email (if we have a valid email address to use) and give the
                    Authorized Party thirty (30) days to opt-in to the changes as they pertain to the Authorized Party’s
                    information. If the Authorized Party does not opt-in, its information will continue to be used in a
                    manner that is consistent with the version of this Notice under which it was collected, or the
                    information will be deleted.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyParaHeader}>
                    Collection and Use of Data
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    Personal data (“Personal Data”) is generally defined as information that may be able to identify a
                    natural person, information that could reasonably be linked to such person, or information that may
                    be used to get access to personal accounts. Personal Data could include, but is not limited to,
                    name, address, telephone number, email address, username and password, IP address or other online
                    identifiers, credit/debit card number or other financial information, professional or
                    employment-related information, office address and other business information, etc.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    There are certain parts of the Services where the Authorized Party may be asked to provide Eaton
                    with Personal Data, or where such Personal Data or other information may be collected from the
                    Authorized Party in an automated manner. For example, Eaton may collect Personal Data (and other
                    related information) such as name, title, residential and/or business address(es), email
                    address(es), personal and/or business telephone number(s), and employer information when the
                    Authorized Party:
                </Typography>
                <Typography
                    variant="body2"
                    component={'h6'}
                    className={classes.typographyPara}
                    sx={{ paddingLeft: '16px' }}
                >
                    - Registers to set up a user account in connection with the BSSRM Service;
                    <br /> - Creates a username and password (or security question and answer);
                    <br /> - Contacts Eaton with questions and comments;
                    <br /> - Participates in surveys, questionnaires; and/or
                    <br /> - Voluntarily provides us with Personal Data through the Services.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    In order to enroll in the Services, the Authorized Party is required to provide certain Personal
                    Data, including name, email address. If the Authorized Party does not provide such required Personal
                    Data, the Services will not function properly or may not be available.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    Eaton may use the Personal Data and other information we collect to:
                </Typography>
                <Typography
                    variant="body2"
                    component={'h6'}
                    className={classes.typographyPara}
                    sx={{ paddingLeft: '16px' }}
                >
                    - Process the Authorized Party’s account registration in connection with the BSSRM Service;
                    <br /> - Administer the Authorized Party’s account in connection with the Services;
                    <br /> - Provide the Services, its contents and related information to the Authorized Party;
                    <br /> - Maintain proper business records and other relevant records;
                    <br /> - Provide ongoing technical support related to the Authorized Party’s account;
                    <br /> - Contact the Authorized Party if there are issues with content that was uploaded or posted
                    through the Services;
                    <br /> - Respond to questions and comments;
                    <br /> - Communicate with the Authorized Party regarding additional uses of the Personal Data it
                    provides which go beyond the scope of this list;
                    <br /> - Process and catalog the Authorized Party’s responses to surveys or questionnaires;
                    <br /> - Evaluate and improve the content and general administration of the Services;
                    <br /> - Perform internal operations related to the Services;
                    <br /> - Troubleshoot software issues and operational problems with the Services;
                    <br /> - Conduct data analysis and testing; and/or
                    <br /> - Monitor usage patterns for the Services.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    The BSSRM Service may use cookies. Cookies are files that are transmitted to the Authorized Party’s
                    computer when the Authorized Party uses the BSSRM Services. Cookies allow Eaton to recognize the
                    Authorized Party’s browser or uses the BSSRM Services again. Cookies may also collect the Authorized
                    Party’s user preferences when using the BSSRM Services. Eaton uses cookies to manage acceptance of
                    Eaton’s Terms of Use/Legal Notice/End-User License Agreement for the Services and this Notice. The
                    Authorized Party may be able to disable the cookies by consulting the relevant browser and/or
                    computer instructions. Please note that disabling cookies could affect how the BSSRM Services appear
                    and/or operate for the Authorized Party.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    Eaton uses hosting and cloud services provided by a third party to host the Site content and provide
                    Site-related services. Eaton manages such services, and no Personal Data is collected by or shared
                    with any third party as a result.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    Eaton relies on performance of a contract, legitimate interest, and/or consent from the Authorized
                    Party as the legal basis to process the Personal Data we collect. If the Authorized Party has
                    questions about the legal basis, please contact dataprotection@eaton.com.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    By providing to Eaton the Personal Data and other information referenced above, the Authorized Party
                    agrees that Eaton may use the Personal Data and other information in accordance with the terms of
                    this Notice.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyParaHeader}>
                    Other Individual’s Personal Data
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    To the extent the Authorized Party inputs or otherwise provides the Personal Data of another
                    individual (“Other Individual”) through the Services, the Authorized Party represents that it has
                    obtained such Other Individual’s prior written consent to: (1) allow the Authorized Party to input
                    such Other Individual’s Personal Data through the Services and/or the applicable feature of the
                    Site; and (2) the terms of this Notice.The Authorized Party shall have sole responsibility for any
                    violation of privacy laws as a result of any failure to obtain the Other Individual’s prior written
                    consent as described in the preceding sentence.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyParaHeader}>
                    Sharing of Personal Data and Other Information
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    Except as stated in this Notice or for purposes disclosed by Eaton when the Authorized Party
                    provides the information, Eaton does not sell, rent or share the Authorized Party’s Personal Data
                    with any person or entity outside of Eaton.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    Eaton may disclose Personal Data it collects as required by law, an arbitral body, a court of
                    competent jurisdiction, a law enforcement agency, or any other government agency, and may disclose
                    Personal Data it collects when it believes it is appropriate to prevent physical or financial loss
                    or in connection with an investigation of suspected or actual illegal activity.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    Eaton may share collected Personal Data with a buyer, successor or other third party if Eaton’s
                    ownership status changes, such as in the event of a merger, divestiture, restricting,
                    reorganization, dissolution, or other sale or transfer of some or all of Eaton’s assets, in which
                    Personal Data held by Eaton about users of the Services is among the assets transferred.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    Eaton may share collected Personal Data internally with other affiliates or subsidiaries.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    Eaton may share collected Personal Data with third-party service providers like cloud hosting
                    services or developers who will act on behalf and under instruction of Eaton.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    Other than what is referenced above, the Personal Data and other information collected from the
                    Authorized Party is not shared with nor sold to any person or entity outside of Eaton.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyParaHeader}>
                    Review of Collected Personal Data/Other Choices
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    If the Authorized Party would like to review, edit, delete or obtain a copy of any of the Personal
                    Data Eaton has collected, or if the Authorized Party wishes Eaton to cease using collected Personal
                    Data in the manners specified in this Notice, please contact Eaton at dataprotection@eaton.com.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    Under the General Data Protection Regulation (“GDPR”), if Eaton collects Personal Data from the
                    Authorized Party which was originally obtained from residents of the European Economic Area (“EEA,”
                    i.e. the 27 European Union Member States, as well as Iceland, Norway and Liechtenstein), then the
                    Authorized Party and its data subjects (as such term is defined under the GDPR) have the following
                    rights regarding such EEA-based Personal Data to the extent it was collected by Eaton:
                </Typography>
                <Typography
                    variant="body2"
                    component={'h6'}
                    className={classes.typographyPara}
                    sx={{ paddingLeft: '16px' }}
                >
                    - the right to access details on the Personal Data that Eaton has collected from the Authorized
                    Party;
                    <br /> - the right to update and correct any inaccurate Personal Data Eaton has collected;
                    <br /> - the right to withdraw any consent (with effect for the future) for marketing activities or
                    the processing of Personal Data the Authorized Party might have provided to Eaton;
                    <br /> - the right to request erasure of collected Personal Data;
                    <br /> - the right to request restriction of Eaton’s processing of Personal Data;
                    <br /> - the right to object at any time to Eaton’s processing of Personal Data concerning EEA
                    residents; and
                    <br /> - the right to request the portability of Personal Data that has been provided to Eaton.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    If the Authorized Party would like to exercise the rights listed above in any way, please contact
                    Eaton at dataprotection@eaton.com.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    Please note that Eaton will do its best to accommodate any removal request, but if the Authorized
                    Party wishes to delete Personal Data that has already been collected, Eaton cannot guarantee it can
                    remove all Personal Data from the specified uses. Therefore, please be as specific as possible in
                    any removal request. If the request relates to information that Eaton needs to make the Services
                    function properly, the Authorized Party may not be able to use the Services going forward.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    Under the GDPR, if Eaton collects the Personal Data of EEA residents from the Authorized Party, then
                    the Authorized Party or its data subjects also have the right to lodge a complaint about Eaton’s
                    processing of such EEA-based Personal Data with a supervisory authority. In particular, the
                    Authorized Party or its data subject may lodge a complaint in the Member State where the affected
                    EEA resident(s) live or work, or where the alleged violation took place.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    Eaton reserves the right to maintain proper business records as required by law, or for otherwise
                    legitimate business purposes to the extent permitted by law, even if such records contain the
                    Authorized Party’s Personal Data.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    If the Authorized Party would like to opt-out of receiving further promotional emails from Eaton,
                    please follow the opt-out instructions at the bottom of the email.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    Eaton does not knowingly collect any information from minors, nor are the Services directed at or
                    intended for minors. If a minor uploads/posts information through the Services that is publicly
                    available, and the minor subsequently wants that same information deleted, the minor has a right to
                    request that such information be removed from public viewing. A minor may email
                    dataprotection@eaton.com and request that any such information be removed. Any removal of content by
                    Eaton does not ensure or guarantee complete or comprehensive removal of the content in all places.
                    The content may have been shared or reposted by other parties, or maintenance of the content or
                    information may be required by law.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyParaHeader}>
                    Data Security and Retention
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    Eaton uses, implements and maintains industry standard technological and security measures that are
                    reasonably designed to provide a level of security appropriate to the risk of processing your
                    Personal Data to help protect such information from loss, unauthorized access or disclosure, both in
                    storage and in transmission.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    While Eaton takes the issue of protecting the Authorized Party’s Personal Data seriously, the
                    Authorized Party should exercise discretion in what information it discloses and/or transmits
                    through the Services. Eaton cannot guarantee that information sent over the Internet is fully
                    secure, and therefore the transmitted information may be intercepted by others before it reaches
                    Eaton. If the Authorized Party is concerned about sending information to Eaton over the Internet,
                    please send the information by mail or call us to make other arrangements. Eaton is not responsible
                    for the security of information sent over the Internet.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    Eaton retains Personal Data only for as long as necessary to fulfill the stated purpose for which
                    the Personal Data was collected or otherwise processed, and thereafter for a variety of legitimate
                    legal or business purposes. These may include retention periods that are: (i) mandated by law,
                    contract or similar obligations applicable to Eaton’s business operations; (ii) for preserving,
                    resolving, defending or enforcing our legal/contractual rights; or (iii) needed to maintain adequate
                    and accurate business and financial records. We will delete the Authorized Party’s Personal Data as
                    soon as the respective purpose for its use is not applicable anymore and no legal obligation to
                    retain such data exists. If the Authorized Party has any questions about the security or retention
                    of its Personal Data, the Authorized Party can contact us at dataprotection@eaton.com.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyParaHeader}>
                    Children’s Privacy
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    The Services are intended for individuals 18 years of age and older.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    The Services are not directed at, marketed to, nor intended for, children under 13 years of age.
                    Eaton does not knowingly collect any information, including Personal Data, from children under 13
                    years of age. If Eaton learns that any information was provided through the Services by a person
                    younger than 13 years of age, Eaton will delete the information immediately.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyParaHeader}>
                    Notice to California Residents
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    This section applies to California Residents.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    As described in the ‘Collection and Use of Data’ we might collect personal information including
                    identifiers, commercial information, internet or other network activity information, geolocation
                    information, professional or employment-related information and inferences drawn from any of the
                    information identified above to create a profile about a consumer.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    As described in ‘Sharing of Personal Data and Other Information’ section, personal information we
                    collect from consumers might be shared for business purposes with third parties. We might have
                    disclosed all of the categories of personal information listed above, based on the use case, for a
                    business purpose in the past 12 months.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    We do not sell your personal information.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    As a California resident you have certain rights, subject to legal limitations and applicable
                    exceptions, regarding the collection, use, and sharing of your personal information. Those rights
                    are in particular:
                </Typography>
                <Typography
                    variant="body2"
                    component={'h6'}
                    className={classes.typographyPara}
                    sx={{ paddingLeft: '16px' }}
                >
                    · The right to know. You have the right to request information about the categories of personal
                    information we have collected about you, the categories of sources from which we collected the
                    personal information, the purposes for collecting the personal information, the categories of third
                    parties with whom we have shared your personal information, and the purpose for which we shared your
                    personal information (“Categories Report”). You may also request information about the specific
                    pieces of personal information we have collected about you (“Specific Pieces Report”). Please
                    specify which request you want to execute.
                    <br /> · The right to delete. You have the right to request that we delete personal information that
                    we have collected from you.
                    <br /> · The right to opt-out. You have the right to opt out of the sale of your personal
                    information.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    In accordance with applicable law, we will not discriminate against you for exercising these rights.
                    You may use an authorized agent to exercise your rights on your behalf. If you are making any of the
                    requests above through an authorized agent, we will request written authorization from you and will
                    seek to verify as described above or we will accept a legal Power of Attorney under the California
                    Probate Code to the authorized agent.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    You can execute your right at any time by contacting us by sending an email to
                    dataprotection@eaton.com.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    In order to exercise your rights, we will need to obtain information to locate you in our records or
                    verify your identity depending on the nature of the request. If you are submitting a request on
                    behalf of a household, we will need to verify each member of the household in the manner set forth
                    in this section. Therefore, based on the request we might reach out to you for further information.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    We will respond to a Request to Opt-Out within 15 days. We will respond to Requests to Delete and
                    Requests to Know within 45 days, unless we need more time in which case, we will notify you and may
                    take up to 90 days total to respond to your request.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyParaHeader}>
                    International Concerns
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    The Authorized Party is responsible for complying with any local laws regarding its use of the
                    Services, and any related data collection. The Authorized Party also agrees and acknowledges that by
                    providing any information, including Personal Data, through the Services, that such information will
                    be transmitted to, and stored in, the United States.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    If the Authorized Party or its data subjects are residents of the EEA, the Personal Data that Eaton
                    collects may be disclosed to and processed by staff operating outside the EEA. Disclosure will be to
                    individuals who work for Eaton, our related organizations, and companies with which we have
                    contracted to process or store this data on Eaton’s behalf. By providing us Personal Data on or
                    through the Sites, the Authorized Party is indicating its consent for Personal Data to be sent and
                    stored outside the EEA.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    The individuals and organizations that receive Personal Data as a result of international transfers
                    out of the EEA must follow our express instructions with respect to the use of Personal Data and
                    they must comply with appropriate technological and organizational security measures to protect the
                    Authorized Party’s information.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyParaHeader}>
                    External Links
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    The Services may contain links to external sites or other online services, including those embedded
                    in third party advertisements or sponsor information, that are not controlled by Eaton. Eaton is not
                    responsible for the privacy practices and data collection policies for such third party services.
                    The Authorized Party should consult the privacy policies of those third party services for details.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyParaHeader}>
                    Terms of Use
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    The Terms of Use/Legal Notice/End-User License Agreement for the Services is incorporated by
                    reference into this Notice, and can be found at {''}
                    <a
                        style={{ wordBreak: 'break-word' }}
                        href={`${window.location.origin}/EULA.pdf`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {`${window.location.origin}/EULA.pdf`}
                    </a>
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyParaHeader}>
                    Contact Info/Miscellaneous
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    If the Authorized Party has any questions regarding this Notice, please contact Eaton at
                    dataprotection@eaton.com
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    It is the Notice of Eaton to strictly enforce this Notice. If the Authorized Party believes there
                    has been some violation of this Notice, please contact Eaton.
                </Typography>
                <Typography variant="body2" component={'h6'} className={classes.typographyPara}>
                    This Notice was last updated on July 1, 2023.
                </Typography>
            </DialogContent>
            <DialogActions className={classes.MuiDialogActionsRoot}>
                <Button variant="contained" onClick={(): void => setIsOpen(false)}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default PrivacyPolicy;
