import { CircularProgress } from '@mui/material';
import React, { memo } from 'react';

/**
 * The below function is a React component that renders a loader with a circular progress indicator.
 * @param {any} props - The `props` parameter is an object that contains any additional properties that
 * are passed to the `Loader` component. These properties can be accessed using dot notation, such as
 * `props.size`.
 */
type LoaderProps = {
    size?: number;
};
const Loader = (props: LoaderProps): JSX.Element => (
    <div className="loader-container">
        <CircularProgress size={props?.size ?? 96} />
    </div>
);

export default memo(Loader);
