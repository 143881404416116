import React from 'react';
import { Box, Card, Container, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { RolesPermissions } from '../Roles/RolesPermissions';

const useStyles = makeStyles(() => ({
    cardWrapper: {
        padding: '48px',
        marginBottom: '16px',
    },
    sectionHeader: {
        width: '100%',
        marginBottom: 16,
    },
}));

export const RolesViewer = (): JSX.Element => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <header>
                <title>RolesViewer</title>
                <meta name="description" content="Description of RolesViewer" />
            </header>
            <Container fixed>
                <Box sx={{ p: 2 }}>
                    <Card className={classes.cardWrapper}>
                        <Typography variant={'h6'} className={classes.sectionHeader}>
                            Viewer
                        </Typography>
                        <Typography variant={'body1'} className={classes.sectionHeader}>
                            View device group and individual device status.
                        </Typography>
                        <RolesPermissions />
                    </Card>
                </Box>
            </Container>
        </React.Fragment>
    );
};
