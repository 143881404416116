import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    FormControl,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useStyles } from './styles';
import { useCreateOrgMutation } from '@fiji/common/src/features/orgManagement/orgApi';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { UserProfile } from '@fiji/common/src/types';
import { RefetchConfigOptions } from '@reduxjs/toolkit/dist/query/core/apiState';
import { handleWhiteSpaces } from '../../CommonUtils';
import { CorporateFare } from '@mui/icons-material';
import orgSlice from '@fiji/common/src/features/orgManagement/orgSlice';
import { useAppDispatch } from '@fiji/common/src/app/store';

export const CreateOrg = (): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();

    const [orgName, setOrgName] = useState<string>('');
    const [isCreatingOrg, setIsCreatingOrg] = useState(false);
    const [isCreated, setIsCreated] = useState(false);
    const [createOrgError, setCreatedOrgError] = useState('');

    const [createOrganization, { error }] = useCreateOrgMutation<any>();

    const { data: userProfileData } = useGetUserProfileQuery<{
        data: UserProfile;
        isLoading: boolean;
        refetch: RefetchConfigOptions;
    }>();

    /* The `useEffect` hook is used to perform side effects in a React component. In this case, the
    `useEffect` hook is used to handle errors returned from the `createOrganization` mutation. */
    useEffect(() => {
        if (error?.status === 400 && error?.data?.errorMessage) {
            setCreatedOrgError(error?.data?.errorMessage);
        }
    }, [error]);

    /**
     * The closeOrgFlowModal function dispatches an action to close the organization flow modal.
     */
    const closeOrgFlowModal = (): any => dispatch(orgSlice.actions.orgFlowModal({ isOpen: false, flow: '' }));

    /**
     * The handleChangeOrgName function updates the state variable orgName with the value of the input
     * field.
     * @param {any} e - The parameter `e` is an event object that is passed to the
     * `handleChangeOrgName` function. It is typically an event object that is triggered by a user
     * action, such as a button click or input change.
     */
    const handleChangeOrgName = (e: any): void => {
        setCreatedOrgError('');
        setOrgName(e.target.value);
    };

    /**
     * The function `handleCreateOrg` sets a flag to indicate that an organization is being created,
     * calls an API to create the organization with a given name, and sets a flag to indicate that the
     * organization has been created if the API call is successful.
     */
    const handleCreateOrg = async (): Promise<void> => {
        setIsCreatingOrg(true);
        const { data }: any = await createOrganization({ name: orgName });
        setIsCreatingOrg(false);
        if (data) {
            setIsCreated(true);
        }
    };

    return (
        <Card className={classes.card}>
            <CardHeader
                avatar={
                    <Avatar className={classes.headerAvatarIcon}>
                        <CorporateFare />
                    </Avatar>
                }
                className={classes.cardHeader}
            ></CardHeader>
            <CardContent className={classes.cardBody}>
                {isCreated ? (
                    <>
                        <Typography variant="h6" mb={3}>
                            Welcome {userProfileData?.data?.firstName}!
                        </Typography>
                        <Typography variant="body2">
                            You have successfully created the {orgName} organization! Tap Finish to continue to the
                            application.
                        </Typography>
                    </>
                ) : (
                    <FormControl variant={'filled'} sx={{ marginLeft: '0 !important' }} className="select-form-control">
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <CorporateFare
                                sx={{
                                    marginLeft: '14px',
                                    color: createOrgError ? '#CA3C3D' : '#727E84',
                                }}
                            />
                            <TextField
                                required
                                id="organization-name"
                                sx={{ width: '100%' }}
                                label={'Organization Name'}
                                variant={'filled'}
                                InputLabelProps={{ required: false }}
                                value={orgName}
                                onChange={handleChangeOrgName}
                                {...(createOrgError && {
                                    error: true,
                                    helperText: createOrgError,
                                })}
                            />
                        </Box>
                    </FormControl>
                )}
            </CardContent>

            <CardActions className={classes.cardFooter}>
                {isCreated ? (
                    <Button className="btn" sx={{ width: '100%' }} variant="contained" onClick={closeOrgFlowModal}>
                        Finish
                    </Button>
                ) : (
                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Button
                            sx={{ width: '100%' }}
                            variant="contained"
                            disabled={orgName === '' || !handleWhiteSpaces(orgName)}
                            onClick={handleCreateOrg}
                            {...(isCreatingOrg && { startIcon: <CircularProgress color="inherit" size={20} /> })}
                        >
                            {!isCreatingOrg && 'Create Organization'}
                        </Button>
                        <Button sx={{ width: '100%' }} variant="outlined" onClick={closeOrgFlowModal}>
                            Cancel
                        </Button>
                    </Stack>
                )}
            </CardActions>
        </Card>
    );
};
