import React from 'react';
import { createNumArray } from '../../CommonUtils';
import { Container, Card, Grid, Box, Skeleton, Typography, Button, Switch, Select, Divider } from '@mui/material';
import { Create } from '@mui/icons-material';

export const ProfileSkeleton = ({
    count,
    wrapperClass,
    id,
}: {
    count?: number;
    wrapperClass?: string;
    bodyClass?: string;
    id: any;
}): JSX.Element => (
    <>
        {createNumArray(count ?? 4).map((item, arrIndex) =>
            arrIndex === 0 ? (
                <Container fixed className={wrapperClass} key={`unique${item}`} sx={{ mb: 2, mt: 2 }}>
                    <Box>
                        <Card>
                            <Grid container>
                                <Grid item xs={!id ? 9.7 : 12}>
                                    {createNumArray(5).map((rowItem, index) => (
                                        <Box
                                            className="modal-space-between"
                                            sx={{
                                                gap: '16px',
                                                padding: '16px',
                                            }}
                                            key={`unique${rowItem}`}
                                        >
                                            <Box className="modal-align-center">
                                                {index !== 0 && (
                                                    <Skeleton
                                                        animation="wave"
                                                        variant="circular"
                                                        width={40}
                                                        height={40}
                                                        sx={{
                                                            marginRight: '22px',
                                                            marginLeft: '10px',
                                                            color: '#727E84',
                                                        }}
                                                    />
                                                )}

                                                <Box>
                                                    <Skeleton animation="wave">
                                                        <Typography variant="body1" className="modal-greyhead-text">
                                                            Loading Title
                                                        </Typography>
                                                    </Skeleton>
                                                    {index !== 0 && (
                                                        <Skeleton
                                                            animation="wave"
                                                            height={10}
                                                            width="80%"
                                                            style={{ marginBottom: 6 }}
                                                        >
                                                            <Typography variant="body2" className="modal-para">
                                                                Loading Description
                                                            </Typography>
                                                        </Skeleton>
                                                    )}
                                                </Box>
                                            </Box>
                                            {index !== 1 && (
                                                <Skeleton animation="wave">
                                                    {index === 5 ? <Switch /> : <Create />}
                                                </Skeleton>
                                            )}
                                        </Box>
                                    ))}
                                </Grid>
                                {!id && (
                                    <Grid xs={2.3}>
                                        <Skeleton
                                            animation="wave"
                                            variant="circular"
                                            width={100}
                                            height={100}
                                            sx={{
                                                marginRight: '22px',
                                                marginLeft: '30px',
                                                marginTop: '20px',
                                                color: '#727E84',
                                            }}
                                        />
                                        <Skeleton
                                            animation="wave"
                                            height={10}
                                            width="50%"
                                            style={{ marginBottom: 6, marginLeft: 30, marginTop: 10 }}
                                        >
                                            <Typography variant="body2" className="modal-para">
                                                Loading Description
                                            </Typography>
                                        </Skeleton>

                                        <Skeleton sx={{ width: '80%' }} animation="wave">
                                            <Button variant="contained" sx={{ padding: 2, marginLeft: 20 }} />
                                        </Skeleton>
                                    </Grid>
                                )}
                            </Grid>
                        </Card>
                    </Box>
                </Container>
            ) : (
                <Container fixed className={wrapperClass} key={`unique${item}`} sx={{ mb: 2, mt: 2 }}>
                    {createNumArray(4).map((rowItem, index) => (
                        <Card
                            className="modal-space-between"
                            sx={{
                                gap: '16px',
                                padding: '16px',
                            }}
                            key={`unique${rowItem}`}
                        >
                            <Box className="modal-align-center">
                                {index !== 0 && arrIndex !== 2 && (
                                    <Skeleton
                                        animation="wave"
                                        variant="circular"
                                        width={40}
                                        height={40}
                                        sx={{ marginRight: '22px', marginLeft: '10px', color: '#727E84' }}
                                    />
                                )}

                                <Box>
                                    <Skeleton animation="wave">
                                        <Typography variant="body1" className="modal-greyhead-text">
                                            Loading Title
                                        </Typography>
                                    </Skeleton>
                                    {index !== 0 && (
                                        <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }}>
                                            <Typography variant="body2" className="modal-para">
                                                Loading Description
                                            </Typography>
                                        </Skeleton>
                                    )}
                                </Box>
                            </Box>
                            {index !== 0 && arrIndex !== 1 && <Skeleton animation="wave">{<Select />}</Skeleton>}
                        </Card>
                    ))}
                    {Boolean(count && count > 1) && <Divider />}
                </Container>
            )
        )}
    </>
);
