import React from 'react';
import { Box, Button, Checkbox, DialogContent, DialogContentText, Stack, Typography } from '@mui/material';
import { Device as DeviceIcon, Ups } from '@brightlayer-ui/icons-mui';
import { Device } from '@fiji/common/src/types/Device';
import Loader from '../../components/Loader';
import { EmptyState } from '@brightlayer-ui/react-components';
import * as Colors from '@brightlayer-ui/colors';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useGetPaginatedDeviceListQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';

export const SelectDevices = React.memo((props: any): JSX.Element => {
    const navigate = useNavigate();

    const {
        data: devicesData,
        isLoading,
        isFetching,
    } = useGetPaginatedDeviceListQuery<{ data: any; isLoading: boolean; isFetching: boolean }>(
        {
            size: props?.total,
            filters: {
                deviceType: props?.payload?.deviceTypeIds,
                groupId: props?.payload?.groupIds,
            },
        },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    /**
     * The function `groupName` takes a `pathName` parameter and returns a JSX element that displays
     * the `pathName` with forward slashes replaced by `<` symbols.
     * @param {any} pathName - The `pathName` parameter is of type `any`, which means it can accept any
     * data type. It is used to represent a path name that may contain forward slashes ("/").
     * @returns a JSX element. Specifically, it is returning a Typography component with the variant
     * set to "body2" and the className set to "modal-para". The content of the Typography component is
     * the result of replacing all occurrences of '/' in the pathName with ' < '.
     */
    const groupName = (pathName: any): JSX.Element => {
        const resultPath = pathName?.replaceAll('/', ' < ');
        return (
            <Typography variant="body2" className="modal-para">
                {resultPath}
            </Typography>
        );
    };

    /**
     * The function `selectDeviceHandler` is used to handle the selection of a device by updating the
     * payload with the selected device's ID.
     * @param {any} e - The parameter `e` is an event object that represents the event that triggered
     * the handler function. It is typically passed as an argument when an event listener is invoked.
     * @param {string} deviceId - The `deviceId` parameter is a string that represents the unique
     * identifier of a device.
     */
    const selectDeviceHandler = (e: any, deviceId: string): void => {
        if (e.target.checked) {
            props?.setPayload((prev: any) => ({
                ...prev,
                deviceIds: [...prev.deviceIds, deviceId],
            }));
        } else {
            const newSelectedDevice: [] = props?.payload?.deviceIds.filter((id: any): any => id !== deviceId);
            props?.setPayload((prev: any) => ({
                ...prev,
                deviceIds: newSelectedDevice,
            }));
        }
    };

    /**
     * The function `handleSelectAll` toggles the selection of all devices by either selecting all
     * devices if none are selected, or deselecting all devices if all are already selected.
     */
    const handleSelectAll = (): void => {
        if (props?.payload?.deviceIds?.length === devicesData?.records?.length) {
            props?.setPayload((prev: any) => ({ ...prev, deviceIds: [] }));
        } else {
            props?.setPayload((prev: any) => ({
                ...prev,
                deviceIds: devicesData?.records?.map((item: any) => item?.deviceId),
            }));
        }
    };

    const getDeviceList = (): JSX.Element =>
        devicesData?.records?.length ? (
            <>
                <Box
                    sx={{
                        borderTop: '1px solid rgba(66, 78, 84, 0.12)',
                        borderBottom: '1px solid rgba(66, 78, 84, 0.12)',
                    }}
                    className="modal-space-between"
                >
                    <Typography variant="body2" className="modal-blue-header">
                        All Devices
                    </Typography>
                    <Checkbox
                        sx={{ marginRight: '16px' }}
                        checked={
                            Boolean(props?.payload?.deviceIds?.length) &&
                            props?.payload?.deviceIds?.length === devicesData?.records?.length
                        }
                        indeterminate={
                            Boolean(props?.payload?.deviceIds?.length) &&
                            props?.payload?.deviceIds?.length < devicesData?.records?.length
                        }
                        onChange={handleSelectAll}
                    />
                </Box>
                {devicesData?.records?.map(
                    (device: Device): JSX.Element => (
                        <Box
                            key={device?.deviceId}
                            className="modal-space-between"
                            sx={{ borderBottom: '1px solid rgba(66, 78, 84, 0.12)', padding: '16px' }}
                        >
                            <Box className="modal-align-center">
                                <Ups className="modal-custom-icon" />
                                <Stack sx={{ paddingLeft: '16px', whiteSpace: 'pre' }}>
                                    <Typography variant="body1" className="modal-grey-header">
                                        {device?.deviceName}
                                    </Typography>
                                    {groupName(device?.groupPath)}
                                </Stack>
                            </Box>
                            <Checkbox
                                checked={props?.payload?.deviceIds?.includes(device?.deviceId)}
                                onChange={(e): void => selectDeviceHandler(e, device?.deviceId)}
                            />
                        </Box>
                    )
                )}
            </>
        ) : (
            <EmptyState
                icon={<DeviceIcon fontSize={'inherit'} sx={{ color: Colors.gray[500] }} />}
                title={'No Devices'}
                actions={
                    <Stack direction={'column'} spacing={2}>
                        {
                            <Button
                                variant={'outlined'}
                                color={'primary'}
                                startIcon={<Add />}
                                onClick={(): void =>
                                    navigate('/add-device', { state: { manageDevicesTab: 'Devices' } })
                                }
                            >
                                {' '}
                                Add a Device{' '}
                            </Button>
                        }
                    </Stack>
                }
            />
        );

    return (
        <DialogContent sx={{ padding: '0', marginTop: '0' }}>
            <DialogContentText id="alert-dialog-slide-description">
                {isLoading || isFetching ? <Loader /> : getDeviceList()}
            </DialogContentText>
        </DialogContent>
    );
});
