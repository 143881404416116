import React from 'react';
import { DialogContent, DialogContentText, Box, Stack, Typography, Divider, Radio } from '@mui/material';
import PowerSettingsNewSharpIcon from '@mui/icons-material/PowerSettingsNewSharp';
import { useGetAllActionsQuery } from '@fiji/common/src/features/actions/actionsApi';
import Loader from '../../components/Loader';

export const SelectAction = React.memo((props: any): JSX.Element => {
    const {
        data: allActions,
        isLoading,
        isFetching,
    }: any = useGetAllActionsQuery(undefined, { refetchOnMountOrArgChange: true });

    /**
     * The function `actionSelectHandler` updates the `actionId` property in the `payload` state using
     * the provided `id`.
     * @param {string} id - The `id` parameter is a string that represents the ID of the selected
     * action.
     */
    const actionSelectHandler = (e: any): void => {
        const id = e.target.value;
        if (props?.onChangeActionName) {
            props.onChangeActionName(e.target.id);
        }
        props?.setPayload((prev: any) => ({
            ...prev,
            actionId: id,
        }));
    };

    return (
        <DialogContent sx={{ padding: '0', marginTop: '0' }}>
            {isLoading || isFetching ? (
                <Loader />
            ) : (
                <DialogContentText
                    id="alert-dialog-slide-description"
                    sx={{ borderTop: '1px solid rgba(66, 78, 84, 0.12)' }}
                >
                    {allActions?.data?.records?.map(
                        (action: any): JSX.Element => (
                            <>
                                <Box className="modal-space-between" sx={{ padding: '8px 16px' }}>
                                    <Box
                                        className={`modal-align-center ${
                                            action.id === props?.payload?.actionId ? 'color-blue' : ''
                                        }`}
                                    >
                                        <PowerSettingsNewSharpIcon className="modal-custom-icon" />
                                        <Stack sx={{ paddingLeft: '8px' }}>
                                            <Typography
                                                variant="body1"
                                                className={`modal-greyhead-text ${
                                                    action.id === props?.payload?.actionId ? 'color-blue' : ''
                                                }`}
                                            >
                                                {action?.name}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                className={`modal-para ${
                                                    action.id === props?.payload?.actionId ? 'color-blue' : ''
                                                }`}
                                            >
                                                {action?.description}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                    <Radio
                                        checked={action.id === props?.payload?.actionId}
                                        onChange={actionSelectHandler}
                                        value={action?.id}
                                        id={action?.name}
                                        name="radio-buttons"
                                    />
                                </Box>
                                <Divider />
                            </>
                        )
                    )}
                </DialogContentText>
            )}
        </DialogContent>
    );
});
