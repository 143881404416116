import { makeStyles } from '@mui/styles';
import * as Colors from '@brightlayer-ui/colors';

import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    card: {
        maxWidth: '450px',
        width: '450px',
        height: '621px',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            borderRadius: 0,
            maxWidth: 'none',
            maxHeight: 'none',
        },
    },
    cardHeader: {
        padding: '40px 24px 0px 24px',
        '& .MuiCardHeader-avatar': {
            margin: 'auto',
            marginBottom: theme.spacing(5),
        },
        '& .MuiCardHeader-content': {
            display: 'none',
        },
    },
    headerAvatarIcon: {
        height: '96px',
        width: '96px',
        backgroundColor: '#e0eef8',
        color: '#007bc1',
        margin: 'auto',
        '& .MuiSvgIcon-root': {
            height: '56px',
            width: '56px',
        },
    },
    cardBody: {
        padding: '16px 24px',
        flex: '1 1 0px',
    },
    cardFooter: {
        borderTop: '1px solid rgba(66, 78, 84, 0.12)',
        padding: '24px',
    },
    deleteButton: {
        background: Colors.red[600],
        '&:hover': {
            backgroundColor: Colors.red[400],
        },
    },
    customCardPadding: {
        padding: `0px 30px`,
        overflowY: 'auto',
    },
}));
