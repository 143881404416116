import { api } from '../../app/api/baseApi';

const customNotificationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        createCustomNotification: builder.mutation<any, any>({
            query: (body) => ({
                url: `/iam/v1/notifications`,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['CustomNotifications'] : []),
        }),
        editCustomNotification: builder.mutation<any, any>({
            query: ({ body, id }) => ({
                url: `/iam/v1/notifications/${id}`,
                method: 'PUT',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['CustomNotifications'] : []),
        }),

        deleteCustomNotification: builder.mutation<object, object>({
            query: (body) => ({
                url: `/iam/v1/notifications`,
                method: 'DELETE',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['CustomNotifications'] : []),
        }),
        getCustomNotifications: builder.query<object, any>({
            query: (body) => ({
                url: `/iam/v1/notifications/filter`,
                body: body,
                method: 'POST',
            }),
            providesTags: ['CustomNotifications'],
        }),
        getCustomNotificationById: builder.query<object, any>({
            query: (notificationId) => ({
                url: `/iam/v1/notifications/${notificationId}`,
            }),
            keepUnusedDataFor: 0.00001,
        }),
    }),
    overrideExisting: true,
});

export const {
    useCreateCustomNotificationMutation,
    useDeleteCustomNotificationMutation,
    useEditCustomNotificationMutation,
    useGetCustomNotificationsQuery,
    useGetCustomNotificationByIdQuery,
} = customNotificationApi;
