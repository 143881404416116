import { useTypedSelector } from '@fiji/common/src/app/store';
import {
    Box,
    DialogContent,
    FormControl,
    FormHelperText,
    InputLabel,
    Select,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useStyles } from './styles';
import { getSelectedGroupLabel } from './';
import { GROUP_NAME_LENGTH_LIMIT, SHORT_GROUP_NAME_LENGTH_LIMIT } from '@fiji/common/src/constants';
import Hierarchy from '../../../components/Hierarchy';
import { selectHierarchyData } from '@fiji/common/src/features/group/groupSlice';

type GroupDetails = { groupName: string; groupShortName: string; destinationGroup: string; [key: string]: unknown };
type GroupDetailsProps = {
    groupDetails: GroupDetails;
    setGroupDetails: (groupDetails: any) => void;
};

export const GroupDetailsContent = ({ groupDetails, setGroupDetails }: GroupDetailsProps): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const hierarchyData = useTypedSelector(selectHierarchyData);

    const [openSelectBox, setOpenSelectBox] = useState(false);

    const handleToggleSelectBox = (): void => {
        setOpenSelectBox((prev) => !prev);
    };

    const handleGroupSelect = (nodeIds: string): void => {
        setGroupDetails((prevState: GroupDetails) => ({ ...prevState, destinationGroup: nodeIds }));
        setOpenSelectBox(false);
    };

    return (
        <DialogContent>
            <TextField
                className={classes.textField}
                sx={{ marginTop: '8px !important' }}
                id={'groupName'}
                label={'Group Name'}
                required
                fullWidth
                variant={'filled'}
                helperText={
                    <Box className={classes.helperTextwrapper}>
                        <Typography component={'span'}>{'e.g. Location, Region, Division, etc.'}</Typography>
                        <Typography component={'span'} className={classes.helperText}>
                            {`${groupDetails?.groupName?.length ?? 0}/${GROUP_NAME_LENGTH_LIMIT}`}
                        </Typography>
                    </Box>
                }
                value={groupDetails?.groupName}
                onChange={(e: any): void => {
                    if (e?.target?.value?.length > GROUP_NAME_LENGTH_LIMIT) {
                        return;
                    }
                    setGroupDetails((prevState: GroupDetails) => ({ ...prevState, groupName: e.target.value }));
                }}
            />
            <TextField
                className={classes.textField}
                id={'groupName'}
                label={'Group Short Name'}
                fullWidth
                variant={'filled'}
                helperText={
                    <Box className={classes.helperTextwrapper}>
                        <Typography component={'span'}>
                            {'A short name can be used when displaying a breadcrumb or auto-naming a device'}
                        </Typography>
                        <Typography component={'span'} className={classes.helperText}>
                            {`${groupDetails?.groupShortName?.length ?? 0}/${SHORT_GROUP_NAME_LENGTH_LIMIT}`}
                        </Typography>
                    </Box>
                }
                value={groupDetails?.groupShortName}
                onChange={(e: any): void => {
                    if (e?.target?.value?.length > SHORT_GROUP_NAME_LENGTH_LIMIT) {
                        return;
                    }
                    setGroupDetails((prevState: GroupDetails) => ({ ...prevState, groupShortName: e.target.value }));
                }}
            />
            <FormControl variant={'filled'} required fullWidth sx={{ marginTop: theme.spacing(2) }}>
                <InputLabel id="demo-simple-select-autowidth-label">Parent Group</InputLabel>
                <Select
                    displayEmpty
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    disabled={hierarchyData?.isLoading}
                    renderValue={(): string =>
                        getSelectedGroupLabel(groupDetails?.destinationGroup, hierarchyData?.data?.data)
                    }
                    value={getSelectedGroupLabel(groupDetails?.destinationGroup, hierarchyData?.data?.data) ? ' ' : ''}
                    open={openSelectBox}
                    onClose={handleToggleSelectBox}
                    onOpen={handleToggleSelectBox}
                >
                    <Hierarchy
                        selectedNodes={groupDetails?.destinationGroup}
                        customClass="parent-group-height"
                        isOverflow={true}
                        onChangeTreeItem={handleGroupSelect}
                        hierarchyData={hierarchyData?.data?.data?.data}
                    />
                </Select>
                <FormHelperText>
                    This structure can also be edited from the main hierarchy page with a drag-and-drop function
                </FormHelperText>
            </FormControl>
        </DialogContent>
    );
};
