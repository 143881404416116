import { Box, Button, DialogContent, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { InsertPhoto, Remove } from '@mui/icons-material';
import { UploadImage } from '../../../components/UploadImage';
import { useImageValidated } from '../../../hooks';

export const DisplayPreferencesContent = ({
    preferenceType,
    setPreferenceType,
    groupImage,
    setGroupImage,
}: {
    preferenceType: string;
    setPreferenceType: (preferenceType: any) => void;
    groupImage: any;
    setGroupImage: (groupImage: any) => void;
}): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [getValidator] = useImageValidated();

    const [groupImagePreview, setGroupImagePreview] = useState<string | null>(null);

    useEffect(() => {
        if (typeof groupImage === 'object' && groupImage !== null) {
            const groupImagePreviewUrl = URL.createObjectURL(groupImage);
            setGroupImagePreview(groupImagePreviewUrl);
            setPreferenceType('photo');
        } else if (groupImage?.includes('https')) {
            setGroupImagePreview(groupImage);
            setPreferenceType('photo');
        } else {
            setGroupImage(null);
            setGroupImagePreview(null);
            setPreferenceType('default');
        }
    }, [groupImage]);

    const handleChangePreferenceType = (e: any, value: string): void => {
        if (value !== null) {
            setPreferenceType(value);
        }
        if (value === 'default') {
            setGroupImagePreview(null);
            setGroupImage(null);
        }
    };

    const handleRemoveGroupImage = (): void => {
        setGroupImage(null);
        setGroupImagePreview(null);
        setPreferenceType('default');
    };

    const handleUploadImage = (e: any): void => {
        const target = e.target as HTMLInputElement;
        if (!target.files) return;
        if (getValidator(target.files[0])) {
            setGroupImage(target.files[0]);
        }
        e.target.value = null;
    };

    return (
        <DialogContent>
            <ToggleButtonGroup
                className={classes.toggleButtonGroup}
                sx={{ width: '100%' }}
                color="primary"
                exclusive
                aria-label="Platform"
                value={preferenceType}
                onChange={handleChangePreferenceType}
            >
                <ToggleButton value="photo" sx={{ width: '50%' }} className={classes.toggleButton}>
                    <InsertPhoto sx={{ mr: 1 }} />
                    Photo
                </ToggleButton>
                <ToggleButton value="default" sx={{ width: '50%' }} className={classes.toggleButton}>
                    Default
                </ToggleButton>
            </ToggleButtonGroup>
            {preferenceType === 'default' || groupImagePreview ? (
                <>
                    <Box className={classes.contentBodyImage}>
                        <Box
                            component={'img'}
                            src={groupImagePreview ?? require('../../../assets/images/login-image.png')}
                        ></Box>
                    </Box>
                    {groupImagePreview && (
                        <Button
                            sx={{ mt: 4 }}
                            variant={'outlined'}
                            color={'primary'}
                            startIcon={<Remove />}
                            fullWidth
                            onClick={handleRemoveGroupImage}
                        >
                            Remove Photo
                        </Button>
                    )}
                </>
            ) : (
                <UploadImage onChange={handleUploadImage} wrapperClass={classes.emptyStateWrapper} />
            )}
        </DialogContent>
    );
};
