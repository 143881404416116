import React from 'react';

/**
 * The `useRBAC` function is a custom React hook that manages user permissions and provides a way to
 * check if a user has a specific permission.
 * @param {any} permissions - The `permissions` parameter is an array of objects representing the
 * permissions of a user. Each object in the array should have a `name` property that represents the
 * name of the permission.
 * @returns The function `useRBAC` returns an object with a single property `hasPermission`, which is a
 * function.
 */
export const useRBAC = (permissions: any): any => {
    const [userPermissions, setUserPermissions] = React.useState<string[]>([]);

    /* The `React.useEffect` hook is used to perform side effects in a functional component. In this
    case, it is used to update the `userPermissions` state whenever the `permissions` prop changes. */
    React.useEffect(() => {
        if (permissions && Array.isArray(permissions)) {
            const allPermissions: string[] = [];
            permissions.forEach((permission) => {
                allPermissions.push(permission.name);
            });
            setUserPermissions(allPermissions);
        }
    }, [permissions]);

    /**
     * The function `hasPermission` checks if a given permission name is included in the `userPermissions`
     * array and returns a boolean value.
     * @param {string} permissionName - A string representing the name of the permission that needs to be
     * checked.
     */
    const hasPermission = (permissionName: string): boolean => userPermissions.includes(permissionName);

    return {
        hasPermission,
    };
};
