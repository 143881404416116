/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { Dialog, DialogTitle, Typography, DialogContent, TextField, DialogActions, Stack, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useStyles } from './styles';
import { useEditDeviceMutation } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { useAppDispatch } from '@fiji/common/src/app/store';
import { setToastifyContent } from '@fiji/common/src/features/common/commonSlice';
import Loader from '../../components/Loader';
import { handleWhiteSpaces } from '../../CommonUtils';
import { DEVICE_FIELDS_LIMIT } from '@fiji/common/src/constants';

/**
 * The `EditDeviceDescriptionModalProps` type is used to define the props for a modal component that
 * allows editing the description of a device.
 * @property {string | undefined} description - A string that represents the description of the device.
 * It can be undefined if no description is provided.
 * @property {boolean} isOpen - A boolean value indicating whether the modal is open or not.
 * @property {any} deviceId - The `deviceId` property is used to identify the specific device for which
 * the description is being edited. It can be of any type, as indicated by the `any` type annotation.
 * @property closeHandler - A function that will be called when the modal is closed.
 * @property refetchDetailsCall - The `refetchDetailsCall` property is a function that is used to
 * trigger a data refetch or update after the device description has been edited.
 */
type EditDeviceDescriptionModalProps = {
    description: string | undefined;
    isOpen: boolean;
    deviceId: any;
    closeHandler: () => void;
    refetchDetailsCall: () => void;
};

export const EditDeviceDescriptionModal = (props: EditDeviceDescriptionModalProps): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();

    const [description, setDescription] = React.useState<any>(props.description ?? '');

    const [editDeviceDetail, { isSuccess, isLoading }] = useEditDeviceMutation();

    /* The `React.useEffect` hook is used to perform side effects in a functional component. In this
    case, the effect is triggered whenever the `props.description` value changes. */
    React.useEffect(() => {
        if (props?.description) {
            setDescription(props.description);
        }
    }, [props?.description]);

    /* The `React.useEffect` hook is used to perform side effects in a functional component. In this
    case, the effect is triggered whenever the `isSuccess` value changes. */
    React.useEffect(() => {
        if (isSuccess) {
            props.refetchDetailsCall();
            props.closeHandler();
        }
    }, [isSuccess]);

    /**
     * The function `handleModalClose` closes a modal and resets the description.
     */
    const handleModalClose = (): void => {
        props.closeHandler();
        setDescription(props?.description ?? '');
    };

    /**
     * The submitHandler function is an asynchronous function that calls the editDeviceDetail function
     * with a payload and an id.
     */
    const submitHandler = async (): Promise<void> => {
        const descriptionPayload = { description };
        const { error }: any = await editDeviceDetail({ payload: descriptionPayload, id: props.deviceId });

        if (!error) {
            dispatch(setToastifyContent({ isOpen: true, message: 'Changes Saved Successfully' }));
        }
    };

    return (
        <Dialog
            open={props.isOpen}
            onClose={handleModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.rootMuiDialogpaper}
        >
            {isLoading ? (
                <>
                    <DialogContent sx={{ height: '225px' }}>
                        <Loader size={60} />
                    </DialogContent>
                </>
            ) : (
                <>
                    <DialogTitle id="alert-dialog-title" className={classes.dialogTitleRoot}>
                        <Typography variant={'h6'}>Edit Description</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            value={description}
                            id="deviceName"
                            label={'Device Description'}
                            fullWidth
                            variant={'filled'}
                            onChange={(e: any): void => {
                                if (e.target.value?.length <= DEVICE_FIELDS_LIMIT['description'])
                                    setDescription(e.target.value);
                            }}
                        />
                        <Typography variant="body2" className="custom-add-device-field-lengths-style">
                            {`${description?.length || 0}/${DEVICE_FIELDS_LIMIT['description']}`}
                        </Typography>
                    </DialogContent>
                    <DialogActions className={classes.MuiDialogActionsRoot}>
                        <Stack direction={'row'} spacing={2} justifyContent={'end'}>
                            <Button variant={'outlined'} onClick={handleModalClose}>
                                Cancel
                            </Button>
                            <Button
                                disabled={description === props.description || !handleWhiteSpaces(description)}
                                onClick={submitHandler}
                                variant={'contained'}
                            >
                                Save
                            </Button>
                        </Stack>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};
