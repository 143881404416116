import React, { useState } from 'react';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import {
    selectMoveGroupsDevicesModal,
    moveGroupsDevicesModal,
    selectHierarchyData,
} from '@fiji/common/src/features/group/groupSlice';
import { StepperModal } from '../../../components/StepperModal';
import {
    MovingSourceContent,
    MovingDestinationContent,
    MovingPreviewContent,
    SourceNodes,
    DestinationNode,
    RelocationCompleteModal,
} from './';
import { useGetHierarchyHandlers } from '../../../hooks';
import { useMoveExistingGroupMutation } from '@fiji/common/src/features/group/groupApi';
import { BackdropLoader } from '../../../components/BackdropLoader';

export const MoveGroupsDevicesModal = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const moveModal = useTypedSelector(selectMoveGroupsDevicesModal);

    const [currentStep, setCurrentStep] = useState<string>('source');
    const [selectedDestinationGroup, setSelectedDestinationGroup] = useState<DestinationNode>({
        id: '',
        name: '',
    });
    const [selectedSource, setSelectedSource] = useState<SourceNodes>({ devices: [], groups: [] });
    const { removeUnwantedIds, findGroupOrDeviceById } = useGetHierarchyHandlers({
        isChildrenPreselected: true,
    });

    const hierarchyData = useTypedSelector(selectHierarchyData);

    const [moveExistingPayload, setMoveExistingPayload] = useState({ devices: [], groups: [] });
    const [previewHierarchy, setPreviewHierarchy] = useState<any>([]);
    const [isMovingGroupsAndDevices, setIsMovingGroupsAndDevices] = useState(false);
    const [isRelocated, setIsRelocated] = useState(false);

    const [moveExistingGroup] = useMoveExistingGroupMutation();

    React.useEffect(() => {
        if (moveModal?.destinationGroupId)
            setSelectedDestinationGroup({
                id: moveModal?.destinationGroupId,
                name: moveModal?.destinationGroupName ?? '',
            });
    }, [moveModal]);

    React.useEffect(() => {
        const finalHierarchy = JSON.parse(
            JSON.stringify(findGroupOrDeviceById(hierarchyData?.data?.data?.data, selectedDestinationGroup?.id))
        );
        if (finalHierarchy) {
            const filteredNodeIds = removeUnwantedIds(
                hierarchyData?.data?.data?.data,
                selectedSource?.groups,
                selectedSource?.devices
            );
            setMoveExistingPayload(filteredNodeIds);
            const allDevices: any = [];
            const allGroups: any = [];
            filteredNodeIds?.devices?.map((deviceId: string) => {
                allDevices.push(findGroupOrDeviceById(hierarchyData?.data?.data?.data, deviceId));
            });
            filteredNodeIds?.groups?.map((groupId: string) => {
                allGroups.push(findGroupOrDeviceById(hierarchyData?.data?.data?.data, groupId));
            });
            finalHierarchy.devices = allDevices?.filter((item: any, i: any, ar: any) => ar.indexOf(item) === i);
            finalHierarchy.children = allGroups;
            setPreviewHierarchy([finalHierarchy]);
        }
    }, [selectedSource, hierarchyData?.data, selectedDestinationGroup]);

    const closeMoveModal = (): void => {
        dispatch(moveGroupsDevicesModal({ isOpen: false, groupId: '', deviceId: '', destinationGroupId: '' }));
    };
    const closeCompleteModal = (): void => {
        setIsRelocated(false);
        setCurrentStep('source');
        setSelectedDestinationGroup({
            id: '',
            name: '',
        });
        setPreviewHierarchy([]);
        setSelectedSource({ devices: [], groups: [] });
        setMoveExistingPayload({ devices: [], groups: [] });
    };

    const modalContent = React.useMemo(
        () => [
            {
                key: 'source',
                title: 'Move Group',
                header: 'Select Devices and Groups to Move',
                description:
                    'Moving groups may affect user access rights, information aggregation, historical data, reports, and more.',
                backButtonLabel: 'Back',
                component: (
                    <MovingSourceContent
                        onNodeSelection={setSelectedSource}
                        selectedSource={selectedSource}
                        disabledGroupId={selectedDestinationGroup.id}
                        isOpen={moveModal?.isOpen}
                    />
                ),
            },
            {
                key: 'destination',
                title: 'Move Group',
                header: 'Select the Destination Group',
                description:
                    'Moving groups may affect user access rights, information aggregation, historical data, reports, and more.',
                component: (
                    <MovingDestinationContent
                        selectedDestinationGroup={selectedDestinationGroup}
                        setSelectedDestinationGroup={setSelectedDestinationGroup}
                        disabledGroupIds={selectedSource?.groups}
                    />
                ),
            },
            {
                key: 'preview',
                title: 'Move Group',
                header: `Move Devices and Groups to ${selectedDestinationGroup?.name}`,
                description:
                    'Moving groups may affect user access rights, information aggregation, historical data, reports, and more.',
                nextButtonLabel: 'Move Group(s) & Device(s)',
                component: (
                    <MovingPreviewContent
                        destinationGroup={selectedDestinationGroup}
                        previewHierarchy={previewHierarchy}
                    />
                ),
            },
        ],
        [selectedSource, selectedDestinationGroup, previewHierarchy]
    );

    const submitHandler = async (): Promise<void> => {
        setIsMovingGroupsAndDevices(true);
        closeMoveModal();
        const { error }: any = await moveExistingGroup({
            destinationGroupId: selectedDestinationGroup?.id,
            ...(moveExistingPayload?.devices?.length && { deviceIds: moveExistingPayload?.devices }),
            ...(moveExistingPayload?.groups?.length && { groupIds: moveExistingPayload?.groups }),
        });
        if (!error) {
            setIsRelocated(true);
        } else {
            closeCompleteModal();
        }
        setIsMovingGroupsAndDevices(false);
    };

    const getCurrentStep = (step: string): void => {
        setCurrentStep(step);
    };

    const handleStepDisable = (): boolean => {
        switch (currentStep) {
            case 'source':
                return Boolean(!selectedSource?.devices?.length && !selectedSource?.groups?.length);
            case 'destination':
                return Boolean(!selectedDestinationGroup?.id);
            default:
                return false;
        }
    };

    if (isMovingGroupsAndDevices) {
        return <BackdropLoader isOpen={true} />;
    }

    return (
        <>
            <StepperModal
                modalContent={modalContent}
                closeModal={(): void => {
                    closeMoveModal();
                    closeCompleteModal();
                }}
                isOpen={moveModal?.isOpen}
                submitHandler={submitHandler}
                getCurrentStep={getCurrentStep}
                isDisabled={handleStepDisable()}
                closeModalOnFinish={false}
            />
            <RelocationCompleteModal
                isOpen={isRelocated}
                modalHandler={closeCompleteModal}
                destinationGroup={selectedDestinationGroup.name}
                selectedSource={selectedSource}
                groupsHierarchy={hierarchyData?.data?.data?.data}
            />
        </>
    );
};
