import React from 'react';
import { useTypedSelector } from '@fiji/common/src/app/store';
import Hierarchy from '../../../components/Hierarchy';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useGetHierarchyHandlers } from '../../../hooks';
import { DestinationNode } from './';
import { selectHierarchyData } from '@fiji/common/src/features/group/groupSlice';

export const MovingDestinationContent = ({
    selectedDestinationGroup,
    setSelectedDestinationGroup,
    disabledGroupIds,
}: {
    selectedDestinationGroup: DestinationNode;
    setSelectedDestinationGroup: (args: DestinationNode) => void;
    disabledGroupIds: string[];
}): JSX.Element => {
    const currentOrg = useTypedSelector(selectedOrg);
    const { getGroupLabel } = useGetHierarchyHandlers({
        isChildrenPreselected: true,
    });

    const hierarchyData = useTypedSelector(selectHierarchyData);

    const [expandedNodes, setExpandedNodes] = React.useState<string[]>([currentOrg?.id]);

    const onExpandNodes = (nodeIds: string[]): void => {
        setExpandedNodes(nodeIds);
    };

    const onChangeTreeItem = (id: string): void => {
        const name = getGroupLabel(hierarchyData?.data?.data?.data, id);
        setSelectedDestinationGroup({ id, name });
    };

    return (
        <>
            <Hierarchy
                hierarchyData={hierarchyData?.data?.data?.data}
                onChangeTreeItem={onChangeTreeItem}
                selectedNodes={selectedDestinationGroup?.id}
                expandedNodes={expandedNodes}
                filter={[
                    ...(disabledGroupIds.length
                        ? disabledGroupIds.map((groupId): any => ({
                              key: 'id',
                              value: groupId,
                              operator: '!==',
                              iterator: 'children',
                              action: 'disable',
                          }))
                        : []),
                ]}
                isLoading={hierarchyData?.isLoading}
                onExpandNodes={onExpandNodes}
                radioSelection
            />
        </>
    );
};
