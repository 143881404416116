import { api } from '../../app/api/baseApi';

export const registrationUIApi = api.injectEndpoints({
    endpoints: (builder) => ({
        generateCode: builder.mutation({
            query: (body) => ({
                url: '/iam/v1/users/signup/code/generate',
                method: 'POST',
                body: body,
            }),
        }),
        verifySignUpCode: builder.mutation({
            query: (body) => ({
                url: '/iam/v1/users/signup/code/verify',
                method: 'POST',
                body: body,
            }),
        }),
        updateProfile: builder.mutation({
            query: (body) => ({
                url: '/iam/v1/users/signup',
                method: 'POST',
                body: body,
            }),
        }),
    }),
});

export const { useGenerateCodeMutation, useVerifySignUpCodeMutation, useUpdateProfileMutation } = registrationUIApi;

export const {
    endpoints: { generateCode, verifySignUpCode, updateProfile },
} = registrationUIApi;
