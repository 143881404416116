/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import {
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    TextField,
    DialogActions,
    Stack,
    Button,
    Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useStyles } from './styles';
import { setToastifyContent } from '@fiji/common/src/features/common/commonSlice';
import { useAppDispatch } from '@fiji/common/src/app/store';
import { LOAD_NAME_LENGTH_LIMIT } from '@fiji/common/src/constants';
import { useUpdateDeviceLoadByIdMutation } from '@fiji/common/src/features/deviceDetails/deviceDetailApi';
import Loader from '../../components/Loader';

/**
 * The `EditLoadNameModalProps` type represents the props for an edit device name modal component in
 * a TypeScript React application.
 * @property {string | undefined} loadName - The `loadName` property is a string that represents
 * the name of the device. It can be either a valid string value or `undefined` if the device name is
 * not available.
 * @property {boolean} isOpen - A boolean value indicating whether the modal is open or not.
 * @property {any} loadId - The `loadId` property is used to identify the specific device for which
 * the name is being edited. It can be of any type, as indicated by the `any` type annotation.
 * @property closeHandler - A function that will be called when the modal is closed.
 */
type EditLoadNameModalProps = {
    loadName: string | undefined;
    isOpen: boolean;
    loadId: any;
    deviceId: any;
    closeHandler: () => void;
};

export const EditLoadNameModal = (props: EditLoadNameModalProps): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();

    const [name, setName] = React.useState<string | undefined>(props.loadName);

    const [updateDeviceLoadById, { isSuccess, isLoading }] = useUpdateDeviceLoadByIdMutation();

    /* The `React.useEffect` hook is used to perform side effects in a functional component. In this
   case, the effect is triggered when the `props.loadName` value changes. */
    React.useEffect(() => {
        if (props?.loadName && props.isOpen) {
            setName(props.loadName);
        }
    }, [props?.loadName, props.isOpen]);

    /* The `React.useEffect` hook in the code snippet is used to perform a side effect when the
    `isSuccess` variable changes. */
    React.useEffect(() => {
        if (isSuccess) {
            props.closeHandler();
        }
    }, [isSuccess]);

    /**
     * The submitHandler function is an asynchronous function that calls the editDeviceDetail function
     * with the device name and device ID as parameters.
     */
    const submitHandler = async (): Promise<void> => {
        const { error }: any = await updateDeviceLoadById({
            body: { loadName: name },
            loadId: props.loadId,
            deviceId: props.deviceId,
        });

        if (!error) {
            dispatch(setToastifyContent({ isOpen: true, message: 'Changes Saved Successfully' }));
        }
    };

    /**
     * The function `handleModalClose` closes a modal and sets the name of a device.
     */
    const handleModalClose = (): void => {
        props.closeHandler();
        setName(props?.loadName);
    };

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.closeHandler}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.rootSmallMuiDialogPaper}
        >
            {isLoading ? (
                <DialogContent sx={{ height: '225px' }}>
                    <Loader size={60} />
                </DialogContent>
            ) : (
                <>
                    <DialogTitle id="alert-dialog-title" className={classes.dialogTitleRoot}>
                        <Typography variant={'h6'}>Edit Name</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            value={name}
                            id="loadName"
                            label={'Load Name'}
                            fullWidth
                            variant={'filled'}
                            onChange={(e: any): void => {
                                if (e.target.value.length > LOAD_NAME_LENGTH_LIMIT) {
                                    return;
                                }
                                setName(e.target.value);
                            }}
                            helperText={
                                <Box className={classes.helperTextwrapper}>
                                    <Typography component={'span'} className={classes.helperText}>
                                        {`${name?.length ?? 0}/${LOAD_NAME_LENGTH_LIMIT}`}
                                    </Typography>
                                </Box>
                            }
                        />
                    </DialogContent>
                    <DialogActions className={classes.MuiDialogActionsRoot}>
                        <Stack direction={'row'} spacing={2} justifyContent={'end'}>
                            <Button variant={'outlined'} onClick={handleModalClose}>
                                Cancel
                            </Button>
                            <Button
                                disabled={name === props.loadName || name === ''}
                                onClick={submitHandler}
                                variant={'contained'}
                            >
                                Save
                            </Button>
                        </Stack>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};
