import { api } from '../../app/api/baseApi';

export const timeLineApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getTimeLine: builder.query<object, any>({
            query: (body) => ({
                url: `/alarm/v1/timeline/filter`,
                method: 'POST',
                body: body,
            }),
            providesTags: (args): any => [{ type: 'Timeline', id: args }],
        }),
        getTimeLineSummary: builder.query<object, any>({
            query: (params) => ({
                url: `/alarm/v1/timeline/summary`,
                method: 'POST',
                params,
            }),
            providesTags: ['Timeline'],
        }),
        getTimeLineById: builder.query<object, any>({
            query: (body) => ({
                url: `/alarm/v1/timeline/${body?.timeLineId}`,
            }),
        }),
        getTimelineStatus: builder.query<object, void>({
            query: () => ({
                url: `/alarm/v1/timeline/status`,
            }),
        }),
        downloadTimeline: builder.mutation<any, any>({
            query: ({ payload, id }: any) => ({
                url: `/alarm/v1/timeline/${id}/download`,
                method: 'POST',
                body: payload,
            }),
        }),
    }),
});

export const {
    useGetTimeLineQuery,
    useGetTimeLineSummaryQuery,
    useGetTimeLineByIdQuery,
    useGetTimelineStatusQuery,
    useDownloadTimelineMutation,
} = timeLineApi;
