import React, { useEffect, useState } from 'react';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { setLastInteractionTime } from '@fiji/common/src/features/common/commonSlice';

export const IdleLogoutHandler = (props: any): JSX.Element => {
    const [isIdle, setIsIdle] = useState(false);
    const dispatch = useAppDispatch();
    const lastInteractionTime = useTypedSelector((state) => state.common.lastInteractionTime);
    let timer: any;
    const events = ['click', 'load', 'keydown'];
    const eventHandler = (): void => {
        if (!isIdle) {
            dispatch(setLastInteractionTime(Date.now()));
            if (timer) {
                startTimer();
            }
        }
    };

    useEffect(() => {
        addEvents();

        return () => {
            removeEvents();
            clearTimeout(timer);
        };
    }, []);

    const startTimer = (): void => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(
            () => {
                const diff = Date.now() - lastInteractionTime;
                const timeOutInterval = props.timeOutInterval ? props.timeOutInterval : 6000;
                if (isIdle) {
                    clearTimeout(timer);
                } else if (diff < timeOutInterval) {
                    startTimer();
                } else {
                    props.onIdle();
                    setIsIdle(true);
                }
            },
            props.timeOutInterval ? props.timeOutInterval : 6000
        );
    };
    const addEvents = (): void => {
        events.forEach((eventName) => {
            window.addEventListener(eventName, eventHandler);
        });

        startTimer();
    };

    const removeEvents = (): void => {
        events.forEach((eventName) => {
            window.removeEventListener(eventName, eventHandler);
        });
    };

    return <></>;
};
