import { createEntityAdapter } from '@reduxjs/toolkit';
import { api } from '../../app/api/baseApi';
import { Device, DeviceTypeApiResponse } from '../../types/Device';
import { store } from '../../app/store';
import { setTotalDeviceRecords } from './deviceSlice';

const deviceAdapter = createEntityAdapter<any>({
    selectId: (item: Device) => item.deviceId,
});
const deviceSelector = deviceAdapter.getSelectors();

export const deviceApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllDevices: builder.query<object, void>({
            query: (body) => ({
                url: `/dm/v1/devices/filter`,
                method: 'POST',
                body: body,
            }),
        }),

        getDevices: builder.query<object, any>({
            query: (params: any) => ({
                url: `/dm/v1/devices/valid`,
                method: 'POST',
                body: params,
            }),
            providesTags: ['DeviceList'],
        }),

        getDeviceName: builder.mutation({
            query: (params: any) => ({
                url: `/dm/v1/devices/valid/name`,
                method: 'POST',
                body: params,
            }),
        }),

        createDevice: builder.mutation({
            query: (body) => ({
                url: `/dm/v1/devices`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error): any =>
                !error ? ['DeviceList', 'GroupsHierarchy', 'GroupsHierarchyList', 'Orgs'] : [],
            extraOptions: {
                noToast: true,
            },
        }),
        getDeviceStatus: builder.query<object, void>({
            query: () => ({ url: `/dm/v1/devices/statuses` }),
        }),
        getDeviceTypes: builder.query<DeviceTypeApiResponse, void>({
            query: () => ({
                url: `/dm/v1/devices/types`,
                method: 'GET',
            }),
        }),
        checkValidGuid: builder.mutation({
            query: (body) => ({
                url: `/dm/v1/devices/valid`,
                method: 'POST',
                body,
            }),
        }),

        getDeviceList: builder.query({
            query: (body: any) => ({
                url: '/dm/v1/devices/filter',
                method: 'POST',
                body,
            }),
            keepUnusedDataFor: 0.00001,
            providesTags: (args): any => [{ type: 'DeviceList', id: args }],

            transformResponse: (response: any) => {
                store.dispatch(setTotalDeviceRecords(response.data.total));
                return deviceAdapter.addMany(deviceAdapter.getInitialState(), response.data.records);
            },
            merge: (currentState, incomingState) => {
                deviceAdapter.addMany(currentState, deviceSelector.selectAll(incomingState));
            },
            forceRefetch: ({ currentArg, previousArg }) => JSON.stringify(currentArg) !== JSON.stringify(previousArg),
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
                const args = JSON.parse(JSON.stringify(queryArgs));
                delete args?.page;
                return `${endpointName}-${JSON.stringify(args)}`;
            },
        }),

        getPaginatedDeviceList: builder.query({
            query: (body) => ({
                url: `/dm/v1/devices/filter`,
                method: 'POST',
                body: body,
            }),
            providesTags: ['DeviceList'],
            keepUnusedDataFor: 0.00001,
            transformResponse: (response: any) => response?.data,
        }),

        deleteDevice: builder.mutation<object, object>({
            query: (body) => ({
                url: `/dm/v1/devices`,
                method: 'DELETE',
                body,
            }),
            invalidatesTags: (result, error): any =>
                !error ? ['GroupsHierarchy', 'GroupsHierarchyList', 'DeviceList', 'Orgs'] : [],
        }),
        editDevice: builder.mutation<object, any>({
            query: (body) => ({
                url: `/dm/v1/devices/${body.id}`,
                method: 'PATCH',
                body: body.payload,
            }),
            invalidatesTags: (result, error): any =>
                !error ? ['GroupsHierarchy', 'GroupsHierarchyList', 'DeviceList'] : [],
        }),
        getDeviceById: builder.query<object, any>({
            query: (id) => ({
                url: `/dm/v1/devices/${id}`,
                method: 'GET',
            }),
            providesTags: ['DeviceDetails'],
        }),
        moveExistingDevice: builder.mutation<object, any>({
            query: (body) => ({
                url: `/dm/v1/devices/${body.groupId}/assign`,
                method: 'PATCH',
                body: body.payload,
            }),
            invalidatesTags: (result, error): any =>
                !error ? ['GroupsHierarchy', 'GroupsHierarchyList', 'DeviceList'] : [],
        }),
    }),
    overrideExisting: true,
});

export { deviceSelector, deviceAdapter };

export const {
    useGetAllDevicesQuery,
    useGetDevicesQuery,
    useLazyGetDeviceListQuery,
    useDeleteDeviceMutation,
    useEditDeviceMutation,
    useGetPaginatedDeviceListQuery,
    useGetDeviceByIdQuery,
    useMoveExistingDeviceMutation,
    useGetDeviceStatusQuery,
    useGetDeviceListQuery,
    useCreateDeviceMutation,
    useGetDeviceTypesQuery,
    useCheckValidGuidMutation,
    useGetDeviceNameMutation,
} = deviceApi;
