import { api } from '../../app/api/baseApi';

export const orgApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getOrg: builder.query<any, any>({
            query: (params) => ({
                url: `/iam/v2/users/me/orgs`,
                method: 'GET',
                ...(params ? { params } : { params: { page: 0, size: 25 } }),
            }),
            providesTags: ['Orgs'],
        }),
        getPaginatedOrg: builder.query<any, any>({
            query: (params) => {
                const paramsClone = JSON.parse(JSON.stringify(params ?? {}));
                if (paramsClone?.pullRefresh) {
                    delete paramsClone.pullRefresh;
                }
                return {
                    url: `/iam/v2/users/me/orgs`,
                    method: 'GET',
                    ...(paramsClone ? { params: paramsClone } : { params: { page: 0, size: 25 } }),
                };
            },
            providesTags: ['Orgs'],
            keepUnusedDataFor: 0.00001,
            serializeQueryArgs: ({ endpointName, queryArgs }: any) => {
                const newQueryArgs = { ...queryArgs };
                if (newQueryArgs.page !== null && newQueryArgs?.page !== undefined) {
                    delete newQueryArgs.page;
                    delete newQueryArgs.pullRefresh;
                }

                return [`${endpointName}`, { ...newQueryArgs }];
            },
            forceRefetch: ({ current, incoming }: any): any => JSON.stringify(current) !== JSON.stringify(incoming),
            merge: (currentCache: any, newItems: any, { arg }: any): any => {
                const newData = JSON.parse(JSON.stringify(currentCache));
                if (arg?.pullRefresh || arg.page === 0) {
                    return newItems;
                }
                if (newItems?.data?.records?.length) {
                    newData?.data?.records?.push(...newItems.data.records);
                }
                return newData;
            },
        }),

        createOrg: builder.mutation({
            query: (body) => ({
                url: `/iam/v1/orgs`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Orgs', 'Profile'] : []),
            extraOptions: {
                noToast: true,
            },
        }),

        joinOrg: builder.mutation({
            query: (body) => ({
                url: `/iam/v1/users/me/request/initiate`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Orgs', 'Profile'] : []),
        }),

        withdrawOrg: builder.mutation({
            query: (body) => ({
                url: `/iam/v1/users/me/request/withdraw`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Orgs'] : []),
        }),

        updateOrg: builder.mutation({
            query: ({ orgId, body }) => ({
                url: `/iam/v1/orgs/${orgId}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Orgs'] : []),
        }),

        uploadOrganizationLogo: builder.mutation<object, object>({
            query: (payload: { orgId: string; logo: FormData }) => ({
                url: `/iam/v1/orgs/${payload.orgId}/logo`,
                method: 'PATCH',
                body: payload.logo,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Orgs'] : []),
        }),

        switchOrg: builder.mutation({
            query: (body) => ({
                url: `/iam/v1/users/me/switch/orgs`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error): any =>
                !error ? ['Orgs', 'Notifications', 'DeviceList', 'Timeline', 'Documents', 'GroupsHierarchyList'] : [],
        }),

        deleteOrg: builder.mutation({
            query: (body: { orgId: string }) => ({
                url: `/iam/v1/orgs/${body.orgId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error): any => (!error ? ['Orgs', 'GroupsHierarchyList'] : []),
        }),

        getUsersForRole: builder.mutation({
            query: (body) => ({
                url: `/iam/v1/users/filter`,
                method: 'POST',
                body,
            }),
        }),

        assignRole: builder.mutation({
            query: ({ userId, body }) => ({
                url: `/iam/v1/users/${userId}/assign/role`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (result, error): any =>
                !error
                    ? [
                          'Orgs',
                          'Notifications',
                          'GroupsHierarchy',
                          'DeviceList',
                          'Timeline',
                          'Documents',
                          'GroupsHierarchyList',
                      ]
                    : [],
        }),

        leaveOrg: builder.mutation({
            query: ({ orgId }) => ({
                url: `/iam/v1/orgs/${orgId}/leave`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error): any =>
                !error ? ['Orgs', 'Notifications', 'DeviceList', 'Timeline', 'Documents', 'GroupsHierarchyList'] : [],
        }),
        removeOrgLogo: builder.mutation({
            query: ({ orgId }) => ({
                url: `/iam/v1/orgs/${orgId}/logo`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error): any => (!error ? ['Orgs'] : []),
        }),
    }),
});

export const {
    useGetOrgQuery,
    useGetPaginatedOrgQuery,
    useCreateOrgMutation,
    useJoinOrgMutation,
    useWithdrawOrgMutation,
    useUpdateOrgMutation,
    useSwitchOrgMutation,
    useDeleteOrgMutation,
    useGetUsersForRoleMutation,
    useAssignRoleMutation,
    useLeaveOrgMutation,
    useUploadOrganizationLogoMutation,
    useRemoveOrgLogoMutation,
} = orgApi;
