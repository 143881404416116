import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    Typography,
} from '@mui/material';
import { Devices, Error, LockPerson, Security } from '@mui/icons-material';
import { AccountDetailsFormProps } from '@brightlayer-ui/react-auth-workflow';
import { Stack } from '@mui/system';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import * as Colors from '@brightlayer-ui/colors';
import { EmptyState, InfoListItem } from '@brightlayer-ui/react-components';
import { useGetCountriesQuery, useGetAreaCodesQuery } from '@fiji/common/src/features/profile/profileApi';
import { NUMBER_REGEX } from '@fiji/common/src/constants';
const useStyles = makeStyles(() => ({
    muiDialogpaper: {
        '& .MuiPaper-root': {
            maxWidth: '450px',
        },
    },
    MuiDialogTitleRoot: {
        paddingTop: '32px',
    },
    dialogcontent: {
        minHeight: '334px',
    },
    MuiDialogActionsRoot: {
        padding: '24px',
        borderTop: '1px solid rgba(66, 78, 84, 0.12)',
    },
    sectionHeader: {
        width: '100%',
        marginBottom: 16,
    },
    divider: {
        width: 'calc(100% + 48px)',
        marginTop: '48px',
        marginLeft: '-24px',
        marginRight: '-24px',
    },
    topDivider: {
        marginTop: '48px',
    },
    formOverflow: {
        display: 'flex',
        flex: '1',
        overflow: 'auto',
        width: '100%',
    },
    infoListItem: {
        background: '#F7F8F8',
        paddingTop: '4px',
        paddingBottom: '4px',
        marginTop: '4px',
        marginBottom: '4px',
        borderRadius: '4px',
        height: 'auto',
        '& .MuiListItemText-root': {
            '& .MuiTypography-subtitle2 ': {
                whiteSpace: 'pre-wrap',
            },
        },
    },
    emptyState: {
        padding: '0 16px',
        '& .MuiTypography-h6 ': {
            padding: '0px 40px',
        },
    },
}));

/* The above code is a TypeScript React component called `CustomDetailsScreen`. It is a form component
that allows users to enter and update their account details, such as phone number, country, area
code, and two-factor authentication (MFA) settings. */
export const CustomDetailsScreen: React.FC<React.PropsWithChildren<AccountDetailsFormProps>> = (props) => {
    const { onDetailsChanged, initialDetails } = props;
    const [phoneNumber, setPhoneNumber] = useState<any>(initialDetails ? initialDetails.phoneNumber : '');
    const { data: countries } = useGetCountriesQuery<{ data: any }>();
    const { data: areaCodes } = useGetAreaCodesQuery<{ data: any }>();

    const [country, setCountry] = useState(initialDetails ? initialDetails.country : '');
    const [areaCode, setAreaCode] = useState(initialDetails ? initialDetails.areaCode : '');
    const [mfa, setMfa] = useState<any>(initialDetails ? initialDetails.mfa : true);
    const [secureYourAccountModal, setSecureYourAccountModal] = useState(false);
    const classes = useStyles();

    /* The above code is using the useEffect hook in a React component. It is performing validation
    checks on the values of the variables `country` and `phoneNumber`. If the `phoneNumber` is not
    empty and its length is not equal to 10, the `valid` variable is set to false. Then, it calls
    the `onDetailsChanged` function with the values of `country`, `phoneNumber`, `areaCode`, and
    `mfa`, along with the `valid` variable. This function is likely used to update the details of
    the component. The useEffect hook is triggered whenever any of the variables */
    useEffect(() => {
        // validation checks
        let valid = country !== '';
        if (phoneNumber !== '' && phoneNumber.length !== 10) {
            valid = false;
        }
        onDetailsChanged({ country, phoneNumber, areaCode, mfa }, valid);
    }, [country, phoneNumber, areaCode, mfa]); // Do NOT include onDetailsChanged in the dependencies array here or you will run into an infinite loop of updates

    useEffect(() => {
        if (countries) setCountry(countries?.data[0]?.name);
    }, [countries]);

    useEffect(() => {
        if (areaCodes) setAreaCode(areaCodes?.data[0]);
    }, [areaCodes]);
    /**
     * The function handleChangePhoneNumber updates the phone number state based on the input value,
     * with a validation check for length and format.
     * @param {any} e - The parameter "e" is an event object that is passed to the function when the
     * event (such as a change event) is triggered. It contains information about the event, such as
     * the target element that triggered the event and the value of the target element.
     * @returns The function `handleChangePhoneNumber` is returning `void`, which means it is not
     * returning any value.
     */
    const handleChangePhoneNumber = (e: any): void => {
        if (e.target.value.length && (e.target.value.length > 10 || !NUMBER_REGEX.test(e.target.value))) return;
        setPhoneNumber(e.target.value);
    };

    /**
     * The handleChangeCountry function updates the country state based on the value of the event
     * target.
     * @param {any} evt - The parameter `evt` is an event object that is passed to the
     * `handleChangeCountry` function. It represents the event that triggered the function, such as a
     * change event on an input element.
     */
    const handleChangeCountry = (evt: any): void => {
        setCountry(evt.target.value);
    };

    /**
     * The handleChangeAreaCode function updates the area code state based on the value of an event
     * target.
     * @param {any} evt - The parameter `evt` is an event object that is passed to the function when an
     * event occurs. It contains information about the event, such as the target element that triggered
     * the event and the value of the target element. In this case, `evt.target.value` refers to the
     * value of the target
     */
    const handleChangeAreaCode = (evt: any): void => {
        setAreaCode(evt.target.value);
    };

    /* The above code is defining a function called `handleChangeMFA` using the `useCallback` hook in a
    TypeScript React component. This function takes an event object as an argument and sets the
    value of `MFA` (presumably a state variable) to the checked value of the event target. */
    const handleChangeMFA = useCallback((evt: any): void => setMfa(evt.target.checked), []);

    return (
        <>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <FormControl variant={'filled'} fullWidth>
                            <InputLabel htmlFor={'Area Code'}>Country Code</InputLabel>
                            <Select
                                data-cy={'country-selector'}
                                fullWidth
                                labelId={'Area Code'}
                                inputProps={{
                                    name: 'Country Code',
                                }}
                                value={areaCode}
                                onChange={handleChangeAreaCode}
                            >
                                {areaCodes?.data?.map((data: any) => (
                                    <MenuItem key={data} value={data}>
                                        {data}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            id="phone-number"
                            fullWidth
                            type="text"
                            variant={'filled'}
                            label={'Phone Number (Optional)'}
                            placeholder={''}
                            value={phoneNumber}
                            onChange={handleChangePhoneNumber}
                        />
                    </Grid>
                </Grid>
                <FormControl variant="filled" fullWidth sx={{ mt: '32px' }}>
                    <InputLabel htmlFor={'country'}>Country</InputLabel>
                    <Select id="country" fullWidth labelId={'country'} value={country} onChange={handleChangeCountry}>
                        {countries?.data?.map((item: any) => (
                            <MenuItem key={item?.name} value={item?.name}>
                                {item?.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box
                    sx={{
                        mt: '32px',
                        mx: '0',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center', mx: 0 }}>
                        <Typography variant="body1" color={'primary'} sx={{ marginRight: 1, fontWeight: 600 }}>
                            Enable Two-factor Authentication
                        </Typography>
                        <Error
                            id="secure-modal"
                            sx={{ color: '#9CA5A9' }}
                            onClick={(e): void => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSecureYourAccountModal(true);
                            }}
                        />
                    </Grid>
                    <Switch checked={mfa} onChange={handleChangeMFA} />
                </Box>
            </Box>
            <Dialog
                open={secureYourAccountModal}
                onClose={(): void => setSecureYourAccountModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.muiDialogpaper}
            >
                <DialogTitle id="alert-dialog-title" className={classes.MuiDialogTitleRoot}>
                    <Typography variant={'h6'} className={classes.sectionHeader}>
                        {'Secure Your Account'}
                    </Typography>
                    <Divider className={clsx(classes.divider, classes.topDivider)} sx={{ mt: '40px !important' }} />
                </DialogTitle>
                <DialogContent className={classes.dialogcontent}>
                    <Box className={classes.formOverflow}>
                        <EmptyState
                            mt={1}
                            className={classes.emptyState}
                            icon={<Security fontSize={'inherit'} sx={{ color: Colors.blue[500] }} />}
                            title={'Secure Your Account With Two-Factor Authentication'}
                            description={
                                <>
                                    <Typography variant={'body1'} mb={1} textAlign={'justify'} px={3} mt={1}>
                                        {
                                            'Prevent unauthorized access for your account by adding an additional layer of security. When you sign in, two-factor authentication helps make sure your information remains safe, private and secure.'
                                        }
                                    </Typography>
                                    <InfoListItem
                                        className={classes.infoListItem}
                                        data-testid="infoListItem"
                                        data-cy={'list-content'}
                                        icon={<LockPerson sx={{ color: Colors.blue[500] }} fontSize={'large'} />}
                                        subtitle={
                                            'We’ll ask for your password and then a login code when we notice an unusual login request for your account.'
                                        }
                                        title={'Extra Protection'}
                                        avatar={false}
                                        iconAlign="center"
                                        hidePadding
                                    />
                                    <InfoListItem
                                        className={classes.infoListItem}
                                        data-testid="infoListItem"
                                        data-cy={'list-content'}
                                        icon={<Devices sx={{ color: Colors.blue[500] }} fontSize={'large'} />}
                                        subtitle={'A code will be sent to you via SMS Message or Email.'}
                                        title={'VIA SMS Message or Email '}
                                        avatar={false}
                                        iconAlign="center"
                                        hidePadding
                                    />
                                </>
                            }
                        />
                    </Box>
                </DialogContent>
                <DialogActions className={classes.MuiDialogActionsRoot}>
                    <Stack direction="row" justifyContent="end" sx={{ width: '100%' }}>
                        <Button variant={'outlined'} onClick={(): void => setSecureYourAccountModal(false)}>
                            Close
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
};
