import React, { useState, useEffect, useRef } from 'react';
import { Avatar, IconButton, Typography, useMediaQuery, TextField, Chip, Skeleton, Tooltip, Box } from '@mui/material';
import * as Colors from '@brightlayer-ui/colors';
import { ListItemTag, UserMenu, UserMenuItem } from '@brightlayer-ui/react-components';
import {
    ChevronRight,
    MoreVert,
    DeleteForever,
    Search,
    NotificationsActive,
    Warning,
    Check,
    CloudOff,
    NotificationsOff,
    MoveDown,
    TimerOutlined,
    CancelOutlined,
} from '@mui/icons-material';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import { Device } from '@brightlayer-ui/icons-mui';
import { useTheme } from '@mui/material/styles';

import { Devices } from '@fiji/common/src/types';
import { useStyles } from './styles';
import { MobileView } from './';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';

import CustomTable from '../../components/CustomTable';
import {
    useDebounce,
    useFilteredValues,
    useGetHierarchyHandlers,
    useIsMount,
    useRBAC,
    useSelectedIds,
} from '../../hooks';
import Hierarchy from '../../components/Hierarchy';

import MultiItemsDeleteModal from '../../components/MultiItemsDeleteModal';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import {
    useDeleteDeviceMutation,
    useGetDeviceStatusQuery,
    useGetDeviceTypesQuery,
    useGetPaginatedDeviceListQuery,
} from '@fiji/common/src/features/deviceManagement/deviceApi';
import { setAppHeaderTitle, setSelectedGroup, setToastifyContent } from '@fiji/common/src/features/common/commonSlice';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { moveGroupsDevicesModal, selectHierarchyData } from '@fiji/common/src/features/group/groupSlice';
import { toFirstLetterUpperCase } from '../../CommonUtils';
import BatteryPercentageIcon from '../../components/BatteryPercentageIcon';

/**
 * The DeleteModalRefType is a type that defines a function called modalHandler which takes a boolean
 * argument and does not return anything.
 * @property modalHandler - The `modalHandler` property is a function that takes a boolean argument and
 * does not return anything (`void`).
 */
type DeleteModalRefType = {
    modalHandler: (args0: boolean) => void;
};

export const DevicesList = (): JSX.Element => {
    const theme = useTheme();
    const navigate = useNavigate();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const currentOrg = useTypedSelector(selectedOrg);
    const selectedGroup = useTypedSelector((state) => state.common.groupId);

    const tableRef = useRef<any>(null);
    const deleteModalRef = useRef<DeleteModalRefType>(null);

    const [payload, setPayload] = useState<any>({ size: 10, page: 0, filters: {} });
    const [searchKey, setSearchKey] = useState<string | undefined>(undefined);
    const [filteredDevices, setFilteredDevices] = useState<Devices['data']['records']>([]);
    const [initiateApiCall, setInitiatedApiCall] = useState<boolean>(false);

    const rolePermissions = useTypedSelector(selectCurrentPermission);

    const { hasPermission } = useRBAC(rolePermissions);
    const isMount = useIsMount();
    const canViewDevice = hasPermission('view-device');
    const canCreateDevice = hasPermission('create-device');
    const canDeleteDevice = hasPermission('delete-device');
    const canAssignDevice = hasPermission('assign-device');

    const hierarchyData = useTypedSelector(selectHierarchyData);

    const { data: deviceStatusList }: any = useGetDeviceStatusQuery();
    const { data: deviceTypesList } = useGetDeviceTypesQuery<{ data: any }>();

    const [deleteDevice, { isLoading }] = useDeleteDeviceMutation();

    const [, debouncedValue] = useDebounce(undefined, undefined, searchKey);

    const [selectedIds, setSelectedIds] = useSelectedIds();
    const [deleteUserIds, setDeleteUserIds] = React.useState(selectedIds);
    const { selectedGroups, handleSelectGroup, groupSelectionHandler, removeUnwantedIds } = useGetHierarchyHandlers({});

    /* The below code is using the `useEffect` hook in a TypeScript React component. It is setting the
    `searchKey` property of the `payload` state variable to the `debouncedValue` whenever the
    `debouncedValue` changes. The `debouncedValue` is likely a value that has been debounced using a
    debounce function, which means it will only update after a certain delay or when a certain
    condition is met. */
    useEffect(() => {
        if (!isMount) {
            dispatch(setSelectedGroup(''));
            dispatch(setAppHeaderTitle(''));
            setPayload((prev: any) => ({
                size: prev?.size,
                page: 0,
                searchKey: debouncedValue,
            }));
        }
    }, [debouncedValue]);
    /* The below code is a useEffect hook in a TypeScript React component. It is triggered whenever the
    values of `selectedGroups` or `selectedGroup` change. */
    useEffect(() => {
        const filteredGroupIds = removeUnwantedIds(hierarchyData?.data?.data?.data, selectedGroups, []);
        const filterClone = payload?.filters ? JSON.parse(JSON.stringify(payload?.filters)) : {};
        if (!filteredGroupIds?.groups?.length) {
            delete filterClone['groupId'];
        } else {
            filterClone['groupId'] = filteredGroupIds?.groups;
        }
        setPayload((prev: any) => ({
            ...prev,
            page: 0,
            filters: filterClone,
        }));
    }, [selectedGroups]);

    useEffect(() => {
        if (selectedGroup && payload?.filters?.groupId) {
            setInitiatedApiCall(true);
        }
    }, [selectedGroup, payload]);

    /* The below code is a useEffect hook in a TypeScript React component. It is triggered whenever the
    value of the `selectedGroup` variable changes. */
    useEffect(() => {
        if (hierarchyData?.data) {
            handleSelectGroup([selectedGroup], hierarchyData?.data?.data?.data);
            setInitiatedApiCall(true);
        }
    }, [selectedGroup, hierarchyData?.data]);

    /* The below code is defining a function called `getActionCell` that takes a parameter `device`.
    Inside the function, it checks if a variable `canViewDevice` is truthy. If it is, it returns a
    JSX element `<ChevronRight>` with an `onClick` event handler that navigates to a specific device
    summary page when clicked. The element also has a `className` attribute set to
    `classes.hevronRight`. */
    const getActionCell = (device: any): JSX.Element =>
        canViewDevice && (
            <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                <ChevronRight
                    onClick={(): void =>
                        navigate(`/device/details/${device?.deviceId}`, { state: { manageDevicesTab: 'Devices' } })
                    }
                    sx={{ cursor: 'pointer' }}
                    className={classes.hevronRight}
                />
            </Stack>
        );

    /**
     * The function `getStatusColor` takes a key as input and returns a corresponding color based on
     * the key value.
     * @param {string} key - The `key` parameter in the `getStatusColor` function is a string that
     * represents the status key. It is used in a switch statement to determine the color code to be
     * returned based on the value of the key.
     * @returns The function `getStatusColor` returns a string representing a color code.
     */
    const getStatusColor = (key: string): string => {
        switch (key) {
            case 'Alarm': {
                return '#FFFFFF';
            }
            case 'Offline': {
                return '#EEF0F0';
            }
            case 'Warning': {
                return '#FFFFFF';
            }
            default: {
                return '#FFFFFF';
            }
        }
    };

    /**
     * The function `getStatusBackgroundColor` takes a key as input and returns a corresponding
     * background color based on the key value.
     * @param {string} key - The `key` parameter is a string that represents the status of something.
     * It can have one of the following values: 'Alarm', 'Offline', 'Warning', or any other value.
     * @returns The function `getStatusBackgroundColor` returns a string representing the background
     * color based on the input key.
     */
    const getStatusBackgroundColor = (key: string): string => {
        switch (key) {
            case 'Alarm': {
                return '#CA3C3D';
            }
            case 'Offline': {
                return '#69B1C3';
            }
            case 'Warning': {
                return '#F0AA1F';
            }
            default: {
                return '#39B620';
            }
        }
    };

    /**
     * The function `getStatusIconStyle` returns an object with a color property based on the value of
     * the `key` parameter.
     * @param {string} key - The `key` parameter is a string that represents a status key.
     */
    const getStatusIconStyle = (key: string): object => ({ color: getStatusColor(key) });

    /* The below code is a TypeScript function called `getStatusIcon` that takes in two parameters:
    `key` (a string) and `style` (an object). */
    const getStatusIcon = (key: string, style: any): JSX.Element => {
        switch (key) {
            case 'Alarm': {
                return <NotificationsActive sx={style} />;
            }
            case 'Offline': {
                return <CloudOff sx={style} />;
            }
            case 'Warning': {
                return <Warning sx={style} />;
            }
            default: {
                return <Check sx={{ ...style, backgroundColor: '#39B620' }} />;
            }
        }
    };

    /* The below code is defining a function called `getMenuItems` that returns an array of objects
    representing menu items. Each menu item object has properties such as `title`, `icon`, and
    `onClick`. The function checks certain conditions (`canCreateDevice`, `canDeleteDevice`,
    `canAssignDevice`) and adds menu items to the array based on those conditions. The menu items
    are then returned by the function. */

    const getMenuItems = (): UserMenuItem[] => {
        const items: any[] = [];
        if (canCreateDevice) {
            items.push({
                title: 'Add Device',
                icon: <Device className={classes.menuItem} />,
                onClick: (): void => navigate('/add-device', { state: { manageDevicesTab: 'Devices' } }),
            });
        }
        if (canDeleteDevice) {
            items.push({
                className: !selectedIds?.length ? classes.disabled : '',
                title: 'Delete Device(s)',
                icon: <DeleteForever className={classes.menuItem} />,
                onClick: () => deleteModalHandler(true),
            });
        }
        if (canAssignDevice) {
            items.push({
                className: !selectedIds?.length ? classes.disabled : '',
                title: 'Move Groups & Devices',
                icon: <MoveDown className={classes.menuItem} />,
                onClick: () =>
                    dispatch(
                        moveGroupsDevicesModal({
                            isOpen: true,
                            deviceId: selectedIds,
                            groupId: '',
                        })
                    ),
            });
        }
        return items;
    };

    /**
     * The function `getActionExtraOptions` returns a UserMenu component with menu items if there are
     * any available.
     * @returns The function `getActionExtraOptions` returns a JSX element if `getMenuItems()?.length`
     * is truthy. Otherwise, it does not return anything.
     */
    const getActionExtraOptions = (): any => {
        if (getMenuItems()?.length) {
            return (
                <UserMenu
                    id="users"
                    avatar={
                        <IconButton id="device-action-btn" size="large" sx={{ pr: 0 }}>
                            {' '}
                            <MoreVert />
                        </IconButton>
                    }
                    menuGroups={[
                        {
                            items: getMenuItems(),
                        },
                    ]}
                />
            );
        }
    };

    /**
     * The function `getAlarmCountColor` returns a color code based on the status provided.
     * @param {string} status - The `status` parameter is a string that represents the current status
     * of an alarm.
     * @returns The function `getAlarmCountColor` returns a string representing a color code.
     */
    const getAlarmCountColor = (status: string): string => {
        switch (status) {
            case 'Offline':
                return '#727E84';

            case 'Alarm':
                return '#CA3C3D';

            default:
                return '#727E84';
        }
    };

    /* The below code is defining a function called `getStatusCell` that takes in a parameter
    `statusData` of type `any`. Inside the function, it returns a JSX element that renders an
    `Avatar` component. The `Avatar` component has a background color determined by the
    `getStatusBackgroundColor` function, and it also renders an icon determined by the
    `getStatusIcon` function with a style determined by the `getStatusIconStyle` function. */
    const getStatusCell = (statusData: any): JSX.Element => (
        <Avatar sx={{ backgroundColor: getStatusBackgroundColor(statusData?.status) }}>
            {getStatusIcon(statusData?.status, getStatusIconStyle(statusData?.status))}
        </Avatar>
    );

    /* The below code is defining a function called `getDeviceNameCell` that takes a parameter `device`
    of type `any`. Inside the function, it returns a JSX element that consists of two `Typography`
    components. The first `Typography` component displays the `deviceName` property of the `device`
    object, or 'N/A' if it is undefined or null. The second `Typography` component displays the
    `serialNumber` property of the `device` object, or 'N/A' if it is undefined or null. */
    const getDeviceNameCell = (device: any): JSX.Element => (
        <>
            <Typography variant={'body2'} sx={{ fontWeight: '600 !important' }}>
                {device?.deviceName || 'N/A'}
            </Typography>
            <Typography variant={'body2'}>{device?.serialNumber || 'N/A'}</Typography>
        </>
    );

    /**
     * The function `getGroupNameCell` returns a JSX element that displays a tooltip with the group
     * path and a span with the group name.
     * @param {any} item - The `item` parameter is of type `any`, which means it can be any data type.
     * It is used as an input to generate a JSX element.
     */
    const getGroupNameCell = (item: any): JSX.Element => (
        <Tooltip title={item?.groupPath} arrow>
            <span>{`../${item?.groupName}`}</span>
        </Tooltip>
    );

    /**
     * The function returns a JSX element that renders a Select component with hierarchical options for
     * group headers.
     */
    const getGroupHeaderOptions = (): JSX.Element => (
        <Hierarchy
            isMultiSelect
            expandedNodes={[currentOrg?.id]}
            selectedNodes={selectedGroups}
            onChangeTreeItem={(value: any, key?: string, type?: string, isChecked?: boolean): void =>
                handleGroupSelect(value, key, type, isChecked)
            }
            hierarchyData={hierarchyData?.data?.data?.data}
            multiSelectionKey="children"
        />
    );

    /* The below code is defining a function called `getAlarmCountIcons` that takes a `status`
    parameter of type string and returns a JSX element. */
    const getAlarmCountIcons = (status: string): JSX.Element => {
        switch (status) {
            case 'Offline':
                return <NotificationsOff sx={{ color: getAlarmCountColor(status) }} />;
            case 'Alarm':
                return <NotificationsActive sx={{ color: getAlarmCountColor(status) }} />;
            default:
                return <NotificationsOutlinedIcon sx={{ color: getAlarmCountColor(status) }} />;
        }
    };

    /**
     * The function `getAlertCell` returns a JSX element that displays the alarm count and icons based
     * on the device's status.
     * @param {any} device - The `device` parameter is an object that represents a device. It may have
     * properties such as `status` and `alarmCount`.
     */
    const getAlertCell = (device: any): JSX.Element => (
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
            {getAlarmCountIcons(device?.status)}
            <Typography sx={{ color: getAlarmCountColor(device?.status) }} variant="body2">
                {device?.alarmCount || 0}
            </Typography>
        </Stack>
    );

    const getStatusStyles = (data: any): any => ({
        content: '" "',
        position: 'absolute',
        top: '0px',
        bottom: '-1px',
        left: '0px',
        width: '6px',
        zIndex: '100',
        backgroundColor: getStatusBackgroundColor(data?.status),
    });

    /* The below code is defining a function called `getDetailsCell` that takes an `item` parameter of
    type `any`. Inside the function, it returns a JSX element that renders a `ListItemTag`
    component. The `ListItemTag` component is passed several props including `label`, `color`, and
    `backgroundColor`. The values for these props are determined by calling various functions
    (`getStatusColor` and `getStatusBackgroundColor`) with the `item.status` value. The
    `item.status` value is also converted to uppercase using the `toUpperCase()` method before being
    passed as the `label` prop. */
    const getDetailsCell = (item: any): JSX.Element => {
        if (item?.status === 'Offline') {
            return (
                <ListItemTag
                    label={item?.status?.toUpperCase()}
                    color={`${getStatusColor(item?.status)}!important`}
                    backgroundColor={getStatusBackgroundColor(item?.status)}
                />
            );
        }
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                    style={{
                        width: '1rem',
                        height: '100%',
                        display: 'flex',
                        marginRight: '10px',
                    }}
                >
                    <CircularProgressbar
                        value={item?.percentLoad ?? 0}
                        strokeWidth={50}
                        counterClockwise
                        styles={buildStyles({
                            strokeLinecap: 'butt',
                        })}
                    />
                </Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: '400', color: '#727E84' }}>
                    {item?.percentLoad || 0}%
                </Typography>
                <BatteryPercentageIcon batteryLevel={item?.batteryPercentage} />
                <Typography sx={{ fontSize: '14px', fontWeight: '400', color: '#727E84' }}>
                    {item?.batteryPercentage}%
                </Typography>
                <TimerOutlined
                    sx={{
                        margin: '0 10px',
                        color: '#727E84',
                    }}
                />
                <Typography sx={{ fontSize: '14px', fontWeight: '400', color: '#727E84' }}>
                    {item?.batteryTimeRemaining} s
                </Typography>
            </Box>
        );
    };

    const columns: any[] = [
        {
            header: '',
            isSelectable: true,
            sx: getStatusStyles,
            width: '5%',
        },
        {
            header: 'Status',
            width: '16%',
            accessor: 'status',
            isSortable: true,
            isFilterable: true,
            filterOptions: [{ id: 'all', label: 'All' }].concat(
                deviceStatusList?.data?.map((item: any) => ({ id: item?.value, label: item?.label }))
            ),
            cell: getStatusCell,
            skeleton: (
                <Skeleton animation="wave" variant="circular">
                    <Avatar />
                </Skeleton>
            ),
        },
        {
            header: 'Device',
            accessor: 'deviceName',
            isSortable: true,
            isDebounce: true,
            width: '18%',
            isFilterable: true,
            cell: getDeviceNameCell,
            skeleton: (
                <>
                    <Skeleton animation="wave" variant="text"></Skeleton>
                    <Skeleton animation="wave" variant="text"></Skeleton>
                </>
            ),
        },

        {
            header: 'Type',
            accessor: 'deviceType',
            typeVariant: 'subtitle1',
            isFilterable: true,
            filterOptions: [
                { id: 'all', label: 'All' },
                ...(deviceTypesList?.data?.records.map((device: any) => ({
                    id: device?.id,
                    label: device?.name,
                })) ?? []),
            ],
            width: '10%',
        },
        {
            header: 'Group',
            width: '22%',
            isFilterable: true,
            isSortable: true,
            accessor: 'groupName',
            filterKey: 'groupId',
            cell: getGroupNameCell,
            typeVariant: 'body2',
            headerOptions: getGroupHeaderOptions,
            sx: (): any => ({ padding: '0' }),
        },
        {
            header: 'Alert',
            width: '6%',
            cell: getAlertCell,
        },
        {
            header: 'Details',
            width: '18%',
            cell: getDetailsCell,
        },
        {
            extraOptions: getActionExtraOptions,
            width: '5%',
            cell: getActionCell,
        },
    ];

    /* The above code is using the `useFilteredValues` hook to set the `tableFilters` state variable. The
`useFilteredValues` hook takes two parameters: `allFilters` and `triggeredFilters`. */
    const [tableFilters] = useFilteredValues({ allFilters: columns, triggeredFilters: payload?.filters });

    const {
        data: devicesData,
        isLoading: areDevicesLoading,
        isFetching: areDevicesFetching,
        isSuccess: deviceSuccess,
        isError: deviceError,
        refetch: refetchDevices,
    }: any = useGetPaginatedDeviceListQuery(
        { ...payload, filters: tableFilters },
        {
            refetchOnMountOrArgChange: true,
            skip: !initiateApiCall,
        }
    );

    useEffect(() => {
        if (devicesData) {
            tableRef.current.resetCheckboxes();
        }
    }, [devicesData]);

    /* The below code is using the useEffect hook in a React component. It is setting up a side effect
    that will be triggered whenever the value of the currentOrg variable changes. */
    useEffect(() => {
        if (currentOrg?.id && !isMount) {
            refetchDevices();
            tableRef?.current?.resetAllFiltersAndSort();
            setSearchKey(undefined);
            setPayload({ page: 0, size: 10, filters: {} });
        }
    }, [currentOrg]);

    /**
     * The function `handleGroupSelect` is used to handle the selection of a group in a React
     * component, updating the selected group state and performing additional actions based on the
     * selected group.
     * @param {any} nodeIds - The `nodeIds` parameter is an array that contains the selected group node
     * IDs.
     * @param {string} [key] - The `key` parameter is an optional string that represents a unique
     * identifier for the group.
     * @param {string} [type] - The `type` parameter is a string that represents the type of group
     * being selected.
     * @param {boolean} [isChecked] - The `isChecked` parameter is a boolean value that indicates
     * whether the group selection is checked or not. It is used to determine the state of the group
     * selection.
     */
    const handleGroupSelect = (nodeIds: any, key?: string, type?: string, isChecked?: boolean): void => {
        if (nodeIds !== selectedGroup || !nodeIds?.length) {
            dispatch(setSelectedGroup(''));
        }
        handleSelectGroup(nodeIds, hierarchyData?.data?.data?.data, type, isChecked, key);
    };

    /**
     * The function `handleFilterChange` updates the `payload` state based on the provided filters,
     * page, size, and sort data.
     * @param {any} filters - An object representing the filters applied to the data. It could contain
     * various properties and values depending on the specific filtering requirements.
     * @param {any} sortData - The `sortData` parameter is an object that contains information about
     * how the data should be sorted. It typically includes properties like `field` (the field to sort
     * by) and `direction` (the sorting direction, such as ascending or descending).
     */
    const handleFilterChange = (filters: any, sortData: any): void => {
        if (!isMount) {
            if (JSON.stringify(filters) !== JSON.stringify(payload?.filters)) {
                setPayload((prev: any) => ({
                    ...prev,
                    page: 0,
                    filters: prev?.filters?.groupId?.length ? { groupId: prev?.filters?.groupId, ...filters } : filters,
                }));
            }
            if (Object.keys(sortData)?.length) {
                setPayload((prev: any) => ({
                    ...prev,
                    page: 0,
                    sort: sortData,
                }));
            }
        }
    };

    /**
     * The function `handlePaginationChange` updates the `payload` state with the new `page` and `size`
     * values.
     * @param {number} page - The page parameter represents the current page number in the pagination. It
     * is used to determine which page of data to display.
     * @param {number} size - The `size` parameter represents the number of items to be displayed per page
     * in a paginated list.
     */
    const handlePaginationChange = (page: number, size: number): void => {
        if (page || size) setPayload((prev: any) => ({ ...prev, page: page, size: size }));
    };

    /**
     * The function `handleSearchChange` updates the search key based on the value of the input field.
     * @param {any} e - The parameter `e` is an event object that represents the event that triggered
     * the change. In this case, it is likely an event object from an input field's `onChange` event.
     */
    const handleSearchChange = (e: any): void => {
        setSearchKey(e.target.value);
    };

    /**
     * The function `deleteModalHandler` is used to handle the deletion of selected devices by
     * filtering out the devices with selected IDs and updating the state with the filtered devices.
     * @param {boolean} action - The `action` parameter is a boolean value that determines the action
     * to be performed. It is used to control the behavior of the `modalHandler` function.
     */
    const deleteModalHandler = (action: boolean): void => {
        deleteModalRef?.current?.modalHandler(action);
        const allDevicesClone = JSON.parse(JSON.stringify(devicesData));
        allDevicesClone.records = allDevicesClone.records.filter((device: any) =>
            selectedIds.includes(device?.deviceId)
        );
        setFilteredDevices(allDevicesClone?.records);
    };

    /**
     * The function "handleTableCheckboxes" sets the selected IDs based on the provided checkbox data.
     * @param {string[]} checkBoxData - An array of strings representing the selected checkbox values.
     */
    const handleTableCheckboxes = (checkBoxData: string[]): void => {
        setSelectedIds(checkBoxData);
    };

    /**
     * The function `handleChipDelete` is used to handle the deletion of a chip and perform related
     * actions such as resetting filters, updating the payload, and dispatching an action to set the
     * selected group.
     * @param {any} key - The `key` parameter is a variable that represents the key of the filter that
     * is being deleted. It is used to determine which filter to remove from the `payload` object and
     * to reset the corresponding filter in the `tableRef` component.
     */
    const handleChipDelete = (key: any): void => {
        groupSelectionHandler([]);
        tableRef?.current?.resetFilters(key === 'groupId' ? 'groupPath' : key, true);
        if (key === 'groupId') tableRef?.current?.dropDownHandler('groupName', 'close');
        const paginationPayloadClone = JSON.parse(JSON.stringify(payload));
        delete paginationPayloadClone?.filters[key];
        setPayload(paginationPayloadClone);
        dispatch(setSelectedGroup(''));
    };

    /**
     * The function `getFilterChipLabel` takes a string parameter `key` and returns a label for a
     * filter chip based on the value of `key`.
     * @param {string} key - The `key` parameter is a string that represents a filter key.
     * @returns The function `getFilterChipLabel` returns a string. If the `key` parameter is equal to
     * 'groupId', it returns the string 'Group'. Otherwise, it returns the `key` parameter with the
     * first character capitalized.
     */
    const getFilterChipLabel = (key: string): string => {
        if (key === 'groupId') {
            return 'Group';
        }
        return toFirstLetterUpperCase(key);
    };

    /**
     * The function returns an object with properties for a devices table, including the total number
     * of devices, the device records, and a loading indicator.
     */
    const getDevicesTableProps = (): any => ({
        total: devicesData?.total,
        data: devicesData?.records,
        isLoading:
            areDevicesLoading || areDevicesFetching || hierarchyData?.isLoading || (!deviceSuccess && !deviceError),
    });

    /**
     * The deleteHandler function deletes devices and displays a success message if the deletion is
     * successful.
     */
    const deleteHandler = async (): Promise<void> => {
        const { error }: any = await deleteDevice({ deviceIds: deleteUserIds });
        if (!error) {
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: `${deleteUserIds.length} Device(s) Successfully deleted!`,
                    duration: 3000,
                })
            );
            deleteModalRef?.current?.modalHandler(false);
            tableRef?.current?.resetCheckboxes();
        }
    };

    return (
        <React.Fragment>
            {sm ? (
                <>
                    <Stack direction={'row'} spacing={2} my={2} justifyContent={'space-between'} alignItems={'center'}>
                        <TextField
                            hiddenLabel
                            id="searchKey"
                            placeholder="Search..."
                            size="small"
                            onChange={handleSearchChange}
                            value={searchKey || ''}
                            InputProps={{
                                startAdornment: <Search id="search" sx={{ mr: '5px', color: '#727e84' }} />,
                                endAdornment: searchKey ? (
                                    <CancelOutlined
                                        sx={{ color: '#727e84', cursor: 'pointer' }}
                                        onClick={(): void => setSearchKey('')}
                                    />
                                ) : (
                                    ''
                                ),
                            }}
                        />

                        {payload?.filters && (
                            <Stack direction="row" spacing={1}>
                                {Object.keys(payload?.filters).map((key: string) => (
                                    <Chip
                                        key={`unique${key}`}
                                        label={getFilterChipLabel(key)}
                                        onDelete={(): void => {
                                            handleChipDelete(key);
                                        }}
                                    />
                                ))}
                            </Stack>
                        )}
                    </Stack>
                    <CustomTable
                        ref={tableRef}
                        isPagination
                        noDataFoundIcon={<Device fontSize={'inherit'} sx={{ color: Colors.gray[500] }} />}
                        keyToTraverse="deviceId"
                        headers={columns}
                        controlledPageSize={payload?.page}
                        handleCheckboxSelect={handleTableCheckboxes}
                        handleFilterChange={handleFilterChange}
                        handlePageChange={handlePaginationChange}
                        noDataFoundTitle={'No Devices found'}
                        {...getDevicesTableProps()}
                        {...(canCreateDevice && {
                            noDataFoundButtonAction: (): void =>
                                navigate('/add-device', { state: { manageDevicesTab: 'Devices' } }),
                            noDataFoundButtonText: 'Add Device',
                        })}
                    />
                </>
            ) : (
                <MobileView data={devicesData?.records} />
            )}
            <MultiItemsDeleteModal
                key="1dd3dw3ddf4d"
                ref={deleteModalRef}
                deleteButtonText="Delete Device(s)"
                filteredData={filteredDevices ?? []}
                isLoading={isLoading}
                deleteHandler={deleteHandler}
                selectedIds={selectedIds}
                keyToTraverse="deviceId"
                handleIdsToBeDeleted={setDeleteUserIds}
                nameKey="deviceName"
                modalTitle="Delete Device(s)"
                modalBodyText={
                    "Selecting 'continue' will permanently delete selected Device(s) and all associated information. This will not remove log entries or changes previously made to the system."
                }
                deleteModalDescription={
                    <>
                        {selectedIds?.length} {selectedIds?.length > 1 ? 'devices' : 'device'} will be deleted
                        permanently.
                    </>
                }
                deleteModalHeading={'Delete Device(s)?'}
            />
        </React.Fragment>
    );
};
