import React from 'react';
/* eslint-disable @typescript-eslint/no-misused-promises */
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    FormControl,
    Stack,
    Typography,
    Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useStyles } from './styles';
import { RefetchConfigOptions } from '@reduxjs/toolkit/dist/query/core/apiState';
import { AutoCompleteTags } from '../AddDevice';
import { useCreateTagMutation, useGetTagsQuery } from '@fiji/common/src/features/tagManagement/tagApi';
import { useEditDeviceMutation } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { setToastifyContent } from '@fiji/common/src/features/common/commonSlice';
import { useAppDispatch } from '@fiji/common/src/app/store';
import { TAGS_LIMIT, TAG_LIMIT } from '@fiji/common/src/constants';
import Loader from '../../components/Loader';

/**
 * The `EditDeviceTagsProps` type is used to define the props for a component that allows editing
 * device tags in a TypeScript React application.
 * @property {boolean} isOpen - A boolean value indicating whether the device tags editing modal is
 * open or not.
 * @property {any} deviceId - The `deviceId` property is the unique identifier of the device for which
 * the tags are being edited.
 * @property {any[]} tags - An array of tags associated with the device.
 * @property closeHandler - A function that will be called when the device tags editing is closed.
 * @property refetchDetailsCall - The `refetchDetailsCall` property is a function that is used to
 * trigger a refresh or refetch of the device details. It is typically called after making changes to
 * the device tags, so that the updated tags can be displayed.
 */
type EditDeviceTagsProps = {
    isOpen: boolean;
    deviceId: any;
    tags: any[];
    closeHandler: () => void;
    refetchDetailsCall: () => void;
};

export const EditDeviceTagsModal = (props: EditDeviceTagsProps): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();

    const {
        data: deviceTagsList,
        isLoading: isDeviceTagLoading,
        isFetching: isDeviceTagFetching,
        refetch: refetchDeviceTags,
    } = useGetTagsQuery<{
        data: any;
        isLoading: boolean;
        isFetching: boolean;
        refetch: RefetchConfigOptions;
    }>({ maxVar: '100', offset: '0' });

    const [createDeviceTag] = useCreateTagMutation();
    const [editDeviceDetail, { isSuccess }] = useEditDeviceMutation();

    const [tagIds, setTagIds] = React.useState<any[]>([]);
    const [inputTagValue, setInputTagValue] = React.useState('');

    /* The `React.useEffect` hook is used to perform side effects in a functional component. In this
    case, the effect is triggered when the `isSuccess` variable changes. */
    React.useEffect(() => {
        if (isSuccess) {
            props.refetchDetailsCall();
            props.closeHandler();
        }
    }, [isSuccess]);

    /* The `React.useEffect` hook is used to perform side effects in a functional component. In this
    case, the effect is triggered when the `props.tags` or `props.isOpen` variables change. */
    React.useEffect(() => {
        if (props?.tags?.length) {
            const selectedTags = deviceTagsList?.data?.records
                ?.map((tag: any) => props.tags.filter((item: any) => item.id === tag.id))
                ?.flat(deviceTagsList?.data?.records?.length)
                ?.map((item: any) => item.name);
            setTagIds(selectedTags);
        } else {
            setTagIds([]);
        }
    }, [props.tags, props.isOpen, isDeviceTagLoading]);

    /**
     * The function `selectedTagsHandler` sets the state variable `tagIds` to the selected values.
     * @param {any} e - The parameter `e` is an event object that represents the event that triggered
     * the selectedTagsHandler function. It can be used to access information about the event, such as
     * the target element or the event type.
     * @param {any} values - The `values` parameter is the array of selected tags values.
     */
    const selectedTagsHandler = (e: any, values: any): void => {
        setTagIds(values);
        setInputTagValue('');
    };

    /**
     * The function `handleKeyDown` is an asynchronous function that is triggered when the Enter key is
     * pressed, and it creates a new device tags with the name provided in the input field.
     * @param {any} e - The parameter `e` is an event object that represents the keydown event. It
     * contains information about the event, such as the key code and the target element.
     */
    let isEnteredTagValue = false;
    const handleKeyDown = async (e: any): Promise<void> => {
        if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
            isEnteredTagValue = true;
        } else if (e.code !== 'Enter' && e.code !== 'ArrowDown' && e.code !== 'ArrowUp') {
            isEnteredTagValue = false;
        }
        if (e.code === 'Enter') {
            const filterdTagIds =
                deviceTagsList?.data.records
                    .filter((device: any) => !tagIds?.includes(device.name))
                    .map((device: any) => device.name) ?? [];
            const value: string = e?.target?.value;
            if (filterdTagIds?.includes(value)) {
                const tagIdsClone = JSON.parse(JSON.stringify({ ids: tagIds }));
                tagIdsClone.ids.push(e.target.value);
                setTagIds(tagIdsClone?.ids);
            } else if (e?.target?.value?.length && !isEnteredTagValue) {
                const { error }: any = await createDeviceTag({ name: e.target.value });
                if (!error && e?.target?.value) {
                    const tagIdsClone = JSON.parse(JSON.stringify({ ids: tagIds }));
                    tagIdsClone.ids.push(e.target.value);
                    setTagIds(tagIdsClone?.ids);
                    await refetchDeviceTags();
                    setInputTagValue('');
                }
            }
        }
    };

    /**
     * The function `inputTagHandler` limits the length of the input value and updates the state with
     * the new value.
     * @param {any} e - The parameter `e` is an event object that is passed to the `inputTagHandler`
     * function. It is typically an event object that is triggered when an input tag's value changes.
     * @returns If the length of the value in the input tag is greater than the TAG_LIMIT, then nothing
     * is returned. Otherwise, the value of the input tag is set as the input tag value.
     */
    const inputTagHandler = (e: any): void => {
        if (e?.target?.value?.length > TAG_LIMIT) {
            return;
        }
        if (e) setInputTagValue(e?.target?.value);
    };

    /**
     * The function `handleTagDelete` is used to remove a tags from an array of tags IDs.
     * @param {any} data - The `data` parameter is of type `any`, which means it can accept any data
     * type. It is not clear from the code snippet what the purpose of the `data` parameter is or how
     * it is used within the function.
     * @param {number} index - The `index` parameter represents the index of the element in the
     * `tagIds` array that needs to be deleted.
     */
    const handleTagDelete = (data: any, index: number): void => {
        const tagIdsClone = JSON.parse(JSON.stringify({ ids: tagIds }));

        tagIdsClone.ids.splice(index, 1);
        setTagIds(tagIdsClone?.ids);
    };

    /**
     * The submitHandler function filters and maps an array of device tags, and then calls the
     * editDeviceDetail function with the filtered and mapped data.
     */
    const submitHandler = async (): Promise<void> => {
        const tagsPayload = deviceTagsList?.data?.records
            ?.filter((tags: any) => tagIds?.includes(tags?.name))
            .map((tags: any) => tags.id);
        const { error }: any = await editDeviceDetail({ payload: { tagIds: tagsPayload }, id: props.deviceId });
        if (!error) {
            dispatch(setToastifyContent({ isOpen: true, message: 'Changes Saved Successfully' }));
            setTagIds([]);
        }
    };

    return (
        <Dialog
            open={props?.isOpen}
            onClose={props?.closeHandler}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.rootMuiDialogpaper}
        >
            {isDeviceTagLoading ? (
                <>
                    <DialogContent sx={{ height: '225px' }}>
                        <Loader size={60} />
                    </DialogContent>
                </>
            ) : (
                <>
                    <DialogTitle id="alert-dialog-title" className={classes.dialogTitleRoot}>
                        <Typography variant={'h6'}>Edit Tag</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <FormControl variant={'filled'} required fullWidth>
                            <AutoCompleteTags
                                value={tagIds?.length ? tagIds : []}
                                inputValue={inputTagValue ?? ''}
                                onInputChange={inputTagHandler}
                                disabled={isDeviceTagFetching || isDeviceTagLoading}
                                onChange={selectedTagsHandler}
                                options={
                                    deviceTagsList?.data.records
                                        .filter((device: any) => !tagIds?.includes(device.name))
                                        .map((device: any) => device.name) ?? []
                                }
                                handleTagDelete={handleTagDelete}
                                onKeyDown={handleKeyDown}
                            />
                        </FormControl>
                        <Box className="modal-space-between">
                            <Typography
                                variant="body2"
                                sx={{ paddingLeft: '16px' }}
                                className="custom-add-device-field-lengths-style"
                            >
                                {`${TAGS_LIMIT} tags maximum`}
                            </Typography>
                            <Typography variant="body2" className="custom-add-device-field-lengths-style">
                                {`${inputTagValue?.length || 0}/${TAG_LIMIT}`}
                            </Typography>
                        </Box>
                    </DialogContent>
                    <DialogActions className={classes.MuiDialogActionsRoot}>
                        <Stack direction={'row'} spacing={2} justifyContent={'end'}>
                            <Button
                                variant={'outlined'}
                                onClick={(): void => {
                                    props.closeHandler();
                                    setTagIds([]);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={!tagIds?.length && !props?.tags?.length}
                                variant={'contained'}
                                onClick={submitHandler}
                            >
                                Save
                            </Button>
                        </Stack>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};
