import { IconButton, Paper, Typography, Stack } from '@mui/material';
import React, { useState, useEffect } from 'react';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import CustomTable from '../../components/CustomTable';
import { useGetGroupsListQuery } from '@fiji/common/src/features/group/groupApi';
import { useNavigate, useParams } from 'react-router-dom';
import { Device } from '@brightlayer-ui/icons-mui';
import { addGroupModal, selectHierarchyData } from '@fiji/common/src/features/group/groupSlice';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { getEventColor, getPriorityEvent, getTimelineDetailsStyle } from '../../CommonUtils';
import {
    NotificationsActive,
    Check,
    CloudOff,
    Warning,
    Error,
    ChevronRight,
    Workspaces,
    NotificationsNoneOutlined,
    WarningAmberOutlined,
    ErrorOutlineOutlined,
} from '@mui/icons-material';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useRBAC } from '../../hooks';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';

/* The `getTimelineEventIcon` function is a helper function that returns an icon component based on the
provided `key` and `style` parameters. It is used to determine the appropriate icon to display for
different types of timeline events. */
const getTimelineEventIcon = (key: string, style: any, value?: any): JSX.Element => {
    switch (key) {
        case 'alarms': {
            return value === 0 ? (
                <NotificationsNoneOutlined sx={{ fontSize: '40px', color: '#727E84' }} />
            ) : (
                <NotificationsActive sx={style} />
            );
        }
        case 'offlineDevices': {
            return value === 0 ? <CloudOff sx={{ fontSize: '40px', color: '#727E84' }} /> : <CloudOff sx={style} />;
        }
        case 'warnings': {
            return value === 0 ? (
                <WarningAmberOutlined sx={{ fontSize: '40px', color: '#727E84' }} />
            ) : (
                <Warning sx={style} />
            );
        }
        case 'information': {
            return value === 0 ? (
                <ErrorOutlineOutlined sx={{ fontSize: '40px', color: '#727E84' }} />
            ) : (
                <Error sx={style} />
            );
        }
        default: {
            return <Check sx={{ ...style, backgroundColor: '#39B620' }} />;
        }
    }
};

export const AllGroupScreen = (): JSX.Element => {
    const { groupId } = useParams();
    const dispatch = useAppDispatch();
    const currentOrg = useTypedSelector(selectedOrg);
    const {
        data: groupsHierarchy,
        isLoading: isLoadingGroups,
        isFetching: isFetchingGroups,
    }: any = useGetGroupsListQuery(groupId, { skip: !currentOrg?.id || !groupId });

    const hierarchyData = useTypedSelector(selectHierarchyData);

    const [sortedHierarchyData, setSortedHierarchyData] = useState();
    const tableRef = React.useRef<any>();
    const navigate = useNavigate();
    const [payload, setPayload] = useState<any>({});

    const rolePermissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(rolePermissions);
    const canCreateGroup = hasPermission('create-group');

    /* The `useEffect` hook is used to perform side effects in a React component. In this case, the
    effect is triggered whenever the `payload` or `groupsHierarchy` variables change. */
    useEffect(() => {
        if (payload?.sort && payload?.sort?.key) {
            let sortedData = JSON.parse(
                JSON.stringify(
                    !groupId ? hierarchyData?.data?.data?.data?.[0]?.children : groupsHierarchy?.data?.[0]?.children
                )
            );
            sortedData = sortedData?.sort((a: any, b: any) => getFilters(a, b));
            setSortedHierarchyData(sortedData);
        }
    }, [payload, groupsHierarchy, hierarchyData]);

    const getStats = (a: any, b: any): any => {
        const priorityOrder =
            payload?.sort?.sortType === 'ASC'
                ? ['alarms', 'warnings', 'information']
                : ['information', 'warnings', 'alarms'];

        // Compare the stats properties based on priority
        for (const stat of priorityOrder) {
            const compare = b.stats[stat] - a.stats[stat];
            if (compare !== 0) {
                return compare;
            }
        }

        // If all stats are equal, compare the names
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
    };

    const getFilters = (a: any, b: any): any => {
        switch (payload?.sort?.key) {
            case 'name': {
                return payload?.sort?.sortType === 'ASC' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
            }
            case 'devices': {
                return payload?.sort?.sortType === 'ASC'
                    ? a.devices.length - b.devices.length
                    : b.devices.length - a.devices.length;
            }
            case 'offlineDevices': {
                return payload?.sort?.sortType === 'ASC'
                    ? a.stats.offlineDevices - b.stats.offlineDevices
                    : b.stats.offlineDevices - a.stats.offlineDevices;
            }
            case 'status':
            case 'stats': {
                return getStats(a, b);
            }
            default: {
                return '';
            }
        }
    };

    /**
     * The function returns a JSX element containing an IconButton component with a ChevronRight icon,
     * which triggers a navigation action when clicked.
     * @param {any} row - The `row` parameter is of type `any`, which means it can be any type of
     * value. It is used as an argument in the `getActionCell` function.
     */
    const getActionCell = (row: any): JSX.Element => (
        <IconButton
            onClick={(): void => navigate(`/groups/summary/${row.id}`, { state: { manageDevicesTab: 'Summary' } })}
        >
            <ChevronRight />
        </IconButton>
    );

    /**
     * The function returns a JSX element that displays the number of children in a row, along with a
     * folder icon.
     * @param {any} row - The `row` parameter is an object that represents a row of data. It is of type
     * `any`, which means it can be any type of object.
     */
    const getSubGroupCell = (row: any): JSX.Element => (
        <Typography className="counters">
            <FolderOpenIcon className="counter-icon" />
            {row?.children?.length}
        </Typography>
    );

    /**
     * The function `getDevicesCell` returns a JSX element that displays the number of devices in a
     * row.
     * @param {any} row - The `row` parameter is an object that represents a row of data. It likely
     * contains information about a particular item or entity in a table or list.
     */
    const getDevicesCell = (row: any): JSX.Element => (
        <Typography className="counters">
            <Device className="counter-icon" />
            {row?.devices?.length}
        </Typography>
    );

    /**
     * The function `getOfflineCountCell` returns a JSX element displaying the number of offline
     * devices if it exists in the given row object.
     * @param {any} row - The `row` parameter is an object that represents a row of data. It likely
     * contains information about a device or some other entity.
     */
    const getOfflineCountCell = (row: any): JSX.Element | false =>
        Boolean(row?.stats?.offlineDevices) && (
            <Typography className="counters" style={{ color: getEventColor('offlineDevices') }}>
                <CloudOff className="counter-icon" />
                {row?.stats?.offlineDevices}
            </Typography>
        );

    /* The below code is defining a function called `getAlertsCountCell` that takes a `row` object as a
    parameter and returns a JSX element. */
    const getAlertsCountCell = (row: any): JSX.Element => (
        <Stack direction={'row'}>
            {Boolean(row?.stats?.alarms) && (
                <Typography className="counters multiple-counters" style={{ color: getEventColor('alarms') }}>
                    <NotificationsActive className="counter-icon" color="inherit" />
                    {row?.stats?.alarms}
                </Typography>
            )}
            {Boolean(row?.stats?.warnings) && (
                <Typography className="counters multiple-counters" style={{ color: getEventColor('warnings') }}>
                    <Warning className="counter-icon" color="inherit" />
                    {row?.stats?.warnings}
                </Typography>
            )}
            {Boolean(row?.stats?.information) && (
                <Typography className="counters multiple-counters" style={{ color: getEventColor('information') }}>
                    <Error className="counter-icon" color="inherit" />
                    {row?.stats?.information}
                </Typography>
            )}
        </Stack>
    );

    /* The below code is defining a function called `getStatusCell` that takes a parameter `row` of
    type `any`. */
    const getStatusCellStyles = (row: any): any => {
        const priorityEvent = getPriorityEvent(row);
        const sx: any = {
            content: '" "',
            position: 'absolute',
            top: '0px',
            bottom: '-1px',
            left: '0px',
            width: '6px',
            zIndex: '100',
        };
        if (priorityEvent) {
            sx['backgroundColor'] = getPriorityEvent(priorityEvent);
        }
        return sx;
    };

    const getStatusCell = (row: any): JSX.Element => {
        const priorityEvent = getPriorityEvent(row);
        return priorityEvent ? (
            getTimelineEventIcon(priorityEvent, getTimelineDetailsStyle(priorityEvent))
        ) : (
            <Workspaces sx={{ color: '#727E84', height: '40px', width: '40px' }} />
        );
    };

    /**
     * The function `handleFilterChange` sets the payload with the sorted data if it exists.
     * @param {any} filters - The `filters` parameter is an object that contains the filter criteria
     * for the data. It can include properties such as `name`, `category`, `price`, etc.
     * @param {any} page - The `page` parameter is used to determine the current page number in a
     * paginated data set. It is typically an integer value representing the page number.
     * @param {any} size - The "size" parameter represents the number of items to be displayed per page
     * in a paginated list or table.
     * @param {any} sortedData - The `sortedData` parameter is an object that represents the sorting
     * criteria for the data. It typically contains information such as the field to sort by and the
     * sort order (ascending or descending).
     */
    const handleFilterChange = (filters: any, sortedData: any): void => {
        if (Object.keys(sortedData)?.length) {
            setPayload(() => ({
                sort: sortedData,
            }));
        }
    };
    const handlePaginationChange = (page: number, size: number): void => {
        if (page || size) setPayload((prev: any) => ({ ...prev, page: page, size: size }));
    };

    const columns = React.useMemo(
        () => [
            {
                header: 'Status',
                cell: getStatusCell,
                sx: getStatusCellStyles,
                accessor: 'status',
                isSortable: true,
            },
            {
                header: 'Group',
                isSortable: true,
                accessor: 'name',
            },
            {
                header: 'Sub Groups',
                cell: getSubGroupCell,
            },
            {
                header: 'Devices',
                cell: getDevicesCell,
                isSortable: true,
                accessor: 'devices',
            },
            {
                header: 'Offline Devices',
                cell: getOfflineCountCell,
                isSortable: true,
                accessor: 'offlineDevices',
            },
            {
                header: 'Alerts',
                cell: getAlertsCountCell,
                accessor: 'stats',
                isSortable: true,
            },
            {
                width: '5%',
                cell: getActionCell,
            },
        ],
        []
    );

    /**
     * The function `handleOpenAddGroupModal` dispatches an action to open a modal for adding a group,
     * with an optional groupId parameter.
     */
    const handleOpenAddGroupModal = (): any => dispatch(addGroupModal({ isOpen: true, groupId: groupId ?? '' }));

    return (
        <>
            <Paper sx={{ padding: '16px', boxShadow: 'none' }}>
                <CustomTable
                    total={0}
                    ref={tableRef}
                    data={
                        sortedHierarchyData ?? !groupId
                            ? hierarchyData?.data?.data?.data?.[0]?.children
                            : groupsHierarchy?.data?.[0]?.children
                    }
                    headers={columns}
                    isLoading={isLoadingGroups || isFetchingGroups || hierarchyData?.isLoading}
                    keyToTraverse="id"
                    controlledPageSize={payload?.page}
                    handleFilterChange={handleFilterChange}
                    handlePageChange={handlePaginationChange}
                    isPagination
                    noDataFoundButtonText="Add a Group"
                    noDataFoundIcon={<Workspaces className="my-svg-icon" />}
                    noDataFoundTitle="No Child Groups"
                    noDataFoundDescription="This group has no groups"
                    {...(canCreateGroup && { noDataFoundButtonAction: handleOpenAddGroupModal })}
                />
            </Paper>
        </>
    );
};
