import React from 'react';
import { Box, Checkbox, DialogContent, DialogContentText, Stack, Typography } from '@mui/material';
import { Ups } from '@brightlayer-ui/icons-mui';
import { useGetDeviceTypesQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';
import Loader from '../../components/Loader';
import { EmptyState } from '@brightlayer-ui/react-components';

export const SelectDeviceType = React.memo((props: any): JSX.Element => {
    const { data: deviceTypes, isLoading, isFetching }: any = useGetDeviceTypesQuery();

    /**
     * The function `deviceTypeSelectionHandler` handles the selection and deselection of device types
     * and updates the payload accordingly.
     * @param {any} e - The parameter `e` is an event object that represents the event that triggered
     * the deviceTypeSelectionHandler function. It could be an event like a click or change event.
     * @param {string} id - The `id` parameter is a string that represents the identifier of a device
     * type.
     */
    const deviceTypeSelectionHandler = (e: any, id: string): void => {
        const payloadClone = JSON.parse(JSON.stringify(props?.payload));
        const uncheckedIndex = payloadClone?.deviceTypeIds?.indexOf(id);
        if (e?.target?.checked) {
            payloadClone?.deviceTypeIds?.push(id);
        } else {
            payloadClone?.deviceTypeIds?.splice(uncheckedIndex, 1);
        }

        props.setPayload(payloadClone);
    };

    const getDeviceTypeList = (): JSX.Element =>
        deviceTypes?.data?.records?.length ? (
            deviceTypes?.data?.records?.map(
                (deviceType: any): JSX.Element => (
                    <Box
                        key={deviceType?.id}
                        className="modal-space-between"
                        sx={{ borderBottom: '1px solid rgba(66, 78, 84, 0.12)' }}
                    >
                        <Box className="modal-align-center" sx={{ padding: '16px' }}>
                            <Ups className="modal-custom-icon" />
                            <Stack sx={{ paddingLeft: '16px' }}>
                                <Typography variant="body1" className="modal-grey-header">
                                    {deviceType?.name}
                                </Typography>
                            </Stack>
                        </Box>
                        <Checkbox
                            checked={props?.payload?.deviceTypeIds?.includes(deviceType?.id)}
                            onChange={(e: any): void => deviceTypeSelectionHandler(e, deviceType?.id)}
                            sx={{ marginRight: '16px' }}
                        />
                    </Box>
                )
            )
        ) : (
            <EmptyState icon={<Ups fontSize={'inherit'} className="my-svg-icon" />} title={'No Device Types'} />
        );

    /**
     * The function `handleSelectAll` toggles the selection of all device types by either deselecting
     * all if all are currently selected, or selecting all if not all are currently selected.
     */
    const handleSelectAll = (): void => {
        if (props?.payload?.deviceTypeIds?.length === deviceTypes?.data?.records?.length) {
            props?.setPayload((prev: any) => ({ ...prev, deviceTypeIds: [] }));
        } else {
            props?.setPayload((prev: any) => ({
                ...prev,
                deviceTypeIds: deviceTypes?.data?.records?.map((item: any) => item?.id),
            }));
        }
    };

    return (
        <DialogContent sx={{ padding: '0', marginTop: '0' }}>
            <DialogContentText id="alert-dialog-slide-description">
                <Box
                    sx={{
                        borderTop: '1px solid rgba(66, 78, 84, 0.12)',
                        borderBottom: '1px solid rgba(66, 78, 84, 0.12)',
                    }}
                    className="modal-space-between"
                >
                    <Typography variant="body2" className="modal-blue-header">
                        All Device Types
                    </Typography>
                    <Checkbox
                        checked={
                            Boolean(props?.payload?.deviceTypeIds?.length) &&
                            props?.payload?.deviceTypeIds?.length === deviceTypes?.data?.records?.length
                        }
                        indeterminate={
                            Boolean(props?.payload?.deviceTypeIds?.length) &&
                            props?.payload?.deviceTypeIds?.length < deviceTypes?.data?.records?.length
                        }
                        onChange={handleSelectAll}
                        sx={{ marginRight: '16px' }}
                    />
                </Box>
                {isLoading || isFetching ? <Loader /> : getDeviceTypeList()}
            </DialogContentText>
        </DialogContent>
    );
});
