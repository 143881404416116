export const getGroupLabel = (source: any, groupId: any): string | null => {
    let result = null;
    source?.forEach((item: any) => {
        if (item.id === groupId) {
            result = item.name;
        } else if (item.children) {
            const label = getGroupLabel(item.children, groupId);
            if (label) {
                result = label;
            }
        }
    });
    return result;
};

export const getSelectedGroupLabel = (selectedGroup: any, groupsHierarchy: any): string => {
    if (selectedGroup === groupsHierarchy?.data?.[0]?.id && groupsHierarchy?.data[0]?.defaultGroup === true) {
        return `${groupsHierarchy?.data?.[0]?.name} (Organization Root)`;
    }
    return getGroupLabel(groupsHierarchy?.data, selectedGroup) ?? '';
};
