import React from 'react';

import { RootState, useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { toggleLoader } from '@fiji/common/src/features/common/commonSlice';
import { useNavigate } from 'react-router-dom';

type ReturnProps = {
    isLoading: boolean;
    togglePageLoader: (arg0: boolean) => void;
    refreshRouterState: (arg0: string) => void;
};

export const useCommonActions = (): ReturnProps => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const refreshRouterState = (url: string): void => {
        navigate(url);
    };
    const isLoading = useTypedSelector((state: RootState) => state?.common?.isLoader);

    const togglePageLoader = React.useCallback(
        (action: boolean): void => {
            dispatch(toggleLoader(action));
        },
        [dispatch]
    );

    return {
        togglePageLoader,
        isLoading,
        refreshRouterState,
    };
};
