import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
import { setAuthModal, setToastifyContent, setNotFound } from '../../features/common/commonSlice';
import { config } from '../../app-config';
import { setHierarchyData } from '../../features/group/groupSlice';

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: config.apiUrl,
    credentials: 'include',
    prepareHeaders: (headers: any, { getState }) => {
        const token = (getState() as RootState)?.auth?.token;

        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

const baseQueryWithInterceptor: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args: any,
    api: any,
    extraOptions: any
) => {
    if (extraOptions?.manualCaching) {
        api.dispatch(setHierarchyData({ isLoading: true, data: undefined }));
    }
    const result: any = await baseQuery(args, api, extraOptions);
    if (extraOptions?.manualCaching) {
        api.dispatch(setHierarchyData({ isLoading: false, data: JSON.parse(JSON.stringify(result)) }));
    }
    if (result?.error?.status === 401) {
        api.dispatch(setAuthModal(true));
    } else if (result?.error?.status === 500 || result?.error?.status === 403) {
        api.dispatch(
            setToastifyContent({
                isOpen: true,
                message: result?.error?.data?.errorMessage,
                duration: 3000,
                // isUnauthorized: result?.error?.status === 401 ? true : false,
            })
        );
    } else if (result?.error?.status === 400 && !args.url.includes('signup') && !extraOptions?.noToast) {
        api.dispatch(
            setToastifyContent({
                isOpen: true,
                message: result?.error?.data.errorMessage,
                duration: 3000,
            })
        );
    } else if (result?.error?.status === 'FETCH_ERROR') {
        api.dispatch(
            setToastifyContent({
                isOpen: true,
                message: [result?.error.error],
                duration: 3000,
            })
        );
    } else if (result?.error?.status === 404) {
        api.dispatch(setNotFound(true));
    }
    return result;
};
//
/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
    reducerPath: 'api',
    tagTypes: [
        'AssignedUsers',
        'Orgs',
        'Profile',
        'GroupById',
        'UserById',
        'DeviceList',
        'DeviceDetails',
        'Settings',
        'Notifications',
        'CustomNotifications',
        'GroupsHierarchy',
        'Automations',
        'AllUsers',
        'Timeline',
        'Loads',
        'LoadById',
        'NFCConfigurationList',
        'GroupsHierarchyList',
        'Documents',
    ],
    /**
     * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
     */
    baseQuery: baseQueryWithInterceptor,
    /**
     * This api has endpoints injected in adjacent files,
     * which is why no endpoints are shown below.
     * If you want all endpoints defined in the same file, they could be included here instead
     */
    endpoints: () => ({}),
});
