import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import * as Colors from '@brightlayer-ui/colors';

export const useStyles = makeStyles((theme: Theme) => ({
    ContainerWrapper: {
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    contentBody: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    continueButton: {
        background: Colors.blue[600],
        '&:hover': {
            backgroundColor: Colors.blue[400],
        },
    },
    deleteButton: {
        background: Colors.red[600],
        '&:hover': {
            backgroundColor: Colors.red[400],
        },
        '&.Mui-disabled': {
            backgroundColor: '#F9E8E8',
            color: '#E59E9E',
        },
    },
    infoListItem: {
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            height: 'auto',
            padding: '16px',
        },
        '& .BluiInfoListItem-rightComponent': {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                margin: 0,
            },
        },
    },
    formControl: {
        minWidth: 160,
        marginBottom: theme.spacing(0),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    multiSelect: {
        padding: '9px 16px',
        background: Colors.white[200],
        borderRadius: '4px 4px 0px 0px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        '& .MuiSelect-select': {
            padding: '0px',
        },
    },
    helperText: {
        float: 'right',
    },
    emptyState: {
        justifyContent: 'flex-start',
        '& .BluiEmptyState-icon': {
            marginBottom: theme.spacing(0),
            color: '#D5D8DA',
            '& .MuiAvatar-root': {
                width: '96px',
                height: '96px',
            },
        },
        '& .BluiEmptyState-actions': {
            marginBottom: theme.spacing(1),
        },
    },
    muiDialogpaper: {
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(100vh - 56px)',
        },
        '& .MuiPaper-root': {
            maxWidth: '450px',
            width: '450px',
            height: 'auto',
            maxHeight: '621px',
            overflowY: 'hidden',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                margin: '0px',
                maxHeight: 'none',
                width: '100%',
                borderRadius: 0,
                height: 'auto',
            },
        },
    },
    MuiDialogTitleRoot: {
        paddingTop: '32px',
    },
    dialogcontent: {
        minHeight: '334px',
    },
    MuiDialogActionsRoot: {
        padding: '24px',
        borderTop: '1px solid rgba(66, 78, 84, 0.12)',
    },
    sectionHeader: {
        width: '100%',
        marginBottom: 16,
    },
    newPasswordInputField: {
        marginTop: '32px',
        marginBottom: '8px',
    },
    formOverflow: {
        display: 'flex',
        flex: '1',
        overflow: 'auto',
        width: '100%',
    },
    phoneNumberUpdate: {
        display: 'block',
        width: '100%',
    },

    visibilityToggle: {
        height: 36,
        width: 36,
    },
    toolbarGutters: {
        padding: '0 16px',
    },
    divider: {
        width: 'calc(100% + 48px)',
        marginTop: '48px',
        marginLeft: '-24px',
        marginRight: '-24px',
    },
    topDivider: {
        marginTop: '40px',
        marginBottom: '16px',
    },
    verifyPhoneNumberWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    verifyPhoneNumberInfo: {
        textAlign: 'center',
        marginTop: '72px',
    },
    verifyPhoneNumber: {
        '& .MuiInputBase-input': {
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            fontWeight: 600,
            fontSize: '20px',
            color: '#727E84',
        },
    },
    submitButtonContainer: {
        marginTop: 'auto',
    },
    submitButton: {
        width: '100%',
    },
    disabled: {
        opacity: '0.5',
        pointerEvents: 'none',
        cursor: 'not-allowed',
    },
    deleteContainer: {
        justifyContent: 'right',
        marginBottom: '16px',
        marginTop: '26px',
        marginRight: '16px',
    },
    deleteButtonContainer: {
        color: Colors.red[500],
        border: `1px solid ${Colors.red[500]}`,
    },
}));
