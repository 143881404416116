import React, { useCallback, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import OctaConfig from './OctaConfig';
import { RequiredAuth } from './components/SecureAuth';
import CustomLoginComponent from './components/CustomLogin';
import { SelfRegistrationPager } from '@brightlayer-ui/react-auth-workflow/dist/screens/SelfRegistrationPager';
import AuthUIConfiguration from './contexts/authUIContextProvider';
import { Button } from '@mui/material';
import { ErrorPage } from './pages/Public/ErrorPage';
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from 'notistack';
import { useTypedSelector } from '@fiji/common/src/app/store';
import NotificationWithIcon from './components/NotificationWithIcon';
import { QrDetails } from './pages/QrDetails';
import AuthenticationModal from './components/AuthenticationModal';
import englishTranslations from '@fiji/common/src/translations/english';

import { i18n } from '@brightlayer-ui/react-auth-workflow';
import { AdminApp } from './AdminPortal/AdminApp';
import { CustomLoginCallback } from './components/CustomLoginCallback';
import { IdleLogoutHandler } from './components/IdleLogoutHandler';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(
    '316081936b3b35aad069b495ae2b1a93Tz04ODk2OSxFPTE3NDU0MzYwMjgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);
i18n.addResourceBundle('en', 'app', englishTranslations.translation);
i18n.addResourceBundle('en', 'blui', englishTranslations.translation.BLUI, true, true);

const oktaAuth = new OktaAuth(OctaConfig);

declare module 'notistack' {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface VariantOverrides {
        notificationWithIcon: true;
    }
}

/* The code defines a functional component named `App` that represents the main application component
in a TypeScript React application. */
export const App = (): JSX.Element => {
    const toastData = useTypedSelector((state: any) => state.common.toast);
    const isAdmin = false;

    const [isIdleTimeout, setIsIdleTimeout] = useState(false);
    useEffect(() => {
        if (isIdleTimeout) oktaAuth.signOut() as any;
    }, [isIdleTimeout]);

    /**
     * The function `triggerLogin` redirects the user to the login page.
     */
    const triggerLogin = (): void => {
        window.location.replace('/login');
    };

    /* The `React.useEffect` hook is used to perform side effects in a functional component. In this
    case, the effect is triggered whenever the `toastData` variable changes. */
    React.useEffect(() => {
        if (toastData?.message && toastData?.message?.length) {
            if (Array.isArray(toastData?.message)) {
                toastData?.message?.forEach((message: string) => {
                    enqueueSnackbar(message, toastData?.extraArgs);
                });
            } else {
                enqueueSnackbar(toastData?.message, toastData?.extraArgs);
            }
        }
    }, [toastData]);

    /* The `restoreOriginalUri` function is a callback function that is used as a parameter in the
    `Security` component from the `@okta/okta-react` library. */
    const restoreOriginalUri = useCallback((_oktaAuth: any, originalUri: string): void => {
        window.location.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    }, []);

    /* The `customAuthHandler` function is a callback function that is used to handle custom
    authentication logic. It is triggered when the user's authentication state changes. */
    const customAuthHandler = useCallback((): void => {
        const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
        if (!previousAuthState?.isAuthenticated) {
            triggerLogin();
        }
    }, [oktaAuth]);

    /**
     * The function `getWrapperClassName` returns a string representing the class name of a wrapper
     * element based on the current URL path.
     * @returns The function `getWrapperClassName` returns a string value. The specific value that is
     * returned depends on the current `window.location.pathname` value. If the `pathname` is equal to
     * '/forbidden', the function returns 'content-body-wrapper'. If the `pathname` includes
     * '/device/details', the function returns an empty string. Otherwise, the function returns
     * 'content-body'.
     */
    const getWrapperClassName = (): string => {
        if (window.location.pathname === '/forbidden' || window.location.pathname === '/qr_epas_details') {
            return 'content-body-wrapper';
        } else if (window.location.pathname.includes('/device/details')) {
            return '';
        }
        return 'content-body';
    };

    /**
     * The function `renderSnackActionButton` returns a JSX element representing a small button with
     * the text "OK" and an `onClick` event handler that calls the `closeSnackbar` function with the
     * `snackId` parameter.
     * @param {any} snackId - The `snackId` parameter is a variable that represents the unique
     * identifier of a snack (notification) that is being rendered.
     */
    const renderSnackActionButton = (snackId: any): JSX.Element => (
        <Button color="primary" size="small" onClick={(): void => closeSnackbar(snackId)}>
            OK
        </Button>
    );

    return (
        <>
            <div className={getWrapperClassName()}>
                <Security
                    oktaAuth={oktaAuth}
                    onAuthRequired={customAuthHandler}
                    restoreOriginalUri={restoreOriginalUri}
                >
                    <AuthUIConfiguration>
                        <Routes>
                            <Route path="/login" element={<CustomLoginComponent />} />
                            <Route path="/qr_epas_details" element={<QrDetails />} />
                            <Route path="/login/callback" element={<CustomLoginCallback />} />
                            <Route path="/register/create-account" element={<SelfRegistrationPager />} />
                            <Route path="/error" element={<ErrorPage />} />
                            {!isAdmin ? (
                                <Route path="*" element={<RequiredAuth />}></Route>
                            ) : (
                                <Route path="/admin/*" element={<AdminApp />}></Route>
                            )}
                        </Routes>
                    </AuthUIConfiguration>
                </Security>
            </div>
            <AuthenticationModal />
            <IdleLogoutHandler onIdle={(): void => setIsIdleTimeout(true)} timeOutInterval={900000} />
            <SnackbarProvider
                className="snackbar-word-break"
                autoHideDuration={toastData?.duration || 3000}
                maxSnack={9}
                action={(snackId: any): JSX.Element => renderSnackActionButton(snackId)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                preventDuplicate
                Components={{
                    notificationWithIcon: NotificationWithIcon,
                }}
            />
        </>
    );
};

export default App;
