import { createContext, useContext } from 'react';

/**
 * The below type defines the shape of a context object used for managing the state of a drawer
 * component in a TypeScript React application.
 * @property {boolean} drawerOpen - A boolean value indicating whether the drawer is open or closed.
 * @property setDrawerOpen - A function that takes a boolean parameter and does not return anything. It
 * is used to update the state of the drawerOpen property.
 */
type DrawerContextType = {
    drawerOpen: boolean;
    setDrawerOpen: (open: boolean) => void;
};

/* `export const DrawerContext = createContext<DrawerContextType | null>(null);` is creating a new
context object called `DrawerContext` using the `createContext` function from React. */
export const DrawerContext = createContext<DrawerContextType | null>(null);

/**
 * The useDrawer function is a custom hook that returns the context value from the
 * DrawerContextProvider.
 * @returns the value of the `context` variable, which is of type `DrawerContextType`.
 */
export const useDrawer = (): DrawerContextType => {
    const context = useContext(DrawerContext);
    if (context === null) {
        throw new Error('useDrawer must be used within a DrawerContextProvider');
    }
    return context;
};
