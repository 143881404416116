import React, { useState, useRef } from 'react';
import { Route } from 'react-router-dom';
import AppHeader from '../components/AppHeader';
import { DrawerContext } from '../contexts/drawerContextProvider';
import { DEFAULT_DRAWER_WIDTH, MAX_DRAWER_WIDTH, MIN_DRAWER_WIDTH } from '@fiji/common/src/constants';
import { DrawerLayout } from '@brightlayer-ui/react-components';
import InternalPageHeader from '../components/InternalPageHeader';
import { makeStyles } from '@mui/styles';
import { NavigationDrawer } from './drawer';
import { DeleteGroupModal } from '../pages/GroupManagement';
import SwitchOrganizationModal from '../pages/ManageOrganization/SwitchOrganizationModal';
import { AppCollapsibleHeader } from '../components/AppCollapsibleHeader';
import { useTypedSelector } from '@fiji/common/src/app/store';

import { useRBAC } from '../hooks';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { OrgFlowModal } from '../pages/OrgFlowModal';
import { MoveGroupsDevicesModal, AddGroupModal } from '../pages/Common';
import { Box } from '@mui/material';

/**
 * The below type represents a route in a TypeScript React application, including its path, component,
 * children, title, and various optional properties.
 * @property {string} path - The path property is a string that represents the URL path for the route.
 * It is used to match the current URL and determine which component to render.
 * @property {string} component - The "component" property is a string that represents the name or path
 * of the component that should be rendered when the route is accessed. This component will be
 * responsible for displaying the content of the route.
 * @property {Route[]} children - The `children` property is an optional array of `Route` objects. It
 * is used to define nested routes within a parent route. Each child route can have its own path,
 * component, and other properties. This allows for creating a hierarchical structure of routes in your
 * application.
 * @property {string} title - The title property is used to specify the title of the route. It is
 * typically used to display the title in the header or navigation menu of the application.
 * @property {boolean} hideHeader - The `hideHeader` property is used to determine whether to hide the
 * header component for a specific route. If set to `true`, the header component will be hidden for
 * that route.
 * @property {boolean} hideDrawer - The `hideDrawer` property is used to determine whether or not to
 * hide the drawer navigation for a specific route. If set to `true`, the drawer navigation will be
 * hidden for that route.
 * @property {boolean} isCollapsibleHeader - The `isCollapsibleHeader` property is used to indicate
 * whether the header of the route's component is collapsible or not. If set to `true`, it means that
 * the header can be collapsed or expanded by the user. If set to `false` or not provided, the header
 * is not
 * @property {string} permissionKey - The `permissionKey` property is used to specify the permission
 * required to access the route. This can be used to implement role-based access control, where certain
 * routes are only accessible to users with specific permissions.
 */
export type Route = {
    path: string;
    component: string;
    subComponent?: string;
    children?: Route[];
    title?: string;
    hideHeader?: boolean;
    hideDrawer?: boolean;
    isCollapsibleHeader?: boolean;
    permissionKey?: string;
    isNoShadowHeader?: boolean;
};

const useStyles = makeStyles(() => ({
    dragger: {
        width: '5px',
        cursor: 'ew-resize',
        padding: '4px 0 0',
        position: 'absolute',
        top: 0,
        bottom: 0,
        zIndex: '9',
        backgroundColor: 'transparent',
    },
}));

/* The below code is a TypeScript function called `CreateRoutes` that generates an array of JSX
elements representing the routes of a React application. */
export const CreateRoutes = (
    routes: Route[],
    drawerProviderValue: any,
    isChildren: boolean,
    parentRoutes: Route[],
    skip: boolean
): JSX.Element[] => {
    const rolePermissions = useTypedSelector(selectCurrentPermission);
    const currentOrg = useTypedSelector(selectedOrg);
    const { hasPermission } = useRBAC(rolePermissions);
    const classes = useStyles();
    const [drawerWidth, setDrawerWidth] = useState(DEFAULT_DRAWER_WIDTH);

    /**
     * The function `handleMouseMove` updates the width of a drawer based on the client's mouse
     * movement.
     * @param {any} e - The parameter `e` is an event object that represents the mouse move event. It
     * contains information about the event, such as the mouse's current position.
     */
    const handleMouseMove = (e: any): void => {
        const newWidth = e.clientX - document.body.offsetLeft;
        if (newWidth > MIN_DRAWER_WIDTH && newWidth < MAX_DRAWER_WIDTH) {
            setDrawerWidth(newWidth);
        }
    };

    /**
     * The function `handleMouseUp` removes event listeners for the 'mouseup' and 'mousemove' events
     * from the document.
     */
    const handleMouseUp = (): void => {
        document.removeEventListener('mouseup', handleMouseUp, true);
        document.removeEventListener('mousemove', handleMouseMove, true);
    };

    /**
     * The function "handleMouseDown" adds event listeners for mouseup and mousemove events.
     */
    const handleMouseDown = (): void => {
        document.addEventListener('mouseup', handleMouseUp, true);
        document.addEventListener('mousemove', handleMouseMove, true);
    };

    /**
     * The function `getPageElement` returns a JSX element based on the provided route,
     * nestedRoutesList, and component, with different variations depending on the route's properties.
     * @param {Route} route - The `route` parameter is an object that represents a specific route in
     * the application. It contains information such as the route's title, whether the header should be
     * hidden, and whether the header should be collapsible.
     * @param {Route[]} nestedRoutesList - nestedRoutesList is an array of Route objects that represent
     * the nested routes for a particular page.
     * @param {any} component - The `component` parameter is the component that will be rendered on the
     * page. It can be any valid React component.
     * @returns The function `getPageElement` returns a JSX element.
     */
    const getPageElement = (route: Route, nestedRoutesList: Route[], component: any): JSX.Element => {
        const DynamicComponent = component;
        if (route.hideHeader) {
            return (
                <Box sx={{ backgroundColor: '#f7f8f8', height: '100%' }}>
                    <DynamicComponent ref={addPageRef} />
                </Box>
            );
        } else if (route?.isCollapsibleHeader) {
            return (
                <AppCollapsibleHeader
                    page={
                        <Box sx={{ backgroundColor: '#f7f8f8', height: '100%' }}>
                            <DynamicComponent ref={addPageRef} />
                        </Box>
                    }
                    nestedRoutesList={nestedRoutesList}
                />
            );
        }
        return (
            <AppHeader
                title={route?.title ?? ''}
                page={
                    <Box sx={{ backgroundColor: '#f7f8f8', height: '100%' }}>
                        <DynamicComponent />
                    </Box>
                }
            />
        );
    };

    /* The `getRouteElement` function is responsible for rendering the appropriate component based on
    the given route. */

    const addPageRef = useRef(null);

    const getRouteElement = (route: Route, nestedRoutesList: Route[], component: any): JSX.Element => {
        const DynamicComponent = component;
        if (isChildren && !route.isCollapsibleHeader) {
            return (
                <InternalPageHeader
                    nestedRoutesList={nestedRoutesList}
                    title={currentOrg?.name ?? ''}
                    page={
                        <Box sx={{ backgroundColor: '#f7f8f8' }}>
                            <DynamicComponent ref={addPageRef} />
                        </Box>
                    }
                    addPageRef={addPageRef}
                    isNoShadowHeader={route?.isNoShadowHeader}
                />
            );
        } else if (route.hideDrawer) {
            return <DynamicComponent />;
        }
        return (
            <DrawerContext.Provider value={drawerProviderValue}>
                <div
                    id="dragger"
                    onMouseDown={handleMouseDown}
                    className={classes.dragger}
                    style={{ left: drawerWidth }}
                />
                <DrawerLayout
                    drawer={
                        <NavigationDrawer
                            drawerWidth={drawerWidth}
                            setDrawerWidth={setDrawerWidth}
                            addPageRef={addPageRef}
                        />
                    }
                    sx={{ height: '100%' }}
                >
                    <div className={classes.dragger} />
                    {getPageElement(route, nestedRoutesList, DynamicComponent)}
                </DrawerLayout>
            </DrawerContext.Provider>
        );
    };

    return routes.map((route: Route): JSX.Element => {
        const nestedRoutesList = JSON.parse(JSON.stringify(parentRoutes));
        if (route.path !== '') {
            nestedRoutesList.push(route);
        } else {
            nestedRoutesList.push({ ...route, title: '' });
        }
        let DynamicComponent: any;
        if (route.component)
            DynamicComponent = require(`../pages/${route.component}/index.ts`)[route.subComponent ?? route.component];
        return (
            <>
                {!skip && (!route.permissionKey || hasPermission(route.permissionKey)) && (
                    <Route
                        key={route.path}
                        {...(DynamicComponent && {
                            path: route.path,
                            element: (
                                <>
                                    <AddGroupModal />
                                    <SwitchOrganizationModal />
                                    <DeleteGroupModal />
                                    <OrgFlowModal />
                                    <MoveGroupsDevicesModal />
                                    {getRouteElement(route, nestedRoutesList, DynamicComponent)}
                                </>
                            ),
                        })}
                    />
                )}
                {route.children?.length &&
                    CreateRoutes(route.children, drawerProviderValue, true, nestedRoutesList, skip)}
            </>
        );
    });
};
