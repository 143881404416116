import React from 'react';
import { createNumArray } from '../../CommonUtils';
import { Box, Skeleton, Typography } from '@mui/material';

const GroupCardSkeleton = ({ count }: { count?: number }): JSX.Element => (
    <>
        {createNumArray(count ?? 3)?.map((item) => (
            <Box
                className="align-center"
                sx={{
                    padding: '16px',
                }}
                key={`unique${item}`}
            >
                <Box className="align-center" sx={{ flexBasis: '70%' }}>
                    <Box>
                        <Skeleton animation="wave" height={45} width={45} variant="circular" />
                    </Box>
                    <Box sx={{ paddingLeft: '10px' }}>
                        <Skeleton animation="wave">
                            <Typography variant="body1" sx={{ fontSize: '16px', fontWeight: '600', color: '#424E54' }}>
                                name
                            </Typography>
                        </Skeleton>
                        <Skeleton animation="wave">
                            <Typography
                                variant="caption"
                                sx={{ fontSize: '14px', fontWeight: '400', color: '#424E54' }}
                            >
                                0 Devices
                            </Typography>
                        </Skeleton>
                    </Box>
                </Box>
                <Box
                    className="d-flex"
                    sx={{
                        flexBasis: '30%',
                    }}
                >
                    <Skeleton animation="wave">
                        <Typography
                            sx={{
                                fontWeight: '600',
                            }}
                        >
                            100
                        </Typography>
                    </Skeleton>

                    <Skeleton animation="wave">
                        <Typography
                            sx={{
                                fontWeight: '600',
                            }}
                        >
                            100
                        </Typography>
                    </Skeleton>
                    <Skeleton animation="wave" variant="circular" width={30} height={30} />
                </Box>
            </Box>
        ))}
    </>
);

export default GroupCardSkeleton;
