import React from 'react';
import { IconButton, Box, Toolbar, Skeleton, Typography } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { AppBar, Spacer, ThreeLiner } from '@brightlayer-ui/react-components';
import { ArrowBack } from '@mui/icons-material';
import HeaderActions from './HeaderActions';
import clsx from 'clsx';
import { Route } from '../router/routes';
import { useNavigate } from 'react-router';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { useLocation, useParams } from 'react-router-dom';
import { setSelectedDevice } from '@fiji/common/src/features/common/commonSlice';

const backgroundImage = require('../assets/images/collapsible_app_bar.jpg');

const useStyles = makeStyles((theme: Theme) => ({
    title: {},
    subtitle: {},
    info: {},
    liner: {
        top: 0,
        position: 'absolute',
        flexGrow: 1,
        marginLeft: 56,
        [theme.breakpoints.down('md')]: {
            marginLeft: 56,
        },
    },
    toolbarRightItem: {
        marginRight: theme.spacing(3),
    },
    expanded: {
        '& $liner': {
            top: 80,
        },
    },
    collapsed: {
        '& $title': {
            fontSize: '1.25rem',
            fontWeight: 600,
        },
        '& $subtitle': {
            fontSize: 0,
        },
        '& $info': {
            fontSize: '1rem',
            marginTop: '-0.25rem',
            fontWeight: 400,
        },
        '& $backgroundGradient': {
            backgroundImage: 'none !important',
        },
    },
    userMenuButton: {
        // height:'100% !important',
    },
    toolbarGutters: {
        paddingLeft: 16,
        paddingRight: 16,
        minHeight: '56px',
        [theme.breakpoints.down('sm')]: {
            minHeight: '56px',
        },
    },
    normalGradient: {
        background: `rgba(0, 123, 193)  !important`,
    },
    warningGradient: {
        background: `rgba(244,119,33) !important`,
    },
    alertGradient: {
        background: `rgba(200, 60, 61) !important`,
    },
    offlineGradient: {
        background: `rgba(105,177,195)  !important`,
    },
    backGroundGradient: {
        opacity: '0.1!important',
    },
    toolbarRightContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
}));

/* The code defines a functional component called `AppCollapsibleHeader`. It takes in a `props` object
with two properties: `page` and `nestedRoutesList`. */
export const AppCollapsibleHeader = (props: { page: JSX.Element; nestedRoutesList: Route[] }): JSX.Element => {
    const theme = useTheme();
    const { deviceId } = useParams();
    const classes = useStyles(theme);
    const navigate = useNavigate();
    const { page, nestedRoutesList } = props;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { collapsibleHeaderDetails: data } = useTypedSelector((state: any) => state?.common);
    const selectedDevice = useTypedSelector((state) => state.common.deviceId);

    /**
     * The function `handleSubtitle` returns a string based on the value of `data.status` and
     * `data.connectionStatus`.
     * @returns The function `handleSubtitle` returns a string.
     */
    const handleSubtitle = (): string | JSX.Element => {
        if (data?.isLoading) {
            return (
                <Skeleton animation="wave">
                    <Typography variant="body2">Offline</Typography>
                </Skeleton>
            );
        }
        if (!data?.status && !data?.connectionStatus) {
            return `N/A`;
        }
        if (data?.connectionStatus === 'Offline') {
            return 'Offline';
        }
        return `${data?.connectionStatus ?? '-'} • ${getStatus()}`;
    };

    const getStatus = (): string => {
        if (data?.status === 'Informational') {
            return 'Normal';
        }
        return data?.status ?? '-';
    };

    const handleTitle = (): any => {
        if (data?.isLoading) {
            return (
                <Skeleton animation="wave">
                    <Typography variant="h5">Devicename</Typography>
                </Skeleton>
            );
        }
        return data?.deviceName || '';
    };

    const handleGroupPath = (): any => {
        if (data?.isLoading) {
            return (
                <Skeleton animation="wave">
                    <Typography variant="body2">{'group > path'}</Typography>
                </Skeleton>
            );
        }
        return data?.groupPath?.replaceAll('/', ' < ') || data?.groupName;
    };

    /**
     * The function `handleNavigateBack` navigates back to the previous route using the `navigate`
     * function and passes the state from the current location.
     */
    const handleNavigateBack = (): void => {
        if (deviceId === selectedDevice) dispatch(setSelectedDevice(''));
        navigate(`/${nestedRoutesList[nestedRoutesList.length - 2].path}`, { state: location.state });
    };

    return (
        <div style={{ minHeight: '100vh' }}>
            <AppBar
                style={{ fontFamily: 'Open Sans', whiteSpace: 'pre' }}
                className={clsx({
                    [classes.offlineGradient]: data?.status === 'Offline',
                    [classes.normalGradient]: data?.status === 'Normal',
                    [classes.alertGradient]: data?.status === 'Alarm',
                    [classes.warningGradient]: data?.status === 'Warning',
                })}
                expandedHeight={260}
                collapsedHeight={56}
                scrollThreshold={136}
                animationDuration={300}
                backgroundImage={backgroundImage}
                variant={'snap'}
                classes={{
                    collapsed: classes.collapsed,
                    expanded: classes.expanded,
                    background: classes.backGroundGradient,
                }}
            >
                <Toolbar data-cy={'toolbar'} classes={{ gutters: classes.toolbarGutters }}>
                    <IconButton
                        data-cy="toolbar-menu"
                        color={'inherit'}
                        edge={'start'}
                        size="large"
                        onClick={handleNavigateBack}
                    >
                        <ArrowBack />
                    </IconButton>
                    <Spacer />
                    <ThreeLiner
                        classes={{ title: classes.title, subtitle: classes.subtitle, info: classes.info }}
                        className={classes.liner}
                        title={handleTitle()}
                        subtitle={handleSubtitle()}
                        info={handleGroupPath()}
                        animationDuration={300}
                    />
                    <Box className={classes.toolbarRightContent}>
                        <HeaderActions />
                    </Box>
                </Toolbar>
            </AppBar>
            <Box>{page}</Box>
        </div>
    );
};
