import React from 'react';
import { LoginCallback } from '@okta/okta-react';
import { BackdropLoader } from './BackdropLoader';
import CustomLoginComponent from './CustomLogin';

export const CustomLoginCallback = (): JSX.Element => {
    const queryParams = new URLSearchParams(window.location.search);
    const error = queryParams.get('error') ?? '';
    if (error === 'interaction_required') {
        return <CustomLoginComponent />;
    }
    return <LoginCallback loadingElement={<BackdropLoader isOpen={true} />} />;
};
