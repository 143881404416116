import { AppBar, Box } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import React from 'react';
import { PreferencesTab, NotificationTable, SelectUsers, SelectNotificationTypes } from './';
import { ToggleOn } from '@mui/icons-material';
import { CustomTabs } from '../../components/CustomTabs';
import {
    useCreateCustomNotificationMutation,
    useEditCustomNotificationMutation,
    useGetCustomNotificationByIdQuery,
} from '@fiji/common/src/features/customNotification/customNotificationApi';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import {
    addNotificationModal,
    selectAddNotificationModal,
} from '@fiji/common/src/features/notification/notificationSlice';
import commonSlice from '@fiji/common/src/features/common/commonSlice';
import { SelectDeviceType, SelectDevices, SelectEvents, SelectGroups } from '../Common';
import { StepperModal } from '../../components/StepperModal';
import { useGetHierarchyHandlers, useRBAC } from '../../hooks';
import { handleWhiteSpaces } from '../../CommonUtils';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useGetAllEventsQuery } from '@fiji/common/src/features/events/eventsApi';
import { AllUserObj, EventList, EventType } from '@fiji/common/src/types';
import { useGetAllUsersQuery } from '@fiji/common/src/features/userManagement/userManagement';
import { Device } from '@fiji/common/src/types/Device';
import { useGetPaginatedDeviceListQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { selectHierarchyData } from '@fiji/common/src/features/group/groupSlice';

const initialState = {
    groupIds: [],
    deviceTypeIds: [],
    eventIds: [],
    deviceIds: [],
    name: '',
    email: false,
    push: false,
    delay: 30,
    sms: false,
    userIds: [],
    isAdvanced: false,
};

export const NotificationPreferences = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const rolePermissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(rolePermissions);
    const getAllCustomNotifications = hasPermission('get-all-custom-notification');
    const canViewUsers = hasPermission('view-filtered-users');

    const tabRef = React.useRef<any>(null);

    const [selectedTab, setSelectedTab] = React.useState<string>('preferences');
    const [currentStep, setCurrentStep] = React.useState<string>('groups');
    const [payload, setPayload] = React.useState<any>(initialState);
    const { data: allEvents } = useGetAllEventsQuery<{ data: EventList }>();

    const notificationModal = useTypedSelector(selectAddNotificationModal);

    const hierarchyData = useTypedSelector(selectHierarchyData);

    const { removeUnwantedIds } = useGetHierarchyHandlers({
        isChildrenPreselected: true,
    });

    const { currentData: notificationData, isSuccess }: any = useGetCustomNotificationByIdQuery(
        notificationModal?.notificationId,
        {
            skip: !notificationModal?.notificationId,
            refetchOnMountOrArgChange: true,
        }
    );

    const { data: users } = useGetAllUsersQuery<{ data: AllUserObj; isLoading: boolean; isFetching: boolean }>(
        {
            page: 0,
            size: 20,
            filters: {
                notificationGroup: payload?.groupIds,
            },
        },
        { skip: !canViewUsers }
    );

    const { data: devices, isSucess: isDeviceSucess } = useGetPaginatedDeviceListQuery<{
        data: any;
        isSucess: boolean;
    }>({
        size: 25,
        page: 0,
        filters: {
            deviceType: payload?.deviceTypeIds,
            groupId: payload?.groupIds,
        },
    });

    const [createNotification, { isLoading: isNotificationCreated }] = useCreateCustomNotificationMutation();
    const [editNotifification, { isLoading: isNotificationEdited }] = useEditCustomNotificationMutation();

    /* The `tabsData` constant is an array of objects that define the tabs for the notification preferences
component. Each object represents a tab and contains the following properties: */
    const tabsData: any[] = [
        {
            id: 'preferences',
            label: 'Preferences',
            icon: <ToggleOn />,
            sx: {
                fontSize: '14px',
                fontWeight: '600',
                textTransform: 'capitalize',
            },
        },
    ];

    if (getAllCustomNotifications) {
        tabsData.push({
            id: 'customNotifications',
            label: 'Custom Notifications',
            sx: { fontSize: '14px', fontWeight: '600', textTransform: 'capitalize' },
            icon: <TuneIcon />,
        });
    }

    React.useEffect(
        () => () => {
            closeModal();
            setPayload(initialState);
        },
        []
    );

    /* The `React.useEffect` hook is used to perform side effects in a functional component. In this
    case, the effect is triggered when the `isSuccess` or `notificationModal?.notificationId` values
    change. */
    React.useEffect(() => {
        if (notificationData && notificationModal?.notificationId) {
            initialPayloadHandler();
        }
    }, [notificationData, notificationModal?.notificationId]);

    /* The `React.useEffect` hook is used to perform side effects in a functional component. In this
    case, the effect is triggered when the value of `notificationModal?.isOpen` changes. */
    React.useEffect(() => {
        if (!notificationModal?.isOpen) {
            setPayload(initialState);
        }
    }, [notificationModal?.isOpen]);

    /**
     * The function `initialPayloadHandler` sets the payload state by extracting data from the
     * `notificationData` object.
     */
    const initialPayloadHandler = (): void => {
        setPayload({
            ...payload,
            deviceIds: notificationData?.data?.devices?.map((device: any) => device?.id) || [],
            groupIds: notificationData?.data?.groups?.map((group: any) => group?.id) || [],
            delay: notificationData?.data?.delay ?? payload?.delay,
            deviceTypeIds: notificationData?.data?.deviceTypeIds || [],
            eventIds: notificationData?.data?.events?.map((event: any) => event?.id) || [],
            email: notificationData?.data?.email,
            name: notificationData?.data?.name,
            push: notificationData?.data?.push,
            sms: notificationData?.data?.sms,
            userIds: notificationData?.data?.users?.map((user: any) => user?.id) || [],
            isAdvanced: notificationData?.data?.delay !== 30,
        });
    };

    /**
     * The function closeModal is used to close a notification modal by dispatching an action to update
     * its isOpen property to false.
     */
    const closeModal = (): void => {
        dispatch(addNotificationModal({ isOpen: false }));
    };

    /**
     * The submitHandler function is used to handle the submission of a form for creating or editing a
     * notification, and displays a success message accordingly.
     */
    const submitHandler = async (): Promise<void> => {
        const customPayload = { ...payload, delay: parseInt(payload?.delay) };
        delete customPayload.isAdvanced;
        if (notificationModal?.notificationId) {
            const filteredGroupIds = removeUnwantedIds(hierarchyData?.data?.data?.data, customPayload.groupIds, []);
            const { error }: any = await editNotifification({
                body: { ...customPayload, groupIds: filteredGroupIds?.groups },
                id: notificationModal?.notificationId,
            });
            if (!error) {
                setPayload(initialState);
                dispatch(
                    commonSlice.actions.setToastifyContent({
                        isOpen: true,
                        message: 'Notification edited successfully',
                    })
                );
                closeModal();
            }
        } else {
            const filteredGroupIds = removeUnwantedIds(hierarchyData?.data?.data?.data, customPayload.groupIds, []);
            const { error }: any = await createNotification({ ...customPayload, groupIds: filteredGroupIds?.groups });
            if (!error) {
                setPayload(initialState);
                dispatch(
                    commonSlice.actions.setToastifyContent({
                        isOpen: true,
                        message: 'Notification created successfully',
                    })
                );
                closeModal();
            }
        }
    };

    /**
     * The function `getCurrentStep` sets the current step to the provided value.
     * @param {string} step - The parameter "step" is a string that represents the current step.
     */
    const getCurrentStep = (step: string): void => {
        setCurrentStep(step);
    };

    const getCounts = (): number => {
        let count = 0;
        if (allEvents?.data?.records?.length) {
            allEvents?.data?.records?.forEach((event: EventType) => {
                if (event?.child?.length) {
                    event?.child?.forEach(() => {
                        count++;
                    });
                }
            });
        }
        return count;
    };

    const eventOrEventsTag = (tempArr: any[]): string => {
        if (tempArr.length > 1) {
            return 'events';
        }
        return 'event';
    };

    const getSelectedEventsNamesOrCounts = (): any => {
        const tempArr: string[] = [];
        const counts = getCounts();

        allEvents?.data?.records?.forEach((event: EventType) => {
            payload?.eventIds?.forEach((tempId: any) => {
                if (tempId === event.id) {
                    tempArr.push(event.name);
                } else {
                    event?.child?.forEach((childEvent: EventType) => {
                        if (tempId === childEvent.id) {
                            tempArr.push(childEvent.name);
                        }
                    });
                }
            });
        });

        if (tempArr?.length > 2 && tempArr?.length !== counts) {
            return (
                <>
                    <b>{tempArr[0]}</b> and <b>{tempArr?.length - 1} Other </b> events
                </>
            );
        }
        if (tempArr?.length === counts) {
            return <b>All events</b>;
        }

        return (
            <>
                {tempArr.map((item: string, index: number) => (
                    <>
                        <b>{item}</b>
                        {index !== tempArr.length - 1 ? `, ` : `${eventOrEventsTag(tempArr)}`}
                    </>
                ))}
            </>
        );
    };

    const getSelectedUserNames = (): any => {
        if (users?.data?.records?.length === payload?.userIds) {
            return <b>All users</b>;
        }
        return (
            <b>
                {payload?.userIds?.length} {payload?.userIds?.length > 1 ? `users` : 'user'}
            </b>
        );
    };

    const getSelectedDeviceNames = (): any => {
        const tempArr: string[] = [];
        devices?.records?.forEach((device: Device) => {
            payload?.deviceIds?.forEach((tempId: any) => {
                if (device.deviceId === tempId) {
                    tempArr.push(device.deviceName);
                }
            });
        });

        if (tempArr?.length > 2 && tempArr?.length !== devices?.data?.records?.length) {
            return (
                <>
                    <b>{tempArr[0]}</b> and <b>{tempArr?.length - 1} Other </b> devices
                </>
            );
        }
        if (tempArr?.length === devices?.data?.records?.length) {
            return <b>All devices</b>;
        }

        return (
            <>
                {tempArr.map((item: string, index: number) => (
                    <>
                        <b>{item}</b>
                        {index !== tempArr.length - 1 ? `, ` : ` `}
                    </>
                ))}
            </>
        );
    };

    /**
     * The function `handleStepDisable` checks if certain properties in the `payload` object are empty
     * and returns a boolean value accordingly.
     * @returns The function `handleStepDisable` returns a boolean value.
     */
    const handleStepDisable = (): boolean => {
        switch (currentStep) {
            case 'groups':
                return Boolean(!payload?.groupIds?.length);
            case 'users':
                return Boolean(!payload?.userIds?.length);
            case 'deviceType':
                return Boolean(!payload?.deviceTypeIds?.length);
            case 'devices':
                return Boolean(!payload?.deviceIds?.length);
            case 'events':
                return Boolean(!payload?.eventIds?.length) || (payload?.isAdvanced && !payload.delay);
            case 'notificationTypes':
                return (
                    Boolean(!payload?.name?.length) ||
                    !handleWhiteSpaces(payload?.name) ||
                    (!payload.email && !payload.sms && !notificationModal?.notificationId)
                );
            default:
                return false;
        }
    };

    /* The below code is defining an array called `modalContent` using the `React.useMemo` hook. This
    array contains objects that represent different sections of a modal. Each object has properties
    such as `key`, `title`, `header`, `isEnabled`, and `component`. These properties are used to
    configure and render different components within the modal. The `payload` and `isSuccess`
    variables are used as dependencies for the `useMemo` hook, so that the `modalContent` array is
    recalculated only when these variables change. */
    const modalContent = React.useMemo(
        () => [
            {
                key: 'groups',
                title: isSuccess ? 'Edit Custom Notifcation' : 'New Custom Notification',
                header: 'Select Groups',
                isEnabled: false,
                component: <SelectGroups payload={payload} setPayload={setPayload} />,
            },
            {
                key: 'deviceType',
                title: isSuccess ? 'Edit Custom Notifcation' : 'New Custom Notification',
                header: 'Select Device Types',
                isEnabled: false,
                component: <SelectDeviceType payload={payload} setPayload={setPayload} />,
            },
            {
                key: 'devices',
                title: isSuccess ? 'Edit Custom Notifcation' : 'New Custom Notification',
                header: 'Select Devices',
                isEnabled: false,
                component: <SelectDevices payload={payload} setPayload={setPayload} total={devices?.total} />,
            },
            {
                key: 'events',
                title: isSuccess ? 'Edit Custom Notifcation' : 'New Custom Notification',
                header: 'Select Events',
                component: (
                    <SelectEvents
                        isMulti
                        payload={payload}
                        setPayload={setPayload}
                        isAdvanced={notificationData?.data?.delay ?? payload?.delay}
                    />
                ),
            },
            {
                key: 'users',
                title: isSuccess ? 'Edit Custom Notifcation' : 'New Custom Notification',
                header: 'Select Users',
                isEnabled: false,
                component: <SelectUsers payload={payload} setPayload={setPayload} />,
            },
            {
                key: 'notificationTypes',
                title: isSuccess ? 'Edit Custom Notifcation' : 'New Custom Notification',
                header: 'Select Notification Types',
                isEnabled: false,
                component: <SelectNotificationTypes payload={payload} setPayload={setPayload} />,
                description: (
                    <>
                        {getSelectedEventsNamesOrCounts()} for {getSelectedDeviceNames()} will notify{' '}
                        {getSelectedUserNames()} when active and when cleared.
                    </>
                ),
                nextButtonLabel: notificationModal?.notificationId ? 'Update Notification' : 'Add Notification',
            },
        ],
        [payload, isSuccess, isDeviceSucess]
    );

    /**
     * The function `onTabChange` updates the selected tab based on the active tab passed as an
     * argument.
     * @param {string} activeTab - The activeTab parameter is a string that represents the currently
     * selected tab.
     */
    const onTabChange = (activeTab: string): void => {
        setSelectedTab(activeTab);
    };

    /**
     * The function `renderTabContent` returns different JSX elements based on the value of
     * `selectedTab`.
     * @returns The function `renderTabContent` returns a JSX element. The specific element that is
     * returned depends on the value of the `selectedTab` variable. If the `selectedTab` is equal to
     * `'preferences'`, then the function returns the `<PreferencesTab />` component. Otherwise, it
     * returns the `<NotificationTable />` component with the `loading` prop set to the value of
     * `isNotification
     */
    const renderTabContent = (): JSX.Element => {
        if (selectedTab === 'preferences') {
            return <PreferencesTab />;
        }
        return <NotificationTable loading={isNotificationCreated || isNotificationEdited} />;
    };

    return (
        <>
            <AppBar position="static" color="primary" className="subHeader">
                <Box className="w-100" sx={{ backgroundColor: '#f7f8f8' }}>
                    <CustomTabs
                        onTabChange={onTabChange}
                        ref={tabRef}
                        key="customTabNotification"
                        data={tabsData}
                        preSelectedTab="preferences"
                        customStyleObject={{ boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2)', marginBottom: '50px' }}
                    />
                    {renderTabContent()}
                </Box>
            </AppBar>
            <StepperModal
                modalContent={modalContent}
                closeModal={closeModal}
                isOpen={notificationModal?.isOpen}
                submitHandler={submitHandler}
                closeModalOnFinish={false}
                getCurrentStep={getCurrentStep}
                isDisabled={handleStepDisable()}
                isLoading={isNotificationCreated || isNotificationEdited}
            />
        </>
    );
};
