import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import * as Colors from '@brightlayer-ui/colors';

export const useStyles = makeStyles((theme: Theme) => ({
    muiDialogpaper: {
        '& .MuiDialog-paper': {
            maxWidth: '820px',
        },
        '& .MuiMobileStepper-positionStatic': {
            width: '100%',
        },
    },
    deleteMuiDialogpaper: {
        '& .MuiPaper-root': {
            maxWidth: '356px',
        },
    },
    MuiDialogTitleRoot: {
        paddingTop: '16px',
    },
    dialogTitleDivider: {
        borderBottom: ' 1px solid rgba(66, 78, 84, 0.12)',
        padding: '16px 24px',
    },
    dialogContent: {
        height: '385px',
    },
    contentBodyImage: {
        height: '309px',
        width: '100%',
        border: '1px solid rgba(66, 78, 84, 0.12)',
        borderRadius: '4px',
        '& img': {
            borderRadius: '4px',
            width: '100%',
            objectFit: 'cover',
            height: '100%',
            objectPosition: 'center',
        },
    },
    MuiDialogActionsRoot: {
        padding: '16px',
    },
    helperText: {
        float: 'right',
    },
    helperTextwrapper: {
        '& .MuiTypography-root': {
            fontSize: '12px',
        },
    },
    textField: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(4),
        },
    },
    infoListItem: {
        '& .MuiCheckbox-colorPrimary': {
            order: '-1',
        },
        '& .MuiListItemAvatar-root ': {
            '& .MuiAvatar-root': {
                marginRight: theme.spacing(0),
                marginLeft: theme.spacing(1),
                backgroundColor: Colors.blue[50],
                color: Colors.blue[500],
            },
        },
    },
    toggleButtonGroup: {
        marginBottom: theme.spacing(4),
    },
    toggleButton: {
        padding: '8px 12px 8px 12px',
        textTransform: 'capitalize',
    },
    mobileStepper: {
        flexGrow: 1,
        backgroundColor: 'transparent',
    },
    button: {
        textTransform: 'capitalize',
    },
    expandIcon: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: Colors.gray[500],
        fontSize: '24px !important',
    },
    emptyStateWrapper: {
        border: '3px dashed #D5D8DA',
        borderRadius: '8px',
        height: '309px',
        padding: '0px 24px',
    },
    emptyAddGroup: {
        border: 'none !important',
        height: 'Calc(100vh - 100px)',
    },
    disabled: {
        opacity: '0.5',
        pointerEvents: 'none',
        cursor: 'not-allowed',
    },
    rootMuiDialogpaper: {
        '& .MuiDialog-paper': {
            maxWidth: '450px',
            width: '450px',
        },
    },
    deleteButton: {
        background: Colors.red[600],
        '&:hover': {
            backgroundColor: Colors.red[400],
        },
        '&.Mui-disabled': {
            backgroundColor: '#F9E8E8',
            color: '#E59E9E',
        },
    },
    ContainerWrapper: {
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    contentBody: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    card: {
        padding: theme.spacing(0),
    },
    cardless: {
        boxShadow: 'none',
    },
    addButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(2),
    },
    buttonGroup: {
        marginRight: theme.spacing(-1),
        '& + h6': {
            display: 'none',
        },
    },

    menuItem: {
        marginRight: '24px',
        color: Colors.gray[500],
        height: '2rem',
    },
}));
