export const stylesClasses = {
    responsiveStyles: {
        '@media (max-width:960px)': {
            width: '100%',
            minHeight: '80vh',
        },

        '@media (min-width:960px)': {
            width: '70%',
            minHeight: '80vh',
        },
    },
    responsiveStylesContainer: {
        '@media (max-width:959.9px)': {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
        },

        '@media (min-width:960px)': {
            paddingTop: 30,
            paddingBottom: 100,
        },
    },
    responsiveStylesCardContent: {
        '@media (max-width:959.9px)': {
            paddingLeft: 2,
            paddingRight: 2,
        },

        '@media (min-width:960px)': {
            paddingLeft: 5,
            paddingRight: 5,
        },
    },
};
