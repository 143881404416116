import React from 'react';
import { Box, AppBar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useStyles } from './styles';
import { CorporateFare, Notifications, PhonelinkErase } from '@mui/icons-material';

import AppHeader from '../../../components/AppHeader';
import { Alarms } from '../Alarms/Alarms';
import { CustomerValidation } from '../CustomerValidation/CustomerValidation';
import { Communications } from '../Communications/Communications';
import { CustomTabs } from '../../../components/CustomTabs';

export const HomePage = (): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const tabRef = React.useRef<any>(null);
    const [selectedTab, setSelectedTab] = React.useState<string>('Alarms');

    /* The `tabsData` constant is an array of objects that define the tabs for the notification preferences
    component. Each object represents a tab and contains the following properties: */
    const tabsData: any[] = [
        {
            id: 'Alarms',
            label: 'Alarms',
            icon: <Notifications />,
            sx: {
                fontSize: '14px',
                fontWeight: '600',
                textTransform: 'capitalize',
            },
        },
        {
            id: 'CustomerValidation',
            label: 'CustomerValidation',
            icon: <CorporateFare />,
            sx: {
                fontSize: '14px',
                fontWeight: '600',
                textTransform: 'capitalize',
            },
        },
        {
            id: 'Communications',
            label: 'Communications',
            icon: <PhonelinkErase />,
            sx: {
                fontSize: '14px',
                fontWeight: '600',
                textTransform: 'capitalize',
            },
        },
    ];

    /**
     * The function `onTabChange` updates the selected tab based on the active tab passed as an
     * argument.
     * @param {string} activeTab - The activeTab parameter is a string that represents the currently
     * selected tab.
     */
    const onTabChange = (activeTab: string): void => {
        setSelectedTab(activeTab);
    };

    /**
     * The function `renderTabContent` returns different JSX elements based on the value of
     * `selectedTab`.
     * @returns The function `renderTabContent` returns a JSX element. The specific element that is
     * returned depends on the value of the `selectedTab` variable. If the `selectedTab` is equal to
     * `'preferences'`, then the function returns the `<PreferencesTab />` component. Otherwise, it
     * returns the `<NotificationTable />` component with the `loading` prop set to the value of
     * `isNotification
     */
    const renderTabContent = (): JSX.Element => {
        //console.log('renderTabContent:', selectedTab);
        switch (selectedTab) {
            case 'Alarms': {
                return <Alarms />;
            }
            case 'Customer Validation': {
                return <CustomerValidation />;
            }
            case 'Communications': {
                return <Communications />;
            }
            case 'Upcoming': {
                return <></>;
            }
            default: {
                return <></>;
            }
        }
    };

    return (
        <React.Fragment>
            <AppHeader
                title={'Alarm Management'}
                page={
                    <>
                        <AppBar className={classes.subHeader} position="static" color="primary">
                            <Box className="w-100" sx={{ backgroundColor: '#f7f8f8' }}>
                                <CustomTabs
                                    onTabChange={onTabChange}
                                    ref={tabRef}
                                    key="customTabNotification"
                                    data={tabsData}
                                    preSelectedTab="Alarms"
                                    customStyleObject={{
                                        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2)',
                                        marginBottom: '50px',
                                    }}
                                />
                                <React.Fragment>
                                    <Box className={classes.containerWrapper}>{renderTabContent()}</Box>
                                </React.Fragment>
                            </Box>
                        </AppBar>
                    </>
                }
            />
        </React.Fragment>
    );
};
