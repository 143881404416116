/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useCallback } from 'react';
import { IconButton } from '@mui/material';
import { UserMenu } from '@brightlayer-ui/react-components';
import Avatar from '@mui/material/Avatar';
import { getUserInitials } from '../CommonUtils';
import { useRBAC } from '../hooks';
import { AccountTree, Group, Person, Settings, SmartToy, Input, Policy } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import * as Colors from '@brightlayer-ui/colors';
import { PushNotificationSolid } from '@brightlayer-ui/icons-mui';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { useOktaAuth } from '@okta/okta-react';
import { Theme, useTheme } from '@mui/material/styles';
import { UserProfile } from '@fiji/common/src/types';
import HelpIcon from '@mui/icons-material/Help';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import NeedHelpScreen from './NeedHelpScreen';
import PrivacyPolicy from './PrivacyPolicy';
import { useDispatch } from 'react-redux';
import { setIsSignOut } from '@fiji/common/src/features/common/commonSlice';

const useStyles = makeStyles((theme: Theme) => ({
    toolbarRightItem: {
        marginRight: theme.spacing(2),
        marginLeft: '11px',
    },
    headerMenuListItem: {
        '& .BluiInfoListItem-root': {
            padding: '9px 16px',
        },
    },
}));

declare global {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Window {
        APP_CONFIG: any;
    }
}

/* The below code is defining a functional component called `HeaderActions` in TypeScript with React. */
const HeaderActions = ({ disabled }: { disabled?: boolean }): JSX.Element => {
    const rolePermissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(rolePermissions);
    const policyRef = React.useRef<any>(null);
    const canViewGroups = hasPermission('view-groups');
    const canViewUsers = hasPermission('view-filtered-users');
    const canViewAutomations = hasPermission('get-all-automation-block');
    const [isHelpScreenVisible, setIsHelpScreenVisible] = React.useState<boolean>(false);
    const helModalId = React.useId();
    const dispatch = useDispatch();

    const theme = useTheme();
    const classes = useStyles(theme);
    const navigate = useNavigate();
    const { oktaAuth } = useOktaAuth();
    const selectedOrganization = useTypedSelector(selectedOrg);

    const { data: userProfileData } = useGetUserProfileQuery<{
        data: UserProfile;
        isLoading: boolean;
    }>();

    /* The `handleUserLogout` function is a callback function that is used to handle the user logout
    action. It is created using the `useCallback` hook from React. */
    const handleUserLogout = useCallback(async (): Promise<void> => {
        dispatch(setIsSignOut(true));
        await oktaAuth.signOut();
    }, []);

    const menuItems = [];
    menuItems.push({
        title: 'Organization',
        icon: (
            <Avatar alt={selectedOrganization?.name} src={selectedOrganization?.logo ?? ''}>
                {selectedOrganization?.name && getUserInitials(selectedOrganization?.name)}
            </Avatar>
        ),
        subtitle: selectedOrganization?.orgCode,
        chevron: true,
        divider: true,
        ...(!disabled && { onClick: () => navigate('/organization') }),
    });

    if (canViewGroups) {
        menuItems.push({
            title: 'Groups',
            icon: (
                <Avatar sx={{ background: Colors.blue[500], color: Colors.white[50] }}>
                    <AccountTree />
                </Avatar>
            ),
            subtitle: 'Configure Hierarchy of Groups, Devices',
            chevron: true,
            divider: true,
            ...(!disabled && { onClick: () => navigate('/groups') }),
        });
    }

    menuItems.push({
        title: 'Notifications',
        icon: (
            <Avatar sx={{ color: Colors.blue[500] }}>
                <PushNotificationSolid />
            </Avatar>
        ),
        subtitle: 'Preferences, Custom Notifications',
        chevron: true,
        divider: true,
        ...(!disabled && { onClick: () => navigate('/notifications') }),
    });

    if (canViewAutomations) {
        menuItems.push({
            title: 'Automations',
            icon: (
                <Avatar sx={{ background: Colors.gray[500] }}>
                    <SmartToy sx={{ color: 'white' }} />
                </Avatar>
            ),
            subtitle: 'Control Automations',
            chevron: true,
            divider: true,
            ...(!disabled && { onClick: () => navigate('/automations') }),
        });
    }
    if (canViewUsers) {
        menuItems.push({
            title: 'Users',
            icon: (
                <Avatar sx={{ background: Colors.blue[500], color: Colors.white[50] }}>
                    <Group />
                </Avatar>
            ),
            subtitle: 'Configure Users and Roles',
            chevron: true,
            divider: true,
            ...(!disabled && { onClick: () => navigate('/users') }),
        });
    }
    menuItems.push({
        title: '',
        subtitle: `v${process.env.REACT_APP_VERSION || ''}.${window.APP_CONFIG.BUILD_NUMBER}`,
        className: 'build-version-text',
    });

    return (
        <>
            <UserMenu
                id="need-help"
                MenuProps={{
                    style: {
                        top: '16px',
                    },
                    sx: {
                        '& .MuiButtonBase-root ': {
                            padding: '9px 16px',
                        },
                        '& .MuiListItem-root': {
                            height: 'auto',
                        },
                        '& .BluiInfoListItem-title': {
                            fontWeight: '600 !important',
                            lineHeight: '22px',
                            color: '#424E54',
                        },
                    },
                }}
                avatar={
                    <IconButton color={'inherit'} size="large">
                        <HelpIcon />
                    </IconButton>
                }
                menuGroups={[
                    {
                        items: [
                            {
                                title: 'Need Help ?',
                                icon: <HelpIcon />,
                                onClick: (): void => setIsHelpScreenVisible(true),
                            },
                            {
                                title: 'Privacy Policy',
                                icon: <Policy />,
                                onClick: (): void => policyRef?.current?.modalHandler(true),
                            },
                        ],
                    },
                ]}
            />

            {!disabled && (
                <UserMenu
                    className={classes.toolbarRightItem}
                    id="users"
                    MenuProps={{
                        style: {
                            top: '16px',
                        },
                        sx: {
                            '& .MuiButtonBase-root ': {
                                padding: '9px 16px',
                                height: '64px !important',
                            },
                            '& .MuiListItem-root': {
                                height: 'auto',
                            },
                            '& .BluiInfoListItem-title': {
                                fontWeight: '600 !important',
                                lineHeight: '22px',
                                color: ' #424E54',
                            },
                        },
                    }}
                    avatar={
                        <IconButton color={'inherit'} size="large">
                            <Settings />
                        </IconButton>
                    }
                    menuGroups={[
                        {
                            items: menuItems,
                        },
                    ]}
                />
            )}
            <UserMenu
                id="profile-menu"
                MenuProps={{
                    style: {
                        top: '16px',
                    },
                }}
                avatar={
                    <Avatar src={userProfileData?.data?.image || ''}>
                        {userProfileData?.data?.firstName || userProfileData?.data?.lastName ? (
                            getUserInitials(
                                `${userProfileData?.data?.firstName || ' '} ${userProfileData?.data?.lastName || ''}`
                            )
                        ) : (
                            <Person fontSize="inherit" />
                        )}{' '}
                    </Avatar>
                }
                menuGroups={[
                    {
                        items: [
                            {
                                title: 'My Profile',
                                icon: <Person />,
                                onClick: () => navigate('/profile'),
                            },
                            {
                                title: 'Log Out',
                                icon: <Input />,
                                onClick: handleUserLogout,
                            },
                        ],
                    },
                ]}
                menuTitle={`${userProfileData?.data?.firstName || '-'} ${userProfileData?.data?.lastName || '-'}`}
                menuSubtitle={userProfileData?.data?.email || 'N/A'}
            />
            <NeedHelpScreen
                key={helModalId}
                isOpen={isHelpScreenVisible}
                handleCloseModal={(): void => setIsHelpScreenVisible(false)}
            />
            <PrivacyPolicy ref={policyRef} />
        </>
    );
};

export default React.memo(HeaderActions);
