import { Battery } from '@brightlayer-ui/icons-mui';
import {
    BatteryAlert,
    Battery20,
    Battery30,
    Battery50,
    Battery60,
    Battery80,
    Battery90,
    BatteryUnknown,
} from '@mui/icons-material';
import React from 'react';

const BatteryPercentageIcon = ({
    batteryLevel,
    batteryColor,
    margin,
    fontSize,
}: {
    batteryLevel: number;
    batteryColor?: string;
    margin?: string;
    fontSize?: string;
}): JSX.Element => {
    switch (true) {
        case batteryLevel !== null && batteryLevel < 10:
            return (
                <BatteryAlert
                    sx={{
                        fontSize: fontSize ?? '20px',
                        color: '#727E84',
                        margin: margin ?? '0 10px',
                        transform: 'rotate(90deg)',
                        marginBottom: '1px',
                    }}
                />
            );
        case batteryLevel >= 10 && batteryLevel < 30:
            return (
                <Battery20
                    sx={{
                        fontSize: fontSize ?? '20px',
                        color: batteryColor ?? '#727E84',
                        margin: margin ?? '0 10px',
                        transform: 'rotate(90deg)',
                        marginBottom: '1px',
                    }}
                />
            );
        case batteryLevel >= 30 && batteryLevel < 50:
            return (
                <Battery30
                    sx={{
                        fontSize: fontSize ?? '20px',
                        color: batteryColor ?? '#727E84',
                        margin: margin ?? '0 10px',
                        transform: 'rotate(90deg)',
                        marginBottom: '1px',
                    }}
                />
            );
        case batteryLevel === 50:
            return (
                <Battery50
                    sx={{
                        fontSize: fontSize ?? '20px',
                        color: batteryColor ?? '#727E84',
                        margin: margin ?? '0 10px',
                        transform: 'rotate(90deg)',
                        marginBottom: '1px',
                    }}
                />
            );
        case batteryLevel > 50 && batteryLevel < 60:
            return (
                <Battery60
                    sx={{
                        fontSize: fontSize ?? '20px',
                        color: batteryColor ?? '#727E84',
                        margin: margin ?? '0 10px',
                        transform: 'rotate(90deg)',
                        marginBottom: '1px',
                    }}
                />
            );
        case batteryLevel >= 60 && batteryLevel < 80:
            return (
                <Battery80
                    sx={{
                        fontSize: fontSize ?? '20px',
                        color: batteryColor ?? '#727E84',
                        margin: margin ?? '0 10px',
                        transform: 'rotate(90deg)',
                        marginBottom: '1px',
                    }}
                />
            );
        case batteryLevel >= 80 && batteryLevel < 90:
            return (
                <Battery90
                    sx={{
                        fontSize: fontSize ?? '20px',
                        color: batteryColor ?? '#727E84',
                        margin: margin ?? '0 10px',
                        transform: 'rotate(90deg)',
                        marginBottom: '1px',
                    }}
                />
            );
        case batteryLevel >= 90:
            return (
                <Battery
                    sx={{
                        fontSize: fontSize ?? '20px',
                        color: batteryColor ?? '#727E84',
                        margin: margin ?? '0 10px',
                        marginBottom: '1px',
                    }}
                />
            );
        default:
            return (
                <BatteryUnknown
                    sx={{
                        fontSize: fontSize ?? '20px',
                        color: '#727E84',
                        margin: margin ?? '0 10px',
                        transform: 'rotate(90deg)',
                        marginBottom: '1px',
                    }}
                />
            );
    }
};

export default BatteryPercentageIcon;
