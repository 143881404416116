import React from 'react';
import { Dialog, DialogContent, DialogTitle, Typography, Button, DialogActions, Stack } from '@mui/material';
import { useEditDeviceMutation } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { useStyles } from './styles';
import { useTheme } from '@mui/material/styles';
import Loader from '../../components/Loader';
import { useAppDispatch } from '@fiji/common/src/app/store';
import { setToastifyContent } from '@fiji/common/src/features/common/commonSlice';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import moment from 'moment';

type InstallationDateModalProps = {
    type: string;
    isOpen: boolean;
    handleModalClose: () => void;
    data: any;
    deviceId: any;
    refetchDetailsCall: any;
};

export const InstallationDateModal = ({ handleModalClose, ...props }: InstallationDateModalProps): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();

    const [date, setDate] = React.useState<any>(null);
    const [editDeviceDetail, { isLoading, isSuccess }] = useEditDeviceMutation();

    React.useEffect(() => {
        if (props.type) {
            const data = moment(props.data[props.type]);
            setDate(data);
        }
    }, [props.type]);

    React.useEffect(() => {
        if (isSuccess) {
            handleModalClose();
            props.refetchDetailsCall();
        }
    }, [isSuccess]);

    const handleChange = (data: any): void => {
        setDate(data);
    };

    const handleSubmit = async (): Promise<void> => {
        const { error }: any = await editDeviceDetail({
            payload: { [props.type]: new Date(date)?.getTime() },
            id: props.deviceId,
        });
        if (!error) {
            dispatch(setToastifyContent({ isOpen: true, message: 'Changes Saved Successfully' }));
        }
    };

    return (
        <Dialog
            open={props.isOpen}
            onClose={handleModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.rootMuiDialogpaper}
        >
            <>
                <DialogTitle id="alert-dialog-title" className={classes.dialogTitleRoot}>
                    <Typography variant={'h6'}>
                        Edit {props.type === 'deviceInstallDate' ? 'Device Install Date' : 'Battery Install Date'}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {isLoading ? (
                        <div style={{ height: '150px' }}>
                            <Loader size={60} />
                        </div>
                    ) : (
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateCalendar
                                minDate={
                                    props.type === 'batteryInstallDate'
                                        ? moment(props?.data?.deviceInstallDate)
                                        : moment(1641038661000)
                                }
                                disableFuture
                                value={date ?? null}
                                onChange={handleChange}
                            />
                        </LocalizationProvider>
                    )}
                </DialogContent>
                <DialogActions className={classes.MuiDialogActionsRoot}>
                    <Stack direction={'row'} spacing={2} justifyContent={'end'}>
                        <Button variant={'outlined'} onClick={handleModalClose}>
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} variant={'contained'}>
                            Save
                        </Button>
                    </Stack>
                </DialogActions>
            </>
        </Dialog>
    );
};
