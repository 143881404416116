import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import * as Colors from '@brightlayer-ui/colors';

export const useStyles = makeStyles((theme: Theme) => ({
    ContainerWrapper: {
        padding: theme.spacing(2),
    },
    contentBody: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    helperText: {
        float: 'right',
    },
    helperTextwrapper: {
        '& .MuiTypography-root': {
            fontSize: '12px',
        },
    },
    contentHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        padding: '16px 0px 16px 16px',
        background: Colors.white[50],
        minHeight: '68px',
        borderRadius: '4px',
        boxShadow:
            ' 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    },
    card: {
        marginBottom: theme.spacing(2),
    },
    cardHeader: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        height: '48px',
        padding: '16px 8px 16px 16px',
    },
    cardHeaderTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    cardContent: {
        padding: 0,
        '&:last-child': {
            paddingBottom: 0,
        },
        '& .BluiInfoListItem-divider': {
            left: 0,
        },
    },
    cardFooter: {
        padding: '16px 8px 16px 16px',
        height: '48px',
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    rightComponentChevron: {
        color: theme.palette.text.secondary,
    },
    categoryName: {
        color: theme.palette.primary.main,
    },
    rootSmallMuiDialogPaper: {
        '& .MuiDialog-paper': {
            maxWidth: '432px',
            width: '432px',
        },
    },
    rootMuiDialogpaper: {
        '& .MuiDialog-paper': {
            maxWidth: '600px',
            width: '600px',
        },
    },
    dialogTitleRoot: {
        padding: '16px',
    },
    dialogTitleDivider: {
        borderBottom: ' 1px solid rgba(66, 78, 84, 0.12)',
        padding: '16px 24px',
    },
    MuiDialogActionsRoot: {
        padding: '24px',
        borderTop: ' 1px solid rgba(66, 78, 84, 0.12)',
    },
    hevronRight: {
        color: Colors.black[300],
    },
    MyPaperStyle: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
    },
    SettingSelectBtn: {
        '& .MuiSelect-select-MuiInputBase-input-MuiFilledInput-input': {
            paddingTop: '18px',
        },
    },

    mainContent1: {
        height: 'calc(100vh - 260px)',
        overflow: 'hidden',
        overflowY: 'visible',
    },
    muiDialogpaper: {
        '& .MuiPaper-root': {
            width: '450px',
            maxWidth: '450px',
        },
    },
    discardButton: {
        background: Colors.red[600],
        color: Colors.white[600],
        '&:hover': {
            backgroundColor: Colors.red[400],
        },
    },
}));
