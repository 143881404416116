export const COMMAND_CONSTANTS: any = {
    CYCLE_DEVICE: {
        successMessage: {
            title: 'Device Cycling Completed',
        },
        loadingMessage: {
            title: 'Cycling',
            subtitle: 'Device cycling initiated.',
        },
    },
    TURN_ON_DEVICE: {
        successMessage: {
            title: 'Device Turn on Completed',
        },
        loadingMessage: {
            title: 'Turn On',
            subtitle: 'Device is turning on',
        },
    },
    TURN_OFF_DEVICE: {
        successMessage: {
            title: 'Device Turn off Completed',
        },
        loadingMessage: {
            title: 'Turn Off',
            subtitle: 'Device is turning off',
        },
    },
    CYCLE_LOAD: {
        successMessage: {
            title: 'Load Cycle Completed',
        },
        loadingMessage: {
            title: 'Cycling Load',
            subtitle: 'Load cycling initiated',
        },
    },
    TURN_ON_LOAD: {
        successMessage: {
            title: 'Load Turn On Completed',
        },
        loadingMessage: {
            title: 'Turning On Load',
            subtitle: 'Load is turning on',
        },
    },
    TURN_OFF_LOAD: {
        successMessage: {
            title: 'Load Turn Off Completed',
        },
        loadingMessage: {
            title: 'Turning Off Load',
            subtitle: 'Load is turning off',
        },
    },
    RUN_TEST: {
        loadingMessage: {
            title: 'Running Test',
            subtitle: 'Test Run command sent',
        },
        successMessage: {
            title: 'Run Test Passed',
            subtitle: 'Test Passed Successfully',
        },
        DoneAndPassed: {
            title: 'Run Test Passed',
            subtitle: 'Test Passed Successfully',
        },
        DoneAndWarning: {
            title: 'Run Test Passed',
            subtitle: 'Test Passed Successfully',
        },
        NoTestInitiated: {
            title: 'Test Did Not Run',
            subtitle: 'There was an issue running test',
        },
        NoTestInitiatedMapped: {
            title: 'Test Did Not Run',
            subtitle: 'There was an issue running test',
        },
        Aborted: {
            title: 'Test Did Not Run',
            subtitle: 'There was an issue running test',
        },
        InProgress: {
            title: 'Running Test',
            subtitle: 'Test Run command sent',
        },
        DoneAndError: {
            title: 'Run Test Failed',
            subtitle: '',
        },
        TimeOut: {
            title: 'Run Test timeout',
            subtitle: 'There was an issue running test',
        },
    },
    MUTE_ALARM: {
        successMessage: {
            title: 'Alarm Muted Successfully',
        },
        loadingMessage: {
            title: 'Mute Alarm',
            subtitle: 'Muting Alarm',
        },
    },
};
