import { Close, FilterListOff, Search } from '@mui/icons-material';
import {
    Box,
    Button,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    InputLabel,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import React from 'react';
import { useStyles } from './styles';
import { StaticDateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { MovingSourceContent } from '../Common/MoveGroupsDevicesModal';
import { useCleanPayload, useGetHierarchyHandlers } from '../../hooks';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { RootState, useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { toFirstLetterUpperCase } from '../../CommonUtils';
import { useGetDocumentTypesQuery, useGetDocumentsMutation } from '@fiji/common/src/features/documents/documentsApi';
import { useParams } from 'react-router-dom';
import { setSelectedGroup } from '@fiji/common/src/features/common/commonSlice';
import { selectHierarchyData } from '@fiji/common/src/features/group/groupSlice';

type DocumentFilterProps = {
    handleIsFilterOpen: (arg0: boolean) => void;
    customDate: any;
    payload: any;
    setPayload: any;
    setCustomDate: any;
    documentTypes?: any;
};

const ApplyFilterBox = ({
    payload,
    customDate,
    groupsHierarchy,
    setIsFilterApplied,
}: {
    payload: any;
    customDate: any;
    groupsHierarchy: any;
    setIsFilterApplied: (props: boolean) => any;
}): JSX.Element => {
    const [cleanPayload] = useCleanPayload();
    const [getDocuments]: any = useGetDocumentsMutation({ fixedCacheKey: 'documents' });
    const { removeUnwantedIds } = useGetHierarchyHandlers({
        isChildrenPreselected: true,
    });
    const submitHandler = async (): Promise<void> => {
        setIsFilterApplied(true);
        const documentPayload = {
            filters: {
                ...payload.filters,
                from: new Date(customDate?.[0])?.getTime() === 0 ? null : new Date(customDate?.[0])?.getTime(),
                to: new Date(customDate?.[1])?.getTime() === 0 ? null : new Date(customDate?.[1])?.getTime(),
            },
        };
        let filteredNodeIds: any;
        if (payload?.filters?.groupsAndDevices) {
            filteredNodeIds = removeUnwantedIds(
                groupsHierarchy?.data,
                payload?.filters?.groupsAndDevices?.groups || [],
                payload?.filters?.groupsAndDevices?.devices || []
            );
        }
        if (filteredNodeIds) {
            documentPayload.filters['deviceIds'] = filteredNodeIds.devices ?? [];
            documentPayload.filters['groupIds'] = filteredNodeIds.groups ?? [];
        }
        delete documentPayload?.filters?.['date'];
        delete documentPayload?.filters?.['groupsAndDevices'];

        const cleanedPayload = cleanPayload(documentPayload?.filters);

        await getDocuments({ ...payload, filters: { ...cleanedPayload } });
    };
    return (
        <Box sx={{ padding: '16px' }}>
            <Button
                variant="contained"
                sx={{
                    backgroundColor: '#007bc1',
                    color: '#fff',
                    width: '100%',
                }}
                onClick={submitHandler}
            >
                Apply Filters
            </Button>
        </Box>
    );
};

const getTransformPayloadData = (payload: any, id: string, data?: any, checked?: boolean): any => {
    const payloadClone = JSON.parse(JSON.stringify(payload?.filters ?? {}));
    if (id !== 'documentTypes') {
        payloadClone[id] = data;
    } else {
        if (!payloadClone?.documentTypes) {
            payloadClone['documentTypes'] = [];
        }
        if (checked) {
            payloadClone?.documentTypes?.push(data);
        } else {
            const deleteIndex = payloadClone?.documentTypes?.indexOf(data);
            payloadClone?.documentTypes?.splice(deleteIndex, 1);
        }
    }
    return payloadClone;
};

export const DocumentFilter = React.memo(
    ({ payload, setPayload, customDate, setCustomDate, ...props }: DocumentFilterProps): JSX.Element => {
        const theme = useTheme();
        const classes = useStyles(theme);
        const { deviceId } = useParams();
        const dispatch = useAppDispatch();
        const currentOrg = useTypedSelector(selectedOrg);

        const [isFilterApplied, setIsFilterApplied] = React.useState<boolean>(false);

        const selectedGroup = useTypedSelector((state: RootState) => state?.common?.groupId);
        const { data: documentTypesList } = useGetDocumentTypesQuery<{
            data: any;
            isLoading: boolean;
            isFetching: boolean;
            isSuccess: boolean;
        }>(deviceId ? { deviceId: deviceId } : { groupId: [selectedGroup?.length ? selectedGroup : currentOrg?.id] }, {
            refetchOnMountOrArgChange: true,
        });

        const hierarchyData = useTypedSelector(selectHierarchyData);

        React.useEffect(() => {
            if (deviceId) {
                setPayload({ ...payload, filters: { ...payload.filters, groupsAndDevices: { devices: [deviceId] } } });
            }
        }, [deviceId]);

        const idsHandler = (ids: any): void => {
            setPayload((prev: any) => ({ ...prev, filters: { ...prev.filters, groupsAndDevices: ids } }));
        };

        const payloadHandler = (id: string, data?: any, checked?: boolean): void => {
            const payloadClone = getTransformPayloadData(payload, id, data, checked);
            setPayload({ filters: payloadClone });
        };

        const resetFilters = (key: string): void => {
            const payloadClone = JSON.parse(JSON.stringify(payload?.filters));
            delete payloadClone[key];
            setPayload({ filters: payloadClone });
            if (key === 'date') {
                setCustomDate([null, null]);
            }
            if (key === 'groupsAndDevices' && selectedGroup) {
                dispatch(setSelectedGroup(''));
            }
        };

        const closeHandler = (): void => {
            if (!isFilterApplied) {
                setPayload({});
            }
            props.handleIsFilterOpen(false);
        };

        const handleResetFilters = (): void => {
            setPayload({});
            setCustomDate([null, null]);
            closeHandler();
        };

        return (
            <Paper
                sx={{
                    width: '450px',
                    position: 'absolute',
                    top: '-47px',
                    right: '-35px',
                    zIndex: '5',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '16px',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton>
                            <Close onClick={closeHandler} />
                        </IconButton>
                        <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: '600' }}>
                            Document Filters
                        </Typography>
                    </Box>
                    <IconButton>
                        <FilterListOff onClick={handleResetFilters} />
                    </IconButton>
                </Box>
                <Divider />
                <Box className={classes.mainContent1}>
                    {payload?.filters && Boolean(Object?.keys(payload?.filters)?.length) && (
                        <Stack sx={{ padding: '16px' }} flexDirection={'row'} alignItems={'center'} flexWrap={'wrap'}>
                            {Object?.keys(payload?.filters)?.map(
                                (key: string) =>
                                    key !== 'deviceIds' &&
                                    key !== 'groupIds' && (
                                        <Chip
                                            key={key}
                                            disabled={Boolean(deviceId && key === 'groupsAndDevices')}
                                            label={toFirstLetterUpperCase(key === 'fileName' ? 'name' : key)}
                                            onDelete={(): void => resetFilters(key)}
                                            variant="outlined"
                                            className="custom-filter-search-btn"
                                        />
                                    )
                            )}
                        </Stack>
                    )}

                    <Divider />
                    <Box sx={{ padding: '16px' }}>
                        <Typography
                            variant="h6"
                            color="primary"
                            sx={{
                                fontSize: '16px',
                                fontWeight: '600',
                                paddingBottom: '20px',
                            }}
                        >
                            File Name
                        </Typography>

                        <Box sx={{ marginBottom: '20px' }}>
                            <FormControl sx={{ width: '100%' }}>
                                <Box sx={{ position: 'relative' }}>
                                    <TextField
                                        variant="outlined"
                                        placeholder="File Name"
                                        sx={{ width: '100%' }}
                                        value={payload?.filters?.fileName ?? ''}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                            payloadHandler('fileName', e.target.value)
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <Search
                                                    style={{
                                                        color: '#777',
                                                    }}
                                                    id="search"
                                                />
                                            ),
                                        }}
                                    />
                                </Box>
                            </FormControl>
                        </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ padding: '16px' }}>
                        <Typography
                            variant="h6"
                            color="primary"
                            sx={{
                                fontSize: '16px',
                                fontWeight: '600',
                                paddingBottom: '20px',
                            }}
                        >
                            Date
                        </Typography>
                        <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <StaticDateRangePicker
                                    disableFuture
                                    value={customDate ?? [null, null]}
                                    onChange={(data: any): void => {
                                        payloadHandler('date', data);
                                        setCustomDate(data);
                                    }}
                                    slotProps={{ actionBar: { actions: [] }, toolbar: { hidden: true } }}
                                    className="date-range-picker"
                                />
                            </LocalizationProvider>
                        </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ padding: '16px' }}>
                        <Typography
                            variant="h6"
                            color="primary"
                            sx={{
                                fontSize: '16px',
                                fontWeight: '600',
                                paddingBottom: '20px',
                            }}
                        >
                            Document Type
                        </Typography>
                        <Box>
                            <FormGroup>
                                {documentTypesList?.data?.map((type: any) => (
                                    <FormControlLabel
                                        key={type?.categoryId}
                                        control={
                                            <Checkbox
                                                checked={payload?.documentTypes?.includes(type?.categoryId)}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                                    payloadHandler(
                                                        'documentTypes',
                                                        type?.categoryId,
                                                        e?.target?.checked
                                                    )
                                                }
                                            />
                                        }
                                        label={type?.categoryName}
                                    />
                                ))}
                            </FormGroup>
                        </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ padding: '16px' }}>
                        <Typography
                            variant="h6"
                            color="primary"
                            sx={{
                                fontSize: '16px',
                                fontWeight: '600',
                                paddingBottom: '20px',
                            }}
                        >
                            Group(s) & Devices
                        </Typography>
                        <Box>
                            <FormControl variant="filled" sx={{ m: 1, minWidth: 120, width: '100%' }}>
                                <InputLabel id="demo-simple-select-filled-label">Group(s)</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    disabled={window.location.pathname.includes('details')}
                                    id="demo-simple-select-filled"
                                >
                                    <MovingSourceContent
                                        isOpen={true}
                                        selectedSource={
                                            payload?.filters?.groupsAndDevices ?? {
                                                devices: [],
                                                groups: [],
                                            }
                                        }
                                        onNodeSelection={idsHandler}
                                        showDefaultGroup={true}
                                    />
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <ApplyFilterBox
                    payload={payload}
                    customDate={customDate}
                    groupsHierarchy={hierarchyData?.data?.data}
                    setIsFilterApplied={setIsFilterApplied}
                />
            </Paper>
        );
    }
);
