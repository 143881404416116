import React from 'react';
import { useStyles } from './styles';
import { useTheme } from '@mui/styles';
import { Dialog, DialogTitle, Typography, DialogActions, DialogContent, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router';

/**
 * The DiscardModalProps type is used in a TypeScript React component and includes properties for
 * visibility and a modal handler function.
 * @property {boolean} isVisible - The `isVisible` property is a boolean value that determines whether
 * the discard modal is visible or not. If `isVisible` is `true`, the modal is visible. If `isVisible`
 * is `false`, the modal is hidden.
 * @property modalHandler - The `modalHandler` property is a function that takes a boolean parameter
 * `action` and does something with it. It is used to handle the action performed on the modal, such as
 * closing it or confirming a discard action.
 */
type DiscardModalProps = {
    isVisible: boolean;
    modalHandler: (action: boolean) => void;
    state?: any;
};

/**
 * The `DiscardModal` function is a TypeScript React component that renders a dialog box with a title,
 * message, and two buttons for canceling or discarding changes.
 * @param {DiscardModalProps}  - - `isVisible`: a boolean value indicating whether the modal is visible
 * or not.
 * @returns The code is returning a JSX element, specifically a `Dialog` component with various child
 * components such as `DialogTitle`, `DialogContent`, and `DialogActions`.
 */
export const DiscardModal = ({ isVisible, modalHandler, state }: DiscardModalProps): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const navigate = useNavigate();

    return (
        <Dialog
            open={isVisible}
            onClose={(): void => modalHandler(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.muiDialogpaper}
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant={'h6'}> {'Discard Changes?'}</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant={'body1'} mb={1}>
                    {'This action cannot be undone.'}
                </Typography>
            </DialogContent>
            <DialogActions className={classes.MuiDialogActionsRoot}>
                <Grid container>
                    <Grid item xs={6}>
                        <Button variant={'outlined'} onClick={(): void => modalHandler(false)}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6} textAlign={'end'}>
                        <Button
                            variant={'contained'}
                            className={classes.discardButton}
                            onClick={(): void => navigate('/devices', { state })}
                        >
                            Discard
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
