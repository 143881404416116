import { Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AutomationTable } from './';
import { Details } from './Details';
import { StepperModal } from '../../components/StepperModal';
import { SelectAction } from './SelectAction';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';

import {
    useCreateAutomationMutation,
    useGetAutomationByIdQuery,
    useGetAutomationsWithFilterQuery,
    useUpdateAutomationMutation,
} from '@fiji/common/src/features/automation/automationApi';

import { RefetchConfigOptions } from '@reduxjs/toolkit/dist/query/core/apiState';
import { addAutomationModal, selectAutomationModal } from '@fiji/common/src/features/automation/automationSlice';
import { AutomationsType } from '@fiji/common/src/types';
import { SelectDeviceType, SelectDevices, SelectEvents, SelectGroups } from '../Common';
import { useGetHierarchyHandlers } from '../../hooks';
import commonSlice from '@fiji/common/src/features/common/commonSlice';
import { useGetPaginatedDeviceListQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { selectHierarchyData } from '@fiji/common/src/features/group/groupSlice';

const initialState = {
    groupIds: [],
    deviceTypeIds: [],
    eventIds: [],
    deviceIds: [],
    actionId: '',
    delay: '30',
    name: '',
};

export const Automations = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const automationModal = useTypedSelector(selectAutomationModal);
    const { data: devicesData, isSuccess: isDeviceSuccess } = useGetPaginatedDeviceListQuery<{
        data: any;
        isSuccess: boolean;
    }>({
        size: 10,
        page: 0,
        filters: {},
    });

    const [currentStep, setCurrentStep] = React.useState<string>('deviceType');
    const [payload, setPayload] = useState(initialState);
    const [automationId, setAutomationId] = useState('');
    const [activeActionName, setActiveActionName] = useState('');
    const [automationFilter, setAutomationFilter] = useState<any>({
        size: 10,
        page: 0,
        sort: {
            key: 'name',
            sortType: 'ASC',
        },
        filters: {},
    });

    const [createAutomation, { isLoading: isAutomationCreated }] = useCreateAutomationMutation();
    const [updateAutomation, { isLoading: isAutomationEdited }] = useUpdateAutomationMutation();

    const {
        data: allAutomation,
        isLoading,
        isFetching,
    } = useGetAutomationsWithFilterQuery<{
        data: AutomationsType;
        refetch: RefetchConfigOptions;
        isLoading: boolean;
        isFetching: boolean;
    }>(automationFilter, { refetchOnMountOrArgChange: true });

    const { data: automation, isSuccess } = useGetAutomationByIdQuery<{
        data: any;
        isSuccess: boolean;
        refetch: RefetchConfigOptions;
        isLoading: boolean;
    }>(automationId, {
        skip: !automationId,
    });

    const hierarchyData = useTypedSelector(selectHierarchyData);

    const { removeUnwantedIds } = useGetHierarchyHandlers({
        isChildrenPreselected: true,
    });

    useEffect(
        () => () => {
            closeModal();
            setPayload(initialState);
        },
        []
    );

    /* The `useEffect` hook is used to perform side effects in a React component. In this case, the
    effect is triggered whenever the value of `automationModal?.isOpen` changes. */
    useEffect(() => {
        if (!automationModal?.isOpen) {
            setPayload(initialState);
        }
    }, [automationModal?.isOpen]);

    /* The `useEffect` hook is used to perform side effects in a React component. In this case, the
    effect is triggered whenever the values of `automation` and `automationId` change. */
    useEffect(() => {
        if (automation && automationId) {
            const { actionId, deviceIds, groupIds, eventIds, name, delay, deviceTypeIds } = automation.data;
            setPayload({
                groupIds: groupIds || [],
                actionId,
                deviceIds: deviceIds || [],
                deviceTypeIds: deviceTypeIds || [],
                eventIds: eventIds || [],
                delay,
                name,
            });
        }
    }, [automation, automationId]);

    /**
     * The function closeModal resets some state variables and dispatches an action to close a modal.
     */
    const closeModal = (): void => {
        setCurrentStep('deviceType');
        setPayload(initialState);
        dispatch(addAutomationModal({ isOpen: false }));
        setAutomationId('');
    };

    const handleAutomationFilters = (data: any): void => {
        if (JSON.stringify(data) !== JSON.stringify(automationFilter.filters))
            setAutomationFilter((prev: any) => ({ ...prev, filters: { ...data } }));
    };

    /**
     * The submitHandler function updates or creates an automation based on the provided automationId
     * and payload.
     */
    const submitHandler = async (): Promise<void> => {
        const filteredGroupIds = removeUnwantedIds(hierarchyData?.data?.data?.data, payload?.groupIds, []);
        if (automationId && automationId !== undefined) {
            const { error }: any = await updateAutomation({
                automationId,
                body: { ...payload, groupIds: filteredGroupIds?.groups, delay: parseInt(payload?.delay) },
            });
            if (!error) {
                dispatch(
                    commonSlice.actions.setToastifyContent({
                        isOpen: true,
                        message: 'Automation edited successfully',
                    })
                );
                closeModal();
                setAutomationId('');
            }
        } else {
            const { error }: any = await createAutomation({
                ...payload,
                groupIds: filteredGroupIds?.groups,
                delay: parseInt(payload?.delay),
            });
            if (!error) {
                dispatch(
                    commonSlice.actions.setToastifyContent({
                        isOpen: true,
                        message: 'Automation created successfully',
                    })
                );
                closeModal();
                setAutomationId('');
                setPayload(initialState);
            }
        }
    };

    /**
     * The function `getCurrentStep` sets the current step to the provided value.
     * @param {string} step - The parameter "step" is a string that represents the current step.
     */
    const getCurrentStep = (step: string): void => {
        setCurrentStep(step);
    };

    /**
     * The function "editAutomation" sets the automation ID and dispatches an action to open the
     * automation modal.
     * @param {any} id - The `id` parameter is of type `any`, which means it can accept any data type.
     * It is used to set the `automationId` state variable.
     */
    const editAutomation = (id: any): void => {
        setAutomationId(id);
        dispatch(addAutomationModal({ isOpen: true }));
    };

    /**
     * The function `handleStepDisable` returns a boolean value based on the current step and the
     * presence of certain properties in the `payload` object.
     * @returns The function `handleStepDisable` returns a boolean value.
     */
    const handleStepDisable = (): boolean => {
        switch (currentStep) {
            case 'groups':
                return Boolean(!payload?.groupIds?.length);
            case 'action':
                return Boolean(!payload?.actionId?.length);
            case 'deviceType':
                return Boolean(!payload?.deviceTypeIds?.length);
            case 'devices':
                return Boolean(!payload?.deviceIds?.length);
            case 'events':
                return Boolean(!payload?.eventIds?.length);
            case 'details':
                return Boolean(!payload?.name?.length);
            default:
                return false;
        }
    };

    /* The `modalContent` constant is an array of objects that define the content of each step in the
    stepper modal. Each object represents a step and contains properties such as `key`, `title`,
    `header`, `isEnabled`, and `component`. */
    const modalContent = React.useMemo(
        () => [
            {
                key: 'deviceType',
                title: isSuccess ? 'Edit Automation' : 'New Automation',
                header: 'Select Device Types',
                component: <SelectDeviceType payload={payload} setPayload={setPayload} />,
            },
            {
                key: 'action',
                title: isSuccess ? 'Edit Automation' : 'New Automation',
                header: 'Select Action',
                component: (
                    <SelectAction
                        payload={payload}
                        setPayload={setPayload}
                        onChangeActionName={(actionName: string): void => setActiveActionName(actionName)}
                    />
                ),
            },
            {
                key: 'groups',
                title: isSuccess ? 'Edit Automation' : `New Automation / ${activeActionName}`,
                header: 'Select Groups',
                component: <SelectGroups payload={payload} setPayload={setPayload} />,
            },
            {
                key: 'devices',
                title: isSuccess ? 'Edit Automation' : `New Automation / ${activeActionName}`,

                header: 'Select Devices',
                component: <SelectDevices payload={payload} setPayload={setPayload} total={devicesData?.total} />,
            },
            {
                key: 'events',
                title: isSuccess ? 'Edit Automation' : `New Automation / ${activeActionName}`,

                header: 'Select Trigger Events',
                component: <SelectEvents payload={payload} setPayload={setPayload} />,
            },
            {
                key: 'details',
                title: isSuccess ? 'Edit Automation' : `New Automation / ${activeActionName}`,
                header: 'Details',
                component: <Details payload={payload} setPayload={setPayload} />,
                nextButtonLabel: automationId ? 'Update Automation' : 'Add Automation',
            },
        ],
        [payload, isSuccess, isDeviceSuccess]
    );

    return (
        <>
            <Paper sx={{ padding: '16px', boxShadow: 'none', backgroundColor: '#f7f8f8' }}>
                <AutomationTable
                    data={allAutomation?.data}
                    editHandler={editAutomation}
                    filterHandler={setAutomationFilter}
                    filter={automationFilter}
                    handleAutomationFilters={handleAutomationFilters}
                    isLoading={isFetching || isLoading}
                />
            </Paper>
            <StepperModal
                isLoading={isAutomationCreated || isAutomationEdited}
                modalContent={modalContent}
                closeModal={closeModal}
                closeModalOnFinish={false}
                isOpen={automationModal?.isOpen}
                submitHandler={submitHandler}
                getCurrentStep={getCurrentStep}
                isDisabled={handleStepDisable()}
            />
        </>
    );
};
