import React, { useEffect } from 'react';
import Hierarchy from '../../../components/Hierarchy';
import { DestinationNode } from './';
import { Box } from '@mui/material';
import { Ups } from '@brightlayer-ui/icons-mui';
import * as Colors from '@brightlayer-ui/colors';

export const MovingPreviewContent = ({
    destinationGroup,
    previewHierarchy,
}: {
    destinationGroup: DestinationNode;
    previewHierarchy: any;
}): JSX.Element => {
    const [expandedNodes, setExpandedNodes] = React.useState<string[]>([]);

    const onExpandNodes = (nodeIds: string[]): void => {
        setExpandedNodes(nodeIds);
    };

    useEffect(() => {
        if (destinationGroup?.id) setExpandedNodes([destinationGroup?.id]);
    }, [destinationGroup]);

    return (
        <>
            <Hierarchy
                hierarchyData={previewHierarchy}
                onChangeTreeItem={(): void => {}}
                selectedNodes={''}
                expandedNodes={expandedNodes}
                isLoading={!previewHierarchy?.length}
                onExpandNodes={onExpandNodes}
                customIterator={['devices', 'children']}
                iconKey={['devices']}
                renderIcon={(): JSX.Element => <Box component={Ups} sx={{ color: Colors.gray[500], ml: 1, mr: 1 }} />}
                radioSelection
                disableSelect
            />
        </>
    );
};
