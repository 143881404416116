import React from 'react';
import { Dialog, DialogTitle, Avatar, Typography, Box, DialogActions, Button } from '@mui/material';
import { useStyles } from '../../ManageDevices/styles';
import { useTheme } from '@mui/material/styles';
import { Done } from '@mui/icons-material';
import { useGetHierarchyHandlers } from '../../../hooks';

export const RelocationCompleteModal = (props: any): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const pluralize = (word: string, count: number): string => (count === 1 ? word : `${word}s`);

    const { groups, devices } = props?.selectedSource || {};

    const { findGroupOrDeviceById } = useGetHierarchyHandlers({ isChildrenPreselected: true });

    const getMovedMessage = (): string => {
        if (groups?.length > 0) {
            const groupNames = groups
                .map((group: string) => findGroupOrDeviceById(props.groupsHierarchy, group)?.name)
                .join(', ');

            const groupMessage = `${groups?.length} ${pluralize(
                'Group',
                groups?.length
            )} (${groupNames}), their children`;

            const deviceMessage =
                devices?.length > 0 ? ` and ${devices?.length} ${pluralize('device', devices?.length)}` : '';
            return `${groupMessage}${deviceMessage} have been moved to ${props.destinationGroup}.`;
        } else if (devices?.length > 0) {
            return `${devices?.length} ${pluralize('device', devices?.length)} have been moved to ${
                props.destinationGroup
            }.`;
        }

        return '';
    };

    return (
        props.isOpen && (
            <Dialog
                id="successfullyRelocateModal"
                open={props.isOpen}
                onClose={(): void => props.modalHandler(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.muiDialogpaper}
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="body2" className="modal-greyhead-text modal-header-title">
                        Move Group
                    </Typography>
                    <Typography variant="h6">
                        {Boolean(groups?.length) && pluralize('Group', groups.length)}
                        {Boolean(groups?.length) && Boolean(devices?.length) && ' & '}
                        {Boolean(devices?.length) && pluralize('Device', devices.length)} Moved Successfully
                    </Typography>
                    <Box className={classes.avatarBox}>
                        <Avatar className={classes.avatarIcon}>
                            <Done color="primary" />
                        </Avatar>
                    </Box>
                    <Typography variant={'body1'} mb={2}>
                        {getMovedMessage()}
                    </Typography>
                </DialogTitle>
                <DialogActions className={classes.MuiDialogActionsRoot}>
                    <Button variant={'contained'} onClick={(): void => props.modalHandler(false)}>
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        )
    );
};
