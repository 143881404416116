/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useCallback, useEffect, useState } from 'react';
import {
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    Avatar,
    Button,
    Tooltip,
    CircularProgress,
} from '@mui/material';
import { Done } from '@mui/icons-material';
import { InfoListItem, ListItemTag } from '@brightlayer-ui/react-components';
import { useStyles } from './style';

import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { UserOrgs } from './ManageOrganization';
import clsx from 'clsx';
import { getUserInitials } from '../../CommonUtils';
import { useConfirm, useRBAC } from '../../hooks';
import * as Colors from '@brightlayer-ui/colors';
import ConfirmModal from '../../components/ConfirmModal';
import { RefetchConfigOptions } from '@reduxjs/toolkit/dist/query/core/apiState';
import {
    useGetPaginatedOrgQuery,
    useSwitchOrgMutation,
    useWithdrawOrgMutation,
} from '@fiji/common/src/features/orgManagement/orgApi';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { selectSwitchOrgModal, switchOrgModal } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import { setSelectedGroup } from '@fiji/common/src/features/common/commonSlice';

const SwitchOrganizationModal = (): JSX.Element => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const selectedOrg = useTypedSelector(selectCurrentPermission);
    const showSwitchOrganizationModal = useTypedSelector<boolean>(selectSwitchOrgModal);

    const rolePermissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(rolePermissions);
    const canWithdrawOrganization = hasPermission('withdraw-join-organization');

    const [isWithdrawingOrg, setIsWithdrawingOrg] = useState<string[]>([]);

    const [isSwitchingOrganization, setIsSwitchingOrganization] = useState(false);

    const [orgFilter, setOrgFilter] = React.useState<{ size: number; page: number; pullRefresh?: boolean }>({
        page: 0,
        size: 25,
    });

    const [switchOrganization] = useSwitchOrgMutation();
    const [withdrawOrganization] = useWithdrawOrgMutation();

    const {
        data: associatedOrganizations,
        refetch: refreshAssociatedOrgs,
        isLoading,
        isFetching,
    } = useGetPaginatedOrgQuery<{
        data: any;
        isLoading: boolean;
        isFetching: boolean;
        refetch: RefetchConfigOptions;
    }>(orgFilter, { skip: !showSwitchOrganizationModal, refetchOnMountOrArgChange: true });

    useEffect(() => {
        if (!showSwitchOrganizationModal) {
            setOrgFilter({ page: 0, size: 25 });
        }
    }, [showSwitchOrganizationModal]);

    /**
     * The function handleCloseSwitchOrganizationModal dispatches an action to close a switch
     * organization modal.
     */
    const handleCloseSwitchOrganizationModal = (): void => {
        dispatch(switchOrgModal(false));
    };

    /* The `handleSwitchOrganization` function is an asynchronous callback function that is triggered
    when a user clicks on a specific organization in the switch organization modal. */
    const handleSwitchOrganization = useCallback(
        async (e: any): Promise<void> => {
            const orgId = e.currentTarget.id;
            setIsSwitchingOrganization(true);
            dispatch(setSelectedGroup(''));
            const { data }: any = await switchOrganization({ orgId });
            setIsSwitchingOrganization(false);
            if (data) {
                handleCloseSwitchOrganizationModal();
                await refreshAssociatedOrgs();
                if (window.location.pathname.includes('device/details')) navigate('/');
            }
        },
        [associatedOrganizations]
    );

    /**
     * The function `handleWithdrawOrganization` is an asynchronous function that handles the
     * withdrawal of an organization and refreshes the associated organizations if the withdrawal is
     * successful.
     * @param {any} e - The parameter `e` is an event object that is passed to the function when it is
     * triggered by an event. It is commonly used in event handlers to access information about the
     * event that occurred, such as the target element that triggered the event.
     */
    const handleWithdrawOrganization = async (e: any): Promise<void> => {
        const orgId = e.target.name;
        setIsWithdrawingOrg((prevState) => [...prevState, orgId]);
        const { error: withdrawError }: any = await withdrawOrganization({ orgIds: [orgId] });
        setIsWithdrawingOrg((prevState) => prevState.filter((id) => id !== orgId));
        if (!withdrawError) {
            setOrgFilter({ page: 0, size: 25, pullRefresh: true });
        }
    };

    const clickHandler = (e: any, org: any): void => {
        if (org?.status === 'ACTIVE') {
            if (org?.selected && window.location.pathname !== '/') {
                handleCloseSwitchOrganizationModal();
                return;
            }
            if (!org?.selected) handleSwitchOrganization(e) as any;
        }
    };

    const {
        isVisible: isConfirmModalOpen,
        onClick: openConfirmModal,
        onCancel: closeConfirmModal,
        onConfirm: confirmSuccess,
    } = useConfirm(handleWithdrawOrganization as any);

    return (
        <>
            <Dialog
                open={showSwitchOrganizationModal}
                onClose={handleCloseSwitchOrganizationModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.muiDialogpaper}
                sx={isSwitchingOrganization ? { pointerEvents: 'none' } : { pointerEvents: 'all' }}
            >
                {isSwitchingOrganization || isLoading ? (
                    <>
                        <DialogContent sx={{ height: '225px' }}>
                            <Loader size={60} />
                        </DialogContent>
                    </>
                ) : (
                    <>
                        <DialogTitle id="alert-dialog-title">
                            <Typography variant={'h6'} className={classes.sectionHeader}>
                                {'Switch Organization'}
                            </Typography>
                        </DialogTitle>
                        <DialogContent className={classes.listWrapper}>
                            <List sx={{ p: 0 }}>
                                {Boolean(associatedOrganizations?.data?.records?.length) &&
                                    associatedOrganizations?.data?.records?.map(
                                        (org: UserOrgs): JSX.Element => (
                                            <InfoListItem
                                                key={org.id}
                                                className={`${clsx({
                                                    [classes.listItem]: true,
                                                    [classes.listItemActive]: selectedOrg?.id === org.id,
                                                    'text-truncate': true,
                                                })} ${org?.selected ? 'selected-primary-blue-highlight-color' : ''}`}
                                                data-testid="infoListItem"
                                                data-cy={'list-content'}
                                                icon={
                                                    <Avatar
                                                        alt={org?.name}
                                                        src={org?.logo}
                                                        sx={{ background: 'transparent' }}
                                                    >
                                                        {org?.name && getUserInitials(org?.name)}
                                                    </Avatar>
                                                }
                                                subtitle={`${org.groupCount ?? 0} Groups, ${
                                                    org.deviceCount ?? 0
                                                } Devices`}
                                                title={
                                                    <Tooltip title={org.name} arrow>
                                                        <span>{org.name}</span>
                                                    </Tooltip>
                                                }
                                                statusColor={Colors.gray[50]}
                                                avatar
                                                iconAlign="center"
                                                divider={'partial'}
                                                hidePadding
                                                id={org.id}
                                                onClick={(e: any): void => {
                                                    clickHandler(e, org);
                                                }}
                                                {...(org?.selected && { secondaryAction: <Done /> })}
                                                {...(org?.status === 'PENDING' &&
                                                    canWithdrawOrganization && {
                                                        secondaryAction: (
                                                            <Button
                                                                name={org?.id}
                                                                variant="outlined"
                                                                onClick={openConfirmModal}
                                                                {...(isWithdrawingOrg.includes(org?.id) && {
                                                                    startIcon: (
                                                                        <CircularProgress color="inherit" size={20} />
                                                                    ),
                                                                })}
                                                            >
                                                                {!isWithdrawingOrg.includes(org?.id) &&
                                                                    'Cancel Request'}
                                                            </Button>
                                                        ),
                                                    })}
                                                {...(org.status === 'INVITED' && {
                                                    secondaryAction: (
                                                        <ListItemTag
                                                            style={{ marginLeft: '20px' }}
                                                            label={'INVITED'}
                                                            backgroundColor={'#0088F2 !important'}
                                                            color={'#E0F1FD'}
                                                        />
                                                    ),
                                                })}
                                            />
                                        )
                                    )}
                            </List>
                            {orgFilter?.page + 1 <
                                Math.ceil(associatedOrganizations?.data?.total / orgFilter?.size) && (
                                <List>
                                    <InfoListItem
                                        className={`${clsx({
                                            [classes.listItem]: true,
                                        })}`}
                                        data-testid="infoListItem"
                                        data-cy={'list-content'}
                                        title={
                                            !isFetching && (
                                                <Typography sx={{ color: '#007bc1', fontWeight: 'bold' }}>
                                                    Show More...
                                                </Typography>
                                            )
                                        }
                                        avatar
                                        iconAlign="center"
                                        hidePadding
                                        sx={{ textAlign: 'center' }}
                                        onClick={(): void =>
                                            setOrgFilter((prev) => ({ size: prev.size, page: prev.page + 1 }))
                                        }
                                    />
                                    {isFetching && <Loader size={30} />}
                                </List>
                            )}
                        </DialogContent>
                    </>
                )}
            </Dialog>
            <ConfirmModal
                key={'ugs8djsd3'}
                confirmButtonText={'Cancel Request'}
                closeHandler={closeConfirmModal}
                confirmClick={confirmSuccess}
                isVisible={isConfirmModalOpen}
                wrapperClass={classes.muiDialogpaper}
                actionClass={classes.MuiDialogActionsRoot}
                titleClass={classes.MuiDialogTitleRoot}
                description={<>Your request will be withdrawn from joining organization</>}
                headingText={`Cancel Request ?`}
            />
        </>
    );
};

export default SwitchOrganizationModal;
