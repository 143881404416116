import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    ContainerWrapper: {},
    contentBody: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    card: {
        marginBottom: theme.spacing(2),
    },
    cardHeader: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        height: '48px',
        padding: '16px 8px 16px 16px',
    },
    cardHeaderTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    cardContent: {
        padding: 0,
        '&:last-child': {
            paddingBottom: 0,
        },
    },
    cardFooter: {
        padding: '16px 8px 16px 16px',
        height: '48px',
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    rightComponentChevron: {
        color: theme.palette.text.secondary,
    },
    categoryName: {
        color: theme.palette.primary.main,
    },
    searchTextField: {
        '& .MuiInputBase-root': {
            borderRadius: '5px',
        },
    },
    accordionRoot: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:before': {
            display: 'none',
        },
        '&.Mui-expanded': {
            marginBottom: theme.spacing(2),
            border: 'none',
        },
        '& .MuiAccordionSummary-root': {
            height: theme.spacing(7),
            minHeight: theme.spacing(7),
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '100%',
            boxShadow: 'none',
            borderRadius: 0,
            '&:before': {
                display: 'none',
            },
            '& .MuiAccordionSummary-root': {
                height: theme.spacing(7),
                minHeight: theme.spacing(7),
                '&.Mui-expanded': {
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    margin: 0,
                },
            },
        },
    },
    accordionSummary: {
        '& .MuiAccordionSummary-content': {
            alignItems: 'center',
        },
    },
    accordionDetails: {
        padding: theme.spacing(0),
    },
    infoListItem: {
        '& .MuiListItemAvatar-root': {
            '& .BluiInfoListItem-icon': {
                marginRight: theme.spacing(0),
                width: '32px',
            },
        },
    },
    rootMuiDialogpaper: {
        '& .MuiDialog-paper': {
            maxWidth: '450px',
            width: '450px',
        },
    },
    dialogTitleRoot: {
        padding: '16px',
    },
    dialogTitleDivider: {
        borderBottom: ' 1px solid rgba(66, 78, 84, 0.12)',
        padding: '16px 24px',
    },
    MuiDialogActionsRoot: {
        padding: '24px',
        borderTop: ' 1px solid rgba(66, 78, 84, 0.12)',
    },
}));
