/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react';
import { Box, Container, Button, Card, Stack, IconButton } from '@mui/material';
import {
    Add,
    Create,
    Delete,
    KeyboardDoubleArrowDown,
    KeyboardDoubleArrowUp,
    MoreVert,
    MoveUp,
    Workspaces,
} from '@mui/icons-material';
import * as Colors from '@brightlayer-ui/colors';
import { useTheme } from '@mui/material/styles';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';

import Loader from '../../components/Loader';
import { useNavigate } from 'react-router';
import { EmptyState, UserMenu, UserMenuItem } from '@brightlayer-ui/react-components';
import Hierarchy from '../../components/Hierarchy';

import clsx from 'clsx';

import { useRBAC } from '../../hooks';
import { useStyles } from './styles';
import {
    addGroupModal,
    deleteGroupModal,
    moveGroupsDevicesModal,
    selectDeleteGroupModal,
    selectHierarchyData,
} from '@fiji/common/src/features/group/groupSlice';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { GroupModal } from '@fiji/common/src/types';
import { api } from '@fiji/common/src/app/api/baseApi';

export const GroupManagement = (): JSX.Element => {
    const [expandedNodes, setExpandedNodes] = useState<string[]>([]);

    const rolePermissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(rolePermissions);
    const canCreateGroup = hasPermission('create-group');
    const canDeleteGroup = hasPermission('delete-group');
    const canUpdateGroup = hasPermission('update-group');
    const canMoveGroup = hasPermission('move-group');

    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isGroupEmpty, setIsGroupEmpty] = useState(false);

    const deleteModalState = useTypedSelector<GroupModal>(selectDeleteGroupModal);
    const [isWatchingDeleteGroup, setIsWatchingDeleteGroup] = useState(false);

    const hierarchyData = useTypedSelector(selectHierarchyData);

    React.useEffect(() => {
        if (deleteModalState?.isOpen === false && isWatchingDeleteGroup) {
            dispatch((api as any).endpoints.getGroupsHierarchy.initiate(null));
        }
    }, [deleteModalState]);

    /**
     * The function `handleOpenAddGroupModal` dispatches an action to open a modal for adding a group,
     * with an optional group ID parameter.
     * @param {string} [groupId] - The `groupId` parameter is an optional string that represents the ID
     * of a group.
     */
    const handleOpenAddGroupModal = (groupId?: string): any =>
        dispatch(addGroupModal({ isOpen: true, groupId: groupId ?? '' }));

    /**
     * The function `handleOpenDeleteGroupModal` opens a delete group modal and passes the group ID as
     * a parameter.
     * @param {string} groupId - The `groupId` parameter is a string that represents the unique
     * identifier of a group.
     */
    const handleOpenDeleteGroupModal = (groupId: string): void => {
        dispatch(deleteGroupModal({ isOpen: true, groupId }));
        setIsWatchingDeleteGroup(true);
    };

    /**
     * The function `handleOpenMoveGroupModal` opens a modal for moving a group with the specified
     * `groupId`.
     * @param {string} groupId - The `groupId` parameter is a string that represents the unique
     * identifier of a group.
     */
    const handleOpenMoveGroupModal = (groupId: string): void => {
        dispatch(moveGroupsDevicesModal({ isOpen: true, groupId, deviceId: '' }));
    };

    /* The below code is defining a function called `getMenuItems` that takes an `id` parameter and
    returns an array of `UserMenuItem` objects. */
    const getMenuItems = (id: string, expandedItems: []): UserMenuItem[] => {
        const menuItems = [
            {
                title: 'Expand All',
                icon: <KeyboardDoubleArrowDown className={classes.menuItem} />,
                onClick: (): void => handleExpand(id, expandedItems),
            },
            {
                title: 'Collapse All',
                icon: <KeyboardDoubleArrowUp className={classes.menuItem} />,
                onClick: (): void => handleCollapse(id, expandedItems),
                divider: true,
            },
        ];
        if (canMoveGroup) {
            menuItems.push({
                title: 'Move Group',
                icon: <MoveUp className={classes.menuItem} />,
                onClick: (): void => handleOpenMoveGroupModal(id),
            });
        }
        if (canUpdateGroup) {
            menuItems.push({
                title: 'Edit Group',
                icon: <Create className={classes.menuItem} />,
                onClick: (): void => navigate(`/group/edit/${id}`),
            });
        }
        if (canCreateGroup) {
            menuItems.push({
                title: 'Add Group',
                icon: <Add className={classes.menuItem} />,
                onClick: (): void => handleOpenAddGroupModal(id),
                divider: true,
            });
        }
        if (canDeleteGroup) {
            menuItems.push({
                title: 'Delete Group',
                icon: <Delete className={classes.menuItem} />,
                onClick: (): void => handleOpenDeleteGroupModal(id),
            });
        }
        return menuItems;
    };

    /**
     * The function `findObjectById` recursively searches for an object with a specific id within a
     * nested object structure.
     * @param {any} obj - The `obj` parameter is the object that you want to search for. It can be any
     * JavaScript object.
     * @param {string} id - The `id` parameter is a string that represents the unique identifier of the
     * object we are searching for.
     * @returns The function `findObjectById` returns the object with the specified `id` if it is found
     * within the given `obj`. If the object is not found, it returns `null`.
     */
    const findObjectById = (obj: any, id: string): any => {
        if (obj.id === id) {
            return obj;
        }

        for (const key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                const result = findObjectById(obj[key], id);

                if (result !== null) {
                    return result;
                }
            }
        }

        return null;
    };

    /**
     * The function removes specific ids from an array of ids.
     * @param {string[]} idsArray - An array of strings containing the IDs to be filtered.
     * @param {string[]} idsToRemove - An array of string values representing the IDs that need to be
     * removed from the idsArray.
     */
    const removeIdsFromArray = (idsArray: string[], idsToRemove: string[]): any =>
        idsArray.filter((id) => !idsToRemove.includes(id));

    /**
     * The function `getTreeNodes` takes an `id` as input and returns an array of string `treeNodes`
     * that contains the ids of all the tree nodes in the hierarchy starting from the given `id`.
     * @param {string} id - The `id` parameter is a string that represents the ID of a tree node.
     * @returns The function `getTreeNodes` returns an array of strings.
     */
    const getTreeNodes = (id: string): string[] => {
        const treeNodes: string[] = [];

        const createTreeNodes = (treeItems: any[], treeId: string, loop: boolean): void => {
            treeItems.forEach((item: any) => {
                treeNodes.push(item.id);
                if (item.children?.length && loop) createTreeNodes(item.children, treeId, true);
            });
        };
        createTreeNodes([findObjectById(hierarchyData?.data?.data?.data[0], id)], id, true);

        return treeNodes;
    };

    /**
     * The handleExpand function adds tree nodes to the expandedNodes state.
     * @param {string} id - The `id` parameter is a string that represents the identifier of a tree
     * node.
     */
    const handleExpand = (id: string, expandedItems: []): void => {
        setExpandedNodes((oldExpanded) => {
            const treeData = [...oldExpanded, ...expandedItems, ...getTreeNodes(id)];
            return treeData?.filter((treeId, index) => treeData.indexOf(treeId) === index);
        });
    };

    /**
     * The handleCollapse function is used to remove specific ids from an array of expanded nodes.
     * @param {string} id - The `id` parameter is a string that represents the identifier of a tree
     * node.
     */
    const handleCollapse = (id: string, expandedItems: []): void => {
        setExpandedNodes((oldExpanded) => {
            const treeData = removeIdsFromArray([...oldExpanded, ...expandedItems], getTreeNodes(id));
            return treeData?.filter((treeId: string, index: number) => treeData.indexOf(treeId) === index);
        });
    };

    /* The below code is defining a function called `getGroupHierarchyActions` that takes an `id`
    parameter and returns a JSX element. */
    const getGroupHierarchyActions = (id: string, tree: any, expandedItems: []): JSX.Element => (
        <Stack direction={'row'} className={classes.buttonGroup}>
            <IconButton>
                <KeyboardDoubleArrowUp onClick={(): void => handleCollapse(id, expandedItems)} />
            </IconButton>
            <Box mr={4}>
                <IconButton>
                    <KeyboardDoubleArrowDown onClick={(): void => handleExpand(id, expandedItems)} />
                </IconButton>
            </Box>
            {canCreateGroup && (
                <IconButton onClick={(): void => handleOpenAddGroupModal(id)}>
                    <Add />
                </IconButton>
            )}
            {canUpdateGroup && (
                <IconButton onClick={(): void => navigate(`/group/edit/${id}`)}>
                    <Create />
                </IconButton>
            )}
            {canDeleteGroup && (
                <IconButton onClick={(): void => handleOpenDeleteGroupModal(id)}>
                    <Delete />
                </IconButton>
            )}
            <UserMenu
                id="group"
                avatar={
                    <IconButton size="small">
                        <MoreVert />
                    </IconButton>
                }
                MenuProps={{
                    sx: {
                        '& .MuiMenu-list ': {
                            minWidth: '260px',
                        },
                    },
                }}
                menuGroups={[
                    {
                        items: getMenuItems(id, expandedItems),
                    },
                ]}
            />
        </Stack>
    );

    if (hierarchyData?.isLoading) {
        return <Loader />;
    }

    return (
        <React.Fragment>
            <header>
                <title>AddDevice</title>
                <meta name="description" content="Description of AddDevice" />
            </header>
            <Container fixed className={classes.ContainerWrapper}>
                <Box className={classes.contentBody}>
                    {!isGroupEmpty && canCreateGroup && (
                        <Box className={classes.addButtonContainer}>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                startIcon={<Add />}
                                onClick={(): void => handleOpenAddGroupModal()}
                            >
                                Add a Group
                            </Button>
                        </Box>
                    )}

                    <Card className={clsx({ [classes.cardless]: isGroupEmpty })}>
                        <Hierarchy
                            hierarchyData={hierarchyData?.data?.data?.data}
                            disableSelect
                            isGroupEmpty={(isEmpty: boolean): void => setIsGroupEmpty(isEmpty)}
                            filter={{ key: 'defaultGroup', value: false, operator: '===', iterator: 'children' }}
                            expandedNodes={expandedNodes}
                            noDataFoundComponent={
                                <EmptyState
                                    className={classes.emptyAddGroup}
                                    icon={<Workspaces fontSize={'inherit'} sx={{ color: Colors.gray[500] }} />}
                                    title={'No Groups'}
                                    {...(canCreateGroup && {
                                        actions: (
                                            <Stack direction={'column'} spacing={2}>
                                                <Button
                                                    variant={'outlined'}
                                                    color={'primary'}
                                                    startIcon={<Add />}
                                                    onClick={(): void => handleOpenAddGroupModal()}
                                                >
                                                    {' '}
                                                    Add a Group{' '}
                                                </Button>
                                            </Stack>
                                        ),
                                    })}
                                    description={'You can add groups to better organize Devices.'}
                                />
                            }
                            actions={getGroupHierarchyActions}
                        />
                    </Card>
                </Box>
            </Container>
        </React.Fragment>
    );
};
