export const USER_SETTINGS = 'user_settings';
export const LOCAL_USER_DATA = 'user_data';
export const REMEMBER_ME_DATA = 'remember_me_data';
export const REMEMBER_BIOMETRICS_DATA = 'remember_biometrics_data';
export const HAS_BIOMETRICS = 'has_biometrics';
export const HAS_BEEN_ASKED_FOR_BIOMETRICS = 'has_been_asked_for_biometrics';
export const HAS_LOGGED_OUT = 'has_logged_out';
export const CST_EMAIL = 'BLSupport@eaton.com';
export const CST_PHONE_NUMBER = '1-877-ETN-CARE';

export const MIN_DRAWER_WIDTH = 72;
export const MAX_DRAWER_WIDTH = 600;
export const DEFAULT_DRAWER_WIDTH = 360;
export const MAX_POWER_ON_DELAY = 255;
export const ORG_NAME_LIMIT = 50;

export const baudData = [
    { key: '1200', value: '1200' },
    { key: '2400', value: '2400' },
    { key: '4800', value: '4800' },
    { key: '9600', value: '9600' },
    { key: '19200', value: '19200' },
    { key: '38400', value: '38400' },
    { key: '57600', value: '57600' },
    { key: '115200', value: '115200' },
    { key: '230400', value: '230400' },
    { key: '460800', value: '460800' },
    { key: '921600', value: '921600' },
];

export const bits = [
    { key: 5, value: '5' },
    { key: 6, value: '6' },
    { key: 7, value: '7' },
    { key: 8, value: '8' },
    { key: 9, value: '9' },
];

export const term = [
    { key: 'OFF', value: 'OFF' },
    { key: 'ON', value: 'ON' },
];
export const standard = [
    { key: '232', value: 'RS-232' },
    { key: '485', value: 'RS-485' },
];
export const stop = [
    { key: 0, value: '0' },
    { key: 1, value: '1' },
];
export const parity = [
    { key: 'N', value: 'None' },
    { key: 'E', value: 'Even' },
    { key: 'O', value: 'Odd' },
];

export const SHORT_GROUP_NAME_LENGTH_LIMIT = 6;
export const GROUP_NAME_LENGTH_LIMIT = 24;
export const LOAD_NAME_LENGTH_LIMIT = 32;

export const TAGS_LIMIT = 6;
export const TAG_LIMIT = 16;
export const DEVICE_FIELDS_LIMIT: any = {
    productId: 6,
    serialNumber: 18,
    description: 256,
    deviceName: 32,
    guid: 36,
};
export const USER_DETAILS_LIMIT = 50;
export const PHONE_NUMBER_LIMIT = 10;
export const DELETE_KEY = 6;
export const guidRegex = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i;
export const NUMBER_REGEX = /^\d+$/;
