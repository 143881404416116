import React from 'react';
import { useNavigate } from 'react-router';
import { InfoListItem, EmptyState } from '@brightlayer-ui/react-components';
import { FormatListBulleted } from '@mui/icons-material';
import { Box, Button, Switch, CircularProgress } from '@mui/material';
import { useAppDispatch } from '@fiji/common/src/app/store';
import { setPageHeaderData } from '@fiji/common/src/features/common/commonSlice';

const DeviceLoadsBtn = ({
    load,
    isCommandExistsInLoading,
}: {
    load: any;
    isCommandExistsInLoading: (command: string, loadId: string) => boolean;
}): JSX.Element => {
    const getCommandColor = (loadId: string): string => {
        switch (true) {
            case isCommandExistsInLoading('TURN_ON_LOAD', loadId):
                return '#2CA618';
            case isCommandExistsInLoading('TURN_OFF_LOAD', loadId):
                return '#CA3C3D';
            case isCommandExistsInLoading('CYCLE_LOAD', loadId):
                return '#007BC1';
            default:
                return '#000';
        }
    };

    return (
        <>
            <Button
                variant={'text'}
                startIcon={<CircularProgress color="inherit" size={20} />}
                disabled
                sx={{
                    '&.Mui-disabled': {
                        color: getCommandColor(load.id),
                    },
                }}
            >
                {isCommandExistsInLoading('TURN_OFF_LOAD', load.id) ? 'Turning Off Load' : ''}
                {isCommandExistsInLoading('TURN_ON_LOAD', load.id) ? 'Turning On Load' : ''}
                {isCommandExistsInLoading('CYCLE_LOAD', load.id) ? 'Cycling Load' : ''}
            </Button>
        </>
    );
};

export const DeviceLoads = ({
    deviceLoads,
    infoListProps,
    deviceId,
    isDisabled,
    setModalState,
    isCommandExistsInLoading,
}: {
    deviceLoads: any;
    infoListProps: any;
    deviceId: string;
    isDisabled: boolean;
    setModalState: (props: any) => any;
    isCommandExistsInLoading: (command: string, loadId: string) => boolean;
}): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const loadNumberStyle = {
        fontSize: '14px',
        fontWeight: '600',
        backgroundColor: '#727E84',
        color: '#fff',
        width: '18px',
        height: '18px',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const getRightComponent = (load: any): JSX.Element => {
        if (isCommandExistsInLoading(load?.isTurnOn ? 'TURN_OFF_LOAD' : 'TURN_ON_LOAD', load.id)) {
            return <DeviceLoadsBtn isCommandExistsInLoading={isCommandExistsInLoading} load={load} />;
        }
        return (
            <Switch
                disabled={isDisabled}
                checked={load?.isTurnOn}
                onClick={(e: any): void => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (load?.isTurnOn)
                        setModalState({
                            isOpen: true,
                            command: 'TURN_OFF',
                            type: 'LOAD',
                            loadId: load.id,
                        });
                    else
                        setModalState({
                            isOpen: true,
                            command: 'TURN_ON',
                            type: 'LOAD',
                            loadId: load.id,
                        });
                }}
            />
        );
    };

    if (!deviceLoads?.data?.length) {
        return <EmptyState icon={<FormatListBulleted className="my-svg-icon" />} title="No Loads Found" />;
    }
    return deviceLoads?.data?.map(
        (load: any) =>
            load?.isEnabledOnOutletMap && (
                <InfoListItem
                    key={load.number}
                    {...infoListProps}
                    chevron
                    onClick={(): void => {
                        navigate(`/load-details/${deviceId || ''}/${load.id}`, {
                            state: { deviceDetailsTab: 'Summary' },
                        });
                        dispatch(setPageHeaderData({ loadName: `Edit Loads ${load?.number}` }));
                    }}
                    title={load.name}
                    icon={<Box sx={loadNumberStyle}>{load.number}</Box>}
                    rightComponent={getRightComponent(load)}
                />
            )
    );
};
