import React, { useState, useCallback, useEffect } from 'react';
import { UserProfile } from '@fiji/common/src/types';
import {
    Dialog,
    Divider,
    DialogTitle,
    Typography,
    DialogContent,
    Box,
    DialogActions,
    Stack,
    FormControl,
    MenuItem,
    InputLabel,
    Button,
    Select,
} from '@mui/material';
import Loader from '../../components/Loader';
import { useUpdateUserProfileMutation } from '@fiji/common/src/features/userManagement/userManagement';
import { useGetCountriesQuery, useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { RefetchConfigOptions } from '@reduxjs/toolkit/dist/query/core/apiState';
import clsx from 'clsx';
import { useAppDispatch } from '@fiji/common/src/app/store';
import { setToastifyContent } from '@fiji/common/src/features/common/commonSlice';

/**
 * The above type defines the props for a country code modal component in a TypeScript React
 * application.
 * @property {string} activeModal - A string representing the currently active modal in the country
 * code modal component.
 * @property {string} wrapperClass - A CSS class name for the wrapper element of the country code modal
 * component.
 * @property {string} titleClass - A CSS class name for the title element of the modal.
 * @property {string} sectionClass - The `sectionClass` property is a string that represents the CSS
 * class name for the section element in the country code modal.
 * @property {string} formOverFlow - The `formOverFlow` property is a string that represents the CSS
 * class for the form overflow. It is used to control the overflow behavior of the form within the
 * modal.
 * @property {string} actionsClass - A CSS class name for the actions section of the modal.
 * @property activeModalHandler - A function that takes a string argument and does something with it.
 * It is used to handle the active modal state in the component.
 * @property {string} topDividerClass - A CSS class name for the top divider element in the country
 * code modal component.
 * @property {string} dividerClass - A CSS class name for the divider element in the country code
 * modal.
 */
type CountryCodeModalProps = {
    activeModal: string;
    wrapperClass: string;
    titleClass: string;
    sectionClass: string;
    formOverFlow: string;
    actionsClass: string;
    activeModalHandler: (arg0: string) => void;
    topDividerClass: string;
    dividerClass: string;
};

/* The `CountryCodeModal` component is a modal dialog that allows the user to update their country
preferences. It receives several props that define the styling and behavior of the modal. */
export const CountryCodeModal = ({
    activeModal,
    wrapperClass,
    titleClass,
    sectionClass,
    formOverFlow,
    actionsClass,
    activeModalHandler,
    dividerClass,
    topDividerClass,
}: CountryCodeModalProps): JSX.Element => {
    const dispatch = useAppDispatch();

    const [userData, setUserData] = useState<Partial<UserProfile['data']>>({ country: '' });

    const [updateUserProfile, { isLoading: editUserLoader }] = useUpdateUserProfileMutation();

    const { data: profileDetails } = useGetUserProfileQuery<{
        data: UserProfile;
        refetch: RefetchConfigOptions;
    }>();

    const { data: countries } = useGetCountriesQuery<{ data: any }>();

    /* The `useEffect` hook is used to perform side effects in a React functional component. In this
    case, the `useEffect` hook is used to update the `userData` state variable when the
    `profileDetails` data changes. */
    useEffect(() => {
        if (profileDetails) {
            setUserData((prev) => ({
                ...prev,
                country: profileDetails?.data?.country,
            }));
        }
    }, [profileDetails]);

    /* The `handleUpdateCountry` function is an asynchronous function that is used to update the user's
    country preference. */
    const handleUpdateCountry = useCallback(async (): Promise<void> => {
        const { error }: any = await updateUserProfile({
            ...(userData.country && {
                country: userData?.country,
            }),
        });
        if (!error) {
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: 'Your country preference has been updated.',
                    duration: 3000,
                })
            );
            activeModalHandler('');
        }
    }, [userData]);

    /* The `handleChangeUserCountry` function is a callback function that is used to handle the change
    event of the country select input in the `CountryCodeModal` component. */
    const handleChangeUserCountry = useCallback(
        (e: any): void => {
            setUserData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
        },
        [userData]
    );

    const isSubmitDisabled = (): boolean =>
        !userData?.country?.length || profileDetails?.data?.country === userData?.country;

    return (
        <Dialog
            open={activeModal === 'countryModal'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={wrapperClass}
        >
            <DialogTitle id="alert-dialog-title" className={titleClass}>
                <Typography variant={'h6'} className={sectionClass}>
                    Update My Country Preferences
                </Typography>
                <Typography variant={'body1'}>Please select your location country preferences</Typography>
                <Divider className={clsx(dividerClass, topDividerClass)} />
            </DialogTitle>

            <DialogContent sx={{ position: 'relative', height: '416px' }}>
                {editUserLoader ? (
                    <Loader size={40} />
                ) : (
                    <Box className={formOverFlow}>
                        <FormControl variant={'filled'} fullWidth>
                            <InputLabel htmlFor={'Country Code'}>Country</InputLabel>
                            <Select
                                name="country"
                                data-cy={'country-selector'}
                                fullWidth
                                labelId={'Country'}
                                inputProps={{
                                    name: 'country',
                                }}
                                value={userData?.country}
                                onChange={handleChangeUserCountry}
                            >
                                {countries?.data?.map((country: any) => (
                                    <MenuItem key={country?.name} value={country?.name}>
                                        {country?.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                )}
            </DialogContent>
            <DialogActions className={actionsClass}>
                <Stack direction="row" justifyContent="end" sx={{ width: '100%', gap: '20px' }}>
                    <Button
                        variant={'outlined'}
                        onClick={(): void => {
                            setUserData((prev) => ({
                                ...prev,
                                country: profileDetails?.data?.country || '',
                            }));
                            activeModalHandler('');
                        }}
                    >
                        Cancel
                    </Button>
                    <Button variant={'contained'} onClick={handleUpdateCountry} disabled={isSubmitDisabled()}>
                        Submit
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
