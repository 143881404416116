import React from 'react';
import { createNumArray } from '../../../CommonUtils';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { ListItemTag } from '@brightlayer-ui/react-components';

const TimelineCardSkeleton = ({ type }: { type?: string }): JSX.Element => (
    <>
        {createNumArray(4).map((item) => (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: type ? '10px' : '16px',
                }}
                key={`timeline${item}`}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexBasis: !type ? '60%' : '0',
                    }}
                >
                    <Skeleton variant="circular" animation="wave" width={type ? 50 : 40} height={40} />
                    <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {createNumArray(2).map((subitem) => (
                            <Stack key={`sub${subitem}`} sx={{ paddingLeft: 4 }} direction="column">
                                <Skeleton animation="wave">
                                    <Typography variant={type ? 'body2' : 'body1'}>Connection Restored</Typography>
                                </Skeleton>
                                <Skeleton animation="wave">
                                    <Typography variant="body2">FirstFloor</Typography>
                                </Skeleton>
                            </Stack>
                        ))}
                    </span>
                </Box>
                <Stack spacing={2} alignItems={'center'} justifyContent={'end'} direction="row">
                    <Skeleton animation="wave">
                        <ListItemTag label="Offline" />
                    </Skeleton>
                    <Skeleton variant="circular" animation="wave" width={30} height={30} />
                </Stack>
            </Box>
        ))}
    </>
);

export default TimelineCardSkeleton;
