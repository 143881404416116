import React from 'react';

/**
 * The `PaginationOptions` type represents the options for pagination, including the page size and
 * total number of items.
 * @property {number} pageSize - The `pageSize` property represents the number of items to be displayed
 * on each page of a paginated list or table.
 * @property {number} totalItems - The total number of items that need to be paginated.
 */
type PaginationOptions = {
    pageSize: number;
    totalItems: number;
};

/**
 * The below type represents the state and methods for handling pagination.
 * @property {number} currentPage - The current page number.
 * @property {number} totalPages - The total number of pages in the pagination.
 * @property setPage - A function that takes a page number as an argument and sets the current page to
 * that value.
 * @property goToNextPage - A function that moves the pagination to the next page.
 * @property goToPrevPage - A function that navigates to the previous page in the pagination.
 * @property goToFirstPage - A function that navigates to the first page of the pagination.
 * @property goToLastPage - A function that navigates to the last page of the pagination.
 */
type PaginationState = {
    currentPage: number;
    totalPages: number;
    setPage: (page: any) => void;
    setCurrentPage: (page: 0) => void;
    goToNextPage: () => void;
    goToPrevPage: () => void;
    goToFirstPage: () => void;
    goToLastPage: () => void;
};

/**
 * The `usePagination` function is a custom hook in TypeScript that provides pagination functionality
 * by keeping track of the current page and allowing navigation to previous, next, first, and last
 * pages.
 * @param {PaginationOptions} options - The options parameter is an object that contains the following
 * properties:
 * @returns The function `usePagination` returns an object of type `PaginationState`.
 */
export const usePagination = (options: PaginationOptions): PaginationState => {
    const { pageSize, totalItems } = options;
    const [currentPage, setCurrentPage] = React.useState(0);

    /* The line `const totalPages = Math.ceil(totalItems / pageSize);` calculates the total number of pages
needed for pagination based on the total number of items and the page size. */
    const totalPages = Math.ceil(totalItems / pageSize);

    /**
     * The `setPage` function sets the current page to the specified page number if it is within the
     * valid range.
     * @param {number} page - The `page` parameter is a number that represents the page number to be
     * set.
     */
    const setPage = (page: number): void => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    /**
     * The function `goToNextPage` increments the current page by 1 if it is not already the last page.
     */
    const goToNextPage = (): void => {
        if (currentPage < totalPages - 1) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    /**
     * The function `goToPrevPage` decreases the value of `currentPage` by 1 if it is greater than or
     * equal to 1.
     */
    const goToPrevPage = (): void => {
        if (currentPage >= 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    /**
     * The function `goToLastPage` sets the current page to the last page.
     */
    const goToLastPage = (): void => {
        setCurrentPage(totalPages - 1);
    };

    /**
     * The function `goToFirstPage` sets the current page to the first page.
     */
    const goToFirstPage = (): void => {
        setCurrentPage(0);
    };

    return {
        currentPage,
        totalPages,
        setPage,
        goToNextPage,
        goToPrevPage,
        goToLastPage,
        goToFirstPage,
        setCurrentPage,
    };
};
