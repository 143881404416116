import { InfoListItem } from '@brightlayer-ui/react-components';
import { Create } from '@mui/icons-material';
import { Box, Card, CardContent, Container, IconButton, Stack, Typography, useTheme } from '@mui/material';
import * as Colors from '@brightlayer-ui/colors';
import React from 'react';
import { useStyles } from './styles';
import { EditLoadNameModal, EditLoadDescriptionModal } from './';
import { useParams } from 'react-router-dom';
import { useGetDeviceLoadByIdQuery } from '@fiji/common/src/features/deviceDetails/deviceDetailApi';
import { BackdropLoader } from '../../components/BackdropLoader';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { useRBAC } from '../../hooks';
import { setPageHeaderData } from '@fiji/common/src/features/common/commonSlice';
import { useGetDeviceByIdQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';

const loadNumberStyle = {
    fontSize: '14px',
    fontWeight: '600',
    backgroundColor: '#727E84',
    color: '#fff',
    width: '18px',
    height: '18px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

export const LoadDetails = (): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const rolePermissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(rolePermissions);
    const canUpdateLoads = hasPermission('update-device-loads');
    const dispatch = useAppDispatch();

    const { loadId, deviceId } = useParams();
    const { data: loadData, isLoading: isLoadingLoadDetails } = useGetDeviceLoadByIdQuery({ deviceId, loadId });
    const { data: deviceDetails } = useGetDeviceByIdQuery<{
        data: any;
        isLoading: boolean;
    }>(deviceId, {
        skip: !deviceId || deviceId === ':deviceId',
    });

    React.useEffect(() => {
        if (loadData)
            dispatch(
                setPageHeaderData({
                    loadName: `Edit Loads ${loadData?.data?.number}`,
                    subTitle: deviceDetails?.data?.deviceName ?? '',
                })
            );
    }, [loadData, deviceDetails]);

    const [isOpenEditNameModal, setIsOpenEditNameModal] = React.useState(false);
    const [isOpenEditDescriptionModal, setIsOpenEditDescriptionModal] = React.useState(false);

    const INFO_LIST_PROPS: any = {
        dataTestid: 'infoListItem',
        hidePadding: true,
        ripple: true,
        dense: true,
        title: '',
    };

    React.useEffect(
        () => (): void => {
            dispatch(setPageHeaderData(undefined));
        },
        []
    );

    return (
        <>
            <Container fixed className={classes.ContainerWrapper}>
                <Box className={classes.contentBody}>
                    <Card>
                        <CardContent className={classes.cardContent}>
                            <InfoListItem
                                {...INFO_LIST_PROPS}
                                subtitle={'Load Name'}
                                divider
                                fullWidth={true}
                                rightComponent={
                                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                        <Box sx={loadNumberStyle}>{loadData?.data?.number}</Box>
                                        <Typography variant={'subtitle1'}>{loadData?.data?.name}</Typography>
                                        <IconButton
                                            disabled={!canUpdateLoads}
                                            color={'inherit'}
                                            edge={'start'}
                                            size="small"
                                            onClick={(): void => setIsOpenEditNameModal(true)}
                                        >
                                            <Create
                                                sx={{ color: canUpdateLoads ? Colors.gray[300] : Colors.gray[100] }}
                                            />
                                        </IconButton>
                                    </Stack>
                                }
                            />
                            <InfoListItem
                                {...INFO_LIST_PROPS}
                                subtitle={'Description'}
                                rightComponent={
                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                        <IconButton
                                            disabled={!canUpdateLoads}
                                            data-cy="toolbar-menu"
                                            color={'inherit'}
                                            edge={'start'}
                                            size="small"
                                            onClick={(): void => setIsOpenEditDescriptionModal(true)}
                                        >
                                            <Create
                                                sx={{ color: canUpdateLoads ? Colors.gray[300] : Colors.gray[100] }}
                                            />
                                        </IconButton>
                                    </Stack>
                                }
                            />
                            <Typography py={1} pl={2} pr={8} variant={'subtitle1'} sx={{ wordWrap: 'break-word' }}>
                                {loadData?.data?.description || '-'}
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
            </Container>
            <EditLoadNameModal
                isOpen={isOpenEditNameModal}
                closeHandler={(): void => setIsOpenEditNameModal(false)}
                loadId={loadId}
                deviceId={deviceId}
                loadName={loadData?.data?.name}
            />
            <EditLoadDescriptionModal
                isOpen={isOpenEditDescriptionModal}
                closeHandler={(): void => setIsOpenEditDescriptionModal(false)}
                loadId={loadId}
                deviceId={deviceId}
                description={loadData?.data?.description}
            />
            {isLoadingLoadDetails && <BackdropLoader isOpen={true} />}
        </>
    );
};
