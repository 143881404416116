/* eslint-disable @typescript-eslint/no-this-alias */

import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Box, Grid, Button, Typography, Select, MenuItem, FormControl, Divider, Stack } from '@mui/material';
import MouseWheelZoomModule from 'highcharts/modules/mouse-wheel-zoom';
import DownloadIcon from '@mui/icons-material/Download';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Skeleton from '@mui/material/Skeleton';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { MultiColumnList } from './MultiColumnList';
import { RefetchConfigOptions } from '@reduxjs/toolkit/dist/query/core/apiState';
import {
    useDownloadDeviceTrendsMutation,
    useGetAllChannelsQuery,
    useGetTrendsByDeviceIdQuery,
} from '@fiji/common/src/features/deviceDetails/deviceDetailApi';
import { closeMqttConnection, connectMQTT, subscribeTopic } from '../../mqttConnection';
import { downloadFileFromLink } from '../../CommonUtils';
import moment from 'moment-timezone';

import { UserProfile } from '@fiji/common/src/types';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useRBAC } from '../../hooks';
import Loader from '../../components/Loader';
import * as Colors from '@brightlayer-ui/colors';
import { useGetMQTTCredentialsMutation } from '@fiji/common/src/features/mqtt/mqttApi';

type LegendData = {
    color: string;
    name: string;
    visible: boolean;
    id: string;
};

type Trends = {
    success: boolean;
    data: any[];
    requestId: string;
};

MouseWheelZoomModule(Highcharts);

const drawerWidth = 460;

const seriesColors: string[] = [
    '#0088F2',
    '#F0CB2F',
    '#F47721',
    '#39B620',
    '#983FEF',
    '#004B9E',
    '#87C6D4',
    '#9C0E33',
    '#008A7E',
    '#774E08',
];

const getMainWidth = (isMultiYAxis: boolean, open: boolean): string => {
    if (isMultiYAxis) {
        return `calc(100% - ${open ? drawerWidth : '65'}px)`;
    }
    return '100%';
};

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open: boolean;
    isMultiYAxis: boolean;
}>(({ theme, open, isMultiYAxis }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    width: getMainWidth(isMultiYAxis, open),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    }),
}));

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});
const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

export const DeviceTrends = (props: any): JSX.Element => {
    const chartRef = useRef<any>(null);
    const { data: profileDetails } = useGetUserProfileQuery<{ data: UserProfile }>();

    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const rolePermissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(rolePermissions);
    const canDownload = hasPermission('download-trends');

    const [legendData, setLegendData] = useState<LegendData[]>();
    const [activeDuration, setActiveDuration] = useState<number>(24);
    const [isChannelDraw, setIsChannelDraw] = useState<boolean>(false);
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

    const [option, setOption] = useState({
        chart: {
            type: 'line',
            height: !props?.isMultiYAxis ? 200 : 500,
            zoomType: 'x',
            zooming: {
                mouseWheel: {
                    enabled: true,
                    sensitivity: 1.1,
                    type: 'x',
                },
            },
        },
        title: {
            text: ' ',
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            shared: true,
            style: {
                fontSize: '',
            },
            followTouchMove: false,
        },
        xAxis: {
            crosshair: {
                color: 'gray',
                dashStyle: 'Dash',
                width: 1,
            },
            type: 'datetime',
            tickLength: 0,
            zoomEnabled: true,
            panningEnabled: true,
        },
        series: [],
        legend: {
            enabled: false,
        },
        plotOptions: {
            series: {
                marker: {
                    symbol: 'circle',
                },
            },
        },
        exporting: {
            enabled: false,
        },
    });

    const [trendsFilter, setTrendsFilter] = useState({
        channels: [],
        duration: 24,
    });

    const {
        data: channelData,
        isLoading: isChannelLoading,
        isFetching: isChannelFetching,
    } = useGetAllChannelsQuery<any>();
    const {
        currentData: trendsData,
        isLoading,
        isFetching,
        isError,
    } = useGetTrendsByDeviceIdQuery<{
        currentData: Trends;
        refetch: RefetchConfigOptions;
        isLoading: boolean;
        isFetching: boolean;
        isError: boolean;
    }>({ id: props?.deviceId, body: trendsFilter }, { refetchOnMountOrArgChange: true, skip: !isChannelDraw });

    const [downloadTrends, { data: downloadedTrends, isLoading: isUrlLoading, isSuccess }] =
        useDownloadDeviceTrendsMutation();

    const [getMqttCredentials, { data: mqttCredentials }]: any = useGetMQTTCredentialsMutation();
    useEffect(() => {
        if (props?.deviceId) {
            getMqttCredentials({ clientId: null, deviceId: props?.deviceId ?? '' });
        }
        return () => {
            closeMqttConnection();
        };
    }, [props?.deviceId]);

    useEffect(() => {
        const pollingInterval = setInterval(() => {
            getMqttCredentials({ clientId: null, deviceId: props?.deviceId ?? '' });
        }, 540000);
        return () => {
            clearInterval(pollingInterval);
            closeMqttConnection();
        };
    }, [getMqttCredentials]);

    useEffect(() => {
        if (mqttCredentials?.data?.token) {
            const encodedCredentials = JSON.parse(
                Buffer.from(mqttCredentials?.data?.token, 'base64').toString('utf-8')
            );
            const decodedCredentials: any = {};

            for (const key in encodedCredentials) {
                decodedCredentials[key] =
                    key === 'clientId'
                        ? encodedCredentials[key]
                        : Buffer.from(encodedCredentials[key], 'base64').toString('utf-8');
            }

            connectMQTT(() => {
                subscribeTopic(`BSSRM/TREND/${props?.deviceId}`, mqttMessageHandler);
            }, decodedCredentials);
        }
    }, [mqttCredentials]);

    const mqttMessageHandler = (message: any, messageTopic: string): void => {
        if (
            messageTopic === `BSSRM/TREND/${props?.deviceId}` &&
            message &&
            message.name !== 'PresentStatus' &&
            message.name !== 'presentStatus'
        ) {
            const current: any = chartRef?.current;
            const seriesIndex = current?.chart?.series.findIndex((s: any) => s?.userOptions?.id === message.id);
            if (seriesIndex !== -1) {
                current?.chart?.series?.[seriesIndex]?.addPoint([message.timestamp, +message.value], true, false);
                if (
                    message?.unit &&
                    current?.chart?.series?.[seriesIndex]?.tooltipOptions?.valueSuffix !== ` ${message?.unit}`
                ) {
                    current?.chart?.series?.[seriesIndex]?.update({
                        tooltip: { valueSuffix: ` ${message?.unit}` },
                    });
                    current?.chart?.yAxis[seriesIndex].update({
                        title: {
                            text: '',
                        },
                        labels: {
                            formatter: function (): any {
                                const yAxis: any = this;
                                return `${yAxis?.value}${` ${message?.unit}`}`;
                            },
                        },
                    });
                }
                current?.chart?.hideLoading();
            }
        }
    };

    const handleCloseSnackbar = (e: any): void => {
        closeSnackbar(+e.target.name);
    };

    const action = (snackbarKey: any): JSX.Element => (
        <Button variant="text" sx={{ color: '#80BDE0' }} name={snackbarKey} onClick={handleCloseSnackbar}>
            Ok
        </Button>
    );

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [isDrawerOpen]);

    useEffect(() => {
        const current: any = chartRef?.current;
        if (trendsData?.data && chartRef?.current?.chart && option) {
            const newOption = JSON.parse(JSON.stringify(option));
            newOption['yAxis'] = getYAxis();
            newOption['series'] = getSeriesData();
            setOption(newOption);
        }
        if (!trendsData?.data?.some((data: any) => data?.records?.length > 0)) {
            current?.chart?.showLoading('No Trends Data Available');
        }
        if (isError) {
            const newOption = JSON.parse(JSON.stringify(option));
            newOption['yAxis'] = {
                crosshair: {
                    color: 'gray',
                    dashStyle: 'Dash',
                    width: 1,
                },
                type: 'datetime',
                tickLength: 0,
                zoomEnabled: true,
                panningEnabled: true,
            };
            newOption['series'] = [];
            setOption(newOption);
            current?.chart?.showLoading('No Trends Data Available');
        }
    }, [trendsData, channelData, isError]);

    useEffect(() => {
        if (trendsFilter?.channels?.length > 0) {
            setIsChannelDraw(true);
        }
    }, [trendsFilter]);

    useEffect(() => {
        if (profileDetails) {
            Highcharts.setOptions({
                time: {
                    timezoneOffset: -moment.tz(profileDetails?.data?.timezone ?? localTimeZone).utcOffset(),
                },
                lang: {
                    thousandsSep: '',
                },
            });
        }
    }, [profileDetails]);

    useEffect(() => {
        if (channelData?.data?.channels) {
            const intialIds = channelData?.data?.channels?.map((channel: any): any => channel.id);
            setTrendsFilter((prev: any) => ({ ...prev, channels: intialIds }));
            setLegendData(
                channelData?.data?.channels?.map((channel: any, index: number): any => ({
                    color: seriesColors[index],
                    name: channel.displayName,
                    visible: true,
                    id: channel.id,
                }))
            );
        }
    }, [channelData]);

    useEffect(() => {
        if (isSuccess) exportCsv();
    }, [isSuccess]);

    const getUnit = (axis: any): string => {
        let unit = '';
        const current: any = chartRef?.current;
        current?.chart?.series.forEach((series: any): void => {
            if (series?.dataMax === axis?.axis?.dataMax) {
                unit = series?.tooltipOptions?.valueSuffix;
                return;
            }
        });
        return unit;
    };

    const getYAxis = (): any =>
        trendsData?.data?.map((trend: any): any => ({
            title: {
                text: '',
            },
            labels: {
                formatter: function (): any {
                    const yAxis: any = this;
                    return props?.isMultiYAxis
                        ? `${yAxis?.value}${trend?.unit ? ` ${trend?.unit}` : '%'}`
                        : `${yAxis?.value}${getUnit(this)}`;
                },
            },
        }));

    const exportCsv = (): void => {
        const snackbarProps: any = {
            variant: 'notificationWithIcon',
            subtitle: 'Download done successfully',
            autoHideDuration: 5000,
            Icon: (
                <IconButton
                    sx={{
                        width: '42px',
                        height: '42px',
                        borderRadius: '50%',
                        color: '#fff',
                        backgroundColor: 'transparent',
                    }}
                >
                    <DownloadIcon sx={{ width: '24px', height: '24px' }} />
                </IconButton>
            ),
            notificationWithAction: true,
            notificationAction: action,
        };

        const snackbarPropsNoDataFound: any = {
            variant: 'notificationWithIcon',
            autoHideDuration: 5000,
            Icon: (
                <IconButton
                    sx={{
                        width: '42px',
                        height: '42px',
                        borderRadius: '50%',
                        color: '#fff',
                        backgroundColor: 'transparent',
                    }}
                >
                    <DownloadIcon sx={{ width: '24px', height: '24px' }} />
                </IconButton>
            ),
            notificationWithAction: true,
            notificationAction: action,
        };
        if (downloadedTrends?.data) {
            enqueueSnackbar('Downloaded', snackbarProps);
            downloadFileFromLink(downloadedTrends?.data, 'Trends');
        } else {
            enqueueSnackbar('No data available to download', snackbarPropsNoDataFound);
        }
    };
    const getSeriesData = (): any => {
        if (!props?.isMultiYAxis) {
            return trendsData?.data?.map((trend: any) => {
                const data = JSON.parse(JSON.stringify(trend?.records));

                return {
                    id: trend?.channelId,
                    name: channelData?.data?.channels?.find((channel: any): any => channel?.id === trend?.channelId)
                        ?.displayName,
                    data: data?.map((point: any): any => [point.x, point.y]),
                    color: legendData?.find((legend: any): any => legend?.id === trend?.channelId)?.color,
                    type: 'line',
                    tooltip: {
                        valueSuffix: trend?.unit ? ` ${trend?.unit}` : '%',
                    },
                };
            });
        }

        return trendsData?.data?.map((trend: any, index: number) => {
            const data = JSON.parse(JSON.stringify(trend?.records));

            return {
                id: trend?.channelId,
                name: channelData?.data?.channels?.find((channel: any): any => channel?.id === trend?.channelId)
                    ?.displayName,
                data: data?.map((point: any): any => [point.x, point.y]),
                color: legendData?.find((legend: any): any => legend?.id === trend?.channelId)?.color,
                type: 'line',
                tooltip: {
                    valueSuffix: trend?.unit ? ` ${trend?.unit}` : '%',
                },
                yAxis: index,
            };
        });
    };

    const changeHandler = (e: any, id: number): void => {
        const newLegendData = JSON.parse(JSON.stringify(legendData));
        const index = newLegendData?.findIndex((legend: any): any => legend.id === id);
        if (index > -1) {
            newLegendData[index].visible = e.target.checked;
        }
        const channelIds: any = [];
        newLegendData.map((legend: any) => {
            if (legend.visible) {
                channelIds.push(legend.id);
            }
        });
        setTrendsFilter((prev: any): any => ({ ...prev, channels: channelIds }));
        setLegendData(newLegendData);
        const current: any = chartRef?.current;
        if (current?.chart?.series?.length > 0) current?.chart.series.forEach((series: any) => series.remove());
    };

    const durationChangeHandler = (duration: number): void => {
        setTrendsFilter((prev: any) => ({ ...prev, duration: duration }));
        setActiveDuration(duration);
    };

    const chartComponent = (): JSX.Element => (
        <HighchartsReact highcharts={Highcharts} options={option} constructorType="chart" ref={chartRef} />
    );

    const getActionMenu = (): JSX.Element => (
        <>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} margin={'0 24px 24px 24px'}>
                <Box display={'flex'} alignItems={'center'}>
                    <Button
                        variant={activeDuration === 24 ? 'contained' : 'outlined'}
                        sx={{ borderRadius: '0' }}
                        onClick={(): void => durationChangeHandler(24)}
                    >
                        24 Hours
                    </Button>
                    <Button
                        variant={activeDuration === 360 ? 'contained' : 'outlined'}
                        sx={{ borderRadius: '0' }}
                        onClick={(): void => durationChangeHandler(360)}
                    >
                        15 Days
                    </Button>
                    <Button
                        variant={activeDuration === 744 ? 'contained' : 'outlined'}
                        sx={{ borderRadius: '0' }}
                        onClick={(): void => durationChangeHandler(744)}
                    >
                        31 Days
                    </Button>
                </Box>
                <Button
                    onClick={async (): Promise<void> => {
                        await downloadTrends({
                            payload: trendsFilter,
                            id: props?.deviceId,
                        });
                    }}
                    variant="outlined"
                    disabled={!trendsData?.data?.length || !canDownload || isUrlLoading}
                    startIcon={
                        <>
                            {isUrlLoading && <Loader size={14} />}
                            <DownloadIcon
                                sx={{
                                    color: canDownload ? '#007bc1' : Colors.gray[200],
                                }}
                            />
                        </>
                    }
                    className="export-btn"
                >
                    Download
                </Button>
            </Box>
        </>
    );

    return (
        <>
            <Box className="chart-container">
                <Grid container>
                    <Grid item xs={12}>
                        <Box className="custom-drawer">
                            <Main
                                open={isDrawerOpen}
                                isMultiYAxis={props?.isMultiYAxis}
                                sx={{
                                    flexGrow: 1,
                                    padding: '0',
                                }}
                            >
                                <Box>
                                    {!props?.isMultiYAxis ? (
                                        <>
                                            <Box
                                                display={'flex'}
                                                justifyContent={'space-between'}
                                                alignItems={'center'}
                                                margin={'0 16px'}
                                            >
                                                <Typography variant="body1" color="primary">
                                                    Trends
                                                </Typography>
                                                <Box display={'flex'} alignItems={'center'}>
                                                    <FormControl sx={{ minWidth: 80 }}>
                                                        <Select
                                                            labelId="demo-simple-select-helper-label"
                                                            id="demo-simple-select-helper"
                                                            label="Age"
                                                            variant="standard"
                                                            placeholder="15 Days"
                                                            disableUnderline
                                                            defaultValue={24}
                                                            onChange={(e: any): void =>
                                                                durationChangeHandler(e?.target?.value)
                                                            }
                                                        >
                                                            <MenuItem value={24}>24 Hours</MenuItem>
                                                            <MenuItem value={360}>15 Days</MenuItem>
                                                            <MenuItem value={744}>31 Days</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <Button
                                                        startIcon={
                                                            <>
                                                                {isUrlLoading && <Loader size={14} />}
                                                                <DownloadIcon
                                                                    sx={{
                                                                        color: canDownload
                                                                            ? '#727e84'
                                                                            : Colors.gray[200],
                                                                    }}
                                                                />
                                                            </>
                                                        }
                                                        onClick={async (): Promise<void> => {
                                                            await downloadTrends({
                                                                payload: trendsFilter,
                                                                id: props?.deviceId,
                                                            });
                                                        }}
                                                        disabled={
                                                            !trendsData?.data?.length || !canDownload || isUrlLoading
                                                        }
                                                        className="download-icon"
                                                    />
                                                </Box>
                                            </Box>
                                            <Divider />
                                        </>
                                    ) : (
                                        getActionMenu()
                                    )}
                                    {isLoading || isFetching || !isChannelDraw ? (
                                        <Skeleton
                                            animation="wave"
                                            variant="rectangular"
                                            sx={{ width: '100%' }}
                                            height={!props?.isMultiYAxis ? 200 : 600}
                                        />
                                    ) : (
                                        chartComponent()
                                    )}
                                </Box>
                            </Main>
                            {props.isMultiYAxis ? (
                                <Drawer variant="permanent" open={isDrawerOpen}>
                                    {!isDrawerOpen ? (
                                        <IconButton
                                            color="inherit"
                                            aria-label="open drawer"
                                            onClick={(): void => setIsDrawerOpen(true)}
                                            edge="start"
                                            sx={{ left: '24px' }}
                                        >
                                            <ChevronLeftIcon />
                                        </IconButton>
                                    ) : (
                                        <Stack
                                            flexDirection={'row'}
                                            alignItems={'center'}
                                            justifyContent={'space-between'}
                                        >
                                            <Stack flexDirection={'row'} alignItems={'center'}>
                                                <IconButton onClick={(): void => setIsDrawerOpen(false)}>
                                                    <ChevronRightIcon />
                                                </IconButton>
                                                <Typography variant="body2" color="primary">
                                                    Channels
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    )}
                                    <Divider />
                                    <MultiColumnList
                                        isLoading={isChannelLoading || isChannelFetching}
                                        legendData={legendData}
                                        changeHandler={changeHandler}
                                        maxItemsPerColumn={100}
                                    />
                                </Drawer>
                            ) : (
                                <MultiColumnList
                                    isLoading={isChannelLoading || isChannelFetching}
                                    legendData={legendData}
                                    changeHandler={changeHandler}
                                    maxItemsPerColumn={5}
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};
