import { api } from '../../app/api/baseApi';

export const profileApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getUserProfile: builder.query<object, void>({
            query: () => ({
                url: '/iam/v1/users/me/profile',
                method: 'GET',
            }),
            providesTags: ['Profile'],
        }),
        uploadProfileLogo: builder.mutation({
            query: (body: any) => ({
                url: `iam/v1/users/me/profile/image`,
                method: 'PATCH',
                body: body,
            }),
        }),
        getCountries: builder.query<object, void>({
            query: () => ({
                url: 'iam/v1/countries',
            }),
        }),
        getAreaCodes: builder.query<object, void>({
            query: () => ({
                url: 'iam/v1/countries/code',
            }),
        }),
        getLanguages: builder.query<object, void>({
            query: () => ({ url: `iam/v1/languages` }),
        }),
        getTimeZones: builder.query<object, void>({
            query: () => ({ url: `iam/v1/allTimezones` }),
        }),
        getDateFormats: builder.query<object, void>({
            query: () => ({ url: `iam/v1/date/format` }),
        }),
        getRolesPermission: builder.query({
            query: ({ roleId }) => ({
                url: `/iam/v1/roles/${roleId}`,
                method: 'GET',
            }),
        }),
        generateMobileOtp: builder.mutation<object, object>({
            query: (body) => ({
                url: `/iam/v1/users/me/code/generate`,
                method: 'POST',
                body: body,
            }),
        }),
        verifyMobileOtp: builder.mutation<object, object>({
            query: (body) => ({
                url: `/iam/v1/users/me/code/verify`,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Profile'] : []),
        }),
        removeProfileImage: builder.mutation<object, void>({
            query: () => ({
                url: `/iam/v1/users/me/profile/image`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Profile'],
        }),
        deleteProfile: builder.mutation({
            query: (body) => ({
                url: `/iam/v1/users/me`,
                method: 'DELETE',
                body: body,
            }),
            invalidatesTags: [],
        }),
    }),
});
export const {
    useGetDateFormatsQuery,
    useGenerateMobileOtpMutation,
    useVerifyMobileOtpMutation,
    useUploadProfileLogoMutation,
    useGetUserProfileQuery,
    useGetCountriesQuery,
    useGetAreaCodesQuery,
    useGetLanguagesQuery,
    useGetTimeZonesQuery,
    useGetRolesPermissionQuery,
    useLazyGetRolesPermissionQuery,
    useRemoveProfileImageMutation,
    useDeleteProfileMutation,
} = profileApi;
