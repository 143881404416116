/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { MoveChildrenDeleteModal } from './index';
import DeleteModal from '../../components/DeleteModal';
import { useConfirm, useGetHierarchyHandlers } from '../../hooks';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { GroupModal } from '@fiji/common/src/types';
import { BackdropLoader } from '../../components/BackdropLoader';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './styles';
import {
    useDeleteGroupMutation,
    useGetGroupByIdQuery,
    useLazyGetGroupsListQuery,
} from '@fiji/common/src/features/group/groupApi';
import {
    deleteGroupModal,
    selectDeleteGroupModal,
    selectHierarchyData,
} from '@fiji/common/src/features/group/groupSlice';
import { setSelectedGroup, setToastifyContent } from '@fiji/common/src/features/common/commonSlice';
import { api } from '@fiji/common/src/app/api/baseApi';

export const DeleteGroupModal = (): JSX.Element => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classes = useStyles(theme);
    const modalData = useTypedSelector<GroupModal>(selectDeleteGroupModal);

    const [moveChildrenDelete, setMoveChildrenDeleteModal] = useState(false);
    const [groupId, setGroupId] = useState<string | null>(modalData?.groupId);
    const [isGroupDeleted, setIsGroupDeleted] = useState(false);
    const [isGroupMoved, setIsGroupMoved] = useState<boolean | undefined>(false);
    const [groupDetails, setGroupDetails] = useState<any>();

    const [deleteGroup, { isLoading: isDeleted }] = useDeleteGroupMutation();

    const hierarchyData = useTypedSelector(selectHierarchyData);

    const { findGroupOrDeviceById } = useGetHierarchyHandlers({});

    const {
        data: groupData,
        isLoading,
        isFetching,
    }: any = useGetGroupByIdQuery({ groupId }, { skip: Boolean(!groupId) });
    const [getGroupList] = useLazyGetGroupsListQuery();

    /* The `useEffect` hook is used to perform side effects in a functional component. In this case,
    the effect is triggered whenever the `modalData` dependency changes. */
    useEffect(() => {
        if (modalData?.groupId) {
            setGroupId(modalData.groupId);
        }
    }, [modalData]);
    useEffect(() => {
        if (groupId && hierarchyData?.data) {
            setGroupDetails(findGroupOrDeviceById(hierarchyData?.data?.data?.data, groupId));
        }
    }, [hierarchyData?.data, groupId]);

    /* The `useEffect` hook is used to perform side effects in a functional component. In this case,
    the effect is triggered whenever the `isGroupDeleted` dependency changes. */
    useEffect(() => {
        if (isGroupDeleted) {
            setGroupId(null);
            dispatch((api as any).endpoints.getGroupsHierarchy.initiate(null));
        }
    }, [isGroupDeleted]);

    /* The `useEffect` hook is used to perform side effects in a functional component. In this case,
    the effect is triggered whenever the `isGroupDeleted` or `groupId` dependency changes. */
    useEffect(() => {
        if (groupId === null && isGroupDeleted) {
            setIsGroupDeleted(false);
        }
    }, [isGroupDeleted, groupId]);

    /**
     * The function handleCloseDeleteGroupModal sets the isGroupMoved state to false and dispatches an
     * action to close the delete group modal.
     */
    const handleCloseDeleteGroupModal = (): void => {
        setIsGroupMoved(false);
        dispatch(deleteGroupModal({ isOpen: false, groupId: '', mode: modalData?.mode }));
    };

    /**
     * The `deleteGroupHandler` function is used to delete a group and its child groups and devices,
     * and display a toast message confirming the deletion.
     */
    const deleteGroupHandler = async (): Promise<void> => {
        const { error }: any = await deleteGroup({ groupId: groupId });
        if (!error) {
            if (modalData?.mode === 'edit') {
                navigate('/groups');
            }
            closeDeleteModal();
            setIsGroupDeleted(true);
            handleCloseDeleteGroupModal();
            dispatch(setSelectedGroup(''));
            await getGroupList('');
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: `${
                        groupData?.data?.children.length
                            ? `${groupData?.data?.name} and all of its child groups and devices have been deleted`
                            : `${groupData?.data?.name} has been deleted`
                    }`,
                    duration: 3000,
                })
            );
        }
    };

    const {
        isVisible: isDeleteModalOpen,
        onClick: openDeleteModal,
        onCancel: closeDeleteModal,
        onConfirm: confirmDeletion,
    } = useConfirm(deleteGroupHandler as any);

    /* The below code is defining a function called `getModalContentBody` that returns a JSX element.
    The function checks the value of `isGroupMoved` and `groupData.data.children.length` to
    determine the content of the JSX element to be returned. */
    const getModalContentBody = (): JSX.Element => {
        if (isGroupMoved) {
            return (
                <Typography variant={'body1'} mb={4}>
                    Child groups and devices have been moved. Deleting <strong> {groupData?.data?.name}</strong> will
                    delete just the group. No other data will be lost.
                </Typography>
            );
        } else if (groupData?.data?.children?.length) {
            return (
                <>
                    <Typography variant={'body1'} mb={4}>
                        This cannot be undone. All of the location, device, alarm, event data for{' '}
                        <strong> {groupData?.data?.name}</strong> and all of its child groups and devices will be
                        permanently deleted.
                    </Typography>
                    <Typography variant={'body1'} mb={2}>
                        Children can be moved to another group before deleting {groupData?.data?.name}.
                    </Typography>
                    {groupData?.data?.children.map((group: any) => (
                        <InfoListItem
                            key={group.id}
                            className={classes.infoListItem}
                            data-testid="infoListItem"
                            data-cy={'list-content'}
                            title={group.name}
                            avatar={false}
                            hidePadding
                            subtitle={`${group?.groupCount || 0} Groups, ${group?.deviceCount || 0} Devices`}
                        />
                    ))}
                </>
            );
        }
        return (
            <Typography variant={'body1'} mb={4}>
                This cannot be undone. All of the location, device, alarm, event data for{' '}
                <strong> {groupData?.data?.name}</strong> and all of its devices will be permanently deleted.
            </Typography>
        );
    };

    if (isLoading || isFetching || isDeleted) {
        return <BackdropLoader isOpen={true} />;
    }

    return (
        <>
            <Dialog
                open={modalData?.isOpen}
                onClose={handleCloseDeleteGroupModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.rootMuiDialogpaper}
            >
                <DialogTitle id="alert-dialog-title" className={classes.MuiDialogTitleRoot}>
                    <Typography
                        sx={{ whiteSpace: 'pre' }}
                        variant={'h6'}
                    >{`Delete ${groupData?.data?.name}?`}</Typography>
                </DialogTitle>
                <DialogContent>{getModalContentBody()}</DialogContent>
                <DialogActions className={classes.MuiDialogActionsRoot}>
                    <Stack direction={'row'} spacing={2} justifyContent={'end'}>
                        <Button variant={'outlined'} onClick={handleCloseDeleteGroupModal}>
                            Cancel
                        </Button>
                        {(groupData?.data?.children?.length > 0 || groupDetails?.devices?.length > 0) && (
                            <Button
                                variant={'contained'}
                                onClick={(): void => {
                                    setMoveChildrenDeleteModal(true);
                                    handleCloseDeleteGroupModal();
                                }}
                            >
                                Move Children
                            </Button>
                        )}
                        <Button
                            variant={'contained'}
                            className={classes.deleteButton}
                            onClick={
                                isGroupMoved || !groupData?.data?.children?.length
                                    ? (): void => confirmDeletion()
                                    : (): void => {
                                          openDeleteModal('');
                                          handleCloseDeleteGroupModal();
                                      }
                            }
                        >
                            {groupData?.data?.children?.length > 0 || groupDetails?.devices?.length > 0
                                ? 'Delete Group and Children'
                                : 'Delete Group'}
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
            <MoveChildrenDeleteModal
                isOpen={moveChildrenDelete}
                groupChildrens={groupData?.data?.children}
                devices={groupDetails?.devices}
                groupId={groupData?.data?.id}
                closeHandler={(isMoved?: boolean): void => {
                    setMoveChildrenDeleteModal(false);
                    setIsGroupMoved(isMoved);
                }}
            />
            <DeleteModal
                key="3q3fqffa"
                deleteButtonText="Delete & Erase All Data"
                isVisible={isDeleteModalOpen}
                confirmClick={confirmDeletion}
                closeHandler={closeDeleteModal}
                wrapperClass={classes.rootMuiDialogpaper}
                actionClass={classes.MuiDialogActionsRoot}
                titleClass={classes.MuiDialogTitleRoot}
                description={
                    <>
                        <Typography variant={'body1'} mb={4}>
                            This cannot be undone. All of the location, device, alarm, event data for{' '}
                            {groupData?.data?.name} and all of its child groups and devices will be permanently deleted.
                        </Typography>
                        <Typography variant={'body1'} mb={2}>
                            Type “delete” to confirm.
                        </Typography>
                    </>
                }
                noHelperText={true}
                noExtraDescription={true}
                headingText={`Delete ${groupData?.data?.name} and All of its Children?`}
                deleteButtonClass={classes.deleteButton}
            />
        </>
    );
};
