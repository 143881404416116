/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Container,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Stack,
    Button,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    List,
    AppBar,
    useMediaQuery,
    Avatar,
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { CorporateFare, Done, Add, PeopleAlt, Person } from '@mui/icons-material';
import { InfoListItem, EmptyState, Spacer } from '@brightlayer-ui/react-components';
import * as Colors from '@brightlayer-ui/colors';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { useNavigate } from 'react-router';
import { useTheme } from '@mui/material/styles';
import {
    useAssignRoleToUserMutation,
    useGetAllRolesQuery,
    useGetAllUsersQuery,
} from '@fiji/common/src/features/userManagement/userManagement';
import { AllRolesObj, AllUserObj, UserObj, UserProfile } from '@fiji/common/src/types';
import { getFullName, getUserInitials, handleWhiteSpaces } from '../../CommonUtils';
import { useConfirm, useImageValidated, useRBAC } from '../../hooks';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { useStyles } from './style';
import ConfirmModal from '../../components/ConfirmModal';
import { BackdropLoader } from '../../components/BackdropLoader';
import { RefetchConfigOptions } from '@reduxjs/toolkit/dist/query/core/apiState';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import {
    useDeleteOrgMutation,
    useLeaveOrgMutation,
    useUpdateOrgMutation,
    useRemoveOrgLogoMutation,
    useUploadOrganizationLogoMutation,
    useGetOrgQuery,
} from '@fiji/common/src/features/orgManagement/orgApi';
import orgSlice, { selectedOrg, switchOrgModal } from '@fiji/common/src/features/orgManagement/orgSlice';
import { setToastifyContent } from '@fiji/common/src/features/common/commonSlice';
import Loader from '../../components/Loader';
import DeleteModal from '../../components/DeleteModal';
import { OrganizationDetails } from './';

/**
 * The above type represents the structure of an object that contains information about a user's
 * organizations.
 * @property {string} id - A unique identifier for the user organization.
 * @property {string} name - The name property represents the name of the user organization.
 * @property {string} status - The "status" property in the UserOrgs type represents the status of the
 * organization. It can be a string value indicating the current status of the organization, such as
 * "active", "inactive", "pending", etc.
 * @property {boolean} selected - A boolean value indicating whether the user organization is selected
 * or not.
 * @property {number} deviceCount - The number of devices associated with the user's organization.
 * @property {number} groupCount - The `groupCount` property represents the number of groups associated
 * with a user organization.
 * @property {boolean} mfa - The "mfa" property stands for Multi-Factor Authentication. It is a boolean
 * value that indicates whether the user has enabled or disabled multi-factor authentication for their
 * account.
 * @property {string} orgCode - The `orgCode` property is a string that represents the code or
 * identifier of an organization.
 * @property {string} logo - The `logo` property is a string that represents the logo of a user
 * organization.
 * @property {any | null} logoPayload - The `logoPayload` property is of type `any` or `null`. It can
 * be used to store any additional data related to the logo, such as its size, format, or other
 * metadata.
 * @property {any} adminCount - The `adminCount` property represents the number of administrators in
 * the user organization.
 */
export type UserOrgs = {
    id: string;
    name: string;
    status: string;
    selected?: boolean;
    deviceCount?: number;
    groupCount?: number;
    mfa?: boolean;
    orgCode?: string;
    logo?: string;
    logoPayload?: any;
    adminCount?: any;
};

export const ManageOrganization = (): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const deleteModalId = React.useId();
    const currentOrg = useTypedSelector(selectedOrg);
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const rolePermissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(rolePermissions);
    const canUpdateOrganization = hasPermission('update-organization');
    const canLeaveOrganization = hasPermission('leave-organization');
    const canDeleteOrganization = hasPermission('delete-organization');

    const [leaveOrganization, { isLoading: isLeavingOrg }] = useLeaveOrgMutation();
    const [deleteOrganization, { isLoading: isDeletingOrg }] = useDeleteOrgMutation();
    const [updateOrganization, { isLoading: isUpdatingOrg, isSuccess: isSuccessUpdateOrg }] = useUpdateOrgMutation();
    const [uploadOrganizationLogo, { isLoading, isSuccess: isSuccessUploadLogo }] = useUploadOrganizationLogoMutation();
    const [assignRoleToUser, { isLoading: isAssigningRoleToOrg }] = useAssignRoleToUserMutation();
    const [removeOrgLogo, { isSuccess: isSuccessRemoveLogo }] = useRemoveOrgLogoMutation();

    const { data: userProfileData, refetch: refetchUserProfileData } = useGetUserProfileQuery<{
        data: UserProfile;
        isLoading: boolean;
    }>();

    const {
        data: activeOrgs,
        refetch: refreshActiveOrgs,
        isLoading: isLoadingActiveOrg,
    } = useGetOrgQuery<{
        data: any;
        isLoading: boolean;
        isFetching: boolean;
        refetch: RefetchConfigOptions;
    }>({ page: 0, size: 25, status: 'ACTIVE' });

    const {
        data: pendingOrgs,
        refetch: refreshPendingOrgs,
        isLoading: isLoadingPendingOrgs,
    } = useGetOrgQuery<{
        data: any;
        isLoading: boolean;
        isFetching: boolean;
        refetch: RefetchConfigOptions;
    }>({ page: 0, size: 25, status: 'PENDING' });

    const [activeModal, setActiveModal] = useState('');
    const [isImageUploaded, setIsImageUploaded] = useState<boolean>(false);

    const { data: rolesList, refetch: refetchAllRoles } = useGetAllRolesQuery<{ data: AllRolesObj }>(undefined, {
        skip: !currentOrg?.id,
    });

    const {
        data: usersList,
        refetch: refetchAllUsers,
        isLoading: usersLoader,
        isFetching,
    } = useGetAllUsersQuery<{
        data: AllUserObj;
        isLoading: boolean;
        isFetching: boolean;
        refetch: RefetchConfigOptions;
    }>(
        { page: 0, size: 100, sort: { key: 'role', sortType: 'ASC' }, filters: { status: ['ACTIVE'] } },
        { skip: activeModal !== 'assignUsersPrompt' }
    );

    const [orgData, setOrgData] = useState<Partial<UserOrgs>>({ name: '', mfa: false });
    const [nonAdminUsers, setNonAdminUsers] = useState<UserObj[]>([]);
    const [selectedAdminUser, setSelectedAdminUser] = useState<{ userId: string; roleId: string; name: string }>({
        userId: '',
        roleId: '',
        name: '',
    });
    const [logoPreview, setLogoPreview] = useState<string | null>(null);

    const [getValidator] = useImageValidated();

    /* The below code is a useEffect hook in a TypeScript React component. It is triggered whenever the
    value of the "currentOrg" variable changes. */
    useEffect(() => {
        if (currentOrg?.id) {
            refetchAllRoles()
                .then(() => {})
                .catch(() => {});

            refetchUserProfileData()
                .then(() => {})
                .catch(() => {});
            setOrgData({ name: currentOrg?.name, mfa: currentOrg?.mfa, logoPayload: currentOrg?.logo });
        }
    }, [currentOrg]);

    /* The below code is using the useEffect hook in a React component. It is checking if the
    currentOrg object has both an "id" property and a "logo" property. If both properties exist, it
    sets the state variable "isImageUploaded" to true. Otherwise, it sets "isImageUploaded" to
    false. The useEffect hook is triggered whenever the "currentOrg" object changes. */
    useEffect(() => {
        if (currentOrg?.id && currentOrg?.logo) setIsImageUploaded(true);
        else setIsImageUploaded(false);
    }, [currentOrg]);

    /* The below code is using the useEffect hook in a React component. It is checking if the value of
    the activeModal variable is equal to 'assignUsersList'. If it is, then it calls the
    refetchAllUsers function. The useEffect hook is triggered whenever the value of activeModal
    changes. */
    useEffect(() => {
        if (activeModal === 'assignUsersPrompt') {
            refetchAllUsers() as any;
        }
    }, [activeModal]);

    /* The below code is a useEffect hook in a TypeScript React component. It is used to filter a list
    of users and set the filtered list in the state variable `nonAdminUsers`. */
    useEffect(() => {
        const allUsers = usersList?.data?.records;
        if (allUsers?.length > 0 && userProfileData) {
            const nonAdminUsersList = allUsers.filter(
                (user: UserObj) =>
                    user.orgAdmin !== true && user.status === 'ACTIVE' && user?.id !== userProfileData?.data?.id
            );
            setNonAdminUsers(nonAdminUsersList);
        }
    }, [usersList, userProfileData]);

    /* The below code is a useEffect hook in a TypeScript React component. It is used to handle the
    logic for setting the logo preview image URL based on the value of the `orgData` variable. */
    useEffect(() => {
        if (typeof orgData?.logoPayload === 'object' && orgData?.logoPayload !== null) {
            const orgImagePreviewUrl = URL.createObjectURL(orgData?.logoPayload);
            setLogoPreview(orgImagePreviewUrl);
        } else if (orgData?.logoPayload?.includes('https')) {
            setLogoPreview(orgData?.logoPayload);
        } else {
            setLogoPreview(null);
        }
    }, [orgData]);

    /**
     * The function `handleOpenSwitchOrganizationModal` dispatches an action to open a modal for
     * switching organizations.
     */
    const handleOpenSwitchOrganizationModal = (): void => {
        dispatch(switchOrgModal(true));
    };

    /**
     * The function `handleLeaveOrganizationApiCall` makes an API call to leave an organization and
     * displays a success message if the operation is successful.
     */
    const handleLeaveOrganizationApiCall = async (): Promise<void> => {
        const { error }: any = await leaveOrganization({ orgId: currentOrg?.id });
        if (!error) {
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: 'Organization left successfully.',
                    duration: 3000,
                })
            );

            setActiveModal('');
        }
    };

    /* The below code is defining a function called `handleLeaveOrganization` using the `useCallback`
    hook. */
    const handleLeaveOrganization = useCallback((): void => {
        if (!currentOrg?.orgAdmin || currentOrg?.adminCount > 1 || activeModal === 'assignedRole') {
            openConfirmModal(undefined);
        } else {
            setActiveModal('assignUsersPrompt');
        }
    }, [userProfileData, currentOrg, usersList, activeModal]);

    const {
        isVisible: isConfirmModalOpen,
        onClick: openConfirmModal,
        onCancel: closeConfirmModal,
        onConfirm: confirmSuccess,
    } = useConfirm(handleLeaveOrganizationApiCall as any);

    /* The below code is defining a function called `handleSelectAdminUser` in a TypeScript React
    component. This function is using the `useCallback` hook to memoize the function and its
    dependencies. */
    const handleSelectAdminUser = useCallback(
        (e: any) => {
            const userId = e.currentTarget.id;
            const allRoles = rolesList?.data?.records;
            const selectedUser = nonAdminUsers?.find((user) => user.id === userId);
            const selectedUserRole = allRoles?.find((role) => role?.id === selectedUser?.roleId);
            setSelectedAdminUser({
                userId,
                roleId: selectedUserRole?.id ?? '',
                name: `${selectedUser?.firstName ?? ''} ${selectedUser?.lastName ?? ''}`,
            });
        },
        [rolesList, nonAdminUsers]
    );

    /* The below code is defining a function called `handleAssignAdminUser` using the `useCallback`
    hook. This function is an asynchronous function that performs the following steps: */
    const handleAssignAdminUser = useCallback(async () => {
        const allRoles = rolesList?.data?.records;
        if (allRoles?.length > 0) {
            const { error }: any = await assignRoleToUser({
                userId: selectedAdminUser.userId,
                roleId: currentOrg?.roleId ?? '',
            });
            if (!error) {
                setActiveModal('assignedRole');
            }
        }
    }, [rolesList, selectedAdminUser, userProfileData]);

    /* The below code is defining a function called `handleUndoAssignUser` using the `useCallback`
    hook. This function is an asynchronous function that performs the following steps: */
    const handleUndoAssignUser = useCallback(async () => {
        const { error }: any = await assignRoleToUser(selectedAdminUser);
        if (!error) {
            setActiveModal('assignUsersList');
            setSelectedAdminUser({ userId: '', roleId: '', name: '' });
        }
    }, [selectedAdminUser]);

    /* The below code is defining a function called `handleDeleteOrganization` using the `useCallback`
    hook. This function is an asynchronous function that deletes an organization. */
    const handleDeleteOrganization = useCallback(async (): Promise<void> => {
        const { error }: any = await deleteOrganization({ orgId: currentOrg?.id });
        if (!error) {
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: 'Organization deleted successfully.',
                    duration: 3000,
                })
            );

            setActiveModal('');
        }
    }, [currentOrg]);

    const {
        isVisible: isDeleteModalOpen,
        onClick: openDeleteModal,
        onCancel: closeDeleteModal,
        onConfirm: confirmDeletion,
    } = useConfirm(handleDeleteOrganization);

    /* The below code is defining a function called `handleChangeOrgData` using the `useCallback` hook
    in a TypeScript React component. This function is used to handle changes in the organization
    data. */
    const handleChangeOrgData = useCallback((e: any): void => {
        setOrgData((prev) => {
            const resultedValue = e.target.name === 'mfa' ? e.target.checked : e.target.value;
            return { ...prev, [e.target.name]: resultedValue };
        });
    }, []);

    /**
     * The function `isSaveDiscardDisabled` checks if the save and discard buttons should be disabled
     * based on the comparison of `orgData` and `currentOrg` properties.
     * @returns a boolean value.
     */
    const isSaveDiscardDisabled = (): boolean => {
        if (
            (orgData.name === currentOrg?.name &&
                orgData.mfa === currentOrg?.mfa &&
                orgData.logoPayload === currentOrg?.logo) ||
            !handleWhiteSpaces(orgData?.name)
        ) {
            return true;
        }
        return false;
    };

    /* The below code is defining a function called `handleDiscardChanges` using the `useCallback` hook
    in a TypeScript React component. */
    const handleDiscardChanges = useCallback(() => {
        setOrgData({ name: currentOrg?.name, mfa: currentOrg?.mfa, logoPayload: currentOrg?.logo });
        setIsImageUploaded(Boolean(currentOrg?.logo));
    }, [currentOrg]);

    /* The below code is defining a function called `handleUpdateOrganization` using the `useCallback`
    hook. This function is responsible for updating the organization's logo and other details. */
    const handleUpdateOrganization = useCallback(async (): Promise<void> => {
        if (orgData?.logoPayload && typeof orgData?.logoPayload !== 'string') {
            const formData = new FormData();
            formData.append('logo', orgData?.logoPayload);
            await uploadOrganizationLogo({ orgId: currentOrg?.id, logo: formData });
        } else if (!isImageUploaded && currentOrg?.logo) {
            await removeOrgLogo({ orgId: currentOrg?.id });
        }
        if (orgData.name !== currentOrg?.name || orgData.mfa !== currentOrg?.mfa) {
            await updateOrganization({
                body: { name: orgData?.name, mfa: orgData?.mfa },
                orgId: currentOrg?.id,
            });
        }
    }, [orgData, currentOrg]);

    useEffect(() => {
        if (isSuccessRemoveLogo) {
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: 'Changes saved.',
                    duration: 3000,
                })
            );
        }
    }, [isSuccessRemoveLogo]);

    useEffect(() => {
        if (isSuccessUpdateOrg) {
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: 'Changes saved.',
                    duration: 3000,
                })
            );
        }
    }, [isSuccessUpdateOrg]);

    useEffect(() => {
        if (isSuccessUploadLogo) {
            dispatch(
                setToastifyContent({
                    isOpen: true,
                    message: 'Changes saved.',
                    duration: 3000,
                })
            );
        }
    }, [isSuccessUploadLogo]);

    /**
     * The function `handleUploadLogo` handles the upload of a logo file, validates it, and updates the
     * organization data and image upload status accordingly.
     * @param {any} e - The parameter `e` is an event object that represents the event that triggered
     * the function. In this case, it is an event object related to uploading a file.
     * @returns The function `handleUploadLogo` is returning `any`.
     */
    const handleUploadLogo = (e: any): any => {
        const target = e.target as HTMLInputElement;
        if (!target.files) return;
        if (getValidator(target.files[0])) {
            setOrgData((prev) => ({ ...prev, logoPayload: target?.files && target?.files[0] }));
            setIsImageUploaded(true);
        }
        e.target.value = null;
    };

    /**
     * The function `handleRemoveOrgImage` is used to remove an organization's logo image and update
     * the corresponding state variables.
     */
    const handleRemoveOrgImage = (): void => {
        setOrgData((prev) => ({ ...prev, logoPayload: null }));
        setIsImageUploaded(false);
        setLogoPreview(null);
    };

    /**
     * The above function copies the current organization's code to the clipboard and displays a toast
     * message indicating successful copying.
     * @returns The `copyCodeHandler` function returns a Promise that resolves to `void`.
     */
    const copyCodeHandler = (): Promise<void> => {
        dispatch(
            setToastifyContent({
                isOpen: true,
                message: 'Copied successfully',
                duration: 3000,
            })
        );
        return navigator.clipboard.writeText(currentOrg?.orgCode ?? '');
    };

    /**
     * The function `backToOrganizationHandler` sets the active modal to an empty string and refreshes
     * the associated organizations.
     */
    const backToOrganizationHandler = (): void => {
        setActiveModal('');
        refreshActiveOrgs()
            .then(() => {})
            .catch(() => {});
        refreshPendingOrgs()
            .then(() => {})
            .catch(() => {});
    };

    const getNonAdminUserList = (): JSX.Element =>
        nonAdminUsers?.length > 0 ? (
            <List>
                {nonAdminUsers.map((user) => (
                    <InfoListItem
                        key={user.id}
                        className={`${classes.listItem} ${
                            selectedAdminUser.userId === user.id ? 'selected-primary-blue-highlight-color' : ''
                        } `}
                        data-testid="infoListItem"
                        data-cy={'list-content'}
                        icon={
                            <Avatar>
                                {user?.firstName || user?.lastName ? (
                                    getUserInitials(`${user.firstName || ''} ${user.lastName || ' '}`)
                                ) : (
                                    <Person fontSize="inherit" />
                                )}
                            </Avatar>
                        }
                        subtitle={user.role}
                        title={getFullName(user?.firstName, user.lastName)}
                        statusColor={Colors.gray[50]}
                        avatar
                        iconAlign="center"
                        divider={'partial'}
                        hidePadding
                        id={user.id}
                        onClick={handleSelectAdminUser}
                        {...(selectedAdminUser.userId === user.id && { secondaryAction: <Done /> })}
                    />
                ))}
            </List>
        ) : (
            <EmptyState
                icon={<PeopleAlt fontSize={'inherit'} sx={{ color: Colors.gray[500] }} />}
                title={'No Organization Admin(s)'}
                actions={
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        startIcon={<Add />}
                        onClick={(): void => navigate('/user/add')}
                    >
                        Add User
                    </Button>
                }
            />
        );

    const getDialogAction = (): JSX.Element => (
        <DialogActions className={classes.MuiDialogActionsRoot}>
            <Stack spacing={2} direction={'row'}>
                <Button variant={'outlined'} onClick={(): void => setActiveModal('')}>
                    Cancel
                </Button>
                <Button
                    variant={'contained'}
                    {...(!nonAdminUsers?.length
                        ? { onClick: (): void => navigate('/user/add') }
                        : { onClick: (): void => setActiveModal('assignUsersList') })}
                >
                    {!nonAdminUsers?.length ? 'Add User(s)' : 'Assign Role'}
                </Button>
            </Stack>
        </DialogActions>
    );

    /* The below code is defining a function called `getFileUploadHandlerActions` that returns a JSX
    element. */
    const getFileUploadHandlerActions = (): JSX.Element => {
        if (isImageUploaded) {
            return (
                <>
                    <Button
                        color={'primary'}
                        variant={'outlined'}
                        startIcon={<FileUploadIcon />}
                        component="label"
                        onChange={handleUploadLogo}
                        className="grey-btn"
                    >
                        <input hidden accept="image/*" type="file" />
                        Replace Organization Logo
                    </Button>
                    <Button variant="text" onClick={handleRemoveOrgImage} startIcon={<DeleteIcon />}>
                        Remove Logo
                    </Button>
                </>
            );
        }
        return (
            <Button
                variant={'outlined'}
                color={'primary'}
                startIcon={<FileUploadIcon />}
                component="label"
                onChange={handleUploadLogo}
                className="grey-btn"
            >
                <input hidden accept="image/*" type="file" />
                Upload Organization Logo
            </Button>
        );
    };

    if (!activeOrgs?.data || !pendingOrgs?.data) {
        return <BackdropLoader isOpen={isLoadingActiveOrg || isLoadingPendingOrgs} />;
    } else if (activeOrgs?.data?.records?.length === 0) {
        const isPendingApproval = Boolean(pendingOrgs?.data?.records?.length);
        return (
            <EmptyState
                className={classes.emptyStateWrapper}
                icon={<CorporateFare fontSize={'inherit'} sx={{ color: Colors.gray[500] }} />}
                actions={
                    !userProfileData?.data?.isSuperAdmin && (
                        <Stack direction={'column'} spacing={2}>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                onClick={(): void => {
                                    dispatch(orgSlice.actions.orgFlowModal({ isOpen: true, flow: 'create' }));
                                }}
                            >
                                Create a New Organization
                            </Button>
                            <Button
                                variant={'outlined'}
                                color={'primary'}
                                className="grey-btn"
                                onClick={(): void => {
                                    dispatch(orgSlice.actions.orgFlowModal({ isOpen: true, flow: 'join' }));
                                }}
                            >
                                Join an Existing Organization
                            </Button>
                            {isPendingApproval && (
                                <Button
                                    variant={'outlined'}
                                    color={'primary'}
                                    className="grey-btn"
                                    onClick={(): void => {
                                        dispatch(orgSlice.actions.orgFlowModal({ isOpen: true, flow: 'withdraw' }));
                                    }}
                                >
                                    Withdraw Request to Join
                                </Button>
                            )}
                        </Stack>
                    )
                }
                title={isPendingApproval ? 'Awaiting Approval' : 'No Organization'}
                description={
                    isPendingApproval ? (
                        <Typography variant="subtitle2">
                            You&apos;re currently waiting for approval to join an Organization.
                        </Typography>
                    ) : (
                        <>
                            <Typography variant={'subtitle2'}>
                                {'You do not currently belong to an Organization.'}
                            </Typography>
                            <Typography variant={'subtitle2'}>
                                {`You'll need to Join or Create an Organization to use the application.`}
                            </Typography>
                        </>
                    )
                }
            />
        );
    }
    return (
        <React.Fragment>
            <header>
                <title>AddDevice</title>
                <meta name="description" content="Description of AddDevice" />
            </header>
            {canUpdateOrganization && (
                <AppBar position="static" color="primary" className={classes.subHeader}>
                    <Spacer />
                    <Button
                        variant="outlined"
                        sx={!smDown ? { mr: 2 } : { mb: 1, width: '100%' }}
                        disabled={isSaveDiscardDisabled()}
                        onClick={handleDiscardChanges}
                    >
                        Discard Changes
                    </Button>
                    <Button
                        variant="contained"
                        sx={!smDown ? {} : { width: '100%' }}
                        disabled={isSaveDiscardDisabled()}
                        onClick={handleUpdateOrganization}
                    >
                        Save Changes
                    </Button>
                </AppBar>
            )}

            <Container fixed className={classes.ContainerWrapper}>
                <Box className={classes.contentBody}>
                    <Accordion expanded={Boolean(currentOrg?.id)}>
                        {smDown ? null : (
                            <>
                                <AccordionSummary className={classes.accordionSummary}>
                                    <Typography variant={'subtitle2'} color={'primary'}>
                                        Organization
                                    </Typography>
                                </AccordionSummary>
                                <Divider />
                            </>
                        )}
                        <AccordionDetails className={classes.accordionDetails}>
                            <OrganizationDetails
                                orgData={orgData}
                                logoPreview={logoPreview}
                                isImageUploaded={isImageUploaded}
                                currentOrg={currentOrg}
                                getFileUploadHandlerActions={getFileUploadHandlerActions}
                                handleChangeOrgData={handleChangeOrgData}
                                copyCodeHandler={copyCodeHandler}
                            />
                            <Stack spacing={2} direction={'column'} mt={3}>
                                <Button variant={'outlined'} onClick={handleOpenSwitchOrganizationModal}>
                                    Switch Organization
                                </Button>
                                {!userProfileData?.data?.isSuperAdmin && (
                                    <>
                                        <Button
                                            variant={'outlined'}
                                            onClick={(): void => {
                                                dispatch(
                                                    orgSlice.actions.orgFlowModal({ isOpen: true, flow: 'create' })
                                                );
                                            }}
                                        >
                                            Create an Organization
                                        </Button>
                                        <Button
                                            variant={'outlined'}
                                            onClick={(): void => {
                                                dispatch(orgSlice.actions.orgFlowModal({ isOpen: true, flow: 'join' }));
                                            }}
                                        >
                                            Join an Organization
                                        </Button>
                                    </>
                                )}
                                {canLeaveOrganization && (
                                    <Button variant={'outlined'} onClick={handleLeaveOrganization}>
                                        Leave this Organization
                                    </Button>
                                )}
                                {canDeleteOrganization && (
                                    <Button
                                        variant={'outlined'}
                                        color="error"
                                        onClick={(): void => setActiveModal('deleteRole')}
                                    >
                                        Permanently Delete this Organization
                                    </Button>
                                )}
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Container>
            <Dialog
                open={activeModal === 'assignUsersPrompt'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.muiDialogpaper}
            >
                {usersLoader || isFetching ? (
                    <Loader size={60} />
                ) : (
                    <>
                        <DialogTitle id="alert-dialog-title">
                            <Typography variant={'h6'}>
                                {' '}
                                {'Assign Another User the Organization Admin Role Before Leaving'}
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Typography variant={'body1'} mb={1}>
                                {
                                    "As the only organization admin, you'll need to grant that role to another user before you can leave"
                                }{' '}
                                <b>{currentOrg?.name}</b>.{' '}
                                {!nonAdminUsers?.length &&
                                    "If no other users exist, you'll need to add users to the organization first."}
                            </Typography>
                            {!nonAdminUsers?.length && (
                                <Typography variant={'body1'} mt={1}>
                                    The Organization can also be deleted if you cannot add other users.
                                </Typography>
                            )}
                        </DialogContent>
                        {getDialogAction()}
                    </>
                )}
            </Dialog>
            <Dialog
                open={activeModal === 'assignUsersList'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.muiDialogpaper}
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant={'h6'}>{'Assign Organization Admin'}</Typography>
                </DialogTitle>
                <DialogContent className={classes.listWrapper}>
                    {usersLoader || isFetching ? <Loader size={60} /> : getNonAdminUserList()}
                </DialogContent>
                <DialogActions className={classes.MuiDialogActionsRoot}>
                    <Stack spacing={2} direction={'row'}>
                        <Button variant={'outlined'} onClick={(): void => setActiveModal('')}>
                            Cancel
                        </Button>
                        <Button
                            variant={'contained'}
                            disabled={!selectedAdminUser.userId}
                            onClick={handleAssignAdminUser}
                        >
                            Assign Role
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
            <Dialog
                open={activeModal === 'assignedRole'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.muiDialogpaper}
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant={'h6'}> {'Organization Admin Assigned'}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant={'body1'} mb={3}>
                        <span style={{ fontWeight: 600 }}>{`${selectedAdminUser?.name} `}</span>
                        is now an <span style={{ fontWeight: 600 }}>Organization Admin</span>. You are now able to leave
                        <span style={{ fontWeight: 600 }}>{` ${currentOrg?.name}`}</span>.
                    </Typography>
                    <Stack spacing={2} direction={'column'}>
                        <Button variant={'contained'} onClick={handleLeaveOrganization}>
                            Leave Organization
                        </Button>
                        <Button variant={'outlined'} onClick={handleUndoAssignUser}>
                            Undo Assign Role
                        </Button>
                        <Button variant={'outlined'} onClick={backToOrganizationHandler}>
                            Back to Organization Settings
                        </Button>
                    </Stack>
                </DialogContent>
            </Dialog>
            <Dialog
                open={activeModal === 'deleteRole'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.muiDialogpaper}
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant={'h6'} sx={{ wordBreak: 'break-word' }}>
                        {' '}
                        {`Delete ${currentOrg?.name}?`}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant={'body2'} sx={{ wordBreak: 'break-word' }} mb={1}>
                        {`Select 'Continue' to permanently delete ${currentOrg?.name} and all of its associated location, device, alarm, event, report, and user data. This cannot be undone.`}
                    </Typography>
                </DialogContent>
                <DialogActions className={classes.MuiDialogActionsRoot}>
                    <Stack spacing={2} direction={'row'}>
                        <Button variant={'outlined'} onClick={(): void => setActiveModal('')}>
                            Cancel
                        </Button>
                        <Button
                            variant={'contained'}
                            className={classes.continueButton}
                            onClick={(): void => {
                                openDeleteModal();
                                setActiveModal('');
                            }}
                        >
                            Continue
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
            <DeleteModal
                key={deleteModalId}
                closeHandler={closeDeleteModal}
                confirmClick={confirmDeletion}
                deleteButtonText="Delete Organization"
                isVisible={isDeleteModalOpen}
                wrapperClass={classes.muiDialogpaper}
                actionClass={classes.MuiDialogActionsRoot}
                titleClass={classes.MuiDialogTitleRoot}
                description={
                    <>
                        {`${currentOrg?.name} and all of its associated location, device, alarm, event, report, and user data will be permanently deleted. This cannot be undone. `}
                    </>
                }
                headingText={`Delete ${currentOrg?.name}?`}
                deleteButtonClass={classes.deleteButton}
            />

            {(isLoading || isUpdatingOrg || isLeavingOrg || isDeletingOrg || isAssigningRoleToOrg) && (
                <BackdropLoader isOpen={true} />
            )}
            <ConfirmModal
                key={'siugu8383'}
                confirmButtonText={'Leave Organization'}
                closeHandler={closeConfirmModal}
                confirmClick={confirmSuccess}
                isVisible={isConfirmModalOpen}
                wrapperClass={classes.muiDialogpaper}
                actionClass={classes.MuiDialogActionsRoot}
                titleClass={classes.MuiDialogTitleRoot}
                description={<>Once you leave this Organization you will lose all access.</>}
                headingText={`Leave ${currentOrg?.name} ?`}
            />
        </React.Fragment>
    );
};
