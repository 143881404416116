import React from 'react';
import { createNumArray } from '../../../CommonUtils';
import { Box, Skeleton, Typography, Switch } from '@mui/material';

const PropertiesCardSkeleton = ({
    type,
    count,
    noAvatar,
}: {
    type?: string;
    count?: number;
    noAvatar?: boolean;
}): JSX.Element => (
    <>
        {createNumArray(count ?? 5).map((item) => (
            <Box
                className="modal-space-between"
                sx={{
                    gap: '16px',
                    padding: '16px',
                }}
                key={`property${item}`}
            >
                <Box className="modal-align-center">
                    {!noAvatar && (
                        <Skeleton
                            animation="wave"
                            variant="circular"
                            width={35}
                            height={35}
                            sx={{
                                marginRight: '22px',
                                marginLeft: '10px',
                                color: '#727E84',
                            }}
                        />
                    )}

                    <Skeleton animation="wave">
                        <Typography variant="body1" className="modal-greyhead-text">
                            Loading Title
                        </Typography>
                    </Skeleton>
                </Box>

                {type === 'loads' ? (
                    <Box className="modal-align-center">
                        <Skeleton animation="wave">
                            <Switch />
                        </Skeleton>
                        <Skeleton sx={{ marginLeft: 4 }} variant="circular" animation="wave" width={20} height={20} />
                    </Box>
                ) : (
                    <Skeleton animation="wave">
                        <Typography variant="body1" className="modal-greyhead-text">
                            Loading
                        </Typography>
                    </Skeleton>
                )}
            </Box>
        ))}
    </>
);

export default PropertiesCardSkeleton;
