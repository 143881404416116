import React from 'react';
import { Dialog, DialogContent, DialogTitle, Box, Avatar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Theme, useTheme } from '@mui/material/styles';
import { Person } from '@mui/icons-material';
import { RootState, useTypedSelector } from '@fiji/common/src/app/store';

const useStyles = makeStyles((theme: Theme) => ({
    muiDialogpaper: {
        [theme.breakpoints.down('sm')]: {
            // minHeight: '150px',
        },
        '& .MuiPaper-root': {
            maxWidth: '450px',
            width: '450px',
            // height: '100%',
            maxHeight: '621px',
            overflowY: 'hidden',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                margin: '0px',
                maxHeight: 'none',
                width: '100%',
                borderRadius: 0,
                height: '100%',
            },
        },
    },
    MuiDialogTitleRoot: {
        padding: '62px 24px 0px 24px',
    },
    dialogcontent: {
        minHeight: '334px',
    },
    MuiDialogActionsRoot: {
        padding: '24px',
        borderTop: '1px solid rgba(66, 78, 84, 0.12)',
    },
    avatarIcon: {
        height: '96px',
        width: '96px',
        '& .MuiSvgIcon-root': {
            height: '47px',
            width: '47px',
        },
    },
    avatarBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(5),
    },
    typographyTitle: {
        marginBottom: theme.spacing(2),
        fontSize: '24px',
        fontWeight: 'bold',
        // color: Colors.black[800],
        textAlign: 'center',
    },
}));

const AuthenticationModal = (): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const authState = useTypedSelector((state: RootState) => state.common.authModal);

    return (
        <Dialog
            open={authState}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.muiDialogpaper}
        >
            <DialogTitle id="alert-dialog-title" className={classes.MuiDialogTitleRoot}>
                <Box className={classes.avatarBox}>
                    <Avatar className={classes.avatarIcon}>
                        <Person color="primary" />
                    </Avatar>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Typography variant="h1" className={classes.typographyTitle}>
                    Authentication Failed !
                </Typography>
            </DialogContent>
        </Dialog>
    );
};

export default AuthenticationModal;
