import { api } from '../../app/api/baseApi';

export const notifcationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        sendTestNotifcation: builder.mutation<object, object>({
            query: (body) => ({
                url: `/iam/v1/notifications/test`,
                method: 'POST',
                body: body,
            }),
        }),
        getNotificationPreferences: builder.query<object, void>({
            query: () => ({
                url: `/iam/v1/users/me/notification`,
            }),
            providesTags: ['Notifications'],
        }),
        saveNotificationPreferences: builder.mutation<object, object>({
            query: (body) => ({
                url: `/iam/v1/users/me/notification`,
                method: 'PATCH',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Notifications'] : []),
        }),
    }),
});

export const {
    useSendTestNotifcationMutation,
    useGetNotificationPreferencesQuery,
    useSaveNotificationPreferencesMutation,
} = notifcationApi;
