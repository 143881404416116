import React from 'react';
import PropertiesCardSkeleton from '../Common/Skeletons/PropertiesCardSkeleton';
import { CardContent, Stack, Typography, Chip } from '@mui/material';
import { Memory, Today, Tag, Description, DeveloperBoard } from '@mui/icons-material';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { useTheme } from '@mui/material/styles';
import { useStyles } from './styles';

const INFO_LIST_PROPS: any = {
    dataTestid: 'infoListItem',
    hidePadding: true,
    ripple: true,
    dense: true,
};

export const DeviceProperties = ({
    infoListProps,
    deviceDetails,
    isLoading,
    convertDateTime,
}: {
    infoListProps: any;
    deviceDetails: any;
    isLoading: boolean;
    convertDateTime: (props: any) => any;
}): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles(theme);
    return (
        <CardContent className={classes.cardContent}>
            {isLoading ? (
                <PropertiesCardSkeleton />
            ) : (
                <>
                    <InfoListItem
                        {...infoListProps}
                        title={'Serial Number'}
                        icon={<Memory />}
                        rightComponent={
                            <Typography variant={'subtitle1'}>
                                {deviceDetails?.data?.serialNumber?.length ? deviceDetails?.data?.serialNumber : '-'}
                            </Typography>
                        }
                    />
                    <InfoListItem
                        {...infoListProps}
                        title={'Network Card Firmware Version'}
                        icon={<DeveloperBoard fontSize="inherit" />}
                        rightComponent={
                            <Typography variant={'subtitle1'}>
                                {deviceDetails?.data?.firmware?.length ? deviceDetails?.data?.firmware : '-'}
                            </Typography>
                        }
                    />
                    <InfoListItem
                        {...infoListProps}
                        title={'Device Install Date'}
                        icon={<Today fontSize="inherit" />}
                        rightComponent={
                            <Typography variant={'subtitle1'}>
                                {deviceDetails?.data?.deviceInstallDate
                                    ? convertDateTime({
                                          timestamp: deviceDetails?.data?.deviceInstallDate,
                                      })
                                    : '-'}
                            </Typography>
                        }
                    />
                    <InfoListItem
                        {...infoListProps}
                        title={'Battery Install Date'}
                        icon={<Today fontSize="inherit" />}
                        rightComponent={
                            <Typography variant={'subtitle1'}>
                                {deviceDetails?.data?.batteryInstallDate
                                    ? convertDateTime({
                                          timestamp: deviceDetails?.data?.batteryInstallDate,
                                      })
                                    : '-'}
                            </Typography>
                        }
                    />
                    <InfoListItem
                        {...infoListProps}
                        title={'Tags'}
                        icon={<Tag fontSize="inherit" />}
                        className="custom-info-list-item-tag"
                        rightComponent={
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                {deviceDetails?.data?.tag?.length
                                    ? deviceDetails?.data?.tag?.map((tag: any) => (
                                          <Chip key={tag?.id} label={tag?.name} />
                                      ))
                                    : '-'}
                            </Stack>
                        }
                    />
                    <InfoListItem
                        {...INFO_LIST_PROPS}
                        title={'Description'}
                        icon={<Description fontSize="inherit" />}
                    />
                    <Typography py={1} px={2} variant={'subtitle1'} sx={{ wordWrap: 'break-word' }}>
                        {deviceDetails?.data?.description?.length ? deviceDetails?.data?.description : '-'}
                    </Typography>
                </>
            )}
        </CardContent>
    );
};
